import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CheckBox } from '../Selections';

const CardListCheckbox = ({ onSelectionChange, className, ...others }) => (
    <div className={classNames('onsolve-card-list__cell--selection', className)}>
        <CheckBox {...others} onChange={onSelectionChange} />
    </div>
);

CardListCheckbox.propTypes = {
    /**
    Specifies checked state
    */
    checked: PropTypes.bool.isRequired,
    /**
    Specifies className to extend existing class list
    */
    className: PropTypes.string,
    /**
    Specifies onChange handler
    */
    onSelectionChange: PropTypes.func.isRequired,
    /**
    Specifies value for checkbox
    */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

CardListCheckbox.displayName = 'CardListCheckbox';

export default CardListCheckbox;
