import React from 'react';
import SvgIcon from './SvgIcon';

const Group = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
                <g transform="scale(0.8) translate(0.7, 1.70)">
                    <path d="m5 0l2 3h8l7.96e-4 4.3107c2.0939 0.24765 3.718 2.0288 3.718 4.1893 0 2.33-1.8888 4.2188-4.2188 4.2188-1.8014 0-3.339-1.129-3.944-2.718l-8.556-7.509e-4c-1.105 0-2-0.895-2-2v-11h5z" />
                    <g transform="translate(10 7)">
                        <circle cx="4.5" cy="4.5" r="4.2188" fill="#fff" />
                        <g transform="translate(1.7139 2.5)">
                            <circle cx="2.7861" cy="1.9062" r="1.4062" />
                            <path d="m5.5721 5.1618c-0.51335-1.0726-1.5969-1.7553-2.7861-1.7553s-2.2727 0.68268-2.7861 1.7553" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

Group.displayName = 'GroupIcon';

export default Group;
