import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class CheckboxPlaceholder extends Component {
    render() {
        const { className, classes, style } = this.props;

        const rootClasses = classNames('onsolve-placeholder', classes.root);
        const inlineRowClasses = classNames(
            'onsolve-placeholder__row',
            'onsolve-placeholder__row--inline',
            classes.row,
            className
        );
        const tolltipClasses = classNames('onsolve-placeholder__checkbox--tooltip', inlineRowClasses);
        const labelClasses = classNames('onsolve-placeholder__checkbox--label', inlineRowClasses);

        return (
            <div className={rootClasses}>
                <span className={tolltipClasses} style={{ ...style }} />
                <span className={labelClasses} style={{ ...style }} />
            </div>
        );
    }
}

CheckboxPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    style: PropTypes.object
};

CheckboxPlaceholder.defaultProps = {
    classes: {}
};

CheckboxPlaceholder.displayName = 'CheckboxPlaceholder';

export default CheckboxPlaceholder;
