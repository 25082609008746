import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { keyCodes } from '../../../common/constants';

import { IconButton } from '../../Button';
import { componentsTranslations } from '../../translations';
import { DomUtils } from '../../utils';

const StyleButton = forwardRef(
    ({ className, onToggle, style, intl, translate, isActive, iconComponent: IconComponent, disabled }, ref) => {
        const handleToggle = e => {
            e.preventDefault();
            onToggle(style, isActive);
        };

        const handleKeyDown = e => {
            if (DomUtils.getCharCode(e) === keyCodes.SPACE) {
                e.preventDefault();
                e.stopPropagation();
                onToggle(style, isActive);
            }
        };

        return (
            <IconButton
                className={className}
                aria-label={intl.formatMessage(componentsTranslations[`ng_wysiwyg_editor_toolbar_${translate}`])}
                size="md"
                onClick={handleToggle}
                onKeyDown={handleKeyDown}
                ref={ref}
                disabled={disabled}
            >
                <IconComponent size="md" color={isActive ? 'primary' : 'secondary'} />
            </IconButton>
        );
    }
);

StyleButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

StyleButton.defaultProps = {
    disabled: false
};

StyleButton.displayName = 'StyleButton';

export default StyleButton;
