import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { Button } from '../Button';
import { Popover, PopoverHeader, PopoverBody, PopoverActions } from '../Popover';

import { componentsTranslations } from '../translations';

const FiltersWrapper = ({
    id,
    container,
    children,
    isApplyDisabled,
    isOpen,
    modifiers,
    placement,
    target,
    filterCount,
    onApply,
    onReset,
    onClose
}) => {
    const scrollbarProps = {
        autoHeightMax: 504
    };

    return (
        <Popover
            id={id}
            container={container}
            target={target}
            isOpen={isOpen}
            modifiers={modifiers}
            placement={placement}
            className="onsolve-filters-popover"
            size="lg"
            trigger="manual"
            onClose={onClose}
        >
            <PopoverHeader onClose={onClose}>
                <FormattedMessage {...componentsTranslations.ng_components_filters} />
                {!!filterCount && (
                    <Badge className="onsolve-filters-popover__badge" color="primary" pill>
                        {filterCount}
                    </Badge>
                )}
            </PopoverHeader>
            <PopoverBody scrollbarProps={scrollbarProps}>{children}</PopoverBody>
            <PopoverActions>
                <Button className="text-uppercase" size="lg" onClick={onReset}>
                    <FormattedMessage {...componentsTranslations.ng_components_reset} />
                </Button>
                <Button
                    className="text-uppercase"
                    size="lg"
                    color="primary"
                    onClick={onApply}
                    disabled={isApplyDisabled}
                >
                    <FormattedMessage {...componentsTranslations.ng_components_apply} />
                </Button>
            </PopoverActions>
        </Popover>
    );
};

FiltersWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    container: PropTypes.oneOfType([PropTypes.string, PropTypes.func, Element]),
    filterCount: PropTypes.number,
    id: PropTypes.string,
    isApplyDisabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    modifiers: PropTypes.object,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    placement: PropTypes.string,
    target: PropTypes.string.isRequired
};

FiltersWrapper.defaultProps = {
    placement: 'bottom-end'
};

FiltersWrapper.displayName = 'FiltersWrapper';

export default FiltersWrapper;
