import React from 'react';

import SvgIcon from './SvgIcon';

const Stop = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                fillRule="nonzero"
                d="M13.0545 2.9455l-.013-.0095C10.2266.2946 5.8234.3645 3.094 3.094.3645 5.8234.2946 10.2267 2.936 13.0414l.0095.0131.013.0095c2.8148 2.6414 7.218 2.5715 9.9475-.158 2.7295-2.7294 2.7994-7.1327.158-9.9474l-.0095-.0131zM8 3.2338a4.703 4.703 0 012.4057.6756l-6.4963 6.4963A4.703 4.703 0 013.2338 8 4.7662 4.7662 0 018 3.2338zm0 9.5324a4.703 4.703 0 01-2.4057-.6756l6.4963-6.4963A4.703 4.703 0 0112.7662 8 4.7662 4.7662 0 018 12.7662z"
            />
        </SvgIcon>
    );
};

Stop.displayName = 'StopIcon';

export default Stop;
