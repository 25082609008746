import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../../Focusable';
import { SortIcon } from '../../Icons';
import { OnsolvePropTypes } from '../../utils';
import { ColumnResizer } from '../Resize';

class HeaderCell extends PureComponent {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    get headerCellRender() {
        const { render, title, ...other } = this.props;

        if (render) {
            return render({ title, ...other });
        }
        return <span className="onsolve-table__header-cell-value">{title}</span>;
    }

    componentDidMount() {
        const { setRef, index } = this.props;

        setRef(index, this.ref);
    }

    handleClick(field) {
        return e => {
            e.preventDefault();

            const { sort, onSortChange } = this.props;
            const dir = sort.dir === 'asc' ? 'desc' : 'asc';

            if (sort.field === field) {
                onSortChange(e, { ...sort, dir });
            } else {
                onSortChange(e, { field, dir });
            }
        };
    }

    handleKeyUp = index => {
        const { onKeyUp } = this.props;

        return e => onKeyUp(e, index);
    };

    renderSortIcon({ sort = {}, field }) {
        if (sort.field === field) {
            return (
                <span className="onsolve-table__header-icon">
                    <SortIcon direction={sort.dir === 'asc' ? 'up' : 'down'} color="secondary" />
                </span>
            );
        }
    }

    renderHeaderCell() {
        const { align, sort, sortable, field } = this.props;
        const defaultRender = this.headerCellRender;

        if (sortable) {
            const buttonClasses = classNames('onsolve-table__header-button', {
                [`text-${align}`]: align !== undefined
            });

            return (
                <Focusable
                    render={({ classes }) => (
                        <a
                            href="#"
                            ref={this.ref}
                            tabIndex="0"
                            className={classNames(classes, buttonClasses)}
                            onClick={this.handleClick(field)}
                        >
                            {defaultRender}
                            {this.renderSortIcon({ sort, field })}
                        </a>
                    )}
                />
            );
        }
        return defaultRender;
    }

    render() {
        const { align, resizable, sortable, onColumnResize, index, headerCellProps } = this.props;
        const tabIndex = headerCellProps ? headerCellProps.tabIndex : -1;
        const cellClasses = classNames('onsolve-table__header-cell', {
            [`text-${align}`]: align !== undefined,
            'onsolve-table__header-cell--sortable': sortable !== undefined
        });

        return (
            <th tabIndex={tabIndex} className={cellClasses} onKeyUp={this.handleKeyUp(index)} role="columnheader">
                <Fragment>
                    {this.renderHeaderCell()}
                    {resizable && <ColumnResizer onResize={onColumnResize} />}
                </Fragment>
            </th>
        );
    }
}

HeaderCell.propTypes = {
    align: OnsolvePropTypes.align,
    field: PropTypes.string,
    headerCellProps: PropTypes.object,
    index: PropTypes.number,
    onColumnResize: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSortChange: PropTypes.func,
    render: PropTypes.func,
    resizable: PropTypes.bool,
    setRef: PropTypes.func,
    sort: PropTypes.shape({
        field: PropTypes.string,
        dir: PropTypes.oneOf(['asc', 'desc'])
    }),
    sortable: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

HeaderCell.defaultProps = {
    align: 'left',
    onSortChange: f => f,
    resizable: false
};

HeaderCell.displayName = 'HeaderCell';

export default HeaderCell;
