import { composeDecorators } from 'draft-js-plugins-editor';
import createFocusPlugin from 'draft-js-focus-plugin';

import {
    createHeadlinesPlugin,
    createHorizontalLinePlugin,
    createImagePlugin,
    createParagraphsPlugin,
    createRemovalCommandsPlugin
} from '.';
import { wysiwygPlugins } from '../constants';

const getPlugins = (disabledPlugins = []) => {
    const plugins = [];

    if (!disabledPlugins.includes(wysiwygPlugins.headlines)) {
        plugins.push(createHeadlinesPlugin());
    }
    if (!disabledPlugins.includes(wysiwygPlugins.horizontalLine)) {
        plugins.push(createHorizontalLinePlugin());
    }
    if (!disabledPlugins.includes(wysiwygPlugins.focus)) {
        plugins.push(
            createFocusPlugin({
                theme: { focused: 'onsolve-wysiwyg-editor__image-block--focused' }
            })
        );
    }
    if (!disabledPlugins.includes(wysiwygPlugins.image)) {
        plugins.push(
            createImagePlugin({
                decorator: disabledPlugins.includes(wysiwygPlugins.focus)
                    ? composeDecorators(plugins[plugins.length - 1].decorator)
                    : null
            })
        );
    }
    if (!disabledPlugins.includes(wysiwygPlugins.paragraphs)) {
        plugins.push(createParagraphsPlugin());
    }
    if (!disabledPlugins.includes(wysiwygPlugins.removalCommands)) {
        plugins.push(createRemovalCommandsPlugin());
    }

    return plugins;
};

export default getPlugins;
