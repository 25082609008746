import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InputFieldControl, InputFieldGroup, InputFieldLabel } from '../InputBase';

const LabelField = ({ className, valueClassName, label, value, truncate }) => {
    const valueClassNames = useMemo(
        () =>
            classNames('onsolve-label-field__input', valueClassName, {
                'text-truncate': truncate
            }),
        [truncate, valueClassName]
    );

    return (
        <InputFieldControl className={className}>
            <InputFieldGroup variant="naked">
                {label && <InputFieldLabel>{label}</InputFieldLabel>}
                <span className={valueClassNames}>{value}</span>
            </InputFieldGroup>
        </InputFieldControl>
    );
};

LabelField.propTypes = {
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies a label for the component.
    */
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /**
    Specifies whether to truncate value when there are no space enough or not.
    */
    truncate: PropTypes.bool,
    /**
    Specifies the value that should be displayed.
    */
    value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /**
    Override or extend the styles applied to the value.
    */
    valueClassName: PropTypes.string
};

LabelField.defaultProps = {
    truncate: false
};

LabelField.displayName = 'LabelField';

export default LabelField;
