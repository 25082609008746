import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const refUpdater = ({ checked }) => input => {
    if (input) {
        input.checked = checked;
    }
};

class RadioButton extends PureComponent {
    render() {
        const { name, checked, onChange, disabled, readOnly, ...rest } = this.props;

        const isDisabled = disabled || readOnly;

        return (
            <input
                type="radio"
                name={name}
                ref={refUpdater({ checked })}
                onChange={onChange}
                disabled={isDisabled}
                {...rest}
            />
        );
    }
}

RadioButton.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

RadioButton.displayName = 'RadioButton';

export default RadioButton;
