import { createLinkDecorator, createScriptDecorator } from '.';
import { wysiwygDecorators } from '../constants';

const getDecorators = (disabledDecorators = []) => {
    const decorators = [];

    if (!disabledDecorators.includes(wysiwygDecorators.link)) {
        decorators.push(createLinkDecorator());
    }

    if (!disabledDecorators.includes(wysiwygDecorators.script)) {
        decorators.push(createScriptDecorator());
    }

    return decorators;
};

export default getDecorators;
