import React from 'react';
import PropTypes from 'prop-types';

import { fp as _ } from '../utils';
import PieChartSegment from './PieChartSegment';

const PieChartWrapper = ({ data, title, strokeWidth }) => {
    const circleProps = {
        cx: '21',
        cy: '21',
        r: '15.91549430918954'
    };

    const calculateOffset = index => {
        const INITIAL_OFFSET = 25;

        return index === 0 ? INITIAL_OFFSET : 100 - _.sumBy(_.slice(data, 0, index), 'value') + INITIAL_OFFSET;
    };

    return (
        <div className="onsolve-pie-chart">
            <div className="onsolve-pie-chart__title">{title}</div>
            <svg width="100%" height="100%" viewBox="0 0 42 42">
                <circle {...circleProps} fill="#fff" />
                <circle
                    {...circleProps}
                    className="onsolve-pie-chart__segment--very-light-gray"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                />

                {_.map(data, (item, index) => (
                    <PieChartSegment
                        {...item}
                        strokeWidth={strokeWidth}
                        key={item.uuid}
                        calculateOffset={calculateOffset}
                        segmentProps={circleProps}
                        index={index}
                    />
                ))}
            </svg>
        </div>
    );
};

PieChartWrapper.defaultProps = {
    strokeWidth: 3
};

PieChartWrapper.propTypes = {
    /**
    Array of data used to determine color and size to fill chart sector.
    */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            uuid: PropTypes.string.isRequired
        })
    ).isRequired,
    /**
    Parameter used to define chart width.
    */
    strokeWidth: PropTypes.number,
    /**
    Title displayed whithin the chart.
    */
    title: PropTypes.node.isRequired
};

PieChartWrapper.displayName = 'PieChart';

export default PieChartWrapper;
