import React from 'react';
import SvgIcon from './SvgIcon';

const SMS = props => (
    <SvgIcon {...props}>
        <g id="mobile-chat" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M4.4691358,1.89017341 C4.64641239,1.89017341 4.79012346,2.03509858 4.79012346,2.21387283 C4.79012346,2.36710791 4.68453982,2.49547427 4.54273533,2.52902312 L4.4691358,2.53757225 L2.7037037,2.53757225 C2.42449308,2.53757225 2.19286158,2.74300368 2.14932738,3.01216122 L2.14197531,3.10404624 L2.14197531,13.7861272 C2.14197531,14.0676966 2.34568575,14.301285 2.61258844,14.345187 L2.7037037,14.3526012 L9.7654321,14.3526012 C10.0446427,14.3526012 10.2762742,14.1471697 10.3198084,13.8780122 L10.3271605,13.7861272 L10.3271605,8.44508671 C10.3271605,8.26631245 10.4708716,8.12138728 10.6481481,8.12138728 C10.8000995,8.12138728 10.9273905,8.22786292 10.9606583,8.3708654 L10.9691358,8.44508671 L10.9691358,13.7861272 C10.9691358,14.4146304 10.4954787,14.9315705 9.88850381,14.9937329 L9.7654321,15 L2.7037037,15 C2.0804657,15 1.56785625,14.5223414 1.50621459,13.9102386 L1.5,13.7861272 L1.5,3.10404624 C1.5,2.47554301 1.97365708,1.95860292 2.58063199,1.8964405 L2.7037037,1.89017341 L4.4691358,1.89017341 Z M13.2962963,1 C13.9610835,1 14.5,1.54346938 14.5,2.21387283 L14.5,2.21387283 L14.5,5.77456647 C14.5,6.44496993 13.9610835,6.98843931 13.2962963,6.98843931 L13.2962963,6.98843931 L8.98006702,6.98774566 L6.41261982,8.7144209 C6.22063734,8.84349049 5.97000127,8.73148928 5.92179388,8.5193247 L5.92179388,8.5193247 L5.91358025,8.44508671 L5.91358025,2.21387283 C5.91358025,1.54346938 6.45249675,1 7.11728395,1 L7.11728395,1 Z M13.2962963,1.64739884 L7.11728395,1.64739884 C6.80704992,1.64739884 6.55555556,1.90101789 6.55555556,2.21387283 L6.55555556,2.21387283 L6.55486772,7.84027746 L8.70466413,6.39540569 L8.88271605,6.34104046 L13.2962963,6.34104046 C13.6065303,6.34104046 13.8580247,6.08742142 13.8580247,5.77456647 L13.8580247,5.77456647 L13.8580247,2.21387283 C13.8580247,1.90101789 13.6065303,1.64739884 13.2962963,1.64739884 L13.2962963,1.64739884 Z"
                id="Mask"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

SMS.displayName = 'SMSIcon';

export default SMS;
