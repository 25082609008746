import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EventsUtils, ReactUtils } from '../../utils';

class ColumnResizer extends Component {
    constructor(props) {
        super(props);

        this.elementRef = React.createRef();

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    handleMouseDown() {
        EventsUtils.bind(document, 'mousemove', this.handleMouseMove);
        EventsUtils.bind(document, 'mouseup', this.handleMouseUp);
    }

    handleMouseUp(e) {
        EventsUtils.unbind(document, 'mousemove', this.handleMouseMove);
        EventsUtils.unbind(document, 'mouseup', this.handleMouseUp);

        this.resize(e);
    }

    handleMouseMove(e) {
        this.resize(e, true);
    }

    resize(e, move = false) {
        const { onResize } = this.props;
        const { current: element } = this.elementRef;

        if (onResize) {
            onResize(e, { element, move });
        }
    }

    render() {
        return (
            <span
                ref={ref => ReactUtils.setRef(this.elementRef, ref)}
                className="onsolve-table__header-cell--resizer"
                onMouseDown={this.handleMouseDown}
            />
        );
    }
}

ColumnResizer.propTypes = {
    onRelease: PropTypes.func,
    onResize: PropTypes.func
};

ColumnResizer.displayName = 'ColumnResizer';

export default ColumnResizer;
