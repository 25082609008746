import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { RichUtils } from 'draft-js';
import classNames from 'classnames';

import { keyCodes } from '../../common/constants';
import { fp as _, DomUtils } from '../utils';
import { Typography } from '../Typography';

import {
    AlignmentControls,
    BlockStyleControls,
    InlineStyleControls,
    LinkStyleControls,
    StylesDropdownControl,
    StyleButton,
    SizesDropdownControl,
    FontColorControls,
    EraseControls
} from './components';
import { FONT_SIZES, NODE_TYPES, CUSTOM_STYLE_PREFIX_COLOR, COLOR_MODELS } from './constants';
import {
    addHorizontalRule,
    eraseStylesRule,
    addLink,
    addScript,
    removeEntityAtCursorPosition,
    setBlockData,
    setIndent,
    setInlineStyle,
    setFontColor,
    getEntityAtCursorPosition
} from './utils';

import { FONT_SIZE_UNITS } from './constants/fontSizes';

const identityFn = value => value;

const separatorFactory = index => ({
    focusable: false,
    focusableRef: null,

    // eslint-disable-next-line react/display-name
    render: () => <div key={`separator-${index}`} className={`onsolve-wysiwyg-editor__space-block`} />
});

const paragraphStyleTool = props => ({
    focusable: true,
    focusableRef: ref => ref.current.anchorEl,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = blockType => onChange(RichUtils.toggleBlockType(editorState, blockType));

        const paragraphStyles = [
            { id: NODE_TYPES.h1.style, value: 'Heading 1', translate: NODE_TYPES.h1.translate, intl },
            { id: NODE_TYPES.h2.style, value: 'Heading 2', translate: NODE_TYPES.h2.translate, intl },
            { id: NODE_TYPES.h3.style, value: 'Heading 3', translate: NODE_TYPES.h3.translate, intl },
            { id: NODE_TYPES.h4.style, value: 'Heading 4', translate: NODE_TYPES.h4.translate, intl },
            { id: NODE_TYPES.h5.style, value: 'Heading 5', translate: NODE_TYPES.h5.translate, intl },
            { id: NODE_TYPES.h6.style, value: 'Heading 6', translate: NODE_TYPES.h6.translate, intl },
            {
                id: NODE_TYPES.paragraph.style,
                value: 'Paragraph',
                translate: NODE_TYPES.paragraph.translate,
                intl
            }
        ];

        return (
            <StylesDropdownControl
                key="paragraphStyle"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                list={paragraphStyles}
                ref={ref}
                {...props}
            />
        );
    }
});

const fontSizeTool = props => ({
    focusable: true,
    focusableRef: ref => ref.current.anchorEl,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = fontSize => onChange(setInlineStyle(editorState, fontSize, FONT_SIZES));
        const fontSizes = _.map(FONT_SIZES, ({ fontSize }, key) => {
            let fontSizeFormatted = fontSize.slice(0, -FONT_SIZE_UNITS.length) + ' ' + FONT_SIZE_UNITS;

            return {
                id: key,
                value: <Typography style={{ fontSize }}>{fontSizeFormatted}</Typography>
            };
        });

        return (
            <SizesDropdownControl
                key="fontSize"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                list={fontSizes}
                ref={ref}
                {...props}
            />
        );
    }
});

const fontColorTool = ({ isColorPickerOpen, toggleColorPicker, ...other }) => ({
    focusable: true,
    focusableRef: ref => ref.current.anchorEl,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        let color = '#000000';

        const handleToggle = _.debounce(({ hex, source }) => {
            color = hex;
            onChange(setFontColor(editorState, hex));
            if (source.toUpperCase() === COLOR_MODELS.HSV) {
                toggleColorPicker();
            }
        });

        if (editorState) {
            const inlineStyles = editorState.getCurrentInlineStyle();

            for (const style of inlineStyles) {
                if (typeof style === 'string' && style.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
                    color = style.replace(CUSTOM_STYLE_PREFIX_COLOR, '');
                    break;
                }
            }
        }

        return (
            <FontColorControls
                key="fontColor"
                color={color}
                isColorPickerOpen={isColorPickerOpen}
                onColorChange={handleToggle}
                onToggleColorPicker={toggleColorPicker}
                ref={ref}
                {...other}
            />
        );
    }
});

const inlineStyleToolFactory = (nodeType, disabled) => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = inlineStyle => onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));

        return (
            <InlineStyleControls
                key={`inlineStyle-${nodeType.style}`}
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                disabled={disabled}
                {...nodeType}
            />
        );
    }
});

const scriptTypeToolFactory = (scriptType, disabled) => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const entity = editorState && getEntityAtCursorPosition(editorState);
        const isActive = entity && entity.type === scriptType.style;
        const className = classNames({ 'mt-n1': scriptType.style === NODE_TYPES.superscript.style });

        const handleToggle = () => {
            if (isActive) {
                onChange(removeEntityAtCursorPosition(editorState));
            } else {
                onChange(addScript(editorState, scriptType));
            }
        };

        return (
            <StyleButton
                className={className}
                key={`inlineStyle-${scriptType.style}`}
                isActive={isActive}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                disabled={disabled}
                {...scriptType}
            />
        );
    }
});

const alignmentToolFactory = (nodeType, disabled) => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = alignmentValue => onChange(setBlockData(editorState, { 'text-align': alignmentValue }));

        return (
            <AlignmentControls
                key={`alignment-${nodeType.style}`}
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                disabled={disabled}
                {...nodeType}
            />
        );
    }
});

const indentToolFactory = (nodeType, disabled) => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = indentValue => onChange(setIndent(editorState, indentValue));

        return (
            <StyleButton
                key={`indent-${nodeType.style}`}
                isActive={false}
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                disabled={disabled}
                {...nodeType}
            />
        );
    }
});

const blockStyleToolFactory = (nodeType, disabled) => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = (blockType, isActive) => {
            const nextBlockType = isActive ? NODE_TYPES.paragraph.style : blockType;

            onChange(RichUtils.toggleBlockType(editorState, nextBlockType));
        };

        return (
            <BlockStyleControls
                key={`blockStyle-${nodeType.style}`}
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                disabled={disabled}
                {...nodeType}
            />
        );
    }
});

const insertRuleTool = props => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = () => onChange(addHorizontalRule(editorState));

        return (
            <BlockStyleControls
                key="insertRule"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                {...props}
                {...NODE_TYPES.horizontalRule}
            />
        );
    }
});

const insertLinkTool = props => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = ({ isActive, displayText, targetUrl, linkType, openLinkInSeparateWindow }) => {
            if (isActive) {
                onChange(removeEntityAtCursorPosition(editorState));
            } else {
                onChange(
                    addLink({
                        editorState,
                        urlEnteredText: displayText,
                        urlValue: targetUrl,
                        linkType,
                        openLinkInSeparateWindow
                    })
                );
            }
        };

        return (
            <LinkStyleControls
                key="insertLink"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                {...props}
                {...NODE_TYPES.link}
            />
        );
    }
});

const insertEraseStylesTool = props => ({
    focusable: true,
    focusableRef: identityFn,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = () => onChange(eraseStylesRule(editorState));

        return (
            <EraseControls
                key="eraseStylesRule"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                ref={ref}
                {...props}
                {...NODE_TYPES.eraseStyles}
            />
        );
    }
});

const focusableRefOf = toolbarItem => toolbarItem.tool.focusableRef(toolbarItem.ref);

const isFocused = toolbarItem => {
    const focusableRef = focusableRefOf(toolbarItem);

    return focusableRef.current.matches(':focus');
};

const focus = toolbarItem => {
    const focusableRef = focusableRefOf(toolbarItem);

    focusableRef.current.focus();
};

const FOCUS_DIRECTION = {
    PREV: 'prev',
    NEXT: 'next',
    FIRST: 'first',
    LAST: 'last'
};

const keyCodeToFocusDirection = charCode => {
    const { LEFT, RIGHT, HOME, END } = keyCodes;

    if (charCode === LEFT) {
        return FOCUS_DIRECTION.PREV;
    } else if (charCode === RIGHT) {
        return FOCUS_DIRECTION.NEXT;
    } else if (charCode === HOME) {
        return FOCUS_DIRECTION.FIRST;
    } else if (charCode === END) {
        return FOCUS_DIRECTION.LAST;
    } else {
        return null;
    }
};

const nextFocusIndex = (direction, currentIndex, count) => {
    if (direction === FOCUS_DIRECTION.FIRST) {
        return 0;
    } else if (direction === FOCUS_DIRECTION.LAST) {
        return count - 1;
    }

    let nextIndex = Math.max(currentIndex, 0);

    if (direction === FOCUS_DIRECTION.NEXT) {
        nextIndex += 1;
    } else if (direction === FOCUS_DIRECTION.PREV) {
        nextIndex -= 1;
    }

    if (nextIndex < 0) {
        return count - 1;
    } else if (nextIndex > count - 1) {
        return 0;
    } else {
        return nextIndex;
    }
};

const moveFocus = (toolbarItems, direction) => {
    const currentIndex = toolbarItems.findIndex(isFocused);
    const nextIndex = nextFocusIndex(direction, currentIndex, toolbarItems.length);

    focus(toolbarItems[nextIndex]);
};

const WysiwygToolbar = ({
    children,
    editorState,
    enableFontColorPicker,
    enableFontSizeMenu,
    enableStrikethrough,
    enableIndent,
    disabled,
    onChange
}) => {
    const [isColorPickerOpen, setColorPickerOpen] = useState(false);

    const toggleColorPicker = () => setColorPickerOpen(!isColorPickerOpen);

    let tools = [
        paragraphStyleTool({ disabled }),
        enableFontSizeMenu && fontSizeTool({ disabled }),
        separatorFactory(1),

        enableFontColorPicker && fontColorTool({ isColorPickerOpen, toggleColorPicker, disabled }),
        inlineStyleToolFactory(NODE_TYPES.bold, disabled),
        inlineStyleToolFactory(NODE_TYPES.underline, disabled),
        inlineStyleToolFactory(NODE_TYPES.italic, disabled),
        enableStrikethrough && inlineStyleToolFactory(NODE_TYPES.strikeThrough, disabled),
        separatorFactory(2),

        scriptTypeToolFactory(NODE_TYPES.subscript, disabled),
        scriptTypeToolFactory(NODE_TYPES.superscript, disabled),
        separatorFactory(3),

        alignmentToolFactory(NODE_TYPES.alignLeft, disabled),
        alignmentToolFactory(NODE_TYPES.alignCenter, disabled),
        alignmentToolFactory(NODE_TYPES.alignRight, disabled),
        separatorFactory(4),

        enableIndent && indentToolFactory(NODE_TYPES.indentIncrease, disabled),
        enableIndent && indentToolFactory(NODE_TYPES.indentDecrease, disabled),
        separatorFactory(5),

        blockStyleToolFactory(NODE_TYPES.ul, disabled),
        blockStyleToolFactory(NODE_TYPES.ol, disabled),
        separatorFactory(6),

        insertRuleTool({ disabled }),
        insertLinkTool({ disabled }),
        separatorFactory(7),

        insertEraseStylesTool({ disabled }),
        ...children
    ];

    tools = tools.filter(t => !!t);

    // We cannot use useRef inside map, so we have to use map inside useRef
    // https://stackoverflow.com/a/55105849
    const refs = useRef(
        tools.map(tool => {
            return tool.focusable ? createRef() : null;
        })
    );

    const intl = useIntl();

    const toolbarItems = tools.map((tool, index) => {
        const ref = refs.current[index];
        const component = tool.render(editorState, intl, onChange, ref);

        return { component, ref, tool };
    });

    const focusableToolbarItems = toolbarItems.filter(i => !!i.ref);

    const handleKeyDown = e => {
        const focusDirection = keyCodeToFocusDirection(DomUtils.getCharCode(e));

        if (focusDirection) {
            e.preventDefault();
            moveFocus(focusableToolbarItems, focusDirection);
        }
    };

    return (
        <div className="onsolve-wysiwyg-editor__tool-bar" role="toolbar" onKeyDown={handleKeyDown}>
            {toolbarItems.map(i => i.component)}
        </div>
    );
};

WysiwygToolbar.propTypes = {
    /**
     The content of the component.
     */
    children: PropTypes.arrayOf(PropTypes.object),
    /**
     Specifies that all tools are disabled.
     */
    disabled: PropTypes.bool,
    /**
     Entered value of the component. Important: Support only specific converted object using exported function `convertJsonToWysiwygValue` (to convert to json object use: convertWysiwygValueToJson) or null.
     */
    editorState: PropTypes.object,
    /**
     Specifies that toolbar view color font style button.
     */
    enableFontColorPicker: PropTypes.bool,
    /**
     Specifies that toolbar view font size button.
     */
    enableFontSizeMenu: PropTypes.bool,
    /**
     Specifies that toolbar view indent buttons.
     */
    enableIndent: PropTypes.bool,
    /**
     Specifies that toolbar view strike-through font style button.
     */
    enableStrikethrough: PropTypes.bool,
    /**
     Callback function on change event of text input field.
     */
    onChange: PropTypes.func,
    /**
     Applied css classes to the toolbar panel.
     */
    styles: PropTypes.object
};

WysiwygToolbar.defaultProps = {
    children: [],
    enableFontColorPicker: false,
    enableFontSizeMenu: false,
    enableStrikethrough: false
};

WysiwygToolbar.displayName = 'WysiwygToolbar';

export default WysiwygToolbar;
