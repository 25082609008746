import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import {
    InputFieldContainer,
    InputFieldControl,
    InputFieldGroup,
    InputFieldLabel,
    InputFieldMessage
} from '../../InputBase';
import { DateTimeUtils } from '../../utils';
import { TimeInputText } from '../core';
import { Meridiem } from '../core/TimeInputs';
import { componentsTranslations } from '../../translations';

class TimeField extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.value && !this.props.value) {
            this.setState(DateTimeUtils.getTimeInitialValue(this.props.value));
        }
    }

    state = {
        active: false,
        ...DateTimeUtils.getTimeInitialValue(this.props.value)
    };

    handleChange = (e, value) => {
        const { name } = e.target;

        this.setState({ [name]: value }, this.formatTime);
    };

    handleMiddayChange = value => {
        this.setState({ midday: value }, this.formatTime);
    };

    handleFocus = () => {
        this.setState({ active: true });
    };

    handleBlur = () => {
        this.setState({ active: false });
    };

    formatTime = () => {
        const { midday, hours, minutes } = this.state;
        const { onChange } = this.props;

        if (onChange) {
            //TODO use Date object instead of string
            let time;

            if (midday || hours || minutes) {
                time = `${hours}:${minutes} ${midday}`;
            }

            onChange(time);
        }
    };

    render() {
        const {
            className,
            divider,
            placeholder,
            disabled,
            label,
            tabIndex,
            variant,
            error,
            showLabelExclamation,
            innerRef,
            intl,
            ...other
        } = this.props;
        const { active, hours, minutes, midday } = this.state;

        const inputProps = {
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur
        };

        return (
            <div className={classNames('onsolve-time-input', className)}>
                <InputFieldControl className="onsolve-time-input__container">
                    <InputFieldGroup>
                        {label && <InputFieldLabel name={name}>{label}</InputFieldLabel>}
                        <div className="d-flex">
                            <InputFieldContainer
                                className="onsolve-time-input-text__container w-100"
                                tabIndex={tabIndex}
                                variant={variant}
                                error={error}
                                active={active}
                                showExclamation={!showLabelExclamation}
                                ref={innerRef}
                            >
                                <TimeInputText
                                    hours={hours}
                                    minutes={minutes}
                                    midday={midday}
                                    placeholder={placeholder}
                                    divider={divider}
                                    {...inputProps}
                                    disabled={disabled}
                                />
                            </InputFieldContainer>
                            <Meridiem
                                {...other}
                                name="midday"
                                aria-label={intl.formatMessage(componentsTranslations.ng_components_midday)}
                                value={midday}
                                placeholder={placeholder.midday}
                                disabled={disabled}
                                {...inputProps}
                                onChange={this.handleMiddayChange}
                            />
                        </div>
                    </InputFieldGroup>
                    <InputFieldMessage className="onsolve-time-input__error" error={error} {...other} />
                </InputFieldControl>
            </div>
        );
    }
}

TimeField.propTypes = {
    /**
     Applied css classes to the text input field component.
     */
    className: PropTypes.string,
    /**
     Specifies if the input should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     Specifies the character as a divider between hours, minutes and seconds.
     */
    divider: PropTypes.node,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     * @ignore
     */
    innerRef: PropTypes.object,
    /**
     * @ignore
     */
    intl: PropTypes.object,
    /**
     The short text to inform about the type of input value.
     */
    label: PropTypes.node,
    /**
     Specifies input name
     */
    name: PropTypes.string,
    /**
     Callback function on blur event of text input field.
     */
    onBlur: PropTypes.func,
    /**
     Callback function on change event of text input field.
     */
    onChange: PropTypes.func,
    /**
     Callback function on focus event of text input field.
     */
    onFocus: PropTypes.func,
    /**
     The short hint displayed in the input before the user enters a value.
     */
    placeholder: PropTypes.shape({ hours: PropTypes.string, minutes: PropTypes.string, midday: PropTypes.string }),
    /**
     Specifies whether to show exclamation icon at the end of label text.
     */
    showLabelExclamation: PropTypes.bool,
    /**
     * @ignore
     */
    tabIndex: PropTypes.number,
    /**
     Entered value of the component.
     */
    value: PropTypes.string,
    /**
     Specifies one of variant of text field appearance.
     */
    variant: PropTypes.oneOf(['default', 'standard', 'naked', 'outlined'])
};

TimeField.defaultProps = {
    divider: ':',
    placeholder: { hours: '12', minutes: '00', midday: 'AM' },
    disabled: false
};

TimeField.displayName = 'TimeField';

export default injectIntl(TimeField);
