import React from 'react';
import SvgIcon from './SvgIcon';

const ChartGrowth = props => (
    <SvgIcon {...props}>
        <path
            d="M7.588 1l4.118 5.25h-2.47v7.875c0 .525-.33.875-.824.875H6.765c-.494 0-.824-.35-.824-.875V6.25h-2.47L7.588 1zm-4.117 9.625c.494 0 .823.35.823.875v2.625c0 .525-.33.875-.823.875H1.824C1.329 15 1 14.65 1 14.125V11.5c0-.525.33-.875.824-.875zM13.353 8c.494 0 .823.35.823.875v5.25c0 .525-.329.875-.823.875h-1.647c-.494 0-.824-.35-.824-.875v-5.25c0-.525.33-.875.824-.875z"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        />
    </SvgIcon>
);

ChartGrowth.displayName = 'ChartGrowthIcon';

export default ChartGrowth;
