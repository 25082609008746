import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const styles = {
    h1: { fontSize: '20px' },
    h2: { fontSize: '16px' },
    h3: { fontSize: '14px' },
    h4: { fontSize: '12px' },
    h5: { fontSize: '11px' },
    h6: { fontSize: '11px', fontStyle: 'italic' }
};

const HeaderElement = ({ children, variant }) => {
    return [Children.map(children, child => cloneElement(child, { style: styles[variant] }))];
};

HeaderElement.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
};

HeaderElement.defaultProps = {
    variant: 'h1'
};

HeaderElement.displayName = 'HeaderElement';

export default HeaderElement;
