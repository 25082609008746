import React from 'react';

import SvgIcon from './SvgIcon';

const CrossHair = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M7.58333333,0 L7.58333333,1.19641667 C10.3349167,1.47058333 12.5294167,3.66508333 12.8035833,6.41666667 L12.8035833,6.41666667 L14,6.41666667 L14,7.58333333 L12.8035833,7.58333333 C12.5294167,10.3349167 10.3349167,12.5288333 7.58333333,12.8035833 L7.58333333,12.8035833 L7.58333333,14 L6.41666667,14 L6.41666667,12.8035833 C3.66508333,12.5294167 1.47116667,10.3349167 1.19641667,7.58333333 L1.19641667,7.58333333 L0,7.58333333 L0,6.41666667 L1.19641667,6.41666667 C1.47058333,3.66508333 3.66508333,1.47058333 6.41666667,1.19641667 L6.41666667,1.19641667 L6.41666667,0 L7.58333333,0 Z M7.58333333,2.37358333 L7.58333333,3.5 L6.41666667,3.5 L6.41666667,2.37358333 C4.30966667,2.63841667 2.63841667,4.30966667 2.37358333,6.41666667 L2.37358333,6.41666667 L3.5,6.41666667 L3.5,7.58333333 L2.37358333,7.58333333 C2.63841667,9.69033333 4.30966667,11.3615833 6.41666667,11.6264167 L6.41666667,11.6264167 L6.41666667,10.5 L7.58333333,10.5 L7.58333333,11.6264167 C9.69033333,11.3615833 11.3615833,9.69033333 11.6264167,7.58333333 L11.6264167,7.58333333 L10.5,7.58333333 L10.5,6.41666667 L11.6264167,6.41666667 C11.3615833,4.30966667 9.69033333,2.63783333 7.58333333,2.37358333 L7.58333333,2.37358333 Z M7,6 C7.55228475,6 8,6.44771525 8,7 C8,7.55228475 7.55228475,8 7,8 C6.44771525,8 6,7.55228475 6,7 C6,6.44771525 6.44771525,6 7,6 Z"
            />
        </SvgIcon>
    );
};

CrossHair.displayName = 'CrossHairIcon';

export default CrossHair;
