import React from 'react';

import SvgIcon from './SvgIcon';

const Schedule = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <path d="M13.688 3.188H2.312a.875.875 0 00-.874.874v9.625c0 .484.392.876.875.876h11.374a.875.875 0 00.876-.876V4.063a.875.875 0 00-.876-.875zM1.438 5.813h13.124M4.938 1.438v1.75M11.063 1.438v1.75M5.813 7.563v5.25M10.188 7.563v5.25M3.188 10.188h9.624" />
            </g>
        </SvgIcon>
    );
};

Schedule.displayName = 'ScheduleIcon';

export default Schedule;
