import React from 'react';
import SvgIcon from './SvgIcon';

const LockSignInFlow = props => (
    <SvgIcon {...props}>
        <g id="Sign-In-Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="10.3_L_Forgot-P_New-P_Mismatch" transform="translate(-608.000000, -175.000000)">
                <g
                    id="Forgot-password-icons-64px-icon/Forgot-password-icons"
                    transform="translate(608.000000, 175.000000)"
                >
                    <g id="Group">
                        <circle id="Oval-2" fillOpacity="0.1" fill="#1AB1E4" cx="32" cy="32" r="32" />
                        <g
                            id="48px_lock-open"
                            transform="translate(20.000000, 16.000000)"
                            stroke="#3777BC"
                            strokeWidth="2"
                        >
                            <path
                                d="M19,7.5 C19,3.375 15.625,0 11.5,0 L11.5,0 C7.375,0 4,3.375 4,7.5 L4,12"
                                id="Shape"
                            />
                            <circle id="Oval" strokeLinecap="square" cx="12" cy="20" r="3" />
                            <path d="M12,23 L12,26" id="Shape" strokeLinecap="square" />
                            <rect
                                id="Rectangle-path"
                                strokeLinecap="square"
                                x="0"
                                y="12"
                                width="23"
                                height="20"
                                rx="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

LockSignInFlow.displayName = 'LockSignInFlowIcon';

export default LockSignInFlow;
