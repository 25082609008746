import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Focusable } from '../Focusable';
import { ReactUtils } from '../utils';

class Button extends Component {
    constructor(props) {
        super(props);

        this.elementRef = React.createRef();

        this.handleClick = this.handleClick.bind(this);
    }

    getButtonColor(variant, color) {
        let className = 'btn';

        if (variant === 'outline') {
            className = `${className}-outline`;
        } else if (variant === 'flat') {
            className = `${className}-flat`;
        } else if (variant === 'link') {
            className = `${className}-link`;
        } else if (variant === 'gradient') {
            className = `${className}-gradient`;
        }

        return `${className}-${color}`;
    }

    handleClick(e) {
        const { disabled, onClick } = this.props;

        if (disabled) {
            e.preventDefault();
            return;
        }

        if (onClick) {
            onClick(e);
        }
    }

    componentDidMount() {
        const { autoFocus } = this.props;

        if (autoFocus) {
            setTimeout(() => {
                this.elementRef.current.focus();
            }, 0);
        }
    }

    render() {
        const {
            type,
            className,
            color,
            size,
            variant,
            active,
            disabled,
            innerRef,
            children,
            to,
            ...other
        } = this.props;
        const btnColor = this.getButtonColor(variant, color);
        const rootClasses = classes =>
            classNames(
                className,
                classes,
                'btn',
                btnColor,
                {
                    [`btn-${size}`]: size
                },
                {
                    active,
                    disabled
                }
            );

        if (to) {
            return (
                <Focusable
                    render={({ classes }) => (
                        <Link
                            to={to}
                            className={rootClasses(classes)}
                            disabled={disabled}
                            {...other}
                            onClick={this.handleClick}
                        >
                            {children}
                        </Link>
                    )}
                />
            );
        }

        return (
            <Focusable
                render={({ classes }) => (
                    <button
                        type={type}
                        ref={ReactUtils.setMultipleRefs(this.elementRef, innerRef)}
                        className={rootClasses(classes)}
                        disabled={disabled}
                        aria-disabled={disabled}
                        {...other}
                        onClick={this.handleClick}
                    >
                        {children}
                    </button>
                )}
            />
        );
    }
}

Button.propTypes = {
    /**
    Shows if the button should is active.
    */
    active: PropTypes.bool,
    /**
    Specifies a focus property.
    */
    autoFocus: PropTypes.bool,
    /**
    The content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component. `string`
    */
    className: PropTypes.string,
    /**
    Specifies a button color.
    */
    color: PropTypes.string,
    /**
    Specifies a button enabling.
    */
    disabled: PropTypes.bool,
    /**
     * @ignore
     */
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
    Callback function fired on click.
    */
    onClick: PropTypes.func,
    /**
    Specifies a button size.
    */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
    Specifies a redirect link.
    */
    to: PropTypes.string,
    /**
    Specifies a button type.
    */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    /**
    Specifies one of the variants of component.
    */
    variant: PropTypes.oneOf(['raised', 'outline', 'flat', 'link', 'gradient'])
};

Button.defaultProps = {
    color: 'secondary',
    type: 'button',
    variant: 'raised'
};

Button.displayName = 'Button';

export default Button;
