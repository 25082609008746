import React from 'react';
import SvgIcon from './SvgIcon';

const Home = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,0.3L4.6,3.5V2H2.6v3.4L0,7.6l0.9,1l0.9-0.8V15c0,0.4,0.3,0.7,0.7,0.7h4.1v-4.1h2.7v4.1h4.1 c0.4,0,0.7-0.3,0.7-0.7V7.8l0.9,0.8l0.9-1L8,0.3z M9.4,8.8H6.7V6.1h2.7V8.8z"
        />
    </SvgIcon>
);

Home.displayName = 'HomeIcon';

export default Home;
