import React from 'react';
import PropTypes from 'prop-types';

const Step = () => <></>;

Step.propTypes = {
    /**
     * Specifies whether a step is completed and the stepper can change active step.
     */
    completed: PropTypes.bool,
    /**
     * Icon that will be rendered when the current step is completed.
     */
    completedIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Component that will be rendered when the current step is active.
     */
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    /**
     * Specifies whether a step is disabled or not.
     */
    disabled: PropTypes.bool,
    /**
     * Specifies a label for the step.
     */
    title: PropTypes.node.isRequired
};

Step.displayName = 'Step';

export default Step;
