const getDisplayValue = (value, multiple = false) => {
    return multiple ? value.join(', ') : value;
};

const getSelectedValues = (value, acc) => {
    let values = Array.isArray(acc) ? [...acc] : acc ? [acc] : [];
    const itemIndex = values.indexOf(value);

    if (itemIndex === -1) {
        values.push(value);
    } else {
        values.splice(itemIndex, 1);
    }
    return values;
};

const getTextFromItem = (item, key) => {
    return typeof item === 'object' ? item[key] : item;
};

const getDataKeyItem = (item, key) => {
    return typeof item === 'object' ? item[key] : item;
};

export { getDisplayValue, getSelectedValues, getTextFromItem, getDataKeyItem };
