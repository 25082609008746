import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardHeader = React.forwardRef((props, ref) => {
    const {
        actions,
        avatar,
        background,
        className,
        classes,
        color,
        component: Component = 'div',
        subTitle,
        title,
        transparent,
        ...other
    } = props;

    const headerClass = classnames(
        'onsolve-card__header',
        {
            [`bg-${background}`]: background,
            'text-white': !!background,
            'bg-transparent': transparent
        },
        classes.root,
        className,
        color
    );

    return (
        <Component className={headerClass} ref={ref} {...other}>
            {avatar && <div className={classes.avatar}>{avatar}</div>}
            <div className={classes.content}>
                {title}
                {subTitle}
            </div>
            {actions && <div className={classes.actions}>{actions}</div>}
        </Component>
    );
});

CardHeader.propTypes = {
    /**
     Specifies controls buttons, such `ok`, 'cancel`.
     */
    actions: PropTypes.node,
    /**
     Specifies an avatar that could be an icon, e.g. Edit Icon.
     */
    avatar: PropTypes.element,
    /**
     Specifies that a color of background.
     */
    background: PropTypes.string,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Override or extend the styles applied to the sub components.
     */
    classes: PropTypes.object,
    /**
     `color` defines a class that will be passed to headerClass.
     */
    color: PropTypes.string,
    /**
     Specifies a tag of component that wraps the header's content and will be returned by CardHeader.
     */
    component: PropTypes.elementType,
    /**
     Specifies a content of sub-title.
     */
    subTitle: PropTypes.node,
    /**
     Specifies a content of title.
     */
    title: PropTypes.node,
    /**
     Specifies a transparency of CardHeader.
     */
    transparent: PropTypes.bool
};

CardHeader.defaultProps = {
    classes: {}
};

CardHeader.displayName = 'CardHeader';

export default CardHeader;
