import React from 'react';
import SvgIcon from './SvgIcon';

const Send = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M13.744.256a.875.875 0 00-.913-.204L.581 4.427a.874.874 0 00-.097 1.605l4.013 2.006 5.128-3.663-3.664 5.13 2.006 4.012c.15.297.453.483.783.483.018 0 .036 0 .053-.002a.875.875 0 00.772-.579l4.375-12.25a.877.877 0 00-.206-.913z"
        />
    </SvgIcon>
);

Send.displayName = 'SendIcon';

export default Send;
