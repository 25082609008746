import React from 'react';
import SvgIcon from './SvgIcon';

const NestGroup = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    d="M8,16c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C16,12.4,12.4,16,8,16z M8,0.6C3.9,0.6,0.6,3.9,0.6,8
    c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C15.4,3.9,12.1,0.6,8,0.6z"
                />
                <ellipse cx="6" cy="6.7" rx="1.2" ry="1.2" />
                <ellipse cx="10.8" cy="6.7" rx="0.5" ry="0.5" />
                <ellipse cx="8.8" cy="6.7" rx="0.8" ry="0.8" />
                <ellipse cx="6" cy="10" rx="1.2" ry="1.2" />
                <ellipse cx="10.8" cy="10" rx="0.5" ry="0.5" />
                <ellipse cx="8.8" cy="10" rx="0.8" ry="0.8" />
            </g>
        </SvgIcon>
    );
};

NestGroup.displayName = 'NestGroupIcon';

export default NestGroup;
