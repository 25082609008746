import React from 'react';
import SvgIcon from './SvgIcon';

const LeftArrow = props => (
    <SvgIcon {...props}>
        <path d="M10.5 3.5l-5 5 5 5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
);

LeftArrow.displayName = 'LeftArrowIcon';

export default LeftArrow;
