import React from 'react';
import SvgIcon from './SvgIcon';

const Outdent = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M15 12.5v1.75H1V12.5h14zm-14-7l3.5 2.625L1 10.75V5.5zM15 9v1.75H6.25V9H15zm0-3.5v1.75H6.25V5.5H15zM15 2v1.75H1V2h14z"
        />
    </SvgIcon>
);

Outdent.displayName = 'OutdentIcon';

export default Outdent;
