import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { CheckBox } from '../../Selections';
import { HeaderColumnMenu, ColumnMenuSelect } from '../ColumnMenu';
import { SelectionMode } from '../utils';

import { componentsTranslations } from '../../../components/translations';

import { keyCodes } from '../../../common/constants';
import { DomUtils } from '../../utils';

class HeaderSelectionCell extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
        this.anchorRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    handleKeyUp = index => {
        const { onKeyUp } = this.props;

        return e => onKeyUp(e, index);
    };

    get headerSelectionCellRender() {
        const { intl, render, index, ...other } = this.props;

        if (render) {
            return render({ ...other });
        }

        const { selectionValue, disabled } = this.props;

        return (
            <div className="onsolve-table__header-cell--selection" onKeyUp={this.handleKeyUp(index)}>
                <CheckBox
                    checked={!!selectionValue}
                    intermediate={selectionValue === SelectionMode.Partial}
                    disabled={disabled}
                    onChange={this.handleClick}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_select_all)}
                />
                {this.renderSelectModeColumnMenu()}
            </div>
        );
    }

    componentDidMount() {
        const { setRef, index } = this.props;

        setRef(index, this.anchorRef);
    }

    handleMenuClick = value => e => {
        this.handleToggleClick();
        this.handleClick(e, { checked: value });
    };

    handleMenuKeyDown = value => e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ENTER_KEY) {
            e.preventDefault();

            this.handleToggleClick();
            this.handleClick(e, { checked: value });
        }
    };

    handleToggleClick() {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    handleClick(e, { checked }) {
        const { onSelectionChange } = this.props;

        if (onSelectionChange) {
            onSelectionChange(e, +checked);
        }
    }

    renderSelectModeColumnMenu() {
        const { disabled, contextMenu, intl } = this.props;
        const { isOpen } = this.state;
        const menuProps = {
            onMenuClick: this.handleMenuClick,
            onMenuKeyDown: this.handleMenuKeyDown
        };
        const ContextMenu = contextMenu || ColumnMenuSelect;

        return (
            <HeaderColumnMenu
                isOpen={isOpen}
                anchorEl={this.anchorRef.current}
                disabled={disabled}
                onToggleClick={this.handleToggleClick}
                aria-label={intl.formatMessage(componentsTranslations.ng_components_select_rows)}
            >
                <ContextMenu {...menuProps} />
            </HeaderColumnMenu>
        );
    }

    render() {
        const headerSelectionCell = this.headerSelectionCellRender;

        return (
            <th className="onsolve-table__header-cell" ref={this.anchorRef} role="columnheader">
                {headerSelectionCell}
            </th>
        );
    }
}

HeaderSelectionCell.propTypes = {
    contextMenu: PropTypes.element,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    intl: PropTypes.object,
    onKeyUp: PropTypes.func,
    onSelectionChange: PropTypes.func,
    render: PropTypes.func,
    selectionValue: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf([SelectionMode.None, SelectionMode.Partial, SelectionMode.All])
    ]),
    setRef: PropTypes.func
};

HeaderSelectionCell.defaultProps = {
    selectionValue: false,
    onSelectionChange: f => f
};

HeaderSelectionCell.displayName = 'HeaderSelectionCell';

export default injectIntl(HeaderSelectionCell);
