import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { componentsTranslations } from '../translations';

import NoResults from './NoResults';
import NoResultsImage from './NoResultsImage';

export const defaultProps = {
    image: <NoResultsImage />,
    description: <FormattedMessage {...componentsTranslations.ng_components_noResultsDescription} />,
    title: <FormattedMessage {...componentsTranslations.ng_components_noResultsTitle} />
};

const withNoResults = (props = defaultProps) => WrappedComponent => {
    class WithNoResults extends Component {
        renderNoResult = () => {
            const { title, description, image } = props;

            return <NoResults title={title} description={description} image={image} />;
        };

        render() {
            return <WrappedComponent noResultRender={this.renderNoResult} {...this.props} />;
        }

        get displayName() {
            return WrappedComponent.displayName || WrappedComponent.name || 'WithNoResults';
        }
    }

    return WithNoResults;
};

withNoResults.propTypes = {
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    image: PropTypes.element,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

withNoResults.displayName = 'withNoResults';

export default withNoResults;
