import { useState, useEffect } from 'react';
import mediaQueryObserver from './mediaQueryObserver';

export function useBreakpoint() {
    const [screens, setScreens] = useState({});

    useEffect(() => {
        const token = mediaQueryObserver.subscribe(supportScreens => {
            setScreens(supportScreens);
        });

        return () => mediaQueryObserver.unsubscribe(token);
    }, []);

    return screens;
}
