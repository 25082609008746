import React from 'react';

import SvgIcon from './SvgIcon';

const BoxArrowUpRight = props => (
    <SvgIcon {...props}>
        <path
            fillRule="nonzero"
            stroke="none"
            shapeRendering="geometricPrecision"
            d="M7.252 1.718v.803H.916v12.673h12.673V8.858h.803v7.118H.134V1.718h7.118zM15.976.134V6.07h-.803V1.472l-8.831 8.832-.553-.552L14.603.937h-4.584V.134h5.957z"
        />
    </SvgIcon>
);

BoxArrowUpRight.displayName = 'BoxArrowUpRightIcon';

export default BoxArrowUpRight;
