import React from 'react';

import SvgIcon from './SvgIcon';

const StaticList = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    d="M8,16c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C16,12.4,12.4,16,8,16z M8,0.6C3.9,0.6,0.6,3.9,0.6,8
        c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C15.4,3.9,12.1,0.6,8,0.6z"
                />
                <rect x="7.2" y="5.2" width="4" height="0.8" />
                <rect x="7.2" y="7.6" width="4" height="0.8" />
                <rect x="7.2" y="10" width="4" height="0.8" />
                <path d="M6,4.8H5.2C5,4.8,4.8,5,4.8,5.2V6c0,0.2,0.2,0.4,0.4,0.4H6c0.2,0,0.4-0.2,0.4-0.4V5.2C6.4,5,6.2,4.8,6,4.8z" />
                <path
                    d="M6,7.2H5.2C5,7.2,4.8,7.4,4.8,7.6v0.8c0,0.2,0.2,0.4,0.4,0.4H6c0.2,0,0.4-0.2,0.4-0.4V7.6
        C6.4,7.4,6.2,7.2,6,7.2z"
                />
                <path
                    d="M6,9.6H5.2C5,9.6,4.8,9.8,4.8,10v0.8c0,0.2,0.2,0.4,0.4,0.4H6c0.2,0,0.4-0.2,0.4-0.4V10
        C6.4,9.8,6.2,9.6,6,9.6z"
                />
            </g>
        </SvgIcon>
    );
};

StaticList.displayName = 'StaticListIcon';

export default StaticList;
