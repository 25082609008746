import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CloseButton } from '.';
import { Typography } from '../../Typography';

const ModalHeader = ({ id, icon, title, onClose, classes, closeIconColor }) => (
    <div className={classNames('modal-header onsolve-alert-modal__header', classes.root)}>
        {icon && <div className="onsolve-alert-modal__icon">{icon}</div>}
        <Typography
            id={id}
            variant="h3"
            className={classNames('modal-title onsolve-alert-modal__title', classes.title)}
        >
            {title}
        </Typography>
        <CloseButton className="onsolve-alert-modal__close" onClose={onClose} color={closeIconColor} />
    </div>
);

ModalHeader.defaultProps = {
    classes: {}
};

ModalHeader.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        title: PropTypes.string
    }),
    closeIconColor: PropTypes.string,
    icon: PropTypes.element,
    id: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
