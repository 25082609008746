import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuid from 'uuid';
import { parse, parseISO, isValid as isDateValid } from 'date-fns';

import { keyCodes } from '../../../common/constants';

import { DomUtils, EventsUtils, DateTimeUtils } from '../../utils';
import { CalendarIcon } from '../../Icons';
import {
    InputFieldControl,
    InputFieldGroup,
    InputFieldContainer,
    InputFieldLabel,
    InputFieldMessage,
    InputFieldToggle
} from '../../InputBase';
import { DateInputText, DateUtils, DatePopupUtils } from '../core';
import DatePopup from './DatePopup';

class DatePickerInput extends Component {
    constructor(props) {
        super(props);

        this.id = `date_picker_${props.id || uuid()}`;

        this.datePickerRef = React.createRef();
        this.monthInputRef = React.createRef();
        this.dayInputRef = React.createRef();
        this.yearInputRef = React.createRef();
        this.containerRef = React.createRef();
        this.toggleRef = React.createRef();
        this.firstLastDateInputFocused = false;

        this.state = {
            isOpen: false,
            focused: false,
            autoFocusPopover: false,
            value: undefined,
            lastValidValue: undefined,
            startDate: {
                value: undefined,
                isValid: true
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { value: valueProps, dateFormat } = props;
        const { lastValidValue: valueState } = state;

        if (valueProps !== undefined) {
            const value = typeof valueProps === 'string' ? parseISO(valueProps) : valueProps;

            if (+value !== +valueState && isDateValid(value)) {
                const result = DateUtils.getDateInputString(value, dateFormat);

                return { lastValidValue: result.value, ...result };
            }
        }
        return null;
    }

    componentDidMount() {
        const { showCalendar } = this.props;

        this.registerDatePickerFocusOut(!showCalendar);
    }

    componentWillUnmount() {
        this.registerDatePickerFocusOut(false);
    }

    componentDidUpdate(prevProps) {
        const { showCalendar } = this.props;

        if (showCalendar === prevProps.showCalendar) {
            return;
        }

        this.registerDatePickerFocusOut(!showCalendar);
    }

    handleInputContainerKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);
        const { ESC } = keyCodes;

        if (charCode === ESC) {
            e.stopPropagation();
        }
    };

    handleInputKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        const { ESC, ENTER_KEY } = keyCodes;

        if (charCode === ESC) {
            e.preventDefault();
            e.stopPropagation();

            const { dateFormat } = this.props;
            const { lastValidValue } = this.state;
            const state = { isOpen: false, ...DateUtils.getDateInputString(lastValidValue, dateFormat) };

            this.setState(state, this.validateDate);
        }

        if (charCode === ENTER_KEY) {
            this.validateDate();
        }
    };

    handleInputKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { TAB, DOWN } = keyCodes;

        if (charCode === DOWN && e.altKey) {
            this.setState({ isOpen: true, focused: true, autoFocusPopover: true });
        } else if (charCode === TAB) {
            if (e.target === this.yearInputRef.current || (e.target === this.monthInputRef.current && e.shiftKey)) {
                this.firstLastDateInputFocused = true;
                this.validateDate();
            }
        }
    };

    handleInputFocus = () => {
        this.setState({ isOpen: true, focused: true, autoFocusPopover: false });
    };

    handleInputBlur = () => {
        this.setState({ focused: false });
    };

    handleInputMouseDown = () => {
        this.setState({ isOpen: true, focused: true });
    };

    handleInputChange = (_, data) => {
        const { name, value, isValid } = data;
        const { dateFormat } = this.props;

        this.setState({ [name]: { value, isValid } }, () => {
            let start;
            const { startDate } = this.state;

            if (startDate.isValid) {
                start = parse(startDate.value, dateFormat, new Date());
            }

            this.setStartDate(start);
        });
    };

    handleDateChange = value => {
        const { dateFormat } = this.props;
        const state = {
            isOpen: false,
            focused: true,
            autoFocusPopover: false,
            ...DateUtils.getDateInputString(value, dateFormat)
        };

        this.startDate = value;
        this.setState(state, () => {
            if (this.startDate) {
                this.validateDate();
            }
        });
    };

    handleDateKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { UP } = keyCodes;

        if (charCode === UP && e.altKey) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({ focused: true, autoFocusPopover: false }, () => {
                DomUtils.setFocus(this.monthInputRef);
            });
        }
    };

    handleDateKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        const { ESC } = keyCodes;

        if (charCode === ESC) {
            e.preventDefault();
            e.stopPropagation();

            const state = {
                focused: true,
                isOpen: false,
                autoFocusPopover: false
            };

            this.setState(state, this.validateDate);
        }
    };

    handleDatePopupClose = e => {
        const outsideClicked = !DomUtils.isInDOMSubtree(e.target, this.containerRef.current);
        const calendarClicked = DomUtils.isInDOMSubtree(e.target, this.toggleRef.current);
        const result = outsideClicked || calendarClicked;

        if (result) {
            this.validateDate(calendarClicked);
        }
    };

    handleToggleClick = () => {
        const { isOpen } = this.state;

        this.setState({
            isOpen: !isOpen,
            autoFocusPopover: !isOpen
        });
    };

    handleDatePickerFocusOut = ({ relatedTarget }) => {
        if ([this.monthInputRef, this.dayInputRef, this.yearInputRef].every(ref => ref.current !== relatedTarget)) {
            this.validateDate();
        }
    };

    registerDatePickerFocusOut = subscribe => {
        const methodName = subscribe ? 'bind' : 'unbind';

        EventsUtils[methodName](this.datePickerRef.current, 'focusout', this.handleDatePickerFocusOut, true);
    };

    setStartDate = value => {
        if (value && isDateValid(value)) {
            this.startDate = value;
        } else {
            this.startDate = undefined;
        }

        this.setState({ value: this.startDate });
    };

    validateDate = calendarClicked => {
        const { value, startDate } = this.state;
        const { minDate, maxDate, showCalendar, onChange, onError } = this.props;
        const { isValid } = startDate;
        const isInRange = DateTimeUtils.isDateInRange(value, minDate, maxDate);

        let state = {};

        if (!calendarClicked) {
            state = { isOpen: false, autoFocusPopover: false };
        }

        if (isValid && isInRange) {
            if (!this.firstLastDateInputFocused) {
                showCalendar && DomUtils.setFocus(this.yearInputRef);
            }

            state = { ...state, lastValidValue: value };
            this.firstLastDateInputFocused = false;
            onChange(value);
        } else {
            if (isValid) {
                onError(new Date(''), value);
            } else {
                onError(new Date(''));
            }
        }

        this.setState(state);
    };

    getInitialMonth = value => {
        const { currentDate } = this.props;

        return value || currentDate || new Date();
    };

    getDatePickerPopupContainer = () => {
        return DatePopupUtils.getPopupContainer(this.datePickerRef.current);
    };

    render() {
        const {
            className,
            disabled,
            label,
            error,
            errorText,
            helpText,
            currentDate,
            maxDate,
            minDate,
            showCalendar
        } = this.props;
        const { isOpen, value, startDate, focused, autoFocusPopover } = this.state;
        const rootClasses = classNames('onsolve-date-input', className);

        return (
            <InputFieldControl ref={this.datePickerRef} className={rootClasses}>
                <InputFieldGroup>
                    {label && <InputFieldLabel>{label}</InputFieldLabel>}
                    <InputFieldContainer
                        error={error}
                        active={isOpen}
                        focused={focused}
                        ref={this.containerRef}
                        onKeyUp={this.handleInputContainerKeyUp}
                    >
                        <div className="d-flex flex-grow-1 w-100">
                            <DateInputText
                                monthRef={this.monthInputRef}
                                dayRef={this.dayInputRef}
                                yearRef={this.yearInputRef}
                                name="startDate"
                                value={startDate.value}
                                onKeyDown={this.handleInputKeyDown}
                                onKeyUp={this.handleInputKeyUp}
                                onMouseDown={this.handleInputMouseDown}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange}
                                disabled={disabled}
                            />
                        </div>
                        {showCalendar && (
                            <InputFieldToggle
                                id={this.id}
                                icon={CalendarIcon}
                                innerRef={this.toggleRef}
                                disabled={disabled}
                                onClick={this.handleToggleClick}
                            />
                        )}
                    </InputFieldContainer>
                </InputFieldGroup>
                <InputFieldMessage error={error} errorText={errorText} helpText={helpText} />
                {showCalendar && (
                    <DatePopup
                        id={this.id}
                        container={this.getDatePickerPopupContainer}
                        value={value}
                        initialMonth={this.getInitialMonth(value)}
                        isOpen={isOpen}
                        currentDate={currentDate}
                        maxDate={maxDate}
                        minDate={minDate}
                        autoFocus={autoFocusPopover}
                        onClose={this.handleDatePopupClose}
                        onChange={this.handleDateChange}
                        onKeyDown={this.handleDateKeyDown}
                        onKeyUp={this.handleDateKeyUp}
                    />
                )}
            </InputFieldControl>
        );
    }
}

DatePickerInput.propTypes = {
    /**
     Applied css classes to the text input field component.
     */
    className: PropTypes.string,
    /**
     Specifies the current date to be highlighted on the calendar
     */
    currentDate: PropTypes.instanceOf(Date),
    /**
     Specifies the date format applied to input
     */
    dateFormat: PropTypes.string,
    /**
     Specifies if the input should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies error text.
     */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a help text that shows below the input.
     */
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies an id attribute.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     Specifies minimal date that can be choosen.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies maximum date that can be choosen.
     */
    maxDate: PropTypes.instanceOf(Date),
    /**
     Specifies minimal date that can be choosen.
     */
    minDate: PropTypes.instanceOf(Date),
    /**
     Callback function on change event of text input field.
     */
    onChange: PropTypes.func,
    /**
     Callback function on error event of text input field.
     */
    onError: PropTypes.func,
    /**
     Specifies date format for the short hint displayed in the text input field before the user enters a value.
     */
    placeholderFormat: PropTypes.string,
    /**
     Specifies if calendar should be shown.
     */
    showCalendar: PropTypes.bool,
    /**
     Specifies inout value.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

DatePickerInput.defaultProps = {
    dateFormat: 'MM/dd/yyyy',
    placeholderFormat: 'MM/DD/YYYY',
    showCalendar: true,
    onChange: f => f,
    onError: f => f
};

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
