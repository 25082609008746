import React from 'react';
import PropTypes from 'prop-types';

const Link = props => {
    const { children, contentState, entityKey } = props;
    const { url, openLinkInSeparateWindow } = contentState.getEntity(entityKey).getData();

    return (
        <a href={url} target={openLinkInSeparateWindow ? '_blank' : '_self'}>
            {children}
        </a>
    );
};

Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    contentState: PropTypes.object.isRequired,
    entityKey: PropTypes.string.isRequired
};

Link.displayName = 'Link';

export default Link;
