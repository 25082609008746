import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import EventListener from 'react-event-listener';
import { fp as _ } from '../utils';
// Why did we use these widths please follow the url
// https://onsolve.invisionapp.com/dsm/onsolve/onsolve/folder/components/5c17daee80e8d2784782ae37
const breakpointsValues = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

const withWidth = () => WrappedComponent => {
    class withWidth extends Component {
        constructor(props) {
            super(props);

            this.state = {
                width: undefined
            };

            this.handleResize = _.debounce(() => this.updateWidth(), 166);
        }

        componentDidMount() {
            this.updateWidth();
        }

        updateWidth() {
            const width = this.getWidth();

            if (width !== this.state.width) {
                this.setState({ width });
            }
        }

        getWidth() {
            const { innerWidth } = window;

            for (let key in breakpointsValues) {
                const currentWidth = breakpointsValues[key];

                if (innerWidth <= currentWidth) {
                    return key;
                }
            }
            return 'xl';
        }

        render() {
            const { width } = this.state;
            const { forwardedRef, ...rest } = this.props;

            const layoutProps = {
                isMobile: width === 'sm',
                isTablet: width !== 'lg' && width !== 'xl'
            };

            return (
                <Fragment>
                    <WrappedComponent {...rest} ref={forwardedRef} width={width} {...layoutProps} />
                    <EventListener target="window" onResize={this.handleResize} />
                </Fragment>
            );
        }
    }

    withWidth.propTypes = {
        forwardedRef: PropTypes.object
    };

    withWidth.displayName = 'WithWidth';
    return withWidth;
};

export default withWidth;
