import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

import { keyCodes } from '../../common/constants';

import { Focusable } from '../Focusable';
import { ArrowDownIcon } from '../Icons';
import { DomUtils } from '../utils';

class AccordionItem extends React.Component {
    handleToggle = () => {
        const { id, onToggle, disabled } = this.props;

        if (!disabled && onToggle) {
            onToggle(id);
        }
    };

    handleKeyPress = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ENTER_KEY || charCode === keyCodes.SPACE) {
            e.preventDefault();

            this.handleToggle();
        }

        if (charCode === keyCodes.DOWN || charCode === keyCodes.UP) {
            DomUtils.focusOnSiblingAccordion(e.target, charCode);
        }
    };

    render() {
        const {
            props: { isOpen, title, disabled, className, children, tabIndex, body, iconComponent, variant },
            handleToggle,
            handleKeyPress
        } = this;

        const rootClasses = classNames('onsolve-accordion', { 'onsolve-accordion--collapsed': !isOpen }, className);
        const bodyElement = body ? (
            React.cloneElement(body, {
                className: classNames('onsolve-accordion__body', body.props.className)
            })
        ) : (
            <CardBody className="onsolve-accordion__body">{children}</CardBody>
        );

        const showArrowIcon = !disabled && !iconComponent && variant === 'normal';

        return (
            <Focusable
                render={({ classes }) => (
                    <Card className={rootClasses}>
                        <CardHeader className="onsolve-accordion__header">
                            <div
                                className={classNames('onsolve-accordion__btn', classes)}
                                tabIndex={tabIndex}
                                role="button"
                                aria-pressed={isOpen ? 'true' : 'false'}
                                onClick={handleToggle}
                                onKeyPress={handleKeyPress}
                            >
                                <div className="onsolve-accordion__title">{title}</div>
                                <div className="onsolve-accordion__icon-wrapper">
                                    {iconComponent}
                                    {showArrowIcon && <ArrowDownIcon className="onsolve-accordion__arrow" />}
                                </div>
                            </div>
                        </CardHeader>

                        {variant === 'normal' && <Collapse isOpen={isOpen}>{bodyElement}</Collapse>}
                    </Card>
                )}
            />
        );
    }
}

AccordionItem.propTypes = {
    body: PropTypes.element,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconComponent: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.element.isRequired
    ]),
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.element.isRequired
    ]),
    variant: PropTypes.oneOf(['normal', 'cardOnly'])
};

AccordionItem.defaultProps = {
    disabled: false,
    tabIndex: 0,
    variant: 'normal'
};

AccordionItem.displayName = 'AccordionItem';

export default AccordionItem;
