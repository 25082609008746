import React from 'react';
import SvgIcon from './SvgIcon';

const FileCsv = props => {
    return (
        <SvgIcon viewBox="0 0 52 54" {...props}>
            <g stroke="none">
                <path fill="#3777BC" d="M48.609 30.522H3.39L0 25.826l4.522-4.696h42.956L52 25.826z" />
                <path
                    d="M45.217 54H6.783c-1.25 0-2.261-1.05-2.261-2.348V2.348C4.522 1.05 5.533 0 6.782 0h27.131l13.565 14.087v37.565c0 1.297-1.011 2.348-2.26 2.348z"
                    fill="#A4C3E4"
                />
                <path
                    d="M33.913 0v11.74c0 1.296 1.012 2.347 2.26 2.347h11.305L33.913 0zM49.74 46.957H2.26c-1.248 0-2.26-1.051-2.26-2.348V25.826h52V44.61c0 1.297-1.012 2.348-2.26 2.348z"
                    fill="#3777BC"
                />
                <path
                    d="M18.975 33.78c-.57 0-1.016.241-1.336.727-.32.485-.479 1.154-.479 2.008 0 1.775.65 2.662 1.949 2.662.392 0 .773-.058 1.142-.171.368-.114.738-.25 1.11-.41V40.7c-.74.341-1.577.512-2.513.512-1.34 0-2.367-.404-3.082-1.21-.714-.808-1.071-1.975-1.071-3.499 0-.954.172-1.793.518-2.517.347-.723.845-1.28 1.494-1.668.65-.389 1.414-.582 2.292-.582.96 0 1.877.216 2.75.649l-.734 1.96a8.268 8.268 0 00-.984-.403 3.32 3.32 0 00-1.056-.164zM28.896 38.287c0 .572-.14 1.079-.42 1.523-.278.443-.682.789-1.208 1.034-.525.246-1.144.369-1.851.369-.592 0-1.087-.044-1.488-.13a5.328 5.328 0 01-1.25-.45v-2.22c.458.244.932.434 1.426.57a5.15 5.15 0 001.36.205c.356 0 .618-.064.784-.192a.598.598 0 00.248-.495.57.57 0 00-.1-.331 1.197 1.197 0 00-.321-.287c-.147-.096-.542-.294-1.18-.592-.58-.273-1.013-.538-1.303-.795a2.43 2.43 0 01-.643-.883c-.14-.332-.21-.724-.21-1.179 0-.85.297-1.512.893-1.986.596-.474 1.413-.711 2.453-.711.919 0 1.856.22 2.811.66l-.734 1.924c-.83-.395-1.547-.592-2.15-.592-.311 0-.538.056-.68.17a.52.52 0 00-.212.422c0 .18.09.342.27.486.18.143.668.403 1.466.781.765.358 1.295.741 1.594 1.15.296.412.445.927.445 1.55zM35.254 31.868h2.683l-2.908 9.219h-2.824l-2.896-9.219h2.696l1.208 4.671c.251 1.04.39 1.762.413 2.17.028-.294.085-.661.17-1.103.084-.441.16-.789.224-1.041l1.234-4.697z"
                    fill="#FFF"
                />
            </g>
        </SvgIcon>
    );
};

FileCsv.displayName = 'FileCsvIcon';

export default FileCsv;
