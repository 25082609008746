import { withPlaceholder } from '../../Placeholders';
import { TextFieldPlaceholder } from '../../Placeholders/Templates';
import { NumericField } from '../';

export default withPlaceholder({
    classes: {
        root: 'onsolve-input-field__group'
    },
    customPlaceholder: TextFieldPlaceholder
})(NumericField);
