import React from 'react';
import SvgIcon from './SvgIcon';

const ProductUpdatesList = props => (
    <SvgIcon {...props}>
        <defs>
            <path
                d="M9.75,0 L9.75,7.62291868 L6.82865589,10.5 L0,10.5 L0,0 L9.75,0 Z M9,0.75 L0.75,0.75 L0.75,9.75 L6.299,9.75 L6.29999971,7.09090877 L9,7.09 L9,0.75 Z M4.80000007,6.64772701 L4.80000007,7.39772701 L2.25,7.39772701 L2.25,6.64772701 L4.80000007,6.64772701 Z M7.5,4.43181825 L7.5,5.18181825 L2.25,5.18181825 L2.25,4.43181825 L7.5,4.43181825 Z M7.5,2.21590912 L7.5,2.96590912 L2.25,2.96590912 L2.25,2.21590912 L7.5,2.21590912 Z"
                id="path-1"
                stroke="none"
            />
            <rect id="path-3" x="0" y="0" width="18" height="18" />
        </defs>
        <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
        </mask>
        <use id="Mask" fill="#3777BC" xlinkHref="#path-1" />
        <g mask="url(#mask-2)">
            <g transform="translate(-3.750000, -3.750000)">
                <mask fill="white">
                    <use xlinkHref="#path-3" />
                </mask>
                <use id="Base" fillRule="evenodd" xlinkHref="#path-3" />
            </g>
        </g>
    </SvgIcon>
);

ProductUpdatesList.displayName = 'ProductUpdatesListIcon';

export default ProductUpdatesList;
