import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Toastr from './Toastr';
import ToastrStore from './ToastrStore';
import ToastrStackContainer from './ToastStackContainer';

class ToastrProviderComponent extends Component {
    state = { localStore: [] };

    componentDidMount() {
        const addToStore = () =>
            this.setState({
                localStore: ToastrStore.getState() || []
            });

        this.unsubToastrStore = ToastrStore.subscribe(addToStore);
    }

    componentWillUnmount() {
        this.unsubToastrStore();
    }

    render() {
        const { localStore } = this.state;
        const { position } = this.props;

        return (
            <ToastrStackContainer position={position}>
                {localStore.map(toastrData => (
                    <Toastr position="stack" isVisible={true} key={toastrData.id} {...toastrData} />
                ))}
            </ToastrStackContainer>
        );
    }
}

ToastrProviderComponent.propTypes = {
    position: PropTypes.string
};

ToastrProviderComponent.defaultProps = {
    position: 'top-right'
};

ToastrProviderComponent.displayName = 'ToastrProviderComponent';

export default ToastrProviderComponent;
