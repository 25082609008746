import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { shapes, keyCodes } from '../../common/constants';
import { Scrollbars } from '../Scrollbars';
import { DomUtils, fp as _, ReactUtils } from '../utils';

import { TableRow, TableExpandableRow, TableDraggableRow } from './Rows';
import TableDraggableBody from './TableDraggableBody';

import { TableSettings, TablePropTypes } from './utils';

class TableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localData: [],
            selectedRowIndex: -1,
            isDragging: false
        };

        this.tableScrollbarRefs = [];
        this.tableBodyRefs = {};
        this.tableRef = React.createRef();
        this.tableLeftShadowRef = React.createRef();
        this.tableBottomShadowRef = React.createRef();
        this.setTableScrollbarRefs = this.setTableScrollbarRefs.bind(this);
        this.setTableColumnGroupRef = this.setTableColumnGroupRef.bind(this);
        this.handleScrollLeft = this.handleScrollLeft.bind(this);
        this.handleScrollTop = this.handleScrollTop.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleScrollStart = this.handleScrollStart.bind(this);
        this.handleScrollStop = this.handleScrollStop.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleSortOver = this.handleSortOver.bind(this);
        this.onDragBeforeStart = this.onDragBeforeStart.bind(this);
        this.resetDragging = this.resetDragging.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { drag, data } = this.props;
        const { localData } = prevState;
        let orderChanged = false;

        if (drag.useLocalState) {
            if (localData.length === data.length) {
                orderChanged = localData.some((e, i) => e.id !== data[i].id);
            }
            // we don't need to update localData on componentDidUpdate in case of updated rows order, because at this moment we already updated it in handleDragEnd
            // if we do so, the UI will handle drag animation with 0.5-1 second lag while Redux updates after request on BE side
            if (!orderChanged && !_.isEqual(localData, data)) {
                this.setState({ localData: data });
            }
        }

        this.adjustTableRows();
    }

    componentDidMount() {
        this.adjustTableRows();
    }

    handleScrollLeft(e, data) {
        const { onScrollLeft } = this.props;

        if (onScrollLeft) {
            onScrollLeft(e, data);
        }

        const { current } = this.tableLeftShadowRef;

        if (current) {
            TableSettings.displayVerticalShadow(current, data.values);
        }
    }

    handleScrollTop(e, data) {
        const { onScrollTop } = this.props;
        const { target } = e;

        if (onScrollTop) {
            onScrollTop(e, data);
        }

        const { current } = this.tableBottomShadowRef;

        if (current) {
            TableSettings.displayHorizontalShadow(current, data.values);
        }

        this.tableScrollbarRefs.forEach(scrollbar => {
            const scrollBarsElement = scrollbar.getScrollBarsElement();

            if (target !== scrollBarsElement.view) {
                scrollbar.scrollTop(target.scrollTop);
                scrollbar.update();
            }
        });
    }

    handleScrollStart(e) {
        this.tableScrollbarRefs.forEach(scrollbar => {
            const scrollBarsElement = scrollbar.getScrollBarsElement();

            if (scrollBarsElement.view !== e.target) {
                scrollBarsElement.removeListeners();
                scrollBarsElement.showTracks();
            }
        });
    }

    findCellByCellIndex(cells, activeCell) {
        const currentCell = Number.isInteger(activeCell.cellIndex) ? activeCell : activeCell.closest('td');

        if (!currentCell) {
            return null;
        }

        return cells[currentCell.cellIndex];
    }

    findNextFocusable(cell) {
        let targetFocusable;
        let targetCell = Number.isInteger(cell.cellIndex) ? cell : cell.closest('td');

        if (!targetCell) {
            return null;
        }

        const [targetChildren] = targetCell.children;

        if (targetChildren && targetChildren.classList.contains('onsolve-switcher')) {
            targetFocusable = targetChildren.firstChild.firstChild;
            return targetFocusable;
        }

        if (targetCell.getElementsByClassName('onsolve-focusable').length) {
            [targetFocusable] = targetCell.getElementsByClassName('onsolve-focusable');
            return targetFocusable;
        }

        return targetCell;
    }

    handleKeyDown(e) {
        const keycode = DomUtils.getCharCode(e);
        const isCtrl = DomUtils.getCtrlKey(e);
        const { PAGEUP, PAGEDOWN, UP, DOWN, RIGHT, LEFT, HOME, END } = keyCodes;
        let nextFocusable;

        if (keycode === PAGEUP || keycode === PAGEDOWN) {
            const { children: tableRows } = this.tableRef.current;
            const [, tbody] = tableRows;

            nextFocusable = keycode === PAGEUP ? tbody.firstElementChild : tbody.lastElementChild;
        }

        const { activeElement } = document;

        if (keycode === UP || keycode === DOWN) {
            let nextCell;
            const parentNode = activeElement.closest('tr');

            if (!parentNode) {
                return;
            }

            const { nextElementSibling, previousElementSibling } = parentNode;
            let cells;

            if (keycode === DOWN && nextElementSibling != null) {
                cells = nextElementSibling.children;
            }
            if (keycode === UP && previousElementSibling != null) {
                cells = previousElementSibling.children;
            }

            if (_.isEmpty(cells)) {
                return;
            }

            nextCell = this.findCellByCellIndex(cells, activeElement);
            nextFocusable = nextCell ? this.findNextFocusable(nextCell) : null;
        }

        if (keycode === HOME || keycode === END) {
            const parentNode = isCtrl ? activeElement.closest('tbody') : activeElement.closest('tr');
            let cell;

            if (isCtrl) {
                const row = keycode === HOME ? parentNode.firstChild : parentNode.lastChild;

                cell = keycode === HOME ? row.firstChild : row.lastChild;
            } else {
                cell = keycode === HOME ? parentNode.firstChild : parentNode.lastChild;
            }

            e.preventDefault();
            e.stopPropagation();

            nextFocusable = this.findNextFocusable(cell);
        }

        if (keycode === RIGHT || keycode === LEFT) {
            const active =
                activeElement.tagName !== 'TR' && activeElement.classList.contains('onsolve-focusable')
                    ? activeElement.closest('td')
                    : activeElement;
            const parentNode = activeElement.closest('tr');
            const { nextElementSibling, previousElementSibling } = active;

            if (active.tagName === 'TR') {
                const cells = activeElement.children;

                if (!_.isEmpty(cells)) {
                    // moving focus from row to child cells
                    nextFocusable = this.findNextFocusable(parentNode.firstChild);
                }
            } else if (active.tagName === 'TD') {
                const cell = keycode === RIGHT ? nextElementSibling : previousElementSibling;

                if (cell) {
                    nextFocusable = this.findNextFocusable(cell);
                }
            }
        }

        if (nextFocusable) {
            DomUtils.setFocus(nextFocusable);
        }
    }

    handleScrollStop(e) {
        this.tableScrollbarRefs.forEach(scrollbar => {
            const scrollBarsElement = scrollbar.getScrollBarsElement();

            if (scrollBarsElement.view !== e.target) {
                scrollBarsElement.addListeners();
                scrollBarsElement.hideTracks();
            }
        });
    }

    handleMouseEvents(rowIndex) {
        return e => {
            const [lockedTableRows, tableRows] = _.values(this.tableBodyRefs);

            if (lockedTableRows && tableRows) {
                const action = e.type === 'mouseover' ? 'add' : 'remove';

                if (e.type === 'mouseout' && this.state.selectedRowIndex !== -1) {
                    this.setState({ selectedRowIndex: -1 });
                }
                lockedTableRows.children[rowIndex].classList[action]('onsolve-table__body-row--hovered');
                tableRows.children[rowIndex].classList[action]('onsolve-table__body-row--hovered');
            }
        };
    }

    adjustTableRows() {
        const [lockedTableRows, tableRows] = _.values(this.tableBodyRefs);

        if (lockedTableRows && tableRows) {
            for (let index = 0; index < lockedTableRows.children.length; index++) {
                const lockedRow = lockedTableRows.children[index];
                const row = tableRows.children[index];

                if (lockedRow.offsetHeight !== row.offsetHeight) {
                    const maxHeight = Math.max(lockedRow.offsetHeight, row.offsetHeight);

                    lockedRow.style.height = `${maxHeight}px`;
                    row.style.height = lockedRow.style.height;
                }
            }
        }
    }

    highlightCurrentRow() {
        const [lockedTableRows, tableRows] = _.values(this.tableBodyRefs);
        const { selectedRowIndex } = this.state;
        const { skip } = this.props.page;
        const rowIndex = selectedRowIndex - skip;

        if (selectedRowIndex !== -1 && lockedTableRows && tableRows) {
            lockedTableRows.children[rowIndex].classList.add('onsolve-table__body-row--hovered');
            tableRows.children[rowIndex].classList.add('onsolve-table__body-row--hovered');
        }
    }

    updateScrollbars() {
        this.tableScrollbarRefs.forEach(scrollbar => {
            scrollbar.update();
        });
    }

    setWidth(width) {
        if (this.tableRef.current) {
            this.tableRef.current.style.width = `${width}px`;
        }
    }

    setTableScrollbarRefs(tableIndex) {
        return ref => {
            this.tableScrollbarRefs[tableIndex] = ref;
        };
    }

    setTableColumnGroupRef(ref) {
        const { columnResize } = this.props;

        columnResize.bodyColumnGroup = ref;
    }

    setTableBodyRefs(tableIndex) {
        return ref => {
            this.tableBodyRefs[tableIndex] = ref;
        };
    }

    resetDragging() {
        this.setState({ isDragging: false });
    }

    onDragBeforeStart({ node }) {
        this.setState({ isDragging: true });
        this.dragTarget = node;

        this.tableRef.current.classList.add('onsolve-table__body-content--dragging');
        this.dragTarget.classList.remove('onsolve-table__body-row');
        this.dragTarget.classList.add('onsolve-table__body-row--shadow');

        for (let i = 0; i < this.dragTarget.children.length; i++) {
            this.dragTarget.children[i].style.width = this.dragTarget.children[i].clientWidth + 'px';
        }
    }

    handleSortOver({ index, newIndex }) {
        const classes = this.tableRef.current.classList;
        const beforeClass = 'onsolve-table__body-content--dragging--before';
        const afterClass = 'onsolve-table__body-content--dragging--after';

        if (newIndex < index && !classes.contains(beforeClass)) {
            classes.add(beforeClass);
            classes.remove(afterClass);
        } else if (newIndex > index && !classes.contains(afterClass)) {
            classes.add(afterClass);
            classes.remove(beforeClass);
        }
    }

    handleDragEnd({ oldIndex, newIndex, collection, isKeySorting }, e) {
        const { onDragEnd, drag } = this.props;
        const { localData } = this.state;
        const data = localData;

        this.tableRef.current.classList.remove('onsolve-table__body-content--dragging');
        this.tableRef.current.classList.remove('onsolve-table__body-content--dragging--after');
        this.tableRef.current.classList.remove('onsolve-table__body-content--dragging--before');
        this.dragTarget.classList.add('onsolve-table__body-row');
        this.dragTarget.classList.remove('onsolve-table__body-row--shadow');

        if (oldIndex === newIndex) {
            return;
        } else {
            this.resetDragging();
        }

        if (drag.useLocalState) {
            data.splice(newIndex < 0 ? data.length + newIndex : newIndex, 0, data.splice(oldIndex, 1)[0]);

            for (let i = 0; i < this.dragTarget.children.length; i++) {
                this.dragTarget.children[i].style.width = this.dragTarget.children[i].clientWidth + 'px';
            }
            this.setState({ data });
        }

        if (onDragEnd) {
            onDragEnd({ oldIndex, newIndex, collection, isKeySorting }, e, data);
        }
    }

    handleSelectionChange(rowIndex) {
        return (e, dataItem, rowSelection, checked) => {
            const { onSelectionChange } = this.props;

            this.setState({ selectedRowIndex: rowIndex }, this.highlightCurrentRow);
            if (onSelectionChange) {
                onSelectionChange(e, dataItem, rowSelection, checked);
            }
        };
    }

    render() {
        const {
            columns,
            data,
            page,
            lockedColumns,
            expandableField,
            autoHeight,
            autoHeightMax,
            autoHeightMin,
            draggable,
            draggableField,
            drag,
            showShadow,
            ...other
        } = this.props;
        let currentIndex = 0;
        const rowsData = draggable && drag.useLocalState ? this.state.localData : data;

        const tables = _.map([lockedColumns, columns], (child, childIndex) => {
            if (childIndex === 0 && child.cols.length === 0) {
                return null;
            }

            const isLockedTable = childIndex === 0;
            const tableStyles = { minWidth: `${child.width}px` };

            let scrollBarProps = {
                onScrollLeft: this.handleScrollLeft,
                onScrollTop: this.handleScrollTop,
                onScrollStart: this.handleScrollStart,
                onScrollStop: this.handleScrollStop
            };

            if (isLockedTable) {
                scrollBarProps = {
                    ...scrollBarProps,
                    vertical: false,
                    style: {
                        maxWidth: `${child.width}px`,
                        minWidth: `${child.width}px`
                    }
                };
            } else {
                scrollBarProps = {
                    ...scrollBarProps,
                    autoHide: true,
                    autoHeight,
                    autoHeightMax,
                    autoHeightMin,
                    horizontal: true
                };
            }

            this.tableBodyRefs = {};

            const setTableColumnGroupRef = isLockedTable ? f => f : this.setTableColumnGroupRef;
            const setTableRef = isLockedTable ? f => f : ref => ReactUtils.setRef(this.tableRef, ref);

            const rootClasses = classNames('onsolve-table__body-container', {
                'onsolve-table__body-container--locked': isLockedTable
            });
            const TableRowWrapper = draggable ? TableDraggableRow : Fragment;

            const rows = _.map(rowsData, (dataItem, key) => {
                const rowIndex = key + (page.skip || 0);
                const tableRowWrapperProps = { key: rowIndex, ...(draggable && { index: rowIndex }) };

                return (
                    <TableRowWrapper {...tableRowWrapperProps}>
                        <TableRow
                            rowIndex={rowIndex}
                            resetDragging={this.resetDragging}
                            isClickable={dataItem.isClickable}
                            isDragging={this.state.isDragging}
                            columns={child.items}
                            dataItem={dataItem}
                            expandableField={expandableField}
                            onMouseOver={this.handleMouseEvents(key)}
                            onMouseOut={this.handleMouseEvents(key)}
                            draggableField={draggableField}
                            draggable={draggable}
                            drag={drag}
                            {...other}
                            onSelectionChange={this.handleSelectionChange(rowIndex)}
                        />
                        {expandableField && (
                            <TableExpandableRow
                                columns={child.items}
                                dataItem={dataItem}
                                expandableField={expandableField}
                                {...other}
                            />
                        )}
                    </TableRowWrapper>
                );
            });

            return (
                <div key={currentIndex} className={rootClasses}>
                    <Scrollbars {...scrollBarProps} ref={this.setTableScrollbarRefs(currentIndex)}>
                        <table
                            style={tableStyles}
                            className="onsolve-table__body-content"
                            ref={setTableRef}
                            role="presentation"
                        >
                            <colgroup ref={ref => setTableColumnGroupRef(ref)}>{child.cols}</colgroup>
                            {draggable && (
                                <TableDraggableBody
                                    onSortEnd={this.handleDragEnd}
                                    onSortOver={this.handleSortOver}
                                    useDragHandle
                                    lockAxis={drag.lockAxis}
                                    keyCodes={drag.keyCodes}
                                    lockToContainerEdges={drag.lockToContainerEdges}
                                    hideSortableGhost={false}
                                    helperClass="onsolve-table__body-row--dragged-row"
                                    updateBeforeSortStart={this.onDragBeforeStart}
                                >
                                    {rows}
                                </TableDraggableBody>
                            )}
                            {!draggable && <tbody ref={this.setTableBodyRefs(currentIndex)}>{rows}</tbody>}
                        </table>
                    </Scrollbars>
                    {currentIndex++ === 1 && (
                        <div
                            className="onsolve-table__shadow onsolve-table__shadow--vertical"
                            ref={ref => ReactUtils.setRef(this.tableLeftShadowRef, ref)}
                        />
                    )}
                </div>
            );
        });

        return (
            <div className="onsolve-table__body" onKeyDown={this.handleKeyDown}>
                {tables}
                {showShadow && (
                    <div
                        className="onsolve-table__shadow onsolve-table__shadow--horizontal"
                        ref={ref => ReactUtils.setRef(this.tableBottomShadowRef, ref)}
                    />
                )}
            </div>
        );
    }
}

TableBody.propTypes = {
    autoHeight: PropTypes.bool,
    autoHeightMax: PropTypes.string,
    autoHeightMin: PropTypes.string,
    columnResize: PropTypes.shape({
        bodyColumnGroup: PropTypes.object
    }),
    columns: TablePropTypes.ColumnShape.isRequired,
    data: PropTypes.array,
    drag: shapes.dragShape,
    draggable: PropTypes.bool,
    draggableField: PropTypes.string,
    expandableField: PropTypes.string,
    lockedColumns: TablePropTypes.ColumnShape.isRequired,
    onDragEnd: PropTypes.func,
    onScroll: PropTypes.func,
    onScrollLeft: PropTypes.func,
    onScrollTop: PropTypes.func,
    onSelectionChange: PropTypes.func,
    page: PropTypes.shape({
        skip: PropTypes.number,
        take: PropTypes.number
    }),
    showShadow: PropTypes.bool
};

TableBody.defaultProps = {
    data: [],
    page: {
        skip: 0,
        take: 10
    },
    autoHeight: false,
    drag: {
        lockAxis: 'y'
    }
};

TableBody.displayName = 'TableBody';

export default TableBody;
