import React from 'react';

import SvgIcon from './SvgIcon';

const Exclamation = props => (
    <SvgIcon {...props}>
        <path
            fillRule="nonzero"
            stroke="none"
            strokeWidth="1"
            d="M8,12.1428571 C7.48453423,12.1428571 7.06666667,11.7271216 7.06666667,11.2142857 C7.06666667,10.7014499 7.48453423,10.2857143 8,10.2857143 C8.51546577,10.2857143 8.93333333,10.7014499 8.93333333,11.2142857 C8.93333333,11.7271216 8.51546577,12.1428571 8,12.1428571 Z M7.53333333,5.64285714 C7.53333333,5.38512426 7.74226712,5.17619048 8,5.17619048 C8.25773288,5.17619048 8.46666667,5.38512426 8.46666667,5.64285714 L8.46666667,8.89285714 C8.46666667,9.15059003 8.25773288,9.35952381 8,9.35952381 C7.74226712,9.35952381 7.53333333,9.15059003 7.53333333,8.89285714 L7.53333333,5.64285714 Z M2.24986878,13.0690476 L13.7501312,13.0690476 L8,2.44471625 L2.24986878,13.0690476 Z M1.05625416,13.3135896 L7.5895875,1.24216108 C7.76605648,0.916105038 8.23394352,0.916105038 8.4104125,1.24216108 L14.9437458,13.3135896 C15.1120314,13.6245255 14.8868883,14.002381 14.5333333,14.002381 L1.46666667,14.002381 C1.11311169,14.002381 0.887968584,13.6245255 1.05625416,13.3135896 Z"
        />
    </SvgIcon>
);

Exclamation.displayName = 'ExclamationIcon';

export default Exclamation;
