import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxElement = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M13.6875,1 C14.4121246,1 15,1.58787542 15,2.3125 L15,2.3125 L15,13.6875 C15,14.4121246 14.4121246,15 13.6875,15 L13.6875,15 L2.3125,15 C1.58787542,15 1,14.4121246 1,13.6875 L1,13.6875 L1,2.3125 C1,1.58787542 1.58787542,1 2.3125,1 L2.3125,1 Z M13.6875,1.875 L2.3125,1.875 C2.07112458,1.875 1.875,2.07112458 1.875,2.3125 L1.875,2.3125 L1.875,13.6875 C1.875,13.9288754 2.07112458,14.125 2.3125,14.125 L2.3125,14.125 L13.6875,14.125 C13.9288754,14.125 14.125,13.9288754 14.125,13.6875 L14.125,13.6875 L14.125,2.3125 C14.125,2.07112458 13.9288754,1.875 13.6875,1.875 L13.6875,1.875 Z M11.8093592,5.06564078 C11.9612298,5.21751134 11.9781043,5.45325769 11.8599827,5.62376732 L11.8093592,5.68435922 L7.43435922,10.0593592 C7.28248866,10.2112298 7.04674231,10.2281043 6.87623268,10.1099827 L6.81564078,10.0593592 L4.62814078,7.87185922 C4.45728641,7.70100484 4.45728641,7.42399516 4.62814078,7.25314078 C4.78001134,7.10127023 5.01575769,7.08439572 5.18626732,7.20251726 L5.24685922,7.25314078 L7.125,9.131375 L11.1906408,5.06564078 C11.3614952,4.89478641 11.6385048,4.89478641 11.8093592,5.06564078 Z"
        />
    </SvgIcon>
);

CheckBoxElement.displayName = 'CheckBoxElementIcon';

export default CheckBoxElement;
