import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxBlank = props => {
    return (
        <SvgIcon {...props}>
            <rect
                x=".5"
                y=".5"
                width="15"
                height="15"
                rx="1"
                ry="1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                data-cap="butt"
            />
        </SvgIcon>
    );
};

CheckBoxBlank.displayName = 'CheckBoxBlankIcon';

export default CheckBoxBlank;
