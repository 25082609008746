import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNoResults } from '../NoResults';
import { Paginator, Scrollbars, paginatorVariants, withLoader } from '../';

import { keyCodes } from '../../common/constants';

import { DomUtils, KeyboardNavigationUtils } from '../utils';

class List extends Component {
    constructor(props) {
        super(props);

        this.listRef = React.createRef();
    }

    handleKeyDown = e => {
        const { END, HOME, UP, DOWN } = keyCodes;

        const menuListElement = this.listRef.current;
        const currentElement = document.activeElement;

        const charCode = DomUtils.getCharCode(e);

        if (charCode === END) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.previousItem, menuListElement);
        } else if (charCode === HOME) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.nextItem, menuListElement);
        } else if (charCode === UP) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.previousItem, menuListElement, currentElement);
        } else if (charCode === DOWN) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.nextItem, menuListElement, currentElement);
        }
    };

    renderPaginator = () => {
        const { onPageChange, pagination, paginatorRender } = this.props;

        const paginatorProps = {
            variant: paginatorVariants.MOBILE,
            onPageChange,
            total: pagination.count,
            page: pagination,
            pageSize: pagination.take
        };

        if (paginatorRender) {
            return paginatorRender({ ...paginatorProps });
        }

        return <Paginator {...paginatorProps} />;
    };

    renderList() {
        const { noResultRender, items, children, ['aria-label']: ariaLabel } = this.props;

        if (noResultRender && !items.length) {
            return noResultRender();
        }

        return (
            <ul role="listbox" className="onsolve-list-item__container" ref={this.listRef} aria-label={ariaLabel}>
                {items.map((item, index) =>
                    children({
                        item,
                        index
                    })
                )}
            </ul>
        );
    }

    render() {
        const { className, classes, scrollable, pagination } = this.props;
        const Wrapper = scrollable ? Scrollbars : Fragment;

        return (
            <div className={classNames('onsolve-list', className, classes.root)} onKeyDown={this.handleKeyDown}>
                <Wrapper>{this.renderList()}</Wrapper>

                {pagination ? (
                    <div className={classNames('onsolve-list__paginator', classes.paginator)}>
                        {this.renderPaginator()}
                    </div>
                ) : null}
            </div>
        );
    }
}

List.defaultProps = {
    classes: {},
    items: []
};

List.propTypes = {
    /**
     Render function for the list item
     */
    children: PropTypes.func.isRequired,
    /**
     Specifies a class name for wrapper
     */
    className: PropTypes.string,
    /**
     Override or extend the styles applied to the sub components.
     */
    classes: PropTypes.object,
    /**
    Control loading state
    */
    isLoading: PropTypes.bool,
    /**
    List items
     */
    items: PropTypes.array,
    /**
     Render function for no result
     */
    noResultRender: PropTypes.func,
    /**
     Callback function for the page change event
     */
    onPageChange: PropTypes.func,
    /**
     Pagination config
     */
    pagination: PropTypes.object,
    /**
    Override render function for the pagination
     */
    paginatorRender: PropTypes.func,
    /**
     Apply scrollbars
     */
    scrollable: PropTypes.bool
};

List.displayName = 'List';

const EnhancedList = withNoResults()(withLoader(List));

export default EnhancedList;
