import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageContent = ({ className, children }) => {
    const rootClasses = classNames('onsolve-page-body', className);

    return <div className={rootClasses}>{children}</div>;
};

PageContent.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

PageContent.displayName = 'PageContent';

export default PageContent;
