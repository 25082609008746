import React from 'react';
import SvgIcon from './SvgIcon';

const Help = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M8,16c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S12.4,16,8,16z M8,1.1C4.2,1.1,1.1,4.2,1.1,8s3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9 S11.8,1.1,8,1.1z" />
            <circle cx="7.8" cy="11.7" r="1.1" />
            <path d="M7.8,3.7c-1,0-1.9,0.6-2.2,1.6L5.3,5.9l1.1,0.4l0.2-0.6C6.9,5.1,7.5,4.8,8.2,5c0.5,0.2,0.8,0.6,0.8,1.1 c0,0.4-0.1,0.5-0.6,0.8C7.9,7.3,7.2,7.8,7.2,9.1v0.6h1.2V9.1c0-0.7,0.3-0.9,0.7-1.2c0.4-0.3,1.1-0.8,1.1-1.8 C10.1,4.8,9.1,3.7,7.8,3.7z" />
        </g>
    </SvgIcon>
);

Help.displayName = 'HelpIcon';

export default Help;
