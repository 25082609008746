import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const paginatorVariants = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop'
};

class PaginatorPlaceholder extends Component {
    render() {
        const { className, classes, style, componentProps } = this.props;
        const { variant } = componentProps;

        const rootClasses = classNames(
            'onsolve-placeholder',
            'onsolve-placeholder__paginator',
            {
                'onsolve-table__paginator': variant !== paginatorVariants.MOBILE,
                'onsolve-table__paginator-mobile onsolve-placeholder__paginator-mobile':
                    variant === paginatorVariants.MOBILE
            },
            classes.root
        );
        const rowClasses = classNames(
            'onsolve-placeholder__row',
            { 'onsolve-placeholder__row--inline': variant !== paginatorVariants.MOBILE },
            classes.row,
            className
        );
        const pageNumberClasses = classNames('onsolve-placeholder__paginator-page-number', rowClasses);
        const infoClasses = classNames('onsolve-placeholder__paginator-info', rowClasses);
        const arrowsClasses = classNames('onsolve-placeholder__paginator-arrows', rowClasses);

        if (variant === paginatorVariants.MOBILE) {
            return (
                <div className={rootClasses}>
                    <div className={infoClasses} style={{ ...style }} />
                    <div className={arrowsClasses} style={{ ...style }} />
                    <div className={pageNumberClasses} style={{ ...style }} />
                </div>
            );
        }

        return (
            <div className={rootClasses}>
                <div>
                    <div className={pageNumberClasses} style={{ ...style }} />
                    <div className={infoClasses} style={{ ...style }} />
                </div>
                <div className={arrowsClasses} style={{ ...style }} />
            </div>
        );
    }
}

PaginatorPlaceholder.displayName = 'PaginatorPlaceholder';

PaginatorPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    componentProps: PropTypes.object,
    style: PropTypes.object
};

PaginatorPlaceholder.defaultProps = {
    classes: {},
    componentProps: {}
};

export default PaginatorPlaceholder;
