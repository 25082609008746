import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { keyCodes } from '../../../common/constants';
import { DomUtils, fp as _, ReactUtils } from '../../utils';
import { CheckBox } from '../../Selections';
import { ArrowDownIcon } from '../../Icons';
import {
    InputFieldToggle,
    InputFieldControl,
    InputFieldGroup,
    InputFieldContainer,
    InputFieldLabel,
    InputFieldMessage
} from '../../InputBase';
import { Menu, MenuItem } from '../../Menu';
import DropdownInput from './DropdownInput';
import { getTextFromItem, getDataKeyItem, getDisplayValue, getSelectedValues } from '../utils';

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.isOpenControlled = props.open !== undefined;
        this.state = {
            isOpen: false,
            formattedValue: '',
            focused: false,
            autoFocus: false
        };

        this.optionId = `${props.id}_option`;
        this.listBoxId = `${props.id}_listBox`;

        this.anchorEl = React.createRef();

        this.handleToggle = this.handleToggle.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        if (this.isOpenControlled && this.props.open) {
            this.forceUpdate();
        }
    }

    handleToggle(e) {
        const { isOpen } = this.state;
        const { disabled, onClick } = this.props;

        if (disabled) {
            return;
        }

        this.setState({ isOpen: !isOpen });

        if (onClick) {
            onClick(e);
        }
    }

    handleMenuClose(e) {
        const { onClose } = this.props;

        this.setState({ isOpen: false, autoFocus: false });

        if (onClose) {
            onClose(e);
        }
    }

    handleMenuItemEvent = (e, item) => {
        const { value: valueProps, defaultValue, dataItemKey, multiple, onChange, onClose } = this.props;

        if (!multiple) {
            this.setState({ isOpen: false });

            if (onClose) {
                onClose(e);
            }
        }

        if (onChange) {
            const currentValues = valueProps || defaultValue;
            const selectedValue = getDataKeyItem(item, dataItemKey);
            const result = multiple ? getSelectedValues(selectedValue, currentValues) : selectedValue;

            onChange(e, result, { item });
        }
    };

    handleMenuItemClick(item) {
        return e => this.handleMenuItemEvent(e, item);
    }

    handleMenuItemKeyDown(item) {
        const { multiple } = this.props;

        return e => {
            const { ENTER_KEY, SPACE, ESC } = keyCodes;
            const charCode = DomUtils.getCharCode(e);

            if (charCode === ESC) {
                e.stopPropagation();
            } else if (charCode === ENTER_KEY || (charCode === SPACE && multiple)) {
                e.preventDefault();
                e.stopPropagation();

                this.handleMenuItemEvent(e, item);
            }
        };
    }

    handleKeyDown(e) {
        const { ENTER_KEY, DOWN, TAB } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === ENTER_KEY) {
            e.preventDefault();
            this.handleToggle(e);
        } else if (charCode === DOWN) {
            e.preventDefault();
            this.setState({ autoFocus: true });
        } else if (charCode === TAB) {
            const { isOpen } = this.state;

            if (isOpen) {
                this.handleToggle(e);
            }
        }
    }

    handleKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            const { isOpen } = this.state;

            if (isOpen) {
                e.stopPropagation();

                this.handleMenuClose(e);
            }
        }
    };

    handleMenuKeyDown = e => {
        const { LEFT, RIGHT, UP, DOWN } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === UP || charCode === DOWN) {
            const { current } = this.anchorEl;

            if (this.state.isOpen) {
                const currentMenuItem = document.activeElement;

                current.setAttribute('aria-activedescendant', currentMenuItem.getAttribute('id'));
            } else {
                current.removeAttribute('aria-activedescendant');
            }
        }

        if (charCode === LEFT || charCode === RIGHT) {
            e.preventDefault();
            DomUtils.setFocus(this.anchorEl);
            this.setState({ autoFocus: false });
        }
    };

    handleMenuKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            e.preventDefault();
            e.stopPropagation();
            this.handleMenuClose(e);
        }
    };

    renderValue(displayText) {
        const { valueRender, multiple } = this.props;

        let defaulRender = getDisplayValue(displayText, multiple);

        if (valueRender) {
            defaulRender = valueRender({ ...this.props }, () => defaulRender);
        }

        return defaulRender;
    }

    renderItemText(item) {
        const { itemTextRender, ...other } = this.props;
        const { textField } = other;

        let defaulRender = getTextFromItem(item, textField);

        if (itemTextRender) {
            defaulRender = itemTextRender({ item, ...other }, () => defaulRender);
        }

        return defaulRender;
    }

    defaultItemRender(id, { item, multiple, selected, checked, onChange, onClick, onKeyDown, disabled }) {
        const { textField } = this.props;

        return (
            <MenuItem
                key={id}
                id={this.getOptionId(id)}
                role="option"
                selected={selected}
                disabled={disabled}
                onClick={onClick}
                onKeyDown={onKeyDown}
            >
                {multiple && (
                    <CheckBox
                        aria-label={getDataKeyItem(item, textField)}
                        className="mr-3"
                        checked={checked}
                        onChange={onChange}
                    />
                )}
                {this.renderItemText(item)}
            </MenuItem>
        );
    }

    renderItem(index, item, selected, multiple) {
        const { itemRender, dataItemKey } = this.props;
        const id = getDataKeyItem(item, dataItemKey);

        const menuItemProps = {
            selected: selected && !multiple,
            onClick: this.handleMenuItemClick(item),
            onKeyDown: this.handleMenuItemKeyDown(item)
        };
        const checkBoxProps = {
            checked: selected,
            onChange: this.handleMenuItemClick(item)
        };
        const itemRenderProps = {
            index,
            item,
            multiple,
            disabled: item.disabled || false,
            ...menuItemProps,
            ...checkBoxProps
        };

        if (itemRender) {
            return itemRender(itemRenderProps, this.defaultItemRender.bind(this));
        }

        return this.defaultItemRender(id, itemRenderProps);
    }

    getOptionId(index) {
        return `option-${this.optionId}-${index}`;
    }

    render() {
        const {
            ['aria-label']: ariaLabel,
            classes,
            className,
            currentLength,
            data,
            dataItemKey,
            defaultValue,
            disabled,
            error,
            errorText,
            helpText,
            innerRef,
            label,
            maxLength,
            menuProps,
            multiple,
            name,
            open,
            placeholder,
            showToggle,
            styles,
            tabIndex,
            textField,
            value: valueProps,
            variant,
            toggleIcon: ToggleIcon = ArrowDownIcon,
            toggleProps
        } = this.props;
        const { autoFocus } = this.state;

        let displayText;
        let value = valueProps !== undefined ? valueProps : defaultValue;

        if (multiple) {
            displayText = [];
            value = Array.isArray(valueProps) ? [...valueProps] : [defaultValue];
        }

        const isOpen = this.isOpenControlled && this.anchorEl.current ? open : this.state.isOpen;
        const rootClasses = classNames('onsolve-dropdown', className, classes.root);
        const displayTextClasses = classNames(
            {
                'onsolve-dropdown__text--link': variant === 'link'
            },
            classes.displayText
        );

        const items = _.map(data, (item, index) => {
            const text = getTextFromItem(item, textField);
            const id = getDataKeyItem(item, dataItemKey);

            let selected = false;

            if (multiple) {
                selected = _.some(value, v => v === id);
                if (selected) {
                    displayText.push(text);
                }
            } else {
                selected = value === id;
                if (selected) {
                    displayText = text;
                }
            }

            return this.renderItem(index, item, selected, multiple);
        });

        return (
            <>
                <InputFieldControl className={classNames(rootClasses, classes)}>
                    <InputFieldGroup>
                        {label && <InputFieldLabel>{label}</InputFieldLabel>}
                        <InputFieldContainer
                            tabIndex={tabIndex}
                            variant={variant === 'link' ? 'naked' : 'standard'}
                            error={error}
                            active={isOpen}
                            ref={ReactUtils.setMultipleRefs(this.anchorEl, innerRef)}
                            onKeyDown={this.handleKeyDown}
                            onKeyUp={this.handleKeyUp}
                        >
                            <DropdownInput
                                name={name}
                                role="combobox"
                                aria-expanded={isOpen}
                                aria-owns={this.listBoxId}
                                aria-haspopup="listbox"
                                aria-label={ariaLabel || label}
                                className={displayTextClasses}
                                value={this.renderValue(displayText)}
                                placeholder={placeholder}
                                disabled={disabled}
                                onClick={this.handleToggle}
                            />
                            {showToggle && (
                                <InputFieldToggle
                                    icon={ToggleIcon}
                                    disabled={disabled}
                                    onClick={this.handleToggle}
                                    {...toggleProps}
                                />
                            )}
                        </InputFieldContainer>
                    </InputFieldGroup>
                    <InputFieldMessage
                        error={error}
                        errorText={errorText}
                        helpText={helpText}
                        currentLength={currentLength}
                        maxLength={maxLength}
                    />
                </InputFieldControl>
                <Menu
                    id={this.listBoxId}
                    anchorEl={this.anchorEl.current}
                    autoFocus={autoFocus}
                    styles={styles.scrollbar}
                    open={isOpen}
                    role="listbox"
                    aria-label={ariaLabel || label}
                    {...menuProps}
                    onClose={this.handleMenuClose}
                    onKeyDown={this.handleMenuKeyDown}
                    onKeyUp={this.handleMenuKeyUp}
                >
                    {items}
                </Menu>
            </>
        );
    }
}

Dropdown.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(Object)]).isRequired,
    dataItemKey: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.node,
    helpText: PropTypes.node,
    id: PropTypes.string,
    innerRef: PropTypes.object,
    itemRender: PropTypes.func,
    itemTextRender: PropTypes.func,
    label: PropTypes.node,
    menuProps: PropTypes.shape({
        position: PropTypes.oneOf(['left', 'right', 'center'])
    }),
    multiple: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    open: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showToggle: PropTypes.bool,
    styles: PropTypes.object,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    textField: PropTypes.string,
    toggleIcon: PropTypes.func,
    toggleProps: PropTypes.shape({
        hoverColor: PropTypes.string,
        defaultColor: PropTypes.string
    }),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ]),
    valueRender: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'link', 'button']).isRequired
};

Dropdown.defaultProps = {
    id: 'dropdown',
    classes: {},
    dataItemKey: 'id',
    showToggle: true,
    menuProps: {
        position: 'center'
    },
    styles: {},
    tabIndex: '0',
    textField: 'value',
    variant: 'standard'
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
