import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventListener from 'react-event-listener';

import { fp as _ } from '../utils';
import StepperButton from './StepperButton';

class StepperHeader extends Component {
    constructor(props, context) {
        super(props, context);

        this.headerRef = React.createRef();
        this.handleResize = _.debounce(this.scrollSelectedIntoView, 100);
        this.stepButtonsRefs = props.steps.map(() => React.createRef());
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeStep !== prevProps.activeStep) {
            this.scrollSelectedIntoView();
        }
    }

    scrollSelectedIntoView = () => {
        const { activeStep } = this.props;
        const { stepsParams, stepParams } = this.getStepsParams(activeStep);

        if (!stepsParams || !stepParams) {
            return;
        }

        if (stepParams.left < stepsParams.left) {
            const scrollLeft = stepsParams.scrollLeft + (stepParams.left - stepsParams.left);

            this.scroll(scrollLeft);
        } else if (stepParams.right > stepsParams.right) {
            const scrollLeft = stepsParams.scrollLeft + (stepParams.right - stepsParams.right);

            this.scroll(scrollLeft);
        }
    };

    getStepsParams(value) {
        let stepsParams;
        let stepParams;

        const rootElement = this.headerRef.current;

        if (rootElement) {
            const { left, right } = rootElement.getBoundingClientRect();
            const { firstElementChild: element } = rootElement;

            stepsParams = { scrollLeft: rootElement.scrollLeft, left, right };

            if (value >= 0 && value <= element.childNodes.length - 1) {
                const step = element.childNodes[value];

                stepParams = step && step.getBoundingClientRect();
            }
        }

        return { stepsParams, stepParams };
    }

    scroll(value) {
        this.headerRef.current.scrollLeft = value;
    }

    handleStepChange(e, { index, step }) {
        const { activeStep, onStepChange } = this.props;
        const { disabled, completed } = step;

        if (disabled || !completed) {
            return;
        }

        if (index === activeStep) {
            return;
        }

        if (onStepChange) {
            onStepChange(index);
        }
    }

    handleLeftArrowKey = index => {
        if (index > 0) {
            this.stepButtonsRefs[index - 1].current.setFocus();
        }
    };

    handleRightArrowKey = index => {
        const { steps } = this.props;
        const nextStep = steps[index + 1];

        if (nextStep) {
            this.stepButtonsRefs[index + 1].current.setFocus();
        }
    };

    render() {
        const { steps, activeStep, ...other } = this.props;

        this.children = _.map(steps, (step, index) => {
            return (
                <StepperButton
                    key={index}
                    index={index}
                    active={index === activeStep}
                    onClick={e => this.handleStepChange(e, { index, step })}
                    onLeftArrowKey={this.handleLeftArrowKey}
                    onRightArrowKey={this.handleRightArrowKey}
                    ref={this.stepButtonsRefs[index]}
                    {...step}
                    {...other}
                />
            );
        });

        return (
            <div className="onsolve-stepper__header" ref={this.headerRef}>
                <EventListener target="window" onResize={this.handleResize} />
                <ul className="onsolve-stepper__steps" role="tablist">
                    {this.children}
                </ul>
            </div>
        );
    }
}

StepperHeader.propTypes = {
    activeStep: PropTypes.number,
    onStepChange: PropTypes.func,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            title: PropTypes.node.isRequired,
            completed: PropTypes.bool
        })
    ).isRequired
};

StepperHeader.defaultProps = {
    activeStep: 0
};

StepperHeader.displayName = 'StepperHeader';

export default StepperHeader;
