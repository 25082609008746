/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LoadingSpinner } from '../../../../Loading';
import { Typography } from '../../../../Typography';
import { componentsTranslations } from '../../../../translations';

import { StyleButton } from '../../../components';
import { REASONS } from '../../../constants';
import { fileValidation } from '../../../utils';
import { fp as _ } from '../../../../utils';

import { ImageUploadModal } from '../ImageUploadModal';

const rejectedErrorToReason = {
    HasMaxSizeInPixelsValidator: REASONS.WIDTH_HEIGHT,
    IsImageValidator: REASONS.TYPE,
    StreamHasMaxLengthValidator: REASONS.SIZE
};

const imagePromise = file => {
    return new Promise((resolve, reject) => {
        try {
            const preview = URL.createObjectURL(file);
            const img = new Image();

            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = preview;
        } catch (e) {
            reject(e);
        }
    });
};

const ImageUpload = forwardRef(
    (
        { iconComponent, onToggle, style, intl, translate, contentImageConstraints, onContentImageUpload, disabled },
        ref
    ) => {
        if (!onContentImageUpload || !contentImageConstraints) {
            return null;
        }

        const contentImageInputRef = useRef(null);
        const [isRejectedImageUploadModal, setIsRejectedImageUploadModal] = useState(false);
        const [rejectedError, setRejectedError] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const { allowedMimeTypes, maxFileSizeInBytes } = contentImageConstraints;
        const inputAllowedMimeTypes = allowedMimeTypes ? allowedMimeTypes.join(', ') : 'image/*';
        const isActive = false;

        const onToggleClick = (/* blockType, isActive */) => {
            contentImageInputRef.current.click();
        };

        const handleRejectedImageUploadModal = () => {
            setIsRejectedImageUploadModal(false);
        };

        const handleChangeContentImageInput = event => {
            event.stopPropagation();
            event.preventDefault();
            const [file] = event.target.files;

            if (file && onContentImageUpload) {
                imagePromise(file)
                    .then((/* { height, width } */) => {
                        setIsLoading(true);

                        return fileValidation(file, allowedMimeTypes, maxFileSizeInBytes);
                    })
                    .then(() => {
                        return Promise.allSettled([onContentImageUpload(file)]);
                    })
                    .then(items => {
                        const [item] = items;

                        if (item.status === 'rejected') {
                            // INFO: IE11 doesn't support get method for FormData so you should return file from onFileUpload promise
                            if (!_.has(item, 'reason.response.data.errors')) {
                                return Promise.reject();
                            }

                            const errorReason =
                                item.reason.response.data.errors.length > 0
                                    ? item.reason.response.data.errors[0].errorCode
                                    : '';

                            if (!errorReason) {
                                return Promise.reject();
                            }

                            return Promise.reject({ rejectReason: rejectedErrorToReason[errorReason] });
                        }
                        return item.value;
                    })
                    .then(response => {
                        if (!response.url) {
                            return Promise.reject();
                        }
                        onToggle(style, isActive, response.url, response.displayName);
                        setIsLoading(false);
                        contentImageInputRef.current.value = '';
                    })
                    .catch(error => {
                        if (_.has(error, 'rejectReason')) {
                            setRejectedError({ fileName: file.name, rejectReason: error.rejectReason });
                        }
                        setIsRejectedImageUploadModal(true);
                        setIsLoading(false);
                        contentImageInputRef.current.value = '';
                    });
            }
        };

        return (
            <Fragment>
                {isLoading ? (
                    <div className="onsolve-wysiwyg-editor__navigation_image">
                        <LoadingSpinner color="primary" className="onsolve-loading__icon" size="md" />
                    </div>
                ) : (
                    <StyleButton
                        isActive={isActive}
                        onToggle={onToggleClick}
                        style={style}
                        intl={intl}
                        translate={translate}
                        iconComponent={iconComponent}
                        ref={ref}
                        disabled={disabled}
                    />
                )}
                <input
                    type="file"
                    ref={contentImageInputRef}
                    style={{ display: 'none' }}
                    accept={inputAllowedMimeTypes}
                    onChange={handleChangeContentImageInput}
                />
                <ImageUploadModal isOpen={isRejectedImageUploadModal} onCancel={handleRejectedImageUploadModal}>
                    {rejectedError && (
                        <Typography variant="p14">
                            <FormattedMessage
                                {...componentsTranslations.ng_file_upload_rejected_text}
                                values={{ ...rejectedError }}
                            />
                        </Typography>
                    )}
                    <Typography variant="p14">
                        <FormattedMessage {...componentsTranslations.ng_file_upload_select_different} />
                    </Typography>
                </ImageUploadModal>
            </Fragment>
        );
    }
);

ImageUpload.propTypes = {
    contentImageConstraints: PropTypes.object,
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    onContentImageUpload: PropTypes.func,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

ImageUpload.displayName = 'ImageUpload';

export default ImageUpload;
