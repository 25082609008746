import React from 'react';

import SvgIcon from './SvgIcon';

const Pause = props => (
    <SvgIcon {...props}>
        <g transform="translate(2.000000, 1.000000)">
            <path d="M0,13.4509804 L3.84313725,13.4509804 L3.84313725,0 L0,0 L0,13.4509804 Z M7.68627451,0 L7.68627451,13.4509804 L11.5294118,13.4509804 L11.5294118,0 L7.68627451,0 Z" />
        </g>
    </SvgIcon>
);

Pause.displayName = 'PauseIcon';

export default Pause;
