import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { keyCodes } from '../../common/constants';
import { DomUtils, ReactUtils, KeyboardNavigationUtils } from '../utils';

function getActiveItemIndex(children) {
    let activeItemIndex = -1;

    React.Children.forEach(children, (child, index) => {
        if (!React.isValidElement(child)) {
            return;
        }

        const { disabled, variant, selected } = child.props;

        if (!disabled && ['divider', 'header'].indexOf(variant) === -1) {
            if (selected || activeItemIndex === -1) {
                activeItemIndex = index;
            }
        }
    });

    return activeItemIndex;
}

class MenuList extends PureComponent {
    constructor(props) {
        super(props);

        this.menuListRef = React.createRef();
    }

    handleKeyDown = e => {
        const { END, HOME, UP, DOWN } = keyCodes;
        const { onKeyDown } = this.props;

        const menuListElement = this.menuListRef.current;
        const currentElement = document.activeElement;

        const charCode = DomUtils.getCharCode(e);

        if (charCode === END) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.previousItem, menuListElement);
        } else if (charCode === HOME) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.nextItem, menuListElement);
        } else if (charCode === UP) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.previousItem, menuListElement, currentElement);
        } else if (charCode === DOWN) {
            e.preventDefault();
            KeyboardNavigationUtils.moveToItem(KeyboardNavigationUtils.nextItem, menuListElement, currentElement);
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    render() {
        const { id, role, autoFocus, children, className, innerRef, ['aria-label']: ariaLabel, onKeyUp } = this.props;
        const rootClasses = classNames('onsolve-menu__list', className);
        const tabIndex = autoFocus ? 0 : null;
        const activeItemIndex = getActiveItemIndex(children);
        const items = React.Children.map(children, (child, index) => {
            if (index === activeItemIndex) {
                const childProps = {
                    autoFocus
                };

                return React.cloneElement(child, childProps);
            }

            return child;
        });

        return (
            <ul
                id={id}
                ref={ReactUtils.setMultipleRefs(this.menuListRef, innerRef)}
                role={role}
                tabIndex={tabIndex}
                className={rootClasses}
                aria-label={ariaLabel}
                onKeyDown={this.handleKeyDown}
                onKeyUp={onKeyUp}
            >
                {items}
            </ul>
        );
    }
}

MenuList.propTypes = {
    /**
    Specifies focus the first or selected menu item
    */
    autoFocus: PropTypes.bool,
    /**
    Specifies menu contents
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies identifier of menu list
    */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     */
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
    Callback function on keyDown event.
    */
    onKeyDown: PropTypes.func,
    /**
    Callback function on keyUp event.
    */
    onKeyUp: PropTypes.func,
    /**
    Specifies the role classification of element
    */
    role: PropTypes.string
};

MenuList.defaultProps = {
    role: 'menu'
};

MenuList.displayName = 'MenuList';

export default MenuList;
