import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../Focusable';
import OnsolvePropTypes from '../utils/PropTypes';

const IconButton = React.forwardRef((props, ref) => {
    const { className, children, size, ...other } = props;

    const handleKeyDown = e => {
        const { onKeyDown } = props;

        //FIX for UI-1307, If you want to stop propagation,Please do it from outside handler
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    const handleClick = e => {
        const { onClick } = props;

        e.stopPropagation();

        if (onClick) {
            onClick(e);
        }
    };

    return (
        <Focusable
            render={({ classes }) => (
                <button
                    type="button"
                    className={classNames('btn onsolve-icon-btn', className, classes, {
                        [`onsolve-icon-btn--${size}`]: size
                    })}
                    ref={ref}
                    {...other}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                >
                    {children}
                </button>
            )}
        />
    );
});

IconButton.propTypes = {
    /**
    The content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Callback function fired on click.
    */
    onClick: PropTypes.func,
    /**
    Callback function fired on key down.
    */
    onKeyDown: PropTypes.func,
    /**
    Specifies a component size.
    */
    size: OnsolvePropTypes.buttonSize
};

IconButton.defaultProps = {
    size: 'md'
};

// If the callback function has no .name or .displayName, the display name becomes just "ForwardRef".
IconButton.displayName = 'IconButton';

export default IconButton;
