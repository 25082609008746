import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import withScrollbar from './withScrollbar';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.scrollbar.scrollToTop();
        }
    }

    render() {
        return null;
    }
}

ScrollToTop.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string
    }),
    scrollbar: PropTypes.object
};

ScrollToTop.displayName = 'ScrollToTop';

export default withRouter(withScrollbar(ScrollToTop));
