import React from 'react';
import PropTypes from 'prop-types';
import { SCRIPTS_MAP } from '../../constants';

const Script = props => {
    const { children, contentState, entityKey } = props;
    const entity = contentState.getEntity(entityKey);
    const Component = SCRIPTS_MAP[entity.getType()].element;

    return <Component>{children}</Component>;
};

Script.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    contentState: PropTypes.shape({
        getEntity: PropTypes.func.isRequired
    }).isRequired,
    entityKey: PropTypes.string.isRequired
};

Script.displayName = 'Script';

export default Script;
