import React from 'react';

import SvgIcon from './SvgIcon';

const AddPlus = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 Z M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8.5,7.5 L12,7.5 L12,8.5 L8.5,8.5 L8.5,12 L7.5,12 L7.5,8.5 L4,8.5 L4,7.5 L7.5,7.5 L7.5,4 L8.5,4 L8.5,7.5 Z"
            />
        </SvgIcon>
    );
};

AddPlus.displayName = 'AddPlusIcon';

export default AddPlus;
