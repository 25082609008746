import React from 'react';
import SvgIcon from './SvgIcon';

const User = props => (
    <SvgIcon {...props}>
        <g stroke="none" fillRule="evenodd">
            <path
                d="M8,0C5.8,0,4.1,1.8,4.1,4c0,2.4,1.8,5,3.9,5s3.9-2.6,3.9-5C11.9,1.8,10.2,0,8,0z M8,7.9C6.6,7.9,5.2,5.9,5.2,4
		c0-1.6,1.3-2.9,2.8-2.9s2.8,1.3,2.8,2.9C10.8,5.9,9.4,7.9,8,7.9z"
            />
            <path
                d="M11.3,9.5C11,9.4,10.9,9,11,8.7c0.1-0.3,0.5-0.4,0.8-0.3c1.9,0.9,3,2.9,3,5c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.3-0.8,0.7-1.7,1.1
	c-1.4,0.7-3,1-5,1s-3.7-0.4-5-1c-0.8-0.4-1.4-0.8-1.7-1.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-2.1,1.2-4,3-5c0.3-0.1,0.6,0,0.8,0.3
	s0,0.6-0.3,0.8c-1.4,0.7-2.3,2.1-2.4,3.7c0,0,0.1,0.1,0.1,0.1c0.3,0.2,0.6,0.4,1,0.6c1.2,0.6,2.7,0.9,4.5,0.9s3.3-0.4,4.5-0.9
	c0.4-0.2,0.8-0.4,1-0.6c0.1,0,0.1-0.1,0.1-0.1C13.6,11.6,12.7,10.2,11.3,9.5z"
            />
        </g>
    </SvgIcon>
);

User.displayName = 'UserIcon';

export default User;
