import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CircularProgress = ({ className, size, color, suffix, value, maxValue }) => {
    const valueToBeDisplayed = Math.round((value / maxValue) * 100) || 0;
    const pClass = `p${valueToBeDisplayed > 100 ? 100 : valueToBeDisplayed}`;
    const rootClasses = classNames('c100', pClass, size, color, className);

    return (
        <div className="onsolve-progress-circular">
            <div className={rootClasses}>
                <span className="progress-text">{`${valueToBeDisplayed}${suffix}`}</span>
                <div className="slice">
                    <div className="bar" />
                    <div className="fill" />
                </div>
            </div>
        </div>
    );
};

CircularProgress.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'dark', 'light', 'white']),
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['sm', 'lg', 'xl']),
    suffix: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

CircularProgress.defaultProps = {
    size: 'sm',
    color: 'primary',
    suffix: '%',
    maxValue: 100
};

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
