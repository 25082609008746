import React from 'react';
import SvgIcon from './SvgIcon';
const FileZip = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    fill="#e6e6e6"
                    d="M14.209 16h-12.417c-0.404 0-0.73-0.311-0.73-0.696v-14.609c0-0.384 0.327-0.696 0.73-0.696h12.417c0.404 0 0.73 0.311 0.73 0.696v14.609c0 0.384-0.327 0.696-0.73 0.696z"
                />
                <path fill="#444" d="M6.174 0h3.652v7.652h-3.652v-7.652z" />
                <path fill="#e6e6e6" d="M8 0h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M6.904 0.696h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M8 1.391h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M6.904 2.087h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M8 2.783h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M6.904 3.478h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M8 4.174h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M6.904 4.87h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M8 5.565h1.096v1h-1.096v-1z" />
                <path fill="#e6e6e6" d="M6.904 6.261h1.096v1h-1.096v-1z" />
                <path
                    fill="#b3b3b3"
                    d="M6.904 7.652v1.739c0 0.576 0.49 1.043 1.096 1.043s1.096-0.467 1.096-1.043v-1.739h-2.191zM8.365 9.391h-0.73v-0.696h0.73v0.696z"
                />
            </g>
        </SvgIcon>
    );
};

FileZip.displayName = 'FileZipIcon';

export default FileZip;
