import React from 'react';

import SvgIcon from './SvgIcon';

const GroupList = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M6 3.5c0-0.276 0.224-0.5 0.5-0.5h9c0.276 0 0.5 0.224 0.5 0.5v4c0 0.276-0.224 0.5-0.5 0.5h-9c-0.276 0-0.5-0.224-0.5-0.5v-4zM7 4v3h8v-3h-8zM6 11.5c0-0.276 0.224-0.5 0.5-0.5h9c0.276 0 0.5 0.224 0.5 0.5v4c0 0.276-0.224 0.5-0.5 0.5h-9c-0.276 0-0.5-0.224-0.5-0.5v-4zM7 12v3h8v-3h-8zM1 5h2.5c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-2.5v7h2.5c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-3c-0.276 0-0.5-0.224-0.5-0.5v-13c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v4.5z"
            />
        </SvgIcon>
    );
};

GroupList.displayName = 'GroupListIcon';

export default GroupList;
