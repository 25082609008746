import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { fp as _ } from '../../../../utils';
import { Menu, MenuItem } from '../../../../Menu';
import { componentsTranslations } from '../../../../translations';

import TreeNode from '../tree-node';

const DROPDOWN_MENU_MAX_HEIGHT = 192;

const shouldRenderNode = (node, searchModeOn, data) => {
    if (searchModeOn || node.expanded) {
        return true;
    }

    const parent = node._parent && data.get(node._parent);

    // if it has a parent, then check parent's state.
    // otherwise root nodes are always rendered
    return !parent || parent.expanded;
};

const Loader = () => (
    <span className="searchLoader">
        <FormattedMessage {...componentsTranslations.ng_components_loading} /> ...
    </span>
);

Loader.displayName = 'Loader';

class Tree extends Component {
    constructor(props) {
        super(props);

        this.currentPage = 1;
        this.computeInstanceProps(props, true);

        this.state = {
            items: this.getCurrentItems()
        };
    }

    componentDidUpdate(prevProps) {
        const { activeDescendant, searchTerm } = this.props;
        const { items } = this.state;
        const prevVisibleNodes = [...this.allVisibleNodes];
        const hasSameActiveDescendant = prevProps.activeDescendant === this.props.activeDescendant;

        this.computeInstanceProps(this.props, !hasSameActiveDescendant);

        const newItems = this.getCurrentItems();
        const dif = _.differenceWith(items, newItems, _.isEqual);

        if (
            dif.length ||
            !hasSameActiveDescendant ||
            prevVisibleNodes.length !== this.allVisibleNodes.length ||
            prevProps.searchTerm != searchTerm
        ) {
            this.setState({ items: newItems }, () => {
                if (hasSameActiveDescendant) {
                    return;
                }
                const {
                    scrollableTarget: { current: listNode }
                } = this.state;
                const activeLi = activeDescendant && document && document.getElementById(activeDescendant);

                if (activeLi && listNode) {
                    listNode.parentNode.scrollTop =
                        activeLi.offsetTop - (listNode.parentNode.clientHeight - activeLi.clientHeight) / 2;
                }
            });
        }
    }

    componentDidMount = () => {
        this.setState({ scrollableTarget: this.node.menuListRef });
    };

    computeInstanceProps = (props, checkActiveDescendant) => {
        this.allVisibleNodes = this.getNodes(props);
        this.totalPages = Math.ceil(this.allVisibleNodes.length / this.props.pageSize);
        if (checkActiveDescendant && props.activeDescendant) {
            const currentId = props.activeDescendant.replace(/_div$/, '');
            const focusIndex = this.allVisibleNodes.findIndex(n => n.key === currentId) + 1;

            this.currentPage = focusIndex > 0 ? Math.ceil(focusIndex / this.props.pageSize) : 1;
        }
    };

    getCurrentItems = () => {
        return this.props.hasInfiniteScroll ? this.allVisibleNodes.slice(0, this.props.pageSize) : this.allVisibleNodes;
    };

    getNodes = props => {
        const {
            data,
            keepTreeOnSearch,
            keepChildrenOnSearch,
            searchModeOn,
            variant,
            showPartiallySelected,
            readOnly,
            onAction,
            onChange,
            onCheckboxChange,
            onNodeToggle,
            activeDescendant,
            clientId,
            searchTerm
        } = props;
        const items = [];

        data.forEach(node => {
            if (shouldRenderNode(node, searchModeOn, data)) {
                items.push(
                    <TreeNode
                        keepTreeOnSearch={keepTreeOnSearch}
                        keepChildrenOnSearch={keepChildrenOnSearch}
                        key={node._id}
                        {...node}
                        searchModeOn={searchModeOn}
                        onChange={onChange}
                        onCheckboxChange={onCheckboxChange}
                        onNodeToggle={onNodeToggle}
                        onAction={onAction}
                        variant={variant}
                        showPartiallySelected={showPartiallySelected}
                        readOnly={readOnly}
                        clientId={clientId}
                        activeDescendant={activeDescendant}
                        searchTerm={searchTerm}
                    />
                );
            }
        });
        return items;
    };

    hasMore = () => this.currentPage < this.totalPages;

    loadMore = () => {
        this.currentPage = this.currentPage + 1;
        const nextItems = this.allVisibleNodes.slice(0, this.currentPage * this.props.pageSize);

        this.setState({ items: nextItems });
    };

    setNodeRef = node => {
        this.node = node;
    };

    getAriaAttributes = () => {
        const { variant, intl } = this.props;

        const attributes = {
            /* https://www.w3.org/TR/wai-aria-1.1/#select
             * https://www.w3.org/TR/wai-aria-1.1/#tree */
            role: variant === 'simpleSelect' ? 'listbox' : 'tree',
            'aria-label': intl.formatMessage(componentsTranslations.ng_components_selectDivisions),
            'aria-multiselectable': /multiSelect|hierarchical/.test(variant)
        };

        return attributes;
    };

    handleMenuItemClick = item => () => {
        const { dataItemKey, onCheckboxChange } = this.props;

        onCheckboxChange(item[dataItemKey], true);
    };

    render() {
        const { anchorEl, searchModeOn, hasInfiniteScroll, isOpen, position, variant } = this.props;
        const { scrollableTarget, items } = this.state;

        return (
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                role="listbox"
                className={`onsolve-dropdown-tree-content-root p-0 ${searchModeOn ? 'searchModeOn' : ''}`}
                ref={this.setNodeRef}
                scrollProps={{ horizontal: true, vertical: true, autoHeightMax: DROPDOWN_MENU_MAX_HEIGHT }}
                position={position}
                {...this.getAriaAttributes()}
            >
                {hasInfiniteScroll && scrollableTarget ? (
                    <InfiniteScroll
                        className="onsolve-dropdown-tree-content-root-scroll"
                        dataLength={items.length}
                        next={this.loadMore}
                        hasMore={this.hasMore()}
                        loader={<Loader />}
                        scrollableTarget={scrollableTarget}
                    >
                        {items}
                    </InfiniteScroll>
                ) : (
                    items.map(item => (
                        <MenuItem
                            key={item.key}
                            role={variant === 'simpleSelect' ? 'option' : 'treeitem'}
                            className="p-0"
                            tabIndex={null}
                            disabled={item.props.disabled}
                            onClick={this.handleMenuItemClick(item.props)}
                        >
                            {item}
                        </MenuItem>
                    ))
                )}
            </Menu>
        );
    }
}

Tree.defaultProps = {
    pageSize: 100,
    dataItemKey: 'id',
    position: 'left'
};

Tree.propTypes = {
    activeDescendant: PropTypes.string,
    anchorEl: PropTypes.object,
    clientId: PropTypes.string,
    data: PropTypes.object,
    dataItemKey: PropTypes.string,
    hasInfiniteScroll: PropTypes.bool,
    inheritDisable: PropTypes.bool,
    intl: PropTypes.object,
    isOpen: PropTypes.bool,
    keepChildrenOnSearch: PropTypes.bool,
    keepTreeOnSearch: PropTypes.bool,
    onAction: PropTypes.func,
    onChange: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onNodeToggle: PropTypes.func,
    pageSize: PropTypes.number,
    position: PropTypes.oneOf(['left', 'right', 'center']),
    readOnly: PropTypes.bool,
    searchModeOn: PropTypes.bool,
    searchTerm: PropTypes.string,
    showPartiallySelected: PropTypes.bool,
    variant: PropTypes.oneOf(['multiSelect', 'simpleSelect', 'radioSelect', 'hierarchical'])
};

Tree.displayName = 'Tree';

export default Tree;
