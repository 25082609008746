import React from 'react';
import SvgIcon from './SvgIcon';

const FormatAlignCenter = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M2 12v2h10v-2H2zm0-8v2h10V4H2zm-2 6h14V8H0v2zM0 0v2h14V0H0z" fillRule="nonzero" />
        </SvgIcon>
    );
};

FormatAlignCenter.displayName = 'FormatAlignCenterIcon';

export default FormatAlignCenter;
