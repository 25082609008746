import React from 'react';
import PropTypes from 'prop-types';

import SwitcherBase from './SwitcherBase';
import { CheckBoxIcon, CheckBoxBlankIcon, CheckBoxIntermediateIcon } from '../Icons';

const CheckBox = props => {
    const { checkedIcon, icon, intermediate, intermediateIcon, ...other } = props;

    return (
        <SwitcherBase
            type="checkbox"
            icon={icon}
            checkedIcon={intermediate ? intermediateIcon : checkedIcon}
            {...other}
        />
    );
};

CheckBox.propTypes = {
    /**
    Specifies the custom component to replace checked state view
    */
    checkedIcon: PropTypes.node,
    /**
    Specifies the custom component to replace default state view
    */
    icon: PropTypes.node,
    /**
    Specifies if component should use partial select icon for checked state
    */
    intermediate: PropTypes.bool,
    /**
    Specifies the custom intermediate icon for checked state
    */
    intermediateIcon: PropTypes.node
};

CheckBox.defaultProps = {
    checkedIcon: <CheckBoxIcon />,
    icon: <CheckBoxBlankIcon />,
    intermediate: false,
    intermediateIcon: <CheckBoxIntermediateIcon />
};

CheckBox.displayName = 'CheckBox';

export default CheckBox;
