import React, { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EditorState } from 'draft-js';

import { Scrollbars } from '../Scrollbars';

import { addImageTool, addEditorTypeToolFactory, useEditorType } from './plugins';
import WysiwygEditorBase from './WysiwygEditorBase';
import WysiwygToolbar from './WysiwygToolbar';

const WysiwygEditor = ({
    ariaLabel,
    autoFocus,
    className,
    contentImageConstraints,
    editorValue,
    enableFontColorPicker,
    enableFontSizeMenu,
    enableStrikethrough,
    enableIndent,
    enableEditorTypeChange,
    noBorder,
    onBlur,
    onChange,
    onContentImageUpload,
    placeholder,
    readOnly,
    scrollable,
    wrapperClassName,
    ...restEditorProps
}) => {
    const editor = useRef(null);
    const { editorType, isToolbarDisabled, onEditorTypeChange } = useEditorType();

    const [isEditorFocused, setEditorFocused] = useState(false);
    const [readOnlyEditorValue, setReadOnlyEditorValue] = useState(editorValue);

    useEffect(() => {
        // Used for dynamic change readOnly prop
        handleReadOnlyChange(editorValue);
    }, [editorValue]);

    const focusEditor = () => {
        editor.current.focus();
    };

    const handleReadOnlyChange = editorState => {
        if (editorState && editorState.getDecorator()) {
            setReadOnlyEditorValue(editorState);
        }
    };

    const handleFocus = () => {
        setEditorFocused(true);
    };

    const handleBlur = () => {
        setEditorFocused(false);

        if (onBlur) {
            setTimeout(onBlur, 0);
        }
    };

    const wrapperStyles = classNames('onsolve-wysiwyg-editor__wrapper', wrapperClassName, {
        'onsolve-wysiwyg-editor__wrapper--no-border': noBorder
    });
    const scrollStyles = classNames('onsolve-wysiwyg-editor-scroll', {
        'onsolve-wysiwyg-editor-scroll--no-tooltip-bar': readOnly,
        'onsolve-wysiwyg-editor-scroll--focused': !readOnly && isEditorFocused
    });
    const editorStyles = classNames('onsolve-wysiwyg-editor__text-block', className, {
        'onsolve-wysiwyg-editor__text-block--no-border': noBorder
    });

    const Wrapper = scrollable ? Scrollbars : Fragment;

    let editorState;
    let handleChange;

    if (readOnly) {
        editorState = readOnlyEditorValue;
        handleChange = handleReadOnlyChange;
    } else {
        editorState = editorValue ? editorValue : EditorState.createEmpty();
        handleChange = onChange;
    }

    return (
        <div className={wrapperStyles}>
            {!readOnly && (
                <WysiwygToolbar
                    editorState={editorState}
                    onChange={handleChange}
                    enableFontColorPicker={enableFontColorPicker}
                    enableFontSizeMenu={enableFontSizeMenu}
                    enableStrikethrough={enableStrikethrough}
                    enableIndent={enableIndent}
                    disabled={isToolbarDisabled}
                >
                    {[
                        addImageTool(onContentImageUpload, contentImageConstraints),
                        enableEditorTypeChange && addEditorTypeToolFactory(editorType, onEditorTypeChange)
                    ]}
                </WysiwygToolbar>
            )}

            <div className={scrollStyles}>
                <Wrapper className="onsolve-wysiwyg-editor-scroll__content" autoHide>
                    <div className={editorStyles} onClick={focusEditor}>
                        <WysiwygEditorBase
                            autoFocus={autoFocus}
                            editorState={editorState}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder={placeholder}
                            ref={editor}
                            spellCheck={true}
                            readOnly={readOnly}
                            tabIndex={0}
                            ariaLabel={ariaLabel}
                            {...restEditorProps}
                        />
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

WysiwygEditor.propTypes = {
    /**
    Specifies an aria-label attribute for the component.
    */
    ariaLabel: PropTypes.string,
    /**
    Specifies an auto focus to the text input field component.
    */
    autoFocus: PropTypes.bool,
    /**
    Applied css classes to the text input field component.
    */
    className: PropTypes.string,
    /**
    Specifies a custom image constraints object that contains: target, allowed MIME types, maximum file size in bytes.
    */
    contentImageConstraints: PropTypes.object,
    /**
    Entered value of the component. Important: Support only specific converted object using exported function `convertJsonToWysiwygValue` (to convert to json object use: convertWysiwygValueToJson) or null.
    */
    editorValue: PropTypes.object,
    /**
    Specifies that toolbar view editor type control.
    */
    enableEditorTypeChange: PropTypes.bool,
    /**
    Specifies that toolbar view color font style button.
    */
    enableFontColorPicker: PropTypes.bool,
    /**
    Specifies that toolbar view font size button.
    */
    enableFontSizeMenu: PropTypes.bool,
    /**
    Specifies that toolbar view indent buttons.
    */
    enableIndent: PropTypes.bool,
    /**
    Specifies that toolbar view strike-through font style button.
    */
    enableStrikethrough: PropTypes.bool,
    /**
    Specifies that an text input field display without border.
    */
    noBorder: PropTypes.bool,
    /**
    Callback function on blur event of text input field.
    */
    onBlur: PropTypes.func,
    /**
    Callback function on change event of text input field.
    */
    onChange: PropTypes.func,
    /**
    Callback function on image upload event. The function receives a `file` to be sent to the server or processed in some other way. The function should return an object with file `url` and `displayName` values.
    */
    onContentImageUpload: PropTypes.func,
    /**
    The short hint displayed in the text input field before the user enters a value.
    */
    placeholder: PropTypes.string,
    /**
    Specifies that an text input field is read-only. All panels are hidden in this mode.
    */
    readOnly: PropTypes.bool,
    /**
    Specifies that an text input field can be scrolled.
    */
    scrollable: PropTypes.bool,
    /**
    Applied css classes to the editor (text) block of component. It wrapped editor block and could be Scrollbars or Fragment.
    */
    wrapperClassName: PropTypes.string
};

WysiwygEditor.defaultProps = {
    editorValue: EditorState.createEmpty(),
    enableFontColorPicker: false,
    enableFontSizeMenu: false,
    enableStrikethrough: false,
    enableEditorTypeChange: false,
    enableIndent: false,
    readOnly: false,
    autoFocus: true,
    scrollable: true,
    noBorder: false
};

WysiwygEditor.displayName = 'WysiwygEditor';

export default WysiwygEditor;
