import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fp as _ } from '../utils';

const Highlighter = ({ children = '', className, search }) => {
    if (!search) {
        return children;
    }

    const regex = new RegExp(`(${_.escapeRegExp(search)})`, 'gi');
    const parts = children.split(regex);
    const searchInLowerCase = search.toLowerCase();

    return (
        <>
            {parts.map((part, i) => {
                if (part.toLowerCase() === searchInLowerCase) {
                    return (
                        <mark key={i} className={classNames('onsolve-highlight-text', className)}>
                            {part}
                        </mark>
                    );
                }

                return part;
            })}
        </>
    );
};

Highlighter.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.string,
    /**
    Override or extend the styles applied to the highlighted text.
    */
    className: PropTypes.string,
    /**
    The search string used to find matches (case-insensitive).
    */
    search: PropTypes.string
};

Highlighter.displayName = 'Highlighter';

export default Highlighter;
