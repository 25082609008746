import React from 'react';

import SvgIcon from './SvgIcon';

const Chat = props => {
    return (
        <SvgIcon {...props}>
            <path
                fill="#1ab1e4"
                d="M39.579 0c1.86 0 3.368 1.508 3.368 3.368v25.263c0 1.86-1.508 3.368-3.368 3.368h-31.005c-1.86 0-3.368-1.508-3.368-3.368v-21.895l0.003-0.207c-1.836-2.603-3.572-4.779-5.208-6.53v0h39.579z"
            />
            <path
                fill="#fff"
                d="M17.684 11.368c0 1.163-0.943 2.105-2.105 2.105s-2.105-0.943-2.105-2.105c0-1.163 0.943-2.105 2.105-2.105s2.105 0.943 2.105 2.105z"
            />
            <path
                fill="#fff"
                d="M34.526 11.368c0 1.163-0.943 2.105-2.105 2.105s-2.105-0.943-2.105-2.105c0-1.163 0.943-2.105 2.105-2.105s2.105 0.943 2.105 2.105z"
            />
            <path
                fill="#fff"
                d="M16.842 18.526h15.158c-2.653 2.807-5.179 4.211-7.579 4.211s-4.926-1.404-7.579-4.211z"
            />
        </SvgIcon>
    );
};

Chat.displayName = 'ChatIcon';

export default Chat;
