import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';

import Month from './Month';
import MonthYearDropdown from './MonthYearDropdown';

const DatePicker = React.forwardRef(
    (
        {
            chooseMonthYear,
            className,
            initialValues,
            leftButton,
            maxDate,
            minDate,
            month,
            rightButton,
            size,
            value,
            onChange,
            onMonthChange,
            onYearChange,
            ...other
        },
        ref
    ) => {
        const datePickerClasses = classNames('onsolve-date-picker', `onsolve-date-picker--${size}`, className);

        return (
            <div className={datePickerClasses} ref={ref}>
                <div className="onsolve-date-picker__header">
                    {leftButton}
                    <div className="onsolve-date-picker__caption">
                        {chooseMonthYear ? (
                            <MonthYearDropdown
                                date={month}
                                maxDate={maxDate}
                                minDate={minDate}
                                onYearChange={onYearChange}
                                onMonthChange={onMonthChange}
                            />
                        ) : (
                            format(month, 'MMMM yyyy')
                        )}
                    </div>
                    {rightButton}
                </div>
                <Month
                    month={month}
                    value={value}
                    maxDate={maxDate}
                    minDate={minDate}
                    initialValues={initialValues}
                    onChange={onChange}
                    {...other}
                />
            </div>
        );
    }
);

DatePicker.propTypes = {
    chooseMonthYear: PropTypes.bool,
    className: PropTypes.string,
    initialValues: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    leftButton: PropTypes.node,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    month: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func,
    onMonthChange: PropTypes.func,
    onYearChange: PropTypes.func,
    rightButton: PropTypes.node,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date))])
};

DatePicker.defaultProps = {
    autoFocus: false,
    size: 'md'
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
