import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
    InputFieldContainer,
    InputFieldControl,
    InputFieldGroup,
    InputFieldLabel,
    InputFieldMessage
} from '../InputBase';
import { InputFieldUtils } from '../utils';

const NumericField = ({
    disabled,
    onChange,
    readOnly,
    onFocus,
    onBlur,
    label,
    name,
    value: valueProps,
    defaultValue,
    placeholder,
    maxLength,
    error,
    errorText,
    helpText,
    className,
    hideMaxLength,
    ['aria-label']: ariaLabel,
    variant,
    min,
    max,
    onKeyPress
}) => {
    const [active, setActive] = useState(false);

    const inputRef = React.createRef();

    const getCurrentLength = value => {
        if (value) {
            return String(value).length;
        }
        return 0;
    };

    const handleChange = useCallback(
        e => {
            const { value } = e.target;

            if (disabled) {
                return;
            }

            if (onChange) {
                onChange(e, value);
            }
        },
        [disabled, onChange]
    );

    const handleFocus = useCallback(
        e => {
            if (readOnly) {
                return;
            }

            setActive(true);

            if (onFocus) {
                onFocus(e);
            }
        },
        [readOnly, onFocus]
    );

    const handleBlur = useCallback(
        e => {
            if (readOnly) {
                return;
            }

            setActive(false);

            if (onBlur) {
                onBlur(e);
            }
        },
        [readOnly, onBlur]
    );

    const handleKeyPress = useCallback(
        e => {
            if (onKeyPress) {
                onKeyPress(e);
            }

            const inputValue = InputFieldUtils.getValue(e, inputRef.current);
            const defaultValidator = {
                pattern: variant,
                min: min,
                max: max
            };
            const isInvalidInput = Object.keys(defaultValidator).some(key => {
                return !InputFieldUtils.validators[key](defaultValidator[key], inputValue);
            });

            if (isInvalidInput) {
                e.preventDefault();
            }
        },
        [variant, min, max, onKeyPress, inputRef]
    );

    const value = valueProps === undefined || valueProps === null ? defaultValue : valueProps;

    return (
        <InputFieldControl className={className}>
            <InputFieldGroup>
                {label && <InputFieldLabel name={name}>{label}</InputFieldLabel>}
                <InputFieldContainer error={error} active={active}>
                    {value.toString().length === 0 && (
                        <span className="onsolve-input-field__placeholder">{placeholder}</span>
                    )}
                    <input
                        id={name}
                        ref={inputRef}
                        className="onsolve-input-field__input"
                        name={name}
                        value={value}
                        autoComplete="off"
                        maxLength={maxLength}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        aria-label={ariaLabel}
                    />
                </InputFieldContainer>
            </InputFieldGroup>
            <InputFieldMessage
                error={error}
                errorText={errorText}
                helpText={helpText}
                maxLength={maxLength}
                currentLength={getCurrentLength(value)}
                hideMaxLength={hideMaxLength}
            />
        </InputFieldControl>
    );
};

NumericField.propTypes = {
    /**
    Specifies an aria-label attribute for the component.
    */
    'aria-label': PropTypes.string,
    /**
    Applied css classes to the component.
    */
    className: PropTypes.string,
    /**
    Default value for the component.
    */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
    Specifies an input field is disabled.
    */
    disabled: PropTypes.bool,
    /**
    Specifies if the error should be showed.
    */
    error: PropTypes.bool,
    /**
    Specifies the error message.
    */
    errorText: PropTypes.node,
    /**
    Specifies the helper message.
    */
    helpText: PropTypes.node,
    /**
    Specifies whether to show max limit character counter.
    */
    hideMaxLength: PropTypes.bool,
    /**
    Specifies a label for the component.
    */
    label: PropTypes.node,
    /**
    Specifies the max value.
    */
    max: PropTypes.number,
    /**
    Specifies the max character length for the component.
    */
    maxLength: PropTypes.number,
    /**
    Specifies the min value.
    */
    min: PropTypes.number,
    /**
    Specifies the name for name attribute of an input element.
    */
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    Callback function on blur event of text input.
    */
    onBlur: PropTypes.func,
    /**
    Callback function on change event of text input.
    */
    onChange: PropTypes.func,
    /**
    Callback function on focus event of text input.
    */
    onFocus: PropTypes.func,
    /**
    Callback function on keydown event of text input.
    */
    onKeyDown: PropTypes.func,
    /**
    Callback function on keypress event of text input.
    */
    onKeyPress: PropTypes.func,
    /**
    The short hint displayed in the input before the user enters a value.
    */
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
    Specifies that an input field is read-only.
    */
    readOnly: PropTypes.bool,
    /**
    Entered value of the component.
    */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
    Specifies type of text input.
    */
    variant: PropTypes.oneOf(['numeric', 'decimal']).isRequired
};

NumericField.defaultProps = {
    defaultValue: '',
    variant: 'numeric',
    hideMaxLength: false
};

NumericField.displayName = 'NumericField';

export default NumericField;
