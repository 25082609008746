import React from 'react';
import SvgIcon from './SvgIcon';

const SupportAndTraining = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M14,5.2C13.9,2.3,11.6,0,8.7,0H7.3C4.4,0,2.1,2.3,2,5.2c-1.2,0.7-2,2-2,3.5c0,1.5,0.8,2.8,2,3.5v0.5 C2,14.5,3.5,16,5.3,16H8c0.4,0,0.7-0.3,0.7-0.7S8.4,14.7,8,14.7H5.3c-1.1,0-2-0.9-2-2v-0.1c0.2,0,0.4,0.1,0.7,0.1	c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.3,0.5-0.7V5.4c0-0.3-0.2-0.6-0.5-0.7C4.5,4.7,4.3,4.7,4,4.7c-0.2,0-0.4,0-0.6,0.1 c0.3-1.9,2-3.4,3.9-3.4h1.3c2,0,3.7,1.5,3.9,3.4c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0-0.5,0-0.8,0.1c-0.3,0.1-0.5,0.3-0.5,0.7v6.5 c0,0.3,0.2,0.6,0.5,0.7c0.3,0.1,0.5,0.1,0.8,0.1c2.2,0,4-1.8,4-4C16,7.2,15.2,5.9,14,5.2z"
        />
    </SvgIcon>
);

SupportAndTraining.displayName = 'SupportAndTrainingIcon';

export default SupportAndTraining;
