import React from 'react';

import SvgIcon from './SvgIcon';

const Plus = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M14.125,7.125 L8.875,7.125 L8.875,1.875 C8.875,1.35 8.525,1 8,1 C7.475,1 7.125,1.35 7.125,1.875 L7.125,7.125 L1.875,7.125 C1.35,7.125 1,7.475 1,8 C1,8.525 1.35,8.875 1.875,8.875 L7.125,8.875 L7.125,14.125 C7.125,14.65 7.475,15 8,15 C8.525,15 8.875,14.65 8.875,14.125 L8.875,8.875 L14.125,8.875 C14.65,8.875 15,8.525 15,8 C15,7.475 14.65,7.125 14.125,7.125 Z"
            />
        </SvgIcon>
    );
};

Plus.displayName = 'PlusIcon';

export default Plus;
