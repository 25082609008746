import React from 'react';

import SvgIcon from './SvgIcon';

const ZoomIn = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M6.8 6.8h-4.8v2.4h12v-2.4h-4.8z" />
        </SvgIcon>
    );
};

ZoomIn.displayName = 'ZoomInIcon';

export default ZoomIn;
