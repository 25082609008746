import React from 'react';

import SvgIcon from './SvgIcon';

const DragIndicatorBurger = props => (
    <SvgIcon {...props}>
        <g id="Patterns" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Drag-and-Drop" transform="translate(-583.000000, -1082.000000)" fill="#C8C8C8">
                <g id="reordering-lists-illustration" transform="translate(422.000000, 1004.000000)">
                    <g id="Group-10" transform="translate(25.000000, 60.000000)">
                        <g id="Group-3" transform="translate(115.000000, 0.000000)">
                            <g id="icons/16/Access-Drang-and-Drop-Header" transform="translate(20.964466, 17.000000)">
                                <path
                                    d="M11,12 L8,14 L5,12 L11,12 Z M15,10 L15,11 L1,11 L1,10 L15,10 Z M15,7 L15,8 L1,8 L1,7 L15,7 Z M15,4 L15,5 L1,5 L1,4 L15,4 Z M8,1 L11,3 L5,3 L8,1 Z"
                                    id="Combined-Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

DragIndicatorBurger.displayName = 'DragIndicatorBurgerIcon';

export default DragIndicatorBurger;
