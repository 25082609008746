import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { BlurLoader } from '../BlurLoader';
import { IconButton, ButtonLazy } from '../Button';
import { SlidersHorizontalIcon } from '../Icons';
import { componentsTranslations } from '../translations';

const FiltersButton = ({ variant, disabled, isMobile, i18nBlur, className, ...rest }) => {
    const intl = useIntl();

    if (variant === 'compact' || isMobile) {
        return (
            <IconButton
                className={classnames('p-0', className)}
                aria-label={intl.formatMessage(componentsTranslations.ng_components_filters)}
                {...rest}
            >
                <SlidersHorizontalIcon size="lg" color="primary" disabled={disabled} />
            </IconButton>
        );
    }

    return (
        <ButtonLazy className={className} color="primary" variant="outline" {...rest}>
            <SlidersHorizontalIcon color="primary" className="mr-2" disabled={disabled} />
            {i18nBlur ? (
                <BlurLoader width={2}>{intl.formatMessage(componentsTranslations.ng_components_filters)}</BlurLoader>
            ) : (
                intl.formatMessage(componentsTranslations.ng_components_filters)
            )}
        </ButtonLazy>
    );
};

FiltersButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    i18nBlur: PropTypes.bool,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isMobile: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'compact']).isRequired
};

FiltersButton.displayName = 'FiltersButton';

export default FiltersButton;
