import React from 'react';
import SvgIcon from './SvgIcon';

const Solutions = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M15.5,0.3h-15C0.2,0.3,0,0.5,0,0.8v2.6h16V0.8C16,0.5,15.8,0.3,15.5,0.3z M1.5,2.3C1.3,2.3,1,2.1,1,1.8 c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C2.1,2.1,1.8,2.3,1.5,2.3z M3.4,2.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5 c0.3,0,0.5,0.2,0.5,0.5C3.9,2.1,3.6,2.3,3.4,2.3z M5.2,2.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5 C5.7,2.1,5.4,2.3,5.2,2.3z M0,3.9v10.3c0,0.3,0.2,0.5,0.5,0.5h15c0.3,0,0.5-0.2,0.5-0.5V3.9H0z M10.1,9.3H8.3v1.8 c0,0.1-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3V9.3H5.9C5.8,9.3,5.7,9.2,5.7,9s0.1-0.3,0.3-0.3h1.8V7c0-0.1,0.1-0.3,0.3-0.3S8.3,6.8,8.3,7 v1.8h1.8c0.1,0,0.3,0.1,0.3,0.3S10.2,9.3,10.1,9.3z"
        />
    </SvgIcon>
);

Solutions.displayName = 'SolutionsIcon';

export default Solutions;
