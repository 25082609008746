import React from 'react';
import PropTypes from 'prop-types';
import InvisibleInput from '../InvisibleInput';

const YearInput = ({ value, onChange, ...other }) => {
    const handleChange = e => {
        const { value } = e.target;

        if (value.length > 0 && value !== '0') {
            const result = /^[\d]{1,4}$/.test(value);

            if (!result) {
                e.preventDefault();
                return;
            }
        }

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <InvisibleInput
            {...other}
            name="year"
            className="onsolve-date-input__year"
            value={value}
            maxLength={4}
            onChange={handleChange}
        />
    );
};

YearInput.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

YearInput.defaultProps = {
    placeholder: 'YYYY'
};

YearInput.displayName = 'YearInput';

export default YearInput;
