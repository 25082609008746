function moveToItem(fn, list, currentElement) {
    let focusedElement = fn(list, currentElement);

    while (focusedElement) {
        const tabIndex = focusedElement.getAttribute('tabindex');

        if (tabIndex == -1 || focusedElement.disabled) {
            focusedElement = fn(list, focusedElement);
        } else {
            focusedElement.focus();
            break;
        }
    }
}

function nextItem(list, item) {
    if (list === item) {
        return list.firstChild;
    }
    if (item && item.nextElementSibling) {
        return item.nextElementSibling;
    }
    return list.firstChild;
}

function previousItem(list, item) {
    if (list === item) {
        return list.lastChild;
    }
    if (item && item.previousElementSibling) {
        return item.previousElementSibling;
    }
    return list.lastChild;
}

export default {
    moveToItem,
    nextItem,
    previousItem
};
