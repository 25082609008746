import React from 'react';
import { FormattedMessage } from 'react-intl';

import { componentsTranslations } from '../../translations';

export const REASONS = {
    TYPE: <FormattedMessage {...componentsTranslations.ng_file_upload_type_not_support} />,
    SIZE: <FormattedMessage {...componentsTranslations.ng_file_upload_size_to_big} />,
    WIDTH_HEIGHT: <FormattedMessage {...componentsTranslations.ng_file_upload_height_or_width_to_large} />
};
