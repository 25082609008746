import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { addMonths, subMonths, isSameMonth, isBefore, isAfter, addDays, subDays } from 'date-fns';

import { keyCodes } from '../../../common/constants';
import { DomUtils, DateTimeUtils } from '../../utils';
import { DatePicker, NavButton } from '../core';

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMonth: props.initialMonth,
            focusedDate: undefined,
            tabbing: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePreviousMonthClick = this.handlePreviousMonthClick.bind(this);
        this.handleNextMonthClick = this.handleNextMonthClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { value, minDate, maxDate } = this.props;
        const { currentMonth } = this.state;
        const { value: valuePrev } = prevProps;

        if (
            !!value &&
            value !== valuePrev &&
            value !== prevState.currentMonth &&
            currentMonth === prevState.currentMonth &&
            DateTimeUtils.isDateInRange(value, minDate, maxDate)
        ) {
            this.setState({ currentMonth: value });
        }
    }

    selectMonth(fn) {
        const { currentMonth } = this.state;
        const value = fn(currentMonth, 1);

        const { onMonthChange } = this.props;

        if (onMonthChange) {
            onMonthChange();
        }

        this.updateCurrentDate(this.getCurrentMonth(value));
    }

    updateCurrentDate(value) {
        this.setState({
            currentMonth: value,
            focusedDate: new Date(value.getFullYear(), value.getMonth(), 1)
        });
    }

    setFocusedDate = value => {
        const { minDate, maxDate } = this.props;

        if (DateTimeUtils.isDateInRange(value, minDate, maxDate)) {
            this.setState({ focusedDate: value, currentMonth: value, autoFocus: true });
        }
    };

    getCurrentMonth = value => {
        const { minDate, maxDate } = this.props;

        let currentDate = value || new Date();

        if (isBefore(currentDate, minDate) || isAfter(currentDate, maxDate)) {
            currentDate = minDate;
        }

        return currentDate;
    };

    handlePreviousMonthClick() {
        this.selectMonth(subMonths);
    }

    handleNextMonthClick() {
        this.selectMonth(addMonths);
    }

    handleMonthYearChange = (e, date) => {
        this.updateCurrentDate(this.getCurrentMonth(date));
    };

    handleKeyDown(e) {
        const { initialMonth, onChange, onKeyDown } = this.props;

        if (!e.altKey) {
            const { focusedDate } = this.state;
            const currentFocusedDate = focusedDate || initialMonth;
            const { TAB, UP, DOWN, LEFT, RIGHT, SPACE, ENTER_KEY } = keyCodes;
            const charCode = DomUtils.getCharCode(e);

            switch (charCode) {
                case TAB:
                    this.setState({ autoFocus: false });
                    break;
                case UP:
                    e.preventDefault();
                    this.setFocusedDate(subDays(currentFocusedDate, 7));
                    break;
                case DOWN:
                    e.preventDefault();
                    this.setFocusedDate(addDays(currentFocusedDate, 7));
                    break;
                case LEFT:
                    e.preventDefault();
                    this.setFocusedDate(subDays(currentFocusedDate, 1));
                    break;
                case RIGHT:
                    e.preventDefault();
                    this.setFocusedDate(addDays(currentFocusedDate, 1));
                    break;
                case SPACE:
                case ENTER_KEY:
                    e.preventDefault();
                    if (onChange) {
                        onChange(e, currentFocusedDate);
                    }
                    break;
            }
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    render() {
        const { currentMonth, focusedDate, autoFocus } = this.state;
        const {
            className,
            value,
            onChange,
            minDate,
            maxDate,
            size,
            navButtonProps,
            initialValues,
            ...others
        } = this.props;
        const { size: navButtonSize } = navButtonProps;
        const leftButtonDisabled = isSameMonth(currentMonth, minDate);
        const rightButtonDisabled = isSameMonth(currentMonth, maxDate);

        return (
            <DatePicker
                autoFocus={autoFocus}
                className={className}
                month={currentMonth}
                focusedDate={focusedDate}
                initialValues={initialValues}
                maxDate={maxDate}
                minDate={minDate}
                size={size}
                value={value}
                leftButton={
                    <NavButton
                        size={navButtonSize}
                        direction="left"
                        disabled={leftButtonDisabled}
                        onClick={this.handlePreviousMonthClick}
                    />
                }
                rightButton={
                    <NavButton
                        size={navButtonSize}
                        direction="right"
                        disabled={rightButtonDisabled}
                        onClick={this.handleNextMonthClick}
                    />
                }
                onKeyDown={this.handleKeyDown}
                onChange={onChange}
                onYearChange={this.handleMonthYearChange}
                onMonthChange={this.handleMonthYearChange}
                {...others}
            />
        );
    }
}

Calendar.propTypes = {
    /**
     Applied css classes to the text input field component.
     */
    className: PropTypes.string,
    /**
     Specifies the initial month fro calendar
     */
    initialMonth: PropTypes.instanceOf(Date),
    /**
     Specifies the initial values as dates for calendar
     */
    initialValues: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    /**
     Specifies maximum date that can be choosen.
     */
    maxDate: PropTypes.instanceOf(Date),
    /**
     Specifies minimal date that can be choosen.
     */
    minDate: PropTypes.instanceOf(Date),
    /**
     Specifies the size of navigation buttons
     */
    navButtonProps: PropTypes.shape({
        size: PropTypes.oneOf(['sm', 'md', 'lg'])
    }),
    /**
     Callback function on change event of text input field.
     */
    onChange: PropTypes.func,
    /**
     Callback function on key down event of text input field.
     */
    onKeyDown: PropTypes.func,
    /**
     Callback function on month change event of text input field.
     */
    onMonthChange: PropTypes.func,
    /**
     Specifies the size of calendar
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /**
     Specifies the value as date applied to calendar
     */
    value: PropTypes.instanceOf(Date)
};

Calendar.defaultProps = {
    initialMonth: new Date(),
    navButtonProps: {
        size: 'lg'
    },
    size: 'md',
    initialValues: []
};

Calendar.displayName = 'Calendar';

export default Calendar;
