import React from 'react';
import SvgIcon from './SvgIcon';

const Pager = props => (
    <SvgIcon {...props}>
        <g id="pager" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M13,2 C14.1045695,2 15,2.8954305 15,4 L15,12 C15,13.1045695 14.1045695,14 13,14 L3,14 C1.8954305,14 1,13.1045695 1,12 L1,4 C1,2.8954305 1.8954305,2 3,2 L13,2 Z M13,2.58333333 L3,2.58333333 C2.26362033,2.58333333 1.65846514,3.14517208 1.58981843,3.86356555 L1.58333333,4 L1.58333333,12 C1.58333333,12.7363797 2.14517208,13.3415349 2.86356555,13.4101816 L3,13.4166667 L13,13.4166667 C13.7363797,13.4166667 14.3415349,12.8548279 14.4101816,12.1364345 L14.4166667,12 L14.4166667,4 C14.4166667,3.26362033 13.8548279,2.65846514 13.1364345,2.58981843 L13,2.58333333 Z M11.5207101,9.2433432 C12.3680641,9.2433432 13.0549803,9.93025937 13.0549803,10.7776134 C13.0549803,11.6249675 12.3680641,12.3118836 11.5207101,12.3118836 C10.673356,12.3118836 9.98643984,11.6249675 9.98643984,10.7776134 C9.98643984,9.93025937 10.673356,9.2433432 11.5207101,9.2433432 Z M6.34415918,8.80204598 L9.57300059,10.5317824 L6.34415918,12.2615189 L6.34415918,8.80204598 Z M6.17655088,8.80204598 L6.17655088,12.2615189 L2.94770947,10.5317824 L6.17655088,8.80204598 Z M11.5207101,9.82667653 C10.9955221,9.82667653 10.5697732,10.2524255 10.5697732,10.7776134 C10.5697732,11.3028014 10.9955221,11.7285503 11.5207101,11.7285503 C12.045898,11.7285503 12.4716469,11.3028014 12.4716469,10.7776134 C12.4716469,10.2524255 12.045898,9.82667653 11.5207101,9.82667653 Z M6.92836233,9.77620257 L6.92836233,11.2872026 L8.33836233,10.5312026 L6.92836233,9.77620257 Z M5.59234773,9.77565227 L4.18234773,10.5316523 L5.59234773,11.2866523 L5.59234773,9.77565227 Z M12.9583333,4.05916667 L12.9583333,8.1425 L3.04166667,8.1425 L3.04166667,4.05916667 L12.9583333,4.05916667 Z M12.375,4.642 L3.625,4.642 L3.625,7.559 L12.375,7.559 L12.375,4.642 Z"
                id="Combined-Shape"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

Pager.displayName = 'PagerIcon';

export default Pager;
