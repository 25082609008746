import React from 'react';
import SvgIcon from './SvgIcon';

const PublicFeed = props => {
    return (
        <SvgIcon {...props}>
            <g id="Phase-3" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="5.1" transform="translate(-479.000000, -401.000000)" fillRule="nonzero">
                    <g id="public-feeds-copy-2" transform="translate(298.000000, 388.000000)">
                        <g id="public-feeds" transform="translate(180.000000, 12.000000)">
                            <path
                                d="M10.7391304,1 L10.7391304,12.1061711 L5.47765217,8.73618398 L3.13043478,8.73636221 C3.11292441,8.73636221 3.09546114,8.736145 3.07804748,8.73571297 L5.38783141,13.9663701 C5.53970086,14.3150471 5.93803528,14.4713987 6.27812157,14.3158317 C6.58742877,14.1743441 6.74163637,13.8241143 6.65377751,13.5013711 L6.6200709,13.4056089 L5.26937525,10.3664878 L5.82332041,10.1079302 L7.17496184,13.1492009 C7.46381073,13.8123688 7.17339351,14.5894189 6.52622625,14.885455 C5.91955381,15.1629675 5.21521219,14.9187315 4.89201619,14.3417814 L4.83260081,14.2220112 L2.34338706,8.58220125 C1.65215231,8.29986878 1.1361389,7.65557751 1.0225913,6.86573719 L1.0225913,6.86573719 L1.00571865,6.71134098 L1,6.55308553 C1,5.3472042 1.95373942,4.36980885 3.13043478,4.36980885 L3.13043478,4.36980885 L5.47765217,4.36918505 L10.7391304,1 Z M10.1301739,2.12442017 L5.72652142,4.94619336 L5.56521739,4.99360219 L3.13043478,4.99360219 C2.28991275,4.99360219 1.60869565,5.69171574 1.60847044,6.54108911 L1.60847044,6.54108911 L1.61328419,6.67531097 C1.67424583,7.48365462 2.33424486,8.11256887 3.13043478,8.11256887 L3.13043478,8.11256887 L5.56521739,8.11256887 L5.72652142,8.1599777 L10.1301739,10.9809489 L10.1301739,2.12442017 Z M12.6327802,8.93409533 L14.2141831,9.86979219 L13.9098285,10.4100091 L12.3284256,9.47431224 L12.6327802,8.93409533 Z M15,6.24118886 L15,6.8649822 L13.173913,6.8649822 L13.173913,6.24118886 L15,6.24118886 Z M13.9098285,2.69616195 L14.2141831,3.23637887 L12.6327802,4.17207573 L12.3284256,3.63185882 L13.9098285,2.69616195 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

PublicFeed.displayName = 'PublicFeedIcon';

export default PublicFeed;
