import React from 'react';

import SvgIcon from './SvgIcon';

const MenuDots = props => {
    return (
        <SvgIcon {...props}>
            <path d="M7,12 C7,11.4477153 7.44771525,11 8,11 C8.55228475,11 9,11.4477153 9,12 C9,12.5522847 8.55228475,13 8,13 C7.44771525,13 7,12.5522847 7,12 Z M7,8 C7,7.44771525 7.44771525,7 8,7 C8.55228475,7 9,7.44771525 9,8 C9,8.55228475 8.55228475,9 8,9 C7.44771525,9 7,8.55228475 7,8 Z M7,4 C7,3.44771525 7.44771525,3 8,3 C8.55228475,3 9,3.44771525 9,4 C9,4.55228475 8.55228475,5 8,5 C7.44771525,5 7,4.55228475 7,4 Z" />
        </SvgIcon>
    );
};

MenuDots.displayName = 'MenuDotsIcon';

export default MenuDots;
