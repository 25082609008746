import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MenuItem } from '../../Menu';
import { SelectionType } from '../utils';
import { componentsTranslations } from '../../translations';

const ColumnMenuSelect = ({ onMenuClick, onMenuKeyDown }) => (
    <>
        <MenuItem onClick={onMenuClick(SelectionType.SelectAll)} onKeyDown={onMenuKeyDown(SelectionType.SelectAll)}>
            <FormattedMessage {...componentsTranslations.ng_components_selectAllPages} />
        </MenuItem>
        <MenuItem onClick={onMenuClick(SelectionType.SelectPage)} onKeyDown={onMenuKeyDown(SelectionType.SelectPage)}>
            <FormattedMessage {...componentsTranslations.ng_components_selectThisPage} />
        </MenuItem>
        <MenuItem onClick={onMenuClick(SelectionType.Reset)} onKeyDown={onMenuKeyDown(SelectionType.Reset)}>
            <FormattedMessage {...componentsTranslations.ng_components_selectNone} />
        </MenuItem>
    </>
);

ColumnMenuSelect.displayName = 'ColumnMenuSelect';

ColumnMenuSelect.propTypes = {
    onMenuClick: PropTypes.func,
    onMenuKeyDown: PropTypes.func
};

export default ColumnMenuSelect;
