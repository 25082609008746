import React from 'react';

import SvgIcon from './SvgIcon';

const SingleUser = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 10 12">
            <g transform="translate(.5 .75)" fill="none">
                <path d="M5.464 6.89H3.536C1.76 6.89.32 8.36.32 10.173H8.68c0-.87-.34-1.705-.942-2.32a3.181 3.181 0 00-2.273-.961z" />
                <ellipse cx="4.5" cy="2.625" rx="2.25" ry="2.297" />
            </g>
        </SvgIcon>
    );
};

SingleUser.displayName = 'SingleUserIcon';

export default SingleUser;
