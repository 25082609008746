import React from 'react';
import SvgIcon from './SvgIcon';

const Fax = props => (
    <SvgIcon {...props}>
        <g id="fax" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M12.0057803,5.45086705 C13.6003828,5.45086705 14.9038465,6.69738062 14.9949171,8.26915356 L15,8.44508671 L15,12.0057803 C15,12.1590154 14.8935244,12.2873818 14.7505219,12.3209306 L14.6763006,12.3294798 L12.329,12.329 L12.3294798,14.6763006 C12.3294798,14.8327281 12.2185214,14.9632398 12.0710171,14.9934236 L12.0057803,15 L3.99421965,15 C3.8154454,15 3.67052023,14.8550748 3.67052023,14.6763006 L3.67052023,14.6763006 L3.67,12.329 L1.32369942,12.3294798 C1.17046435,12.3294798 1.04209799,12.2230041 1.00854913,12.0800017 L1,12.0057803 L1,8.44508671 C1,6.8504842 2.24651357,5.54702055 3.8182865,5.45594993 L3.99421965,5.45086705 L12.0057803,5.45086705 Z M11.6817572,9.6582659 L4.31722543,9.6582659 L4.31722543,14.3522775 L11.6817572,14.3522775 L11.6817572,9.6582659 Z M12.0057803,6.0982659 L3.99421965,6.0982659 C2.75211103,6.0982659 1.73538385,7.06323855 1.65281302,8.28440895 L1.64739884,8.44508671 L1.6467052,11.6817572 L3.67,11.682 L3.67052023,9.33526012 C3.67052023,9.17883264 3.78147856,9.04832088 3.92898293,9.01813712 L3.99421965,9.01156069 L12.0057803,9.01156069 C12.1845546,9.01156069 12.3294798,9.15648586 12.3294798,9.33526012 L12.3294798,9.33526012 L12.329,11.682 L14.3522775,11.6817572 L14.3526012,8.44508671 C14.3526012,7.20297808 13.3876285,6.1862509 12.1664581,6.10368008 L12.0057803,6.0982659 Z M12.0057803,1 C12.1590154,1 12.2873818,1.10647563 12.3209306,1.24947811 L12.3294798,1.32369942 L12.3294798,3.99421965 C12.3294798,4.17299391 12.1845546,4.31791908 12.0057803,4.31791908 C11.8525453,4.31791908 11.7241789,4.21144344 11.6906301,4.06844096 L11.6820809,3.99421965 L11.6817572,1.6467052 L4.31722543,1.6467052 L4.31791908,3.99421965 C4.31791908,4.14745473 4.21144344,4.27582109 4.06844096,4.30936994 L3.99421965,4.31791908 C3.84098458,4.31791908 3.71261822,4.21144344 3.67906936,4.06844096 L3.67052023,3.99421965 L3.67052023,1.32369942 C3.67052023,1.17046435 3.77699586,1.04209799 3.91999835,1.00854913 L3.99421965,1 L12.0057803,1 Z"
                id="Mask"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

Fax.displayName = 'FaxIcon';

export default Fax;
