import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Focusable } from '../../../Focusable';

class PageNumberButton extends Component {
    handlePageChange = value => e => {
        const { onPageChange } = this.props;

        onPageChange(e, value);
    };

    render() {
        const { page } = this.props;
        const { active, text, number } = page;

        return (
            <Focusable
                render={({ classes }) => (
                    <li>
                        <span
                            tabIndex="0"
                            role="button"
                            className={classNames('onsolve-table__paginator-button', classes, { active: active })}
                            onClick={this.handlePageChange(number)}
                            onKeyPress={this.handlePageChange(number)}
                        >
                            {text}
                        </span>
                    </li>
                )}
            />
        );
    }
}

PageNumberButton.displayName = 'PageNumberButton';

PageNumberButton.propTypes = {
    onPageChange: PropTypes.func,
    page: PropTypes.shape({
        number: PropTypes.number,
        text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        active: PropTypes.bool
    })
};

export default PageNumberButton;
