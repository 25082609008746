import { EditorState, Modifier, SelectionState } from 'draft-js';
import { INDENT_BOUNDS, INDENT_STATE_KEYS, NODE_TYPES } from '../constants';
import { getSelectedBlocksList } from './getSelectedBlocksMetadata';

export const checkMinIndent = currentTextIndent =>
    currentTextIndent <= INDENT_BOUNDS.MIN ? INDENT_BOUNDS.MIN : currentTextIndent - 1;

export const checkMaxIndent = currentTextIndent =>
    currentTextIndent >= INDENT_BOUNDS.MAX ? INDENT_BOUNDS.MAX : currentTextIndent + 1;

export const increaseIndent = (indentClass = 'indent_0') => `indent_${checkMaxIndent(+indentClass.split('_')[1])}`;

export const decreaseIndent = (indentClass = 'indent_0') => `indent_${checkMinIndent(+indentClass.split('_')[1])}`;

export const setIndent = (editorState, data) => {
    const selectedBlocks = getSelectedBlocksList(editorState);

    const selection = editorState.getSelection();

    let newContentState = editorState.getCurrentContent();

    selectedBlocks.forEach(block => {
        const indent = block.getData().get(INDENT_STATE_KEYS.STYLE_NAME);

        const newIndent = data === NODE_TYPES.indentDecrease.style ? increaseIndent(indent) : decreaseIndent(indent);

        newContentState = Modifier.mergeBlockData(newContentState, SelectionState.createEmpty(block.getKey()), {
            [INDENT_STATE_KEYS.STYLE_NAME]: newIndent
        });
    });

    //selecting whole block back
    newContentState = Modifier.mergeBlockData(newContentState, selection);

    return EditorState.push(editorState, newContentState, 'change-block-data');
};
