import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Typography } from '../Typography';

class PaperHeader extends Component {
    renderAction = () => {
        const { action } = this.props;

        return action && <div className="onsolve-paper-header__action">{action}</div>;
    };

    renderAvatar = () => {
        const { avatar } = this.props;

        return avatar && <div className="onsolve-paper-header__avatar">{avatar}</div>;
    };

    renderTitle = () => {
        const { title, classNames } = this.props;
        const titleClasses = classnames('onsolve-paper-header__title', classNames.title);

        if (typeof title === 'function') {
            return title({ className: titleClasses });
        }

        return (
            title && (
                <Typography variant="h2" className={titleClasses}>
                    {title}
                </Typography>
            )
        );
    };

    renderSubTitle = () => {
        const { subtitle, classNames } = this.props;
        const subtitleClasses = classnames('onsolve-paper-header__subtitle', classNames.subtitle);

        if (typeof subtitle === 'function') {
            return subtitle({ className: subtitleClasses });
        }

        return (
            subtitle && (
                <Typography variant="p12" className={subtitleClasses} color="secondary">
                    {subtitle}
                </Typography>
            )
        );
    };

    render() {
        const { className } = this.props;
        const rootClasses = classnames('onsolve-paper-header', className);

        return (
            <div className={rootClasses}>
                {this.renderAvatar()}
                <div className="onsolve-paper-header__wrapper">
                    {this.renderTitle()}
                    {this.renderSubTitle()}
                </div>
                {this.renderAction()}
            </div>
        );
    }
}

PaperHeader.propTypes = {
    /**
    The actions displayed at the right top corner on the paper.
    */
    action: PropTypes.node,
    /**
    The avatar displayed at the left top corner on the paper. it can be used to display any action or presentation components.
    */
    avatar: PropTypes.element,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Override or extend the styles applied to the sub components like `avatar`, `title`, `subtitle`.
    */
    classNames: PropTypes.shape({
        avatar: PropTypes.string,
        title: PropTypes.string,
        header: PropTypes.string,
        subtitle: PropTypes.string
    }),
    /**
    The subtitle of component.
    */
    subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
    The title of component.
    */
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

PaperHeader.defaultProps = {
    classNames: {}
};

PaperHeader.displayName = 'PaperHeader';

export default PaperHeader;
