import React from 'react';
import SvgIcon from './SvgIcon';

const DateElement = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M11.0625,1 C11.2772774,1 11.4559073,1.15476577 11.4929513,1.35885882 L11.5,1.4375 L11.5,2.75 L13.6875,2.75 C14.3668355,2.75 14.9259814,3.26668738 14.993221,3.92834556 L15,4.0625 L15,13.6875 C15,14.4121246 14.4121246,15 13.6875,15 L13.6875,15 L2.3125,15 C1.58787542,15 1,14.4121246 1,13.6875 L1,13.6875 L1,4.0625 C1,3.33787542 1.58787542,2.75 2.3125,2.75 L2.3125,2.75 L4.5,2.75 L4.5,1.4375 C4.5,1.19587542 4.69587542,1 4.9375,1 C5.1522774,1 5.33090732,1.15476577 5.36795129,1.35885882 L5.375,1.4375 L5.375,2.75 L10.625,2.75 L10.625,1.4375 C10.625,1.19587542 10.8208754,1 11.0625,1 Z M14.125,6.25 L1.875,6.25 L1.875,13.6875 C1.875,13.9020559 2.02996263,14.0808581 2.23392444,14.1179431 L2.3125,14.125 L13.6875,14.125 C13.9288754,14.125 14.125,13.9288754 14.125,13.6875 L14.125,13.6875 L14.125,6.25 Z M10.1875,7.125 C10.4022774,7.125 10.5809073,7.27976577 10.6179513,7.48385882 L10.625,7.5625 L10.625,9.75 L12.8125,9.75 C13.0541246,9.75 13.25,9.94587542 13.25,10.1875 C13.25,10.4022774 13.0952342,10.5809073 12.8911412,10.6179513 L12.8125,10.625 L10.625,10.625 L10.625,12.8125 C10.625,13.0541246 10.4291246,13.25 10.1875,13.25 C9.9727226,13.25 9.79409268,13.0952342 9.75704871,12.8911412 L9.75,12.8125 L9.75,10.625 L6.25,10.625 L6.25,12.8125 C6.25,13.0541246 6.05412458,13.25 5.8125,13.25 C5.5977226,13.25 5.41909268,13.0952342 5.38204871,12.8911412 L5.375,12.8125 L5.375,10.625 L3.1875,10.625 C2.94587542,10.625 2.75,10.4291246 2.75,10.1875 C2.75,9.9727226 2.90476577,9.79409268 3.10885882,9.75704871 L3.1875,9.75 L5.375,9.75 L5.375,7.5625 C5.375,7.32087542 5.57087542,7.125 5.8125,7.125 C6.0272774,7.125 6.20590732,7.27976577 6.24295129,7.48385882 L6.25,7.5625 L6.25,9.75 L9.75,9.75 L9.75,7.5625 C9.75,7.32087542 9.94587542,7.125 10.1875,7.125 Z M13.6875,3.625 L2.3125,3.625 C2.07112458,3.625 1.875,3.82112458 1.875,4.0625 L1.875,4.0625 L1.875,5.375 L14.125,5.375 L14.125,4.0625 C14.125,3.84794407 13.9700374,3.66914189 13.7660756,3.63205691 L13.6875,3.625 Z"
        />
    </SvgIcon>
);

DateElement.displayName = 'DateElementIcon';

export default DateElement;
