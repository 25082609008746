import React from 'react';
import SvgIcon from './SvgIcon';

const UpDownArrows = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <polygon points="3.020618438720703,7 12.979381561279297,7 8.000000953674316,1 " />
            <polygon points="8.000000953674316,15 12.979381561279297,9 3.020618438720703,9 " />
        </g>
    </SvgIcon>
);

UpDownArrows.displayName = 'UpDownArrowsIcon';

export default UpDownArrows;
