import React from 'react';
import SvgIcon from './SvgIcon';

const EyePreview = props => (
    <SvgIcon {...props}>
        <path d="M8,13.5 C11.1598746,13.5 13.6175549,10.6583333 14.6708464,9.00833333 C15.1097179,8.36666667 15.1097179,7.54166667 14.6708464,6.9 C13.6175549,5.34166667 11.1598746,2.5 8,2.5 C4.84012539,2.5 2.38244514,5.34166667 1.32915361,6.99166667 C0.890282132,7.63333333 0.890282132,8.45833333 1.32915361,9.00833333 C2.38244514,10.6583333 4.84012539,13.5 8,13.5 Z M8,5.25 C9.49216301,5.25 10.6332288,6.44166667 10.6332288,8 C10.6332288,9.55833333 9.49216301,10.75 8,10.75 C6.50783699,10.75 5.36677116,9.55833333 5.36677116,8 C5.36677116,6.44166667 6.50783699,5.25 8,5.25 Z" />
    </SvgIcon>
);

EyePreview.displayName = 'EyePreviewIcon';

export default EyePreview;
