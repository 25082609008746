import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TooltipPlaceholder extends Component {
    render() {
        const { className, classes, style } = this.props;

        const rootClasses = classNames('onsolve-placeholder', classes.root);
        const tooltipClasses = classNames(
            'onsolve-placeholder__row',
            'onsolve-placeholder__row--inline',
            'onsolve-placeholder__row--tooltip',
            rootClasses,
            classes.row,
            className
        );

        return <span className={tooltipClasses} style={{ ...style }} />;
    }
}

TooltipPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    style: PropTypes.object
};

TooltipPlaceholder.defaultProps = {
    classes: {}
};

TooltipPlaceholder.displayName = 'TooltipPlaceholder';

export default TooltipPlaceholder;
