import React from 'react';

import SvgIcon from './SvgIcon';

const ClockIcon = props => (
    <SvgIcon viewBox="0 0 125 125" {...props}>
        <path
            stroke="none"
            d="M62.5,0 C27.9891304,0 0,26.6348177 0,59.4758065 C0,92.3167952 27.9891304,118.951613 62.5,118.951613 C97.0108696,118.951613 125,92.3167952 125,59.4758065 C125,26.6348177 97.0108696,0 62.5,0 Z M59.7826087,10.3436185 C59.7826087,8.79207574 60.8695652,7.75771388 62.5,7.75771388 C64.1304348,7.75771388 65.2173913,8.79207574 65.2173913,10.3436185 L65.2173913,20.687237 C65.2173913,22.2387798 64.1304348,23.2731417 62.5,23.2731417 C60.8695652,23.2731417 59.7826087,22.2387798 59.7826087,20.687237 L59.7826087,10.3436185 Z M21.7391304,62.0617111 L10.8695652,62.0617111 C9.23913043,62.0617111 8.15217391,61.0273492 8.15217391,59.4758065 C8.15217391,57.9242637 9.23913043,56.8899018 10.8695652,56.8899018 L21.7391304,56.8899018 C23.3695652,56.8899018 24.4565217,57.9242637 24.4565217,59.4758065 C24.4565217,61.0273492 23.3695652,62.0617111 21.7391304,62.0617111 Z M65.2173913,108.607994 C65.2173913,110.159537 64.1304348,111.193899 62.5,111.193899 C60.8695652,111.193899 59.7826087,110.159537 59.7826087,108.607994 L59.7826087,98.2643759 C59.7826087,96.7128331 60.8695652,95.6784712 62.5,95.6784712 C64.1304348,95.6784712 65.2173913,96.7128331 65.2173913,98.2643759 L65.2173913,108.607994 Z M84.2391304,62.0617111 L62.5,62.0617111 C61.6847826,62.0617111 60.8695652,61.5445302 60.326087,61.0273492 L38.5869565,29.9964937 C37.7717391,28.7035414 38.0434783,27.1519986 39.4021739,26.3762272 C40.7608696,25.6004558 42.3913043,25.8590463 43.2065217,27.1519986 L63.8586957,56.8899018 L84.2391304,56.8899018 C85.8695652,56.8899018 86.9565217,57.9242637 86.9565217,59.4758065 C86.9565217,61.0273492 85.8695652,62.0617111 84.2391304,62.0617111 Z M114.130435,62.0617111 L103.26087,62.0617111 C101.630435,62.0617111 100.543478,61.0273492 100.543478,59.4758065 C100.543478,57.9242637 101.630435,56.8899018 103.26087,56.8899018 L114.130435,56.8899018 C115.76087,56.8899018 116.847826,57.9242637 116.847826,59.4758065 C116.847826,61.0273492 115.76087,62.0617111 114.130435,62.0617111 Z"
        />
    </SvgIcon>
);

ClockIcon.displayName = 'ClockIcon';

export default ClockIcon;
