import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DomUtils } from '../../../../utils';
import cx from 'classnames';

import { getDataset, isEmpty } from '../utils';
import Actions from './actions';
import NodeLabel from './node-label';
import Toggle from './toggle';

const isLeaf = children => isEmpty(children);

const getNodeCx = props => {
    const {
        keepTreeOnSearch,
        keepChildrenOnSearch,
        _children,
        matchInChildren,
        matchInParent,
        disabled,
        partial,
        hide,
        className,
        showPartiallySelected,
        readOnly,
        checked,
        _focused: focused,
        searchTerm,
        label
    } = props;

    const baseClass = 'onsolve-dropdown-tree-content-root-scroll-node';

    const isSearchMatched = searchTerm && label && label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;

    return cx(
        baseClass,
        {
            [baseClass + '__leaf']: isLeaf(_children),
            [baseClass + '__tree']: !isLeaf(_children),
            [baseClass + '--disabled']: disabled,
            [baseClass + '--hide']: hide,
            'match-in-children': keepTreeOnSearch && matchInChildren,
            'match-in-parent': keepTreeOnSearch && keepChildrenOnSearch && matchInParent,
            [baseClass + '__partial']: showPartiallySelected && partial,
            [baseClass + '--read-only']: readOnly,
            [baseClass + '--checked']: checked,
            [baseClass + '--focused']: focused,
            [baseClass + '--search-match']: isSearchMatched
        },
        className
    );
};

class TreeNode extends PureComponent {
    getAriaAttributes = () => {
        const { _children, _depth, checked, disabled, expanded, readOnly, variant, partial } = this.props;
        const attributes = {};

        attributes['aria-disabled'] = disabled || readOnly;
        attributes['aria-selected'] = checked;
        if (variant !== 'simpleSelect') {
            attributes['aria-checked'] = partial ? 'mixed' : checked;
            attributes['aria-level'] = (_depth || 0) + 1;
            attributes['aria-expanded'] = _children && (expanded ? 'true' : 'false');
        }
        return attributes;
    };

    render() {
        const {
            variant,
            keepTreeOnSearch,
            _id,
            _children,
            dataset,
            _depth,
            expanded,
            title,
            label,
            partial,
            checked,
            value,
            disabled,
            actions,
            onAction,
            searchModeOn,
            onNodeToggle,
            onCheckboxChange,
            showPartiallySelected,
            readOnly,
            clientId,
            searchTerm
        } = this.props;
        const divCx = getNodeCx(this.props);
        const isLeafNode = isLeaf(_children);
        const padding = DomUtils.isRtl() ? 'paddingRight' : 'paddingLeft';
        const offset = isLeafNode ? 4 : 0;
        const style = keepTreeOnSearch || !searchModeOn ? { [padding]: `${(_depth + 1 || 0) * 24 + offset}px` } : {};

        const divId = `${_id}_div`;

        return (
            <div className={divCx} style={style} id={divId} {...getDataset(dataset)} {...this.getAriaAttributes()}>
                <NodeLabel
                    title={title}
                    label={label}
                    id={_id}
                    partial={partial}
                    checked={checked}
                    value={value}
                    toggle={<Toggle isLeaf={isLeafNode} expanded={expanded} id={_id} onNodeToggle={onNodeToggle} />}
                    disabled={disabled}
                    variant={variant}
                    onCheckboxChange={onCheckboxChange}
                    showPartiallySelected={showPartiallySelected}
                    readOnly={readOnly}
                    clientId={clientId}
                    searchTerm={searchTerm}
                />
                <Actions actions={actions} onAction={onAction} id={_id} readOnly={readOnly} />
            </div>
        );
    }
}

TreeNode.propTypes = {
    _children: PropTypes.array,
    _depth: PropTypes.number,
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    actions: PropTypes.array,
    checked: PropTypes.bool,
    className: PropTypes.string,
    clientId: PropTypes.string,
    dataset: PropTypes.object,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    keepChildrenOnSearch: PropTypes.bool,
    keepTreeOnSearch: PropTypes.bool,
    label: PropTypes.string,
    onAction: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onNodeToggle: PropTypes.func,
    partial: PropTypes.bool,
    readOnly: PropTypes.bool,
    searchModeOn: PropTypes.bool,
    searchTerm: PropTypes.string,
    showPartiallySelected: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.oneOf(['multiSelect', 'simpleSelect', 'radioSelect', 'hierarchical'])
};

TreeNode.displayName = 'TreeNode';

export default TreeNode;
