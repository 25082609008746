import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { keyCodes } from '../../../common/constants';
import { Focusable } from '../../Focusable';
import { fp as _, OnsolvePropTypes } from '../../utils';
import { TableCell, TableSelectionCell, TableExpansionCell, TableDraggableCell } from '../Cells';

import { RowSelectionType } from '../constants';

const TableRow = forwardRef((props, ref) => {
    const isRowSelected = ({ selectedField, dataItem }) => {
        return selectedField && dataItem[selectedField];
    };

    const isRowExpanded = ({ expandableField, dataItem }) => {
        return expandableField && dataItem[expandableField];
    };

    const isCheckboxDisabled = ({ disabled, dataItem }) => {
        if (typeof disabled === 'function') {
            return disabled(dataItem);
        }

        return disabled;
    };

    const getAriaLabel = (data, props = {}) => {
        const { 'aria-label': ariaLabel } = props;

        return typeof ariaLabel === 'function' ? ariaLabel(data) : ariaLabel;
    };

    const handleRowKeyPress = e => {
        const { dataItem, onRowClick } = props;

        if (e.charCode !== keyCodes.ENTER_KEY) {
            return;
        }

        onRowClick && onRowClick(e, dataItem);
    };

    const handleRowClick = e => {
        const { dataItem, onRowClick, draggableField, isClickable, isDragging, resetDragging } = props;

        if (draggableField && isDragging) {
            resetDragging();
            e.preventDefault();
            return;
        }

        if (!isClickable) {
            e.preventDefault();
            return;
        }

        if (onRowClick) {
            onRowClick(e, dataItem);
        }
    };

    const handleSelectionChange = dataItem => (e, checked) => {
        const { onSelectionChange, rowSelection } = props;

        if (onSelectionChange) {
            onSelectionChange(e, dataItem, rowSelection, checked);
        }
    };

    const renderTableCells = props => {
        const {
            columns,
            draggableField,
            drag,
            dataItem,
            expandableField,
            onExpandChange,
            selectedField,
            rowIndex
        } = props;

        return _.map(columns, (column, key) => {
            const { cell, field, title, disabled, cellProps } = column;

            if (selectedField && selectedField === field) {
                return (
                    <TableSelectionCell
                        key={key}
                        selectionValue={dataItem[selectedField]}
                        disabled={isCheckboxDisabled({ disabled, dataItem })}
                        aria-label={getAriaLabel(dataItem, cellProps)}
                        onSelectionChange={handleSelectionChange(dataItem)}
                        dataItem={dataItem}
                        render={cell}
                    />
                );
            }
            if (expandableField && expandableField === field) {
                return (
                    <TableExpansionCell
                        key={key}
                        title={title}
                        expanded={dataItem[expandableField]}
                        onExpandChange={(e, expanded) => onExpandChange(e, expanded, dataItem)}
                        {...column}
                    />
                );
            }
            if (draggableField && draggableField === field) {
                return <TableDraggableCell key={key} drag={drag} dataItem={dataItem} {...column} />;
            }
            return (
                <TableCell
                    key={key}
                    rowIndex={rowIndex}
                    field={field}
                    render={cell}
                    dataItem={dataItem}
                    {...column}
                    {...cellProps}
                />
            );
        });
    };

    const {
        selectedField,
        expandableField,
        dataItem,
        onRowClick,
        onMouseOver,
        onMouseOut,
        isClickable,
        ...other
    } = props;

    const selected = isRowSelected({ selectedField, dataItem }) || isRowExpanded({ expandableField, dataItem });

    const isRowClickable = isClickable && onRowClick !== undefined;

    return (
        <Focusable
            outlineDirection="inset"
            render={({ classes }) => (
                <tr
                    className={classNames('onsolve-table__body-row', classes, {
                        selected: selected,
                        pointer: isRowClickable
                    })}
                    ref={ref}
                    onClick={handleRowClick}
                    onKeyPress={handleRowKeyPress}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    tabIndex={isRowClickable ? '0' : '-1'}
                    role="row"
                >
                    {renderTableCells({ selectedField, expandableField, dataItem, ...other })}
                </tr>
            )}
        />
    );
});

TableRow.propTypes = {
    'aria-label': PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            cell: PropTypes.func,
            align: OnsolvePropTypes.align
        })
    ).isRequired,
    dataItem: PropTypes.object,
    drag: OnsolvePropTypes.drag,
    draggableField: PropTypes.string,
    expandableField: PropTypes.string,
    isClickable: PropTypes.bool,
    isDragging: PropTypes.bool,
    onExpandChange: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onRowClick: PropTypes.func,
    onSelectionChange: PropTypes.func,
    resetDragging: PropTypes.func,
    rowIndex: PropTypes.number,
    rowSelection: PropTypes.oneOf([RowSelectionType.Multiple, RowSelectionType.Single]),
    selectedField: PropTypes.string
};

TableRow.defaultProps = {
    isClickable: true
};

TableRow.displayName = 'TableRow';

export default TableRow;
