import React from 'react';

import SvgIcon from './SvgIcon';

const LocationFloor = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 Z M8,1.56 C4.44328621,1.56 1.56,4.44328621 1.56,8 C1.56,11.5567138 4.44328621,14.44 8,14.44 C11.5567138,14.44 14.44,11.5567138 14.44,8 C14.44,4.44328621 11.5567138,1.56 8,1.56 Z M11.7536092,4.5 C11.8896871,4.5 12,4.61607432 12,4.75925926 C12,4.88454608 11.9155417,4.98907633 11.8032656,5.01325129 L11.7536092,5.01851852 L9.99792108,5.01835648 L9.99807507,6.91975309 C9.99807507,7.0450399 9.91361676,7.14957016 9.80134064,7.17374512 L9.75168431,7.17901235 L7.99599615,7.17890432 L7.99615014,9.08024691 C7.99615014,9.20553373 7.91169183,9.31006399 7.79941572,9.33423895 L7.74975938,9.33950617 L5.99407122,9.33945216 L5.99422522,11.2407407 C5.99422522,11.3660276 5.9097669,11.4705578 5.79749079,11.4947328 L5.74783446,11.5 L4.24639076,11.5 C4.1103129,11.5 4,11.3839257 4,11.2407407 C4,11.1154539 4.08445831,11.0109237 4.19673443,10.9867487 L4.24639076,10.9814815 L5.50093038,10.9811034 L5.5014437,9.08024691 C5.5014437,8.9549601 5.58590201,8.85042984 5.69817812,8.82625488 L5.74783446,8.82098765 L7.50285531,8.82055556 L7.50336862,6.91975309 C7.50336862,6.79446627 7.58782694,6.68993601 7.70010305,6.66576105 L7.74975938,6.66049383 L9.50478024,6.66000772 L9.50529355,4.75925926 C9.50529355,4.63397244 9.58975187,4.52944218 9.70202798,4.50526723 L9.75168431,4.5 L11.7536092,4.5 Z"
            />
        </SvgIcon>
    );
};

LocationFloor.displayName = 'LocationFloorIcon';

export default LocationFloor;
