import React from 'react';

import SvgIcon from './SvgIcon';

const CommentIcon = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M 15 4.773438 C 15 0.984375 11.640625 -2.078125 7.5 -2.078125 C 3.359375 -2.078125 0 0.984375 0 4.773438 C 0 8.558594 3.359375 11.625 7.5 11.625 C 8.167969 11.628906 8.835938 11.542969 9.488281 11.371094 L 13.238281 13.03125 C 13.273438 13.03125 13.304688 13.066406 13.371094 13.066406 C 13.4375 13.058594 13.503906 13.035156 13.566406 12.992188 C 13.652344 12.914062 13.703125 12.792969 13.695312 12.667969 L 13.335938 9.0625 C 14.382812 7.933594 14.980469 6.390625 15 4.773438 Z M 8.152344 6.574219 L 4.238281 6.574219 C 4.058594 6.574219 3.914062 6.414062 3.914062 6.214844 C 3.914062 6.015625 4.058594 5.851562 4.238281 5.851562 L 8.152344 5.851562 C 8.332031 5.851562 8.476562 6.015625 8.476562 6.214844 C 8.476562 6.414062 8.332031 6.574219 8.152344 6.574219 Z M 10.761719 3.691406 L 4.238281 3.691406 C 4.058594 3.691406 3.914062 3.527344 3.914062 3.328125 C 3.914062 3.128906 4.058594 2.96875 4.238281 2.96875 L 10.761719 2.96875 C 10.941406 2.96875 11.085938 3.128906 11.085938 3.328125 C 11.085938 3.527344 10.941406 3.691406 10.761719 3.691406 Z M 10.761719 3.691406"
            />
        </SvgIcon>
    );
};

CommentIcon.displayName = 'CommentIcon';

export default CommentIcon;
