import React from 'react';
import PropTypes from 'prop-types';

import { TrashIcon } from '../Icons';
import { IconButton } from '../Button';
import { componentsTranslations } from '../translations';

const TagEditorTag = ({ children, intl, onDelete }) => (
    <div className="onsolve-tag-editor__tag">
        <span>{children}</span>
        <IconButton aria-label={intl.formatMessage(componentsTranslations.ng_generic_delete)}>
            <TrashIcon onClick={onDelete} />
        </IconButton>
    </div>
);

TagEditorTag.propTypes = {
    children: PropTypes.string,
    intl: PropTypes.object.isRequired,
    onDelete: PropTypes.func
};

TagEditorTag.displayName = 'TagEditorTag';

export default TagEditorTag;
