import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { keyCodes } from '../../common/constants';
import { Focusable } from '../Focusable';
import { fp as _, DomUtils } from '../utils';

import CleanButton from './CleanButton';
import SearchButton from './SearchButton';
import Spinner from './Spinner';
import SuggestionList from './SuggestionList';
import SearchIcon from './../Icons/Search';

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            inputFocus: false,
            searchFocus: false,
            autoFocusSuggestionItem: false,
            loading: false,
            showSuggestionList: false,
            choiceMade: false
        };

        this.anchorEl = React.createRef();
        this.inputRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const regex = new RegExp(_.escapeRegExp(state.value), 'i');
        const { field, suggestions, variant, emptyMessageRender } = props;
        const { value, choiceMade } = state;
        let haveMatches;
        let newState = state;

        if (typeof field === 'string') {
            haveMatches = suggestions.some(item => regex.exec(item[field]));
        }

        if (typeof field === 'object') {
            haveMatches = field.some(fieldItem => suggestions.some(item => regex.exec(item[fieldItem])));
        }

        if (variant !== 'search') {
            if (value && (suggestions.length || emptyMessageRender) && !choiceMade) {
                newState.loading = false;
                newState.showSuggestionList = true;
            }

            if ((!haveMatches && !emptyMessageRender && variant !== 'suggest') || !value || choiceMade) {
                newState.showSuggestionList = false;
            }
        }

        return newState;
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (prevProps.value !== value) {
            this.setState({ value });
        }
    }

    componentDidMount() {
        const { handleChangeValue, delay } = this.props;

        this.handleGetSuggestionDebounced = _.debounce(value => {
            handleChangeValue(value);
            this.setState({ loading: false });
        }, delay);
    }

    componentWillUnmount() {
        this.handleGetSuggestionDebounced.cancel();
    }

    handleInputFocus = () => {
        this.inputRef.current && this.inputRef.current.focus();
    };

    handleChange = e => {
        const { value } = e.target;
        const { variant, onChange } = this.props;

        onChange(value);

        if (variant !== 'search') {
            this.setState({ value, choiceMade: false }, () => {
                DomUtils.setFocus(this.inputRef);
            });
        } else {
            this.setState({ value });
        }
    };

    handleFocus = () => {
        const { onInputFocus } = this.props;

        this.setState({ inputFocus: true, autoFocusSuggestionItem: false }, onInputFocus);
    };

    handleBlur = () => this.setState({ inputFocus: false });

    handleSearchFocus = () => this.setState({ searchFocus: true });

    handleSearchBlur = () => this.setState({ searchFocus: false });

    handleSearch = e => {
        e.preventDefault();
        e.stopPropagation();

        const {
            state: { value: rawValue },
            props: { value: query, onSearch, disabledSearch }
        } = this;

        const value = rawValue.trim();

        if (value !== query && !disabledSearch) {
            this.setState({ showSuggestionList: false, choiceMade: true, autoFocusSuggestionItem: false });
            onSearch(value);
        }
    };

    handleClean = () => {
        this.setState({ value: '', showSuggestionList: false, choiceMade: false, autoFocusSuggestionItem: false });
        this.props.onClean();
    };

    handleClose = () => {
        this.setState({ showSuggestionList: false, choiceMade: true, autoFocusSuggestionItem: false });
        this.props.onClose();
    };

    handleKeyUp = e => {
        e.preventDefault();
        let { value, choiceMade, showSuggestionList } = this.state;
        const { minLength, variant, disabledSearch } = this.props;
        const { TAB, ENTER_KEY: ENTER, ESC, DOWN, BACKSPACE, DELETE } = keyCodes;

        if (variant === 'search' || (disabledSearch && e.keyCode === ENTER)) {
            return;
        }

        switch (e.keyCode) {
            case ENTER:
            case TAB:
            case ESC:
                {
                    this.handleClose();
                }
                break;
            case DOWN:
                this.setState({ autoFocusSuggestionItem: true });
                return;
            case BACKSPACE:
            case DELETE:
                if (value && value.length <= minLength && showSuggestionList && !choiceMade) {
                    this.setState({ choiceMade: false, showSuggestionList: false, autoFocusSuggestionItem: false });
                }
                break;
            default:
                break;
        }
        value = value.trim();

        if (value && value.length > minLength) {
            this.setState({ loading: true });
            this.handleGetSuggestionDebounced(value);
        }
    };

    handleListClick = (e, itemValue, { item }) => {
        e.preventDefault();
        const { field, onSelection } = this.props;
        const regex = new RegExp(_.escapeRegExp(itemValue), 'i');
        const suggestionField =
            typeof field === 'string' ? field : field.find(fieldItem => regex.exec(item[fieldItem]));
        const value = item[suggestionField];

        DomUtils.setFocus(this.inputRef);

        this.setState({ value, choiceMade: true, autoFocusSuggestionItem: false });
        onSelection(item, suggestionField);
    };

    handleListKeyDown = e => {
        const { LEFT, RIGHT } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === LEFT || charCode === RIGHT) {
            DomUtils.setFocus(this.inputRef);
        }
    };

    render() {
        const {
            emptyMessageRender,
            placeholder,
            className,
            variant,
            suggestions,
            field,
            autoCompleteVariant,
            itemRender,
            searchType,
            dataItemKey,
            itemsLoading
        } = this.props;
        const { value, inputFocus, searchFocus, loading, showSuggestionList, autoFocusSuggestionItem } = this.state;

        const isMobile = searchType === 'mobile';

        const rootClasses = classNames('onsolve-search-box', className, {
            'onsolve-search-box--mobile': isMobile
        });
        const inputClasses = classNames('onsolve-search-box__body', { 'onsolve-search-box__body--active': inputFocus });
        const submitButtonClasses = classNames('onsolve-search-box__actions', {
            'onsolve-search-box__actions--active': searchFocus
        });

        return (
            <Fragment>
                <form className={rootClasses} onSubmit={this.handleSearch} ref={this.anchorEl}>
                    <label className={inputClasses}>
                        {isMobile && (
                            <div className="onsolve-search-box__icon onsolve-search-box__icon--mobile">
                                <SearchIcon color="secondary" />
                            </div>
                        )}
                        <div className="onsolve-search-box__input-container">
                            {!value && <span className="onsolve-search-box__placeholder">{placeholder}</span>}
                            <Focusable
                                render={({ classes }) => (
                                    <input
                                        aria-label={placeholder}
                                        className={classNames('onsolve-search-box__input', classes)}
                                        value={value}
                                        ref={this.inputRef}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        onKeyUp={this.handleKeyUp}
                                    />
                                )}
                            />
                        </div>
                        {(loading || itemsLoading) && variant !== 'search' && <Spinner />}
                        {value && <CleanButton tabIndex={0} onFocus={this.handleClean} onClick={this.handleClean} />}
                    </label>
                    {searchType === 'standard' && (
                        <div className={submitButtonClasses}>
                            <SearchButton onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} />
                        </div>
                    )}
                </form>
                <SuggestionList
                    autoFocus={autoFocusSuggestionItem}
                    emptyMessageRender={emptyMessageRender}
                    search={value}
                    anchorEl={this.anchorEl}
                    showSuggestionList={showSuggestionList}
                    suggestions={suggestions}
                    field={field}
                    variant={autoCompleteVariant}
                    itemRender={itemRender}
                    dataItemKey={dataItemKey}
                    onClick={this.handleListClick}
                    onClose={this.handleClose}
                    onKeyDown={this.handleListKeyDown}
                />
            </Fragment>
        );
    }
}

SearchBox.propTypes = {
    /**
    Whether auto complete behavior is enabled.
    */
    autoComplete: PropTypes.bool,
    /**
    Specifies an auto completion variant:
    - 'none': shows only suggestions list.
    - 'other: shows suggestions list with 'starts with' and 'contains' sections. 
    */
    autoCompleteVariant: PropTypes.oneOf(['none', 'other']),
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies the key for autocompletion / suggestion list.
    */
    dataItemKey: PropTypes.string,
    /**
    Specifies a delay with which handleChangeValue function is called on search edit.
    */
    delay: PropTypes.number,
    /**
     * If 'disabledSearch' is set to 'true', the search box will never trigger onSearch.
     * This means the search box will only display the suggestions list and allow to pick items from it,
     * but it won't allow initiating the search, the results of which are supposed to go to another place.
     */
    disabledSearch: PropTypes.bool,
    /**
    Specifies an empty message render function.
    */
    emptyMessageRender: PropTypes.func,
    /**
    Name of a field(s) to search in.
    */
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /**
    Callback function on change event.
    */
    handleChangeValue: PropTypes.func,
    /**
    Specifies an item render function.
    */
    itemRender: PropTypes.func,
    /**
    Whether the items are currently being loaded. Spinner will be displayed when set to true.
    */
    itemsLoading: PropTypes.bool,
    /**
    Specifies a minimum number of symbols required for the search to be triggered.
    */
    minLength: PropTypes.number,
    /**
    Callback function on change event.
    */
    onChange: PropTypes.func,
    /**
    Callback function on clean event.
    */
    onClean: PropTypes.func,
    /**
    Callback function on close event.
    */
    onClose: PropTypes.func,
    /**
    Callback function on focus event.
    */
    onInputFocus: PropTypes.func,
    /**
    Callback function on search event.
    */
    onSearch: function(props, propName) {
        if (
            props['disabledSearch'] === false &&
            (props[propName] === undefined || typeof props[propName] !== 'function')
        ) {
            return new Error(`Please provide a "${propName}" function!`);
        }
    },
    /**
    Callback function on select event.
    */
    onSelection: PropTypes.func,
    /**
    Specifies a placeholder text.
    */
    placeholder: PropTypes.node,
    /**
    Specifies a search type:
    - 'standard': displays standard view of the component.
    - 'mobile: displays mobile view of the component.
    */
    searchType: PropTypes.oneOf(['standard', 'mobile']),
    /**
    The list of suggestion items.
    */
    suggestions: PropTypes.arrayOf(Object),
    /**
    The current search value.
    */
    value: PropTypes.string,
    /**
    Specifies the behavior related to autocompletion/suggestion list: 
    - 'search': search without autocompletion list
    - 'autocomplete': displays autocompletion list, if there is a match of current input value with an item from suggestions list
    - 'suggest': displays autocompletion list regardless of whether there is a match
    */
    variant: PropTypes.oneOf(['search', 'autocomplete', 'suggest'])
};

SearchBox.defaultProps = {
    autoComplete: false,
    delay: 200,
    variant: 'search',
    minLength: 2,
    autoCompleteVariant: 'other',
    suggestions: [],
    handleChangeValue: () => {},
    onInputFocus: () => {},
    onSelection: () => {},
    onChange: () => {},
    onClose: () => {},
    onClean: () => {},
    searchType: 'standard',
    value: '',
    disabledSearch: false
};

SearchBox.displayName = 'SearchBox';

export default SearchBox;
