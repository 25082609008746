import { ENTITY_TYPES, NODE_TYPES } from '../../constants';
import { HorizontalLineBlock } from './HorizontalLineBlock';

const createHorizontalLinePlugin = () => {
    const blockRendererFn = (block, { getEditorState }) => {
        const blockType = block.getType();

        if (blockType !== NODE_TYPES.horizontalRule.style) {
            return null;
        }

        const entityKey = block.getEntityAt(0);

        if (!entityKey) {
            return {
                editable: false
            };
        }

        const entity = getEditorState()
            .getCurrentContent()
            .getEntity(entityKey);

        switch (entity.type) {
            case ENTITY_TYPES.HORIZONTAL_RULE: {
                return {
                    component: HorizontalLineBlock,
                    editable: false
                };
            }

            default:
                return null;
        }
    };

    return {
        blockRendererFn
    };
};

export default createHorizontalLinePlugin;
