import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToastrStackContainer = ({ children, position }) => {
    const classes = classNames('onsolve-toastr-stack', `onsolve-toastr-stack--${position}`);

    return <div className={classes}>{children}</div>;
};

ToastrStackContainer.propTypes = {
    children: PropTypes.node,
    position: PropTypes.oneOf(['top-left', 'top-right'])
};

ToastrStackContainer.displayName = 'ToastrStackContainer';

export default ToastrStackContainer;
