import React from 'react';
import SvgIcon from './SvgIcon';

const SlidersHorizontal = props => (
    <SvgIcon {...props}>
        <g fill="none">
            <path d="M14.5 15.5h-13a1 1 0 0 1-1-1v-13a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1z" />
            <path d="M8 5.5h4.5m-9 0h2" />
            <path d="M5.5 3.5v4m2.5 3H3.5m9 0h-2" />
            <path d="M10.5 8.5v4" />
        </g>
    </SvgIcon>
);

SlidersHorizontal.displayName = 'SlidersHorizontalIcon';

export default SlidersHorizontal;
