import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import { ScrollbarsContext } from './ScrollbarsContext';
import { DomUtils, fp as _ } from '../utils';
import { isMobile } from '../../common/utility';

const withScrollbarsHoc = WrappedComponent => {
    class WithScrollbars extends Component {
        constructor(props) {
            super(props);

            this.viewSize = {
                scrollHeight: null,
                scrollWidth: null
            };
            this.scrollBars = React.createRef();

            this.update = this.update.bind(this);
            this.scrollToBottom = this.scrollToBottom.bind(this);
            this.scrollToTop = this.scrollToTop.bind(this);

            this.handleWindowResize = _.debounce(() => this.updateScrollbarWidth(), 10);
            this.handleScroll = this.handleScroll.bind(this);

            this.handleScrollStart = this.handleScrollStart.bind(this);
            this.handleScrollStop = this.handleScrollStop.bind(this);
            this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
            this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
            this.renderView = this.renderView.bind(this);
        }

        componentDidMount() {
            window.addEventListener('resize', this.handleWindowResize);

            const scrollBarsElement = this.getScrollBarsElement();

            this.viewSize = {
                scrollHeight: scrollBarsElement.getScrollHeight(),
                scrollWidth: scrollBarsElement.getScrollWidth()
            };
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.handleWindowResize);
        }

        getScrollBarsElement() {
            return this.scrollBars.current;
        }

        handleScroll(e) {
            const { onScrollLeft, onScrollTop } = this.props;

            const scrollBarsElement = this.getScrollBarsElement();

            const values = scrollBarsElement.getValues();
            const scrollTop = scrollBarsElement.getScrollTop();
            const scrollLeft = scrollBarsElement.getScrollLeft();
            const { lastViewScrollTop = 0, lastViewScrollLeft = 0 } = scrollBarsElement;

            if (onScrollLeft && scrollLeft !== lastViewScrollLeft) {
                onScrollLeft(e, { position: scrollLeft, values });
            } else if (onScrollTop && scrollTop !== lastViewScrollTop) {
                onScrollTop(e, { position: scrollTop, values });
            }
        }

        handleScrollStart() {
            const { onScrollStart } = this.props;

            if (onScrollStart) {
                const e = { target: this.scrollBars.current.view };

                onScrollStart(e);
            }
        }

        handleScrollStop() {
            const { onScrollStop } = this.props;

            if (onScrollStop) {
                const e = { target: this.scrollBars.current.view };

                onScrollStop(e);
            }
        }

        handleAnimationEnd() {
            const { scrollTopAfterAnimation } = this.props;

            this.handleTransitionEnd({ scrollTopAfterAnimation });
        }

        handleTransitionEnd({ scrollTopAfterAnimation }) {
            const { scrollHeight, scrollWidth } = this.viewSize;
            const { getScrollHeight, getScrollWidth, getClientHeight, thumbVertical } = this.getScrollBarsElement();
            const nextScrollHeight = getScrollHeight();
            const nextScrollWidth = getScrollWidth();

            if (
                scrollHeight !== nextScrollHeight ||
                scrollWidth !== nextScrollWidth ||
                (nextScrollHeight > getClientHeight() && !thumbVertical.clientHeight)
            ) {
                this.viewSize = {
                    scrollHeight: nextScrollHeight,
                    scrollWidth: nextScrollWidth
                };

                if (scrollTopAfterAnimation) {
                    this.scrollToTop();
                }
                this.update();
            }
        }

        updateScrollbarWidth() {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                const scrollbarWidth = DomUtils.getScrollbarWidth(true);
                const width = `${scrollbarWidth ? -scrollbarWidth : 0}px`;

                scrollBarsElement.view.style.marginRight = width;
                scrollBarsElement.view.style.marginBottom = width;
                this.update();
            }
        }

        update() {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                scrollBarsElement.update();
            }
        }

        scrollLeft(value) {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                scrollBarsElement.scrollLeft(value);

                const { clientWidth, scrollWidth } = scrollBarsElement.getValues();

                scrollBarsElement.thumbHorizontal.style.visibility = scrollWidth > clientWidth ? 'visible' : 'hidden';
            }
        }

        scrollTop(value) {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                scrollBarsElement.scrollTop(value);

                const { clientHeight, scrollHeight } = scrollBarsElement.getValues();

                scrollBarsElement.trackVertical.style.visibility = scrollHeight > clientHeight ? 'visible' : 'hidden';
            }
        }

        scrollToTop() {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                setTimeout(scrollBarsElement.scrollToTop, 0);
            }
        }

        scrollToBottom() {
            const scrollBarsElement = this.getScrollBarsElement();

            if (scrollBarsElement) {
                setTimeout(scrollBarsElement.scrollToBottom, 0);
            }
        }

        renderView(props) {
            const scrollbarWidth = isMobile.any() ? 0 : -DomUtils.getScrollbarWidth(true);
            const margins = { marginRight: scrollbarWidth, marginBottom: scrollbarWidth };

            return (
                <div
                    tabIndex={-1}
                    className="onsolve-custom-scrollbars-view"
                    {...props}
                    style={{ ...props.style, ...margins }}
                />
            );
        }

        render() {
            const { blue, classes, className, children, vertical, horizontal, stickChildToBody, ...other } = this.props;
            const rootClasses = classNames(
                'onsolve-custom-scrollbars',
                className,
                {
                    'onsolve-custom-scrollbars--horizontal': horizontal,
                    'onsolve-custom-scrollbars--vertical': vertical,
                    'blue-scrollbar': blue,
                    'onsolve-custom-scrollbars--stick-child-to-body': stickChildToBody
                },
                classes.root
            );

            delete other.onScrollLeft;
            delete other.onScrollTop;
            delete other.onScrollStart;
            delete other.onScrollStop;
            delete other.scrollTopAfterAnimation;

            return (
                <WrappedComponent
                    ref={this.scrollBars}
                    className={rootClasses}
                    {...other}
                    onScroll={this.handleScroll}
                    onScrollStart={this.handleScrollStart}
                    onScrollStop={this.handleScrollStop}
                    onAnimationEnd={this.handleAnimationEnd}
                    onTransitionEnd={this.handleTransitionEnd}
                    renderView={this.renderView}
                >
                    <ScrollbarsContext.Provider
                        value={{
                            update: this.update,
                            scrollToBottom: this.scrollToBottom,
                            scrollToTop: this.scrollToTop
                        }}
                    >
                        {children}
                    </ScrollbarsContext.Provider>
                </WrappedComponent>
            );
        }
    }

    WithScrollbars.propTypes = {
        blue: PropTypes.bool,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        classes: PropTypes.object,
        horizontal: PropTypes.bool,
        onScrollLeft: PropTypes.func,
        onScrollStart: PropTypes.func,
        onScrollStop: PropTypes.func,
        onScrollTop: PropTypes.func,
        onUpdate: PropTypes.func,
        scrollTopAfterAnimation: PropTypes.bool,
        stickChildToBody: PropTypes.bool,
        vertical: PropTypes.bool
    };

    WithScrollbars.defaultProps = {
        classes: {},
        vertical: true,
        horizontal: false
    };

    WithScrollbars.displayName = 'Scrollbars';

    return WithScrollbars;
};

export default withScrollbarsHoc(Scrollbars);
