import { Map } from 'immutable';

// Function returns collection of currently selected blocks.
const getSelectedBlocksMap = editorState => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const blockMap = contentState.getBlockMap();

    return blockMap
        .toSeq()
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);
};

// Function returns collection of currently selected blocks.
export const getSelectedBlocksList = editorState => {
    return getSelectedBlocksMap(editorState).toList();
};

// Function will return currently selected blocks meta data.
export const getSelectedBlocksMetadata = editorState => {
    let metaData = new Map({});
    const selectedBlocks = getSelectedBlocksList(editorState);

    if (selectedBlocks && selectedBlocks.size > 0) {
        for (let i = 0; i < selectedBlocks.size; i += 1) {
            const data = selectedBlocks.get(i).getData();

            if (!data || data.size === 0) {
                metaData = metaData.clear();
                break;
            }
            if (i === 0) {
                metaData = data;
            } else {
                metaData.forEach((value, key) => {
                    // eslint-disable-line no-loop-func
                    if (!data.get(key) || data.get(key) !== value) {
                        metaData = metaData.delete(key);
                    }
                });
                if (metaData.size === 0) {
                    metaData = metaData.clear();
                    break;
                }
            }
        }
    }
    return metaData;
};
