import React from 'react';
import classNames from 'classnames';
import { sortableHandle } from 'react-sortable-hoc';

import { ReorderIcon } from '../../Icons';
import { OnsolvePropTypes } from '../../utils';

const SortableHandleIcon = sortableHandle(({ color, size }) => <ReorderIcon color={color} size={size} />);

const TableDraggableCell = props => {
    const {
        align,
        drag: { iconColor, iconSize }
    } = props;
    const cellClasses = classNames('onsolve-table__header-cell onsolve-table__header-cell--draggable', {
        [`text-${align}`]: align !== undefined
    });

    return (
        <td className={cellClasses}>
            <SortableHandleIcon color={iconColor} size={iconSize} />
        </td>
    );
};

TableDraggableCell.propTypes = {
    align: OnsolvePropTypes.align,
    drag: OnsolvePropTypes.drag
};

TableDraggableCell.defaultProps = {
    drag: {
        iconSize: 'md'
    }
};

TableDraggableCell.displayName = 'TableDraggableCell';

export default TableDraggableCell;
