import React from 'react';

import SvgIcon from './SvgIcon';

const FileUpload = props => {
    return (
        <SvgIcon viewBox="0 0 24 16" {...props}>
            <path
                d="M19.355 6.035C18.675 2.595 15.64 0 12 0 9.1 0 6.605 1.64 5.35 4.035 2.345 4.36 0 6.905 0 10a6 6 0 0 0 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.055-4.78-4.645-4.965zM14 9v4h-4V9H7l5-5 5 5h-3z"
                fillRule="nonzero"
            />
        </SvgIcon>
    );
};

FileUpload.displayName = 'FileUploadName';

export default FileUpload;
