import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { addDays, addMonths, endOfMonth, isBefore, isSameMonth, startOfMonth, subDays, subMonths } from 'date-fns';

import { keyCodes } from '../../../common/constants';

import { Popover, PopoverBody } from '../../Popover';
import { DomUtils } from '../../utils';

import { DatePicker, NavButton } from '../core';

class DateRangePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMonth: undefined,
            value: undefined,
            focusedDate: undefined,
            tabbing: false
        };

        this.handleDatePickerKeyDown = this.handleDatePickerKeyDown.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value || !props.isOpen) {
            return {
                currentMonth: props.initialMonth,
                value: props.value,
                focusedDate: props.initialMonth || state.focusedDate
            };
        }
        return null;
    }

    handleDatePickerChange = (e, date) => {
        const {
            value: [from, to]
        } = this.state;
        const { onChange } = this.props;

        let fromDay = from;

        let toDay = to;

        if (!from || (from && to) || isBefore(date, from)) {
            fromDay = date;
            toDay = undefined;
        } else {
            toDay = date;
        }

        this.setState({ value: [fromDay, toDay], tabbing: false });

        if (onChange) {
            onChange([fromDay, toDay]);
        }
    };

    handlePrevMonthClick = () => {
        this.calculateMonth(subMonths);
    };

    handleNextMonthClick = () => {
        this.calculateMonth(addMonths);
    };

    handleNavigationButtonKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { UP } = keyCodes;

        if (charCode === UP && e.altKey) {
            this.setState({ tabbing: false });
        }

        const { onKeyDown } = this.props;

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    handleDatePickerKeyDown(e) {
        const { onKeyDown } = this.props;

        if (!e.altKey) {
            const charCode = DomUtils.getCharCode(e);

            const { TAB, UP, DOWN, LEFT, RIGHT, SPACE, ENTER_KEY } = keyCodes;

            switch (charCode) {
                case TAB:
                    this.setState({ tabbing: true });
                    break;
                case UP:
                    e.preventDefault();
                    this.moveToPreviousMonth(subDays(this.state.focusedDate, 7));
                    break;
                case LEFT:
                    e.preventDefault();
                    this.moveToPreviousMonth(subDays(this.state.focusedDate, 1));
                    break;
                case DOWN:
                    e.preventDefault();
                    this.moveToNextMonth(addDays(this.state.focusedDate, 7));
                    break;
                case RIGHT:
                    e.preventDefault();
                    this.moveToNextMonth(addDays(this.state.focusedDate, 1));
                    break;
                case SPACE:
                case ENTER_KEY:
                    e.preventDefault();
                    this.handleDatePickerChange(e, this.state.focusedDate);
                    this.setState({ focusedDate: undefined });
                    break;
            }
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    handleKeyUp = e => {
        const { onKeyUp } = this.props;

        const charCode = DomUtils.getCharCode(e);
        const { ESC } = keyCodes;

        if (charCode === ESC) {
            this.setState({ focusedDate: undefined, tabbing: false });
        }

        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    moveToPreviousMonth = date => {
        const { currentMonth, focusedDate } = this.state;
        const nextMonth = addMonths(currentMonth, 1);

        if (date.getMonth() === nextMonth.getMonth() || date.getMonth() === focusedDate.getMonth()) {
            this.setState({ focusedDate: date, tabbing: false });
        } else {
            this.setFocusedDate(date);
        }
    };

    moveToNextMonth = date => {
        const { focusedDate } = this.state;
        const endMonth = addMonths(this.state.currentMonth, 1);

        if (date.getMonth() === focusedDate.getMonth() || date.getMonth() === endMonth.getMonth()) {
            this.setState({ focusedDate: date, tabbing: false });
        } else {
            this.setState({ focusedDate: date, currentMonth: endMonth, tabbing: false });
        }
    };

    setFocusedDate = value => {
        this.setState({ focusedDate: value, currentMonth: value, tabbing: false });
    };

    calculateMonth(fn) {
        const { onMonthChange } = this.props;
        const { currentMonth } = this.state;

        const value = fn(currentMonth, 1);

        if (onMonthChange) {
            onMonthChange();
        }

        this.setState({
            currentMonth: value,
            focusedDate: new Date(value.getFullYear(), value.getMonth(), 1)
        });
    }

    render() {
        const { id, isOpen, autoFocus, onClose, currentDate, minDate, maxDate } = this.props;
        const { currentMonth, value, focusedDate, tabbing } = this.state;
        const nextMonth = addMonths(currentMonth, 1);
        const placement = DomUtils.isRtl() ? 'bottom-start' : 'bottom-end';
        const range = [startOfMonth(currentMonth), endOfMonth(nextMonth)];
        const leftButtonDisabled = isSameMonth(currentMonth, minDate);
        const rightButtonDisabled = isSameMonth(nextMonth, maxDate);

        return (
            <Popover
                boundariesElement={id}
                isOpen={isOpen}
                target={id}
                placement={placement}
                onClose={onClose}
                trigger="manual"
            >
                <PopoverBody>
                    <div className="onsolve-date-range" onKeyUp={this.handleKeyUp}>
                        <NavButton
                            direction="left"
                            disabled={leftButtonDisabled}
                            onKeyDown={this.handleNavigationButtonKeyDown}
                            onClick={this.handlePrevMonthClick}
                        />
                        <DatePicker
                            key="startDate"
                            id="0"
                            autoFocus={autoFocus && !tabbing}
                            month={currentMonth}
                            range={range}
                            value={value}
                            focusedDate={focusedDate}
                            currentDate={currentDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={this.handleDatePickerChange}
                            onKeyDown={this.handleDatePickerKeyDown}
                        />
                        <DatePicker
                            key="endDate"
                            id="1"
                            autoFocus={autoFocus && !tabbing}
                            month={nextMonth}
                            range={range}
                            value={value}
                            focusedDate={focusedDate}
                            currentDate={currentDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={this.handleDatePickerChange}
                            onKeyDown={this.handleDatePickerKeyDown}
                        />
                        <NavButton
                            direction="right"
                            disabled={rightButtonDisabled}
                            onKeyDown={this.handleNavigationButtonKeyDown}
                            onClick={this.handleNextMonthClick}
                        />
                    </div>
                </PopoverBody>
            </Popover>
        );
    }
}

DateRangePopup.propTypes = {
    autoFocus: PropTypes.bool,
    currentDate: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    initialMonth: PropTypes.instanceOf(Date),
    isOpen: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMonthChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};

DateRangePopup.defaultProps = {
    autoFocus: false,
    initialMonth: new Date(),
    value: [null, null]
};

DateRangePopup.displayName = 'DateRangePopup';

export default DateRangePopup;
