import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '../../Dropdowns';
import { componentsTranslations } from '../../translations';

const handleDropdownChange = (event, type, blockType, onToggle) => {
    event.preventDefault();

    if (type === blockType) {
        return;
    }

    onToggle(type);
};

const StylesDropdownControl = forwardRef(({ editorState, intl, list, onToggle, disabled }, ref) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const activeId = list.findIndex(item => {
        return item.id === blockType;
    });
    let activeValue = '';

    if (activeId !== -1) {
        activeValue = list[activeId].id;
    }

    return (
        <div
            className={`onsolve-wysiwyg-editor__drop-down`}
            aria-label={intl.formatMessage(componentsTranslations.ng_wysiwyg_editor_toolbar_dropdown_headers)}
        >
            <Dropdown
                ref={ref}
                variant="standard"
                value={activeValue}
                data={list}
                onChange={(event, type) => handleDropdownChange(event, type, blockType, onToggle)}
                disabled={disabled}
            />
        </div>
    );
});

StylesDropdownControl.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired
};

StylesDropdownControl.defaultProps = {
    disabled: false
};

StylesDropdownControl.displayName = 'StylesDropdownControl';

export default StylesDropdownControl;
