import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../Focusable';

const ListItem = ({ active, children, className, onClick, onFocus, selected, color }) => (
    <Focusable
        outlineDirection="inset"
        render={({ classes: focusableClasses }) => {
            const rowClasses = classNames('onsolve-list__row', focusableClasses, className, {
                'onsolve-list__row--active': active,
                'onsolve-list__row--selected': selected,
                [`onsolve-list__row--${color}`]: color
            });

            return (
                <li role="option" tabIndex="0" onClick={onClick} onFocus={onFocus} className={rowClasses}>
                    {children}
                </li>
            );
        }}
    />
);

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
    /**
    Control item active state
    */
    active: PropTypes.bool,
    /**
    Content for the list item
    */
    children: PropTypes.node,
    /**
    Specifies class name for the wrapper
    */
    className: PropTypes.string,
    /**
     Override color
     */
    color: PropTypes.string,
    /**
    Callback function for the item click event
    */
    onClick: PropTypes.func,
    /**
    Callback function for the item focus event
    */
    onFocus: PropTypes.func,
    /**
    Control item select state
    */
    selected: PropTypes.bool
};

export default ListItem;
