import React from 'react';
import SvgIcon from './SvgIcon';

const FormatBold = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.63 5.82C8.46 5.24 9 4.38 9 3.5 9 1.57 7.43 0 5.5 0H0v12h6.25A3.26 3.26 0 0 0 9.5 8.75c0-1.3-.77-2.4-1.87-2.93zM2.5 2h2.75a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 5.25 5H2.5V2zm3.25 8H2.5V7h3.25a1.5 1.5 0 1 1 0 3z"
                fillRule="nonzero"
                stroke="none"
            />
        </SvgIcon>
    );
};

FormatBold.displayName = 'FormatBoldIcon';

export default FormatBold;
