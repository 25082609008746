import React from 'react';
import SvgIcon from './SvgIcon';

const DevicesIcon = props => (
    <SvgIcon viewBox="0 0 26 26" {...props}>
        <path
            stroke="none"
            strokeWidth="1"
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm7.697 6H4.97c-.9 0-1.636.71-1.636 1.58v9.483c0 .87.736 1.58 1.636 1.58h3.273v-1.58H4.97V7.58h14.727V6zm-6.545 6.322H9.88v1.407c-.5.435-.818 1.055-.818 1.754s.32 1.32.818 1.754v1.407h3.273v-1.407c.5-.435.818-1.055.818-1.754s-.32-1.32-.818-1.754v-1.407zm7.364-3.16h-4.91a.87.87 0 0 0-.818.79v7.903a.87.87 0 0 0 .818.79h4.91a.87.87 0 0 0 .818-.79V9.95a.87.87 0 0 0-.818-.79zm-.818 1.58v6.322h-3.273v-6.322h3.273zm-8.182 3.556c.68 0 1.227.53 1.227 1.185s-.548 1.185-1.227 1.185-1.227-.53-1.227-1.185.548-1.185 1.227-1.185z"
        />
    </SvgIcon>
);

DevicesIcon.displayName = 'DevicesIcon';

export default DevicesIcon;
