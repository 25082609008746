import React from 'react';
import SvgIcon from './SvgIcon';

const CornerDownArrow = props => (
    <SvgIcon {...props}>
        <path d="M0 .5A.5.5 0 01.992.41L1 .5v4a3.5 3.5 0 003.308 3.495L4.5 8h8.793L9.146 3.854a.5.5 0 01-.057-.638l.057-.07a.5.5 0 01.638-.057l.07.057 5 5 .011.013a.503.503 0 01.033.039l-.044-.052A.502.502 0 0115 8.5v.02a.503.503 0 01-.005.052L15 8.5a.502.502 0 01-.089.284l-.013.018a.503.503 0 01-.033.04l-.011.012-5 5a.5.5 0 01-.765-.638l.057-.07L13.293 9H4.5A4.5 4.5 0 01.005 4.712L0 4.5v-4z" />
    </SvgIcon>
);

CornerDownArrow.displayName = 'CornerDownArrowIcon';

export default CornerDownArrow;
