import React from 'react';
import SvgIcon from './SvgIcon';

const FormatAlignLeft = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M0 14h10v-2H0v2zM10 4H0v2h10V4zM0 0v2h14V0H0zm0 10h14V8H0v2z" fillRule="nonzero" />
        </SvgIcon>
    );
};

FormatAlignLeft.displayName = 'FormatAlignLeftIcon';

export default FormatAlignLeft;
