import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MenuItemText = React.forwardRef(({ children, className, ...other }, ref) => {
    const rootClassNames = classNames('onsolve-menu__text', className);

    return (
        <div role="presentation" ref={ref} className={rootClassNames} {...other}>
            {children}
        </div>
    );
});

MenuItemText.propTypes = {
    /**
    Specifies menu text content
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

MenuItemText.displayName = 'MenuItemText';

export default MenuItemText;
