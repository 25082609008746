import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';

const ToggleButton = ({ children, className, color, disabled, selected, size, value, variant, onChange }) => {
    const handleClick = () => {
        if (onChange) {
            onChange(value);
        }
    };

    const rootClasses = classNames('onsolve-toggle-button', className);

    return (
        <Button
            className={rootClasses}
            color={color}
            size={size}
            variant={variant}
            active={selected}
            disabled={disabled}
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

ToggleButton.propTypes = {
    /**
    Override or extend the styles applied to the component. `string`
    */
    children: PropTypes.node,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
    Specifies a button color.
    */
    color: PropTypes.string,
    /**
    Specifies a button enabling.
    */
    disabled: PropTypes.bool,
    /**
    Specifies onChange handler
    */
    onChange: PropTypes.func,
    /**
    Specifies selected state
    */
    selected: PropTypes.bool,
    /**
    Specifies a button size.
    */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
    Entered value of the component.
    */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    /**
    Specifies one of the variants of component.
    */
    variant: PropTypes.oneOf(['raised', 'outline'])
};

ToggleButton.displayName = 'ToggleButton';

ToggleButton.defaultProps = Button.defaultProps;

export default ToggleButton;
