import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuid from 'uuid';

import parse from 'date-fns/parse';
import isDateValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';

import { keyCodes } from '../../../common/constants';
import { DomUtils, fp as _ } from '../../utils';

import { CalendarIcon } from '../../Icons';
import {
    InputFieldControl,
    InputFieldGroup,
    InputFieldContainer,
    InputFieldLabel,
    InputFieldMessage,
    InputFieldToggle,
    InputFieldDivider
} from '../../InputBase';
import { DateInputText, DateUtils, DatePopupUtils } from '../core';
import DateRangePopup from './DateRangePopup';

class DateRangePickerInput extends Component {
    constructor(props) {
        super(props);

        this.id = `date_range_picker_${props.id || uuid()}`;

        this.dateRangePickerRef = React.createRef();
        this.startDateMonthInputRef = React.createRef();
        this.endDateMonthInputRef = React.createRef();
        this.endDateYearInputRef = React.createRef();
        this.containerRef = React.createRef();
        this.toggleRef = React.createRef();
        this.firstLastDateInputFocused = false;

        this.state = {
            isOpen: false,
            focused: false,
            autoFocusPopover: false,
            value: [undefined, undefined],
            lastValidValue: [undefined, undefined],
            startDate: { value: undefined, isValid: true },
            endDate: { value: undefined, isValid: true }
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { value: valueProps, dateFormat } = props;
        const { lastValidValue: valueState } = state;

        if (!valueProps && !_.every(valueState, date => !date)) {
            return {
                value: [undefined, undefined],
                lastValidValue: [undefined, undefined],
                startDate: { value: undefined, isValid: true },
                endDate: { value: undefined, isValid: true }
            };
        }

        if (!(Array.isArray(valueProps) && valueProps.length === 2)) {
            return null;
        }

        const predicate = (item, index) => {
            return item !== undefined && isDateValid(item) && item !== valueState[index];
        };

        if (_.every(valueProps, predicate)) {
            const result = DateUtils.getDateRangeInputString(valueProps, dateFormat);

            return { lastValidValue: result.value, ...result };
        }
        return null;
    }

    handleInputContainerKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);
        const { ESC } = keyCodes;

        if (charCode === ESC) {
            e.stopPropagation();
        }
    };

    handleInputKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);
        const { ESC, ENTER_KEY } = keyCodes;

        if (charCode === ESC) {
            e.preventDefault();
            e.stopPropagation();

            const { dateFormat } = this.props;
            const { lastValidValue } = this.state;
            const state = { isOpen: false, ...DateUtils.getDateRangeInputString(lastValidValue, dateFormat) };

            this.setState(state, this.validateDateRange);
        }

        if (charCode === ENTER_KEY) {
            this.validateDateRange();
        }
    };

    handleInputKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { TAB, DOWN } = keyCodes;

        if (charCode === DOWN && e.altKey) {
            this.setState({ isOpen: true, focused: true, autoFocusPopover: true });
        } else if (charCode === TAB) {
            if (
                e.target === this.endDateYearInputRef.current ||
                (e.target === this.startDateMonthInputRef.current && e.shiftKey)
            ) {
                this.firstLastDateInputFocused = true;
                this.validateDateRange();
            }
        }
    };

    handleInputMouseDown = () => {
        this.setState({ autoFocusPopover: false });
    };

    handleInputChange = (_, data) => {
        const { name, value, isValid } = data;
        const { dateFormat } = this.props;

        this.setState({ [name]: { value, isValid } }, () => {
            let start;
            let end;

            const { startDate, endDate } = this.state;

            if (startDate.value && startDate.isValid) {
                start = parse(startDate.value, dateFormat, new Date());
            }
            if (endDate.value && endDate.isValid) {
                end = parse(endDate.value, dateFormat, new Date());
            }

            this.setStartDate(start);
            this.setEndDate(end);
            this.updateDatePicker();
        });
    };

    handleInputFocus = () => {
        this.setState({ isOpen: true, focused: true });
    };

    handleInputBlur = () => {
        this.setState({ focused: false });
    };

    handleDateRangePopupClose = e => {
        const calendarClicked = DomUtils.isInDOMSubtree(e.target, this.toggleRef.current);
        const outsideClicked = !DomUtils.isInDOMSubtree(e.target, this.containerRef.current);
        const result = outsideClicked || calendarClicked;

        if (result) {
            this.validateDateRange(calendarClicked);
        }
    };

    handleDateRangeChange = value => {
        const { dateFormat } = this.props;
        const [start, end] = value;

        this.startDate = start;
        this.endDate = end;

        const state = {
            ...DateUtils.getDateRangeInputString(value, dateFormat)
        };

        this.setState(state, () => {
            if (this.startDate && this.endDate) {
                this.setState({ focused: true, isOpen: false, autoFocusPopover: false }, this.validateDateRange);
            }
        });
    };

    handleDateRangeKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { UP } = keyCodes;

        if (charCode === UP && e.altKey) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({ focused: true, autoFocusPopover: false }, () => {
                DomUtils.setFocus(this.startDateMonthInputRef);
            });
        }
    };

    handleDateRangeKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        const { ESC } = keyCodes;

        if (charCode === ESC) {
            e.preventDefault();
            e.stopPropagation();

            const { dateFormat } = this.props;
            const { lastValidValue } = this.state;

            const state = {
                focused: true,
                isOpen: false,
                autoFocusPopover: false,
                ...DateUtils.getDateRangeInputString(lastValidValue, dateFormat)
            };

            this.setState(state, this.validateDateRange);
        }
    };

    handleToggleClick = () => {
        const { isOpen } = this.state;

        this.setState({
            isOpen: !isOpen,
            autoFocusPopover: !isOpen
        });
    };

    setStartDate = value => {
        if (value && isDateValid(value)) {
            this.startDate = value;
        } else {
            this.startDate = undefined;
        }
    };

    setEndDate = value => {
        if (value && isDateValid(value)) {
            let date = value;

            if (isBefore(date, this.startDate)) {
                date = this.startDate;
            }
            this.endDate = date;
        } else {
            this.endDate = undefined;
        }
    };

    updateDatePicker() {
        const value = [this.startDate, this.endDate];

        this.setState({ value });
    }

    validateDateRange(calendarClicked) {
        const { value, startDate, endDate } = this.state;
        const { onChange, onError } = this.props;
        const result = value.filter(Boolean);

        let state = {};

        if (!calendarClicked) {
            state = { isOpen: false, autoFocusPopover: false };
        }

        if (result.length === 1) {
            onError(value);
        } else if (startDate.isValid && endDate.isValid) {
            if (!this.firstLastDateInputFocused) {
                DomUtils.setFocus(this.endDateYearInputRef);
            }

            state = { ...state, lastValidValue: value };
            this.firstLastDateInputFocused = false;
            onChange(value);
        } else {
            onError([new Date(''), new Date('')]);
        }

        this.setState(state);
    }

    getInitialMonth(value) {
        const { currentDate } = this.props;

        return Array.isArray(value) ? value[0] : currentDate || new Date();
    }

    getDateRangePickerPopupContainer = () => {
        return DatePopupUtils.getPopupContainer(this.dateRangePickerRef.current);
    };

    render() {
        const { className, disabled, error, errorText, helpText, label, currentDate, maxDate, minDate } = this.props;
        const { endDate, isOpen, startDate, value, focused, autoFocusPopover } = this.state;
        const rootClasses = classNames('onsolve-date-input', className);

        return (
            <InputFieldControl ref={this.dateRangePickerRef} className={rootClasses}>
                <InputFieldGroup>
                    {label && <InputFieldLabel>{label}</InputFieldLabel>}
                    <InputFieldContainer error={error} active={isOpen} focused={focused} ref={this.containerRef}>
                        <div className="d-flex flex-grow-1 w-100">
                            <DateInputText
                                disabled={disabled}
                                name="startDate"
                                monthRef={this.startDateMonthInputRef}
                                value={startDate.value}
                                onKeyUp={this.handleInputKeyUp}
                                onKeyDown={this.handleInputKeyDown}
                                onMouseDown={this.handleInputMouseDown}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange}
                            />
                            <InputFieldDivider symbol="-" />
                            <DateInputText
                                disabled={disabled}
                                name="endDate"
                                monthRef={this.endDateMonthInputRef}
                                yearRef={this.endDateYearInputRef}
                                value={endDate.value}
                                onKeyUp={this.handleInputKeyUp}
                                onKeyDown={this.handleInputKeyDown}
                                onMouseDown={this.handleInputMouseDown}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <InputFieldToggle
                            id={this.id}
                            icon={CalendarIcon}
                            innerRef={this.toggleRef}
                            disabled={disabled}
                            onClick={this.handleToggleClick}
                        />
                    </InputFieldContainer>
                </InputFieldGroup>
                <InputFieldMessage error={error} errorText={errorText} helpText={helpText} />
                <DateRangePopup
                    id={this.id}
                    container={this.getDateRangePickerPopupContainer}
                    value={value}
                    initialMonth={this.getInitialMonth(value)}
                    isOpen={isOpen}
                    maxDate={maxDate}
                    minDate={minDate}
                    autoFocus={autoFocusPopover}
                    currentDate={currentDate}
                    onClose={this.handleDateRangePopupClose}
                    onChange={this.handleDateRangeChange}
                    onKeyDown={this.handleDateRangeKeyDown}
                    onKeyUp={this.handleDateRangeKeyUp}
                />
            </InputFieldControl>
        );
    }
}

DateRangePickerInput.propTypes = {
    /**
     Applied css classes to the text input field component.
     */
    className: PropTypes.string,
    /**
     Specifies the current date shown in  the date popup
     */
    currentDate: PropTypes.instanceOf(Date),
    /**
     Specifies the date format applied to input
     */
    dateFormat: PropTypes.string,
    /**
     Specifies if the input should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies error text.
     */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a help text that shows below the input.
     */
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies an id attribute.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     The short text to inform about the type of input value.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies maximum date that can be chosen.
     */
    maxDate: PropTypes.instanceOf(Date),
    /**
     Specifies minimal date that can be chosen.
     */
    minDate: PropTypes.instanceOf(Date),
    /**
     Specifies input name whether it is startDate or EndDate.
     */
    name: PropTypes.string,
    /**
     Callback function on change event of text input field.
     */
    onChange: PropTypes.func,
    /**
     Callback function on error event of text input field.
     */
    onError: PropTypes.func,
    /**
     Specifies input value.
     */
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};

DateRangePickerInput.defaultProps = {
    dateFormat: 'MM/dd/yyyy',
    onChange: f => f,
    onError: f => f
};

DateRangePickerInput.displayName = 'DateRangePickerInput';

export default DateRangePickerInput;
