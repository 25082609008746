import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyleButton } from '.';

const InlineStyleControls = forwardRef(
    ({ editorState, iconComponent, onToggle, style, intl, translate, disabled }, ref) => {
        const currentStyle = editorState.getCurrentInlineStyle();

        return (
            <StyleButton
                isActive={currentStyle.has(style)}
                onToggle={onToggle}
                style={style}
                intl={intl}
                translate={translate}
                iconComponent={iconComponent}
                ref={ref}
                disabled={disabled}
            />
        );
    }
);

InlineStyleControls.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

InlineStyleControls.defaultProps = {
    disabled: false
};

InlineStyleControls.displayName = 'InlineStyleControls';

export default InlineStyleControls;
