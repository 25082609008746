import React from 'react';
import SvgIcon from './SvgIcon';

const MobileThreeDots = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,3.6c-1,0-1.8-0.8-1.8-1.8S7,0,8,0s1.8,0.8,1.8,1.8S9,3.6,8,3.6z M8,9.8C7,9.8,6.2,9,6.2,8S7,6.2,8,6.2 S9.8,7,9.8,8S9,9.8,8,9.8z M8,16c-1,0-1.8-0.8-1.8-1.8S7,12.4,8,12.4s1.8,0.8,1.8,1.8S9,16,8,16z"
        />
    </SvgIcon>
);

MobileThreeDots.displayName = 'MobileThreeDotsIcon';

export default MobileThreeDots;
