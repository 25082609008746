import React from 'react';

import SvgIcon from './SvgIcon';

const Ellipsis = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,5.33333333 C8.73333333,5.33333333 9.33333333,4.73333333 9.33333333,4 C9.33333333,3.26666667 8.73333333,2.66666667 8,2.66666667 C7.26666667,2.66666667 6.66666667,3.26666667 6.66666667,4 C6.66666667,4.73333333 7.26666667,5.33333333 8,5.33333333 L8,5.33333333 Z M8,6.66666667 C7.26666667,6.66666667 6.66666667,7.26666667 6.66666667,8 C6.66666667,8.73333333 7.26666667,9.33333333 8,9.33333333 C8.73333333,9.33333333 9.33333333,8.73333333 9.33333333,8 C9.33333333,7.26666667 8.73333333,6.66666667 8,6.66666667 L8,6.66666667 Z M8,10.6666667 C7.26666667,10.6666667 6.66666667,11.2666667 6.66666667,12 C6.66666667,12.7333333 7.26666667,13.3333333 8,13.3333333 C8.73333333,13.3333333 9.33333333,12.7333333 9.33333333,12 C9.33333333,11.2666667 8.73333333,10.6666667 8,10.6666667 L8,10.6666667 Z"
            id="Menu-Icon"
        />
    </SvgIcon>
);

Ellipsis.displayName = 'EllipsisIcon';

export default Ellipsis;
