import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fp as _, ReactUtils } from '../utils';

import { HeaderRow } from './Rows';
import { TableSettings, TablePropTypes } from './utils';

class Header extends Component {
    constructor(props) {
        super(props);

        this.headerTableHeaderRefs = [];
        this.headerContentRef = React.createRef();
        this.headerTableRef = React.createRef();
        this.headerLeftShadowRef = React.createRef();

        this.setHeaderTableBodyRefs = this.setHeaderTableBodyRefs.bind(this);
        this.setHeaderTableColumnGroupRef = this.setHeaderTableColumnGroupRef.bind(this);

        this.handleColumnResize = this.handleColumnResize.bind(this);
    }

    componentDidUpdate() {
        this.adjustHeaderRows();
    }

    componentDidMount() {
        this.adjustHeaderRows();
    }

    handleColumnResize(e, data) {
        const { columnResize } = this.props;

        columnResize.resize(e, data);
    }

    setScrollPosition({ position, values }) {
        const { current: scrollBarElement } = this.headerContentRef;
        const { current: leftShadowElement } = this.headerLeftShadowRef;

        if (scrollBarElement) {
            scrollBarElement.scrollLeft = position;
        }

        if (leftShadowElement) {
            TableSettings.displayVerticalShadow(leftShadowElement, values);
        }
    }

    adjustHeaderRows() {
        if (this.headerTableHeaderRefs.length === 2) {
            const [{ firstChild: lockedRow }, { firstChild: row }] = this.headerTableHeaderRefs;

            const maxHeight = Math.max(lockedRow.offsetHeight, row.offsetHeight);
            const minHeight = Math.min(lockedRow.offsetHeight, row.offsetHeight);

            if (lockedRow.offsetHeight > row.offsetHeight) {
                const lockedWidth = parseInt(lockedRow.style.height, 10);

                if (lockedWidth === lockedRow.offsetHeight) {
                    lockedRow.style.height = `${minHeight}px`;
                } else {
                    row.style.height = `${maxHeight}px`;
                }
            } else if (lockedRow.offsetHeight < row.offsetHeight) {
                lockedRow.style.height = `${maxHeight}px`;
            }
        }
    }

    setHeaderTableBodyRefs(tableIndex) {
        return ref => {
            this.headerTableHeaderRefs[tableIndex] = ref;
        };
    }

    setHeaderTableColumnGroupRef(ref) {
        const { columnResize } = this.props;

        columnResize.headerColumnGroup = ref;
    }

    setWidth(width) {
        if (this.headerTableRef.current) {
            this.headerTableRef.current.style.width = `${width}px`;
        }
    }

    render() {
        const { lockedColumns, columns, columnResize, ...other } = this.props;

        this.headerTableHeaderRefs = [];

        let currentIndex = 0;
        const headers = _.map([lockedColumns, columns], (child, childIndex) => {
            if (childIndex === 0 && child.cols.length === 0) {
                return null;
            }

            const isLockedHeader = childIndex === 0;

            let style = { minWidth: `${child.width}px` };

            if (isLockedHeader) {
                style = {
                    maxWidth: `${child.width}px`
                };
            }

            const setHeaderContentRef = isLockedHeader ? f => f : ref => ReactUtils.setRef(this.headerContentRef, ref);
            const setHeaderTableRef = isLockedHeader ? f => f : ref => ReactUtils.setRef(this.headerTableRef, ref);
            const setHeaderTableColumnGroupRef = isLockedHeader ? f => f : this.setHeaderTableColumnGroupRef;

            const rootClasses = classNames('onsolve-table__header-container', {
                'onsolve-table__header-container--locked': isLockedHeader
            });

            return (
                <div key={currentIndex} className={rootClasses}>
                    <div className="onsolve-table__header-content" ref={ref => setHeaderContentRef(ref)}>
                        <table style={style} ref={ref => setHeaderTableRef(ref)} role="presentation">
                            <colgroup ref={ref => setHeaderTableColumnGroupRef(ref)}>{child.cols}</colgroup>
                            <thead ref={this.setHeaderTableBodyRefs(currentIndex)}>
                                <HeaderRow
                                    columns={child.items}
                                    resizable={!isLockedHeader && columnResize.resizable}
                                    onColumnResize={this.handleColumnResize}
                                    {...other}
                                />
                            </thead>
                        </table>
                    </div>
                    {currentIndex++ === 1 && (
                        <div
                            className="onsolve-table__shadow onsolve-table__shadow--vertical"
                            ref={ref => ReactUtils.setRef(this.headerLeftShadowRef, ref)}
                        />
                    )}
                </div>
            );
        });

        return <div className="onsolve-table__header">{headers}</div>;
    }
}

Header.propTypes = {
    columnResize: PropTypes.shape({
        resize: PropTypes.func,
        resizable: PropTypes.bool,
        headerColumnGroup: PropTypes.object
    }),
    columns: TablePropTypes.ColumnShape.isRequired,
    lockedColumns: TablePropTypes.ColumnShape.isRequired
};

Header.displayName = 'Header';

export default Header;
