import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OnsolvePropTypes from '../utils/PropTypes';

const defaultStyleMapping = {
    caption: 'paragraph12',
    body1: 'body14',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p8: 'paragraph8',
    p10: 'paragraph10',
    p12: 'paragraph12',
    p14: 'paragraph14',
    p16: 'paragraph16',
    p18: 'paragraph18',
    p24: 'paragraph24',
    p30: 'paragraph30',
    p48: 'paragraph48'
};

const defaultTypographyMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p8: 'p',
    p10: 'p',
    p12: 'p',
    p14: 'p',
    p16: 'p',
    p18: 'p',
    p24: 'p',
    p30: 'p'
};

const Typography = React.forwardRef(function Typography(props, ref) {
    const { component, variant, className, children, color, ...other } = props;
    const classPrefix = defaultStyleMapping[variant] || 'h1';
    const rootClasses = classNames(`onsolve-${classPrefix}`, className, {
        [`text-${color}`]: color !== 'initial'
    });
    const Component = component || defaultTypographyMapping[variant] || 'span';

    return (
        <Component className={rootClasses} ref={ref} {...other}>
            {children}
        </Component>
    );
});

Typography.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to component.
    */
    className: PropTypes.string,
    /**
    Specifies the color of the component.
    */
    color: OnsolvePropTypes.textColors,
    /**
    Specifies the tag name of root element i.e overrides behaviour of `variant` property.
    */
    component: PropTypes.elementType,
    /**
    Specifies the theme typography styles.
    */
    variant: OnsolvePropTypes.textVariants
};

Typography.defaultProps = {
    color: 'initial'
};

Typography.displayName = 'Typography';

export default Typography;
