import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LoadingIcon, EmblemIcon, UpdatedLoadingIcon } from '../Icons';

const LoadingSpinner = ({ className, logoType, ...props }) => {
    const rootClasses = classNames('onsolve-loading__icon', className);
    const emblemClasses = classNames('onsolve-loading__icon onsolve-loading__icon--emblem', className);
    const LoadingIndicator = logoType === 'oldLogo' ? LoadingIcon : UpdatedLoadingIcon;

    return (
        <div className="onsolve-loading__wrapper">
            <LoadingIndicator className={rootClasses} {...props} />
            <EmblemIcon {...props} className={emblemClasses} />
        </div>
    );
};

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.propTypes = {
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
     Specifies the type of loading indicator.
     */
    logoType: PropTypes.oneOf(['oldLogo', 'newLogo'])
};

LoadingSpinner.defaultProps = {
    logoType: 'oldLogo'
};

export default LoadingSpinner;
