import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyleButton } from '../components';

import { getSelectedBlocksMetadata } from '../utils';

const AlignmentControls = forwardRef(
    ({ editorState, iconComponent, onToggle, style, intl, translate, disabled }, ref) => {
        const currentTextAlignment = getSelectedBlocksMetadata(editorState).get('text-align');

        return (
            <StyleButton
                isActive={style === currentTextAlignment}
                onToggle={onToggle}
                style={style}
                intl={intl}
                translate={translate}
                iconComponent={iconComponent}
                disabled={disabled}
                ref={ref}
            />
        );
    }
);

AlignmentControls.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

AlignmentControls.defaultProps = {
    disabled: false
};

AlignmentControls.displayName = 'AlignmentControls';

export default AlignmentControls;
