import React from 'react';
import SvgIcon from './SvgIcon';

const Box = props => (
    <SvgIcon {...props}>
        <path
            d="M13.3,0.4C13.2,0.2,13,0,12.7,0H3.3C3.1,0,2.8,0.2,2.8,0.4L0,8.3c0,0.1,0,0.1,0,0.2v5.3
	c0,0.3,0.3,0.6,0.6,0.6h14.8c0.3,0,0.6-0.3,0.6-0.6V8.5c0-0.1,0-0.1,0-0.2L13.3,0.4z M10.6,8.9c-0.3,0-0.5,0.2-0.6,0.5
	c0,0.1-0.4,1.9-2,1.9S6,9.6,6,9.4C5.9,9.1,5.7,8.8,5.4,8.9h-4l2.3-7.7h8.6l2.3,7.7H10.6z"
        />
    </SvgIcon>
);

Box.displayName = 'BoxIcon';

export default Box;
