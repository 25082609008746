import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListItemContent = ({ children, className }) => (
    <div className={classNames('onsolve-list-item__content', className)}>{children}</div>
);

ListItemContent.displayName = 'ListItemContent';

ListItemContent.propTypes = {
    /**
    Content for the component
    */
    children: PropTypes.node,
    /**
    Specifies class name for the wrapper
    */
    className: PropTypes.string
};

export default ListItemContent;
