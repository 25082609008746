import React from 'react';
import SvgIcon from './SvgIcon';

const TextLink = props => {
    return (
        <SvgIcon {...props}>
            <path
                fill="none"
                d="M8.995 7.005a3.52 3.52 0 0 1 0 4.975l-2 2a3.52 3.52 0 0 1-4.975 0 3.52 3.52 0 0 1 0-4.975l1.748-1.698m3.237 1.688a3.52 3.52 0 0 1 0-4.975l2-2a3.52 3.52 0 0 1 4.975 0 3.52 3.52 0 0 1 0 4.975l-1.748 1.698"
            />
        </SvgIcon>
    );
};

TextLink.displayName = 'TextLinkIcon';

export default TextLink;
