import React from 'react';
import SvgIcon from './SvgIcon';

const PaperPlaneOutlined = props => (
    <SvgIcon {...props}>
        <path
            d="M14 .446l-.008.077-2.625 13.125a.438.438 0 01-.626.306l-.066-.042-3.933-2.95-2.472 2.885a.438.438 0 01-.764-.207l-.006-.078.004-5.247v-.013l.003-.042-.003.055a.437.437 0 01.087-.262l.012-.016a.437.437 0 01.028-.03l.013-.013a.42.42 0 01.037-.031l.017-.012 1.947-1.3-3.357.776a.438.438 0 01-.296-.036l-.065-.04L.175 6.038a.438.438 0 01.026-.72l.074-.037L13.4.031c.298-.119.608.11.6.415zm-1.025.697L1.322 5.804l.966.724 5.489-1.267.01-.002a.435.435 0 01.035-.006l-.045.008a.44.44 0 01.5.253v.003l.013.03.011.042.003.011a.435.435 0 01.004.021L8.3 5.589a.44.44 0 01-.165.45l-.018.012-3.421 2.283 5.947 4.461 2.331-11.652zM4.377 9.189l-.002 3.19 1.665-1.943L4.377 9.19z"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
        />
    </SvgIcon>
);

PaperPlaneOutlined.displayName = 'PaperPlaneOutlinedIcon';

export default PaperPlaneOutlined;
