import React from 'react';

import SvgIcon from './SvgIcon';

const Logo = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle stroke="#3777bc" fill="#ffffff" cx="8" cy="8" r="7" />
            <path
                d="M8.007 1A7 7 0 0115 8a7 7 0 11-7.006-7zM5.62 9.557l-1.364 2.361 7.528.003-1.363-2.362-4.8-.002zM6.655 3.04L2.892 9.557h2.726l2.4-4.157-1.363-2.361zm2.727 0L8.02 5.4l2.4 4.157h2.727L9.382 3.04z"
                fill="#3777bc"
            />
        </g>
    </SvgIcon>
);

Logo.displayName = 'LogoIcon';

export default Logo;
