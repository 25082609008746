import React from 'react';
import { FileIcon, FileImageIcon, FileDocIcon, FilePdfIcon, FileXlxsIcon, FileZipIcon } from '../Icons';

const getFileIcon = (name, size = 'lg') => {
    const re = /(?:\.([^.]+))?$/;
    const [, ext] = re.exec(name);
    const upperCaseExtension = typeof ext === 'string' ? ext.toUpperCase() : '';

    switch (upperCaseExtension) {
        case 'JPEG':
        case 'JPG':
        case 'PNG':
        case 'GIF':
        case 'TIF':
            return <FileImageIcon size={size} />;
        case 'PDF':
            return <FilePdfIcon size={size} />;
        case 'DOC':
        case 'DOCX':
        case 'ODT':
        case 'RTF':
        case 'PPT':
        case 'PPTX':
            return <FileDocIcon size={size} />;
        case 'XLSX':
        case 'XLS':
        case 'XLSM':
            return <FileXlxsIcon size={size} />;
        case 'ZIP':
        case 'RAR':
        case 'ARJ':
        case 'GZ':
        case 'TAR':
        case 'TGZ':
            return <FileZipIcon size={size} />;
        default:
            return <FileIcon size={size} />;
    }
};

getFileIcon.displayName = 'getFileIcon';

export default { getFileIcon };
