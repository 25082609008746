import React from 'react';
import SvgIcon from './SvgIcon';

const RightArrow = props => (
    <SvgIcon {...props}>
        <path d="M5.5 13.5l5-5-5-5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
);

RightArrow.displayName = 'RightArrowIcon';

export default RightArrow;
