const wysiwygPlugins = {
    focus: 'focus',
    headlines: 'headlines',
    horizontalLine: 'horizontalLine',
    image: 'image',
    paragraphs: 'paragraphs',
    removalCommands: 'removalCommands'
};

export default wysiwygPlugins;
