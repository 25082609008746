// Should be up to date with $themeColors in \src\scss\variables\_colors.scss
export const globalThemeColors = {
    primary: 'primary',
    secondary: 'secondary',
    danger: 'danger',
    success: 'success',
    dark: 'dark',
    light: 'light',
    white: 'white',
    waveBlue: 'wave-blue',
    catalinaBlue: 'catalina-blue',
    curiousPurple: 'curious-purple',
    blueAqua: 'blue-aqua',
    darkOrange: 'dark-orange'
};

const { primary, secondary, danger, success, dark, light } = globalThemeColors;

const iconColors = [primary, secondary, danger, success, dark, light];
const themeColors = Object.values(globalThemeColors);

export default {
    iconColors,
    themeColors
};
