import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Backdrop = React.forwardRef(({ className, ...other }, ref) => {
    const rootClasses = classNames('onsolve-backdrop', className);

    return <div className={rootClasses} ref={ref} {...other} />;
});

Backdrop.propTypes = {
    className: PropTypes.string
};

Backdrop.displayName = 'Backdrop';

export default Backdrop;
