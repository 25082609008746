import React from 'react';
import SvgIcon from './SvgIcon';

const Expand = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect id="Rectangle" x="3.9375" y="3.9375" width="6.125" height="6.125" />
                <path
                    d="M0.4375,4.375 L0.4375,1.3125 C0.4375,0.8295 0.8295,0.4375 1.3125,0.4375 L4.375,0.4375"
                    id="Path"
                />
                <path
                    d="M9.625,0.4375 L12.6875,0.4375 C13.1705,0.4375 13.5625,0.8295 13.5625,1.3125 L13.5625,4.375"
                    id="Path"
                />
                <path
                    d="M13.5625,9.625 L13.5625,12.6875 C13.5625,13.1705 13.1705,13.5625 12.6875,13.5625 L9.625,13.5625"
                    id="Path"
                />
                <path
                    d="M4.375,13.5625 L1.3125,13.5625 C0.8295,13.5625 0.4375,13.1705 0.4375,12.6875 L0.4375,9.625"
                    id="Path"
                />
            </g>
        </SvgIcon>
    );
};

Expand.displayName = 'ExpandIcon';

export default Expand;
