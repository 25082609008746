import React from 'react';
import SvgIcon from './SvgIcon';

const SignOut = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M5.587 15.365c-0.143 0-0.285-0.043-0.406-0.131l-4.889-3.492c-0.184-0.131-0.293-0.342-0.293-0.568v-10.476c0-0.386 0.313-0.698 0.698-0.698 0.271 0 0.828 0.436 0.919 0.501l4.376 3.132c0.183 0.132 0.292 0.343 0.292 0.569v10.465c0 0.262-0.146 0.501-0.379 0.622-0.101 0.051-0.211 0.077-0.32 0.077zM16.762 6.286l-4.19 3.492v-2.794h-2.794v4.19c0 0.386-0.313 0.698-0.698 0.698h-1.397v-7.671c0-0.673-0.327-1.31-0.876-1.704l-3.489-2.498h5.761c0.386 0 0.698 0.312 0.698 0.698v4.889h2.794v-2.794l4.19 3.492z"
        />
    </SvgIcon>
);

SignOut.displayName = 'SignOutIcon';

export default SignOut;
