import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown } from '../../../Dropdowns';

const Meridiem = ({ value, name, placeholder, pattern, onChange, className, ...other }) => {
    const handleChange = (e, value) => {
        if (onChange) {
            onChange(value);
        }
    };

    const classes = classNames(
        'onsolve-time-input__mask-input',
        'onsolve-time-input__mask-input__midday',
        'ml-2',
        className
    );

    return (
        <Dropdown
            {...other}
            data={['AM', 'PM']}
            className={classes}
            placeholderClassName="ml-2"
            name={name}
            value={value}
            placeholder={placeholder}
            pattern={pattern}
            onChange={handleChange}
        />
    );
};

Meridiem.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired
};

Meridiem.displayName = 'Meridiem';

export default Meridiem;
