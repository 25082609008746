import { EditorState, RichUtils } from 'draft-js';

export const addScript = (editorState, scriptType) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity(scriptType.style, 'MUTABLE');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState;

    if (!selection.isCollapsed()) {
        // Selected text
        newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    }

    return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
};
