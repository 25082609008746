import React from 'react';
import SvgIcon from './SvgIcon';

const Calling = props => (
    <SvgIcon {...props}>
        <g id="voice-phone" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M3.21367521,1.42481654 L6.79740568,5.00854701 L5.00253389,6.8034188 L9.1965812,10.9974661 L10.991453,9.20259432 L14.5751835,12.7863248 L12.3615082,15 L12.1880342,15 C6.00912861,15 1,9.99087139 1,3.81196581 L1,3.63849176 L3.21367521,1.42481654 Z M3.21367521,2.60937149 L1.83900834,3.98403837 C1.92996001,9.56373673 6.43626327,14.07004 12.0159616,14.1609917 L13.3906285,12.7863248 L10.991453,10.3871493 L9.1965812,12.1820211 L3.81797894,6.8034188 L5.61285073,5.00854701 L3.21367521,2.60937149 Z M9.1965812,1 C12.4019825,1 15,3.5980175 15,6.8034188 L15,7.22222222 L14.1623932,7.22222222 L14.1623932,6.8034188 C14.1623932,4.06061498 11.939385,1.83760684 9.1965812,1.83760684 L8.77777778,1.83760684 L8.77777778,1 L9.1965812,1 Z M9.1965812,3.39316239 C11.0797603,3.39316239 12.6068376,4.92023972 12.6068376,6.8034188 L12.6068376,7.22222222 L11.7692308,7.22222222 L11.7692308,6.8034188 C11.7692308,5.3828372 10.6171628,4.23076923 9.1965812,4.23076923 L8.77777778,4.23076923 L8.77777778,3.39316239 L9.1965812,3.39316239 Z"
                id="Mask"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

Calling.displayName = 'CallingIcon';

export default Calling;
