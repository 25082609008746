import React from 'react';
import SvgIcon from './SvgIcon';

const FormatAlignRight = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M4 14h10v-2H4v2zm-4-4h14V8H0v2zM0 0v2h14V0H0zm4 6h10V4H4v2z" fillRule="nonzero" />
        </SvgIcon>
    );
};

FormatAlignRight.displayName = 'FormatAlignRightIcon';

export default FormatAlignRight;
