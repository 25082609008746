import React from 'react';

import SvgIcon from './SvgIcon';

const InboxOut = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M5.375,6.25 L5.375,8 L3.522625,8 L2.866375,13.25 L13.133625,13.25 L12.477375,8 L10.625,8 L10.625,6.25 L13.25,6.25 C13.691,6.25 14.06375,6.578125 14.118,7.0165 L14.993,14.0165 C15.0245,14.265875 14.946625,14.516125 14.780375,14.70425 C14.615,14.892375 14.376125,15 14.125,15 L1.875,15 C1.623875,15 1.385,14.892375 1.21875,14.70425 C1.0525,14.516125 0.9755,14.265875 1.006125,14.0165 L1.881125,7.0165 C1.93625,6.578125 2.309,6.25 2.75,6.25 L5.375,6.25 Z M8,1 L11.5,4.5 L8.875,4.5 L8.875,10.625 L7.125,10.625 L7.125,4.5 L4.5,4.5 L8,1 Z"
        />
    </SvgIcon>
);

InboxOut.displayName = 'InboxOutIcon';

export default InboxOut;
