import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Scrollbars } from '../Scrollbars';

const PopoverBody = props => {
    const { children, className, classes, size, scrollbarProps } = props;
    const rootClasses = classNames('onsolve-popover__body', className, {
        [`onsolve-popover__body--${size}`]: size
    });

    const containerClasses = classNames('onsolve-popover__container', classes.container, {
        [`onsolve-popover__container--${size}`]: size
    });

    return (
        <div className={rootClasses}>
            <Scrollbars autoHide autoHeight {...scrollbarProps}>
                <div className={containerClasses}>{children}</div>
            </Scrollbars>
        </div>
    );
};

PopoverBody.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Override or extend the styles applied to the sub components.
     */
    classes: PropTypes.object,
    /**
     Specifies autoHeightMax in Scrollbars component.
     */
    scrollbarProps: PropTypes.shape({
        autoHeightMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    /**
     Specifies one of the size of component.
     */
    size: PropTypes.oneOf(['md', 'lg'])
};

PopoverBody.defaultProps = {
    classes: {},
    scrollbarProps: {
        autoHeightMax: `100%`
    }
};

PopoverBody.displayName = 'PopoverBody';

export default PopoverBody;
