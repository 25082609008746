import { useCallback, useEffect, useState } from 'react';

import { EDITOR_TYPE_VALUES, EDITOR_TYPES } from '../../../constants';
import { convertStateToStateHTMLText, convertStateHTMLTextToState } from '../../../utils';

const isToolbarDisabled = value => value !== EDITOR_TYPE_VALUES.EDITOR;

export const useEditorType = ({ initialType = EDITOR_TYPES[0] } = {}) => {
    const [editorType, setEditorType] = useState(initialType);
    const [disabled, setDisabled] = useState(isToolbarDisabled(editorType.value));

    useEffect(() => {
        setDisabled(isToolbarDisabled(editorType.value));
    }, [editorType]);

    const handleEditorTypeChange = useCallback(
        (editorState, onChange) => type => {
            if (type.value === EDITOR_TYPE_VALUES.HTML && editorType.value !== EDITOR_TYPE_VALUES.HTML) {
                onChange(convertStateToStateHTMLText(editorState));
            } else if (type.value !== EDITOR_TYPE_VALUES.HTML && editorType.value === EDITOR_TYPE_VALUES.HTML) {
                onChange(convertStateHTMLTextToState(editorState));
            }

            setEditorType(type);
        },
        [editorType.value]
    );

    return {
        editorType,
        isToolbarDisabled: disabled,
        setEditorType,
        onEditorTypeChange: handleEditorTypeChange
    };
};
