import React from 'react';

import SvgIcon from './SvgIcon';

const Calendar = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M14.5,1.8H12V0.3h-1v1.5H5V0.3H4v1.5H1.5C0.7,1.8,0,2.4,0,3.3v11c0,0.8,0.7,1.5,1.5,1.5h13c0.8,0,1.5-0.7,1.5-1.5v-11 C16,2.4,15.3,1.8,14.5,1.8z M1.5,2.8h13C14.8,2.8,15,3,15,3.3v1.5H1V3.3C1,3,1.2,2.8,1.5,2.8z M14.5,14.8h-13 c-0.3,0-0.5-0.2-0.5-0.5V5.8h14v8.5C15,14.5,14.8,14.8,14.5,14.8z"
            />
        </SvgIcon>
    );
};

Calendar.displayName = 'CalendarIcon';

export default Calendar;
