import React from 'react';
import SvgIcon from './SvgIcon';

const Times = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
            d="M14.7 1.3c-.4-.4-1-.4-1.4 0L8 6.6 2.7 1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L6.6 8l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.4l5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8l5.3-5.3c.4-.4.4-1 0-1.4z"
        />
    </SvgIcon>
);

Times.displayName = 'TimesIcon';

export default Times;
