import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class DropdownInput extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { disabled, onClick } = this.props;

        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(e);
        }
    }

    render() {
        const { className, value, placeholder, disabled, name, ...other } = this.props;
        const rootClasses = classNames('onsolve-dropdown__text', className, {
            'onsolve-dropdown__placeholder': !value && !disabled,
            'onsolve-dropdown__text--disabled': disabled
        });

        return (
            <span id={name} aria-hidden="true" className={rootClasses} {...other} onClick={this.handleClick}>
                {value || placeholder}
            </span>
        );
    }
}

DropdownInput.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.node
};

DropdownInput.displayName = 'DropdownInput';

export default DropdownInput;
