import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { keyCodes } from '../../common/constants';
import { DomUtils, getFlag } from '../utils';
import { Focusable } from '../Focusable';
import { AddIcon, TimesIcon } from '../Icons';
import { Menu, MenuItem } from '../Menu';
import { DropdownButton } from '../Button/DropdownButton';
import { InputFieldExclamation } from '../InputBase';
import { componentsTranslations } from '../translations';

import { LanguageDeleteModal } from './LanguageDeleteModal';

const LanguageTabs = ({
    children,
    activeLanguages,
    errors,
    menuLanguages,
    hideDropdown,
    languages,
    className,
    hidden,
    value,
    defaultLanguage,
    onTabClick,
    onLanguageAdd,
    onLanguageDelete,
    showCountryFlags
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCompact, setCompact] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const headerRef = useRef();
    const sizerRef = useRef();
    const baseClass = 'onsolve-language-tabs';
    const rootClasses = classNames(baseClass, className);

    const getLanguagePrefix = useCallback(
        value => {
            const { translatedName, cultureRegionCode } = languages[value] || {};

            return translatedName && cultureRegionCode ? `${translatedName} (${cultureRegionCode})` : '';
        },
        [languages]
    );

    const handleCloseDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);

    const handleOpenDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(true);
    }, []);

    const handleDeleteLanguage = useCallback(() => {
        onLanguageDelete && onLanguageDelete();

        setIsDeleteModalOpen(false);
    }, [onLanguageDelete]);

    const handleToggleMenu = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    const handleKeyDownMenuItem = useCallback(
        languageCode => e => {
            e.preventDefault();
            const keyCode = DomUtils.getCharCode(e);

            if (keyCode === keyCodes.ENTER_KEY || keyCode === keyCodes.SPACE) {
                onLanguageAdd(languageCode);
                setIsDropdownOpen(false);
            }
        },
        [onLanguageAdd]
    );

    const handleLanguageAdd = useCallback(
        languageCode => () => {
            onLanguageAdd(languageCode);
            setIsDropdownOpen(false);
        },
        [onLanguageAdd]
    );

    const handleKeyDownLangTab = useCallback(e => {
        const keyCode = DomUtils.getCharCode(e);

        if (keyCode === keyCodes.ENTER_KEY || keyCode === keyCodes.SPACE) {
            e.preventDefault();
            e.target.click();
        }

        let target;

        if (keyCode === keyCodes.LEFT || keyCode === keyCodes.RIGHT) {
            const { previousElementSibling, nextElementSibling } = e.target;

            target = keyCode === keyCodes.LEFT ? previousElementSibling : nextElementSibling;

            if (!target) {
                const { firstChild, lastChild } = e.target.parentNode;

                target = keyCode === keyCodes.LEFT ? lastChild : firstChild;
            }
        }

        if (keyCode === keyCodes.HOME || keyCode === keyCodes.END) {
            const { firstChild, lastChild } = e.target.parentNode;

            e.preventDefault();
            target = keyCode === keyCodes.HOME ? firstChild : lastChild;
        }

        if (target) {
            DomUtils.setFocus(target);
            e.target.setAttribute('tabindex', '-1');
            target.setAttribute('tabindex', '0');
        }
    }, []);

    useEffect(() => {
        if (headerRef.current) {
            if (headerRef.current.scrollWidth < sizerRef.current.scrollWidth) {
                setCompact(true);
            }
        }
    }, [headerRef, activeLanguages]);
    const languagesLength = useMemo(() => Object.keys(languages).length, [languages]);

    return (
        <div className={rootClasses}>
            {!hidden && languagesLength > 1 ? (
                <>
                    <div className="onsolve-language-tabs-sizer" ref={sizerRef}>
                        {activeLanguages.map(
                            languageCode =>
                                languages[languageCode] && (
                                    <div key={languageCode} className="onsolve-language-tabs-sizer__placeholder" />
                                )
                        )}
                        {!hideDropdown && menuLanguages.length && (
                            <div className="onsolve-language-tabs-sizer__placeholder" />
                        )}
                    </div>
                    <div className="onsolve-language-tabs-header" ref={headerRef}>
                        {activeLanguages.map(
                            languageCode =>
                                languages[languageCode] && (
                                    <Focusable
                                        render={({ classes }) => (
                                            <div
                                                key={languageCode}
                                                className={cx(
                                                    'onsolve-language-tabs-header-button',
                                                    {
                                                        'onsolve-language-tabs-header-button--active':
                                                            languageCode === value,
                                                        'onsolve-language-tabs-header-button--compact': isCompact
                                                    },
                                                    classes
                                                )}
                                                onClick={() => onTabClick(languageCode)}
                                                onKeyDown={handleKeyDownLangTab}
                                                tabIndex={languageCode === value ? '0' : '-1'}
                                                aria-selected={languageCode === value}
                                            >
                                                {showCountryFlags &&
                                                    getFlag(
                                                        languages[languageCode],
                                                        'onsolve-language-tabs-header-button__flag'
                                                    )}
                                                {!isCompact && (
                                                    <div className="onsolve-language-tabs-header-button__text">
                                                        <span>{languages[languageCode].translatedName}</span>
                                                        <span> ({languages[languageCode].cultureRegionCode})</span>
                                                    </div>
                                                )}

                                                {errors && errors.includes(languageCode) && (
                                                    <InputFieldExclamation className="d-flex p-0 ml-2" error />
                                                )}

                                                {defaultLanguage.locale !== languageCode && onLanguageDelete && (
                                                    <TimesIcon
                                                        className="onsolve-language-tabs-header-button__delete-icon"
                                                        size="md"
                                                        color="secondary"
                                                        onClick={handleOpenDeleteModal}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                )
                        )}
                        {!hideDropdown && menuLanguages.length ? (
                            <DropdownButton
                                size="sm"
                                color="secondary"
                                className="onsolve-language-tabs-header__dropdown"
                                isOpen={isDropdownOpen}
                                showToggle={false}
                                title={
                                    <div className="onsolve-language-tabs-header__dropdown-title">
                                        <AddIcon size="sm" />
                                        <span className="onsolve-language-tabs-header__dropdown-title__message text-capitalize">
                                            <FormattedMessage {...componentsTranslations.ng_components_addLanguage} />
                                        </span>
                                    </div>
                                }
                                onToggle={handleToggleMenu}
                                onKeyDown={handleKeyDownLangTab}
                                tabIndex={activeLanguages.length ? '-1' : '0'}
                            >
                                <Menu>
                                    {menuLanguages.map(languageCode => (
                                        <MenuItem
                                            key={languageCode}
                                            onClick={handleLanguageAdd(languageCode)}
                                            onKeyDown={handleKeyDownMenuItem(languageCode)}
                                            className="onsolve-alert-details-menu-item"
                                        >
                                            {getLanguagePrefix(languageCode)}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </DropdownButton>
                        ) : null}
                    </div>
                </>
            ) : null}
            <div className="onsolve-language-tabs__body">{children}</div>

            <LanguageDeleteModal
                isOpen={isDeleteModalOpen}
                languagePrefix={getLanguagePrefix(value)}
                onClose={handleCloseDeleteModal}
                onCancel={handleCloseDeleteModal}
                onDelete={handleDeleteLanguage}
            />
        </div>
    );
};

LanguageTabs.propTypes = {
    activeLanguages: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    defaultLanguage: PropTypes.object,
    errors: PropTypes.array,
    hidden: PropTypes.bool,
    hideDropdown: PropTypes.bool,
    languages: PropTypes.object,
    menuLanguages: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    onLanguageAdd: PropTypes.func,
    onLanguageDelete: PropTypes.func,
    onTabClick: PropTypes.func,
    showCountryFlags: PropTypes.bool,
    value: PropTypes.string
};

LanguageTabs.defaultProps = {
    defaultLanguage: {},
    showCountryFlags: true,
    loading: false,
    onLanguageAdd: () => {}
};

LanguageTabs.displayName = 'LanguageTabs';

export default LanguageTabs;
