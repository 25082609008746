import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useResizeObserver } from './hooks';
import createEllipsisString from './utils';
import { ReactUtils } from '../utils';

// Why do we not use -webkit-line-clamp?
// Limited Support: is not supported by Internet Explorer (IE)
// No RTL Support: It does not support any text with a direction: rtl
// No Spaces? No Support: If the language doesn’t use spaces, -webkit-line-clamp does not know what to do.
const Ellipsis = React.forwardRef(
    ({ children, component: Component, lines, ellipsis, onTruncated, wordBreak, ...other }, ref) => {
        const elementRef = useRef(null);
        const requestRef = useRef(null);

        const [text, setText] = useState(children);

        const styles = useMemo(() => {
            let styles = {
                display: 'block'
            };

            if (lines === 1) {
                styles = { ...styles, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
            } else {
                styles = { ...styles, wordBreak };
            }

            return styles;
        }, [lines, wordBreak]);

        const handleResize = useCallback(() => {
            const textEllipsis = createEllipsisString(elementRef.current, children, ellipsis, lines, onTruncated);

            setText(textEllipsis);
        }, [children, ellipsis, lines, onTruncated]);

        useLayoutEffect(() => {
            requestRef.current = requestAnimationFrame(handleResize);

            return () => cancelAnimationFrame(requestRef.current);
        }, [children, lines, handleResize]);

        useResizeObserver(elementRef, { onResize: handleResize });

        return (
            <Component
                ref={ReactUtils.setMultipleRefs(ref, elementRef)}
                aria-label={children}
                style={styles}
                {...other}
            >
                {text}
            </Component>
        );
    }
);

Ellipsis.propTypes = {
    /**
    The content of the component.
    */
    children: PropTypes.string.isRequired,
    /**
    Specifies the name of parent element.
    */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.elementType]),
    /**
    Specifies the custom symbol of ellipsis, by default is ...
    */
    ellipsis: PropTypes.string,
    /**
    Specifies the maximum lines of content.
    */
    lines: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
    Called when enter or leave ellipsis state.
    */
    onTruncated: PropTypes.func,
    /**
    Specifies how words should break when reaching the end of a line.
    */
    wordBreak: PropTypes.oneOf(['normal', 'break-all', 'keep-all'])
};

Ellipsis.defaultProps = {
    component: 'div',
    ellipsis: '...',
    lines: 1,
    wordBreak: 'break-all'
};

Ellipsis.displayName = 'Ellipsis';

export default Ellipsis;
