import uuid from 'uuid';

import ToastrStore from './ToastrStore';
import { ToastrVariants, ToastrActions } from './ToastrConstants';

const insertFunc = data => {
    const id = uuid();

    ToastrStore.dispatch({
        type: ToastrActions.Insert,
        data: {
            id,
            isNewToastr: true,
            isVisible: true,
            onClose: () => ToastrProvider.close(id),
            ...data
        }
    });

    return id;
};

const ToastrProvider = {
    primary: (content, options) => insertFunc({ content, variant: ToastrVariants.primary, ...options }),
    danger: (content, options) => insertFunc({ content, variant: ToastrVariants.danger, ...options }),
    success: (content, options) => insertFunc({ content, variant: ToastrVariants.success, ...options }),
    warning: (content, options) => insertFunc({ content, variant: ToastrVariants.warning, ...options }),
    close: id => {
        ToastrStore.dispatch({ type: ToastrActions.Remove, data: { id } });
    },
    closeAll: () => {
        ToastrStore.dispatch({ type: ToastrActions.RemoveAll });
    }
};

ToastrProvider.displayName = 'ToastrProvider';

export default ToastrProvider;
