import { EditorState, SelectionState } from 'draft-js';

const moveSelectionTo = (editorState, block) => {
    if (!editorState || !block) {
        return;
    }
    const key = block.getKey();
    const length = block.getLength();

    const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length
    });

    return EditorState.forceSelection(editorState, selection);
};

export default moveSelectionTo;
