import React from 'react';
import SvgIcon from './SvgIcon';

const TextDivider = props => {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M.5 7.5h15" strokeLinecap="round" />
            <path fill="none" d="M1.5.5h13v4h-13zm0 10h13v4h-13z" />
        </SvgIcon>
    );
};

TextDivider.displayName = 'TextDividerIcon';

export default TextDivider;
