import format from 'date-fns/format';
import { isAfter, isBefore, isValid as isDateValid, isWithinInterval } from 'date-fns';
import moment from 'moment';
/* eslint-disable-next-line */
import tz from 'moment-timezone';

const getTimeInitialValue = value => {
    if (value) {
        const date = new Date(`01/01/1980 ${value}`);

        if (!isDateValid(date)) {
            return {
                hours: '',
                minutes: '',
                midday: ''
            };
        }

        return {
            hours: format(date, 'hh'),
            minutes: format(date, 'mm'),
            midday: format(date, 'a')
        };
    }
    return {
        hours: '',
        minutes: '',
        midday: 'AM'
    };
};

const convertFrom12To24 = time => format(new Date(`01/01/1980 ${time}`), 'HH:mm');

const convertFrom24to12 = time => format(new Date(`01/01/1980 ${time}`), 'hh:mm a');

const getUTCDate = (dateString = Date.now()) => {
    const date = new Date(dateString);

    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
};

const convertDateToISOString = date => {
    let formattedDate = new Date(date);

    formattedDate = Date.UTC(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate());
    return new Date(formattedDate).toISOString();
};

const convertDateTimeToISOString = date => {
    let formattedDate = new Date(date);

    formattedDate = Date.UTC(
        formattedDate.getFullYear(),
        formattedDate.getMonth(),
        formattedDate.getDate(),
        formattedDate.getHours(),
        formattedDate.getMinutes()
    );
    return new Date(formattedDate).toISOString();
};

const getOffsetString = name => {
    const [, splitDate] = name.split('(');
    const [offset] = splitDate.split(')');

    return offset;
};

const getZonedTime = (isoTime, timeZoneId, dateFormat = 'll', timeFormat = 'LT') => {
    const zonedTime = moment(isoTime)
        .tz('UTC')
        .tz(timeZoneId);

    return { dateValue: zonedTime.format(dateFormat), timeValue: zonedTime.format(timeFormat) };
};

const getMonthName = (locale = 'en-US') =>
    Array.from({ length: 12 }, (v, k) => {
        return new Date(1910, k, 1).toLocaleDateString(locale, {
            month: 'long'
        });
    });

const isDateInRange = (date, minDate, maxDate) => {
    if (minDate !== undefined && maxDate !== undefined) {
        return isWithinInterval(date, { start: minDate, end: maxDate });
    } else if (maxDate !== undefined) {
        return isBefore(date, maxDate);
    } else if (minDate !== undefined) {
        return isAfter(date, minDate);
    }

    return true;
};

export default {
    convertDateToISOString,
    convertDateTimeToISOString,
    convertFrom12To24,
    convertFrom24to12,
    getTimeInitialValue,
    getUTCDate,
    getOffsetString,
    getMonthName,
    isDateInRange,
    getZonedTime
};
