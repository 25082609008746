import { componentsTranslations } from '../../translations';

export const EDITOR_TYPE_VALUES = {
    EDITOR: 'editor',
    HTML: 'html',
    PREVIEW: 'preview'
};

export const EDITOR_TYPES = [
    {
        value: EDITOR_TYPE_VALUES.EDITOR,
        translation: componentsTranslations.ng_wysiwyg_editor_type_visual_editor
    },
    {
        value: EDITOR_TYPE_VALUES.HTML,
        translation: componentsTranslations.ng_wysiwyg_editor_type_html_editor
    },
    {
        value: EDITOR_TYPE_VALUES.PREVIEW,
        translation: componentsTranslations.ng_wysiwyg_editor_type_preview
    }
];
