import { ENTITY_TYPES } from '../../constants';

const findScriptEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        const scriptTypes = [ENTITY_TYPES.SUBSCRIPT, ENTITY_TYPES.SUPERSCRIPT];

        return entityKey !== null && scriptTypes.includes(contentState.getEntity(entityKey).getType());
    }, callback);
};

export default findScriptEntities;
