/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '../Typography';

const PageHeader = ({ title, description, action, className, leftAction, classes }) => {
    const rootClasses = classNames('onsolve-page-header', className);
    const renderLeftAction = leftAction =>
        leftAction && <div className="onsolve-page-header__left-action">{leftAction}</div>;

    const renderAction = action => action && <div className="onsolve-page-header__action">{action}</div>;

    const renderTitle = title => {
        const titleClasses = classNames('onsolve-page-header__title', classes.title);

        if (typeof title === 'function') {
            return title({ className: titleClasses });
        }

        return (
            title && (
                <Typography variant="h1" className={titleClasses}>
                    {title}
                </Typography>
            )
        );
    };

    return (
        <div className={rootClasses}>
            {renderLeftAction(leftAction)}
            <div className="onsolve-page-header__wrapper">
                {renderTitle(title)}
                {description && (
                    <Typography variant="p12" className="onsolve-page-header__description" color="secondary">
                        {description}
                    </Typography>
                )}
            </div>
            {action && renderAction(action)}
        </div>
    );
};

PageHeader.propTypes = {
    /**
    The actions displayed at the right top corner on the component.
    */
    action: PropTypes.node,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Override or extend the styles applied to the sub components.
    */
    classes: PropTypes.object,
    /**
    The description of component.
    */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
    The actions displayed at the left top corner on the component.
    */
    leftAction: PropTypes.node,
    /**
    The title of component.
    */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired
};

PageHeader.defaultProps = {
    classes: {}
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
