import React from 'react';

import SvgIcon from './SvgIcon';

const MapGroup = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M8,16c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C16,12.4,12.4,16,8,16z M8,0.6C3.9,0.6,0.6,3.9,0.6,8 c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C15.4,3.9,12.1,0.6,8,0.6z" />
            <path d="m 11.387865,5.5659432 a 0.246144,0.246144 0 0 1 0.215376,0.02154 0.2092224,0.2092224 0 0 1 0.100918,0.175993 v 4.2601368 a 0.21414528,0.21414528 0 0 1 -0.145225,0.198146 l -2.3076001,0.851658 a 0.2492208,0.2492208 0 0 1 -0.17107,0 L 6.8581999,10.253142 4.6367503,11.073417 A 0.24552864,0.24552864 0 0 1 4.4213743,11.051887 0.2092224,0.2092224 0 0 1 4.3198399,10.875894 V 6.6151412 c 0,-0.08738 0.057228,-0.165532 0.1452249,-0.198146 l 1.2504116,-0.461523 a 1.4491728,1.4491728 0 0 0 0.015999,0.452905 L 4.7813596,6.7591322 V 10.560826 L 6.7726645,9.8254712 a 0.2492208,0.2492208 0 0 1 0.1710701,0 L 9.1657996,10.645746 11.24264,9.8790082 v -3.800464 l -0.955039,0.352601 c 0.01846,-0.09476 0.03199,-0.188915 0.03199,-0.279988 0,-0.064 -0.005,-0.125534 -0.01046,-0.18707 z M 8.0119999,4.3198389 c 0.907656,0 1.84608,0.629513 1.84608,1.6836253 0,0.967961 -1.5113241,2.401135 -1.6830097,2.561128 A 0.23814432,0.23814432 0 0 1 8.0119999,8.6273592 0.23814432,0.23814432 0 0 1 7.8489295,8.5645922 C 7.6766286,8.4045992 6.1659199,6.9714252 6.1659199,6.0034642 c 0,-1.0541123 0.9384239,-1.6836253 1.84608,-1.6836253 z m 0,1.0768803 c -0.3821385,0 -0.69228,0.289219 -0.69228,0.646128 0,0.356909 0.3101415,0.646128 0.69228,0.646128 0.3821385,0 0.69228,-0.289219 0.69228,-0.646128 0,-0.356909 -0.3101415,-0.646128 -0.69228,-0.646128 z" />
        </g>
    </SvgIcon>
);

MapGroup.displayName = 'MapGroupIcon';

export default MapGroup;
