import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function AccordionGroup(props) {
    const { allOpen, children, className, defaultId, firstOpened, items } = props;
    const rootClasses = classNames('onsolve-accordion-group', className);
    const [firstItem = {}] = items;
    const activeId = defaultId || (firstOpened && firstItem.id);
    const [state, setState] = useState({
        ...(activeId && {
            [activeId]: true
        })
    });

    const onToggle = id => {
        setState(state => ({
            ...(allOpen && state),
            [id]: !state[id]
        }));
    };

    return <div className={rootClasses}>{items.map(item => children(state[item.id], item, onToggle))}</div>;
}
AccordionGroup.defaultProps = {
    items: []
};

AccordionGroup.propTypes = {
    allOpen: PropTypes.bool, // by default only 1 accordion can be opened at once, use <allOpen> flag to allow expand multiple items
    children: PropTypes.func.isRequired,
    className: PropTypes.string,
    defaultId: PropTypes.string, // use <defaultId> to open exact accordion by id on the first render instead of <firstOpened> flag
    firstOpened: PropTypes.bool, // by default all accordions are closed, use <firstOpened> flag to open first item on the first render
    items: PropTypes.instanceOf(Array).isRequired
};

AccordionGroup.displayName = 'AccordionGroup';

export default AccordionGroup;
