import React from 'react';
import SvgIcon from './SvgIcon';

const General = props => (
    <SvgIcon {...props}>
        <g id="components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M4.57775318,0 L4.57775318,16 L-2.09166018e-13,16 L-2.09166018e-13,0 L4.57775318,0 Z M10.2888766,11.4285714 L10.2888766,16 L5.71112341,16 L5.71112341,11.4285714 L10.2888766,11.4285714 Z M16,5.71428571 L16,16 L11.4222468,16 L11.4222468,5.71428571 L16,5.71428571 Z M3.80739347,0.761904762 L0.770337576,0.761904762 L0.770337576,15.2380952 L3.80739347,15.2380952 L3.80739347,0.761904762 Z M15.2295739,6.47619048 L12.192518,6.47619048 L12.192518,15.2380952 L15.2295739,15.2380952 L15.2295739,6.47619048 Z M9.51829109,12.1904762 L6.4812352,12.1904762 L6.4812352,15.2380952 L9.51829109,15.2380952 L9.51829109,12.1904762 Z M10.2888766,5.71428571 L10.2888766,10.2857143 L5.71112341,10.2857143 L5.71112341,5.71428571 L10.2888766,5.71428571 Z M9.51829109,6.47619048 L6.4812352,6.47619048 L6.4812352,9.52380952 L9.51829109,9.52380952 L9.51829109,6.47619048 Z M16,0 L16,4.57142857 L5.71112341,4.57142857 L5.71112341,0 L16,0 Z M15.2295739,0.761904762 L6.4812352,0.761904762 L6.4812352,3.80952381 L15.2295739,3.80952381 L15.2295739,0.761904762 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
            ></path>
        </g>
    </SvgIcon>
);

General.displayName = 'GeneralIcon';

export default General;
