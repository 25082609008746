import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '../Typography';

const NoResults = ({ className, title, description, image }) => {
    const rootClassNames = classNames('onsolve-no-results', className);

    return (
        <div className={rootClassNames}>
            <div className="onsolve-no-results__container">
                <div className="onsolve-no-results__banner">{image}</div>
                <div className="onsolve-no-results__content">
                    <Typography variant="p30" className="onsolve-no-results__title">
                        {title}
                    </Typography>
                    <Typography variant="p16" className="onsolve-no-results__description" color="secondary">
                        {description}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

NoResults.propTypes = {
    className: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    image: PropTypes.element.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

NoResults.displayName = 'NoResults';

export default NoResults;
