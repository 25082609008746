import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputFieldExclamation from './InputFieldExclamation';

class InputFieldLabel extends Component {
    render() {
        const { children, name, error } = this.props;

        return (
            <div className="d-inline-flex align-items-start">
                <label htmlFor={name} className="onsolve-input-field__label">
                    {children}
                </label>
                <InputFieldExclamation error={error} />
            </div>
        );
    }
}

InputFieldLabel.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies htmlFor attribute.
     */
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

InputFieldLabel.displayName = 'InputFieldLabel';

export default InputFieldLabel;
