import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class InputFieldGroup extends Component {
    render() {
        const { children, className } = this.props;
        const rootClasses = classNames('onsolve-input-field__group', className);

        return <div className={rootClasses}>{children}</div>;
    }
}

InputFieldGroup.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string
};

InputFieldGroup.displayName = 'InputFieldGroup';

export default InputFieldGroup;
