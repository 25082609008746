import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { fp as _ } from '../../../utils';
import { componentsTranslations } from '../../../translations';
import PageNavigationButton from './PageNavigationButton';
import PageNumberButton from './PageNumberButton';
import getPages from './Pagination';

class PageNumberButtons extends PureComponent {
    handlePageChange = value => e => {
        const { onPageChange } = this.props;

        onPageChange(e, value);
    };

    render() {
        const {
            currentPage,
            totalPages,
            buttonCount,
            pageButtons: pageButtonsProps,
            pageNoRef,
            intl,
            onKeyDown
        } = this.props;

        const pages = getPages({ buttonCount, currentPage, totalPages });
        const pageButtons = ({ pages }) =>
            _.map(pages, (page, key) => {
                return <PageNumberButton key={key} page={page} onPageChange={this.handlePageChange(page.number)} />;
            });

        return (
            <div className="onsolve-table__paginator-pages" ref={pageNoRef}>
                <PageNavigationButton
                    type="prev"
                    hidden={currentPage == 1}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_previous_page)}
                    onPageChange={this.handlePageChange(currentPage - 1)}
                    onKeyDown={onKeyDown}
                />
                {pageButtonsProps && <ul>{pageButtons({ pages })}</ul>}
                <PageNavigationButton
                    onKeyDown={onKeyDown}
                    type="next"
                    hidden={currentPage === totalPages}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_next_page)}
                    onPageChange={this.handlePageChange(currentPage + 1)}
                />
            </div>
        );
    }
}

PageNumberButtons.displayName = 'PageNumberButtons';

PageNumberButtons.propTypes = {
    buttonCount: PropTypes.number,
    currentPage: PropTypes.number,
    intl: PropTypes.object,
    onKeyDown: PropTypes.func,
    onPageChange: PropTypes.func,
    pageButtons: PropTypes.bool,
    pageNoRef: PropTypes.object,
    totalPages: PropTypes.number
};

PageNumberButtons.defaultProps = {
    currentPage: 1,
    buttonCount: 5,
    pageButtons: true,
    onPageChange: () => {}
};

export default injectIntl(PageNumberButtons);
