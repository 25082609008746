import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class RadioGroup extends Component {
    render() {
        const { children, className, name, value, onChange, ...other } = this.props;

        const radioButtons = React.Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) {
                return null;
            }

            const checked = value === child.props.value;
            let tabIndex = checked ? 0 : -1;

            if (!value && index === 0) {
                tabIndex = 0;
            }

            return React.cloneElement(child, { name, checked, tabIndex, onChange });
        });

        const rootClasses = classNames('onsolve-radio-group', className);

        return (
            <div role="radiogroup" className={rootClasses} {...other}>
                {radioButtons}
            </div>
        );
    }
}

RadioGroup.propTypes = {
    /**
    Specifies child radio button components
    */
    children: PropTypes.arrayOf(PropTypes.element),
    /**
    Specifies custom className property
    */
    className: PropTypes.string,
    /**
    Specifies htmlFor attribute
    */
    name: PropTypes.string,
    /**
    Callback function on change of any child radio button components
    */
    onChange: PropTypes.func,
    /**
    Specifies current value
    */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
