import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from '../../../components/Selections';
import { fp as _ } from '../../../components/utils';

class TableSelectionCell extends Component {
    constructor(props) {
        super(props);
    }

    handleChange = (e, { checked }) => {
        const { onSelectionChange } = this.props;

        onSelectionChange(e, checked);
    };

    render() {
        const { selectionValue, disabled, 'aria-label': ariaLabel, render, dataItem } = this.props;

        return (
            <td className="onsolve-table__body-cell onsolve-table__body-cell--selection" role="gridcell">
                {_.isNil(render) ? (
                    <CheckBox
                        checked={selectionValue}
                        disabled={disabled}
                        onChange={this.handleChange}
                        aria-label={ariaLabel}
                    />
                ) : (
                    render(dataItem, () => (
                        <CheckBox
                            checked={selectionValue}
                            disabled={disabled}
                            onChange={this.handleChange}
                            aria-label={ariaLabel}
                        />
                    ))
                )}
            </td>
        );
    }
}

TableSelectionCell.propTypes = {
    'aria-label': PropTypes.string,
    dataItem: PropTypes.object,
    disabled: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    render: PropTypes.func,
    selectionValue: PropTypes.bool
};

TableSelectionCell.defaultProps = {
    onSelectionChange: f => f
};

TableSelectionCell.displayName = 'TableSelectionCell';

export default TableSelectionCell;
