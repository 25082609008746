import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';

import { ModalHeader, ModalSubHeader } from './Header';
import { ModalFooter } from './Footer';

class AlertModal extends Component {
    constructor(props) {
        super(props);

        this.labelledById = `${props.id}_header`;
        this.describedById = `${props.id}_body`;
    }

    setDescribedByAttribute = ref => {
        const { role } = this.props;

        if (ref && role === 'alertdialog') {
            ref.setAttribute('aria-describedby', this.describedById);
        }
    };

    render() {
        const {
            actions,
            className,
            children,
            classes,
            isOpen,
            icon,
            title,
            message,
            subHeader,
            size,
            role,
            onClose,
            onClosed,
            onExit,
            scrollable,
            closeIconColor
        } = this.props;

        //Please delete scrollable property when updated to reactstrap@8.0.1 https://github.com/reactstrap/reactstrap/releases
        const rootClasses = classNames('onsolve-alert-modal', className, classes.root, {
            'modal-dialog-scrollable': scrollable
        });
        const bodyClasses = classNames('onsolve-alert-modal__body', classes.body);
        const footerClasses = classNames('onsolve-alert-modal__footer', classes.footer);

        return (
            <Modal
                role={role}
                className={rootClasses}
                isOpen={isOpen}
                toggle={onClose}
                onClosed={onClosed}
                onExit={onExit}
                centered
                zIndex="auto"
                backdrop={'static'}
                size={size}
                labelledBy={this.labelledById}
                innerRef={this.setDescribedByAttribute}
            >
                <ModalHeader
                    id={this.labelledById}
                    classes={classes.header}
                    icon={icon}
                    title={title}
                    onClose={onClose}
                    closeIconColor={closeIconColor}
                />
                {subHeader && <ModalSubHeader className={classes.subHeader}>{subHeader}</ModalSubHeader>}
                <ModalBody id={this.describedById} className={bodyClasses}>
                    {message || children}
                </ModalBody>
                <ModalFooter className={footerClasses}>{actions}</ModalFooter>
            </Modal>
        );
    }
}

AlertModal.propTypes = {
    /**
    Specifies action elements put into the footer, such as `ok`, 'cancel` buttons.
    */
    actions: PropTypes.element,
    /**
    The display content of the component.
    */
    children: PropTypes.node,
    /**
    Overrides or extends the class applied to the component.
    */
    className: PropTypes.string,
    /**
    Overrides or extends the styles applied to the sub-components.
    */
    classes: PropTypes.shape({
        root: PropTypes.string,
        header: PropTypes.object,
        subHeader: PropTypes.string,
        body: PropTypes.string,
        footer: PropTypes.string
    }),
    /**
    Specifies a color of a close icon of a modal header. 
    */
    closeIconColor: PropTypes.string,
    /**
    Specifies an icon of a modal header. 
    */
    icon: PropTypes.element,
    /**
    ID of a modal.
    */
    id: PropTypes.string,
    /**
    Whether the modal is currently open.
    */
    isOpen: PropTypes.bool,
    /**
    Specifies a message for a modal body. Deprecated: please use children props instead.
    */
    message: PropTypes.node,
    /**
    Callback function on cancel event.
    */
    onCancel: PropTypes.func,
    /**
    Callback function on close event.
    */
    onClose: PropTypes.func,
    /**
    Callback function on closed event.
    */
    onClosed: PropTypes.func,
    /**
    Callback function on exit event.
    */
    onExit: PropTypes.func,
    /**
    Specifies a role of a modal.
    */
    role: PropTypes.string,
    /**
    Whether the content of the modal body is scrollable.
    */
    scrollable: PropTypes.bool,
    /**
    Specifies the size of a modal.
    */
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
    Specifies a sub header.
    */
    subHeader: PropTypes.node,
    /**
    Specifies a title for a modal header.
    */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

AlertModal.defaultProps = {
    id: 'alert_dialog',
    classes: {},
    role: 'alertdialog',
    scrollable: false,
    size: 'sm'
};

AlertModal.displayName = 'AlertModal';

export default AlertModal;
