import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageBlock = ({ block, className, contentState, direction, onClick, blockProps: { setFocusToBlock } }) => {
    const { src, alt } = contentState.getEntity(block.getEntityAt(0)).getData();
    const { 'text-align': blockAlignment, 'margin-left': indent } = Object.fromEntries(block.getData());

    const handleClick = () => {
        onClick?.();
        setFocusToBlock();
    };

    const imageContainerClasses = classNames('onsolve-wysiwyg-editor__image', {
        [`onsolve-wysiwyg-editor__image--${blockAlignment}`]: !!blockAlignment,
        [`onsolve-wysiwyg-editor__${indent}`]: !!indent
    });

    return (
        <span className={imageContainerClasses}>
            <img
                src={src}
                alt={alt}
                role="presentation"
                className={className}
                direction={direction}
                onClick={handleClick}
            />
        </span>
    );
};

ImageBlock.propTypes = {
    block: PropTypes.object,
    blockProps: PropTypes.shape({ setFocusToBlock: PropTypes.func }),
    className: PropTypes.string,
    contentState: PropTypes.object,
    direction: PropTypes.string,
    onClick: PropTypes.func
};

ImageBlock.displayName = 'ImageBlock';

export default ImageBlock;
