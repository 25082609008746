import React from 'react';
import SvgIcon from './SvgIcon';

const Lock = props => (
    <SvgIcon {...props}>
        <g stroke="none" fillRule="evenodd">
            <path
                d="M8.2,8c-1,0-1.9,0.8-1.9,1.9c0,0.9,0.6,1.6,1.4,1.8v1.5h0.9v-1.5c0.8-0.2,1.4-0.9,1.4-1.8C10.1,8.8,9.3,8,8.2,8z M8.2,10.8
            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S8.8,10.8,8.2,10.8z"
            />
            <path
                d="M12.5,5.6H4.9V4c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1H12c0-2.2-1.8-4-4-4S4,1.8,4,4v1.6H3.5c-0.8,0-1.4,0.6-1.4,1.4v7.5
            c0,0.8,0.6,1.4,1.4,1.4h8.9c0.8,0,1.4-0.6,1.4-1.4V7.1C13.9,6.3,13.2,5.6,12.5,5.6z M12.9,14.6c0,0.3-0.2,0.5-0.5,0.5H3.5
            c-0.3,0-0.5-0.2-0.5-0.5V7.1c0-0.3,0.2-0.5,0.5-0.5h8.9c0.3,0,0.5,0.2,0.5,0.5V14.6z"
            />
        </g>
    </SvgIcon>
);

Lock.displayName = 'LockIcon';

export default Lock;
