import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DotsSpinnerIcon } from '../Icons';

const DotsLoadingSpinner = ({ className, ...props }) => {
    const rootClasses = classNames('onsolve-loading__dots-spinner', className);

    return (
        <div className={rootClasses}>
            <DotsSpinnerIcon {...props} />
        </div>
    );
};

DotsLoadingSpinner.displayName = 'DotsLoadingSpinner';

DotsLoadingSpinner.propTypes = {
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

export default DotsLoadingSpinner;
