import React from 'react';

import SvgIcon from './SvgIcon';

const Bell = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M12.9,7c0,2.3,2.1,2.9,2.1,4.2c0,1.2-2.7,2.1-7,2.1s-7-0.9-7-2.1C1,9.8,3.1,9.3,3.1,7 c0-1.1,0-2.1,0-2.1C3.1,2.2,5.3,0,8,0s4.9,2.2,4.9,4.9C12.9,4.9,12.9,5.8,12.9,7z M8,14.6c0.9,0,1.7-0.1,2.4-0.1C10,15.4,9,16,8,16 s-1.9-0.6-2.4-1.5C6.3,14.5,7.1,14.6,8,14.6z"
        />
    </SvgIcon>
);

Bell.displayName = 'BellIcon';

export default Bell;
