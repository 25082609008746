import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListItemIcon = ({ className, children }) => (
    <div className={classNames('onsolve-list-item__icon', className)}>{children}</div>
);

ListItemIcon.displayName = 'ListItemIcon';

ListItemIcon.propTypes = {
    /**
    Content for the component
    */
    children: PropTypes.node,
    /**
    Specifies class name for the wrapper
    */
    className: PropTypes.string
};

export default ListItemIcon;
