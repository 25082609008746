import React from 'react';
import SvgIcon from './SvgIcon';

const Superscript = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M12 2v4h-1l-.724-1.447A1 1 0 009.382 4H7v9.28a1 1 0 00.684.948L10 15v1H2v-1l2.316-.772A1 1 0 005 13.279V4H2.618a1 1 0 00-.894.553L1 6H0V2h12zm2.362-2c1.05 0 1.421.447 1.421 1.083 0 .636-.44 1.056-.933 1.53l-.765.73h.995c.067 0 .094-.013.108-.08l.095-.535H16V4h-3.127v-.63l.907-.886c.684-.663.975-.934.975-1.32 0-.25-.122-.433-.48-.433-.373 0-.529.122-.529.603l-.92-.082C12.833.176 13.65 0 14.362 0z"
        />
    </SvgIcon>
);

Superscript.displayName = 'SuperscriptIcon';

export default Superscript;
