import React from 'react';
import SvgIcon from './SvgIcon';

const Recording = props => (
    <SvgIcon {...props}>
        <path
            d="M4.52631579,15 L4.52631579,14.3913043 L7.684,14.391 L7.68458287,12.5573663 C4.59333514,12.4031054 2.12131759,9.99449076 2.00433217,7.00443291 L2,6.7826087 L2,6.47826087 L2.63157895,6.47826087 L2.63157895,6.7826087 C2.63157895,9.64034812 5.03482676,11.9565217 8,11.9565217 C10.8891432,11.9565217 13.2448081,9.75760346 13.363712,7.00133671 L13.3684211,6.7826087 L13.3684211,6.47826087 L14,6.47826087 L14,6.7826087 C14,9.87431103 11.4831476,12.3989126 8.31616197,12.5573291 L8.315,14.391 L11.4736842,14.3913043 L11.4736842,15 L4.52631579,15 Z M8,1 C9.91819518,1 11.4736842,2.49913073 11.4736842,4.34782609 L11.4736842,4.34782609 L11.4736842,6.7826087 C11.4736842,8.55810622 10.0348815,10.0224736 8.19464629,10.1251403 L8.19464629,10.1251403 L8,10.1304348 C6.08180482,10.1304348 4.52631579,8.63130405 4.52631579,6.7826087 L4.52631579,6.7826087 L4.52631579,4.34782609 C4.52631579,2.57232856 5.9651185,1.10796114 7.80535371,1.00529447 L7.80535371,1.00529447 Z M8.0038797,1.60834783 L7.83274873,1.61335319 C6.33524864,1.697003 5.15789474,2.89527618 5.15789474,4.34782609 L5.15789474,4.34782609 L5.157,5.26 L6.42105263,5.26086957 L6.42105263,5.86956522 L5.157,5.869 L5.15789474,6.7826087 C5.15789474,8.23911139 6.33808642,9.43048705 7.81874835,9.51685258 L7.99087735,9.52186615 L8.16725127,9.51708159 C9.66475136,9.43343178 10.8421053,8.2351586 10.8421053,6.7826087 L10.8421053,6.7826087 L10.842,5.869 L9.57894737,5.86956522 L9.57894737,5.26086957 L10.842,5.26 L10.8421053,4.34782609 C10.8421053,2.89132339 9.66191358,1.69994773 8.17656994,1.61338504 L8.0038797,1.60834783 Z"
            stroke="none"
        />
    </SvgIcon>
);

Recording.displayName = 'RecordingIcon';

export default Recording;
