import React from 'react';
import SvgIcon from './SvgIcon';

const SwitchAccount = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M15.405 1.939h-2.975v-1.939l-2.975 2.586 2.975 2.586v-1.939h2.38v2.586h1.19v-3.232c0-0.357-0.267-0.646-0.595-0.646zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4c-2.206 0-4-1.794-4-4s1.794-4 4-4zM10.667 11.333v0.333c0 0.736 0.615 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-0.333c0-0.736-0.615-1.333-1.333-1.333s-1.333 0.597-1.333 1.333zM12 15.333c0.811 0 1.555-0.292 2.134-0.775-0.28-0.528-0.828-0.891-1.467-0.891h-1.333c-0.639 0-1.187 0.364-1.467 0.891 0.579 0.483 1.323 0.775 2.134 0.775zM3.57 12.768v-1.939l2.975 2.586-2.975 2.586v-1.939h-2.975c-0.328 0-0.595-0.29-0.595-0.646v-3.232h1.19v2.586h2.38zM4 0c2.206 0 4 1.794 4 4s-1.794 4-4 4c-2.206 0-4-1.794-4-4s1.794-4 4-4zM2.667 3.333v0.333c0 0.736 0.615 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-0.333c0-0.736-0.615-1.333-1.333-1.333s-1.333 0.597-1.333 1.333zM4 7.333c0.811 0 1.555-0.292 2.134-0.775-0.28-0.528-0.828-0.891-1.467-0.891h-1.333c-0.639 0-1.187 0.364-1.467 0.891 0.579 0.483 1.323 0.775 2.134 0.775z"
        />
    </SvgIcon>
);

SwitchAccount.displayName = 'SwitchAccountIcon';

export default SwitchAccount;
