const keyCodes = {
    BACKSPACE: 8,
    DELETE: 46,
    DOWN: 40,
    END: 35,
    ENTER_KEY: 13,
    ESC: 27,
    HOME: 36,
    LEFT: 37,
    RIGHT: 39,
    SPACE: 32,
    TAB: 9,
    UP: 38,
    PAGEUP: 33,
    PAGEDOWN: 34
};

export default keyCodes;
