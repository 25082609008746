import React from 'react';
import SvgIcon from './SvgIcon';

const Eraser = props => {
    return (
        <SvgIcon {...props}>
            <path d="M16,14 L16,16 L0,16 L0,14 L16,14 Z M8.3,0.3 C8.7,-0.1 9.3,-0.1 9.7,0.3 L9.7,0.3 L14.7,5.3 C15.1,5.7 15.1,6.3 14.7,6.7 L14.7,6.7 L9.4,12 L4.6,12 L1.3,8.7 C0.9,8.3 0.9,7.7 1.3,7.3 L1.3,7.3 Z" />
        </SvgIcon>
    );
};

Eraser.displayName = 'EraserIcon';

export default Eraser;
