import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PopoverActions = props => {
    const { className, children, size, ...otherProps } = props;
    const rootClasses = classNames('onsolve-popover__actions', className, {
        [`onsolve-popover__actions--${size}`]: size
    });

    return (
        <div {...otherProps} className={rootClasses}>
            {children}
        </div>
    );
};

PopoverActions.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies one of the size of component.
     */
    size: PropTypes.oneOf(['md', 'lg'])
};

PopoverActions.displayName = 'PopoverActions';

export default PopoverActions;
