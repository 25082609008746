import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { componentsTranslations } from '../translations';

import { VALIDATION_TYPES } from './constants';
import { RejectedFilesModal } from './components';
import { fileUploadToServer, filesValidation, limitsMessageValues } from './utils';
import { Focusable } from '../Focusable';
import { InputFieldLabel } from '../InputBase';
import { Typography } from '../Typography';
import { FileUploadIcon } from '../Icons';
import { LoadingSpinner } from '../Loading';
import { CircularProgress } from '../Progress';

const FileUpload = ({
    acceptMimeTypes,
    additionalValidationParams,
    className,
    classes,
    disabled,
    errorMessages,
    icon,
    isMultipleFiles,
    label,
    maxFileNameLength,
    minFileNameLength,
    maxSizeInBytes,
    minSizeInBytes,
    onFilesLoaded,
    onFileUpload,
    placeholder,
    rejectMimeTypes,
    showRejectedFilesModal,
    validationType
}) => {
    const [isRejectFilesModalOpen, setIsRejectFilesModalOpen] = useState(false);
    const [uploadPercentComplete, setUploadPercentComplete] = useState(0.0);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [uploadingFilesName, setUploadingFilesName] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [globalReasonError, setGlobalReasonError] = useState([]);

    const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
        onDrop: files => {
            if (disabled) {
                return;
            }

            setIsFileUploading(true);
            setUploadingFilesName([]);
            filesValidation(
                files,
                acceptMimeTypes,
                rejectMimeTypes,
                minSizeInBytes,
                maxSizeInBytes,
                maxFileNameLength,
                minFileNameLength,
                isMultipleFiles,
                validationType,
                additionalValidationParams,
                errorMessages
            )
                .then(({ acceptedFiles, rejectedFiles, globalReason }) => {
                    if (globalReason || rejectedFiles.length > 0) {
                        return { acceptedFiles, rejectedFiles, globalReason };
                    }
                    if (onFileUpload && acceptedFiles.length > 0) {
                        setUploadingFilesName([...acceptedFiles.map(file => file.name)]);

                        const uploadFile = isMultipleFiles
                            ? onFileUpload
                            : file =>
                                  onFileUpload(file, ({ percentComplete }) =>
                                      setUploadPercentComplete(percentComplete)
                                  );

                        return fileUploadToServer(acceptedFiles, rejectedFiles, globalReason, uploadFile);
                    }
                    return { acceptedFiles, rejectedFiles, globalReason };
                })
                .then(({ acceptedFiles, rejectedFiles, globalReason }) => {
                    setRejectedFiles(rejectedFiles);
                    setGlobalReasonError(globalReason);
                    if (onFilesLoaded) {
                        onFilesLoaded({
                            acceptedFiles,
                            rejectedFiles
                        });
                    }
                    setIsFileUploading(false);
                    setUploadPercentComplete(0.0);
                });
        }
    });

    useEffect(() => {
        if (rejectedFiles.length && showRejectedFilesModal) {
            setIsRejectFilesModalOpen(true);
        }
    }, [rejectedFiles, showRejectedFilesModal]);

    const handleCloseRejectFilesModal = () => {
        setIsRejectFilesModalOpen(false);
    };

    const wrapperClassNames = classNames('onsolve-file-upload__wrapper', classes.wrapper, className);

    const fileUploadClassNames = classeNames =>
        classNames(
            'onsolve-file-upload__base-style',
            {
                'onsolve-file-upload__on_dragging': !disabled && (isDragActive || isDragAccept || isDragReject)
            },
            classes.base,
            classeNames
        );

    // eslint-disable-next-line react/display-name
    const rejectedFilesItems = () => {
        const limitsMessage = limitsMessageValues(
            acceptMimeTypes,
            maxSizeInBytes,
            additionalValidationParams.maxHeight,
            additionalValidationParams.maxWidth
        );

        return isMultipleFiles ? (
            <ul>
                {rejectedFiles.map(file => {
                    return (
                        <li key={file.name}>
                            <Typography variant="p14">
                                <FormattedMessage
                                    {...componentsTranslations.ng_file_upload_rejected_text}
                                    values={{ fileName: file.name, rejectReason: file.rejectReason }}
                                />{' '}
                                {limitsMessage}
                            </Typography>
                        </li>
                    );
                })}
            </ul>
        ) : (
            <Typography variant="p14">
                <FormattedMessage
                    {...componentsTranslations.ng_file_upload_rejected_text}
                    values={{ fileName: rejectedFiles[0].name, rejectReason: rejectedFiles[0].rejectReason }}
                />{' '}
                {limitsMessage}
            </Typography>
        );
    };

    // eslint-disable-next-line react/display-name
    const renderLoadingIndicator = () => {
        if (isMultipleFiles) {
            return <LoadingSpinner color="primary" size="xl" />;
        } else {
            return <CircularProgress value={uploadPercentComplete} />;
        }
    };

    const uploadIconClassNames = classNames('onsolve-file-upload__icon', classes.icon);

    // eslint-disable-next-line react/display-name
    const renderedContent = () => {
        return isFileUploading ? (
            <Fragment>
                {renderLoadingIndicator()}
                <Typography variant="p12">
                    <FormattedMessage {...componentsTranslations.ng_file_upload_uploading_files} />
                    {` ${uploadingFilesName.join(', ')}`}
                </Typography>
            </Fragment>
        ) : (
            <Fragment>
                {icon && <div className={uploadIconClassNames}>{icon}</div>}
                {placeholder}
            </Fragment>
        );
    };

    return (
        <Fragment>
            {label && (
                <div className="onsolve-file-upload__label">
                    <InputFieldLabel>{label}</InputFieldLabel>
                </div>
            )}
            <div className={wrapperClassNames}>
                <Focusable
                    render={({ classes }) => (
                        <div {...getRootProps({ className: fileUploadClassNames(classes) })}>
                            <input {...getInputProps()} disabled={disabled} />
                            {renderedContent()}
                        </div>
                    )}
                />
            </div>
            <RejectedFilesModal isOpen={isRejectFilesModalOpen} onCancel={handleCloseRejectFilesModal}>
                {globalReasonError ? (
                    <div>
                        <Typography variant="p14">{globalReasonError}</Typography>
                    </div>
                ) : (
                    <div>
                        {rejectedFiles.length && rejectedFilesItems()}
                        <Typography variant="p14">
                            <FormattedMessage {...componentsTranslations.ng_file_upload_select_different} />
                        </Typography>
                    </div>
                )}
            </RejectedFilesModal>
        </Fragment>
    );
};

FileUpload.propTypes = {
    acceptMimeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    additionalValidationParams: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    classes: PropTypes.shape({
        base: PropTypes.string,
        icon: PropTypes.string,
        wrapper: PropTypes.string
    }),
    disabled: PropTypes.bool,
    errorMessages: PropTypes.shape({
        multipleFiles: PropTypes.element,
        name: PropTypes.element,
        size: PropTypes.element,
        type: PropTypes.element
    }),
    icon: PropTypes.element,
    isMultipleFiles: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    maxFileNameLength: PropTypes.number,
    maxSizeInBytes: PropTypes.number,
    minFileNameLength: PropTypes.number,
    minSizeInBytes: PropTypes.number,
    onFileUpload: PropTypes.func,
    onFilesLoaded: PropTypes.func,
    placeholder: PropTypes.node,
    rejectMimeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    showRejectedFilesModal: PropTypes.bool,
    validationType: PropTypes.oneOf([...Object.values(VALIDATION_TYPES)])
};

FileUpload.defaultProps = {
    additionalValidationParams: {},
    classes: {},
    disabled: false,
    errorMessages: {},
    icon: <FileUploadIcon size="lg" color="primary" />,
    isMultipleFiles: false,
    placeholder: (
        <div className="onsolve-file-upload__placeholder">
            <Typography variant="p12">
                <FormattedMessage
                    {...componentsTranslations.ng_file_upload_placeholder}
                    values={{
                        /* eslint-disable react/display-name */
                        b: (...chunks) => <b className="onsolve-file-upload__blue_text">{chunks}</b>
                    }}
                />
            </Typography>
        </div>
    ),
    showRejectedFilesModal: true,
    validationType: 'any'
};

FileUpload.displayName = 'FileUpload';

export default FileUpload;
