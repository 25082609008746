import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { componentsTranslations } from '../translations';
import { keyCodes } from '../../common/constants';
import { DomUtils, ReactUtils, EventsUtils } from '../utils';
import { IconButton } from '../Button';
import { TimesIcon } from '../Icons';
import { Backdrop } from '../Backdrop';
import { Typography } from '../Typography';

const Drawer = forwardRef(
    (
        {
            isOpen,
            title,
            placement,
            children,
            actions,
            className,
            headerClassName,
            bodyClassName,
            footerClassName,
            contentClassName,
            headerTag,
            onClose
        },
        ref
    ) => {
        const triggerElement = useRef();
        const drawerElement = useRef();
        const backdropElement = useRef();
        const contentElement = useRef();
        const intl = useIntl();

        const handleTab = useCallback(e => {
            const charCode = DomUtils.getCharCode(e);

            if (charCode !== keyCodes.TAB) {
                return;
            }

            const focusableChildren = drawerElement.current.querySelectorAll(
                DomUtils.getFocusableElements().join(', ')
            );
            const totalFocusable = focusableChildren.length;
            let focusedIndex = 0;

            for (let i = 0; i < totalFocusable; i++) {
                if (focusableChildren[i] === document.activeElement) {
                    focusedIndex = i;
                    break;
                }
            }

            if (e.shiftKey && focusedIndex === 0) {
                e.preventDefault();
                DomUtils.setFocus(focusableChildren[totalFocusable - 1]);
            } else if (!e.shiftKey && focusedIndex === totalFocusable - 1) {
                e.preventDefault();
                DomUtils.setFocus(focusableChildren[0]);
            }
        }, []);

        useEffect(() => {
            if (isOpen && backdropElement && contentElement) {
                triggerElement.current = document.activeElement;
                backdropElement.current.classList.add('onsolve-drawer__backdrop--visible');
                contentElement.current.classList.add('onsolve-drawer__content--visible');
                document.body.className = classNames(document.body.className, 'onsolve-drawer--open');
                DomUtils.setFocus(drawerElement);
            }

            return () => {
                EventsUtils.unbind(drawerElement, 'keydown', handleTab);
                document.body.className = document.body.className.replace('onsolve-drawer--open', ' ').trim();
            };
        }, [isOpen, handleTab]);

        const handleClose = () => {
            if (onClose) {
                onClose();
            }

            DomUtils.setFocus(triggerElement);
        };

        const handleDrawerRef = value => {
            if (value) {
                ReactUtils.setMultipleRefs(ref, drawerElement)(value);
                EventsUtils.bind(drawerElement, 'keydown', handleTab, true);
            }
        };

        if (!isOpen) {
            return null;
        }

        const rotation = (DomUtils.isRtl() && '-90') || '-270';

        const rootClassNames = classNames('onsolve-drawer', className);
        const headerClassNames = classNames(
            'onsolve-drawer__header',
            `bg-gradient-primary${rotation}`,
            headerClassName
        );
        const titleClassNames = classNames('onsolve-drawer__title mr-auto');
        const bodyClassNames = classNames('onsolve-drawer__body', bodyClassName);
        const footerClassNames = classNames('onsolve-drawer__footer', footerClassName);
        const contentClassNames = classNames(
            'onsolve-drawer__content',
            `onsolve-drawer__content--${placement}`,
            contentClassName
        );

        return ReactDOM.createPortal(
            <div ref={handleDrawerRef} role="dialog" className={rootClassNames} tabIndex={-1}>
                <Backdrop ref={backdropElement} className="onsolve-drawer__backdrop" onClick={handleClose} />
                <div className={contentClassNames} ref={contentElement}>
                    <div className={headerClassNames}>
                        <Typography component={headerTag} className={titleClassNames} variant="h3" color="white">
                            {title}
                        </Typography>
                        <IconButton
                            className="onsolve-drawer__close ml-3"
                            onClick={handleClose}
                            aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
                        >
                            <TimesIcon className="onsolve-drawer__close--icon" color="white" size="sm" />
                        </IconButton>
                    </div>
                    <div className={bodyClassNames}>{children}</div>
                    {actions && <div className={footerClassNames}>{actions}</div>}
                </div>
            </div>,
            document.body
        );
    }
);

Drawer.propTypes = {
    /**
    The actions displayed at the bottom.
    */
    actions: PropTypes.node,
    /**
    Override or extend the styles applied to body.
    */
    bodyClassName: PropTypes.string,
    /**
    The display content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to component.
    */
    className: PropTypes.string,
    /**
    Override or extend the styles applied to content.
    */
    contentClassName: PropTypes.string,
    /**
    Override or extend the styles applied to footer.
    */
    footerClassName: PropTypes.string,
    /**
    Override or extend the styles applied to header.
    */
    headerClassName: PropTypes.string,
    /**
    Specifies the html element for header.
    */
    headerTag: PropTypes.string,
    /**
    Controls the visual state of component.
    */
    isOpen: PropTypes.bool,
    /**
    Callback function on close event occurs when the close button is clicked.
    */
    onClose: PropTypes.func,
    /**
    Specifies one of placement.
    */
    placement: PropTypes.oneOf(['left', 'right']),
    /**
    Specifies the title of header.
    */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

Drawer.defaultProps = {
    placement: 'right',
    headerTag: 'h3'
};

Drawer.displayName = 'Drawer';

export default Drawer;
