import React from 'react';
import { EditorTypeDropdown } from '../../../components';

const editorTypeTool = (currentType, onTypeChange) => ({
    focusable: true,
    focusableRef: value => value,

    // eslint-disable-next-line react/display-name
    render: (editorState, _, onChange) => (
        <EditorTypeDropdown currentType={currentType} onTypeChange={onTypeChange(editorState, onChange)} />
    )
});

export default editorTypeTool;
