import React from 'react';
import { Map } from 'immutable';
import { DefaultDraftBlockRenderMap } from 'draft-js';

import { HeaderElement } from './HeaderElement';

const blockRenderMap = Map({
    'header-one': {
        element: 'h1',
        wrapper: <HeaderElement variant="h1" />
    },
    'header-two': {
        element: 'h2',
        wrapper: <HeaderElement variant="h2" />
    },
    'header-three': {
        element: 'h3',
        wrapper: <HeaderElement variant="h3" />
    },
    'header-four': {
        element: 'h4',
        wrapper: <HeaderElement variant="h4" />
    },
    'header-five': {
        element: 'h5',
        wrapper: <HeaderElement variant="h5" />
    },
    'header-six': {
        element: 'h6',
        wrapper: <HeaderElement variant="h6" />
    }
});

export default () => {
    const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

    return {
        blockRenderMap: extendedBlockRenderMap
    };
};
