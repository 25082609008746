import { MEDIA_BREAKPOINTS } from './constants';

const responsiveMap = {
    sm: `(max-width: ${MEDIA_BREAKPOINTS.sm - 1}px)`,
    md: `(min-width: ${MEDIA_BREAKPOINTS.sm}px) and (max-width: ${MEDIA_BREAKPOINTS.md - 1}px)`,
    lg: `(min-width: ${MEDIA_BREAKPOINTS.md}px) and (max-width: ${MEDIA_BREAKPOINTS.xl - 1}px)`,
    xl: `(min-width: ${MEDIA_BREAKPOINTS.xl}px)`
};

let subscribers = [];
let subUid = -1;
let screens = {};

const mediaQueryObserver = {
    matchHandlers: {},
    dispatch(pointMap) {
        screens = pointMap;
        if (subscribers.length < 1) {
            return false;
        }

        subscribers.forEach(item => {
            item.func(screens);
        });

        return true;
    },
    subscribe(func) {
        if (subscribers.length === 0) {
            this.register();
        }
        const token = (++subUid).toString();

        subscribers.push({
            token,
            func
        });
        func(screens);
        return token;
    },
    unsubscribe(token) {
        subscribers = subscribers.filter(item => item.token !== token);
        if (subscribers.length === 0) {
            this.unregister();
        }
    },
    unregister() {
        Object.keys(responsiveMap).forEach(screen => {
            const matchMediaQuery = responsiveMap[screen];
            const handler = this.matchHandlers[matchMediaQuery];

            if (handler && handler.mql && handler.listener) {
                handler.mql.removeListener(handler.listener);
            }
        });
    },
    register() {
        Object.keys(responsiveMap).forEach(screen => {
            const matchMediaQuery = responsiveMap[screen];
            const listener = ({ matches }) => {
                this.dispatch({
                    ...screens,
                    [screen]: matches
                });
            };
            const mql = window.matchMedia(matchMediaQuery);

            mql.addListener(listener);
            this.matchHandlers[matchMediaQuery] = {
                mql,
                listener
            };

            listener(mql);
        });
    }
};

export default mediaQueryObserver;
