import React from 'react';

import SvgIcon from './SvgIcon';

const DragIndicator = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path
                d="M6.67333333,6 L2,6 L2,7.33333333 L6.67333333,7.33333333 L6.67333333,9.33333333 L9.33333333,6.66666667 L6.67333333,4 L6.67333333,6 Z M4.66,4 L4.66,2 L9.33333333,2 L9.33333333,0.666666667 L4.66,0.666666667 L4.66,-1.33333333 L2,1.33333333 L4.66,4 Z"
                transform="translate(7.666667, 6.000000) rotate(-90.000000) translate(-7.666667, -6.000000) "
            />
        </g>
    </SvgIcon>
);

DragIndicator.displayName = 'DragIndicatorIcon';

export default DragIndicator;
