import React from 'react';
import SvgIcon from './SvgIcon';

const InboxMessage = props => (
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fill="#FFF"
                d="M14.125 1H1.875A.875.875 0 001 1.875v3.5h5.25v-1.75l3.5 2.625-3.5 2.625v-1.75H1v3.5c0 .483.392.875.875.875H4.5V15l5.25-3.5h4.375a.875.875 0 00.875-.875v-8.75A.875.875 0 0014.125 1z"
                transform="translate(-408 -334) translate(400 325) translate(8 9)"
            ></path>
        </g>
    </SvgIcon>
);

InboxMessage.displayName = 'InboxMessageIcon';

export default InboxMessage;
