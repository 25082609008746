import React from 'react';

import SvgIcon from './SvgIcon';

const Slack = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path
                fill="#36c5f0"
                d="M6.94 2.769a1.077 1.077 0 100 2.154h1.077V3.846c0-.595-.482-1.077-1.077-1.077zM6.896 5.538H3.907c-.619 0-1.121.482-1.121 1.077s.502 1.077 1.121 1.077h2.989c.619 0 1.121-.482 1.121-1.077s-.502-1.077-1.121-1.077z"
            />
            <path
                fill="#2eb67d"
                d="M13.556 6.615a1.077 1.077 0 10-2.154 0v1.077h1.077c.595 0 1.077-.482 1.077-1.077zM10.786 6.638V3.825c0-.583-.482-1.055-1.077-1.055s-1.077.472-1.077 1.055v2.813c0 .583.482 1.055 1.077 1.055s1.077-.472 1.077-1.055z"
            />
            <path
                fill="#ecb22e"
                d="M9.709 13.231a1.077 1.077 0 100-2.154H8.632v1.077c0 .595.482 1.077 1.077 1.077zM9.753 10.462h2.989c.619 0 1.121-.482 1.121-1.077s-.502-1.077-1.121-1.077H9.753c-.619 0-1.121.482-1.121 1.077s.502 1.077 1.121 1.077z"
            />
            <path
                fill="#e01e5a"
                d="M2.786 9.385a1.077 1.077 0 102.154 0V8.308H3.863c-.595 0-1.077.482-1.077 1.077zM5.863 9.363v2.813c0 .583.482 1.055 1.077 1.055s1.077-.472 1.077-1.055V9.363c0-.583-.482-1.055-1.077-1.055s-1.077.472-1.077 1.055z"
            />
        </g>
    </SvgIcon>
);

Slack.displayName = 'Slack';

export default Slack;
