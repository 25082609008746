import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { paginatorVariants } from '../../constants';
import { fp as _ } from '../../../utils';
import { BlurLoader } from '../../../BlurLoader';
import { Dropdown } from '../../../Dropdowns';
import { componentsTranslations } from '../../../translations';

class PageSizesDropdown extends PureComponent {
    handlePageChange = (e, value) => {
        const { onPageChange } = this.props;

        onPageChange(e, { skip: 0, take: value });
    };

    dropdownMobilePaginationLabel = (selectPageLabel, selectPageLabelHelper) => ({ value }) => (
        <div>{`${selectPageLabel} ${value} ${selectPageLabelHelper}`}</div>
    );

    render() {
        const { end, pageSizes, messages, intl, pageSizeRef, variant, i18nBlur } = this.props;
        const newPageSizes = _.map(pageSizes, item => ({ id: item, value: item }));
        const selectPageLabel = i18nBlur ? (
            <BlurLoader width={2}>
                {intl.formatMessage(componentsTranslations.ng_components_select_page_label)}
            </BlurLoader>
        ) : (
            intl.formatMessage(componentsTranslations.ng_components_select_page_label)
        );

        const selectPageLabelHelper = i18nBlur ? (
            <BlurLoader width={2}>
                {intl.formatMessage(componentsTranslations.ng_components_select_page_helpText)}
            </BlurLoader>
        ) : (
            intl.formatMessage(componentsTranslations.ng_components_select_page_helpText)
        );

        return (
            <div className="onsolve-table__paginator-page-sizes">
                {variant !== paginatorVariants.MOBILE && (
                    <span>{(messages && messages.pageSizelabel) || selectPageLabel}</span>
                )}
                <Dropdown
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_select_page_description)}
                    className="pl-2"
                    classes={{
                        root:
                            variant === paginatorVariants.MOBILE
                                ? 'onsolve-table__paginator-dropdown-mobile'
                                : 'onsolve-table__paginator-dropdown',
                        displayText:
                            variant === paginatorVariants.MOBILE
                                ? ''
                                : 'onsolve-table__paginator-dropdown__text text-azure font-weight-bold'
                    }}
                    value={end}
                    valueRender={
                        variant === paginatorVariants.MOBILE
                            ? this.dropdownMobilePaginationLabel(selectPageLabel, selectPageLabelHelper)
                            : null
                    }
                    variant="link"
                    data={newPageSizes}
                    onChange={this.handlePageChange}
                    tabIndex="0"
                    showToggle={variant !== paginatorVariants.MOBILE}
                    menuProps={{ position: 'right' }}
                    innerRef={pageSizeRef}
                />
                {variant !== paginatorVariants.MOBILE && (
                    <span className="ml-2">{(messages && messages.pageSizeHelpText) || selectPageLabelHelper}</span>
                )}
            </div>
        );
    }
}

PageSizesDropdown.displayName = 'PageSizesDropdown';

PageSizesDropdown.propTypes = {
    end: PropTypes.number,
    i18nBlur: PropTypes.bool,
    intl: PropTypes.object,
    isMobilePagination: PropTypes.bool,
    messages: PropTypes.shape({
        pageSizelabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        pageSizeHelpText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        paginationInfoDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    }),
    onPageChange: PropTypes.func,
    pageSizeRef: PropTypes.object,
    pageSizes: PropTypes.instanceOf(Array),
    variant: PropTypes.oneOf(Object.values(paginatorVariants))
};

PageSizesDropdown.defaultProps = {
    end: 0,
    total: 0,
    variant: paginatorVariants.DESKTOP,
    pageSizes: []
};

export default injectIntl(PageSizesDropdown);
