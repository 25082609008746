import React from 'react';

import SvgIcon from './SvgIcon';

const Edit = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M12.3125,9.3125 C12.3125,9.03635763 12.5363576,8.8125 12.8125,8.8125 C13.0886424,8.8125 13.3125,9.03635763 13.3125,9.3125 L13.3125,13.6875 C13.3125,14.4466424 12.6966424,15.0625 11.9375,15.0625 L2.3125,15.0625 C1.55335763,15.0625 0.9375,14.4466424 0.9375,13.6875 L0.9375,4.0625 C0.9375,3.30335763 1.55335763,2.6875 2.3125,2.6875 L6.6875,2.6875 C6.96364237,2.6875 7.1875,2.91135763 7.1875,3.1875 C7.1875,3.46364237 6.96364237,3.6875 6.6875,3.6875 L2.3125,3.6875 C2.10564237,3.6875 1.9375,3.85564237 1.9375,4.0625 L1.9375,13.6875 C1.9375,13.8943576 2.10564237,14.0625 2.3125,14.0625 L11.9375,14.0625 C12.1443576,14.0625 12.3125,13.8943576 12.3125,13.6875 L12.3125,9.3125 Z M12.375,2.14460678 L5.82016828,8.6994385 L5.22801099,10.771989 L7.3005615,10.1798317 L13.8553932,3.625 L12.375,2.14460678 Z M12.7285534,1.08394661 L14.9160534,3.27144661 C15.1113155,3.46670876 15.1113155,3.78329124 14.9160534,3.97855339 L7.91605339,10.9785534 C7.85596903,11.0386378 7.7815633,11.0824183 7.69986056,11.105762 L4.63736056,11.980762 C4.26018462,12.0885265 3.91147347,11.7398154 4.01923803,11.3626394 L4.89423803,8.30013944 C4.91758166,8.2184367 4.96136224,8.14403097 5.02144661,8.08394661 L12.0214466,1.08394661 C12.2167088,0.888684464 12.5332912,0.888684464 12.7285534,1.08394661 Z"
            />
        </SvgIcon>
    );
};

Edit.displayName = 'EditIcon';

export default Edit;
