import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InputFieldWrapper } from '../InputBase';

class TextArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            currentLength: this.getCurrentlength(props.value)
        };

        this.input = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    getCurrentlength(value) {
        if (value) {
            return String(value).length;
        }
        return 0;
    }

    handleChange(e) {
        const { disabled, onChange } = this.props;
        const { value } = e.target;

        if (disabled) {
            return;
        }

        this.setState({ currentLength: this.getCurrentlength(value) });

        if (onChange) {
            onChange(e, value);
        }
    }

    handleFocus(e) {
        const { readOnly, onFocus } = this.props;

        if (readOnly) {
            return;
        }

        this.setState({ active: true });

        if (onFocus) {
            onFocus(e);
        }
    }

    handleBlur(e) {
        const { readOnly, onBlur } = this.props;

        if (readOnly) {
            return;
        }

        this.setState({ active: false });

        if (onBlur) {
            onBlur(e);
        }
    }

    render() {
        const {
            name,
            rows,
            column,
            value,
            defaultValue,
            placeholder,
            maxLength,
            disabled,
            readOnly,
            className,
            classes,
            showLabelExclamation,
            customLength
        } = this.props;
        const { currentLength } = this.state;
        const textAreaWrapperClasses = classNames('onsolve-textarea-field', classes.root);
        const textAreaClasses = classNames('onsolve-textarea-field__input', className);

        return (
            <InputFieldWrapper
                {...this.props}
                {...this.state}
                currentLength={customLength !== undefined ? customLength : currentLength}
                className={textAreaWrapperClasses}
                showLabelExclamation={showLabelExclamation}
                variant="naked"
            >
                {(!value || value.toString().length === 0) && (
                    <span className="onsolve-textarea-field__placeholder">{placeholder}</span>
                )}
                <textarea
                    id={name}
                    rows={rows}
                    cols={column}
                    ref={this.input}
                    className={textAreaClasses}
                    autoComplete="off"
                    name={name}
                    value={value || defaultValue}
                    disabled={disabled}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
            </InputFieldWrapper>
        );
    }
}

TextArea.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.node,
    helpText: PropTypes.node,
    hideMaxLength: PropTypes.bool,
    label: PropTypes.node,
    maxLength: PropTypes.number,
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    readOnly: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showLabelExclamation: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextArea.defaultProps = {
    defaultValue: '',
    classes: {}
};

TextArea.displayName = 'TextArea';

export default TextArea;
