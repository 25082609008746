import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ArrowDownIcon, TimesIcon } from '../../../../Icons';
import { IconButton } from '../../../../Button';
import { InputFieldToggle, InputFieldWrapper } from '../../../../InputBase';
import PlaceholderValue from '../placeholder';

import { fp as _ } from '../../../../../components/utils';
import { componentsTranslations } from '../../../../../components/translations';

import { getAriaLabel } from '../a11y';

class Input extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: ''
        };

        this.delayedCallback = _.debounce((value = '') => this.props.onInputChange(value), 50);
    }

    handleToggle = () => {
        const { isOpen } = this.state;

        this.setState({ isOpen: !isOpen });
    };

    handleChange = e => {
        const { disabled, onChange } = this.props;
        const { value } = this.state;
        const { value: targetValue } = e.target;

        e.persist();

        if (disabled) {
            return;
        }

        this.delayedCallback(targetValue);

        this.setState({ value: targetValue, isOpen: targetValue.length > 0 });

        if (onChange) {
            onChange(e, value);
        }
    };

    handleFocus = e => {
        const { onFocus } = this.props;

        this.handleToggle();

        if (onFocus) {
            onFocus(e);
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        const { isOpen } = this.state;

        isOpen && this.handleToggle();

        onBlur();
    };

    renderSearchMatchesCounter = () => {
        const { searchMatches, searchTerm, intl } = this.props;

        if (searchTerm) {
            return (
                <span className="onsolve-input-field__matches">
                    {intl.formatMessage(componentsTranslations.ng_components_division_dropdown_matches_found, {
                        0: searchMatches
                    })}
                </span>
            );
        }
    };

    handleClearInput = () => {
        this.setState({
            value: ''
        });

        this.delayedCallback();

        this.input.focus();
    };

    renderSearchClearButton = () => {
        if (this.props.searchTerm) {
            return (
                <IconButton className="onsolve-search-box__button--clean" onClick={this.handleClearInput}>
                    <TimesIcon color="secondary" size="sm" />
                </IconButton>
            );
        }
    };

    renderCaret = () => {
        const { searchTerm, disabled } = this.props;

        if (!searchTerm) {
            return <InputFieldToggle icon={ArrowDownIcon} disabled={disabled} onClick={this.handleToggle} />;
        }
    };

    setInputRef = node => {
        const { inputRef } = this.props;

        this.input = node;

        inputRef && inputRef(node);
    };

    render() {
        const {
            texts = {},
            disabled,
            readOnly,
            onKeyDown,
            onKeyUp,
            activeDescendant,
            error,
            errorText,
            intl,
            innerRef,
            placeholder = intl.formatMessage(componentsTranslations.ng_components_selectDivisions),
            placeholderTextSize,
            tags,
            rectangleBorder
        } = this.props;
        const { isOpen, value } = this.state;

        return (
            <div className="tag-list" ref={innerRef}>
                <div className="tag-item">
                    <InputFieldWrapper
                        active={isOpen}
                        variant={rectangleBorder ? 'bordered' : 'standard'}
                        tabIndex="-1"
                        error={error}
                        errorText={errorText}
                    >
                        <div className="onsolve-input-field__input-wrapper">
                            {!value && (
                                <PlaceholderValue
                                    text={placeholder}
                                    textSize={placeholderTextSize}
                                    tags={tags}
                                    intl={intl}
                                />
                            )}
                            <input
                                ref={this.setInputRef}
                                name={name}
                                type="text"
                                value={value}
                                autoComplete="off"
                                disabled={disabled}
                                className="onsolve-input-field__input"
                                aria-label={intl.formatMessage(componentsTranslations.ng_components_divisionField)}
                                readOnly={readOnly}
                                aria-activedescendant={activeDescendant}
                                aria-autocomplete={onKeyDown ? 'list' : undefined}
                                {...getAriaLabel(texts.label)}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onKeyDown={onKeyDown}
                                onKeyUp={onKeyUp}
                            />
                        </div>
                        {this.renderSearchMatchesCounter()}
                        {this.renderSearchClearButton()}
                        {this.renderCaret()}
                    </InputFieldWrapper>
                </div>
            </div>
        );
    }
}

Input.defaultProps = {
    rectangleBorder: false
};

Input.propTypes = {
    activeDescendant: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    innerRef: PropTypes.func,
    inputRef: PropTypes.func,
    intl: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInputChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onTagRemove: PropTypes.func,
    placeholder: PropTypes.string,
    placeholderTextSize: PropTypes.oneOf(['sm', 'xs']),
    readOnly: PropTypes.bool,
    rectangleBorder: PropTypes.bool,
    searchMatches: PropTypes.number,
    searchTerm: PropTypes.string,
    tags: PropTypes.array,
    texts: PropTypes.object
};

Input.displayName = 'Input';

export default Input;
