import { DomUtils } from '../utils';

class PopoverManager {
    constructor() {
        if (typeof PopoverManager.instance === 'object') {
            return PopoverManager.instance;
        }

        this.container = new Map();
        PopoverManager.instance = this;
        return PopoverManager.instance;
    }

    register(id, element) {
        this.container.set(id, element);
    }

    unregister(id) {
        this.container.delete(id);
    }

    canClose(id, element) {
        const ids = Array.from(this.container.keys());
        const currentIndex = ids.indexOf(id);

        if (currentIndex > -1 && currentIndex === ids.length - 1) {
            return !DomUtils.isInDOMSubtree(element, this.container.get(ids[currentIndex]));
        }
        return false;
    }
}

export default PopoverManager;
