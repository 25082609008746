import React from 'react';
import SvgIcon from './SvgIcon';

const Emergency = props => (
    <SvgIcon {...props}>
        <g id="t-warning" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M15.9604757,15.4671307 L8.30815384,0.179897971 C8.18815152,-0.0599659905 7.81179642,-0.0599659905 7.69214194,0.179897971 L0.0398200987,15.4671307 C-0.0168766494,15.5799651 -0.0127026557,15.7153663 0.050255083,15.8241968 C0.113212822,15.9330274 0.225910652,16 0.347652136,16 L15.6522958,16 C15.7740373,16 15.8867351,15.9333913 15.9496929,15.8245608 C16.0126506,15.7157303 16.0168246,15.5799651 15.9604757,15.4671307 Z M8.79511978,5.44452975 L8.39754688,11.476071 L7.60240107,11.476071 L7.20482817,5.44452975 L8.79511978,5.44452975 Z M7.99997397,14.5440731 C7.42361501,14.5440731 6.95647554,14.0552456 6.95647554,13.4521279 C6.95647554,12.8490101 7.42361501,12.3601827 7.99997397,12.3601827 C8.57633294,12.3601827 9.04347241,12.8490101 9.04347241,13.4521279 C9.04347241,14.0552456 8.57633294,14.5440731 7.99997397,14.5440731 Z"
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
            ></path>
        </g>
    </SvgIcon>
);

Emergency.displayName = 'EmergencyIcon';

export default Emergency;
