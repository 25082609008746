import PropTypes from 'prop-types';

const ColumnShape = PropTypes.shape({
    cols: PropTypes.array,
    items: PropTypes.array,
    width: PropTypes.number
});

export default {
    ColumnShape
};
