import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AlertModal from '../../Modals/AlertModal';
import { Button } from '../../Button';

import { componentsTranslations } from '../../translations';

const Actions = ({ buttonSize, onCancel }) => (
    <Button color="primary" className="text-uppercase" onClick={onCancel} size={buttonSize}>
        <FormattedMessage {...componentsTranslations.ng_components_ok} />
    </Button>
);

Actions.propTypes = {
    buttonSize: PropTypes.string,
    onCancel: PropTypes.func
};

Actions.displayName = 'Actions';

const RejectedFilesModal = ({ isOpen, children, onCancel }) => {
    const title = <FormattedMessage {...componentsTranslations.ng_file_upload_rejected_files_modal} />;

    const actions = <Actions onCancel={onCancel} />;

    return (
        <AlertModal isOpen={isOpen} onClose={onCancel} size="sm" title={title} message={children} actions={actions} />
    );
};

RejectedFilesModal.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

RejectedFilesModal.displayName = 'RejectedFilesModal';

export default RejectedFilesModal;
