import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Highlighter } from '../../../../Highlighter';

import Checkbox from '../checkbox';
import RadioButton from '../radio';

class NodeLabel extends PureComponent {
    handleCheckboxChange = e => {
        const { variant, id, onCheckboxChange } = this.props;

        if (variant === 'simpleSelect' || variant === 'radioSelect') {
            onCheckboxChange(id, true);
        } else {
            const {
                target: { checked }
            } = e;

            onCheckboxChange(id, checked);
        }
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    render() {
        const {
            value,
            disabled,
            showPartiallySelected,
            readOnly,
            clientId,
            variant,
            title,
            label,
            id,
            partial,
            checked,
            searchTerm,
            toggle
        } = this.props;
        // in case of simple select variant, there is no checkbox, so we need to handle the click via the node label
        // but not if the control is in readOnly or disabled state
        const shouldRegisterClickHandler = !readOnly && !disabled;
        const nodeLabelProps = {};

        if (shouldRegisterClickHandler) {
            nodeLabelProps.onClick = this.handleCheckboxChange;
        }

        const sharedProps = { id, value, checked: !!checked, disabled, readOnly, tabIndex: -1 };
        const labelClassNames = cx('onsolve-dropdown-tree__label pr-3', {
            'onsolve-dropdown-tree__label--disabled': disabled
        });

        return (
            <div className="onsolve-dropdown-tree__item" title={title || label} {...nodeLabelProps}>
                {toggle}
                <div className={labelClassNames}>
                    {variant !== 'simpleSelect' &&
                        (variant === 'radioSelect' ? (
                            <RadioButton
                                name={clientId}
                                className="radio-item"
                                onChange={this.handleCheckboxChange}
                                {...sharedProps}
                            />
                        ) : (
                            <Checkbox
                                name={id}
                                className={cx('checkbox-item', { 'simple-select': variant === 'simpleSelect' })}
                                intermediate={showPartiallySelected && partial}
                                onChange={this.handleCheckboxChange}
                                {...sharedProps}
                            />
                        ))}
                    <span className="onsolve-dropdown-tree__text">
                        <Highlighter search={searchTerm} className="text-primary font-weight-bold">
                            {label}
                        </Highlighter>
                    </span>
                </div>
            </div>
        );
    }
}

NodeLabel.propTypes = {
    actions: PropTypes.array,
    checked: PropTypes.bool,
    clientId: PropTypes.string,
    dataset: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string.isRequired,
    onCheckboxChange: PropTypes.func,
    partial: PropTypes.bool,
    readOnly: PropTypes.bool,
    searchTerm: PropTypes.string,
    showPartiallySelected: PropTypes.bool,
    title: PropTypes.string,
    toggle: PropTypes.node,
    value: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['multiSelect', 'simpleSelect', 'radioSelect', 'hierarchical'])
};

NodeLabel.displayName = 'NodeLabel';

export default NodeLabel;
