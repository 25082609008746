import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputFieldDivider = ({ className, symbol, disabled }) => {
    const rootClasses = classNames('onsolve-input-field__divider', className);

    return (
        <span className={rootClasses} disabled={disabled}>
            {symbol}
        </span>
    );
};

InputFieldDivider.propTypes = {
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies disabled status of Divider.
     */
    disabled: PropTypes.bool,
    /**
     Specifies a content of Divider.
     */
    symbol: PropTypes.string.isRequired
};

InputFieldDivider.displayName = 'InputFieldDivider';

export default InputFieldDivider;
