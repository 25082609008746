import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardImage = React.forwardRef((props, ref) => {
    const { alt, className, component: Component = 'div', src, ...other } = props;
    const imageClass = classnames('onsolve-card__image', className);

    if (src) {
        return (
            <Component className="onsolve-card__view">
                <img className={imageClass} src={src} ref={ref} alt={alt} {...other} />
            </Component>
        );
    }

    return <Component className={imageClass} src={src} ref={ref} {...other} />;
});

CardImage.propTypes = {
    /**
     Specifies an alt attribute of img tag.
     */
    alt: PropTypes.string,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies a tag of component that wraps the Image's content and will be returned by CardImage.
     */
    component: PropTypes.elementType,
    /**
     Specifies a src attribute of img tag.
     */
    src: PropTypes.string
};

CardImage.displayName = 'CardImage';

export default CardImage;
