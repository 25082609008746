import React, { Component } from 'react';
import PlaceholderContainer from './PlaceholderContainer';
import { PlaceholderConsumer } from './PlaceholderContext';

const withPlaceholder = props => WrappedComponent =>
    class WithPlaceholder extends Component {
        static displayName = 'WithPlaceholder';

        render() {
            return (
                <PlaceholderConsumer>
                    {context => (
                        <PlaceholderContainer
                            wrappedComponent={WrappedComponent}
                            hocProps={props}
                            context={context}
                            componentProps={{ ...this.props }}
                        />
                    )}
                </PlaceholderConsumer>
            );
        }
    };

export default withPlaceholder;
