import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keyCodes } from '../../common/constants';
import { paginatorVariants } from './constants';
import { PaginationInfo, PageNumberButtons, PageSizesDropdown, MobilePagination } from './Pagination';
import { normalizePaginatorSettings } from './utils';
import { DomUtils } from '../utils';

class Paginator extends Component {
    constructor(props) {
        super(props);
        this.footerRef = [];
        this.pageSizeRef = React.createRef();
        this.pageNoRef = React.createRef();
    }

    get pageSize() {
        const {
            page: { take },
            pageSize
        } = this.props;

        return take || pageSize;
    }

    get totalPages() {
        const { total } = this.props;

        return Math.ceil((total || 0) / this.pageSize);
    }

    get currentPage() {
        const {
            page: { skip }
        } = this.props;

        return Math.floor((skip || 0) / this.pageSize) + 1;
    }

    get start() {
        const {
            page: { skip },
            total
        } = this.props;

        return Math.min(skip + 1, total);
    }

    get end() {
        const {
            page: { skip },
            total
        } = this.props;

        return Math.min(skip + this.pageSize, total);
    }

    handlePageChange = (e, page) => {
        e.preventDefault();

        const { onPageChange } = this.props;

        if (page > 0 && page <= this.totalPages) {
            onPageChange(e, { skip: (page - 1) * this.pageSize, take: this.pageSize });
        }
    };

    handleKeyDown = e => {
        const keycode = DomUtils.getCharCode(e);
        const { LEFT, RIGHT } = keyCodes;

        if (keycode === LEFT || keycode === RIGHT) {
            let footerElementsList = [this.pageSizeRef.current];

            if (this.totalPages > 1) {
                const [prevPageElement, pageNumberButtonElement, nextPageElement] = this.pageNoRef.current.children;
                const pageNumberButtonElements = [...pageNumberButtonElement.children].map(value => value.children[0]);

                const isPrevPageDisabled = prevPageElement.classList.contains('invisible');
                const isNextPageDisabled = nextPageElement.classList.contains('invisible');

                footerElementsList = [
                    ...footerElementsList,
                    ...(!isPrevPageDisabled ? [prevPageElement] : []),
                    ...pageNumberButtonElements,
                    ...(!isNextPageDisabled ? [nextPageElement] : [])
                ];
            }

            let activeIndex = footerElementsList.indexOf(document.activeElement);

            if (activeIndex < footerElementsList.length) {
                activeIndex += keycode === LEFT ? -1 : 1;
                DomUtils.setFocus(footerElementsList[activeIndex]);
            }
        }
    };

    render() {
        const { total, pageable, paginationInfoRender, onPageChange, messages, variant } = this.props;
        const { buttonCount, info, pageSizes, pageButtons } = normalizePaginatorSettings(pageable);

        if (variant === paginatorVariants.MOBILE) {
            return (
                <MobilePagination
                    start={this.start}
                    paginationInfoRender={paginationInfoRender}
                    end={this.end}
                    pageSize={this.pageSize}
                    pageSizes={pageSizes}
                    pageSizeRef={this.pageSizeRef}
                    handleKeyDown={this.onKeyDown}
                    buttonCount={buttonCount}
                    currentPage={this.currentPage}
                    total={total}
                    totalPages={this.totalPages}
                    onNavigateButtonPageChange={this.handlePageChange}
                    onPageChange={onPageChange}
                    messages={messages}
                />
            );
        }

        return (
            <div className="onsolve-table__paginator" onKeyDown={this.handleKeyDown}>
                {pageSizes.length > 0 && total > 0 && (
                    <PageSizesDropdown
                        total={total}
                        start={this.start}
                        end={this.pageSize}
                        pageSizes={pageSizes}
                        onPageChange={onPageChange}
                        messages={messages}
                        pageSizeRef={this.pageSizeRef}
                    />
                )}
                {info && (
                    <PaginationInfo
                        start={this.start}
                        end={this.end}
                        total={total}
                        pageSizes={pageSizes}
                        paginationInfoRender={paginationInfoRender}
                        onPageChange={onPageChange}
                        messages={messages}
                    />
                )}
                {this.totalPages > 1 && (
                    <PageNumberButtons
                        currentPage={this.currentPage}
                        totalPages={this.totalPages}
                        buttonCount={buttonCount}
                        pageButtons={pageButtons}
                        onPageChange={this.handlePageChange}
                        pageNoRef={this.pageNoRef}
                        onKeyDown={this.handleKeyDown}
                    />
                )}
            </div>
        );
    }
}

Paginator.displayName = 'Paginator';

Paginator.propTypes = {
    messages: PropTypes.shape({
        pageSizelabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        pageSizeHelpText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        paginationInfoDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    }),
    onPageChange: PropTypes.func,
    page: PropTypes.shape({
        skip: PropTypes.number,
        take: PropTypes.number
    }),
    pageSize: PropTypes.number,
    pageSizes: PropTypes.instanceOf(Array),
    pageable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            buttonCount: PropTypes.number,
            info: PropTypes.bool
        })
    ]),
    paginationInfoRender: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    selectedCount: PropTypes.number,
    total: PropTypes.number,
    variant: PropTypes.oneOf(Object.values(paginatorVariants))
};

Paginator.defaultProps = {
    total: 0,
    pageSize: 10,
    variant: paginatorVariants.DESKTOP,
    pageSizes: [],
    page: {
        skip: 0,
        take: 10
    },
    onPageChange: () => {}
};

export default Paginator;
