import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextFieldPlaceholder extends Component {
    render() {
        const { className, classes, style, componentProps, placeholderProps } = this.props;
        const { maxLength, errorText, helpText } = componentProps;
        const { tooltip } = placeholderProps;

        const hideMessage = maxLength === undefined && errorText === undefined && helpText === undefined;

        const rootClasses = classNames('onsolve-placeholder', classes.root);
        const rowClasses = classNames('onsolve-placeholder__row', classes.row, className);

        const labelClasses = classNames('onsolve-placeholder__label', classes.label);
        const inputClasses = classNames('onsolve-placeholder__input', classes.input, rowClasses, {
            'onsolve-placeholder__input--with-message': !hideMessage
        });

        const inlineRowClasses = classNames(
            'onsolve-placeholder__row',
            'onsolve-placeholder__row--inline',
            classes.row,
            className
        );
        const titleClasses = classNames('onsolve-placeholder__label--title', inlineRowClasses);
        const tooltipClasses = classNames('onsolve-placeholder__label--tooltip', inlineRowClasses);

        return (
            <div className={rootClasses}>
                <div className={labelClasses}>
                    <span className={titleClasses} style={{ ...style }} />
                    {tooltip && <span className={tooltipClasses} style={{ ...style }} />}
                </div>
                <div className={inputClasses} style={{ ...style }} />
            </div>
        );
    }
}

TextFieldPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    componentProps: PropTypes.object,
    placeholderProps: PropTypes.object,
    style: PropTypes.object
};

TextFieldPlaceholder.defaultProps = {
    classes: {},
    componentProps: {},
    placeholderProps: {}
};

TextFieldPlaceholder.displayName = 'TextFieldPlaceholder';

export default TextFieldPlaceholder;
