import React from 'react';
import PropTypes from 'prop-types';

import BlurComponent from './BlurComponent';

const BlurWrapper = ({ isLoading, component, children, ...props }) =>
    isLoading ? React.createElement(component, props) : children;

BlurWrapper.displayName = 'BlurWrapper';

BlurWrapper.defaultProps = {
    component: BlurComponent
};

BlurWrapper.propTypes = {
    children: PropTypes.node,
    component: PropTypes.func,
    isLoading: PropTypes.bool
};

export default BlurWrapper;
