import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { shapes } from '../../common/constants';
import { EventsUtils, fp as _, ReactUtils } from '../utils';

import Header from './Header';
import TableBody from './TableBody';
import { Paginator } from '../Paginator';
import { ColumnResize } from './utils';
import { RowSelectionType } from './constants';

class Table extends Component {
    constructor(props) {
        super(props);

        this.headerRef = React.createRef();
        this.tableBodyRef = React.createRef();

        this.handleScrollLeft = this.handleScrollLeft.bind(this);
        this.handleColumnResize = this.handleColumnResize.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);

        this.columnResize = new ColumnResize();
        this.columnResize.onResize = this.handleColumnResize;
    }

    componentDidMount() {
        EventsUtils.bind(window, 'resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        EventsUtils.unbind(window, 'resize', this.handleWindowResize);
    }

    handleScrollLeft(_, data) {
        if (this.headerRef.current) {
            this.headerRef.current.setScrollPosition(data);
        }
    }

    handleColumnResize(_, data) {
        const { move } = data;

        this.handleWindowResize();

        if (move) {
            this.adjustTableWidth();
        } else {
            this.updateTableScroll();
        }
    }

    handleWindowResize() {
        if (this.headerRef.current) {
            this.headerRef.current.adjustHeaderRows();
        }

        if (this.tableBodyRef.current) {
            this.tableBodyRef.current.adjustTableRows();
        }
    }

    adjustTableWidth() {
        const { bodyColumnGroup } = this.columnResize;

        if (!bodyColumnGroup) {
            return;
        }

        const width = _.reduce(
            bodyColumnGroup.children,
            (acc, child) => {
                const value = parseInt(child.width);

                return isNaN(value) ? acc : acc + parseInt(value);
            },
            0
        );

        if (this.headerRef.current) {
            this.headerRef.current.setWidth(width);
        }

        if (this.tableBodyRef.current) {
            this.tableBodyRef.current.setWidth(width);
        }
    }

    updateTableScroll() {
        if (this.tableBodyRef.current) {
            this.tableBodyRef.current.updateScrollbars();
        }
    }

    scrollToFirstRowOnChange = () => {
        const {
            current: { tableScrollbarRefs }
        } = this.tableBodyRef;

        tableScrollbarRefs.forEach(scrollbar => {
            scrollbar.scrollTop(0);
        });
    };

    handlePageChange = (e, page) => {
        const { onPageChange } = this.props;

        if (onPageChange) {
            onPageChange(e, page);
        }

        if (this.tableBodyRef.current) {
            this.scrollToFirstRowOnChange();
        }
    };

    renderPaginator = () => {
        const { total, data, page, pageSize, paginationInfoRender, messages, pageable, paginatorRender } = this.props;

        const paginatorProps = {
            total: total || data.length,
            page,
            pageSize,
            pageable,
            paginationInfoRender,
            onPageChange: this.handlePageChange,
            messages
        };

        if (pageable === false) {
            return null;
        }

        if (paginatorRender) {
            return paginatorRender({ ...paginatorProps });
        }

        return <Paginator {...paginatorProps} />;
    };

    render() {
        const {
            className,
            children,
            data,
            showHeader,
            page,
            resizable,
            selectedField,
            sortable,
            sort,
            onSortChange,
            onHeaderSelectionChange,
            onSelectionChange,
            onRowClick,
            autoHeight,
            autoHeightMax,
            autoHeightMin,
            expandableField,
            expandableContent,
            onExpandChange,
            draggable,
            onDragEnd,
            draggableField,
            drag,
            noResultRender,
            showShadow,
            rowSelection,
            innerRef,
            hideTableBorder
        } = this.props;

        let dataProps = [];

        if (Array.isArray(data)) {
            dataProps = data;
        }

        const tableClasses = classNames('onsolve-table', className, {
            'onsolve-table--hide-table-border': hideTableBorder
        });
        const columns = Children.toArray(children).map(column => ({ ...column.props }));

        const columnTypes = {
            locked: { width: 0, cols: [], items: [] },
            unlocked: { width: 0, cols: [], items: [] }
        };

        columns.forEach((column, key) => {
            const columnType = columnTypes[column.locked ? 'locked' : 'unlocked'];
            const width = parseInt(column.width) || ColumnResize.MIN_COLUMN_WIDTH;

            columnType.width += width;
            columnType.cols.push(React.createElement('col', { key, width: column.width }));
            columnType.items.push(column);
        });

        this.columnResize.resizable = resizable;
        this.columnResize.columns = columnTypes.unlocked.items;

        const headerProps = {
            columnResize: this.columnResize,
            sortable,
            sort,
            onSortChange,
            onHeaderSelectionChange,
            rowSelection
        };
        const tableProps = {
            columnResize: this.columnResize,
            data: dataProps,
            page,
            onRowClick,
            onSelectionChange,
            autoHeight,
            autoHeightMax,
            autoHeightMin,
            expandableField,
            expandableContent,
            onExpandChange,
            draggable,
            onDragEnd,
            drag,
            showShadow,
            rowSelection
        };
        const commonProps = {
            lockedColumns: columnTypes.locked,
            columns: columnTypes.unlocked,
            selectedField,
            draggableField
        };

        const showNoResults = noResultRender && !data.length;

        return (
            <div className={tableClasses} ref={ReactUtils.setMultipleRefs(innerRef)} role="grid" aria-readonly="true">
                {showHeader && (
                    <Header {...commonProps} {...headerProps} ref={ref => ReactUtils.setRef(this.headerRef, ref)} />
                )}
                {showNoResults && noResultRender()}
                {!showNoResults && (
                    <TableBody
                        {...commonProps}
                        {...tableProps}
                        ref={ref => ReactUtils.setRef(this.tableBodyRef, ref)}
                        onScrollLeft={this.handleScrollLeft}
                    />
                )}
                {this.renderPaginator()}
            </div>
        );
    }
}

Table.propTypes = {
    /**
     Flag to set auto height to a table
     */
    autoHeight: PropTypes.bool,
    /**
     Set auto height and max height
     */
    autoHeightMax: PropTypes.string,
    /**
     Set auto height and min height
     */
    autoHeightMin: PropTypes.string,
    children: PropTypes.node.isRequired,
    /**
     Additional classnames of them main wrapper
     */
    className: PropTypes.string,
    /**
     Data to show
     */
    data: PropTypes.array,
    /**
     Settings for drag
     */
    drag: shapes.dragShape,
    /**
     Set draggable flag to the table
     */
    draggable: PropTypes.bool,
    /**
     A name of a draggable field (row in data)
     */
    draggableField: (props, propName) => {
        if (props[propName] && typeof props[propName] !== 'string') {
            return new Error('draggableField Should be of type STRING');
        }

        if (props[propName] && !props['draggable']) {
            return new Error('Draggable Table need draggable prop');
        }
    },
    /**
     Function to render expanded cell
     */
    expandableContent: (props, propName) => {
        if (props['expandableField'] && (!props[propName] || typeof props[propName] != 'function')) {
            return new Error('expandable table needs expandableContent');
        }
    },
    /**
     A name of expandable column
     */
    expandableField: PropTypes.string,
    /**
     Set a border around the table
     */
    hideTableBorder: PropTypes.bool,
    /**
     Set ref on the table
     */
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     Text content(messages) on the table
     */
    messages: PropTypes.shape({
        pageSizelabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        pageSizeHelpText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        paginationInfoDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    }),
    /**
     Render function for no-result-screen
     */
    noResultRender: PropTypes.func,
    /**
     Callback on row-dragging end
     */
    onDragEnd: (props, propName) => {
        if (props['draggable'] && (!props[propName] || typeof props[propName] !== 'function')) {
            return new Error('Draggable table needs onDragEnd func');
        }
    },
    /**
     Callback which will be invoked when expanded field is changed
     */
    onExpandChange: PropTypes.func,
    /**
     Callback on header-selection event
     */
    onHeaderSelectionChange: PropTypes.func,
    /**
     Callback on page-changing (if pagination is activated)
     */
    onPageChange: PropTypes.func,
    /**
     Callback on row click, row means item of an array
     */
    onRowClick: PropTypes.func,
    /**
     Callback will be called on selection
     */
    onSelectionChange: PropTypes.func,
    /**
     Callback will be called on sort changing
     */
    onSortChange: PropTypes.func,
    /**
     Data for pagination
     */
    page: PropTypes.shape({
        skip: PropTypes.number,
        take: PropTypes.number
    }),
    /**
     Page's count of items
     */
    pageSize: PropTypes.number,
    /**
     Settings for pagination, or just boolean
     */
    pageable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            buttonCount: PropTypes.number,
            info: PropTypes.bool,
            pageButtons: PropTypes.bool,
            pageSizes: PropTypes.arrayOf(Number)
        })
    ]),
    /**
     Render function for section of pagination info
     */
    paginationInfoRender: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    /**
     Render function for pagination
     */
    paginatorRender: PropTypes.func,
    /**
     Set ability to resize
     */
    resizable: PropTypes.bool,
    /**
     Set ability to select row / rows
     */
    rowSelection: PropTypes.oneOf([RowSelectionType.Multiple, RowSelectionType.Single]),
    /**
     Name of a field which responsible for selection
     */
    selectedField: PropTypes.string,
    /**
     Show / Hide header
     */
    showHeader: PropTypes.bool,
    /**
     Show / Hide shadows
     */
    showShadow: PropTypes.bool,
    /**
     Data to sort
     */
    sort: PropTypes.shape({
        field: PropTypes.string,
        dir: PropTypes.oneOf(['asc', 'desc'])
    }),
    /**
     Activate sortable feature
     */
    sortable: PropTypes.bool,
    /**
     Item-count
     */
    total: PropTypes.number
};

Table.defaultProps = {
    showHeader: true,
    showShadow: true,
    hideTableBorder: false,
    total: 0,
    data: [],
    sortable: false,
    resizable: false,
    draggable: false,
    rowSelection: RowSelectionType.Multiple
};

Table.displayName = 'Table';

export default Table;
