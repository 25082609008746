import React, { createRef, forwardRef, Suspense, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import SketchPicker from 'react-color/lib/Sketch';

import { EventsUtils, DomUtils } from '../../utils';
import { IconButton } from '../../Button';
import { Typography } from '../../Typography';
import { LoadingSpinner } from '../../Loading';

const FontColorControls = forwardRef(
    ({ color, isColorPickerOpen, onColorChange, onToggleColorPicker, disabled }, ref) => {
        const colorPickerRef = createRef();

        const handleDocumentClick = useCallback(
            e => {
                const result =
                    DomUtils.isInDOMSubtree(e.target, ref.current) ||
                    DomUtils.isInDOMSubtree(e.target, colorPickerRef.current);

                if (isColorPickerOpen && !result) {
                    onToggleColorPicker();
                }
            },
            [isColorPickerOpen, colorPickerRef, onToggleColorPicker, ref]
        );

        useEffect(() => {
            if (isColorPickerOpen) {
                EventsUtils.bind(document, 'click', handleDocumentClick, true);
            }

            return () => EventsUtils.unbind(document, 'click', handleDocumentClick, true);
        }, [isColorPickerOpen, handleDocumentClick]);

        return (
            <div className="d-inline" key="fontColor">
                <IconButton
                    className="d-inline-flex flex-column"
                    onClick={onToggleColorPicker}
                    ref={ref}
                    disabled={disabled}
                >
                    <Typography className="mb-0 font-weight-bold" variant="p16">
                        A
                    </Typography>
                    <div style={{ width: '20px', height: '4px', backgroundColor: color }} />
                </IconButton>
                {isColorPickerOpen && (
                    <div className="position-absolute" style={{ zIndex: '2' }} ref={colorPickerRef}>
                        <Suspense
                            fallback={<LoadingSpinner color="primary" className="onsolve-loading__icon--small" />}
                        >
                            <SketchPicker color={color} onChange={onColorChange} />
                        </Suspense>
                    </div>
                )}
            </div>
        );
    }
);

FontColorControls.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isColorPickerOpen: PropTypes.bool,
    onColorChange: PropTypes.func,
    onToggleColorPicker: PropTypes.func
};

FontColorControls.defaultProps = {
    disabled: false
};

FontColorControls.displayName = 'FontColorControls';

export default FontColorControls;
