import React from 'react';
import SvgIcon from './SvgIcon';

const Subscript = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M14.362 12c1.05 0 1.421.447 1.421 1.083 0 .636-.44 1.056-.933 1.53l-.765.73h.995c.067 0 .094-.013.108-.08l.095-.535H16V16h-3.127v-.63l.907-.886c.684-.663.975-.934.975-1.32 0-.25-.122-.433-.48-.433-.373 0-.529.122-.529.603l-.92-.082c.007-1.076.825-1.252 1.536-1.252zM13 0v4h-1l-.724-1.447A1 1 0 0010.382 2H8v9.28a1 1 0 00.684.948L11 13v1H3v-1l2.316-.772A1 1 0 006 11.279V2H3.618a1 1 0 00-.894.553L2 4H1V0h12z"
        />
    </SvgIcon>
);

Subscript.displayName = 'SubscriptIcon';

export default Subscript;
