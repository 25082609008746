import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../Button';

import { componentsTranslations } from '../../translations';
import withCancelAction from './withCancelAction';

const CancelRemoveActions = ({ buttonSize, disabled, onDelete }) => (
    <Button className="text-uppercase" color="primary" size={buttonSize} disabled={disabled} onClick={onDelete}>
        <FormattedMessage {...componentsTranslations.ng_generic_remove} />
    </Button>
);

CancelRemoveActions.propTypes = {
    buttonSize: PropTypes.string,
    disabled: PropTypes.bool,
    onDelete: PropTypes.func
};

CancelRemoveActions.displayName = 'CancelRemoveActions';

export default withCancelAction(CancelRemoveActions);
