import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AddIcon } from '../Icons';
import { IconButton } from '../Button';
import { TextField } from '../TextField';
import { keyCodes } from '../../common/constants';
import { componentsTranslations } from '../translations';

const TagEditorInput = props => {
    const { disabled, error, intl, onAdd, onChange, placeholder, title, value = '' } = props;

    const handleChange = useCallback(event => onChange(event.target.value), [onChange]);

    const handleKeyDown = useCallback(
        event => {
            if (event.keyCode == keyCodes.ENTER_KEY) {
                event.preventDefault();
                onAdd();
            }
        },
        [onAdd]
    );

    const valueIsEmpty = value.trim().length === 0;

    return (
        <div className="onsolve-tag-editor__input">
            <TextField
                name="tag-name-input"
                className="onsolve-tag-editor__input-field"
                autoComplete="off"
                disabled={disabled}
                value={value}
                onChange={handleChange}
                onSubmit={onAdd}
                onKeyDown={handleKeyDown}
                error={!!error}
                errorText={error}
                showExclamation={valueIsEmpty}
                placeholder={placeholder}
                label={title}
                inputIcon={
                    <IconButton
                        aria-label={intl.formatMessage(componentsTranslations.ng_generic_add)}
                        disabled={valueIsEmpty || disabled}
                        onClick={onAdd}
                    >
                        <AddIcon />
                    </IconButton>
                }
            />
        </div>
    );
};

TagEditorInput.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.node,
    intl: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.node,
    title: PropTypes.node,
    value: PropTypes.string
};

TagEditorInput.displayName = 'TagEditorInput';

export default TagEditorInput;
