import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../Button';

import { componentsTranslations } from '../../translations';
import withCancelAction from './withCancelAction';

const CancelAddActions = ({ buttonSize, disabled, onAdd }) => (
    <Button color="primary" className="text-uppercase" size={buttonSize} disabled={disabled} onClick={onAdd}>
        <FormattedMessage {...componentsTranslations.ng_generic_add} />
    </Button>
);

CancelAddActions.propTypes = {
    buttonSize: PropTypes.string,
    disabled: PropTypes.bool,
    onAdd: PropTypes.func
};

CancelAddActions.displayName = 'CancelAddActions';

export default withCancelAction(CancelAddActions);
