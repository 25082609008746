import React from 'react';

import SvgIcon from './SvgIcon';

const Close = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M14.7,1.3 C14.3,0.9 13.7,0.9 13.3,1.3 L8,6.6 L2.7,1.3 C2.3,0.9 1.7,0.9 1.3,1.3 C0.9,1.7 0.9,2.3 1.3,2.7 L6.6,8 L1.3,13.3 C0.9,13.7 0.9,14.3 1.3,14.7 C1.5,14.9 1.7,15 2,15 C2.3,15 2.5,14.9 2.7,14.7 L8,9.4 L13.3,14.7 C13.5,14.9 13.8,15 14,15 C14.2,15 14.5,14.9 14.7,14.7 C15.1,14.3 15.1,13.7 14.7,13.3 L9.4,8 L14.7,2.7 C15.1,2.3 15.1,1.7 14.7,1.3 Z"
                stroke="none"
            />
        </SvgIcon>
    );
};

Close.displayName = 'Close';

export default Close;
