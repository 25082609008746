import React from 'react';

import SvgIcon from './SvgIcon';

const Direction = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-80.000000, -358.000000)" fillRule="nonzero">
                <g transform="translate(80.000000, 358.000000)">
                    <path d="M8.33333333,1.97312505 L15.866927,8 L8.33333333,14.0268749 L8.33333333,10.3333333 L3.66666667,10.3333333 L3.66666667,5.66666667 L8.33333333,5.66666667 L8.33333333,1.97312505 Z M9,3.36 L9,6.33333333 L4.33333333,6.33333333 L4.33333333,9.66666667 L9,9.66666667 L9,12.6393333 L14.7993333,8 L9,3.36 Z M1.66666667,5.66666667 L1.66666667,10.3333333 L1,10.3333333 L1,5.66666667 L1.66666667,5.66666667 Z" />
                </g>
            </g>
        </g>
    </SvgIcon>
);

Direction.displayName = 'DirectionIcon';

export default Direction;
