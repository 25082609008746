import React from 'react';
import SvgIcon from './SvgIcon';

const Zoom = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M6.6,1.2c3,0,5.4,2.4,5.4,5.4c0,3-2.4,5.4-5.4,5.4S1.2,9.5,1.2,6.6C1.2,3.6,3.6,1.2,6.6,1.2 M6.6,0C3,0,0.1,3,0.1,6.6 c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5C13.1,3,10.2,0,6.6,0L6.6,0z" />
            <path d="M15.4,16c-0.1,0-0.3-0.1-0.4-0.2l-2.9-2.9c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l2.9,2.9c0.2,0.2,0.2,0.6,0,0.8 C15.7,15.9,15.6,16,15.4,16z" />
        </g>
    </SvgIcon>
);

Zoom.displayName = 'ZoomIcon';

export default Zoom;
