import React from 'react';

import SvgIcon from './SvgIcon';

const MailboxIcon = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M 12.609375 4.363281 L 5.605469 4.363281 L 5.605469 8.726562 L 4.203125 8.726562 L 4.203125 2.910156 L 8.40625 2.910156 L 8.40625 0 L 2.800781 0 L 2.800781 4.363281 L 1.402344 4.363281 C 0.628906 4.363281 0 5.019531 0 5.816406 L 0 14.546875 C 0 15.34375 0.628906 16 1.402344 16 L 12.609375 16 C 13.382812 16 14.011719 15.34375 14.011719 14.546875 L 14.011719 5.816406 C 14.011719 5.019531 13.382812 4.363281 12.609375 4.363281 Z M 12.609375 4.363281"
            />
        </SvgIcon>
    );
};

MailboxIcon.displayName = 'MailboxIcon';

export default MailboxIcon;
