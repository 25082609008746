import React from 'react';

const BlurLoaderContext = React.createContext({});

export const BlurLoaderContextProvider = BlurLoaderContext.Provider;
export const BlurLoaderContextConsumer = BlurLoaderContext.Consumer;

const I18nBlurLoaderContext = React.createContext({});

export const I18nBlurLoaderProvider = I18nBlurLoaderContext.Provider;
export const I18nBlurLoaderConsumer = I18nBlurLoaderContext.Consumer;
