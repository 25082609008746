import { DomUtils } from '../../utils';

let currentElement;

function styleToString(style) {
    const styleNames = Array.prototype.slice.apply(style);

    return styleNames.map(name => `${name}: ${style.getPropertyValue(name)};`).join('');
}

function createCloneElement(originalElement) {
    if (!currentElement) {
        currentElement = document.createElement(originalElement.tagName);
        currentElement.setAttribute('aria-hidden', 'true');
        document.body.appendChild(currentElement);
    }

    const elementStyles = window.getComputedStyle(originalElement);
    const originCSS = styleToString(elementStyles);

    currentElement.setAttribute('style', originCSS);
    currentElement.style.position = 'fixed';
    currentElement.style.left = '0';
    currentElement.style.height = 'auto';
    currentElement.style.minHeight = 'auto';
    currentElement.style.maxHeight = 'auto';
    currentElement.style.top = '-999999px';
    currentElement.style.zIndex = '-1000';
    currentElement.style.textOverflow = 'clip';
    currentElement.style.whiteSpace = 'normal';
    currentElement.style.webkitLineClamp = 'none';

    return currentElement;
}

function createEllipsisString(originalElement, originalText, ellipsis, lines, callback) {
    if (lines === 1) {
        const isOverflow = originalElement.scrollWidth > originalElement.offsetWidth;

        if (callback) {
            callback(isOverflow);
        }

        return originalText;
    }

    const lineHeight = DomUtils.getLineHeight(originalElement);
    const maxHeight = lineHeight * lines + 1;
    const ellipsisLength = ellipsis.length;

    if (
        originalElement.scrollHeight <= maxHeight &&
        originalElement.innerText.toLowerCase() === originalText.toLowerCase()
    ) {
        return originalElement.innerText;
    }

    const element = createCloneElement(originalElement);

    element.innerText = originalText;

    let start = 0;
    let middle = 0;
    let end = (originalText && originalText.length) || 0;
    const isOverflow = element.offsetHeight > maxHeight;

    if (isOverflow) {
        while (start <= end) {
            middle = Math.floor((start + end) / 2);
            element.innerText = originalText.slice(0, middle).trimRight() + ellipsis;

            if (element.offsetHeight <= maxHeight) {
                start = middle + 1;
            } else {
                end = middle - 1;
            }
        }
        element.innerText = originalText.slice(0, start - ellipsisLength).trimRight() + ellipsis;
    }

    callback && callback(isOverflow);

    return element.innerText;
}

export default createEllipsisString;
