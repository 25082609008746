import React from 'react';
import SvgIcon from './SvgIcon';

const Phone = props => (
    <SvgIcon {...props}>
        <path stroke="none" d="M13.2,16C5.9,16,0,10.1,0,2.8L2.8,0l3.9,3.9L3.9,6.6l5.5,5.5l2.8-2.8l3.9,3.9L13.2,16z" />
    </SvgIcon>
);

Phone.displayName = 'PhoneIcon';

export default Phone;
