import React from 'react';

import SvgIcon from './SvgIcon';

const DownloadOutline = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path
                d="M14.5625,9.6875 C14.8386424,9.6875 15.0625,9.91135763 15.0625,10.1875 L15.0625,13.6875 C15.0625,14.4466424 14.4466424,15.0625 13.6875,15.0625 L2.3125,15.0625 C1.55335763,15.0625 0.9375,14.4466424 0.9375,13.6875 L0.9375,10.1875 C0.9375,9.91135763 1.16135763,9.6875 1.4375,9.6875 C1.71364237,9.6875 1.9375,9.91135763 1.9375,10.1875 L1.9375,13.6875 C1.9375,13.8943576 2.10564237,14.0625 2.3125,14.0625 L13.6875,14.0625 C13.8943576,14.0625 14.0625,13.8943576 14.0625,13.6875 L14.0625,10.1875 C14.0625,9.91135763 14.2863576,9.6875 14.5625,9.6875 Z M9.3125,0.9375 C9.58864237,0.9375 9.8125,1.16135763 9.8125,1.4375 L9.8125,6.1875 L11.9375,6.1875 C12.3703855,6.1875 12.5987322,6.7002211 12.3091471,7.02198237 L8.37164707,11.3969824 C8.17302591,11.6176725 7.82697409,11.6176725 7.62835293,11.3969824 L3.69085293,7.02198237 C3.40126779,6.7002211 3.62961449,6.1875 4.0625,6.1875 L6.1875,6.1875 L6.1875,1.4375 C6.1875,1.16135763 6.41135763,0.9375 6.6875,0.9375 L9.3125,0.9375 Z M8.8125,1.9375 L7.1875,1.9375 L7.1875,6.6875 C7.1875,6.96364237 6.96364237,7.1875 6.6875,7.1875 L5.1851812,7.1875 L8,10.3150764 L10.8148188,7.1875 L9.3125,7.1875 C9.03635763,7.1875 8.8125,6.96364237 8.8125,6.6875 L8.8125,1.9375 Z"
                id="path-1"
            />
        </g>
    </SvgIcon>
);

DownloadOutline.displayName = 'DownloadOutlineIcon';

export default DownloadOutline;
