/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactUtils } from '../utils';

const MenuItem = React.forwardRef(function MenuItem(props, ref) {
    const {
        id,
        autoFocus,
        children,
        className,
        disabled,
        item,
        order,
        selected: selectedProps,
        tabIndex: tabIndexProp,
        value,
        variant,
        role,
        onClick,
        onKeyDown
    } = props;

    const itemRef = React.useRef(null);

    useLayoutEffect(() => {
        if (autoFocus && itemRef.current) {
            itemRef.current.focus();
        }
    }, [autoFocus]);

    const handleClick = useCallback(
        e => {
            if (disabled) {
                return;
            }

            if (onClick) {
                onClick(e, value, { item, order });
            }
        },
        [disabled, value, item, order]
    );

    const handleKeyDown = useCallback(
        e => {
            if (disabled) {
                return;
            }

            if (onKeyDown) {
                onKeyDown(e, value, { item, order });
            }
        },
        [disabled, value, item, order]
    );

    //https://reactjs.org/docs/hooks-faq.html
    const menuListRef = useCallback(element => {
        itemRef.current = element;
    }, []);

    const tabIndex = ['header', 'divider'].includes(variant) || disabled ? -1 : tabIndexProp;
    const selected = variant === 'standard' && selectedProps;

    const rootClasses = classNames('onsolve-menu__item', className, {
        'onsolve-menu__item--selected': selected,
        'onsolve-menu__item--divider': variant === 'divider',
        'onsolve-menu__item--header': variant === 'header',
        'onsolve-menu__item--disabled': disabled
    });

    const ariaProps = {
        ['aria-disabled']: disabled
    };

    if (variant !== 'divider') {
        ariaProps['aria-selected'] = selected;
    }

    return (
        <li
            id={id}
            role={role}
            {...ariaProps}
            tabIndex={tabIndex}
            className={rootClasses}
            ref={ReactUtils.setMultipleRefs(menuListRef, ref)}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            {children}
        </li>
    );
});

MenuItem.propTypes = {
    /**
    Tells the browser the button is being acted on
    */
    autoFocus: PropTypes.bool,
    /**
    The display content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies an menu item is disabled.
     */
    disabled: PropTypes.bool,
    /**
    Specifies an id.
    */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    Specifies an item.
    */
    item: PropTypes.object,
    /**
    Callback function on node click event.
    */
    onClick: PropTypes.func,
    /**
    Callback function on node keydown event.
    */
    onKeyDown: PropTypes.func,
    /**
    Specifies an order.
    */
    order: PropTypes.number,
    /**
    Specifies a role.
    */
    role: PropTypes.string,
    /**
    Specifies a selected.
    */
    selected: PropTypes.bool,
    /**
    Specifies a tabIndex.
    */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    Specifies a value.
    */
    value: PropTypes.any,
    /**
    Specifies variant of the MenuItem.
    */
    variant: PropTypes.oneOf(['standard', 'divider', 'header'])
};

MenuItem.defaultProps = {
    variant: 'standard',
    tabIndex: 0,
    role: 'menuitem'
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
