import React from 'react';
import SvgIcon from './SvgIcon';

const Hamburger = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M15.3,8.7H0.7C0.3,8.7,0,8.4,0,8s0.3-0.7,0.7-0.7h14.7C15.7,7.3,16,7.6,16,8S15.7,8.7,15.3,8.7z" />
            <path d="M15.3,4H0.7C0.3,4,0,3.7,0,3.3s0.3-0.7,0.7-0.7h14.7C15.7,2.7,16,3,16,3.3S15.7,4,15.3,4z" />
            <path d="M8,13.3H0.7C0.3,13.3,0,13,0,12.7C0,12.3,0.3,12,0.7,12H8c0.4,0,0.7,0.3,0.7,0.7C8.7,13,8.4,13.3,8,13.3z" />
        </g>
    </SvgIcon>
);

Hamburger.displayName = 'HamburgerIcon';

export default Hamburger;
