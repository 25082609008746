import React from 'react';

import SvgIcon from './SvgIcon';

const Timer = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 Z M8,1.99526066 C4.68367404,1.99526066 1.99526066,4.68367404 1.99526066,8 C1.99526066,11.316326 4.68367404,14.0047393 8,14.0047393 C11.316326,14.0047393 14.0047393,11.316326 14.0047393,8 C14.0047393,4.68367404 11.316326,1.99526066 8,1.99526066 Z M8,3.78672986 C8.27483364,3.78672986 8.49763033,4.00952655 8.49763033,4.28436019 L8.49763033,7.50236967 L11.7156398,7.50236967 C11.9904735,7.50236967 12.2132701,7.72516636 12.2132701,8 C12.2132701,8.27483364 11.9904735,8.49763033 11.7156398,8.49763033 L8,8.49763033 C7.72516636,8.49763033 7.50236967,8.27483364 7.50236967,8 L7.50236967,4.28436019 C7.50236967,4.00952655 7.72516636,3.78672986 8,3.78672986 Z" />
        </g>
    </SvgIcon>
);

Timer.displayName = 'TimerIcon';

export default Timer;
