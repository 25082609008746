import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TimerIcon } from '../../Icons';
import { EventsUtils } from '../../utils';
import {
    InputFieldContainer,
    InputFieldControl,
    InputFieldGroup,
    InputFieldLabel,
    InputFieldMessage
} from '../../InputBase';
import { TimeCounterInputText } from '../core';

const defaultPattern = {
    hours: '([0-9]{2})',
    minutes: '([0-5][0-9])',
    seconds: '([0-5][0-9])'
};

class TimeCounterField extends Component {
    constructor(props) {
        super(props);

        this.timeCounterRef = React.createRef();

        this.hoursInputRef = React.createRef();
        this.minutesInputRef = React.createRef();
        this.secondsInputRef = React.createRef();

        this.state = {
            isValidValue: false,
            active: false,
            value: undefined
        };
    }

    componentDidMount() {
        this.registerTimeCounterFocusOut(true);
    }

    componentWillUnmount() {
        this.registerTimeCounterFocusOut(false);
    }

    registerTimeCounterFocusOut = subscribe => {
        const methodName = subscribe ? 'bind' : 'unbind';

        EventsUtils[methodName](this.timeCounterRef.current, 'focusout', this.handleTimeCounterFocusOut, true);
    };

    handleTimeCounterFocusOut = ({ relatedTarget }) => {
        if (
            [this.hoursInputRef, this.minutesInputRef, this.secondsInputRef].every(
                ref => !relatedTarget || ref.current !== relatedTarget
            )
        ) {
            this.handleOutput();
        }
    };

    handleChange = values => {
        this.setState({ ...values }, () => {
            const { value } = this.state;
            const { onChange } = this.props;

            if (onChange) {
                onChange(value);
            }
        });
    };

    handleFocus = () => {
        this.setState({ active: true });
    };

    handleBlur = () => {
        this.setState({ active: false });
    };

    handleOutput = () => {
        const { isValidValue, value } = this.state;
        const { onError } = this.props;

        if (isValidValue || value === undefined) {
            return;
        }

        if (onError) {
            onError(null);
        }
    };

    render() {
        const {
            innerRef,
            className,
            name,
            label,
            disabled,
            placeholder,
            format,
            divider,
            variant,
            tabIndex,
            showExclamation,
            showLabelExclamation,
            showInputIcon,
            error,
            inline,
            pattern,
            value,
            ...others
        } = this.props;
        const { active } = this.state;

        const inputProps = {
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur
        };

        const rootClasses = classNames('onsolve-time-counter-input', className);
        const groupClasses = classNames('onsolve-time-counter-input__group', {
            'onsolve-time-counter-input__group--row': inline
        });

        return (
            <div className={rootClasses}>
                <InputFieldControl ref={this.timeCounterRef}>
                    <InputFieldGroup className={groupClasses}>
                        {label && (
                            <InputFieldLabel name={name} error={showLabelExclamation && error}>
                                {label}
                            </InputFieldLabel>
                        )}
                        <InputFieldContainer
                            tabIndex={tabIndex}
                            variant={variant}
                            error={error}
                            active={active}
                            showExclamation={showExclamation}
                            ref={innerRef}
                        >
                            <TimeCounterInputText
                                value={value}
                                refs={{
                                    hours: this.hoursInputRef,
                                    minutes: this.minutesInputRef,
                                    seconds: this.secondsInputRef
                                }}
                                pattern={{
                                    ...defaultPattern,
                                    ...pattern
                                }}
                                placeholder={placeholder}
                                divider={divider}
                                disabled={disabled}
                                format={format}
                                {...inputProps}
                            />

                            {showInputIcon && (
                                <div className="onsolve-time-counter-input__icon">
                                    <TimerIcon color="secondary" />
                                </div>
                            )}
                        </InputFieldContainer>
                    </InputFieldGroup>
                    <InputFieldMessage error={error} {...others} />
                </InputFieldControl>
            </div>
        );
    }
}

TimeCounterField.propTypes = {
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies the input fields as a disabled.
    */
    disabled: PropTypes.bool,
    /**
    Specifies the character as a divider between hours, minutes and seconds.
    */
    divider: PropTypes.string,
    /**
    Specifies if the error should be showed.
    */
    error: PropTypes.bool,
    /**
    Specifies the type of entered value e.g. hours, minutes, seconds or only hours and minutes.
    */
    format: PropTypes.arrayOf(PropTypes.string),
    /**
    Specifies the label position towards input element.
    */
    inline: PropTypes.bool,
    /**
     * @ignore
     */
    innerRef: PropTypes.object,
    /**
    Add a label for the component.
    */
    label: PropTypes.node,
    /**
    Specifies the name for name attribute of an input element.
    */
    name: PropTypes.string,
    /**
    Callback function fired when the value is changed.
    */
    onChange: PropTypes.func,
    /**
    Callback function fired when the entered value is not valid.
    */
    onError: PropTypes.func,
    /**
    Specifies one of predefined or custom patterns to restrict input values.
    */
    pattern: PropTypes.object,
    /**
    The short hint displayed in the input before the user enters a value.
    */
    placeholder: PropTypes.shape({ hours: PropTypes.string, minutes: PropTypes.string, seconds: PropTypes.string }),
    /**
    Specifies whether to show exclamation icon at the end.
    */
    showExclamation: PropTypes.bool,
    /**
    Specifies whether to show input icon at the end.
    */
    showInputIcon: PropTypes.bool,
    /**
    Specifies whether to show exclamation icon at the end of label text.
    */
    showLabelExclamation: PropTypes.bool,
    /**
     * @ignore
     */
    tabIndex: PropTypes.number,
    /**
    Entered value of the component.
    */
    value: PropTypes.number,
    /**
    Specifies one of variant of text field appearance.
    */
    variant: PropTypes.oneOf(['default', 'standard', 'naked', 'outlined'])
};

TimeCounterField.defaultProps = {
    divider: ':',
    format: ['hours', 'minutes', 'seconds'],
    placeholder: { hours: 'HH', minutes: 'MM', seconds: 'SS' },
    showInputIcon: true,
    disabled: false
};

TimeCounterField.displayName = 'TimeCounterField';

export default TimeCounterField;
