import React from 'react';
import SvgIcon from './SvgIcon';
const FilePdf = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path fill="#870000" d="M14.957 8.667h-13.913l-1.043-1.333 1.391-1.333h13.217l1.391 1.333z" />
                <path
                    fill="#e6e6e6"
                    d="M13.965 16h-11.93c-0.388 0-0.702-0.311-0.702-0.696v-14.609c0-0.384 0.314-0.696 0.702-0.696h8.615l4.017 4v11.304c0 0.384-0.314 0.696-0.702 0.696z"
                />
                <path fill="#b3b3b3" d="M10.667 0v3.333c0 0.368 0.298 0.667 0.667 0.667h3.333l-4-4z" />
                <path
                    fill="#bd0100"
                    d="M15.304 13.333h-14.609c-0.384 0-0.696-0.298-0.696-0.667v-5.333h16v5.333c0 0.368-0.311 0.667-0.696 0.667z"
                />
                <path
                    fill="#fff"
                    d="M6.667 9.848c0 0.298-0.091 0.528-0.272 0.69s-0.439 0.243-0.774 0.243h-0.209v0.885h-0.745v-2.667h0.954c0.348 0 0.61 0.074 0.784 0.22s0.262 0.357 0.262 0.628zM5.411 10.195h0.136c0.112 0 0.201-0.031 0.267-0.092s0.099-0.145 0.099-0.251c0-0.18-0.103-0.27-0.309-0.27h-0.192v0.613z"
                />
                <path
                    fill="#fff"
                    d="M9.333 10.277c0 0.445-0.108 0.788-0.325 1.029s-0.521 0.361-0.913 0.361h-0.762v-2.667h0.815c0.378 0 0.67 0.109 0.876 0.328s0.309 0.535 0.309 0.948zM8.673 10.302c0-0.245-0.043-0.425-0.128-0.544s-0.215-0.177-0.389-0.177h-0.185v1.493h0.142c0.194 0 0.335-0.064 0.425-0.191s0.135-0.321 0.135-0.582z"
                />
                <path fill="#fff" d="M10.6 11.667h-0.6v-2.667h1.333v0.578h-0.733v0.509h0.676v0.578h-0.676v1.002z" />
            </g>
        </SvgIcon>
    );
};

FilePdf.displayName = 'FilePdfIcon';

export default FilePdf;
