import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { IconButton } from '../../../Button';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../Icons';

class PageNavigationButton extends PureComponent {
    render() {
        const { type, hidden, onPageChange, onKeyDown, ...other } = this.props;
        const rootClasses = classNames(
            { invisible: hidden },
            `onsolve-table__paginator-pages--navigation-button-${type}`
        );
        const IconComponent = type === 'prev' ? ArrowLeftIcon : ArrowRightIcon;

        return (
            <IconButton
                className={rootClasses}
                onClick={onPageChange}
                onKeyDown={onKeyDown}
                aria-hidden={hidden}
                {...other}
            >
                <IconComponent color="secondary" />
            </IconButton>
        );
    }
}

PageNavigationButton.displayName = 'PageNavigationButton';

PageNavigationButton.propTypes = {
    hidden: PropTypes.bool,
    onKeyDown: PropTypes.func,
    onPageChange: PropTypes.func,
    type: PropTypes.oneOf(['prev', 'next'])
};

PageNavigationButton.defaultProps = {
    hidden: false,
    onPageChange: f => f
};

export default PageNavigationButton;
