import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AlertModal } from '../../../Modals';
import { CancelAddActions } from '../../../Modals/Footer';
import { componentsTranslations } from '../../../translations';

const LinkModal = ({ children, isOpen, onCancel, onSubmit }) => {
    const actions = <CancelAddActions onAdd={onSubmit} onCancel={onCancel} />;
    const title = <FormattedMessage {...componentsTranslations.ng_wysiwyg_editor_link_modal_add} />;

    return (
        <AlertModal isOpen={isOpen} onClose={onCancel} size="sm" title={title} message={children} actions={actions} />
    );
};

LinkModal.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

LinkModal.displayName = 'LinkModal';

export default LinkModal;
