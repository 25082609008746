import React from 'react';
import PropTypes from 'prop-types';

import { paginatorVariants } from '../../constants';
import { PaginationInfo } from '../Information';
import { PageSizesDropdown, PageNumberButtons } from '../Pages';

const MobilePagination = ({
    totalPages,
    start,
    end,
    total,
    pageSizes,
    onPageChange,
    messages,
    currentPage,
    buttonCount,
    onNavigateButtonPageChange,
    pageSizeRef,
    pageSize,
    handleKeyDown,
    paginationInfoRender
}) => (
    <div className="onsolve-table__paginator-mobile">
        <PaginationInfo
            start={start}
            end={end}
            total={total}
            variant={paginatorVariants.MOBILE}
            pageSizes={pageSizes}
            paginationInfoRender={paginationInfoRender}
            onPageChange={onPageChange}
            messages={messages}
        />
        {totalPages > 1 && (
            <PageNumberButtons
                onKeyDown={handleKeyDown}
                buttonCount={buttonCount}
                currentPage={currentPage}
                totalPages={totalPages}
                pageButtons={false}
                onPageChange={onNavigateButtonPageChange}
            />
        )}
        {pageSizes.length > 0 && total > 0 && (
            <PageSizesDropdown
                total={total}
                start={start}
                end={pageSize}
                variant={paginatorVariants.MOBILE}
                pageSizes={pageSizes}
                onPageChange={onPageChange}
                pageSizeRef={pageSizeRef}
                messages={messages}
            />
        )}
    </div>
);

MobilePagination.displayName = 'MobilePagination';

MobilePagination.propTypes = {
    buttonCount: PropTypes.number,
    currentPage: PropTypes.number,
    end: PropTypes.number.isRequired,
    handleKeyDown: PropTypes.func,
    messages: PropTypes.array,
    onNavigateButtonPageChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageSizeRef: PropTypes.object.isRequired,
    pageSizes: PropTypes.array.isRequired,
    paginationInfoRender: PropTypes.func,
    start: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired
};

export default MobilePagination;
