import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';

function Accordion({ children, isOpen, onToggle, ...other }) {
    const [isOpenState, setOpenState] = useState(isOpen);

    useEffect(() => {
        setOpenState(isOpen);
    }, [isOpen]);

    const handleToggle = id => {
        setOpenState(!isOpenState);
        if (onToggle) {
            onToggle(id);
        }
    };
    const props = {
        isOpen: isOpenState,
        onToggle: handleToggle,
        ...other
    };

    return <AccordionItem {...props}>{children}</AccordionItem>;
}

Accordion.propTypes = {
    body: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconComponent: PropTypes.node,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.node,
    variant: PropTypes.oneOf(['normal', 'cardOnly'])
};

Accordion.displayName = 'Accordion';

export default Accordion;
