const makePage = (number, text, isActive = false) => ({
    number: number,
    text: text,
    active: isActive
});

const getPages = ({ buttonCount, currentPage, totalPages }) => {
    let pages = [];

    let startPage = 1,
        endPage = totalPages;
    let isMaxSized = buttonCount < totalPages;

    if (isMaxSized) {
        startPage = (Math.ceil(currentPage / buttonCount) - 1) * buttonCount + 1;

        if (startPage + buttonCount - 1 > totalPages) {
            startPage = totalPages - (buttonCount - 1);
        } else {
            endPage = Math.min(startPage + buttonCount - 1, totalPages);
        }
    }

    for (let number = startPage; number <= endPage; number++) {
        pages.push(makePage(number, number, number === currentPage));
    }

    return pages;
};

export default getPages;
