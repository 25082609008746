import React from 'react';

import SvgIcon from './SvgIcon';

const FileOutbox = props => (
    <SvgIcon {...props}>
        <path
            d="M3.34545455,3.46714852 L3.34545455,4.00253246 L2.56746667,4.00214309 L0.636,9.81833333 L5.45454545,9.81874898 L5.4544,11.9354624 L10.5450667,11.9354624 L10.5454545,9.81874898 L15.362,9.81833333 L13.432,4.00214309 L12.6545455,4.00253246 L12.6545455,3.46714852 L13.8164449,3.46714852 L15.9863155,10.0017893 L16,10.086441 L16,16 L0,16 L0,10.086441 L0.0136844539,10.0017893 L2.18355512,3.46714852 L3.34545455,3.46714852 Z M4.92106667,10.3539382 L0.533,10.3533333 L0.533333333,15.464178 L15.4666667,15.464178 L15.466,10.3533333 L11.0784,10.3539382 L11.0787879,12.4713331 L4.92121212,12.4713331 L4.92106667,10.3539382 Z M8,0.533333333 L10.4862145,3.02910711 L10.1090909,3.40768073 L8.266,1.55733333 L8.26666667,7.26350741 L7.73333333,7.26350741 L7.733,1.55833333 L5.89090909,3.40768073 L5.51378547,3.02910711 L8,0.533333333 Z"
            stroke="none"
        />
    </SvgIcon>
);

FileOutbox.displayName = 'FileOutboxIcon';

export default FileOutbox;
