import React from 'react';

import SvgIcon from './SvgIcon';

const MSTeamsBlank = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path
                fill="#3777bc"
                d="M8.836 0.501c-0.058-0.003-0.117 0.001-0.176 0.011l-7.78 1.349c-0.511 0.089-0.881 0.523-0.881 1.033v10.212c0 0.51 0.371 0.944 0.881 1.033l7.78 1.349c0.047 0.008 0.093 0.012 0.14 0.012 0.186 0 0.368-0.064 0.514-0.184 0.181-0.149 0.285-0.369 0.285-0.602v-2.139c0.24 0.091 0.506 0.143 0.8 0.143 1.219 0 1.749-0.816 1.859-1.247 0.005-0.021 0.008-0.042 0.008-0.063v-4.194c0-0.289-0.239-0.524-0.533-0.524h-2.133v-1.117c0.254 0.206 0.579 0.331 0.933 0.331 0.809 0 1.467-0.647 1.467-1.442s-0.658-1.442-1.467-1.442c-0.354 0-0.68 0.124-0.933 0.331v-2.064c0-0.233-0.104-0.453-0.285-0.603-0.136-0.112-0.304-0.176-0.478-0.183zM8.827 1.025c0.068 0.007 0.118 0.038 0.144 0.060 0.035 0.030 0.095 0.095 0.095 0.201v13.427c0 0.106-0.060 0.172-0.095 0.201s-0.113 0.076-0.218 0.057l-7.78-1.349c-0.255-0.044-0.441-0.261-0.441-0.517v-10.212c0-0.255 0.185-0.472 0.441-0.517l7.78-1.349c0.026-0.005 0.051-0.005 0.073-0.003zM10.533 3.545c0.515 0 0.933 0.411 0.933 0.917s-0.419 0.917-0.933 0.917c-0.515 0-0.933-0.411-0.933-0.917s0.419-0.917 0.933-0.917zM14.267 3.807c-0.809 0-1.467 0.647-1.467 1.442s0.658 1.442 1.467 1.442c0.809 0 1.467-0.647 1.467-1.442s-0.658-1.442-1.467-1.442zM14.267 4.331c0.515 0 0.933 0.411 0.933 0.917s-0.419 0.917-0.933 0.917c-0.515 0-0.933-0.411-0.933-0.917s0.419-0.917 0.933-0.917zM2.667 5.117c-0.147 0-0.267 0.117-0.267 0.262s0.119 0.262 0.267 0.262h1.6v4.98c0 0.145 0.119 0.262 0.267 0.262s0.267-0.117 0.267-0.262v-4.98h1.6c0.147 0 0.267-0.117 0.267-0.262s-0.119-0.262-0.267-0.262h-3.733zM9.6 7.214h2.133v4.156c-0.048 0.142-0.332 0.824-1.333 0.824-0.311 0-0.58-0.064-0.8-0.191v-4.788zM13.333 7.214c-0.294 0-0.533 0.235-0.533 0.524v3.669c0 0.102 0.060 0.195 0.155 0.238 0.219 0.1 0.79 0.287 1.179 0.287 1.219 0 1.749-0.816 1.859-1.247 0.005-0.021 0.008-0.042 0.008-0.063v-2.883c0-0.289-0.239-0.524-0.533-0.524h-2.133zM13.333 7.738h2.133v2.845c-0.048 0.142-0.332 0.824-1.333 0.824-0.223 0-0.572-0.095-0.8-0.177v-3.493z"
            />
        </g>
    </SvgIcon>
);

MSTeamsBlank.displayName = 'MSTeamsBlankIcon';

export default MSTeamsBlank;
