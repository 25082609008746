import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover as ReactstrapPopover } from 'reactstrap';
import classNames from 'classnames';
import uuid from 'uuid';

import { keyCodes } from '../../common/constants';

import { fp as _, DomUtils, EventsUtils, ReactUtils } from '../utils';
import PopoverManager from './PopoverManager';

const popoverManager = new PopoverManager();

class Popover extends Component {
    constructor(props) {
        super(props);

        this.id = `popover_${props.id || uuid()}`;
        this.elementRef = React.createRef();
    }

    componentWillUnmount() {
        this.registerPopup(false);
    }

    componentDidUpdate(prevProps) {
        const { isOpen, shouldFocus } = this.props;
        const { isOpen: isOpenPrev } = prevProps;

        if (isOpenPrev !== isOpen) {
            this.registerPopup(isOpen, shouldFocus);
        }
    }

    handleDocumentClick = e => {
        if (/*this.props.target !== e.target.id &&*/ popoverManager.canClose(this.id, e.target)) {
            this.handleClose(e);
        }
    };

    handleKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            const result = DomUtils.isInDOMSubtree(e.target, this.elementRef.current);

            if (result) {
                this.handleClose(e);
            }
        }
    };

    handleOpen = () => {
        const { onOpen } = this.props;

        if (onOpen) {
            onOpen();
        }
    };

    handleClose = e => {
        const { onClose } = this.props;

        if (onClose) {
            onClose(e);
        }
    };

    handleTab = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.TAB) {
            const focusableChildren = this.elementRef.current.querySelectorAll(
                DomUtils.getFocusableElements().join(', ')
            );
            const totalFocusable = focusableChildren.length;
            let focusedIndex = 0;

            for (let i = 0; i < totalFocusable; i++) {
                if (focusableChildren[i] === document.activeElement) {
                    focusedIndex = i;
                    break;
                }
            }

            if (e.shiftKey && focusedIndex === 0) {
                e.preventDefault();
                DomUtils.setFocus(focusableChildren[totalFocusable - 1]);
            } else if (!e.shiftKey && focusedIndex === totalFocusable - 1) {
                e.preventDefault();
                DomUtils.setFocus(focusableChildren[0]);
            }
        }
    };

    getPopoverRef = ref => {
        if (ref) {
            const { innerRef } = this.props;

            ReactUtils.setMultipleRefs(innerRef, this.elementRef)(ref);
            this.elementRef.current.removeAttribute('aria-hidden');
            EventsUtils.bind(this.elementRef.current, 'keydown', this.handleTab);
            EventsUtils.bind(window, 'keyup', this.handleKeyUp);
        }
    };

    registerPopup = (isOpen, shouldFocus) => {
        if (isOpen) {
            const element = this.elementRef.current;

            popoverManager.register(this.id, element);

            shouldFocus && DomUtils.setFocus(element.querySelector('button'));

            EventsUtils.bind(document, 'click', this.handleDocumentClick, true);
        } else {
            popoverManager.unregister(this.id);

            EventsUtils.unbind(this.elementRef.current, 'keydown', this.handleTab);
            EventsUtils.unbind(document, 'click', this.handleDocumentClick, true);
            EventsUtils.unbind(window, 'keyup', this.handleKeyUp);
        }
    };

    render() {
        const { className, children, size, withTargetCacheReset, ...other } = this.props;
        const otherProps = _.omit(other, ['onOpen', 'shouldFocus']);

        return (
            <ReactstrapPopover
                {...otherProps}
                tabIndex="-1"
                className={classNames('onsolve-popover', className)}
                innerRef={this.getPopoverRef}
                fade={false}
                key={withTargetCacheReset ? uuid() : this.id}
            >
                {React.Children.map(children, element => React.cloneElement(element, { size }))}
            </ReactstrapPopover>
        );
    }
}

Popover.propTypes = {
    /**
        The content of wrapped component/content.
    */
    children: PropTypes.node.isRequired,
    /**
        Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
        Specifies the unique identifier for component.
    */
    id: PropTypes.string,
    /**
        Specifies a ref for a tag.
    */
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
        Controls the visual state of component.
    */
    isOpen: PropTypes.bool,
    /**
        Callback function on close event occurs when the close even triggered.
    */
    onClose: PropTypes.func,
    /**
        Callback function on open event occurs when the open even triggered.
    */
    onOpen: PropTypes.func,
    /**
        Controls the visual state of component on initialization.
    */
    shouldFocus: PropTypes.bool,
    /**
        Specifies one of the size of popover.
    */
    size: PropTypes.oneOf(['md', 'lg']),
    /**
        Specifies type of id, static/generated.
    */
    withTargetCacheReset: PropTypes.bool
};

Popover.defaultProps = {
    size: 'md',
    withTargetCacheReset: false
};

Popover.displayName = 'Popover';

export default Popover;
