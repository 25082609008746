import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { injectIntl } from 'react-intl';

import { keyCodes } from '../../common/constants';

import { Scrollbars } from '../Scrollbars';
import { EventsUtils, DomUtils, ReactUtils, fp as _ } from '../utils';
import MenuList from './MenuList';
import PopoverManager from '../Popover/PopoverManager';
import { componentsTranslations } from '../translations';

function getAnchorEl(anchorEl) {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}

const popoverManager = new PopoverManager();
const parentEvents = ['scroll', 'wheel', 'keydown', 'touchmove'];

class Menu extends Component {
    constructor(props) {
        super(props);

        this.id = `menu_${props.id || uuidv4()}`;
        this.scrollableParents = new Map();
        this.menuRef = React.createRef();
        this.menuListRef = React.createRef();
        this.selectedMenuItemRef = React.createRef();
    }

    componentWillUnmount() {
        this.handleClose();
    }

    componentDidUpdate(prevProps) {
        const { open, anchorEl } = this.props;

        if (!prevProps.open && open) {
            this.handleOpen();
        } else if (prevProps.open && !open) {
            const { anchorEl } = this.props;

            DomUtils.setFocus(getAnchorEl(anchorEl));
            this.handleClose();
        }

        if (open && anchorEl && !_.isEqual(prevProps.children, this.props.children)) {
            this.calculatePosition();
        }
    }

    handleDocumentClick = e => {
        const { anchorEl, open, onClose } = this.props;

        let result = DomUtils.isInDOMSubtree(e.target, getAnchorEl(anchorEl));

        if (result) {
            return;
        }

        result = DomUtils.isInDOMSubtree(e.target, this.menuRef.current);

        if (open && !result) {
            if (onClose) {
                onClose(e);
            }
        }
    };

    handleOpen() {
        const { anchorEl } = this.props;
        const anchor = getAnchorEl(anchorEl);

        popoverManager.register(this.id, this.menuRef.current);

        EventsUtils.bind(document, 'click', this.handleDocumentClick, true);
        EventsUtils.bind(window, 'resize', this.calculatePosition);

        this.scrollableParents = DomUtils.getScrollableParents(anchor);
        this.scrollableParents.forEach((value, element) => {
            EventsUtils.bind(parent, 'scroll', this.calculatePosition);
            parentEvents.forEach(event => {
                EventsUtils.bind(element, event, this.handleParentEvents, {
                    passive: false
                });
            });
        });

        this.calculatePosition();
        this.scrollToSelectedItem();
    }

    handleClose() {
        this.scrollableParents.forEach((value, element) => {
            EventsUtils.unbind(parent, 'scroll', this.calculatePosition);
            parentEvents.forEach(event => {
                EventsUtils.unbind(element, event, this.handleParentEvents, {
                    passive: false
                });
            });
        });
        this.scrollableParents.clear();

        EventsUtils.unbind(document, 'click', this.handleDocumentClick, true);
        EventsUtils.unbind(window, 'resize', this.calculatePosition);

        popoverManager.unregister(this.id);
    }

    handleParentEvents = e => {
        const { type, target, keyCode } = e;
        const value = this.scrollableParents.get(target);

        if (!DomUtils.isInDOMSubtree(target, this.menuRef.current) && value) {
            if (
                type === 'scroll' ||
                type === 'wheel' ||
                type === 'touchmove' ||
                (type === 'keydown' && keyCode >= keyCodes.SPACE && keyCode <= keyCodes.DOWN)
            ) {
                if (type === 'scroll') {
                    const { scrollLeft, scrollTop } = value;

                    target.scrollTo(scrollLeft, scrollTop);
                }
                e.preventDefault();
            }
        }
    };

    handleMenuListKeyDown = e => {
        const { onKeyDown, onClose } = this.props;

        const { LEFT, RIGHT, TAB, ESC, BACKSPACE } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if ([TAB, ESC, BACKSPACE, LEFT, RIGHT].indexOf(charCode) !== -1) {
            e.preventDefault();

            if (charCode !== LEFT && charCode !== RIGHT) {
                if (onClose) {
                    onClose(e);
                }
            }
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    handleMouseEvents = e => {
        e.stopPropagation();
    };

    calculatePosition = () => {
        const { anchorEl, position } = this.props;
        const menuElement = this.menuRef.current;

        DomUtils.calculateElementPosition(position, menuElement, getAnchorEl(anchorEl));
        menuElement.style.visibility = 'visible';
    };

    scrollToSelectedItem = () => {
        const { current: menuListElement } = this.menuListRef;
        const selectedMenuItemElement = menuListElement.querySelector("[aria-selected='true']");

        if (selectedMenuItemElement) {
            DomUtils.setFocus(selectedMenuItemElement);
        } else {
            DomUtils.setFocus(menuListElement);
        }
    };

    render() {
        const { autoFocus, className, children, menuListRef, open, scrollProps, styles, intl, ...other } = this.props;

        if (!open) {
            return null;
        }

        const rootClasses = classNames('onsolve-menu', className);
        const scrollbarsAriaLabel = `${other['aria-label'] || ''} ${intl.formatMessage(
            componentsTranslations.ng_components_menu
        )}`.trim();

        let scrollbarProps = {
            autoHeight: true,
            role: 'region',
            'aria-label': scrollbarsAriaLabel,
            ...scrollProps
        };

        if (styles.autoHeightMax) {
            scrollbarProps = {
                ...scrollbarProps,
                autoHeightMax: styles.autoHeightMax
            };
        }

        return ReactDOM.createPortal(
            <div
                className={rootClasses}
                ref={ReactUtils.setMultipleRefs(this.menuRef)}
                onMouseOver={this.handleMouseEvents}
            >
                <Scrollbars {...scrollbarProps}>
                    <MenuList
                        {...other}
                        autoFocus={autoFocus}
                        innerRef={ReactUtils.setMultipleRefs(this.menuListRef, menuListRef)}
                        onKeyDown={this.handleMenuListKeyDown}
                    >
                        {children}
                    </MenuList>
                </Scrollbars>
            </div>,
            document.body
        );
    }
}

Menu.propTypes = {
    /**
    Specifies an anchorEl.
    */
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
    Tells the browser the button is being acted on
    */
    autoFocus: PropTypes.bool,
    /**
    The display content of the component. `node`
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component. `string`
    */
    className: PropTypes.string,
    /**
    Specifies an id.
    */
    id: PropTypes.string,
    /**
     * React-intl shape injected by injectIntl HOC.
     */
    intl: PropTypes.object.isRequired,
    /**
    Specifies a menuListRef.
    */
    menuListRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
    Callback function on close event.
    */
    onClose: PropTypes.func,
    /**
    Callback function on mode key down event.
    */
    onKeyDown: PropTypes.func,
    /**
    Specifies an open.
    */
    open: PropTypes.bool,
    /**
    Specifies a position related to anchorEl.
    */
    position: PropTypes.oneOf(['left', 'right', 'center']),
    /**
    Specifies a scrollProps.
    */
    scrollProps: PropTypes.object,
    /**
    Specifies a styles.
    */
    styles: PropTypes.object
};

Menu.defaultProps = {
    position: 'left',
    scrollProps: {},
    styles: {},
    autoFocus: true
};

Menu.displayName = 'Menu';

export default injectIntl(Menu, { forwardRef: true });
