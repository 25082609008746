import React from 'react';
import PropTypes from 'prop-types';

import SwitcherBase from './SwitcherBase';
import { RadioIcon, RadioBlankIcon } from '../Icons';

const Radio = props => {
    const { icon, checkedIcon, ...other } = props;

    return <SwitcherBase type="radio" icon={icon} checkedIcon={checkedIcon} {...other} />;
};

Radio.propTypes = {
    /**
    Specifies the custom component to replace default state view
    */
    checkedIcon: PropTypes.node,
    /**
    Specifies the custom component to replace checked state view
    */
    icon: PropTypes.node
};

Radio.defaultProps = {
    checkedIcon: <RadioIcon />,
    icon: <RadioBlankIcon />
};

Radio.displayName = 'Radio';

export default Radio;
