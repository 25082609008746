import React from 'react';

import SvgIcon from './SvgIcon';

const LongArrowRightIcon = props => (
    <SvgIcon {...props}>
        <line x1="1" y1="7.5" x2="15" y2="7.5" strokeLinecap="round" strokeWidth="0.75" />
        <line x1="11.5" y1="4.25" x2="15.25" y2="7.5" strokeLinecap="round" strokeWidth="0.75" />
        <line x1="11.5" y1="11" x2="15.25" y2="7.75" strokeLinecap="round" strokeWidth="0.75" />
    </SvgIcon>
);

LongArrowRightIcon.displayName = 'LongArrowRightIcon';

export default LongArrowRightIcon;
