import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IconButton } from '../../Button';
import { TimesIcon } from '../../Icons';
import { componentsTranslations } from '../../translations';

const CloseButton = ({ onClose, onKeyDown, intl, color }) => (
    <IconButton
        size="xs"
        onClick={onClose}
        tabIndex="0"
        onKeyDown={onKeyDown}
        aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
    >
        <TimesIcon color={color} size="md" />
    </IconButton>
);

CloseButton.defaultProps = {
    color: 'secondary'
};

CloseButton.propTypes = {
    color: PropTypes.string,
    intl: PropTypes.object,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func
};

CloseButton.displayName = 'CloseButton';

export default injectIntl(CloseButton);
