import { withPlaceholder } from '../../Placeholders';
import { PaperHeaderPlaceholder } from '../../Placeholders/Templates';
import { PaperHeader } from '..';

export default withPlaceholder({
    classes: {
        root: 'onsolve-paper-header'
    },
    customPlaceholder: PaperHeaderPlaceholder
})(PaperHeader);
