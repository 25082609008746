import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxCircle = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M15 8c0 3.866-3.134 7-7 7s-7-3.134-7-7c0-3.866 3.134-7 7-7s7 3.134 7 7z" />
            <path
                fill="#fff"
                d="M11.15 5.15l-4.65 4.65-1.65-1.65c-0.2-0.2-0.5-0.2-0.7 0s-0.2 0.5 0 0.7l2 2c0.1 0.1 0.2 0.15 0.35 0.15s0.25-0.050 0.35-0.15l5-5c0.2-0.2 0.2-0.5 0-0.7s-0.5-0.2-0.7 0z"
            />
        </g>
        )
    </SvgIcon>
);

CheckBoxCircle.displayName = 'CheckBoxCircleIcon';

export default CheckBoxCircle;
