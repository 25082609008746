import { EditorState, RichUtils, Modifier } from 'draft-js';

import { ENTITY_TYPES } from '../constants';

export const addLink = ({ editorState, urlEnteredText, urlValue, linkType, openLinkInSeparateWindow }) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', {
        url: urlValue,
        type: linkType,
        openLinkInSeparateWindow: openLinkInSeparateWindow
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState;

    if (selection.isCollapsed() && urlEnteredText) {
        // Entered text
        const newContentStateWithText = Modifier.insertText(contentState, selection, urlEnteredText, null, entityKey);

        newEditorState = EditorState.push(editorState, newContentStateWithText, 'insert-characters');
    } else {
        // Selected text
        newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    }

    return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
};
