import { fp as _ } from '../../../utils';

export const formatTimeItem = (value, size = 2) => ('00000' + value).slice(-size);

export const validate = (pattern, value) => {
    const regExp = new RegExp(pattern);

    return regExp.test(value);
};

export const initState = {
    hours: '',
    minutes: '',
    seconds: ''
};

/**
 * Returns initial state for TimeCounterInputText based on settings and initial value.
 * Value has to be in milliseconds.
 * If the value is not correct (isNaN), it returns default initial state.
 * This state controls separate inputs that display hours, minutes, seconds for current value.
 * Formatted value can be 9999:59:59.
 */
export const formatInitialValue = ({ value, pattern, format, placeholder }) => {
    const valueInMilliseconds = Number(value);

    if (isNaN(valueInMilliseconds)) {
        return initState;
    }

    const valueInSeconds = Math.floor(valueInMilliseconds / 1000);

    const hours = Math.floor(valueInSeconds / 60 / 60);
    const minutes = Math.floor((valueInSeconds - hours * 3600) / 60);
    const seconds = valueInSeconds - hours * 3600 - minutes * 60;

    const formattedValues = _.reduce(
        { hours, minutes, seconds },
        (acc, value, key) => {
            const currentValue = value.toString();

            if (format.includes(key)) {
                const allowedLength = placeholder[key].length;

                acc[key] = currentValue.length <= allowedLength && formatTimeItem(currentValue, allowedLength);
            }

            return acc;
        },
        {}
    );

    const isValid = _.every(formattedValues, (value, field) => validate(pattern[field], value));

    if (!isValid) {
        return initState;
    }

    return formattedValues;
};
