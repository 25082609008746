import React from 'react';
import SvgIcon from './SvgIcon';

const SquareCornerUpRight = props => (
    <SvgIcon {...props}>
        <path
            fill="none"
            d="M15.5,1.5 L15.5,14.5 C15.5,15.052 15.052,15.5 14.5,15.5 L1.5,15.5 C0.948,15.5 0.5,15.052 0.5,14.5 L0.5,1.5 C0.5,0.948 0.948,0.5 1.5,0.5 L14.5,0.5 C15.052,0.5 15.5,0.948 15.5,1.5 Z"
        />
        <path fill="none" d="M12.5,3.5 L6.5,9.5" />
        <polyline points="7.5 3.5 12.5 3.5 12.5 8.5" />
    </SvgIcon>
);

SquareCornerUpRight.displayName = 'SquareCornerUpRightIcon';

export default SquareCornerUpRight;
