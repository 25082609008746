import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';

class PlaceholderContainer extends Component {
    constructor(props) {
        super(props);

        const { context } = props;
        const delay = context.delay !== undefined ? context.delay : 1500;

        this.state = {
            ready: true,
            delay
        };

        this.timeout = 0;
    }

    componentDidUpdate() {
        const { ready } = this.props.context;

        if (!ready && !this.timeout) {
            this.addTimeout();
        }
    }

    addTimeout() {
        const { delay } = this.state;

        if (this.timeout) {
            return;
        }

        this.setState({ ready: false }, () => {
            this.timeout = setTimeout(() => {
                this.setState({ ready: true });
            }, delay);
        });
    }

    render() {
        const { ready } = this.state;
        const { wrappedComponent: WrappedComponent, hocProps, context, componentProps } = this.props;
        const { customPlaceholder: CustomPlaceholder, ...other } = hocProps;
        const { placeholderProps, ...otherComponentProps } = componentProps;

        const customPlaceholderProps = {
            ...other,
            placeholderProps,
            componentProps: otherComponentProps,
            ready: context.ready !== undefined ? context.ready && ready : true
        };

        return (
            <ReactPlaceholder
                showLoadingAnimation
                {...customPlaceholderProps}
                customPlaceholder={<CustomPlaceholder {...customPlaceholderProps} />}
            >
                <WrappedComponent {...componentProps} />
            </ReactPlaceholder>
        );
    }
}

PlaceholderContainer.propTypes = {
    componentProps: PropTypes.object,
    context: PropTypes.shape({
        delay: PropTypes.number,
        ready: PropTypes.bool
    }),
    hocProps: PropTypes.object,
    wrappedComponent: PropTypes.func.isRequired
};

PlaceholderContainer.defaultProps = {
    componentProps: {},
    hocProps: {}
};

PlaceholderContainer.displayName = 'PlaceholderContainer';

export default PlaceholderContainer;
