import { AtomicBlockUtils } from 'draft-js';

import { ENTITY_TYPES } from '../constants';

export const addHorizontalRule = editorState => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.HORIZONTAL_RULE, 'IMMUTABLE', {});
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
};
