import React from 'react';
import SvgIcon from './SvgIcon';

const FileDoc = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path fill="#12376b" d="M14.957 8.667h-13.913l-1.043-1.333 1.391-1.333h13.217l1.391 1.333z" />
                <path
                    fill="#e6e6e6"
                    d="M13.965 16h-11.93c-0.388 0-0.702-0.311-0.702-0.696v-14.609c0-0.384 0.314-0.696 0.702-0.696h8.615l4.017 4v11.304c0 0.384-0.314 0.696-0.702 0.696z"
                />
                <path fill="#b3b3b3" d="M10.667 0v3.333c0 0.368 0.298 0.667 0.667 0.667h3.333l-4-4z" />
                <path
                    fill="#27549b"
                    d="M15.304 13.333h-14.609c-0.384 0-0.696-0.298-0.696-0.667v-5.333h16v5.333c0 0.368-0.311 0.667-0.696 0.667z"
                />
                <path
                    fill="#fff"
                    d="M6.319 10.308c0 0.456-0.126 0.807-0.377 1.054s-0.604 0.37-1.058 0.37h-0.884v-2.731h0.945c0.439 0 0.777 0.112 1.015 0.336s0.358 0.548 0.358 0.971zM5.552 10.334c0-0.25-0.049-0.436-0.149-0.557s-0.249-0.181-0.451-0.181h-0.215v1.53h0.165c0.224 0 0.389-0.065 0.493-0.195s0.157-0.328 0.157-0.596z"
                />
                <path
                    fill="#fff"
                    d="M9.487 10.405c0 0.46-0.113 0.809-0.338 1.048s-0.555 0.359-0.99 0.359c-0.429 0-0.757-0.12-0.986-0.361s-0.342-0.59-0.342-1.050c0-0.455 0.113-0.802 0.341-1.042s0.558-0.36 0.991-0.36c0.434 0 0.764 0.119 0.988 0.357s0.336 0.587 0.336 1.048zM7.608 10.405c0 0.528 0.183 0.792 0.551 0.792 0.187 0 0.326-0.064 0.416-0.192s0.135-0.328 0.135-0.6c0-0.272-0.046-0.474-0.137-0.604s-0.228-0.195-0.41-0.195c-0.369 0.001-0.554 0.267-0.554 0.8z"
                />
                <path
                    fill="#fff"
                    d="M11.317 9.605c-0.176 0-0.313 0.072-0.411 0.216s-0.148 0.342-0.148 0.595c0 0.526 0.2 0.789 0.6 0.789 0.121 0 0.238-0.017 0.351-0.051s0.227-0.074 0.342-0.121v0.624c-0.227 0.101-0.486 0.152-0.773 0.152-0.413 0-0.728-0.12-0.949-0.359s-0.329-0.585-0.329-1.037c0-0.283 0.053-0.531 0.16-0.746s0.259-0.379 0.459-0.494c0.2-0.115 0.435-0.173 0.705-0.173 0.295 0 0.577 0.064 0.846 0.192l-0.226 0.581c-0.101-0.047-0.202-0.087-0.303-0.12s-0.209-0.048-0.325-0.048z"
                />
            </g>
        </SvgIcon>
    );
};

FileDoc.displayName = 'FileDocIcon';

export default FileDoc;
