import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../Focusable';
import { keyCodes } from '../../common/constants';
import { DomUtils } from '../utils';

class StepperButton extends Component {
    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
    }

    renderIcon() {
        const { index, completed, completedIcon } = this.props;

        if (completed && completedIcon) {
            if (typeof completedIcon === 'string') {
                return <span className={completedIcon} />;
            }
            return completedIcon;
        }
        return <span>{index + 1}</span>;
    }

    handleKeyDown = evt => {
        const { index, onClick, onLeftArrowKey, onRightArrowKey } = this.props;

        switch (evt.keyCode) {
            case keyCodes.ENTER_KEY:
            case keyCodes.SPACE:
                DomUtils.setBlur(this.btnRef);
                onClick && onClick();
                break;
            case keyCodes.LEFT:
                onLeftArrowKey && onLeftArrowKey(index);
                break;
            case keyCodes.RIGHT:
                onRightArrowKey && onRightArrowKey(index);
                break;
            default:
        }
    };

    setFocus() {
        const { disabled } = this.props;

        if (!disabled) {
            DomUtils.setFocus(this.btnRef);
        }
    }

    render() {
        const { title, active, disabled, completed, onClick } = this.props;

        return (
            <Focusable
                render={({ classes }) => (
                    <li
                        aria-label={title}
                        aria-selected={active}
                        className={classNames('onsolve-stepper__tab', classes, {
                            active: active,
                            completed: completed,
                            disabled: disabled
                        })}
                        role="tab"
                        ref={this.btnRef}
                        tabIndex={disabled ? -1 : 0}
                        onClick={onClick}
                        onKeyDown={this.handleKeyDown}
                    >
                        <span
                            className={classNames('onsolve-stepper__icon', {
                                'onsolve-stepper__icon--circle': !completed
                            })}
                        >
                            {this.renderIcon()}
                        </span>
                        <span className="onsolve-stepper__title">{title}</span>
                    </li>
                )}
            />
        );
    }
}

StepperButton.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    completedIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disabled: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onLeftArrowKey: PropTypes.func,
    onRightArrowKey: PropTypes.func,
    title: PropTypes.node.isRequired
};

StepperButton.defaultProps = {
    active: false,
    completed: false,
    disabled: false,
    icon: null,
    completedIcon: null
};

StepperButton.displayName = 'StepperButton';

export default StepperButton;
