import React from 'react';

import SvgIcon from './SvgIcon';

const EllipsisVertical = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <circle cx="10" cy="2" r="2" />
            <circle cx="10" cy="8" r="2" />
            <circle cx="10" cy="14" r="2" />
            <circle cx="2" cy="2" r="2" />
            <circle cx="2" cy="8" r="2" />
            <circle cx="2" cy="14" r="2" />
        </g>
    </SvgIcon>
);

EllipsisVertical.displayName = 'EllipsisVerticalIcon';

export default EllipsisVertical;
