import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PieChartSegment = ({ value, color, index, calculateOffset, segmentProps, strokeWidth }) => (
    <circle
        {...segmentProps}
        className={classnames('onsolve-pie-chart__segment', {
            [`onsolve-pie-chart__segment--${color}`]: color
        })}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${value} ${100 - value}`}
        strokeDashoffset={calculateOffset(index)}
    />
);

PieChartSegment.propTypes = {
    calculateOffset: PropTypes.func.isRequired,
    color: PropTypes.oneOf([
        'green',
        'yellow',
        'red',
        'orange',
        'blue',
        'gray',
        'dark',
        'wave-blue',
        'catalina-blue',
        'curious-purple',
        'dark-violet',
        'moderate-blue',
        'soft-blue',
        'strong-cyan',
        'soft-lime-green',
        'very-light-gray'
    ]),
    index: PropTypes.number.isRequired,
    segmentProps: PropTypes.object.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

PieChartSegment.displayName = 'PieChartSegment';

export default PieChartSegment;
