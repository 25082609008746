import { keyCodes } from '../../common/constants';

const OVERFLOW_REGEXP = /auto|scroll/;
const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'object',
    'embed',
    '[tabindex]:not(.modal):not([tabindex^="-"])',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])'
];

const VIEWPORT_WIDTH_OFFSET = 15;

let scrollbarWidth = false;

function getViewport() {
    let win = window,
        d = document,
        e = d.documentElement,
        g = d.body,
        w = win.innerWidth || e.clientWidth || g.clientWidth,
        h = win.innerHeight || e.clientHeight || g.clientHeight,
        s = win.scrollY || e.scrollTop || g.scrollTop;
    /**
     * g.scroll top only works in quirk mode
     */

    return { width: w, height: h, scroll: s };
}

function calculateElementPosition(position, element, target) {
    const { offsetWidth: elementWidth, offsetHeight: elementHeight } = element;
    const { offsetWidth: targetWidth, offsetHeight: targetHeight } = target;
    const {
        top: targetTop,
        bottom: targetBottom,
        left: targetLeft,
        right: targetRight
    } = target.getBoundingClientRect();

    const { height: viewportHeight, width: viewportWidth, scroll: viewPortScroll } = getViewport();
    const rtl = isRtl();

    let top,
        left,
        width = Math.max(elementWidth, targetWidth);

    if (position === 'left' || position === 'center') {
        left = rtl ? viewportWidth - targetRight : targetLeft;
    } else {
        left = rtl ? targetLeft : targetRight - width;
        if (left < 0) {
            left = width - targetRight;
        }
    }

    if (targetTop + targetHeight + elementHeight > viewportHeight - viewPortScroll) {
        top = targetTop - elementHeight;
    } else {
        top = targetBottom;
    }

    const direction = rtl && (position === 'left' || position === 'center') ? 'right' : 'left';

    if (position === 'center') {
        element.style.width = targetWidth + 'px';
    } else if (position === 'left') {
        element.style['min-width'] = targetWidth + 'px';
        const offset = getScrollbarWidth() + VIEWPORT_WIDTH_OFFSET;

        if (rtl && targetLeft >= offset) {
            width = targetWidth + (targetLeft - offset);
        } else if (targetWidth + targetLeft <= viewportWidth - offset) {
            width = viewportWidth - (targetLeft + offset);
        }
        element.style['max-width'] = width + 'px';
    } else {
        element.style['min-width'] = width + 'px';
    }

    element.style.top = top + viewPortScroll + 'px';
    element.style[direction] = left + 'px';
}

function getScrollbarWidth(forceRefresh = false) {
    if (!forceRefresh && scrollbarWidth !== false) {
        return scrollbarWidth;
    }

    if (typeof document !== 'undefined') {
        const div = document.createElement('div');

        div.style.width = 100;
        div.style.height = 100;
        div.style.position = 'absolute';
        div.style.top = -9999;
        div.style.overflow = 'scroll';
        div.style.MsOverflowStyle = 'scrollbar';

        document.body.appendChild(div);
        scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.removeChild(div);
    } else {
        scrollbarWidth = 0;
    }
    return scrollbarWidth || 0;
}

function setFocus(element) {
    if (element) {
        if (element.current) {
            element.current.focus();
        } else {
            element.focus();
        }
    }
}

function setBlur(element) {
    if (element) {
        if (element.current) {
            element.current.blur();
        } else {
            element.blur();
        }
    }
}

function findPreviousInput(element) {
    const previousElement = element.parentElement.previousElementSibling;

    if (previousElement && previousElement.childNodes) {
        const inputNode = findNode(previousElement.childNodes, element.tagName.toLowerCase());

        if (inputNode) {
            return inputNode;
        }
    }

    const prevElChildren =
        previousElement && previousElement.previousElementSibling && previousElement.previousElementSibling.childNodes;

    if (!previousElement || !prevElChildren) {
        return null;
    }

    return findNode(prevElChildren, element.tagName.toLowerCase());
}

function findNextInput(element) {
    const nextElement = element.parentElement.nextElementSibling;

    if (nextElement && nextElement.childNodes) {
        const inputNode = findNode(nextElement.childNodes, element.tagName.toLowerCase());

        if (inputNode) {
            return inputNode;
        }
    }

    const nextElChildren = nextElement && nextElement.nextElementSibling && nextElement.nextElementSibling.childNodes;

    if (!nextElement || !nextElChildren) {
        return null;
    }

    return findNode(nextElChildren, element.tagName.toLowerCase());
}

function findNode(nodeList, tagName) {
    return Object.values(nodeList).find(node => node.tagName && node.tagName.toLowerCase() === tagName);
}

function focusOnSiblingAccordion(accordion, key) {
    const card = accordion.parentElement.parentElement;
    const accordionGroup = card.parentElement;
    let siblingToFocus;

    if (key === keyCodes.DOWN) {
        siblingToFocus = card.nextElementSibling;
    }
    if (key === keyCodes.UP) {
        siblingToFocus = card.previousElementSibling;
    }

    if (siblingToFocus) {
        setFocus(siblingToFocus.firstChild.firstChild);
    } else {
        if (key === keyCodes.DOWN) {
            setFocus(accordionGroup.firstChild.firstChild.firstChild);
        }
        if (key === keyCodes.UP) {
            setFocus(accordionGroup.lastChild.firstChild.firstChild);
        }
    }
}

function getCharCode(e) {
    return e.charCode || e.keyCode || e.which;
}

function getCtrlKey(e) {
    return e.ctrlKey;
}

function getDOMNodeProperty(node, property) {
    return window.getComputedStyle(node, null).getPropertyValue(property);
}

function getLineHeight(element) {
    let value = getDOMNodeProperty(element, 'line-height');

    if (value === 'normal') {
        //https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
        //Depends on the user agent. Desktop browsers (including Firefox)
        //use a default value of roughly 1.2, depending on the element's font-family.
        value = parseInt(getDOMNodeProperty(element, 'font-size')) * 1.2;
    }
    return parseInt(value);
}

function isInDOMSubtree(element, subtreeRoot) {
    return subtreeRoot && (element === subtreeRoot || subtreeRoot.contains(element));
}

function isRtl() {
    return document.documentElement.dir === 'rtl';
}

function overflowStyle(element) {
    const { overflow, overflowX, overflowY } = window.getComputedStyle(element);

    return '' + overflow + overflowX + overflowY;
}

function getScrollableParents(element) {
    const parentElements = new Map();

    const registerParentElement = element => {
        const { scrollLeft = 0, scrollTop = 0 } = element;

        parentElements.set(element, { scrollLeft, scrollTop });
    };

    let parent = element.parentElement;

    while (parent) {
        if (OVERFLOW_REGEXP.test(overflowStyle(parent))) {
            registerParentElement(parent);
        }
        parent = parent.parentElement;
    }
    registerParentElement(window);
    return parentElements;
}

function getFocusableElements() {
    return FOCUSABLE_ELEMENTS;
}

function escape(value = '', replaceValue = '') {
    const result = String(value === null ? '' : value);

    return result.replace(/[-[\]{}()*+?.,\\^$|#&%><\s]/g, replaceValue);
}

function measureText(elementRef, text) {
    const styles = getComputedStyle(elementRef);
    const fontSize = styles.getPropertyValue('font-size');
    const fontFamily = styles.getPropertyValue('font-family');

    const canvas = measureText.canvas || (measureText.canvas = document.createElement('canvas'));
    const canvasContext = canvas.getContext('2d');

    canvasContext.font = `${fontSize} ${fontFamily}`;

    const measure = canvasContext.measureText(text);

    return measure;
}

export default {
    calculateElementPosition,
    findNextInput,
    findPreviousInput,
    getCharCode,
    getCtrlKey,
    getDOMNodeProperty,
    getLineHeight,
    getScrollableParents,
    getScrollbarWidth,
    isInDOMSubtree,
    isRtl,
    setBlur,
    setFocus,
    getFocusableElements,
    focusOnSiblingAccordion,
    escape,
    measureText
};
