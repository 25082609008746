import React from 'react';
import SvgIcon from './SvgIcon';

const FloppyDisk = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M2.625 0v2.625c0 .525.35.875.875.875h4.375c.525 0 .875-.35.875-.875V0h.875c.262 0 .438.087.613.263l3.5 3.5c.175.175.262.35.262.612v8.75c0 .525-.35.875-.875.875H.875C.35 14 0 13.65 0 13.125V.875C0 .35.35 0 .875 0h1.75zM10.5 8.75h-7c-.525 0-.875.35-.875.875v2.625h8.75V9.625c0-.525-.35-.875-.875-.875zM7.875 0v2.625h-1.75V0h1.75z"
                fillRule="nonzero"
            />
        </SvgIcon>
    );
};

FloppyDisk.displayName = 'FloppyDiskIcon';

export default FloppyDisk;
