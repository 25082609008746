import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../Button';
import { AlertModal } from '../../../../Modals';
import { componentsTranslations } from '../../../../translations';

const ImageUploadModal = ({ isOpen, children, onCancel }) => {
    const title = <FormattedMessage {...componentsTranslations.ng_file_upload_rejected_files_modal} />;
    const actions = (
        <Button color="primary" className="text-uppercase" onClick={onCancel}>
            <FormattedMessage {...componentsTranslations.ng_components_ok} />
        </Button>
    );

    return (
        <AlertModal isOpen={isOpen} onClose={onCancel} size="sm" title={title} message={children} actions={actions} />
    );
};

ImageUploadModal.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

ImageUploadModal.displayName = 'ImageUploadModal';

export default ImageUploadModal;
