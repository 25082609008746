import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyleButton } from '.';

const EraseControls = forwardRef(({ editorState, style, ...other }, ref) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return <StyleButton isActive={style === blockType} style={style} ref={ref} {...other} />;
});

EraseControls.propTypes = {
    editorState: PropTypes.object.isRequired,
    style: PropTypes.string.isRequired
};

EraseControls.displayName = 'EraseControls';

export default EraseControls;
