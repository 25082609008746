import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LINK_TYPES } from '../../../../constants';
import { componentsTranslations } from '../../../../../translations';
import { Dropdown } from '../../../../../Dropdowns';

const linkTypesList = [
    {
        id: LINK_TYPES.WEB_ADDRESS,
        value: <FormattedMessage {...componentsTranslations.ng_components_webAddress} />
    },
    {
        id: LINK_TYPES.EMAIL_ADDRESS,
        value: <FormattedMessage {...componentsTranslations.ng_components_emailAddress} />
    }
];

const LinkTypeDropdownField = ({ field, form }) => {
    const handleLinkTypeChange = useCallback(
        (form, field) => (e, newValue) => {
            field.onChange(e);
            form.setErrors({ ...form.errors, linkType: null });
            form.setFieldValue(field.name, newValue);
        },
        []
    );

    return (
        <div className="col-12 p-0">
            <Dropdown
                id={field.name}
                label={<FormattedMessage {...componentsTranslations.ng_components_linkType} />}
                data={linkTypesList}
                {...field}
                onChange={handleLinkTypeChange(form, field)}
            />
        </div>
    );
};

LinkTypeDropdownField.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func
    }),
    form: PropTypes.shape({
        errors: PropTypes.object,
        setErrors: PropTypes.func,
        setFieldValue: PropTypes.func
    })
};

LinkTypeDropdownField.displayName = 'LinkTypeDropdownField';

export default LinkTypeDropdownField;
