import React from 'react';
import SvgIcon from './SvgIcon';

const ArrowLeft = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M6.207 8l4.646 4.646c0.195 0.195 0.195 0.512 0 0.707s-0.512 0.195-0.707 0l-5-5c-0.195-0.195-0.195-0.512 0-0.707l5-5c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-4.646 4.646z"
        />
    </SvgIcon>
);

ArrowLeft.displayName = 'ArrowLeftIcon';

export default ArrowLeft;
