import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../Typography';
import { Button } from '../Button';
import { ExtendedModal } from '../Modals';

import { componentsTranslations } from '../translations';

const FiltersWrapperMobile = ({ children, isApplyDisabled, isOpen, filterCount, onApply, onReset, onClose }) => (
    <ExtendedModal
        isOpen={isOpen}
        onClose={onClose}
        title={
            <Typography variant="p12" className="onsolve-filters-modal__header text-uppercase font-weight-bold">
                <FormattedMessage {...componentsTranslations.ng_components_filters} />
                {!!filterCount && (
                    <Badge className="onsolve-filters-popover__badge" color="primary" pill>
                        {filterCount}
                    </Badge>
                )}
            </Typography>
        }
        classes={{ root: 'onsolve-filters-modal' }}
        actions={
            <Fragment>
                <Button className="text-uppercase mr-0" size="lg" onClick={onReset}>
                    <FormattedMessage {...componentsTranslations.ng_components_reset} />
                </Button>
                <Button
                    className="text-uppercase mt-3"
                    size="lg"
                    color="primary"
                    onClick={onApply}
                    disabled={isApplyDisabled}
                >
                    <FormattedMessage {...componentsTranslations.ng_components_apply} />
                </Button>
            </Fragment>
        }
    >
        {children}
    </ExtendedModal>
);

FiltersWrapperMobile.propTypes = {
    children: PropTypes.node.isRequired,
    filterCount: PropTypes.number,
    isApplyDisabled: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired
};

FiltersWrapperMobile.displayName = 'FiltersWrapperMobile';

export default FiltersWrapperMobile;
