import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { isOutsideClick, clientIdGenerator } from './utils';
import Input from './input';
import Trigger from './trigger';
import Tree from './tree';
import TreeManager from './tree-manager';
import keyboardNavigation from './tree-manager/keyboardNavigation';
import { InputFieldLabel } from '../../../InputBase';
import { Focusable } from '../../../Focusable';
import { DomUtils } from '../../../utils';
import { keyCodes } from '../../../../common/constants';

class DropdownTreeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            searchModeOn: false,
            currentFocus: undefined,
            searchMatches: 0,
            expandedNodes: []
        };
        this.clientId = props.id || clientIdGenerator.get(this);
        this.inputComponentRef = React.createRef();
    }

    initNewProps = ({
        data,
        dataItemKey,
        variant,
        inheritDisable,
        showDropdown,
        showPartiallySelected,
        allowPartialHierarchical,
        keepParentWithCheckedChildren,
        searchPredicate,
        defaultValue,
        showExpanded,
        value: selectedValue,
        expandedNodes = []
    }) => {
        const getSelectedValue = value => {
            if (Array.isArray(value)) {
                return value;
            }
            if (!value) {
                return [];
            }
            return [value];
        };

        this.treeManager = new TreeManager({
            data,
            value: getSelectedValue(selectedValue),
            dataItemKey,
            variant,
            inheritDisable,
            showPartiallySelected,
            allowPartialHierarchical,
            keepParentWithCheckedChildren,
            rootPrefixId: this.clientId,
            searchPredicate,
            showExpanded,
            expandedNodes
        });
        // Restore focus-state
        const currentFocusNode = this.state.currentFocus && this.treeManager.getNodeById(this.state.currentFocus);
        const value = defaultValue && defaultValue[dataItemKey];
        const { tags } = this.treeManager;

        if (value && !tags.length) {
            this.treeManager.setNodeCheckedState(value, true);
        }

        if (currentFocusNode) {
            currentFocusNode._focused = true;
        }
        this.setState(prevState => ({
            showDropdown: /initial|always/.test(showDropdown) || prevState.showDropdown === true,
            keepParentWithCheckedChildren,
            ...this.treeManager.getTreeAndTags()
        }));
    };

    resetSearchState = () => {
        this.inputComponentRef.current.setState({ value: '' });

        return {
            searchTerm: '',
            tree: this.treeManager.restoreNodes(), // restore the tree to its pre-search state
            searchModeOn: false,
            allNodesHidden: false
        };
    };

    componentDidMount() {
        this.initNewProps({ ...this.props, expandedNodes: this.state.expandedNodes });
    }

    componentDidUpdate(prevProps) {
        const { data, value } = this.props;

        if (data !== prevProps.data) {
            this.initNewProps({ ...this.props });
        }

        if (value !== prevProps.value) {
            this.initNewProps({ ...this.props, expandedNodes: this.state.expandedNodes });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleClick = (e, callback) => {
        this.setState(prevState => {
            // keep dropdown active when typing in search box
            const showDropdown =
                this.props.showDropdown === 'always' || this.keepDropdownActive || !prevState.showDropdown;

            // register event listeners only if there is a state change
            if (showDropdown !== prevState.showDropdown) {
                if (showDropdown) {
                    document.addEventListener('click', this.handleOutsideClick, false);
                } else {
                    document.removeEventListener('click', this.handleOutsideClick, false);
                }
            }

            if (showDropdown) {
                this.props.onFocus();
            } else {
                this.props.onBlur();
            }

            return !showDropdown ? { showDropdown, ...this.resetSearchState() } : { showDropdown };
        }, callback);
    };

    handleOutsideClick = e => {
        if (this.props.showDropdown === 'always' || !isOutsideClick(e, this.node)) {
            return;
        }

        this.handleClick();
    };

    handleInputChange = value => {
        const { keepTreeOnSearch, keepChildrenOnSearch, showFullTreeOnSearch } = this.props;

        const { allNodesHidden, tree, searchMatches } = this.treeManager.filterTree(
            value,
            keepTreeOnSearch,
            keepChildrenOnSearch
        );
        const searchModeOn = value.length > 0;

        this.setState({
            searchTerm: value,
            tree: showFullTreeOnSearch ? this.state.tree : tree,
            searchModeOn: !showFullTreeOnSearch && searchModeOn,
            allNodesHidden,
            searchMatches
        });
    };

    handleTagRemove = (id, isKeyboardEvent) => {
        const { tags: prevTags } = this.state;

        this.handleCheckboxChange(id, false, tags => {
            if (!isKeyboardEvent) {
                return;
            }

            keyboardNavigation.getNextFocusAfterTagDelete(id, prevTags, tags, this.searchInput).focus();
        });
    };

    handleNodeToggle = id => {
        const { dataItemKey } = this.props;
        const { expandedNodes } = this.state;
        const node = this.treeManager.getNodeById(id);
        const haveMatch = this.doNodesHaveMatch(node._children, this.state.searchTerm);

        if (this.props.showFullTreeOnSearch && haveMatch) {
            return;
        }

        this.treeManager.toggleNodeExpandState(id);
        const tree = this.state.searchModeOn ? this.treeManager.matchTree : this.treeManager.tree;
        const newExpandedNodes = expandedNodes.includes(node[dataItemKey])
            ? expandedNodes.filter(nodeItem => nodeItem[dataItemKey] !== node[dataItemKey])
            : [...expandedNodes, node[dataItemKey]];

        this.setState({ tree, expandedNodes: newExpandedNodes });
        typeof this.props.onNodeToggle === 'function' && this.props.onNodeToggle(this.treeManager.getNodeById(id));
    };

    handleCheckboxChange = (id, checked, callback) => {
        const { variant, keepOpenOnSelect } = this.props;
        const { keepParentWithCheckedChildren } = this.state;

        const shouldForceCheckPartial =
            this.treeManager.getNodeById(id).partial && keepParentWithCheckedChildren && !checked;

        const isCheckedNode =
            (variant === 'simpleSelect' && this.state.tags.find(tag => id === tag.id)) ||
            !this.state.tags.length ||
            shouldForceCheckPartial
                ? true
                : checked;

        this.treeManager.setNodeCheckedState(id, isCheckedNode);
        let { tags } = this.treeManager;
        const isSingleSelect = ['simpleSelect', 'radioSelect'].indexOf(variant) > -1;
        const showDropdown = isSingleSelect && !keepOpenOnSelect ? false : this.state.showDropdown;

        if (!tags.length) {
            this.treeManager.restoreDefaultValues();
        }

        const tree = this.state.searchModeOn ? this.treeManager.matchTree : this.treeManager.tree;
        const nextState = {
            tree,
            tags,
            showDropdown
        };

        if ((isSingleSelect && !showDropdown) || this.props.clearSearchOnChange) {
            Object.assign(nextState, this.resetSearchState());
        }

        if (isSingleSelect && !showDropdown) {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(nextState, () => {
            callback && callback(tags);
        });

        this.props.onChange && this.props.onChange(this.treeManager.getNodeById(id), tags);
    };

    handleAction = (nodeId, action) => {
        this.props.onAction(this.treeManager.getNodeById(nodeId), action);
    };

    handleInputFocus = () => {
        this.keepDropdownActive = true;
    };

    handleInputBlur = () => {
        let { tags } = this.treeManager;

        if (!this.state.tags.length && tags.length) {
            this.setState({ tags });
        }

        this.keepDropdownActive = false;
    };

    handleTrigger = e => {
        this.handleClick(e, () => {
            // If the dropdown is shown after key press, focus the input
            if (this.state.showDropdown) {
                this.searchInput.focus();
            }
        });
    };

    handleKeyboardKeyDown = e => {
        const { readOnly, variant } = this.props;
        const { showDropdown, tags, searchModeOn, currentFocus } = this.state;
        const tm = this.treeManager;
        const currentNode = tm.getNodeById(currentFocus);
        const tree = searchModeOn ? tm.matchTree : tm.tree;
        const getNewFocus = key =>
            tm.handleNavigationKey(
                currentFocus,
                tree,
                key,
                readOnly,
                !searchModeOn,
                this.handleCheckboxChange,
                this.handleNodeToggle
            );

        if (!showDropdown && (keyboardNavigation.isValidKey(e.key, false) || /^\w$/i.test(e.key))) {
            // Triggers open of dropdown and retriggers event
            e.persist();
            this.handleClick(null, () => this.handleKeyboardKeyDown(e));
            if (/\w/i.test(e.key)) {
                return;
            }
        } else if (showDropdown && keyboardNavigation.isValidKey(e.key, true)) {
            const newFocus = getNewFocus(e.key);

            if (newFocus !== currentFocus) {
                this.setState({ currentFocus: newFocus });
            }

            this.searchInput.focus();
        } else if (showDropdown && e.key === 'Tab') {
            if (tree.has(currentFocus)) {
                if (currentNode && currentNode.disabled) {
                    // Triggers close
                    this.keepDropdownActive = false;
                    this.handleClick();

                    return;
                }

                this.handleCheckboxChange(currentFocus, true);
            } else {
                const newFocus = getNewFocus('ArrowDown');

                this.setState({ currentFocus: newFocus });
            }
        } else if (e.key === 'Backspace' && variant === 'simpleSelect') {
            if (tags.length && !this.searchInput.value.length) {
                const lastTag = tags.pop();

                this.handleCheckboxChange(lastTag._id, false);
            } else if (!showDropdown) {
                this.handleClick();
            }
            return;
        } else {
            return;
        }
        e.preventDefault();
    };

    handleKeyboardKeyUp = e => {
        const { showDropdown } = this.state;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            e.preventDefault();

            if (showDropdown) {
                e.stopPropagation();
                this.keepDropdownActive = false;
                this.handleClick();
            }
        }
    };

    getInitialTreeData = () => this.state.tree || new Map();

    setInnerRef = node => {
        this.node = node;
    };

    setInputRef = el => {
        this.searchInput = el;
    };

    doNodesHaveMatch = (nodeIds = [], searchTerm = '') => {
        if (!nodeIds.length || !searchTerm.length) {
            return false;
        }

        return nodeIds.some(id => {
            const node = this.treeManager.getNodeById(id);

            if (node.label.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
            }

            return this.doNodesHaveMatch(node._children, searchTerm);
        });
    };

    render() {
        const {
            disabled,
            readOnly,
            rectangleBorder,
            variant,
            texts,
            placeholder,
            placeholderTextSize,
            label,
            name,
            error,
            errorText,
            hasInfiniteScroll,
            dataItemKey,
            intl,
            menuProps
        } = this.props;
        const { showDropdown, currentFocus, tags, searchTerm, searchMatches } = this.state;

        const activeDescendant = currentFocus ? `${currentFocus}_div` : undefined;

        const commonProps = {
            disabled,
            readOnly,
            activeDescendant,
            placeholder,
            label,
            texts,
            variant,
            clientId: this.clientId,
            error,
            errorText,
            intl
        };

        return (
            <Focusable
                render={({ classes }) => (
                    <div id={this.clientId} className={this.props.className}>
                        <div
                            className={cx(
                                'onsolve-dropdown-tree',
                                classes,
                                { 'simple-select': variant === 'simpleSelect' },
                                { 'radio-select': variant === 'radioSelect' }
                            )}
                        >
                            <Trigger
                                onTrigger={this.handleTrigger}
                                showDropdown={showDropdown}
                                {...commonProps}
                                tags={tags}
                            >
                                {label && <InputFieldLabel name={name}>{label}</InputFieldLabel>}
                                <Input
                                    innerRef={this.setInnerRef}
                                    inputRef={this.setInputRef}
                                    tags={tags}
                                    searchMatches={searchMatches}
                                    searchTerm={searchTerm}
                                    placeholderTextSize={placeholderTextSize}
                                    rectangleBorder={rectangleBorder}
                                    ref={this.inputComponentRef}
                                    {...commonProps}
                                    onTagRemove={this.handleTagRemove}
                                    onInputChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                    onKeyDown={this.handleKeyboardKeyDown}
                                    onKeyUp={this.handleKeyboardKeyUp}
                                />
                            </Trigger>
                            <Tree
                                isOpen={showDropdown}
                                anchorEl={this.node}
                                data={this.getInitialTreeData()}
                                dataItemKey={dataItemKey}
                                keepTreeOnSearch={this.props.keepTreeOnSearch}
                                keepChildrenOnSearch={this.props.keepChildrenOnSearch}
                                searchModeOn={this.state.searchModeOn}
                                variant={variant}
                                showPartiallySelected={this.props.showPartiallySelected}
                                searchTerm={searchTerm}
                                hasInfiniteScroll={hasInfiniteScroll}
                                {...menuProps}
                                {...commonProps}
                                onAction={this.handleAction}
                                onCheckboxChange={this.handleCheckboxChange}
                                onNodeToggle={this.handleNodeToggle}
                            />
                        </div>
                    </div>
                )}
            />
        );
    }
}

DropdownTreeSelect.defaultProps = {
    dataItemKey: 'id',
    hasInfiniteScroll: false,
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
    rectangleBorder: false,
    showDropdown: 'default',
    showExpanded: false,
    showFullTreeOnSearch: false,
    texts: {},
    variant: 'simpleSelect'
};

DropdownTreeSelect.propTypes = {
    /**
     Enables partial checkboxes when variant prop is set to hierarchical
     */
    allowPartialHierarchical: PropTypes.bool,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Clears search input on selection.
     */
    clearSearchOnChange: PropTypes.bool,
    /**
     Data that form options in dropdown.
     */
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    /**
     Specifies key for auto complete handler.
     */
    dataItemKey: PropTypes.string,
    /**
     Specifies default value.
     */
    defaultValue: PropTypes.object,
    /**
     Specifies an input field is disabled.
     */
    disabled: PropTypes.bool,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies the error message.
     */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies infinite scroll wrapper.
     */
    hasInfiniteScroll: PropTypes.bool,
    /**
     Specifies an id.
     */
    id: PropTypes.string,
    /**
     Specifies an inherit disable, so it allows to disable whole tree from the parent that has been disabled.
     */
    inheritDisable: PropTypes.bool,
    /**
     @ignore
     */
    intl: PropTypes.object,
    /**
     Prop works with keepTreeOnSearch and allows to display children of the node that matches the search.
     */
    keepChildrenOnSearch: PropTypes.bool,
    /**
     Prop keeps dropdown opened on user selection.
     */
    keepOpenOnSelect: PropTypes.bool,
    /**
     When enabled, the parent element will not be checked automatically when all its' children are checked
     */
    keepParentWithCheckedChildren: PropTypes.bool,
    /**
     Prop keeps parents of matched node opened during the search.
     */
    keepTreeOnSearch: PropTypes.bool,
    /**
     Add a label for the component.
     */
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
    /**

     */
    menuProps: PropTypes.shape({
        position: PropTypes.oneOf(['left', 'right', 'center'])
    }),
    /**
     Specifies the name for name attribute of an input element.
     */
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     Callback function on action button.
     */
    onAction: PropTypes.func,
    /**
     Callback function on blur event of text input.
     */
    onBlur: PropTypes.func,
    /**
     Callback function on change event of text input.
     */
    onChange: PropTypes.func,
    /**
     Callback function on focus event of text input.
     */
    onFocus: PropTypes.func,
    /**
     Callback function on node toggle event.
     */
    onNodeToggle: PropTypes.func,
    /**
     Callback function on tag remove event.
     */
    onTagRemove: PropTypes.func,
    /**
     The short hint displayed in the input before the user enters a value.
     */
    placeholder: PropTypes.string,
    /**
     Defines placeholder text size parameter.
     */
    placeholderTextSize: PropTypes.oneOf(['sm', 'xs']),
    /**
     Specifies that an input field is read-only.
     */
    readOnly: PropTypes.bool,
    /**
     Specifies a style of border.
     */
    rectangleBorder: PropTypes.bool,
    /**
     Specifies a custom function for prediction of search.
     */
    searchPredicate: PropTypes.func,
    /**
     Specifies an open state of dropdown ['default', 'initial', 'always'].
     */
    showDropdown: PropTypes.oneOf(['default', 'initial', 'always']),
    /**
     The `showExpanded` prop opens dropdown with expanded options.
     */
    showExpanded: PropTypes.bool,
    /**
     Specifies open state of tree on search.
     */
    showFullTreeOnSearch: PropTypes.bool,
    /**
     The `showPartiallySelected` prop can be used to select whole tree from selected node with cilds.
     */
    showPartiallySelected: PropTypes.bool,
    /**
     Specifies input params.
     */
    texts: PropTypes.shape({
        placeholder: PropTypes.string,
        noMatches: PropTypes.string,
        label: PropTypes.string,
        labelRemove: PropTypes.string
    }),
    /*
     * Specifies selected value(s) in the tree
     * */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /**
     Specifies variant of the Dropdown.
     */
    variant: PropTypes.oneOf(['multiSelect', 'simpleSelect', 'radioSelect', 'hierarchical'])
};

DropdownTreeSelect.displayName = 'DropdownTreeSelect';

export default injectIntl(DropdownTreeSelect);

export { default as TreeManager } from './tree-manager';
export { default as TreeNode } from './tree-node';
