import { EditorState, Modifier } from 'draft-js';

export const copyStyleToNewLine = (_, editorState, { setEditorState }) => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const currentBlock = contentState.getBlockForKey(startKey);

        if (currentBlock) {
            const blockData = currentBlock.getData();

            if (blockData.length > 0) {
                const newContentState = Modifier.splitBlock(
                    editorState.getCurrentContent(),
                    editorState.getSelection()
                );
                let splitState = EditorState.push(editorState, newContentState, 'split-block');
                const target = splitState.getSelection();
                const afterMergeStylesContentState = Modifier.mergeBlockData(
                    splitState.getCurrentContent(),
                    target,
                    blockData
                );

                splitState = EditorState.push(editorState, afterMergeStylesContentState, 'split-block');
                setEditorState(splitState);
                return 'handled';
            }
        }
    }
    return 'not-handled';
};
