import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../../components/Focusable';
import { keyCodes } from '../../common/constants';
import { DomUtils } from '../../components/utils';

class SwitcherBase extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(e) {
        const { onChange, disabled } = this.props;

        if (disabled) {
            return;
        }

        const { checked, value } = e.target;
        const charCode = DomUtils.getCharCode(e);

        onChange &&
            onChange(e, {
                checked: charCode === keyCodes.ENTER_KEY ? !checked : checked,
                value
            });
    }

    handleClick(e) {
        e.stopPropagation();
    }

    handleKeyPress(e) {
        e.stopPropagation();
        const { onChange, disabled, checked } = this.props;

        if (disabled) {
            return;
        }

        if (onChange) {
            const { value } = e.target;
            const charCode = DomUtils.getCharCode(e);

            onChange(e, { checked: charCode === keyCodes.ENTER_KEY ? !checked : checked, value });
        }
    }
    render() {
        const {
            'aria-label': ariaLabel,
            checked,
            checkedIcon,
            classes,
            className,
            color,
            disabled,
            icon,
            label,
            name,
            tabIndex,
            type,
            value
        } = this.props;
        const switcherClasses = classNames('onsolve-switcher', className, classes.root);
        const checkmarkClasses = classNames('onsolve-switcher__checkmark', classes.checkmark, {
            [`onsolve-switcher__checkmark--${color}`]: checked && !disabled,
            disabled: disabled
        });
        const labelClasses = classNames('onsolve-switcher__label', classes.label);

        return (
            <label className={switcherClasses} disabled={disabled} onClick={this.handleClick}>
                <span className={checkmarkClasses} aria-disabled={disabled}>
                    <Focusable
                        type={type === 'radio' ? 'circle' : 'square'}
                        render={({ classes }) => (
                            <input
                                className={classNames('onsolve-switcher__input', classes)}
                                aria-label={ariaLabel}
                                type={type}
                                aria-checked={checked}
                                checked={checked}
                                value={value}
                                name={name}
                                tabIndex={tabIndex}
                                onChange={this.handleChange}
                                onClick={this.handleClick}
                                onKeyPress={this.handleKeyPress}
                            />
                        )}
                    />

                    {checked ? checkedIcon : icon}
                </span>
                {label && <span className={labelClasses}>{label}</span>}
            </label>
        );
    }
}

SwitcherBase.propTypes = {
    'aria-label': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    checkedIcon: PropTypes.node.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'Info']),
    disabled: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])
};

SwitcherBase.defaultProps = {
    'aria-label': '',
    label: '',
    color: 'primary',
    classes: {},
    tabIndex: 0
};

SwitcherBase.displayName = 'SwitcherBase';

export default SwitcherBase;
