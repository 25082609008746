import React from 'react';
import SvgIcon from './SvgIcon';

const FormatListBullet = props => {
    return (
        <SvgIcon {...props}>
            <circle stroke="none" cx="2" cy="2" r="2" />
            <circle stroke="none" cx="2" cy="8" r="2" />
            <circle stroke="none" cx="2" cy="14" r="2" />
            <path stroke="none" d="M6 1h10v2H6zm0 6h10v2H6zm0 6h10v2H6z" />
        </SvgIcon>
    );
};

FormatListBullet.displayName = 'FormatListBulletIcon';

export default FormatListBullet;
