import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AlertModal } from '../../Modals';
import { CancelRemoveActions } from '../../Modals/Footer';
import { componentsTranslations } from '../../translations';

const LanguageDeleteModal = ({ isOpen, languagePrefix, onClose, onCancel, onDelete }) => (
    <AlertModal
        isOpen={isOpen}
        title={
            <FormattedMessage
                {...componentsTranslations.ng_components_removeLanguageQuestion}
                values={{ 0: languagePrefix }}
            />
        }
        message={
            <FormattedMessage
                {...componentsTranslations.ng_components_removeLanguageDescription}
                values={{ 0: languagePrefix }}
            />
        }
        actions={<CancelRemoveActions onCancel={onCancel} onDelete={onDelete} />}
        onClose={onClose}
    />
);

LanguageDeleteModal.propTypes = {
    isOpen: PropTypes.bool,
    languagePrefix: PropTypes.string,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func
};

LanguageDeleteModal.displayName = 'LanguageDeleteModal';

export default LanguageDeleteModal;
