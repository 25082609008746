import React from 'react';

import SvgIcon from './SvgIcon';

const Minus = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M14.125,7.1099639 L1.875,7.1099639 C1.35,7.1099639 1,7.46797112 1,8.00498195 C1,8.54199278 1.35,8.9 1.875,8.9 L14.125,8.9 C14.65,8.9 15,8.54199278 15,8.00498195 C15,7.46797112 14.65,7.1099639 14.125,7.1099639 Z"
            />
        </SvgIcon>
    );
};

Minus.displayName = 'MinusIcon';

export default Minus;
