import React from 'react';
import SvgIcon from './SvgIcon';

const Contact = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M6,8C4.2,8,2.7,6.5,2.7,4.7V3.3C2.7,1.5,4.2,0,6,0s3.3,1.5,3.3,3.3v1.3C9.3,6.5,7.8,8,6,8z M10.3,10.1 c1,0.4,1.7,1.4,1.7,2.5V16H0v-3.4c0-1.1,0.7-2.1,1.7-2.5C2.6,9.7,4.1,9.3,6,9.3S9.4,9.7,10.3,10.1z M10.7,2H16v1.3h-5.3V2z M10.7,5.3H16v1.3h-5.3V5.3z M12.7,8.7H16V10h-3.3V8.7z"
            />
        </SvgIcon>
    );
};

Contact.displayName = 'ContactIcon';

export default Contact;
