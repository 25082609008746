import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { setYear, setMonth, getMonth, getYear, isSameYear } from 'date-fns';

import { componentsTranslations } from '../../translations';

import { Dropdown } from '../../Dropdowns';
import { DateTimeUtils } from '../../utils';
import { SortIcon } from '../../Icons';

const START_YEAR = 1910;
const YEAR_OFFSET = 79;

const dropdownProps = {
    classes: { displayText: 'text-dark text-uppercase' },
    variant: 'link',
    menuProps: { position: 'right' },
    styles: { scrollbar: { autoHeightMax: 240 } }
};

const MonthYearDropdown = ({ intl, date, minDate, maxDate, onMonthChange, onYearChange }) => {
    const months = useMemo(() => {
        return DateTimeUtils.getMonthName().map((value, id) => ({ id, value }));
    }, []);

    const years = useMemo(() => {
        const start = getYear(minDate) || START_YEAR;
        const end = getYear(maxDate) || start + YEAR_OFFSET;
        const length = end - start + 1;

        return Array.from({ length }, (_, k) => ({ id: start + k, value: start + k }));
    }, [minDate, maxDate]);

    const currentMonth = useMemo(() => getMonth(date), [date]);

    const currentYear = useMemo(() => getYear(date), [date]);

    const handleMonthChange = useCallback(
        (e, value) => {
            if (onMonthChange) {
                const result = setMonth(date, value);

                onMonthChange(e, result);
            }
        },
        [date, onMonthChange]
    );

    const handleYearChange = useCallback(
        (e, value) => {
            if (onYearChange) {
                const result = setYear(date, value);

                onYearChange(e, result);
            }
        },
        [date, onYearChange]
    );

    const itemRender = (props, defaultRender) => {
        const { item } = props;
        const currentMonth = isSameYear(minDate, date) ? minDate.getMonth() : -1;
        const disabled = item.id < currentMonth;

        return defaultRender(item.id, { ...props, disabled });
    };

    return (
        <>
            <Dropdown
                aria-label={intl.formatMessage(componentsTranslations.ng_components_month)}
                className="onsolve-date-picker__months"
                data={months}
                value={currentMonth}
                {...dropdownProps}
                toggleIcon={SortIcon}
                itemRender={itemRender}
                onChange={handleMonthChange}
            />
            <Dropdown
                aria-label={intl.formatMessage(componentsTranslations.ng_components_year)}
                className="onsolve-date-picker__years"
                data={years}
                value={currentYear}
                dataItemKey="value"
                toggleIcon={SortIcon}
                {...dropdownProps}
                onChange={handleYearChange}
            />
        </>
    );
};

MonthYearDropdown.propTypes = {
    date: PropTypes.instanceOf(Date),
    intl: PropTypes.object,
    item: PropTypes.object.isRequired,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onMonthChange: PropTypes.func,
    onYearChange: PropTypes.func
};

MonthYearDropdown.displayName = 'MonthYearDropdown';

export default injectIntl(MonthYearDropdown);
