import React from 'react';

import SvgIcon from './SvgIcon';

const Download = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M11.556 8.889v2.667h-7.111v-2.667h-1.778v3.556c0 0.491 0.398 0.889 0.889 0.889h8.889c0.491 0 0.889-0.398 0.889-0.889v-3.556h-1.778z" />
            <path d="M11.111 6.222h-2.222v-3.556h-1.778v3.556h-2.222l3.111 3.556z" />
        </g>
    </SvgIcon>
);

Download.displayName = 'DownloadIcon';

export default Download;
