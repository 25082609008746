import React from 'react';
import SvgIcon from './SvgIcon';

const EnterArrow = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="m5.220447,8.759803l8.762543,0l0,-4.531567l0.996563,0l0,4.531567l0,0.755261l-9.759106,0l0,2.256699l-4.2,-2.63433l4.2,-2.63433l0,2.256699z"
        />
    </SvgIcon>
);

EnterArrow.displayName = 'EnterArrowIcon';

export default EnterArrow;
