import React from 'react';

import SvgIcon from './SvgIcon';

const Radio = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M8 4C5.792 4 4 5.792 4 8s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm0-4C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.398 6.398 0 0 1 1.6 8c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4z"
            />
        </SvgIcon>
    );
};

Radio.displayName = 'RadioIcon';

export default Radio;
