import React from 'react';

import SvgIcon from './SvgIcon';

const PinAdd = props => {
    return (
        <SvgIcon {...props} width="34" height="53" viewBox="0 0 34 53">
            <defs>
                <ellipse id="pin-add-b" cx="17" cy="44" rx="6" ry="3" />
                <filter
                    id="pin-add-a"
                    width="216.7%"
                    height="333.3%"
                    x="-58.3%"
                    y="-83.3%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                    <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" />
                </filter>
            </defs>
            <g fill="none">
                <path
                    fill="#3777BC"
                    d="M17,0 C8.644,0 0,6.359 0,17 C0,28.285 15.628,44.042 16.293,44.707 C16.48,44.895 16.734,45 17,45 C17.266,45 17.52,44.895 17.707,44.707 C18.372,44.042 34,28.285 34,17 C34,6.359 25.356,0 17,0 Z M23,18 L18,18 L18,23 C18,23.552 17.553,24 17,24 C16.447,24 16,23.552 16,23 L16,18 L11,18 C10.447,18 10,17.552 10,17 C10,16.448 10.447,16 11,16 L16,16 L16,11 C16,10.448 16.447,10 17,10 C17.553,10 18,10.448 18,11 L18,16 L23,16 C23.553,16 24,16.448 24,17 C24,17.552 23.553,18 23,18 Z"
                />
                <use fill="#000" filter="url(#pin-add-a)" xlinkHref="#pin-add-b" />
            </g>
        </SvgIcon>
    );
};

PinAdd.displayName = 'PinAddIcon';

export default PinAdd;
