import React from 'react';

import SvgIcon from './SvgIcon';

const Upload = props => {
    return (
        <SvgIcon {...props}>
            <g fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" fill="none">
                <path d="M14.417 9.75v3.5c0 .644-.523 1.167-1.167 1.167H2.75a1.167 1.167 0 01-1.167-1.167v-3.5" />
                <path d="M9.75 10.917h-3.5V6.25H3.917L8 1.583l4.083 4.667H9.75z" />
            </g>
        </SvgIcon>
    );
};

Upload.displayName = 'UploadIcon';

export default Upload;
