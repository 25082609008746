import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BlurComponent = ({ width, height, circle, className, primaryColor, secondaryColor }) => {
    const classes = classNames('onsolve-blur__component', className);
    const style = {
        width: `${width}rem`,
        height: `${circle ? width : height}rem`,
        backgroundColor: primaryColor,
        backgroundImage: `linear-gradient(90deg, ${primaryColor}, ${secondaryColor}, ${primaryColor})`,
        borderRadius: `${circle ? '50%' : '0.25rem'}`
    };

    return <span className={classes} style={style} />;
};

BlurComponent.displayName = 'BlurComponent';

BlurComponent.propTypes = {
    /**
    Whether or not to display as a circle, instead of a bar.
    */
    circle: PropTypes.bool,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Height of the blur component in rem. Has no effect when using "circle".
    */
    height: PropTypes.number,
    /**
    Primary background color of the blur component.
    */
    primaryColor: PropTypes.string,
    /**
    Secondary color, of the background image, of the blur component.
    */
    secondaryColor: PropTypes.string,
    /**
    Width of the blur component in rem.
    */
    width: PropTypes.number.isRequired
};

BlurComponent.defaultProps = {
    height: 0.5,
    primaryColor: '#d8d8d8',
    secondaryColor: '#f6f6f6'
};

export default BlurComponent;
