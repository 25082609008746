import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PaperFooter = ({ className, children }) => {
    const rootClasses = classNames('onsolve-paper-footer', className);

    return <div className={rootClasses}>{children}</div>;
};

PaperFooter.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

PaperFooter.displayName = 'PaperFooter';

export default PaperFooter;
