import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from '../Scrollbars';
import AlertModal from './AlertModal';

class ExtendedModal extends Component {
    render() {
        const { children, maxHeight, ...other } = this.props;

        return (
            <AlertModal
                {...other}
                message={
                    <Scrollbars stickChildToBody autoHide autoHeight autoHeightMax={maxHeight}>
                        {children}
                    </Scrollbars>
                }
            />
        );
    }
}

ExtendedModal.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node,
    /**
    ID of a modal.
    */
    id: PropTypes.string,
    /**
    Specifies a max height of a modal body.
    */
    maxHeight: PropTypes.number,
    /**
    Specifies a role of a modal.
    */
    role: PropTypes.string,
    /**
    Specifies the size of a modal: `xs`, `sm`, `md`, `lg`.
    */
    size: PropTypes.string
};

ExtendedModal.defaultProps = {
    id: 'dialog',
    maxHeight: 770,
    size: 'md',
    role: 'dialog'
};

ExtendedModal.displayName = 'ExtendedModal';

export default ExtendedModal;
