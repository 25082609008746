import React from 'react';
import SvgIcon from './SvgIcon';

const GroupItem = props => {
    return (
        <SvgIcon {...props}>
            <g
                id="Phase-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g id="1---Landing-Page---Large" transform="translate(-375.000000, -555.000000)" stroke="#565858">
                    <g
                        id="tables/assets/table-cell/48/checkbox-table-cell-unselected-copy-38-icons/table/group"
                        transform="translate(359.000000, 523.000000)"
                    >
                        <g id="Group" transform="translate(16.000000, 32.000000)">
                            <g id="multiple-11">
                                <rect id="Rectangle" x="1.5" y="2.5" width="3" height="3" rx="1.5" />
                                <path
                                    d="M3.5,14.5 L1.5,14.5 L1.5,11.5 L0.5,11.5 L0.5,8.5 C0.5,7.94771525 0.94771525,7.5 1.5,7.5 L2.5,7.5"
                                    id="Path"
                                />
                                <rect
                                    id="Rectangle"
                                    transform="translate(13.000000, 4.000000) rotate(-180.000000) translate(-13.000000, -4.000000) "
                                    x="11.5"
                                    y="2.5"
                                    width="3"
                                    height="3"
                                    rx="1.5"
                                />
                                <path
                                    d="M12.5,14.5 L14.5,14.5 L14.5,11.5 L15.5,11.5 L15.5,8.5 C15.5,7.94771525 15.0522847,7.5 14.5,7.5 L13.5,7.5"
                                    id="Path"
                                />
                                <rect id="Rectangle" x="6" y="0.5" width="4" height="4" rx="2" />
                                <path
                                    d="M10.5,15.5 L5.5,15.5 L5.5,11.5 L4.5,11.5 L4.5,8.5 C4.5,7.3954305 5.3954305,6.5 6.5,6.5 L9.5,6.5 C10.6045695,6.5 11.5,7.3954305 11.5,8.5 L11.5,11.5 L10.5,11.5 L10.5,15.5 Z"
                                    id="Path"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

GroupItem.displayName = 'GroupItemIcon';

export default GroupItem;
