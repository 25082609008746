import { fileTypeAccepted, fileMatchSize } from './validation';
import { REASONS } from '../constants';

export const fileValidation = (file, allowedMimeTypes, maxFileSizeInBytes) => {
    return new Promise((resolve, reject) => {
        if (!fileTypeAccepted(file, allowedMimeTypes)) {
            reject({ rejectReason: REASONS.TYPE });
        }

        if (!fileMatchSize(file, 0, maxFileSizeInBytes)) {
            reject({ rejectReason: REASONS.SIZE });
        }

        resolve();
    });
};
