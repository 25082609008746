import React from 'react';
import SvgIcon from './SvgIcon';

const TextImage = props => {
    return (
        <SvgIcon {...props}>
            <circle cx="7.5" cy="4.5" r="1.5" />
            <path d="M15.5 0H.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5zM15 1v8.185l-3.126-3.517c-.2-.214-.558-.214-.748 0L8.054 9.124l1.477 2.47-4.71-3.978a.5.5 0 0 0-.674.031L1 10.793V1h14z" />
        </SvgIcon>
    );
};

TextImage.displayName = 'TextImageIcon';

export default TextImage;
