import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';

import { OnsolvePropTypes } from '../../utils';

function TableExpandableRow(props) {
    const { columns, dataItem, expandableField, expandableContent: ExpandableContent } = props;

    const cellClasses = classNames('expandable-cell', { expanded: dataItem[expandableField] });

    return (
        <tr>
            <td className={cellClasses} colSpan={columns.length}>
                <Collapse isOpen={dataItem[expandableField]}>
                    <ExpandableContent dataItem={dataItem} />
                </Collapse>
            </td>
        </tr>
    );
}

TableExpandableRow.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.string,
            cell: PropTypes.func,
            align: OnsolvePropTypes.align
        })
    ).isRequired,
    dataItem: PropTypes.object,
    expandableContent: PropTypes.func,
    expandableField: PropTypes.string
};

TableExpandableRow.displayName = 'TableExpandableRow';

export default TableExpandableRow;
