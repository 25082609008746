import React from 'react';
import Flags from 'country-flag-icons/react/3x2';

//converts array of languages' codes to Icons
const getFlag = (language, className) => {
    const Flag = Flags[language.cultureRegionCode];

    return Flag ? <Flag key={language.cultureRegionCode} className={className} title={language.locale} /> : null;
};

getFlag.displayName = 'getFlag';

export default getFlag;
