import React from 'react';

import SvgIcon from './SvgIcon';

const Reset = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                fillRule="nonzero"
                d="M5.5822 11.8356a4.7693 4.7693 0 002.0073.8336L7.2755 14.5c-1.0123-.1671-1.9866-.571-2.8152-1.1688l1.1219-1.4956zm5.6756-.0622l1.1531 1.4724c-.856.6461-1.8691 1.0797-2.9305 1.2542l-.3122-1.8317a4.7651 4.7651 0 002.0896-.895zm-7.543-2.9077a4.5835 4.5835 0 00.9488 2.0981l-1.486 1.1503c-.6868-.8541-1.1465-1.8716-1.329-2.9411l1.8663-.3073zM1.6623 1.5l2.0356 1.9979c1.2279-1.2004 2.9088-1.905 4.6806-1.905C12.0288 1.5928 15 4.5078 15 8.0913c0 1.3768-.4342 2.6933-1.2543 3.8073l-1.5334-1.089c.5865-.7947.8958-1.7342.8958-2.7183 0-2.5595-2.1217-4.6418-4.7297-4.6418a4.7978 4.7978 0 00-3.344 1.36l2.0197 1.9821L1 7.4416 1.6622 1.5z"
            />
        </SvgIcon>
    );
};

Reset.displayName = 'ResetIcon';

export default Reset;
