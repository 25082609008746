import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    InputFieldControl,
    InputFieldGroup,
    InputFieldContainer,
    InputFieldLabel,
    InputFieldMessage
} from '../InputBase';
import { InputFieldUtils } from '../utils';

class TextField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this.input = props.forwardedRef || React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    getCurrentLength(value) {
        if (value) {
            return String(value).length;
        }
        return 0;
    }

    handleChange(e) {
        const { disabled, onChange } = this.props;
        const { value } = e.target;

        if (disabled) {
            return;
        }

        if (onChange) {
            onChange(e, value);
        }
    }

    handleFocus(e) {
        const { readOnly, onFocus } = this.props;

        if (readOnly) {
            return;
        }

        this.setState({ active: true });

        if (onFocus) {
            onFocus(e);
        }
    }

    handleBlur(e) {
        const { readOnly, onBlur } = this.props;

        if (readOnly) {
            return;
        }

        this.setState({ active: false });

        if (onBlur) {
            onBlur(e);
        }
    }

    handleKeyDown(e) {
        const { onKeyDown } = this.props;

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    handleKeyPress(e) {
        const { onKeyPress, pattern } = this.props;

        if (onKeyPress) {
            onKeyPress(e);
        }

        const inputValue = InputFieldUtils.getValue(e, this.input.current);
        const defaultValidator = {
            ...(typeof pattern === 'string' && {
                pattern: InputFieldUtils.defaultMasks[pattern] ? pattern : 'default'
            }),
            ...(pattern instanceof RegExp && { pattern: pattern })
        };
        const isInvalidInput = Object.keys(defaultValidator).some(key => {
            return !InputFieldUtils.validators[key](defaultValidator[key], inputValue);
        });

        if (isInvalidInput) {
            e.preventDefault();
        }
    }

    render() {
        const {
            label,
            name,
            type,
            variant,
            value: valueProps,
            defaultValue,
            placeholder,
            error,
            errorText,
            helpText,
            maxLength,
            disabled,
            readOnly,
            className,
            hideMaxLength,
            inputIcon,
            autoComplete,
            ariaLabel,
            showExclamation
        } = this.props;
        const { active } = this.state;
        const value = valueProps === undefined || valueProps === null ? defaultValue : valueProps;

        const placeholderClasses = classNames('onsolve-input-field__placeholder', {
            'onsolve-input-field__placeholder--sm': variant === 'outlined'
        });

        return (
            <InputFieldControl className={className}>
                <InputFieldGroup>
                    {label && <InputFieldLabel name={name}>{label}</InputFieldLabel>}
                    <InputFieldContainer
                        error={error}
                        active={active}
                        inputIcon={inputIcon}
                        variant={variant}
                        showExclamation={showExclamation}
                    >
                        {value.toString().length === 0 && <span className={placeholderClasses}>{placeholder}</span>}
                        <input
                            id={name}
                            ref={this.input}
                            className="onsolve-input-field__input"
                            name={name}
                            type={type}
                            value={value}
                            autoComplete={autoComplete}
                            disabled={disabled}
                            readOnly={readOnly}
                            maxLength={maxLength}
                            aria-label={ariaLabel}
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyDown}
                            onKeyPress={this.handleKeyPress}
                        />
                    </InputFieldContainer>
                </InputFieldGroup>
                <InputFieldMessage
                    error={error}
                    errorText={errorText}
                    helpText={helpText}
                    maxLength={maxLength}
                    currentLength={this.getCurrentLength(value)}
                    hideMaxLength={hideMaxLength}
                />
            </InputFieldControl>
        );
    }
}

TextField.propTypes = {
    ariaLabel: PropTypes.string,
    autoComplete: PropTypes.string,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Default value for the component.
    */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
    Specifies an input field is disabled.
    */
    disabled: PropTypes.bool,
    /**
    Specifies if the error should be showed.
    */
    error: PropTypes.bool,
    /**
    Specifies the error message.
    */
    errorText: PropTypes.node,
    forwardedRef: PropTypes.object,
    /**
    Specifies the helper message.
    */
    helpText: PropTypes.node,
    /**
    Specifies whether to show max limit character counter.
    */
    hideMaxLength: PropTypes.bool,
    /**
    Specifies the icon that should be appeared together with Text field.
    */
    inputIcon: PropTypes.element,
    /**
    Add a label for the component.
    */
    label: PropTypes.node,
    /**
    Specifies the max character length for the component.
    */
    maxLength: PropTypes.number,
    /**
    Specifies the name for name attribute of an input element.
    */
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    Callback function on blur event of text input.
    */
    onBlur: PropTypes.func,
    /**
    Callback function on change event of text input.
    */
    onChange: PropTypes.func,
    /**
    Callback function on focus event of text input.
    */
    onFocus: PropTypes.func,
    /**
    Callback function on keydown event of text input.
    */
    onKeyDown: PropTypes.func,
    /**
    Callback function on keypress event of text input.
    */
    onKeyPress: PropTypes.func,
    /**
    Specifies one of predefined or custom patterns to restrict input values.
    */
    pattern: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(RegExp),
        PropTypes.oneOf(['alpha', 'alphanumeric', 'numeric', 'password'])
    ]),
    /**
    The short hint displayed in the input before the user enters a value.
    */
    placeholder: PropTypes.node,
    /**
    Specifies that an input field is read-only.
    */
    readOnly: PropTypes.bool,
    /**
    Specifies whether to show exclamation icon at the end.
    */
    showExclamation: PropTypes.bool,
    /**
    Specifies the standard input types.
    */
    type: PropTypes.oneOf(['text', 'password']).isRequired,
    /**
    Entered value of the component.
    */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
    Specifies one of the appearance of text field.
    */
    variant: PropTypes.oneOf(['default', 'outlined']).isRequired
};

TextField.defaultProps = {
    type: 'text',
    defaultValue: '',
    inputIcon: null,
    variant: 'default',
    autoComplete: 'new-password'
};

TextField.displayName = 'TextField';

export default TextField;
