import React from 'react';
import SvgIcon from './SvgIcon';

const File = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    fill="#e6e6e6"
                    d="M14.263 16h-12.526c-0.407 0-0.737-0.311-0.737-0.696v-14.609c0-0.384 0.33-0.696 0.737-0.696h8.842l4.421 4.174v11.13c0 0.384-0.33 0.696-0.737 0.696z"
                />
                <path fill="#b3b3b3" d="M10.579 0v3.478c0 0.384 0.33 0.696 0.737 0.696h3.684l-4.421-4.174z" />
                <path
                    fill="#b3b3b3"
                    d="M11.636 11h-7.273c-0.201 0-0.364 0.224-0.364 0.5s0.163 0.5 0.364 0.5h7.273c0.201 0 0.364-0.224 0.364-0.5s-0.163-0.5-0.364-0.5z"
                />
                <path
                    fill="#b3b3b3"
                    d="M11.636 8h-7.273c-0.201 0-0.364 0.224-0.364 0.5s0.163 0.5 0.364 0.5h7.273c0.201 0 0.364-0.224 0.364-0.5s-0.163-0.5-0.364-0.5z"
                />
                <path
                    fill="#b3b3b3"
                    d="M7.6 5h-3.2c-0.221 0-0.4 0.224-0.4 0.5s0.179 0.5 0.4 0.5h3.2c0.221 0 0.4-0.224 0.4-0.5s-0.179-0.5-0.4-0.5z"
                />
            </g>
        </SvgIcon>
    );
};

File.displayName = 'FileIcon';

export default File;
