import React from 'react';
import SvgIcon from './SvgIcon';

const DesktopAlert = props => (
    <SvgIcon {...props}>
        <g strokeWidth="0.5" fill="none" fillRule="evenodd">
            <line x1="5" y1="9.0625" x2="5" y2="7.8125" />
            <line x1="2.1875" y1="9.0625" x2="7.8125" y2="9.0625" />
            <rect x="0.3125" y="0.3125" width="9.375" height="6.25" />
        </g>
    </SvgIcon>
);

DesktopAlert.displayName = 'DesktopAlertIcon';

export default DesktopAlert;
