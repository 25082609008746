import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardFooter = React.forwardRef((props, ref) => {
    const {
        actions,
        background,
        className,
        classes,
        component: Component = 'div',
        content,
        transparent,
        ...other
    } = props;

    const footerClass = classnames(
        'onsolve-card__footer',
        {
            [`bg-${background}`]: !!background,
            'text-white': !!background,
            'bg-transparent': transparent
        },
        classes.root,
        className
    );

    return (
        <Component className={footerClass} ref={ref} {...other}>
            {content}
            {actions && <div className={classes.actions}>{actions}</div>}
        </Component>
    );
});

CardFooter.propTypes = {
    /**
     Specifies controls buttons, such `ok`, 'cancel`.
     */
    actions: PropTypes.node,
    /**
     Specifies that a color of background.
     */
    background: PropTypes.string,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
      Override or extend the styles applied to the sub components.
    */
    classes: PropTypes.shape({
        root: PropTypes.string,
        actions: PropTypes.string
    }),
    /**
     Specifies a tag of component that wraps the footer's content and will be returned by CardFooter.
     */
    component: PropTypes.elementType,
    /**
     Specifies a content node of CardFooter.
     */
    content: PropTypes.node,
    /**
     Specifies a transparency of CardBody.
     */
    transparent: PropTypes.bool
};

CardFooter.defaultProps = {
    classes: {}
};

CardFooter.displayName = 'CardFooter';

export default CardFooter;
