import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { shapes } from '../../common/constants';

import { DomUtils } from '../utils';
import { Divider } from '../Divider';
import CardContainerDraggable from './CardContainerDraggable';

const classes = {
    draggableElement: 'onsolve-card-list__row--draggable',
    container: 'onsolve-card-list--dragging',
    appCursor: 'cursor-grabbing'
};

class CardList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            targetPosition: 0
        };

        this.cardListRef = React.createRef();
        this.cardListContainerRef = React.createRef();

        this.cardMargin = 0;
    }

    toggleClasses = () => {
        this.cardListRef.current.classList.toggle(classes.container);
        this.dragNode.classList.toggle(classes.draggableElement);
        document.body.classList.toggle(classes.appCursor);
    };

    getTargetPosition = (list = [], newIndex, offset, isUpDirection) =>
        list.reduce((acc, node, i) => {
            if (isUpDirection && i === newIndex) {
                return acc;
            }

            if (i <= newIndex) {
                return acc + node.getBoundingClientRect().height + offset;
            }

            return acc;
        }, -offset / 2);

    onHandleUpdateBeforeSortStart = ({ node, index }) => {
        this.dragNode = node;
        const [...childNodes] = this.cardListContainerRef.current.childNodes;

        if (!this.cardMargin) {
            const [marginBottom = '0'] = childNodes[0]
                ? DomUtils.getDOMNodeProperty(childNodes[0], 'margin-bottom').match(/(\d+)/)
                : [];

            const [marginTop = '0'] = childNodes[1]
                ? DomUtils.getDOMNodeProperty(childNodes[1], 'margin-top').match(/(\d+)/)
                : [];

            this.cardMargin = +marginBottom || +marginTop;
        }

        this.toggleClasses();

        this.setState({
            targetPosition: this.getTargetPosition(childNodes, index, this.cardMargin)
        });
    };

    handleSortEnd = ({ oldIndex, newIndex, collection }, e) => {
        const { onDragEnd } = this.props;

        this.toggleClasses();

        if (onDragEnd) {
            onDragEnd({ oldIndex, newIndex, collection }, e);
        }
    };

    handleSortStart = ({ node, index, collection }, e) => {
        const { onDragStart } = this.props;

        if (onDragStart) {
            onDragStart({ node, index, collection }, e);
        }
    };

    handleSortMove = e => {
        const { onDragMove } = this.props;

        if (onDragMove) {
            onDragMove(e);
        }
    };

    handleSortOver = ({ index, newIndex }) => {
        const [...childNodes] = this.cardListContainerRef.current.childNodes;

        this.setState({
            targetPosition: this.getTargetPosition(childNodes, newIndex, this.cardMargin, newIndex < index)
        });
    };

    handleSortStart = ({ node, index, collection, isKeySorting }, event) => {
        const { onDragStart } = this.props;

        if (onDragStart) {
            onDragStart({ node, index, collection, isKeySorting }, event);
        }
    };

    get content() {
        const { targetPosition } = this.state;
        const { className, draggable, items, children, hideDivider, classes } = this.props;

        const rootClasses = classNames('onsolve-card-list', className);
        const containerClasses = classNames('onsolve-card-list__container', classes.container);

        return (
            <div ref={this.cardListRef} className={rootClasses}>
                {draggable && !hideDivider && (
                    <div className="onsolve-card-list__divider" style={{ top: `${targetPosition}px` }}>
                        <Divider color="primary" />
                    </div>
                )}
                <div ref={this.cardListContainerRef} className={containerClasses}>
                    {items.map((item, index) => children(item, index))}
                </div>
            </div>
        );
    }

    render() {
        const {
            draggable,
            drag: { lockAxis, keyCodes, lockToContainerEdges, useDragHandle }
        } = this.props;

        return draggable ? (
            <CardContainerDraggable
                helperClass={classes.draggableElement}
                hideSortableGhost={false}
                transitionDuration={0}
                lockAxis={lockAxis}
                keyCodes={keyCodes}
                lockToContainerEdges={lockToContainerEdges}
                updateBeforeSortStart={this.onHandleUpdateBeforeSortStart}
                onSortOver={this.handleSortOver}
                onSortMove={this.handleSortMove}
                onSortEnd={this.handleSortEnd}
                onSortStart={this.handleSortStart}
                useDragHandle={useDragHandle}
            >
                {this.content}
            </CardContainerDraggable>
        ) : (
            <Fragment>{this.content}</Fragment>
        );
    }
}

CardList.defaultProps = {
    drag: {
        useDragHandle: true
    },
    classes: {}
};

CardList.propTypes = {
    /**
    Specifies render function for a card items.
    */
    children: PropTypes.func.isRequired,
    /**
    Extend classes for root container
    */
    className: PropTypes.string,
    /**
    Extend classes for different elements of component, such as: draggableElement, container, appCursor.
    */
    classes: PropTypes.object,
    /**
    Specifies drag settings
    */
    drag: shapes.dragShape,
    /**
    Specifies the ability to drag
    */
    draggable: PropTypes.bool,
    /**
    Specifies the presence or absence of a divider
    */
    hideDivider: PropTypes.bool,
    /**
    Specifies items to display.
    */
    items: PropTypes.array.isRequired,
    /**
    Specifies handler for drag end
    */
    onDragEnd: PropTypes.func,
    /**
    Specifies handler for drag move
    */
    onDragMove: PropTypes.func,
    /**
    Specifies handler for drag start
    */
    onDragStart: PropTypes.func
};

CardList.displayName = 'CardList';

export default CardList;
