import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../Button';
import { componentsTranslations } from '../../translations';
import withCancelAction from './withCancelAction';

const CancelYesActions = ({ buttonSize, disabled, onYes }) => (
    <Button color="primary" className="text-uppercase" size={buttonSize} disabled={disabled} onClick={onYes}>
        <FormattedMessage {...componentsTranslations.ng_generic_yes} />
    </Button>
);

CancelYesActions.propTypes = {
    buttonSize: PropTypes.string,
    disabled: PropTypes.bool,
    onYes: PropTypes.func
};

CancelYesActions.displayName = 'CancelYesActions';

export default withCancelAction(CancelYesActions);
