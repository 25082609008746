import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InvisibleInput from '../InvisibleInput';

class TimeUnit extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { onChange } = this.props;
        const { value, maxLength, pattern } = e.target;

        if (Number.isInteger(value.length) && value.length <= maxLength) {
            let regExp = new RegExp(/^\d+$/);

            if (value.length > 0 && !regExp.test(value)) {
                return false;
            }

            if (value.length === maxLength) {
                regExp = new RegExp(pattern);

                if (!regExp.test(value)) {
                    return false;
                }
            }

            if (onChange) {
                onChange(e);
            }
        }
    }

    render() {
        const { value, name, placeholder, pattern, className, maxLength, ...other } = this.props;

        const classes = classNames('onsolve-time-input__mask-input', className);

        return (
            <InvisibleInput
                {...other}
                className={classes}
                name={name}
                value={value}
                placeholder={placeholder}
                maxLength={maxLength}
                pattern={pattern}
                onChange={this.handleChange}
            />
        );
    }
}

TimeUnit.propTypes = {
    className: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired
};

TimeUnit.defaultProps = {
    maxLength: 2
};

TimeUnit.displayName = 'TimeUnit';

export default TimeUnit;
