import React from 'react';
import SvgIcon from './SvgIcon';
const FileImage = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    fill="#43a6dd"
                    d="M14.263 16h-12.526c-0.407 0-0.737-0.311-0.737-0.696v-14.609c0-0.384 0.33-0.696 0.737-0.696h8.842l4.421 4.174v11.13c0 0.384-0.33 0.696-0.737 0.696z"
                />
                <path fill="#2a8fbc" d="M10.579 0v3.478c0 0.384 0.33 0.696 0.737 0.696h3.684l-4.421-4.174z" />
                <path fill="#fff" d="M8.079 6.783c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z" />
                <path
                    fill="#fff"
                    d="M11.23 10.212l-1.842-2.087c-0.14-0.159-0.426-0.159-0.566 0l-1.544 1.749-0.826-1.040c-0.139-0.175-0.451-0.175-0.589 0l-1.105 1.391c-0.084 0.105-0.097 0.246-0.035 0.364s0.19 0.192 0.33 0.192h5.895c0.143 0 0.273-0.078 0.334-0.2s0.041-0.266-0.051-0.37z"
                />
            </g>
        </SvgIcon>
    );
};

FileImage.displayName = 'FileImageIcon';

export default FileImage;
