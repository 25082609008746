import React from 'react';
import SvgIcon from './SvgIcon';

const Board = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M13.6875,1 C14.4123737,1 15,1.58762627 15,2.3125 L15,2.3125 L15,13.6875 C15,14.4123737 14.4123737,15 13.6875,15 L13.6875,15 L2.3125,15 C1.58762627,15 1,14.4123737 1,13.6875 L1,13.6875 L1,2.3125 C1,1.58762627 1.58762627,1 2.3125,1 L2.3125,1 Z M13.6875,1.875 L2.3125,1.875 C2.07087542,1.875 1.875,2.07087542 1.875,2.3125 L1.875,2.3125 L1.875,13.6875 C1.875,13.9291246 2.07087542,14.125 2.3125,14.125 L2.3125,14.125 L13.6875,14.125 C13.9291246,14.125 14.125,13.9291246 14.125,13.6875 L14.125,13.6875 L14.125,2.3125 C14.125,2.07087542 13.9291246,1.875 13.6875,1.875 L13.6875,1.875 Z M6.6875,8.875 C6.92912458,8.875 7.125,9.07087542 7.125,9.3125 L7.125,9.3125 L7.125,11.9375 C7.125,12.1791246 6.92912458,12.375 6.6875,12.375 L6.6875,12.375 L4.0625,12.375 C3.82087542,12.375 3.625,12.1791246 3.625,11.9375 L3.625,11.9375 L3.625,9.3125 C3.625,9.07087542 3.82087542,8.875 4.0625,8.875 L4.0625,8.875 Z M11.9375,3.625 C12.1791246,3.625 12.375,3.82087542 12.375,4.0625 L12.375,4.0625 L12.375,11.9375 C12.375,12.1791246 12.1791246,12.375 11.9375,12.375 L11.9375,12.375 L9.3125,12.375 C9.07087542,12.375 8.875,12.1791246 8.875,11.9375 L8.875,11.9375 L8.875,4.0625 C8.875,3.82087542 9.07087542,3.625 9.3125,3.625 L9.3125,3.625 Z M6.25,9.75 L4.5,9.75 L4.5,11.5 L6.25,11.5 L6.25,9.75 Z M11.5,4.5 L9.75,4.5 L9.75,11.5 L11.5,11.5 L11.5,4.5 Z M6.6875,3.625 C6.92912458,3.625 7.125,3.82087542 7.125,4.0625 L7.125,4.0625 L7.125,6.6875 C7.125,6.92912458 6.92912458,7.125 6.6875,7.125 L6.6875,7.125 L4.0625,7.125 C3.82087542,7.125 3.625,6.92912458 3.625,6.6875 L3.625,6.6875 L3.625,4.0625 C3.625,3.82087542 3.82087542,3.625 4.0625,3.625 L4.0625,3.625 Z M6.25,4.5 L4.5,4.5 L4.5,6.25 L6.25,6.25 L6.25,4.5 Z"
        />
    </SvgIcon>
);

Board.displayName = 'BoardIcon';

export default Board;
