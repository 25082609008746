import React from 'react';
import SvgIcon from './SvgIcon';

const CheckedCircle = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M8,0.9c3.9,0,7.1,3.2,7.1,7.1s-3.2,7.1-7.1,7.1S0.9,11.9,0.9,8S4.1,0.9,8,0.9 M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8 s8-3.6,8-8S12.4,0,8,0L8,0z" />
            <path d="M12.7,4.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2l-5.7,5.7l-2-2C4,8.1,3.8,8,3.7,8C3.5,8,3.4,8.1,3.3,8.2 C3,8.4,3,8.8,3.3,9l2.5,2.5c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l6.2-6.2C13,5.1,13,4.7,12.7,4.5z" />
        </g>
    </SvgIcon>
);

CheckedCircle.displayName = 'CheckedCircleIcon';

export default CheckedCircle;
