import React from 'react';

import SvgIcon from './SvgIcon';

const MultipleUsers = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <g transform="translate(1 1.5)" fill="none">
                <ellipse cx="4.813" cy="3.25" rx="2.625" ry="2.786" />
                <ellipse cx="10.938" cy="3.25" rx="1.75" ry="1.857" />
                <path d="M4.813 7.893h0c-2.417 0-4.375 2.079-4.375 4.643h8.75c0-2.564-1.96-4.643-4.376-4.643h0zM9.486 7.429c.43-.304.935-.466 1.451-.465h0c1.45 0 2.626 1.247 2.626 2.786v.929h-2.626" />
            </g>
        </SvgIcon>
    );
};

MultipleUsers.displayName = 'MultipleUsersIcon';

export default MultipleUsers;
