import React from 'react';
import SvgIcon from './SvgIcon';

const ExternalLinkAlt = props => (
    <SvgIcon {...props}>
        <path d="M3.333 0v1.667H1.667v6.666h6.666V6.667H10v1.666C10 9.253 9.254 10 8.333 10H1.667C.747 10 0 9.254 0 8.333V1.667C0 .747.746 0 1.667 0h1.666zM10 0v4.856L8.144 3l-3 3L4 4.856l3-3L5.144 0H10z" />
    </SvgIcon>
);

ExternalLinkAlt.displayName = 'ExternalLinkAltIcon';

export default ExternalLinkAlt;
