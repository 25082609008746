import { ENTITY_TYPES } from '../../constants';
import { ImageBlock } from './ImageBlock';

const createImagePlugin = (config = {}) => {
    let Image = ImageBlock;

    if (config.decorator) {
        Image = config.decorator(ImageBlock);
    }

    return {
        blockRendererFn: (block, { getEditorState }) => {
            if (block.getType() === 'atomic') {
                const contentState = getEditorState().getCurrentContent();
                const entity = block.getEntityAt(0);

                if (!entity) {
                    return null;
                }
                const type = contentState.getEntity(entity).getType();

                if (type === ENTITY_TYPES.IMAGE) {
                    return {
                        component: Image,
                        editable: false
                    };
                }
                return null;
            }

            return null;
        }
    };
};

export default createImagePlugin;
