import React from 'react';

import SvgIcon from './SvgIcon';

const Message = props => (
    <SvgIcon {...props}>
        <path d="M1.875 1h12.25c.483 0 .875.392.875.875v8.75a.875.875 0 01-.875.875H9.75L4.5 15v-3.5H1.875A.875.875 0 011 10.625v-8.75C1 1.392 1.392 1 1.875 1z" />
    </SvgIcon>
);

Message.displayName = 'MessageIcon';

export default Message;
