import { RichUtils } from 'draft-js';

import { moveSelectionTo } from '../../utils';

const createRemovalCommandsPlugin = () => {
    const handleKeyCommand = (command, oldState, _, { setEditorState }) => {
        const selection = oldState.getSelection();
        const content = oldState.getCurrentContent();
        const startKey = selection.getStartKey();
        const currentBlock = content.getBlockForKey(startKey);
        const blockAfter = content.getBlockAfter(startKey);
        const blockBefore = content.getBlockBefore(startKey);

        let validState = oldState;

        if (currentBlock && currentBlock.getType() === 'atomic') {
            if (['backspace', 'backspace-word', 'backspace-to-start-of-line'].includes(command)) {
                validState = moveSelectionTo(validState, blockAfter);
            }
            if (['delete', 'delete-word', 'delete-to-end-of-block'].includes(command)) {
                validState = moveSelectionTo(validState, blockBefore);
            }
        }

        const newState = RichUtils.handleKeyCommand(validState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    return {
        handleKeyCommand
    };
};

export default createRemovalCommandsPlugin;
