import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyleButton } from '.';

const BlockStyleControls = forwardRef(
    ({ editorState, iconComponent, onToggle, style, intl, translate, disabled }, ref) => {
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <StyleButton
                isActive={style === blockType}
                onToggle={onToggle}
                style={style}
                intl={intl}
                translate={translate}
                iconComponent={iconComponent}
                ref={ref}
                disabled={disabled}
            />
        );
    }
);

BlockStyleControls.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

BlockStyleControls.defaultProps = {
    disabled: false
};

BlockStyleControls.displayName = 'BlockStyleControls';

export default BlockStyleControls;
