import React from 'react';
import SvgIcon from './SvgIcon';

const List = props => (
    <SvgIcon {...props}>
        <defs>
            <path
                d="M3.625,11.5 C4.15,11.5 4.5,11.85 4.5,12.375 L4.5,14.125 C4.5,14.65 4.15,15 3.625,15 L1.875,15 C1.35,15 1,14.65 1,14.125 L1,12.375 C1,11.85 1.35,11.5 1.875,11.5 L3.625,11.5 Z M3.625,6.25 C4.15,6.25 4.5,6.6 4.5,7.125 L4.5,8.875 C4.5,9.4 4.15,9.75 3.625,9.75 L1.875,9.75 C1.35,9.75 1,9.4 1,8.875 L1,7.125 C1,6.6 1.35,6.25 1.875,6.25 L3.625,6.25 Z M3.625,1 C4.15,1 4.5,1.35 4.5,1.875 L4.5,3.625 C4.5,4.15 4.15,4.5 3.625,4.5 L1.875,4.5 C1.35,4.5 1,4.15 1,3.625 L1,1.875 C1,1.35 1.35,1 1.875,1 L3.625,1 Z M6.25,12.375 L15,12.375 L15,14.125 L6.25,14.125 L6.25,12.375 Z M6.25,7.125 L15,7.125 L15,8.875 L6.25,8.875 L6.25,7.125 Z M6.25,1.875 L15,1.875 L15,3.625 L6.25,3.625 L6.25,1.875 Z"
                id="path-1"
            />
            <rect id="path-3" x="0" y="0" width="16" height="16" />
        </defs>
        <g id="Phase-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Coverage---Month-small" transform="translate(-43.000000, -242.000000)">
                <g id="icons/16/bullet-list-69" transform="translate(42.000000, 241.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <use id="Path-4" fill="#3777BC" xlinkHref="#path-1" />
                    <g id="colors/$blue:-#3777bc" mask="url(#mask-2)">
                        <g id="$primary-color:-#3777bc">
                            <mask id="mask-4" fill="white">
                                <use xlinkHref="#path-3" />
                            </mask>
                            <use id="Base" fill="#D8D8D8" opacity="0" xlinkHref="#path-3" />
                            <rect id="Base" fill="#3777BC" mask="url(#mask-4)" x="0" y="0" width="16" height="16" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

List.displayName = 'ListIcon';

export default List;
