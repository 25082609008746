import assign from 'lodash/assign';
import chain from 'lodash/chain';
import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import entries from 'lodash/entries';
import escapeRegExp from 'lodash/escapeRegExp';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import isDate from 'lodash/isDate';
import intersectionWith from 'lodash/intersectionWith';
import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import once from 'lodash/once';
import orderBy from 'lodash/orderBy';
import overEvery from 'lodash/overEvery';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import set from 'lodash/set';
import slice from 'lodash/slice';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import times from 'lodash/times';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import upperFirst from 'lodash/upperFirst';
import values from 'lodash/values';
import flatMapDeep from 'lodash/flatMapDeep';
import without from 'lodash/without';
import compose from 'lodash/flowRight';
import has from 'lodash/has';
import maxBy from 'lodash/maxBy';
import concat from 'lodash/concat';
import isMatch from 'lodash/isMatch';
import join from 'lodash/join';
import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import xor from 'lodash/xor';
import flatMap from 'lodash/flatMap';

const fp = {
    assign,
    camelCase,
    chain,
    cloneDeep,
    cloneDeepWith,
    compose,
    compact,
    concat,
    debounce,
    difference,
    differenceWith,
    entries,
    escapeRegExp,
    every,
    filter,
    find,
    findIndex,
    flatMap,
    flatMapDeep,
    flatten,
    forEach,
    get,
    groupBy,
    identity,
    includes,
    intersectionWith,
    isDate,
    isEmpty,
    isEqual,
    isEqualWith,
    isFunction,
    isMatch,
    isNil,
    isNumber,
    isUndefined,
    join,
    keyBy,
    keys,
    map,
    mapKeys,
    mapValues,
    maxBy,
    merge,
    omit,
    omitBy,
    once,
    orderBy,
    overEvery,
    has,
    pick,
    pickBy,
    range,
    reduce,
    remove,
    set,
    slice,
    some,
    sortBy,
    startCase,
    sumBy,
    times,
    toLower,
    trim,
    union,
    uniq,
    uniqBy,
    uniqWith,
    upperFirst,
    values,
    without,
    xor
};

export default fp;
