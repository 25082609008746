import React from 'react';

import SvgIcon from './SvgIcon';

const RemoveCircle = props => (
    <SvgIcon width="15" height="15" {...props}>
        <circle cx="7.5" cy="7.5" r="6.5" fill="#00000000" stroke="#8A8C8C" />
        <path stroke="#8A8C8C" d="M3.5 7.5L11.5 7.5" />
    </SvgIcon>
);

RemoveCircle.displayName = 'RemoveCircleIcon';

export default RemoveCircle;
