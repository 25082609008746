import React from 'react';

import SvgIcon from './SvgIcon';

const Reload = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M13.7,7.7c0.4,0,0.7,0.3,0.7,0.7c0,3.3-2.9,6-6.4,6c-1.6,0-3.1-0.6-4.2-1.7l0,1.1c0,0.4-0.3,0.7-0.7,0.7
	c-0.3,0-0.6-0.2-0.6-0.6l0-0.1v-3.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1l-0.1,0c0,0,0,0,0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l0,0h3.3C6.7,10,7,10.3,7,10.7c0,0.3-0.2,0.6-0.6,0.6l-0.1,0l-2,0
	c0.9,1.1,2.2,1.8,3.7,1.8c2.8,0,5.1-2.1,5.1-4.7C13.1,8,13.4,7.7,13.7,7.7z M12.9,1.6c0.3,0,0.6,0.2,0.6,0.6l0,0.1v3.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0l0,0.1l0,0c0,0,0,0-0.1,0l0,0l-0.1,0l0,0
	c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0L9.6,6C9.3,6,9,5.7,9,5.3
	C9,5,9.2,4.7,9.5,4.7l0.1,0l2,0C10.8,3.6,9.4,2.9,8,2.9C5.2,2.9,2.9,5,2.9,7.6c0,0.4-0.3,0.7-0.7,0.7S1.6,8,1.6,7.6
	c0-3.3,2.9-6,6.4-6c1.6,0,3.1,0.6,4.2,1.7l0-1.1C12.2,1.9,12.5,1.6,12.9,1.6z M13.3,5.9L13.3,5.9L13.3,5.9L13.3,5.9z"
            />
        </SvgIcon>
    );
};

Reload.displayName = 'ReloadIcon';

export default Reload;
