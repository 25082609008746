import {
    TextDividerIcon,
    FormatAlignCenterIcon,
    FormatAlignLeftIcon,
    FormatAlignRightIcon,
    FormatBoldIcon,
    FormatItalicIcon,
    FormatUnderlinedIcon,
    StrikethroughIcon,
    TextImageIcon,
    TextLinkIcon,
    FormatListBulletIcon,
    FormatListNumbersIcon,
    EraserIcon,
    OutdentIcon,
    IndentIcon,
    SubscriptIcon,
    SuperscriptIcon
} from '../../Icons';

export const NODE_TYPES = {
    h1: { translate: 'h1', style: 'header-one', iconComponent: null },
    h2: { translate: 'h2', style: 'header-two', iconComponent: null },
    h3: { translate: 'h3', style: 'header-three', iconComponent: null },
    h4: { translate: 'h4', style: 'header-four', iconComponent: null },
    h5: { translate: 'h5', style: 'header-five', iconComponent: null },
    h6: { translate: 'h6', style: 'header-six', iconComponent: null },
    paragraph: { translate: 'onsolve-paragraph12', style: 'unstyled', iconComponent: null },
    ul: { translate: 'ul', style: 'unordered-list-item', iconComponent: FormatListBulletIcon },
    ol: { translate: 'ol', style: 'ordered-list-item', iconComponent: FormatListNumbersIcon },
    alignLeft: { translate: 'align-left', style: 'align-left', iconComponent: FormatAlignLeftIcon },
    alignCenter: { translate: 'align-center', style: 'align-center', iconComponent: FormatAlignCenterIcon },
    alignRight: { translate: 'align-right', style: 'align-right', iconComponent: FormatAlignRightIcon },
    bold: { translate: 'bold', style: 'BOLD', iconComponent: FormatBoldIcon },
    italic: { translate: 'italic', style: 'ITALIC', iconComponent: FormatItalicIcon },
    strikeThrough: { translate: 'strikeThrough', style: 'STRIKETHROUGH', iconComponent: StrikethroughIcon },
    eraseStyles: { translate: 'eraseStyles', style: 'atomic', iconComponent: EraserIcon },
    underline: { translate: 'underline', style: 'UNDERLINE', iconComponent: FormatUnderlinedIcon },
    horizontalRule: { translate: 'horizontal-line', style: 'atomic', iconComponent: TextDividerIcon },
    link: { translate: 'text-link', style: 'link', iconComponent: TextLinkIcon },
    image: { translate: 'text-image', style: 'atomic', iconComponent: TextImageIcon },
    indentIncrease: { translate: 'indent_increase', style: 'indent_increase', iconComponent: IndentIcon },
    indentDecrease: { translate: 'indent_decrease', style: 'indent_decrease', iconComponent: OutdentIcon },
    subscript: { translate: 'subscript', style: 'SUBSCRIPT', iconComponent: SubscriptIcon },
    superscript: { translate: 'superscript', style: 'SUPERSCRIPT', iconComponent: SuperscriptIcon }
};
