import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ModalFooter } from 'reactstrap';

import { withWidth } from '../../MediaQuery';

const withModalFooter = WrappedComponent => {
    const WithModalFooter = ({ className, children, isMobile }) => {
        const buttonSize = isMobile ? 'lg' : '';

        return (
            <WrappedComponent className={className}>
                {children && cloneElement(children, { buttonSize })}
            </WrappedComponent>
        );
    };

    WithModalFooter.propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        isMobile: PropTypes.bool
    };

    WithModalFooter.displayName = 'Footer';

    return withWidth()(WithModalFooter);
};

export default withModalFooter(ModalFooter);
