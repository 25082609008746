import React from 'react';
import SvgIcon from './SvgIcon';

const Indent = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M15 12.5v1.75H1V12.5h14zm0-7v5.25l-3.5-2.625L15 5.5zM9.75 9v1.75H1V9h8.75zm0-3.5v1.75H1V5.5h8.75zM15 2v1.75H1V2h14z"
        />
    </SvgIcon>
);

Indent.displayName = 'IndentIcon';

export default Indent;
