import React from 'react';

import SvgIcon from './SvgIcon';

const ArchiveCheckIcon = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M 4.566406 9.632812 C 4.746094 9.632812 4.890625 9.785156 4.890625 9.976562 L 4.890625 11.351562 L 10.109375 11.351562 L 10.109375 9.976562 C 10.109375 9.785156 10.253906 9.632812 10.433594 9.632812 L 14.675781 9.632812 C 14.855469 9.632812 15 9.785156 15 9.976562 L 15 14.105469 C 15 14.484375 14.707031 14.792969 14.347656 14.792969 L 0.652344 14.792969 C 0.292969 14.792969 0 14.484375 0 14.105469 L 0 9.976562 C 0 9.785156 0.144531 9.632812 0.324219 9.632812 Z M 12.71875 0 C 12.898438 0 13.042969 0.152344 13.042969 0.34375 L 13.042969 8.945312 L 10.109375 8.945312 C 9.75 8.945312 9.457031 9.253906 9.457031 9.632812 L 9.457031 10.664062 L 5.542969 10.664062 L 5.542969 9.632812 C 5.542969 9.253906 5.25 8.945312 4.890625 8.945312 L 1.957031 8.945312 L 1.957031 0.34375 C 1.957031 0.152344 2.101562 0 2.28125 0 Z M 10.007812 2.855469 C 9.882812 2.722656 9.679688 2.722656 9.550781 2.851562 L 6.847656 5.707031 L 5.773438 4.574219 C 5.644531 4.441406 5.441406 4.445312 5.316406 4.578125 C 5.191406 4.710938 5.1875 4.925781 5.3125 5.058594 L 6.617188 6.433594 C 6.746094 6.570312 6.949219 6.570312 7.078125 6.433594 L 10.011719 3.339844 C 10.136719 3.203125 10.136719 2.988281 10.007812 2.855469 Z M 10.007812 2.855469 "
            />
        </SvgIcon>
    );
};

ArchiveCheckIcon.displayName = 'ArchiveCheckIcon';

export default ArchiveCheckIcon;
