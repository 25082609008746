import React from 'react';
import SvgIcon from './SvgIcon';

const SingleLineElement = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M5.4,15 C5.16067661,15 4.96666667,14.8041246 4.96666667,14.5625 C4.96666667,14.3477226 5.11995847,14.1690927 5.32210779,14.1320487 L5.4,14.125 L7.566,14.125 L7.566,1.875 L2.36666667,1.875 L2.36666667,3.1875 C2.36666667,3.4022774 2.21337486,3.58090732 2.01122555,3.61795129 L1.93333333,3.625 C1.72060143,3.625 1.54367275,3.47023423 1.50698158,3.26614118 L1.5,3.1875 L1.5,1.4375 C1.5,1.2227226 1.65329181,1.04409268 1.85544112,1.00704871 L1.93333333,1 L14.0666667,1 C14.2793986,1 14.4563273,1.15476577 14.4930184,1.35885882 L14.5,1.4375 L14.5,3.1875 C14.5,3.42912458 14.3059901,3.625 14.0666667,3.625 C13.8539348,3.625 13.6770061,3.47023423 13.6403149,3.26614118 L13.6333333,3.1875 L13.6333333,1.875 L8.441,1.875 L8.441,14.125 L10.6,14.125 C10.8393234,14.125 11.0333333,14.3208754 11.0333333,14.5625 C11.0333333,14.7772774 10.8800415,14.9559073 10.6778922,14.9929513 L10.6,15 L5.4,15 Z"
        />
    </SvgIcon>
);

SingleLineElement.displayName = 'SingleLineElementIcon';

export default SingleLineElement;
