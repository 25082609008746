import React from 'react';

import SvgIcon from './SvgIcon';

const CheckMarkIcon = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M11.15,5.15 L6.5,9.8 L4.85,8.15 C4.65,7.95 4.35,7.95 4.15,8.15 C3.95,8.35 3.95,8.65 4.15,8.85 L6.15,10.85 C6.25,10.95 6.35,11 6.5,11 C6.65,11 6.75,10.95 6.85,10.85 L11.85,5.85 C12.05,5.65 12.05,5.35 11.85,5.15 C11.65,4.95 11.35,4.95 11.15,5.15 Z"
        />
    </SvgIcon>
);

CheckMarkIcon.displayName = 'CheckMarkIcon';

export default CheckMarkIcon;
