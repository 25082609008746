import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './SvgIcon';

const SORT_ICON_TYPES = {
    UP: 'up',
    DOWN: 'down'
};

const Sort = props => {
    const { direction } = props;

    return (
        <SvgIcon
            className={direction === SORT_ICON_TYPES.DOWN ? 'onsolve-sort-icon-down' : 'onsolve-sort-icon-up'}
            {...props}
        >
            <g stroke="none" fillRule="evenodd">
                <path fill="none" d="M0 0h16v16H0z" />
                <path d={direction === SORT_ICON_TYPES.DOWN ? 'M4 7l4 4 4-4z' : 'M12 9L8 5 4 9z'} />
            </g>
        </SvgIcon>
    );
};

Sort.defaultProps = {
    direction: SORT_ICON_TYPES.DOWN
};

Sort.propTypes = {
    direction: PropTypes.oneOf(Object.values(SORT_ICON_TYPES))
};

Sort.displayName = 'SortIcon';

export default Sort;
