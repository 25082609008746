import React from 'react';

import SvgIcon from './SvgIcon';

const MSTeams = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path d="M12.289 5.329c-0.305 0-0.587 0.162-0.739 0.425s-0.152 0.587 0 0.85c0.152 0.263 0.434 0.425 0.739 0.425s0.587-0.162 0.739-0.425c0.152-0.263 0.152-0.587 0-0.85s-0.434-0.425-0.739-0.425v0zM3.143 11.886l5.61 0.971v-9.714l-5.61 0.971v7.771z M7.143 6v0.687l-0.933 0.045-0.005 2.982-0.697-0.023v-2.932l-0.937 0.040v-0.631z" />
            <path d="M10.893 5.545c0 0.538-0.447 0.974-1 0.974-0.3 0-0.568-0.129-0.75-0.331v-1.285c0.182-0.202 0.45-0.331 0.75-0.331 0.553 0 1 0.436 1 0.974zM11.393 7.735v2.677c0 0 0.392 0 0.875 0 0.441 0 0.801-0.318 0.863-0.73h0.012v-1.947h-1.75zM9.143 7.005v4.138c0 0 0.392 0 0.875 0 0.441 0 0.801-0.318 0.863-0.73h0.012v-3.407h-1.75z" />
        </g>
    </SvgIcon>
);

MSTeams.displayName = 'MSTeams';

export default MSTeams;
