import { setReason, fileMatchMaxDimensions } from '../utils';

import { REASONS } from '../constants';

const imagePromise = file => {
    const preview = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve({ img, file });
        img.onerror = reject;
        img.src = preview;
    });
};

const destroyImageUrl = image => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(image.src);
};

export const imagesValidation = (acceptedFiles, rejectedFiles, globalReason, additionalValidationParams) => {
    const _acceptedFiles = [];
    const _rejectedFiles = [...rejectedFiles];

    return Promise.all(acceptedFiles.map(file => imagePromise(file))).then(items => {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (
                !fileMatchMaxDimensions(item.img.height, additionalValidationParams.maxHeight) ||
                !fileMatchMaxDimensions(item.img.width, additionalValidationParams.maxWidth)
            ) {
                setReason(item.file, REASONS.WIDTH_HEIGHT);
                _rejectedFiles.push(item.file);
                destroyImageUrl(item.img);
                continue;
            }

            _acceptedFiles.push(item.file);
            destroyImageUrl(item.img);
        }

        return { acceptedFiles: _acceptedFiles, rejectedFiles: _rejectedFiles, globalReason };
    });
};
