import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import { IconButton, Button } from '../Button';

import { TimesIcon } from '../Icons';
import { ToastrVariants } from './ToastrConstants';

import { componentsTranslations } from '../translations';

class Toastr extends PureComponent {
    constructor(props) {
        super(props);
        this.timeoutHandler = null;
        this.handleClose = this.handleClose.bind(this);
        this.setTimeoutForToastr = this.setTimeoutForToastr.bind(this);

        this.closeButton = React.createRef();
    }

    componentDidMount() {
        const { hideAfter, isNewToastr, isAlwaysVisible } = this.props;

        if (isNewToastr && hideAfter && !isAlwaysVisible) {
            this.setTimeoutForToastr();
        }
    }

    componentDidUpdate() {
        const { hideAfter, isAlwaysVisible, isVisible } = this.props;

        if (isVisible && hideAfter && !isAlwaysVisible) {
            this.setTimeoutForToastr();
        }
    }

    componentWillUnmount() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
    }

    setTimeoutForToastr(e) {
        const { onClose, isVisible, hideAfter } = this.props;

        if (isVisible && onClose) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = setTimeout(() => onClose(e), hideAfter);
        }
    }

    handleClose(e) {
        const { onClose } = this.props;

        this.timeoutHandler && clearTimeout(this.timeoutHandler);
        onClose(e);
    }

    render() {
        const { isVisible, className, content, variant, position, onUndo, onClose, intl } = this.props;
        const contentToRender = typeof content === 'function' ? content(this.props) : content;
        const toastrColor = onUndo ? 'primary' : variant;

        const toastrClasses = classNames('onsolve-toastr', className, {
            [`onsolve-toastr--${position}`]: position.length,
            'onsolve-toastr--show': isVisible,
            'onsolve-toastr--hide': !isVisible
        });

        return (
            <div className={toastrClasses}>
                <div className={`onsolve-toastr__container onsolve-toastr--${toastrColor} text-break`}>
                    <div className="onsolve-toastr__content">{contentToRender}</div>
                    <div className="onsolve-toastr__actions">
                        {onUndo && (
                            <Button
                                autoFocus={true}
                                variant="flat"
                                size="md"
                                className="onsolve-toastr__undo-button"
                                onClick={onUndo}
                            >
                                <FormattedMessage {...componentsTranslations.ng_components_undo} />
                            </Button>
                        )}
                        {onClose && (
                            <IconButton
                                ref={this.closeButton}
                                className="onsolve-toastr__close-button"
                                onClick={this.handleClose}
                                aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
                            >
                                <TimesIcon className={`onsolve-toastr__close--${toastrColor}`} />
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

Toastr.propTypes = {
    /**
    Overrides or extends the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    The content/message inside of the Toastr.
    */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
    /**
    Hide the Toastr after X milliseconds.  If "0" is specified, it will show indefinitely until closed.
    */
    hideAfter: PropTypes.number,
    /**
    @ignore
    */
    intl: PropTypes.object,
    /**
    Disables Toastr timeout, so the element is always visible.
    */
    isAlwaysVisible: PropTypes.bool,
    /**
    Prevents setting a timeout after the component mounts (hideAfter).
    */
    isNewToastr: PropTypes.bool,
    /**
    Determines whether the Toastr is visible or hidden.
    */
    isVisible: PropTypes.bool,
    /**
    Toastr onClose event. Setting "false" will hide the "close button".
    */
    onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
    Sets "Undo" functionality.
    Sets the Toastr color to "primary".
    Shows an "Undo" button and supports "Undo" functionality.
    */
    onUndo: PropTypes.func,
    /**
    The position of the Toastr as rendered in the browser.
    */
    position: PropTypes.oneOf([
        'stack',
        'static',
        'inner',
        'top-left',
        'top-right',
        'top-center',
        'bottom-left',
        'bottom-right',
        'bottom-center'
    ]),
    /**
    The type of Toastr variant (style/color) to display (IE error, success, etc...).
    */
    variant: PropTypes.oneOf(Object.keys(ToastrVariants))
};

Toastr.defaultProps = {
    variant: ToastrVariants.danger,
    position: 'top-right',
    hideAfter: 60000
};

Toastr.displayName = 'Toastr';

export default injectIntl(Toastr);
