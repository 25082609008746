import React from 'react';
import SvgIcon from './SvgIcon';

const Settings = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M13.3,5.2 L14.4,3.1 L13,1.7 L10.9,2.8 C10.6,2.6 10.2,2.5 9.8,2.4 L9,0 L7,0 L6.2,2.3 C5.9,2.4 5.5,2.5 5.2,2.7 L3.1,1.6 L1.6,3.1 L2.7,5.2 C2.5,5.5 2.4,5.9 2.3,6.2 L0,7 L0,9 L2.3,9.8 C2.4,10.2 2.6,10.5 2.7,10.9 L1.6,13 L3,14.4 L5.1,13.3 C5.4,13.5 5.8,13.6 6.2,13.7 L7,16 L9,16 L9.8,13.7 C10.2,13.6 10.5,13.4 10.9,13.3 L13,14.4 L14.4,13 L13.3,10.9 C13.5,10.6 13.6,10.2 13.7,9.8 L16,9 L16,7 L13.7,6.2 C13.6,5.9 13.5,5.5 13.3,5.2 Z M8,11 C6.3,11 5,9.7 5,8 C5,6.3 6.3,5 8,5 C9.7,5 11,6.3 11,8 C11,9.7 9.7,11 8,11 Z"
        />
    </SvgIcon>
);

Settings.displayName = 'SettingsIcon';

export default Settings;
