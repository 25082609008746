import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../Focusable';
import { InputFieldExclamation } from '../InputBase';

const InputFieldContainer = React.forwardRef((props, ref) => {
    const {
        active,
        children,
        className,
        error,
        focused,
        inputIcon,
        showExclamation,
        tabIndex,
        variant,
        ...other
    } = props;

    const rootClasses = classNames('onsolve-input-field__container', className, {
        'onsolve-input-field__container--bordered': variant === 'bordered',
        'onsolve-input-field__container--standard': variant === 'standard' || variant === 'default',
        'onsolve-input-field__container--error': error,
        'onsolve-input-field__container--active': !error && active,
        'onsolve-input-field__container--naked': variant === 'naked',
        'onsolve-input-field__container--focused': focused,
        'onsolve-input-field__container--outlined': variant === 'outlined'
    });

    return (
        <Focusable
            render={({ classes }) => (
                <div className={classNames(classes, rootClasses)} tabIndex={tabIndex} ref={ref} {...other}>
                    <div className="onsolve-input-field__text">{children}</div>
                    {showExclamation && error ? (
                        <InputFieldExclamation error={error} />
                    ) : inputIcon ? (
                        <div className="onsolve-input-field__icon">{inputIcon}</div>
                    ) : null}
                </div>
            )}
        />
    );
});

InputFieldContainer.propTypes = {
    /**
     Specifies an active state of input.
     */
    active: PropTypes.bool,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies a focused state of input.
     */
    focused: PropTypes.bool,
    /**
     Specifies an icon for input.
     */
    inputIcon: PropTypes.element,
    /**
     Specifies if the exclamation should be showed.
     */
    showExclamation: PropTypes.bool,
    /**
     Specifies a tab Index.
     */
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     Specifies a variant of the Input.
     */
    variant: PropTypes.oneOf(['default', 'standard', 'naked', 'outlined', 'bordered'])
};

InputFieldContainer.defaultProps = {
    error: false,
    active: false,
    showExclamation: true,
    variant: 'standard'
};

InputFieldContainer.displayName = 'InputFieldContainer';

export default InputFieldContainer;
