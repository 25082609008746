import getPartialState from './getPartialState';

import { isEmpty } from '../utils';

/**
 * Converts a nested node into an associative array with pointers to child and parent nodes
 * Given:
 ```
 const tree = [
 {
    label: 'item1',
    value: 'value1',
    children: [
      {
        label: 'item1-1',
        value: 'value1-1',
        children: [
          {
            label: 'item1-1-1',
            value: 'value1-1-1'
          },
          {
            label: 'item1-1-2',
            value: 'value1-1-2'
          }
        ]
      },
      {
        label: 'item1-2',
        value: 'value1-2'
      }
    ]
  },
 {
    label: 'item2',
    value: 'value2',
    children: [
      {
        label: 'item2-1',
        value: 'value2-1',
        children: [
          {
            label: 'item2-1-1',
            value: 'value2-1-1'
          },
          {
            label: 'item2-1-2',
            value: 'value2-1-2'
          },
          {
            label: 'item2-1-3',
            value: 'item2-1-3',
            children: [
              {
                label: 'item2-1-3-1',
                value: 'value2-1-3-1'
              }
            ]
          }
        ]
      },
      {
        label: 'item2-2',
        value: 'value2-2'
      }
    ]
  }
 ]
 ```
 * results in
 ```
 {
  "0": {
    _id: "0",
    _parent: null,
    _children: [
      "0-0",
      "0-1"
    ],
    label: "item1",
    value: "value1"
  },
  "1": {
    _id: "1",
    _parent: null,
    _children: [
      "1-0",
      "1-1"
    ],
    label: "item2",
    value: "value2"
  },
  "0-0": {
    _id: "0-0",
    _parent: "0",
    _children: [
      "0-0-0",
      "0-0-1"
    ],
    label: "item1-1",
    value: "value1-1"
  },
  "0-1": {
    _id: "0-1",
    _parent: "0",
    label: "item1-2",
    value: "value1-2"
  },
  "0-0-0": {
    _id: "0-0-0",
    _parent: "0-0",
    label: "item1-1-1",
    value: "value1-1-1"
  },
  "0-0-1": {
    _id: "0-0-1",
    _parent: "0-0",
    label: "item1-1-2",
    value: "value1-1-2"
  },
  "1-0": {
    _id: "1-0",
    _parent: "1",
    _children: [
      "1-0-0",
      "1-0-1",
      "1-0-2"
    ],
    label: "item2-1",
    value: "value2-1"
  },
  "1-1": {
    _id: "1-1",
    _parent: "1",
    label: "item2-2",
    value: "value2-2"
  },
  "1-0-0": {
    _id: "1-0-0",
    _parent: "1-0",
    label: "item2-1-1",
    value: "value2-1-1"
  },
  "1-0-1": {
    _id: "1-0-1",
    _parent: "1-0",
    label: "item2-1-2",
    value: "value2-1-2"
  },
  "1-0-2": {
    _id: "1-0-2",
    _parent: "1-0",
    _children: [
      "1-0-2-0"
    ],
    label: "item2-1-3",
    value: "value2-1-3"
  },
  "1-0-2-0": {
    _id: "1-0-2-0",
    _parent: "1-0-2",
    label: "item2-1-3-1",
    value: "value2-1-3-1"
  }
}
 ```
 * @param  {[type]} tree              The incoming tree object
 * @param  {[bool]} radio             Whether its in Radio select variant (radio dropdown)
 * @param  {[bool]} showPartialState  Whether to show partially checked state
 * @param  {[string]} rootPrefixId    The prefix to use when setting root node ids
 * @return {object}                   The flattened list
 */
function flattenTree({
    tree,
    dataItemKey,
    radio,
    showPartialState,
    hierarchical,
    inheritDisable,
    simpleSelect,
    multiSelect,
    rootPrefixId,
    showExpanded,
    value,
    expandedNodes = []
}) {
    const forest = Array.isArray(tree) ? tree : [tree];

    // eslint-disable-next-line no-use-before-define
    return walkNodes({
        dataItemKey,
        nodes: forest,
        radio,
        showPartialState,
        hierarchical,
        rootPrefixId,
        simpleSelect,
        multiSelect,
        inheritDisable,
        showExpanded,
        value,
        expandedNodes
    });
}

/**
 * If the node didn't specify anything on its own
 * figure out the initial state based on parent
 * @param {object} node           [current node]
 * @param {object} parent         [node's immediate parent]
 * @param {boolean}   inheritChecked [if checked should be inherited]
 * @param {boolean}   inheritDisable [if checked should be inherited]
 */
function setInitialStateProps(node, parent = {}, inheritChecked = true, inheritDisable) {
    const stateProps = [];

    if (inheritChecked) {
        stateProps.push('checked');
    }

    if (inheritDisable) {
        stateProps.push('disabled');
    }

    for (let index = 0; index < stateProps.length; index++) {
        const prop = stateProps[index];

        // if and only if, node doesn't explicitly define a prop, grab it from parent
        if (node[prop] === undefined && parent[prop] !== undefined) {
            node[prop] = parent[prop];
        }
    }
}

function checkNodes({ simpleSelect, radio, _rv, node }) {
    if ((simpleSelect || radio) && _rv.defaultValues.length === 0) {
        _rv.defaultValues.push(node._id);
        node.checked = true;
        _rv.singleSelectedNode = node;
    }

    if (!simpleSelect) {
        _rv.defaultValues.push(node._id);
        node.checked = true;
    }
}

function walkNodes({
    dataItemKey,
    nodes,
    parent,
    inheritDisable,
    depth = 0,
    radio,
    showPartialState,
    hierarchical,
    multiSelect,
    rootPrefixId,
    simpleSelect,
    _rv = { list: new Map(), defaultValues: [], singleSelectedNode: null },
    showExpanded,
    value,
    expandedNodes
}) {
    nodes.forEach((node, i) => {
        node._depth = depth;
        const nodeId = node[dataItemKey];

        if (node.children && node.children.length) {
            node.expanded = showExpanded || expandedNodes.includes(node[dataItemKey]);
        }

        if (parent) {
            node._id = nodeId || `${parent._id}-${i}`;
            node._parent = parent._id;
            parent._children.push(node._id);
        } else {
            node._id = nodeId || `${rootPrefixId ? `${rootPrefixId}-${i}` : i}`;
        }

        if (!isEmpty(value)) {
            if (value.includes(node[dataItemKey])) {
                checkNodes({ simpleSelect, _rv, node, radio });
            } else {
                node.checked = !!(multiSelect && parent && parent.checked);
            }
        } else {
            if (node.checked || node.isDefaultValue) {
                checkNodes({ simpleSelect, _rv, node, radio });
            }
        }

        if (!hierarchical || radio) {
            setInitialStateProps(node, parent, !radio && !simpleSelect, inheritDisable);
        }

        _rv.list.set(node._id, node);
        if (node.children) {
            node._children = [];
            walkNodes({
                dataItemKey,
                nodes: node.children,
                parent: node,
                depth: depth + 1,
                radio,
                showPartialState,
                hierarchical,
                simpleSelect,
                multiSelect,
                _rv,
                showExpanded,
                inheritDisable,
                value,
                expandedNodes
            });

            if (showPartialState && !node.checked) {
                node.partial = getPartialState(node);

                // re-check if all children are checked. if so, check thyself
                if (!isEmpty(node.children) && node.children.every(c => c.checked)) {
                    node.checked = true;
                }
            }

            node.children = undefined;
        }
    });

    return _rv;
}

export default flattenTree;
