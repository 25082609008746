import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Card = React.forwardRef((props, ref) => {
    const { background, border, className, focused, variant, ...other } = props;
    const baseClass = 'onsolve-card';
    const rootClass = classnames(
        baseClass,
        {
            [`bg-${background}`]: !!background,
            [`border border-color-${border}`]: !!border,
            'text-white': !!background,
            [`${baseClass}--focused`]: !!focused,
            [`${baseClass}--outlined`]: variant === 'outlined',
            [`${baseClass}--compact`]: variant === 'compact'
        },
        className
    );

    return <div className={rootClass} ref={ref} {...other} />;
});

Card.propTypes = {
    /**
     Specifies that a color of background.
     */
    background: PropTypes.string,
    /**
     Specifies that a color of border.
     */
    border: PropTypes.string,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies a focused state of the Card.
     */
    focused: PropTypes.bool,
    /**
     Specifies variant of the Card.
     */
    variant: PropTypes.oneOf(['outlined', 'flat', 'compact'])
};

Card.defaultProps = {
    variant: 'outlined'
};

Card.displayName = 'Card';

export default Card;
