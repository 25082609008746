import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortableHandle } from 'react-sortable-hoc';

import { shapes } from '../../common/constants';
import { EllipsisVerticalIcon } from '../Icons';

const CardDraggableIcon = sortableHandle(({ color, size, disabled, className }) => {
    const handleClick = e => e.stopPropagation();

    return (
        <div className={classNames('onsolve-card-list__cell--drag', className)} onClick={handleClick}>
            <EllipsisVerticalIcon color={color} size={size} disabled={disabled} />
        </div>
    );
});

CardDraggableIcon.propTypes = {
    className: PropTypes.string,
    color: shapes.iconColorsShape,
    disabled: PropTypes.bool,
    size: shapes.iconSizeShape
};

CardDraggableIcon.displayName = 'CardDraggableIcon';

export default CardDraggableIcon;
