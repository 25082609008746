import React from 'react';
import SvgIcon from './SvgIcon';

const Eye = props => {
    return (
        <SvgIcon {...props}>
            <defs>
                <path
                    d="M8 10.583c-1.48 0-2.687-1.15-2.687-2.583S6.52 5.417 8 5.417 10.688 6.568 10.688 8 9.48 10.583 8 10.583zm0-1c.937 0 1.688-.714 1.688-1.583S8.937 6.417 8 6.417 6.313 7.13 6.313 8 7.063 9.583 8 9.583zM2.316 8.425a12.07 12.07 0 0 0 1.28 1.513c1.35 1.347 2.836 2.146 4.405 2.146s3.054-.798 4.405-2.146a12.07 12.07 0 0 0 1.28-1.513l.29-.425-.29-.425a12.07 12.07 0 0 0-1.28-1.513C11.054 4.715 9.568 3.917 8 3.917s-3.054.798-4.405 2.146a12.07 12.07 0 0 0-1.28 1.513L2.025 8l.29.425zm-1.312-.674c.1-.157.258-.422.5-.76a13.06 13.06 0 0 1 1.386-1.638C4.4 3.837 6.123 2.917 8 2.917s3.6.92 5.11 2.438a13.06 13.06 0 0 1 1.386 1.638l.5.76a.5.5 0 0 1 0 .497c-.1.157-.258.422-.5.76a13.06 13.06 0 0 1-1.386 1.638C11.6 12.163 9.877 13.083 8 13.083s-3.6-.92-5.11-2.438a13.06 13.06 0 0 1-1.386-1.638l-.5-.76a.5.5 0 0 1 0-.497z"
                    id="EYEA"
                />
                <path id="EYEB" d="M0 0h16v16H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="EYEC" fill="#fff">
                    <use xlinkHref="#A" />
                </mask>
                <use fill="#3777bc" fillRule="nonzero" xlinkHref="#EYEA" />
                <g mask="url(#EYEC)">
                    <mask id="EYED" fill="#fff">
                        <use xlinkHref="#EYEB" />
                    </mask>
                    <use fill="#d8d8d8" opacity="0" xlinkHref="#EYEB" />
                    <path fill="#3777bc" mask="url(#EYED)" d="M0 0h16v16H0z" />
                </g>
            </g>
        </SvgIcon>
    );
};

Eye.displayName = 'EyeIcon';

export default Eye;
