/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { format, getTime, isValid, isEqual } from 'date-fns';

import { DomUtils } from '../../utils';

const getDateWithoutTime = date => new Date(date.getYear(), date.getMonth(), date.getDate());

const isEqualDate = (selectedDate, currentDate) => {
    if (!selectedDate || !currentDate) {
        return false;
    }
    return isEqual(getDateWithoutTime(selectedDate), getDateWithoutTime(currentDate));
};

const Day = props => {
    const dayElementRef = useRef();
    const { autoFocus, day, dayFormat, selected, inRange, disabled, isCurrent, focusedDate, id } = props;
    const target = `date-picker-${id}__day--${getTime(day)}`;
    const rootClasses = classNames(
        'onsolve-date-picker__day',
        {
            'onsolve-date-picker__day--focused': isValid(focusedDate) && isEqualDate(focusedDate, day) && !disabled
        },
        { 'onsolve-date-picker__day--withinrange': !disabled && !selected && inRange },
        { 'onsolve-date-picker__day--selected': !disabled && selected },
        { 'onsolve-date-picker__day--current': isCurrent && !disabled }
    );

    useLayoutEffect(() => {
        if (autoFocus && isValid(focusedDate) && isEqualDate(focusedDate, day)) {
            DomUtils.setFocus(dayElementRef);
        }
    }, [focusedDate, autoFocus]);

    const handleClick = e => {
        const { day, disabled, onChange } = props;

        if (disabled) {
            return;
        }
        if (onChange) {
            onChange(e, day);
        }
    };

    const handleKeyDown = e => {
        const { onKeyDown } = props;

        if (onKeyDown) {
            onKeyDown(e, day);
        }
    };

    const getTabIndex = () => {
        if (disabled) {
            return null;
        }

        if (isValid(focusedDate) && isEqualDate(focusedDate, day)) {
            return 0;
        }

        if (isCurrent && !isValid(focusedDate)) {
            return 0;
        }

        return null;
    };

    const renderAriaLabel = date => {
        return format(date, 'eeee, do MMMM yyyy');
    };

    const renderCell = date => {
        return format(date, dayFormat);
    };

    return (
        <span
            ref={dayElementRef}
            className={rootClasses}
            onClick={handleClick}
            aria-label={renderAriaLabel(day)}
            aria-disabled={disabled}
            aria-selected={!disabled && selected}
            disabled={disabled}
            id={target}
            tabIndex={getTabIndex()}
            onKeyDown={handleKeyDown}
            data-day={day}
        >
            {renderCell(day)}
        </span>
    );
};

Day.propTypes = {
    autoFocus: PropTypes.bool,
    day: PropTypes.instanceOf(Date).isRequired,
    dayFormat: PropTypes.string,
    disabled: PropTypes.bool,
    focusedDate: PropTypes.instanceOf(Date),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inRange: PropTypes.bool,
    isCurrent: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    range: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    selected: PropTypes.bool
};

Day.defaultProps = {
    id: 0,
    dayFormat: 'd',
    selected: false,
    inRange: false
};

Day.displayName = 'Day';

export default Day;
