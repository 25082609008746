import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { keyCodes } from '../../common/constants';
import { DomUtils } from '../utils';

const Tabs = ({ className, onChange, value, variant, border, button, ...props }) => {
    const tabsRef = useRef(null);

    const getCurrentValue = value => {
        return typeof value === 'number' ? value.toString() : value;
    };

    const handleKeyDown = e => {
        const linkSelector = `a.onsolve-${variant}__link`;

        const keyCode = DomUtils.getCharCode(e);
        const { SPACE, RIGHT, LEFT, HOME, END } = keyCodes;
        const { activeElement } = document;

        let nextFocusable;

        if (keyCode === HOME || keyCode === END) {
            const { firstElementChild, lastElementChild } = tabsRef.current;

            nextFocusable = (keyCode === HOME ? firstElementChild : lastElementChild).querySelector(linkSelector);
        }

        if (keyCode === RIGHT || keyCode === LEFT) {
            const { current: tabs } = tabsRef;
            const { parentNode: listItem } = activeElement;

            let sibling = keyCode === RIGHT ? listItem.nextElementSibling : listItem.previousElementSibling;

            if (!sibling) {
                sibling = keyCode === RIGHT ? tabs.firstElementChild : tabs.lastElementChild;
            }
            nextFocusable = sibling.querySelector(linkSelector);
        }

        if (keyCode === SPACE && activeElement) {
            e.preventDefault();
            activeElement.click();
        }

        if (nextFocusable) {
            e.stopPropagation();
            e.preventDefault();
            DomUtils.setFocus(nextFocusable);
        }
    };

    const baseClass = `onsolve-${variant}`;

    const rootClasses = classnames(baseClass, className, {
        [`${baseClass}--${border}`]: variant !== 'pills'
    });

    const wrapperClasses = classnames({
        [`${baseClass}__wrapper`]: variant === 'separated'
    });

    const children = React.Children.map(props.children, (child, index) => {
        if (!React.isValidElement(child)) {
            return null;
        }

        const childIndex = index + 1;
        const childValue = child.props.value === undefined ? childIndex : child.props.value;
        const currentValue = getCurrentValue(childValue);
        const selected = currentValue === value.toString();

        return React.cloneElement(child, {
            onChange,
            selected,
            value: currentValue,
            variant,
            tabIndex: selected ? '0' : '-1'
        });
    });

    if (variant === 'separated') {
        return (
            <div className={wrapperClasses}>
                <ul className={rootClasses} ref={tabsRef} onKeyDown={handleKeyDown}>
                    {children}
                </ul>
                {button}
            </div>
        );
    }

    return (
        <ul className={rootClasses} ref={tabsRef} onKeyDown={handleKeyDown}>
            {children}
        </ul>
    );
};

Tabs.propTypes = {
    border: PropTypes.oneOf(['standard', 'striped']).isRequired,
    button: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['tabs', 'pills', 'separated']).isRequired
};

Tabs.defaultProps = {
    variant: 'tabs',
    border: 'standard'
};

Tabs.displayName = 'Tabs';

export default Tabs;
