export const getEntityAtCursorPosition = editorState => {
    const contentState = editorState.getCurrentContent();
    const focusKey = editorState.getSelection().getStartKey();
    const focusOffset = editorState.getSelection().getStartOffset();
    const block = contentState.getBlockForKey(focusKey);
    const key = block.getEntityAt(focusOffset);

    if (key) {
        const entity = contentState.getEntity(key);

        if (entity) {
            return entity;
        }
    }

    return null;
};
