import React from 'react';
import SvgIcon from './SvgIcon';
const Folder = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle" x="0" y="0" width="16" height="16" />
                <path
                    d="M5.86956522,3.2 L4.65217391,2 L1.60869565,2 C1.2723913,2 1,2.2685 1,2.6 L1,12.8 C1,13.4627 1.54508696,14 2.2173913,14 L13.7826087,14 C14.454913,14 15,13.4627 15,12.8 L15,4.4 C15,3.7373 14.454913,3.2 13.7826087,3.2 L5.86956522,3.2 Z"
                    id="Shape"
                    fill="#43A6DD"
                    fillRule="nonzero"
                />
                <path
                    d="M15,5 L1,5 L1,2.6 C1,2.2685 1.2723913,2 1.60869565,2 L4.65217391,2 L5.86956522,3.2 L13.7826087,3.2 C14.454913,3.2 15,3.7373 15,4.4 L15,5 Z"
                    id="Shape"
                    fill="#3685B1"
                    fillRule="nonzero"
                />
            </g>
        </SvgIcon>
    );
};

Folder.displayName = 'FolderIcon';

export default Folder;
