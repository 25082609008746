import React from 'react';
import SvgIcon from './SvgIcon';

const Profile = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8 0c-4.411 0-8 3.589-8 8s3.589 8 8 8c4.411 0 8-3.589 8-8s-3.589-8-8-8zM5.333 6.667c0-1.473 1.229-2.667 2.667-2.667s2.667 1.194 2.667 2.667v0.667c0 1.473-1.229 2.667-2.667 2.667s-2.667-1.194-2.667-2.667v-0.667zM8 14.667c-1.622 0-3.11-0.584-4.267-1.551 0.559-1.055 1.656-1.783 2.934-1.783h2.667c1.278 0 2.375 0.727 2.934 1.783-1.157 0.967-2.645 1.551-4.267 1.551z"
        />
    </SvgIcon>
);

Profile.displayName = 'ProfileIcon';

export default Profile;
