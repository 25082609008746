import React from 'react';
import SvgIcon from './SvgIcon';

const FormatListNumbers = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M5 1h11v2H5zm0 6h11v2H5zm0 6h11v2H5zM.368 4v-.55l.597-.05c.097-.007.1-.035.1-.14V.84c0-.083-.02-.125-.1-.146L.382.57.46 0h1.667v3.264c0 .1.007.132.1.14l.57.05V4H.368zm-.32 6v-.63l.907-.886c.683-.663.975-.934.975-1.32 0-.25-.122-.433-.48-.433-.373 0-.528.122-.528.602L0 7.252C.007 6.176.826 6 1.536 6c1.05 0 1.42.447 1.42 1.083s-.44 1.056-.934 1.53l-.765.73h.995c.068 0 .095-.014.108-.08l.095-.534h.717V10H.047zm2.297 3.682c.566.087.88.426.88 1.026 0 .88-.66 1.292-1.612 1.292C.94 16 .34 15.76 0 15.387l.533-.586c.253.246.533.44 1 .44.373 0 .693-.133.693-.6 0-.406-.253-.573-.666-.573a2.73 2.73 0 0 0-.493.047v-.666l.327-.04c.48-.06.74-.293.74-.72 0-.253-.113-.46-.486-.46-.36 0-.532.12-.532.593l-.912-.08c0-1.052.793-1.232 1.492-1.232 1.026 0 1.42.393 1.42 1.106a1.04 1.04 0 0 1-.766 1.039v.027z"
            />
        </SvgIcon>
    );
};

FormatListNumbers.displayName = 'FormatListNumbersIcon';

export default FormatListNumbers;
