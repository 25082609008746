import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fp as _ } from '../utils';
import StepperHeader from './StepperHeader';
import StepperBody from './StepperBody';

class Stepper extends Component {
    render() {
        const { children, className, variant, ...other } = this.props;
        const steps = _.map(Children.toArray(children), step => ({ ...step.props }));

        return (
            <div className={classNames('onsolve-stepper', `onsolve-stepper--${variant}`, className)}>
                <StepperHeader steps={steps} {...other} />
                <StepperBody steps={steps} {...other} />
            </div>
        );
    }
}

Stepper.propTypes = {
    /**
     * A zero-based index of the active step.
     */
    activeStep: PropTypes.number,
    /**
     * An array of the Step components.
     */
    children: PropTypes.node.isRequired,
    /**
     * Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     * Callback function on step change event of the stepper.
     * @param {number} newActiveStep - new index of the active step.
     * @returns {void}
     */
    onStepChange: PropTypes.func,
    /**
     * The variants to represent component.
     */
    variant: PropTypes.oneOf(['tabs', 'inline'])
};

Stepper.defaultProps = {
    activeStep: 0,
    variant: 'tabs'
};

Stepper.displayName = 'Stepper';

export default Stepper;
