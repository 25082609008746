import React from 'react';

import SvgIcon from './SvgIcon';

const Loading = props => (
    <SvgIcon {...props}>
        <path
            fill="none"
            d="M8,0.6C3.9,0.6,0.6,3.9,0.6,8s3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4l0,0C15.4,3.9,12.1,0.6,8,0.6"
        />
        <polygon fill="none" points="9.4,3.5 13.4,10.2 2.6,10.2 6.6,3.5 11.9,12.5 4,12.5 " />
    </SvgIcon>
);

Loading.displayName = 'LoadingIcon';

export default Loading;
