import React from 'react';
import SvgIcon from './SvgIcon';

const Globus = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M10.889 13.999c0.806-1.319 1.335-3.192 1.427-5.332h2.317c-0.235 2.354-1.703 4.345-3.744 5.332zM1.367 8.667h2.317c0.092 2.14 0.621 4.013 1.427 5.332-2.041-0.987-3.509-2.978-3.744-5.332zM5.111 2.001c-0.806 1.319-1.335 3.193-1.427 5.333h-2.317c0.235-2.355 1.703-4.345 3.744-5.333zM8.667 7.333v-5.8c1.123 0.64 2.178 2.821 2.317 5.8h-2.317zM8.667 14.467v-5.8h2.317c-0.139 2.979-1.193 5.159-2.317 5.8zM7.333 8.667v5.8c-1.123-0.641-2.178-2.821-2.317-5.8h2.317zM7.333 1.533v5.8h-2.317c0.139-2.979 1.193-5.159 2.317-5.8zM14.633 7.333h-2.317c-0.092-2.14-0.621-4.013-1.427-5.333 2.041 0.987 3.509 2.978 3.744 5.333zM8 0c-4.411 0-8 3.589-8 8s3.589 8 8 8c4.411 0 8-3.589 8-8s-3.589-8-8-8z"
        />
    </SvgIcon>
);

Globus.displayName = 'GlobusIcon';

export default Globus;
