import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputFieldControl, InputFieldGroup, InputFieldContainer, InputFieldLabel, InputFieldMessage } from '.';

class InputFieldWrapper extends Component {
    render() {
        const {
            active,
            children,
            className,
            error,
            errorText,
            helpText,
            hideMaxLength,
            innerRef,
            label,
            variant,
            currentLength,
            maxLength,
            tabIndex,
            showLabelExclamation,
            name
        } = this.props;

        return (
            <InputFieldControl className={className}>
                <InputFieldGroup>
                    {label && (
                        <InputFieldLabel name={name} error={error}>
                            {label}
                        </InputFieldLabel>
                    )}
                    <InputFieldContainer
                        tabIndex={tabIndex}
                        variant={variant}
                        error={error}
                        active={active}
                        showExclamation={!showLabelExclamation}
                        ref={innerRef}
                    >
                        {children}
                    </InputFieldContainer>
                </InputFieldGroup>
                <InputFieldMessage
                    error={error}
                    errorText={errorText}
                    helpText={helpText}
                    currentLength={currentLength}
                    maxLength={maxLength}
                    hideMaxLength={hideMaxLength}
                />
            </InputFieldControl>
        );
    }
}

InputFieldWrapper.propTypes = {
    /**
     Specifies an active state of input.
     */
    active: PropTypes.bool,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     The current length of input message.
     */
    currentLength: PropTypes.number,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies the error message.
     */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a help text that shows below the input.
     */
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a hidden status of max length counter.
     */
    hideMaxLength: PropTypes.bool,
    /**
     Specifies a ref for a tag.
     */
    innerRef: PropTypes.object,
    /**
     Specifies a label of input.
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a max length value.
     */
    maxLength: PropTypes.number,
    /**
     Specifies htmlFor attribute.
     */
    name: PropTypes.string,
    /**
     Specifies if the exclamation should be showed.
     */
    showLabelExclamation: PropTypes.bool,
    /**
     Specifies a tab Index.
     */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     Specifies variant of the Input.
     */
    variant: PropTypes.oneOf(['standard', 'naked', 'bordered'])
};

InputFieldWrapper.defaultProps = {
    hideMaxLength: false
};

InputFieldWrapper.displayName = 'InputFieldWrapper';

export default InputFieldWrapper;
