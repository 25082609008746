import { defineMessages } from 'react-intl';

const translations = defineMessages({
    ng_components_apply: {
        id: 'ng_components_apply',
        defaultMessage: 'Apply'
    },
    ng_components_betaToastr_title: {
        id: 'ng_betaToastr_title',
        defaultMessage: 'OnSolve has made updates to Beta!'
    },
    ng_components_betaToastr_link: {
        id: 'ng_betaToastr_link',
        defaultMessage: "see what's new"
    },
    ng_components_betaToastr_content_note: {
        id: 'ng_betaToastr_content_note',
        defaultMessage: 'Note:'
    },
    ng_components_betaToastr_content: {
        id: 'ng_betaToastr_content',
        defaultMessage:
            'Your Beta experience includes ALL features OnSolve has to offer, which may differ from your contracted subscription to the service.'
    },
    ng_components_filters: {
        id: 'ng_components_filters',
        defaultMessage: 'Filters'
    },
    ng_components_close: {
        id: 'ng_components_close',
        defaultMessage: 'Close'
    },
    ng_components_reset: {
        id: 'ng_components_reset',
        defaultMessage: 'Reset'
    },
    ng_components_selectDivisions: {
        id: 'ng_components_selectDivisions',
        defaultMessage: 'Select division(s)'
    },
    ng_components_divisionField: {
        id: 'ui.page.notification.compose.section.details.divisionLabel',
        defaultMessage: 'Division'
    },
    ng_components_division_dropdown_options_selected: {
        id: 'ng_components_division_dropdown_options_selected',
        defaultMessage: '{0} options selected'
    },
    ng_components_division_dropdown_matches_found: {
        id: 'ng_components_division_dropdown_matches_found',
        defaultMessage: '{0} matches'
    },
    ng_components_undo: {
        id: 'ng_toastrUndo',
        defaultMessage: 'Undo'
    },
    ng_components_previous_page: {
        id: 'ng_components_previous_page',
        defaultMessage: 'previous page'
    },
    ng_components_next_page: {
        id: 'ng_components_next_page',
        defaultMessage: 'next page'
    },
    ng_components_select_page_label: {
        id: 'ng_components_select_page_label',
        defaultMessage: 'Display'
    },
    ng_components_select_page_helpText: {
        id: 'ng_components_select_page_helpText',
        defaultMessage: 'Entries'
    },
    ng_components_select_page_description: {
        id: 'ng_components_select_page_description',
        defaultMessage: 'Select page size'
    },
    ng_components_select_all: {
        id: 'ng_common_select_all',
        defaultMessage: 'Select all'
    },
    ng_components_select_rows: {
        id: 'ng_components_select_rows',
        defaultMessage: 'Select rows'
    },
    ng_components_selectAllPages: {
        id: 'ng_selectAllPages',
        defaultMessage: 'Select All Pages'
    },
    ng_components_selectThisPage: {
        id: 'ng_selectThisPage',
        defaultMessage: 'Select This Page'
    },
    ng_components_selectNone: {
        id: 'ng_selectNone',
        defaultMessage: 'Select None'
    },
    ng_components_county_code_label: {
        id: 'ng_components_county_code_label',
        defaultMessage: 'Country Code'
    },
    ng_components_phone_number_label: {
        id: 'ng_components_phone_number_label',
        defaultMessage: 'Phone Number'
    },
    ng_components_loading: {
        id: 'ng_loading',
        defaultMessage: 'Loading'
    },
    ng_components_loadingMsg: {
        id: 'ng_loadingMsg',
        defaultMessage: 'This will only take a moment.'
    },
    ng_common_assign: {
        id: 'ng_common_assign',
        defaultMessage: 'Assign'
    },
    ng_common_cancel: {
        id: 'ui.button.cancel',
        defaultMessage: 'Cancel'
    },
    ng_common_confirm: {
        id: 'Confirm',
        defaultMessage: 'Confirm'
    },
    ng_common_continue: {
        id: 'continue',
        defaultMessage: 'Continue'
    },
    ng_common_create: {
        id: 'ng_create',
        defaultMessage: 'Create'
    },
    ng_common_delete: {
        id: 'ui.button.delete',
        defaultMessage: 'Delete'
    },
    ng_common_discard: {
        id: 'discard',
        defaultMessage: 'Discard'
    },
    ng_common_done: {
        id: 'ng_common_done',
        defaultMessage: 'Done'
    },
    ng_common_ok: {
        id: 'ok',
        defaultMessage: 'OK'
    },
    ng_common_save: {
        id: 'ui.button.save',
        defaultMessage: 'Save'
    },
    ng_common_yesExit: {
        id: 'ng_common_yesExit',
        defaultMessage: 'Yes, Exit'
    },
    ng_generic_add: {
        id: 'ng_add',
        defaultMessage: 'Add'
    },
    ng_generic_remove: {
        id: 'ui.button.remove',
        defaultMessage: 'Remove'
    },
    ng_generic_yes: {
        id: 'ng_generic_yes',
        defaultMessage: 'Yes'
    },
    ng_generic_delete: {
        id: 'ui.button.delete',
        defaultMessage: 'Delete'
    },
    ng_wysiwyg_editor_toolbar_dropdown_headers: {
        id: 'ng_wysiwyg_editor_toolbar_dropdown_headers',
        defaultMessage: 'dropdown headers'
    },
    ng_wysiwyg_editor_toolbar_h1: {
        id: 'ng_wysiwyg_editor_toolbar_h1',
        defaultMessage: 'header one'
    },
    ng_wysiwyg_editor_toolbar_h2: {
        id: 'ng_wysiwyg_editor_toolbar_h2',
        defaultMessage: 'header two'
    },
    ng_wysiwyg_editor_toolbar_h3: {
        id: 'ng_wysiwyg_editor_toolbar_h3',
        defaultMessage: 'header tree'
    },
    ng_wysiwyg_editor_toolbar_h4: {
        id: 'ng_wysiwyg_editor_toolbar_h4',
        defaultMessage: 'header four'
    },
    ng_wysiwyg_editor_toolbar_h5: {
        id: 'ng_wysiwyg_editor_toolbar_h5',
        defaultMessage: 'header five'
    },
    ng_wysiwyg_editor_toolbar_h6: {
        id: 'ng_wysiwyg_editor_toolbar_h6',
        defaultMessage: 'header six'
    },
    ng_wysiwyg_editor_toolbar_ul: {
        id: 'ng_wysiwyg_editor_toolbar_ul',
        defaultMessage: 'bulleted list'
    },
    ng_wysiwyg_editor_toolbar_ol: {
        id: 'ng_wysiwyg_editor_toolbar_ol',
        defaultMessage: 'numbered list'
    },
    ng_wysiwyg_editor_toolbar_subscript: {
        id: 'ng_wysiwyg_editor_toolbar_subscript',
        defaultMessage: 'subscript'
    },
    ng_wysiwyg_editor_toolbar_superscript: {
        id: 'ng_wysiwyg_editor_toolbar_superscript',
        defaultMessage: 'superscript'
    },
    ['ng_wysiwyg_editor_toolbar_onsolve-paragraph12']: {
        id: 'ng_wysiwyg_editor_toolbar_onsolve-paragraph12',
        defaultMessage: 'paragraph'
    },
    ['ng_wysiwyg_editor_toolbar_align-left']: {
        id: 'ng_wysiwyg_editor_toolbar_align-left',
        defaultMessage: 'align left'
    },
    ['ng_wysiwyg_editor_toolbar_align-center']: {
        id: 'ng_wysiwyg_editor_toolbar_align-center',
        defaultMessage: 'align center'
    },
    ['ng_wysiwyg_editor_toolbar_align-right']: {
        id: 'ng_wysiwyg_editor_toolbar_align-right',
        defaultMessage: 'align right'
    },
    ['ng_wysiwyg_editor_toolbar_horizontal-line']: {
        id: 'ng_wysiwyg_editor_toolbar_horizontal-line',
        defaultMessage: 'horizontal line'
    },
    ['ng_wysiwyg_editor_toolbar_text-image']: {
        id: 'ng_wysiwyg_editor_toolbar_text-image',
        defaultMessage: 'image'
    },
    ng_wysiwyg_editor_toolbar_bold: {
        id: 'ng_wysiwyg_editor_toolbar_bold',
        defaultMessage: 'bold'
    },
    ng_wysiwyg_editor_toolbar_underline: {
        id: 'ng_wysiwyg_editor_toolbar_underline',
        defaultMessage: 'underlined'
    },
    ng_wysiwyg_editor_toolbar_italic: {
        id: 'ng_wysiwyg_editor_toolbar_italic',
        defaultMessage: 'italic'
    },
    ng_wysiwyg_editor_toolbar_strikeThrough: {
        id: 'ng_wysiwyg_editor_toolbar_strikeThrough',
        defaultMessage: 'strike-through'
    },
    [`ng_wysiwyg_editor_toolbar_text-link`]: {
        id: 'ng_wysiwyg_editor_toolbar_text-link',
        defaultMessage: 'text link'
    },
    ng_wysiwyg_editor_toolbar_indent_increase: {
        id: 'ng_wysiwyg_editor_toolbar_indent_increase',
        defaultMessage: 'increase indent'
    },
    ng_wysiwyg_editor_toolbar_eraseStyles: {
        id: 'ng_wysiwyg_editor_toolbar_eraseStyles',
        defaultMessage: 'erase styles'
    },
    ng_wysiwyg_editor_toolbar_indent_decrease: {
        id: 'ng_wysiwyg_editor_toolbar_indent_decrease',
        defaultMessage: 'decrease indent'
    },
    ng_wysiwyg_editor_link_modal_add: {
        id: 'ng_wysiwyg_editor_link_modal_add',
        defaultMessage: 'Insert Hyperlink'
    },
    ng_wysiwyg_editor_link_modal_add_description: {
        id: 'ng_wysiwyg_editor_link_modal_add_description',
        defaultMessage: 'Enter the display text and URL below.'
    },
    ng_wysiwyg_editor_link_modal_add_display_text: {
        id: 'ng_wysiwyg_editor_link_modal_add_display_text',
        defaultMessage: 'Display Text'
    },
    ng_wysiwyg_editor_link_modal_add_display_text_placeholder: {
        id: 'ng_wysiwyg_editor_link_modal_add_display_text_placeholder',
        defaultMessage: 'Enter display text'
    },
    ng_wysiwyg_editor_link_modal_add_display_text_error_max_length: {
        id: 'ng_wysiwyg_editor_link_modal_add_display_text_error_max_length',
        defaultMessage: 'Display text cannot exceed {0} characters'
    },
    ng_wysiwyg_editor_link_modal_add_display_text_error_required: {
        id: 'ng_wysiwyg_editor_link_modal_add_display_text_error_required',
        defaultMessage: 'Display text required'
    },
    ng_wysiwyg_editor_link_modal_add_target_url: {
        id: 'ng_wysiwyg_editor_link_modal_add_target_url',
        defaultMessage: 'Target URL'
    },
    ng_wysiwyg_editor_link_modal_add_email_address: {
        id: 'ng_wysiwyg_editor_link_modal_add_email_address',
        defaultMessage: 'Email Address'
    },
    ng_wysiwyg_editor_link_modal_add_target_url_placeholder: {
        id: 'ng_wysiwyg_editor_link_modal_add_target_url_placeholder',
        defaultMessage: 'http://www.onsolve.com'
    },
    ng_wysiwyg_editor_link_modal_add_email_address_placeholder: {
        id: 'ng_wysiwyg_editor_link_modal_add_email_address_placeholder',
        defaultMessage: 'Enter email address'
    },
    ng_wysiwyg_editor_link_modal_add_target_url_error_required: {
        id: 'ng_wysiwyg_editor_link_modal_add_target_url_error_required',
        defaultMessage: 'Target URL required'
    },
    ng_wysiwyg_editor_link_modal_add_open_in_separate_windows: {
        id: 'ng_wysiwyg_editor_link_modal_add_open_in_separate_windows',
        defaultMessage: 'Open link in separate window'
    },
    ng_wysiwyg_editor_type_visual_editor: {
        id: 'ng_wysiwyg_editor_type_visual_editor',
        defaultMessage: 'Visual Editor'
    },
    ng_wysiwyg_editor_type_html_editor: {
        id: 'ng_wysiwyg_editor_type_html_editor',
        defaultMessage: 'HTML editor'
    },
    ng_wysiwyg_editor_type_preview: {
        id: 'ng_wysiwyg_editor_type_preview',
        defaultMessage: 'Preview'
    },
    ng_wysiwyg_editor_type_warning_title: {
        id: 'ng_wysiwyg_editor_type_warning_title',
        defaultMessage: 'Change to Visual Editor'
    },
    ng_wysiwyg_editor_type_warning_description: {
        id: 'ng_wysiwyg_editor_type_warning_description',
        defaultMessage:
            'The Visual Editor does not support full HTML. You may loose work done in the HTML Editor.\n\n\n\nDo you still want to switch to the Visual Editor?'
    },
    ng_components_noResultsDescription: {
        id: 'ng_components_noResultsDescription',
        defaultMessage: "Try adjusting your search or filter to find what you're looking for."
    },
    ng_components_noResultsTitle: {
        id: 'ng_components_noResultsTitle',
        defaultMessage: 'No Results Found'
    },
    ng_components_hours: {
        id: 'ng_components_hours',
        defaultMessage: 'Hours'
    },
    ng_components_midday: {
        id: 'ng_components_midday',
        defaultMessage: 'Midday'
    },
    ng_components_minutes: {
        id: 'ng_components_minutes',
        defaultMessage: 'Minutes'
    },
    ng_components_seconds: {
        id: 'ng_components_seconds',
        defaultMessage: 'Seconds'
    },
    ng_components_day: {
        id: 'ng_components_day',
        defaultMessage: 'Day'
    },
    ng_components_month: {
        id: 'ng_components_month',
        defaultMessage: 'Month'
    },
    ng_components_year: {
        id: 'ng_components_year',
        defaultMessage: 'Year'
    },
    ng_components_previousPeriod: {
        id: 'ng_components_previousPeriod',
        defaultMessage: 'Switch to the previous {0}'
    },
    ng_components_nextPeriod: {
        id: 'ng_components_nextPeriod',
        defaultMessage: 'Switch to the next {0}'
    },
    ng_components_meridiemAfter: {
        id: 'ng_components_meridiemAfter',
        defaultMessage: '{0} Meridiem'
    },
    ng_components_paginationInfoTotal: {
        id: 'ng_components_paginationInfoTotal',
        defaultMessage: 'Showing {0} Result'
    },
    ng_components_paginationInfoRange: {
        id: 'ng_components_paginationInfoRange',
        defaultMessage: 'Showing {0} to {1} of {2} Results'
    },
    ng_components_paginationInfoRangePlus: {
        id: 'ng_components_paginationInfoRangePlus',
        defaultMessage: 'Showing {0} to {1} of {2}+ Results'
    },
    ng_components_ok: {
        id: 'ng_components_ok',
        defaultMessage: 'OK'
    },
    ng_components_search: {
        id: 'ng_components_search',
        defaultMessage: 'Search'
    },
    ng_components_startsWith: {
        id: 'ng_components_startsWith',
        defaultMessage: 'Starts with'
    },
    ng_components_contains: {
        id: 'ng_components_contains',
        defaultMessage: 'Contains'
    },
    ng_file_upload_height_or_width_to_large: {
        id: 'ng_file_upload_width_to_large',
        defaultMessage: 'the size in pixels is too large.'
    },
    ng_file_upload_multiple_files_support: {
        id: 'ng_file_upload_multiple_files_support',
        defaultMessage: 'You can upload only one file'
    },
    ng_file_upload_placeholder: {
        id: 'ng_file_upload_placeholder',
        defaultMessage: 'Drag an image here or <b>browse</b> for a file to upload.'
    },
    ng_file_upload_brief_placeholder: {
        id: 'ng_file_upload_brief_placeholder',
        defaultMessage: 'Drop your file here, or <b>Browse</b>'
    },
    ng_file_upload_placeholder_description: {
        id: 'ng_file_upload_placeholder_description',
        defaultMessage: 'File name must not exceed {0} characters.<br /> Maximum file size: {1}'
    },
    ng_file_upload_mb_size: {
        id: 'ng_file_upload_mb_size',
        defaultMessage: '{0} MB'
    },
    ng_file_upload_rejected_files_modal: {
        id: 'ng_file_upload_rejected_files_modal',
        defaultMessage: 'Cannot Upload File'
    },
    ng_file_upload_rejected_text: {
        id: 'ng_file_upload_rejected_text',
        defaultMessage: 'We cannot upload {fileName}, because {rejectReason}'
    },
    ng_file_upload_limits_text: {
        id: 'ng_file_upload_limits_text',
        defaultMessage:
            'Your image can be in {acceptMimeTypes} format, its size must not exceed {maxHeight}px x {maxWidth}px and the file size must be {maxSize} MB or less.'
    },
    ng_file_upload_select_different: {
        id: 'ng_file_upload_select_different',
        defaultMessage: 'Please select a different image and try again.'
    },
    ng_file_upload_type_error: {
        id: 'ng_file_upload_type_error',
        defaultMessage: 'File type is invalid'
    },
    ng_file_upload_size_error: {
        id: 'ng_file_upload_size_error',
        defaultMessage: 'Attachment file size must not exceed {0}'
    },
    ng_file_upload_name_error: {
        id: 'ng_file_upload_name_error',
        defaultMessage: 'Attachment file name must be {0} to {1} characters length'
    },
    ng_file_upload_size_to_big: {
        id: 'ng_file_upload_size_to_big',
        defaultMessage: 'the file size is too big.'
    },
    ng_file_upload_type_not_support: {
        id: 'ng_file_upload_type_not_support',
        defaultMessage: 'this type of file is not supported.'
    },
    ng_file_upload_uploading_files: {
        id: 'ng_file_upload_uploading_files',
        defaultMessage: 'Uploading'
    },
    ng_components_addLanguage: {
        id: 'ng_components_addLanguage',
        defaultMessage: 'Add language'
    },
    ng_components_removeLanguageQuestion: {
        id: 'ng_alerts_remove_question_var',
        defaultMessage: 'Remove {0} Language?'
    },
    ng_components_removeLanguageDescription: {
        id: 'ng_alerts_remove_language_var',
        defaultMessage: '{0} language and all associated delivery methods will be removed from this alert.'
    },
    ng_components_enter_duplicate_item: {
        id: 'ng_components_enter_duplicate_item',
        defaultMessage: 'Item already entered. Enter a new item'
    },
    ng_components_linkType: {
        id: 'ng_components_linkType',
        defaultMessage: 'Link Type'
    },
    ng_components_webAddress: {
        id: 'ng_components_webAddress',
        defaultMessage: 'Web Address'
    },
    ng_components_emailAddress: {
        id: 'ng_components_emailAddress',
        defaultMessage: 'Email Address'
    },
    ng_components_pictureUrl: {
        id: 'ng_components_pictureUrl',
        defaultMessage: 'Picture URL'
    },
    ng_components_menu: {
        id: 'ng_components_menu',
        defaultMessage: 'menu'
    }
});

export default translations;
