import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SwitcherBase from './SwitcherBase';

const Switcher = props => {
    const { icon, checkedIcon, classes, ...other } = props;

    return (
        <SwitcherBase
            type="checkbox"
            icon={icon}
            checkedIcon={checkedIcon}
            classes={{ ...classes, checkmark: 'onsolve-switcher__bar' }}
            {...other}
        />
    );
};

Switcher.propTypes = {
    /**
    Specifies the custom component to replace checked state view
    */
    checkedIcon: PropTypes.node,
    /**
    Specifies classNames as an object. Available classNames: root, checkmark, label
    */
    classes: PropTypes.object,
    /**
    Specifies the custom component to replace default state view
    */
    icon: PropTypes.node
};

Switcher.defaultProps = {
    icon: <span className="onsolve-switcher__knob" />,
    checkedIcon: <span className={classNames('onsolve-switcher__knob', 'onsolve-switcher__knob--checked')} />
};

Switcher.displayName = 'Switcher';

export default Switcher;
