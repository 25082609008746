import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';

import { keyCodes } from '../../../../../common/constants';
import { DomUtils } from '../../../../utils';

export const getTagId = id => `${id}_tag`;

class Tag extends PureComponent {
    handleClick = e => {
        const { id, onDelete } = this.props;

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onDelete(id, (e.key || e.keyCode) !== undefined);
    };

    onKeyDown = e => {
        if (DomUtils.getCharCode(e) === keyCodes.BACKSPACE) {
            this.handleClick(e);
            e.preventDefault();
        }
    };

    onKeyUp = e => {
        if (
            DomUtils.getCharCode(e) === keyCodes.SPACE ||
            [keyCodes.DELETE, keyCodes.ENTER_KEY].indexOf(DomUtils.getCharCode(e)) > -1
        ) {
            this.handleClick(e);
            e.preventDefault();
        }
    };

    render() {
        const { id, label, labelRemove = 'Remove', readOnly, disabled } = this.props;

        const tagId = getTagId(id);
        const buttonId = `${id}_button`;
        const className = cx('tag-remove', { readOnly }, { disabled });
        const isDisabled = readOnly || disabled;

        return (
            <span className="tag" id={tagId} aria-label={label}>
                {label}
                <button
                    id={buttonId}
                    onClick={!isDisabled ? this.handleClick : undefined}
                    onKeyDown={!isDisabled ? this.onKeyDown : undefined}
                    onKeyUp={!isDisabled ? this.onKeyUp : undefined}
                    className={className}
                    type="button"
                    aria-label={labelRemove}
                    aria-labelledby={`${buttonId} ${tagId}`}
                    aria-disabled={isDisabled}
                >
                    x
                </button>
            </span>
        );
    }
}

Tag.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelRemove: PropTypes.string,
    onDelete: PropTypes.func,
    readOnly: PropTypes.bool
};

Tag.displayName = 'Tag';

export default Tag;
