import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { componentsTranslations } from '../../../../translations';
import { keyCodes } from '../../../../../common/constants';

import { DomUtils } from '../../../../utils';
import { getAriaLabel } from '../a11y';
import { getTagId } from '../tag';

class Trigger extends PureComponent {
    getAriaAttributes = () => {
        const { variant, showDropdown, clientId, tags, intl } = this.props;
        const label = intl.formatMessage(componentsTranslations.ng_components_divisionField);

        const triggerId = `${clientId}_trigger`;
        const labelledBy = [];
        let labelAttributes = getAriaLabel(label);

        if (tags && tags.length) {
            if (labelAttributes['aria-label']) {
                // Adds reference to self when having aria-label
                labelledBy.push(triggerId);
            }
            tags.forEach(t => {
                labelledBy.push(getTagId(t._id));
            });
            labelAttributes = getAriaLabel(label, labelledBy.join(' '));
        }

        const attributes = {
            id: triggerId,
            role: 'button',
            tabIndex: -1,
            'aria-haspopup': variant === 'simpleSelect' ? 'listbox' : 'tree',
            'aria-expanded': showDropdown ? 'true' : 'false',
            ...labelAttributes
        };

        return attributes;
    };

    handleTrigger = e => {
        const { ENTER_KEY, SPACE, DOWN } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        // Just return if triggered from keyDown and the key isn't enter, space or arrow down
        if (e.key && charCode !== ENTER_KEY && charCode !== SPACE && charCode !== DOWN) {
            return;
        } else if (e.key && this.triggerNode && this.triggerNode !== document.activeElement) {
            // Do not trigger if not activeElement
            return;
        } else if (!this.props.showDropdown && charCode === SPACE) {
            // Avoid adding space to input on open

            e.preventDefault();
        }

        // Else this is a key press that should trigger the dropdown
        this.props.onTrigger(e);
    };

    render() {
        const { disabled, readOnly, showDropdown } = this.props;

        const dropdownTriggerClassname = cx('onsolve-dropdown-tree-trigger', {
            disabled,
            readOnly,
            top: showDropdown,
            bottom: !showDropdown
        });

        return (
            <div
                ref={node => {
                    this.triggerNode = node;
                }}
                className={dropdownTriggerClassname}
                onClick={!disabled ? this.handleTrigger : undefined}
                onKeyDown={!disabled ? this.handleTrigger : undefined}
                {...this.getAriaAttributes()}
            >
                {this.props.children}
            </div>
        );
    }
}

Trigger.propTypes = {
    children: PropTypes.node,
    clientId: PropTypes.string,
    disabled: PropTypes.bool,
    intl: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onTrigger: PropTypes.func,
    readOnly: PropTypes.bool,
    showDropdown: PropTypes.bool,
    tags: PropTypes.array,
    variant: PropTypes.oneOf(['multiSelect', 'simpleSelect', 'radioSelect', 'hierarchical'])
};

Trigger.displayName = 'Trigger';

export default Trigger;
