import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxSquare = props => (
    <SvgIcon {...props}>
        <path
            d="M7 0c.6 0 1 .4 1 1s-.4 1-1 1H2v10h10V8c0-.6.4-1 1-1s1 .4 1 1v5c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1zm5.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-2-2c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L6 6.6z"
            fillRule="nonzero"
        />
    </SvgIcon>
);

CheckBoxSquare.displayName = 'CheckBoxSquareIcon';

export default CheckBoxSquare;
