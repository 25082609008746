export const ToastrVariants = {
    primary: 'primary',
    success: 'success',
    warning: 'warning',
    danger: 'danger'
};

export const ToastrActions = {
    Insert: 'INSERT',
    Remove: 'REMOVE',
    RemoveAll: 'REMOVE_ALL'
};
