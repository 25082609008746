import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { CheckedCircleIcon, ExclamationIcon, InfoCircleIcon, TimesIcon } from '../Icons';
import { IconButton } from '../Button';
import { componentsTranslations } from '../translations';
import { OnsolvePropTypes } from '../utils';

const iconsLookup = {
    success: CheckedCircleIcon,
    danger: ExclamationIcon,
    primary: InfoCircleIcon
};

const Alert = forwardRef(
    (
        {
            color,
            message,
            onClose,
            isOpen,
            icon,
            actions,
            iconClassName,
            className,
            messageClassName,
            closeButtonClassName,
            closeTimeout,
            hideTimeout,
            children
        },
        ref
    ) => {
        const intl = useIntl();
        const timeoutHandler = useRef();

        const handleClose = useCallback(() => {
            if (onClose) {
                onClose();
            }
            clearTimeout(timeoutHandler.current);
        }, [onClose]);

        const setAlertTimeout = useCallback(
            value => {
                if (isOpen) {
                    timeoutHandler.current = setTimeout(handleClose, value * 1000);
                }
            },
            [isOpen, handleClose]
        );

        useEffect(() => {
            const value = closeTimeout || hideTimeout;

            if (isOpen && value && value !== 0) {
                setAlertTimeout(value);
            }
            return () => {
                clearTimeout(timeoutHandler.current);
            };
        }, [isOpen, closeTimeout, hideTimeout, setAlertTimeout]);

        const containerClassNames = classNames(
            'onsolve-alert__container',
            `onsolve-alert--${color}`,
            { 'onsolve-alert__container--show': isOpen },
            { 'onsolve-alert__container--hide': !isOpen },
            className
        );
        const messageClassNames = classNames('onsolve-alert__message', `text-${color}`, messageClassName);
        const closeButtonClassNames = classNames('onsolve-alert__close', closeButtonClassName);
        const iconClassNames = classNames(`onsolve-alert__icon onsolve-alert__icon--${color}`, iconClassName);

        const Icon = icon ? icon : iconsLookup[color];

        return (
            <div tabIndex="-1" ref={ref} className={containerClassNames} role="alert">
                <span className={iconClassNames}>
                    <Icon color={color} />
                </span>
                <div className={messageClassNames}>{message || children}</div>
                {(actions || onClose) && (
                    <div className="onsolve-alert__action">
                        {actions}
                        {onClose && (
                            <IconButton
                                className={closeButtonClassNames}
                                onClick={handleClose}
                                tabIndex={isOpen ? 0 : -1}
                                size="sm"
                                aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
                            >
                                <TimesIcon color={color} />
                            </IconButton>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

Alert.defaultProps = {
    color: 'primary',
    closeTimeout: 0,
    hideTimeout: 0
};

Alert.propTypes = {
    /**
    The actions displayed on the right side.
    */
    actions: PropTypes.node,
    /**
    The display content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to component.
    */
    className: PropTypes.string,
    /**
    Override or extend the styles applied to close button.
    */
    closeButtonClassName: PropTypes.string,
    /**
    Specifies the time in seconds when the alert will be closed.
    */
    closeTimeout: PropTypes.number,
    /**
    Specifies one of severity levels of alert.
    */
    color: PropTypes.oneOf(['primary', 'success', 'danger']),
    /**
    @ignore
    */
    hideTimeout: OnsolvePropTypes.deprecatedPropType(PropTypes.number, 'Instead, use the `closeTimeout` property.'),
    /**
    Specifies the custom icon.
    */
    icon: PropTypes.elementType,
    /**
    Override or extend the styles applied to the icon.
    */
    iconClassName: PropTypes.string,
    /**
    Controls the visual state of component.
    */
    isOpen: PropTypes.bool,
    /**
    @ignore
    */
    message: OnsolvePropTypes.deprecatedPropType(PropTypes.node, 'Instead, use the `children` property.'),
    /**
    Override or extend the styles applied to the message.
    */
    messageClassName: PropTypes.string,
    /**
    Callback function on close event occurs when the close button is clicked.
    */
    onClose: PropTypes.func
};

Alert.displayName = 'Alert';

export default Alert;
