import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PaperHeaderPlaceholder extends Component {
    renderAction() {
        const { className } = this.props;

        const actionsClasses = classNames('onsolve-placeholder__row onsolve-placeholder__paper-actions', className);

        return (
            <div className="onsolve-paper-header__action">
                <div className={actionsClasses} />
            </div>
        );
    }

    render() {
        const { className, classes, style, componentProps } = this.props;
        const { action } = componentProps;

        const rootClasses = classNames('onsolve-placeholder', classes.root);
        const rowClasses = classNames('onsolve-placeholder__row', classes.row, className);
        const titleClasses = classNames('onsolve-placeholder__paper-title', rowClasses);
        const subTitleClasses = classNames('onsolve-placeholder__paper-sub-title', rowClasses);

        return (
            <div className={rootClasses}>
                <div className="onsolve-paper-header__wrapper">
                    <div className={titleClasses} style={{ ...style }} />
                    <div className={subTitleClasses} style={{ ...style }} />
                </div>

                {action && this.renderAction()}
            </div>
        );
    }
}

PaperHeaderPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    componentProps: PropTypes.object,
    style: PropTypes.object
};

PaperHeaderPlaceholder.defaultProps = {
    classes: {},
    componentProps: {}
};

PaperHeaderPlaceholder.displayName = 'PaperHeaderPlaceholder';

export default PaperHeaderPlaceholder;
