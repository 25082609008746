import React from 'react';
import PropTypes from 'prop-types';
import Editor from 'draft-js-plugins-editor';

import { DomUtils, ReactUtils } from '../utils';

import {
    FONT_SIZES,
    wysiwygDecorators,
    wysiwygPlugins,
    CUSTOM_STYLE_PREFIX_COLOR,
    INDENT_SIZES,
    ALIGN_VALUES
} from './constants';
import { getDecorators } from './decorators';
import { getPlugins } from './plugins';
import { copyStyleToNewLine } from './utils';

const WysiwygEditorBase = React.forwardRef((props, forwardedRef) => {
    const {
        autoFocus,
        decorators = [],
        disabledDecorators = [],
        disabledPlugins = [],
        name,
        onBlur,
        onFocus,
        plugins = [],
        readOnly,
        ...rest
    } = props;

    const editorRef = React.useRef(null);

    const mergedDecorators = React.useMemo(
        () => [...decorators, ...getDecorators(disabledDecorators)],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [decorators.length, JSON.stringify(disabledDecorators)]
    );
    const mergedPlugins = React.useMemo(
        () => [...plugins, ...getPlugins(disabledPlugins)],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [plugins.length, JSON.stringify(disabledPlugins)]
    );

    React.useEffect(() => {
        if (autoFocus) {
            DomUtils.setFocus(editorRef);
        }
    }, [autoFocus]);

    const handleBlur = React.useCallback(
        event => {
            if (readOnly) {
                return;
            }

            if (onBlur) {
                event.persist();
                setTimeout(() => {
                    if (name) {
                        event.target.name = name;
                    }
                    onBlur(event);
                }, 0);
            }
        },
        [name, readOnly, onBlur]
    );

    const handleFocus = React.useCallback(
        event => {
            if (readOnly) {
                return;
            }

            if (onFocus) {
                if (name) {
                    event.target.name = name;
                }
                onFocus(event);
            }
        },
        [name, readOnly, onFocus]
    );

    const customStyleMap = React.useMemo(() => ({ ...FONT_SIZES, ...INDENT_SIZES, ...ALIGN_VALUES }), []);

    const handleCustomStyle = React.useCallback(style => {
        const styleNames = style.toJS();

        return styleNames.reduce((styles, styleName) => {
            if (styleName.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
                // eslint-disable-next-line prefer-destructuring
                styles.color = styleName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
            }

            return styles;
        }, {});
    }, []);

    return (
        <Editor
            ref={ReactUtils.setMultipleRefs(editorRef, forwardedRef)}
            customStyleMap={customStyleMap}
            customStyleFn={handleCustomStyle}
            decorators={mergedDecorators}
            plugins={mergedPlugins}
            onBlur={handleBlur}
            onFocus={handleFocus}
            readOnly={readOnly}
            handleReturn={copyStyleToNewLine}
            {...rest}
        />
    );
});

WysiwygEditorBase.propTypes = {
    /**
    Specifies an auto focus to the text input field component.
    */
    autoFocus: PropTypes.bool,
    /**
    Specifies an array of custom decorator objects. If a decorators are provided, it will be used to decorate ranges of text for rendering. The decorator consists of a `strategy` and `component`.
     */
    decorators: PropTypes.arrayOf(PropTypes.object),
    /**
    Specifies an array of disabled decorator objects that exist in the decorators list.
     */
    disabledDecorators: PropTypes.arrayOf(PropTypes.oneOf(Object.values(wysiwygDecorators))),
    /**
    Specifies an array of disabled plugins that exist in the plugins list.
     */
    disabledPlugins: PropTypes.arrayOf(PropTypes.oneOf(Object.values(wysiwygPlugins))),
    /**
    Specifies an event target name for blur and focus events.
     */
    name: PropTypes.string,
    /**
    Callback function on blur event of text input field.
    */
    onBlur: PropTypes.func,
    /**
    Callback function on focus event of text input field.
    */
    onFocus: PropTypes.func,
    /**
    Specifies an array of plugins. A Plugin is simply a plain JavaScript object containing a couple deeper nested objects or functions.
     */
    plugins: PropTypes.arrayOf(PropTypes.object),
    /**
    Specifies that an text input field is read-only. All panels are hidden in this mode.
    */
    readOnly: PropTypes.bool
};

WysiwygEditorBase.displayName = 'WysiwygEditorBase';

export default WysiwygEditorBase;
