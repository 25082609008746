import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../Button';

import { componentsTranslations } from '../../translations';

const withCancelAction = (WrappedComponent = () => null) => {
    const WithCancelAction = ({ buttonSize, onCancel, ...others }) => (
        <Fragment>
            <Button color="secondary" className="text-uppercase" size={buttonSize} onClick={onCancel}>
                <FormattedMessage {...componentsTranslations.ng_common_cancel} />
            </Button>
            <WrappedComponent buttonSize={buttonSize} {...others} />
        </Fragment>
    );

    WithCancelAction.propTypes = {
        buttonSize: PropTypes.string,
        disabled: PropTypes.bool,
        onAdd: PropTypes.func,
        onCancel: PropTypes.func
    };

    WithCancelAction.displayName = 'WithCancelAction';

    return WithCancelAction;
};

export default withCancelAction;
