import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IconButton } from '../Button';
import { SearchIcon } from '../Icons';
import { componentsTranslations } from '../translations';

const SearchButton = ({ onClick, intl, ...other }) => {
    return (
        <IconButton
            type="submit"
            className="onsolve-search-box__button--search"
            onClick={onClick}
            aria-label={intl.formatMessage(componentsTranslations.ng_components_search)}
            {...other}
        >
            <SearchIcon color="secondary" />
        </IconButton>
    );
};

SearchButton.propTypes = {
    className: PropTypes.string,
    intl: PropTypes.object,
    onClick: PropTypes.func
};

SearchButton.displayName = 'SearchButton';

export default injectIntl(SearchButton);
