import React from 'react';
import SvgIcon from './SvgIcon';

const EyeStriked = props => {
    return (
        <SvgIcon {...props}>
            <defs>
                <path
                    d="M12.82.125c.173-.17.45-.166.62.006s.166.45-.006.62l-2.25 2.203c.303.25.6.525.89.822.52.534.98 1.105 1.38 1.676l.495.774c.074.132.074.293 0 .426-.09.16-.255.43-.495.774-.397.57-.858 1.142-1.38 1.676-1.5 1.55-3.2 2.49-5.07 2.49-1.23 0-2.39-.41-3.472-1.137L1.18 12.75c-.173.17-.45.166-.62-.006s-.166-.45.006-.62l2.25-2.203c-.303-.25-.6-.525-.89-.822-.52-.534-.98-1.105-1.38-1.676L.055 6.65c-.074-.132-.074-.293 0-.426.09-.16.255-.43.495-.774.397-.57.858-1.142 1.38-1.676 1.5-1.55 3.2-2.49 5.07-2.49 1.23 0 2.39.41 3.472 1.137zM10.553 3.57L4.17 9.825c.902.573 1.85.9 2.83.9 1.585 0 3.084-.828 4.444-2.225.484-.497.916-1.03 1.287-1.565l.322-.487-.322-.487c-.372-.534-.803-1.068-1.287-1.565a10.06 10.06 0 0 0-.892-.816zM9.625 6c.242 0 .438.196.438.438C10.063 8.1 8.7 9.446 7 9.446c-.242 0-.437-.196-.437-.437S6.758 8.57 7 8.57c1.2 0 2.188-.957 2.188-2.134 0-.242.196-.437.438-.437zM7 2.16c-1.585 0-3.084.828-4.444 2.225-.484.497-.916 1.03-1.287 1.565l-.322.487.322.487c.372.534.803 1.068 1.287 1.565a10.06 10.06 0 0 0 .892.816L9.83 3.05c-.902-.573-1.85-.9-2.83-.9zM7 3.43c.242 0 .438.196.438.438s-.196.438-.437.438c-1.2 0-2.187.957-2.187 2.134 0 .242-.196.438-.437.438s-.437-.196-.437-.437C3.938 4.774 5.3 3.43 7 3.43z"
                    id="EYESTRIKEDA"
                />
                <path id="EYESTRIKEDB" d="M0 0h16v16H0z" />
            </defs>

            <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
                <mask id="EYESTRIKEDC" fill="#fff">
                    <use xlinkHref="#EYESTRIKEDA" />
                </mask>
                <use fill="#111" fillRule="nonzero" xlinkHref="#EYESTRIKEDA" />
                <g mask="url(#EYESTRIKEDC)">
                    <g transform="translate(-1 -1.5)">
                        <mask id="EYESTRIKEDD" fill="#fff">
                            <use xlinkHref="#EYESTRIKEDB" />
                        </mask>
                        <use fill="#d8d8d8" opacity="0" xlinkHref="#EYESTRIKEDB" />
                        <path fill="#3777bc" mask="url(#EYESTRIKEDD)" d="M0 0h16v16H0z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

EyeStriked.displayName = 'EyeStrikedIcon';

export default EyeStriked;
