export const ALIGN_VALUES = {
    'align-left': { 'text-align': 'left' },
    'align-center': { 'text-align': 'center' },
    'align-right': { 'text-align': 'right' }
};

export const ALIGN_STATE_KEYS = {
    KEY: 'align-',
    STYLE_NAME: 'text-align'
};
