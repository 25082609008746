import React from 'react';

import SvgIcon from './SvgIcon';

const Trash = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M2.73451327,6.69911504 C2.73451327,6.42541641 2.95638986,6.20353982 3.2300885,6.20353982 C3.50378713,6.20353982 3.72566372,6.42541641 3.72566372,6.69911504 L3.72566372,12.7699115 C3.72566372,13.4545315 4.27998182,14.0088496 4.96460177,14.0088496 L11.0353982,14.0088496 C11.7200182,14.0088496 12.2743363,13.4545315 12.2743363,12.7699115 L12.2743363,6.69911504 C12.2743363,6.42541641 12.4962129,6.20353982 12.7699115,6.20353982 C13.0436101,6.20353982 13.2654867,6.42541641 13.2654867,6.69911504 L13.2654867,12.7699115 C13.2654867,14.0019287 12.2674155,15 11.0353982,15 L4.96460177,15 C3.73258455,15 2.73451327,14.0019287 2.73451327,12.7699115 L2.73451327,6.69911504 Z M9.67256637,3.60176991 L9.67256637,1.99115044 L6.32743363,1.99115044 L6.32743363,3.60176991 L9.67256637,3.60176991 Z M10.6637168,3.60176991 L14.5044248,3.60176991 C15.1651917,3.60176991 15.1651917,4.59292035 14.5044248,4.59292035 L1.49557522,4.59292035 C0.83480826,4.59292035 0.83480826,3.60176991 1.49557522,3.60176991 L5.33628319,3.60176991 L5.33628319,1.49557522 C5.33628319,1.22187658 5.55815977,1 5.83185841,1 L10.1681416,1 C10.4418402,1 10.6637168,1.22187658 10.6637168,1.49557522 L10.6637168,3.60176991 Z"
            />
        </SvgIcon>
    );
};

Trash.displayName = 'TrashIcon';

export default Trash;
