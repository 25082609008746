import React from 'react';

import SvgIcon from './SvgIcon';

const Remove = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M14.125 1c.525 0 .875.35.875.875v12.25c0 .525-.35.875-.875.875H1.875C1.35 15 1 14.65 1 14.125V1.875C1 1.35 1.35 1 1.875 1zm-.875 1.75H2.75v10.5h10.5V2.75zM9.838 4.938l1.225 1.224L9.224 8l1.838 1.838-1.226 1.225L8 9.224l-1.838 1.838-1.224-1.226L6.774 8 4.937 6.162l1.225-1.224L8 6.774l1.838-1.838z"
                stroke="none"
            />
        </SvgIcon>
    );
};

Remove.displayName = 'RemoveIcon';

export default Remove;
