import React from 'react';
import SvgIcon from './SvgIcon';

const EmptyFile = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.438 0A.44.44 0 017.6.031l.015.007a.42.42 0 01.13.09L7.694.083a.44.44 0 01.048.04l.006.005 4.375 4.375a.438.438 0 01.09.131l.006.015a.417.417 0 01.032.163v8.75a.437.437 0 01-.438.438H.438A.437.437 0 010 13.562V.438C0 .196.196 0 .438 0h7zM7 .875H.875v12.25h10.5V5.25H7.437a.438.438 0 01-.43-.359L7 4.812V.876zm3.757 3.5L7.875 1.494v2.881h2.882z"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            />
        </SvgIcon>
    );
};

EmptyFile.displayName = 'EmptyFileIcon';

export default EmptyFile;
