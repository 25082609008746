import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxIntermediate = props => {
    return (
        <SvgIcon {...props}>
            <g strokeWidth="1" fillRule="evenodd">
                <rect x=".5" y=".5" width="15" height="15" rx="1" ry="1" fill="none" />
                <rect stroke="none" fillRule="nonzero" x="4" y="7" width="8" height="2" />
            </g>
        </SvgIcon>
    );
};

CheckBoxIntermediate.displayName = 'CheckBoxIntermediateIcon';

export default CheckBoxIntermediate;
