import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CardListBody = ({ children, title, className }) => (
    <div className={classNames('onsolve-card-list__cell--body', className)}>
        <div className="title">{title}</div>
        <div className="description">{children}</div>
    </div>
);

CardListBody.propTypes = {
    /**
    Specifies elements to be displayed in description section
    */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /**
    Extend classes for card body
    */
    className: PropTypes.string,
    /**
     Specifies elements to be displayed in title section
    */
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

CardListBody.displayName = 'CardListBody';

export default CardListBody;
