export function getAriaLabel(label, additionalLabelledBy) {
    const attributes = getAriaAttributeForLabel(label);

    if (additionalLabelledBy) {
        attributes['aria-labelledby'] = `${attributes['aria-labelledby'] || ''} ${additionalLabelledBy}`.trim();
    }

    return attributes;
}

function getAriaAttributeForLabel(label) {
    if (!label) {
        return {};
    }

    if (label[0] === '#') {
        return { 'aria-labelledby': label.substring(1).replace(/ #/g, ' ') };
    }
    return { 'aria-label': label };
}
