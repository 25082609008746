import React from 'react';
import SvgIcon from './SvgIcon';

const FilterCriteria = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path
                    d="M8,16c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C16,12.4,12.4,16,8,16z M8,0.6C3.9,0.6,0.6,3.9,0.6,8
    c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C15.4,3.9,12.1,0.6,8,0.6z"
                />
                <polygon
                    points="8.4,4.8 7.6,4.8 7.6,7.6 6.8,7.6 6.8,8.4 7.6,8.4 7.6,11.2 8.4,11.2 8.4,10.4 9.2,10.4 9.2,9.6 8.4,9.6
    8.4,6.4 9.2,6.4 9.2,5.6 8.4,5.6 "
                />
                <path
                    d="M10,5.2h0.8c0.2,0,0.4,0.2,0.4,0.4v0.8c0,0.2-0.2,0.4-0.4,0.4H10c-0.2,0-0.4-0.2-0.4-0.4V5.6
    C9.6,5.4,9.8,5.2,10,5.2z"
                />
                <path
                    d="M10,9.2h0.8c0.2,0,0.4,0.2,0.4,0.4v0.8c0,0.2-0.2,0.4-0.4,0.4H10c-0.2,0-0.4-0.2-0.4-0.4V9.6
    C9.6,9.4,9.8,9.2,10,9.2z"
                />
                <path
                    d="M5.2,7.2H6c0.2,0,0.4,0.2,0.4,0.4v0.8c0,0.2-0.2,0.4-0.4,0.4H5.2C5,8.8,4.8,8.6,4.8,8.4V7.6
    C4.8,7.4,5,7.2,5.2,7.2z"
                />
            </g>
        </SvgIcon>
    );
};

FilterCriteria.displayName = 'FilterCriteriaIcon';

export default FilterCriteria;
