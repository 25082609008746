import React from 'react';
import PropTypes from 'prop-types';

import { OnsolvePropTypes } from '../utils';
import { SelectionMode } from './utils';

const TableColumn = () => <React.Fragment />;

TableColumn.propTypes = {
    /**
     Text align in a column
     */
    align: OnsolvePropTypes.align,
    /**
     Render function for cell
     */
    cell: PropTypes.func,
    /**
     Additional classnames
     */
    className: PropTypes.string,
    /**
     Header column menu
     */
    contextMenu: PropTypes.element,
    /**
     Disabled could be passed as boolean or function-checker
     */
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     Name of a field which responsible for corresponded field in data
     */
    field: PropTypes.string,
    /**
     Render function for header of a cell
     */
    headerCell: PropTypes.func,
    /**
     Activate header-selection-mode and specify a method
     */
    headerSelectionValue: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf([SelectionMode.None, SelectionMode.Partial, SelectionMode.All])
    ]),
    /**
     Is cell locked
     */
    locked: PropTypes.bool,
    /**
     Make cell to be sortable
     */
    sortable: PropTypes.bool,
    /**
     Title of a cell
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Width of a cell
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TableColumn.displayName = 'TableColumn';

export default TableColumn;
