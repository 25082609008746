import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DomUtils } from '../utils';

const placementTypes = {
    ltr: {
        right: 'right',
        left: 'left',
        'right-bottom': 'right-bottom',
        'left-bottom': 'left-bottom'
    },
    rtl: {
        right: 'left',
        left: 'right',
        'right-bottom': 'left-bottom',
        'left-bottom': 'right-bottom'
    }
};

const Badge = ({ className, color, value, variant, placement: placementProp, children, invisible, id }) => {
    const placements = DomUtils.isRtl() ? placementTypes.rtl : placementTypes.ltr;
    const placement = placements[placementProp];

    const rootClasses = classNames('onsolve-badge', className);
    const badgeClasses = classNames(
        'onsolve-badge__container',
        { 'onsolve-badge--invisible': invisible },
        `onsolve-badge--${variant}`,
        `onsolve-badge--${color}`,
        `onsolve-badge--${placement}`
    );

    return (
        <span id={id} className={rootClasses}>
            {children}
            <span className={badgeClasses}>{value}</span>
        </span>
    );
};

Badge.propTypes = {
    /**
    The content of the component.
    */
    children: PropTypes.element,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Specifies one of the color of component.
    */
    color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success', 'dark', 'light']),
    /**
    Specifies the unique identifier for component.
    */
    id: PropTypes.string,
    /**
    Specifies whether to show component or not.
    */
    invisible: PropTypes.bool,
    /**
    Specifies one of the position of component.
    */
    placement: PropTypes.oneOf(['left', 'right', 'left-bottom', 'right-bottom']),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
    /**
    Specifies one of the variants of component.
    */
    variant: PropTypes.oneOf(['pill', 'dot'])
};

Badge.defaultProps = {
    invisible: false,
    color: 'primary',
    variant: 'pill',
    placement: 'right'
};

Badge.displayName = 'Badge';

export default Badge;
