import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { keyCodes } from '../../../common/constants';
import { DomUtils, fp as _, ReactUtils } from '../../utils';
import { ArrowDownIcon } from '../../Icons';
import {
    InputFieldToggle,
    InputFieldControl,
    InputFieldGroup,
    InputFieldContainer,
    InputFieldLabel,
    InputFieldMessage
} from '../../InputBase';
import { Menu, MenuItem } from '../../Menu';

const sizerStyle = {
    position: 'absolute',
    top: -99999,
    left: 0,
    visibility: 'hidden',
    height: 0,
    overflow: 'scroll',
    whiteSpace: 'pre'
};

class AutoComplete extends Component {
    constructor(props) {
        super(props);

        this.optionId = `${props.id}_option`;
        this.listBoxId = `${props.id}_listBox`;

        this.state = {
            value: undefined,
            isOpen: false,
            inputWidth: '100%',
            autoFocus: false,
            active: false
        };

        this.inputFieldRef = React.createRef();
        this.anchorRef = React.createRef();
        this.sizerRef = React.createRef();
        this.selectedRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.updateInputWidth = this.updateInputWidth.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleMenuItemSelected = this.handleMenuItemSelected.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    get value() {
        const { value, defaultValue } = this.props;

        if (value !== undefined) {
            return value;
        }
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        return '';
    }

    componentDidMount() {
        const { autosize } = this.props;

        if (autosize) {
            this.updateInputWidth();
        }
    }

    componentDidUpdate(prevProps) {
        const { autosize } = this.props;

        if (autosize && prevProps.value !== this.props.value) {
            this.updateInputWidth();
        }
    }

    updateInputWidth() {
        const { current: element } = this.sizerRef;

        if (element.scrollWidth !== this.state.inputWidth) {
            this.setState({ inputWidth: element.scrollWidth });
        }
    }

    handleToggle() {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        DomUtils.setFocus(this.inputFieldRef);

        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    handleFocus(e) {
        const { onFocus } = this.props;

        this.setState({ autoFocus: false, active: true });

        if (onFocus) {
            onFocus(e);
        }
    }

    handleBlur(e) {
        const { onBlur } = this.props;

        this.setState({ active: false });

        if (onBlur) {
            onBlur(e);
        }
    }

    handleChange(e) {
        const { disabled, onChange } = this.props;
        const { value } = e.target;

        if (disabled) {
            return;
        }

        this.setState({ isOpen: value.length > 0, autoFocus: false }, () => {
            DomUtils.setFocus(this.inputFieldRef);
        });

        if (onChange) {
            onChange(e, value);
        }
    }

    handleMenuItemSelected(item) {
        return e => this.handleMenuItemEvent(e, item);
    }

    handleMenuItemKeyDown(item) {
        return e => {
            const { ENTER_KEY, ESC } = keyCodes;
            const charCode = DomUtils.getCharCode(e);

            if (charCode === ESC) {
                e.stopPropagation();
            } else if (charCode === ENTER_KEY) {
                e.preventDefault();

                this.handleMenuItemEvent(e, item);
            }
        };
    }

    handleMenuItemEvent = (e, item) => {
        const { textField, onItemSelected } = this.props;

        this.handleMenuClose();

        if (onItemSelected) {
            const text = _.get(item, textField);

            onItemSelected(e, item, text);
        }
    };

    handleMenuClose = () => {
        this.setState({ isOpen: false, autoFocus: false }, () => DomUtils.setFocus(this.inputFieldRef));
    };

    handleKeyDown = e => {
        const { isOpen } = this.state;
        const { LEFT, RIGHT, DOWN, ENTER_KEY } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === DOWN || charCode === ENTER_KEY) {
            e.preventDefault();

            if (!isOpen) {
                this.handleToggle();
            } else if (charCode === DOWN) {
                this.setState({ autoFocus: true });
            }
        } else if (charCode === LEFT || charCode === RIGHT) {
            e.preventDefault();
            this.setState({ autoFocus: false });
        }
    };

    handleKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            const { isOpen } = this.state;
            const { value = '' } = e.target;

            e.preventDefault();

            if (isOpen) {
                e.stopPropagation();
                this.handleToggle();
            } else if (value.length > 0) {
                e.stopPropagation();

                const { onChange } = this.props;

                if (onChange) {
                    onChange(e);
                }
            }
        }
    };

    handleMenuKeyDown = e => {
        const { LEFT, RIGHT, UP, DOWN } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if (charCode === UP || charCode === DOWN) {
            const { current } = this.inputFieldRef;

            if (this.state.isOpen) {
                const currentMenuItem = document.activeElement;

                current.setAttribute('aria-activedescendant', currentMenuItem.getAttribute('id'));
            } else {
                current.removeAttribute('aria-activedescendant');
            }
        }

        if (charCode === LEFT || charCode === RIGHT) {
            e.preventDefault();
            DomUtils.setFocus(this.inputFieldRef);
        }
    };

    handleMenuKeyUp = e => {
        const charCode = DomUtils.getCharCode(e);

        if (charCode === keyCodes.ESC) {
            e.stopPropagation();
            this.handleMenuClose(e);
        }
    };

    getOptionId(index) {
        return `option-${this.optionId}-${index}`;
    }

    render() {
        const {
            ['aria-label']: ariaLabel,
            className,
            classes,
            data,
            disabled,
            placeholder,
            textField,
            dataItemKey,
            autosize,
            inputProps,
            variant,
            menuProps,
            error,
            errorText,
            helpText,
            currentLength,
            maxLength,
            label
        } = this.props;
        const { autoFocus, active } = this.state;

        let inputStyle = {};

        const items = _.map(data, (item, index) => {
            const text = _.get(item, textField);
            const key = _.get(item, dataItemKey);
            const selected = this.value === text;

            return (
                <MenuItem
                    id={this.getOptionId(index)}
                    key={key}
                    role="option"
                    selected={selected}
                    onClick={this.handleMenuItemSelected(item)}
                    onKeyDown={this.handleMenuItemKeyDown(item)}
                >
                    <span>{text}</span>
                </MenuItem>
            );
        });

        if (autosize) {
            const { minWidth, maxWidth } = inputProps;

            inputStyle = {
                width: this.state.inputWidth + 'px',
                minWidth: minWidth || '5em',
                maxWidth: maxWidth ? maxWidth : '100%'
            };
        }

        const isOpen = React.Children.count(items) > 0 && this.state.isOpen;
        const rootClasses = classNames('onsolve-autocomplete', className, classes.root);
        const inputClassNames = classNames('onsolve-text-field__input', {
            'text-primary': variant === 'link',
            'onsolve-autocomplete__input--error': error
        });

        return (
            <Fragment>
                <InputFieldControl className={rootClasses}>
                    <InputFieldGroup>
                        {label && <InputFieldLabel>{label}</InputFieldLabel>}
                        <InputFieldContainer
                            ref={this.anchorRef}
                            variant={variant === 'link' ? 'naked' : 'standard'}
                            error={error}
                            active={isOpen || active}
                            onKeyUp={this.handleKeyUp}
                        >
                            <input
                                role="combobox"
                                aria-haspopup="true"
                                aria-autocomplete="both"
                                aria-owns={this.listBoxId}
                                aria-expanded={isOpen}
                                aria-label={ariaLabel || label || ''}
                                ref={this.inputFieldRef}
                                style={inputStyle}
                                className={inputClassNames}
                                type="text"
                                value={this.value}
                                placeholder={placeholder}
                                autoComplete="off"
                                disabled={disabled}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                onKeyUp={this.handleKeyUp}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                            />
                            {autosize && (
                                <div style={sizerStyle} ref={ReactUtils.setMultipleRefs(this.sizerRef)}>
                                    {this.value}
                                </div>
                            )}
                            <InputFieldToggle
                                className="onsolve-autocomplete__toggle"
                                icon={ArrowDownIcon}
                                disabled={disabled}
                                onClick={this.handleToggle}
                            />
                        </InputFieldContainer>
                    </InputFieldGroup>
                    <InputFieldMessage
                        error={error}
                        errorText={errorText}
                        helpText={helpText}
                        currentLength={currentLength}
                        maxLength={maxLength}
                    />
                </InputFieldControl>
                <Menu
                    id={this.listBoxId}
                    anchorEl={this.anchorRef.current}
                    autoFocus={autoFocus}
                    open={isOpen}
                    role="listbox"
                    aria-label={ariaLabel}
                    {...menuProps}
                    onClose={this.handleMenuClose}
                    onKeyDown={this.handleMenuKeyDown}
                    onKeyUp={this.handleMenuKeyUp}
                >
                    {items}
                </Menu>
            </Fragment>
        );
    }
}

AutoComplete.propTypes = {
    autosize: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(Object)]).isRequired,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputProps: PropTypes.shape({
        maxWidth: PropTypes.string,
        minWidth: PropTypes.string
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    menuProps: PropTypes.shape({
        position: PropTypes.oneOf(['left', 'right', 'center'])
    }),
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.func,
    onItemSelected: PropTypes.func,
    placeholder: PropTypes.string,
    textField: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'link']).isRequired
};

AutoComplete.defaultProps = {
    dataItemKey: 'id',
    id: 'autocomplete',
    textField: 'value',
    classes: {},
    menuProps: {
        position: 'center'
    },
    inputProps: {},
    variant: 'standard'
};

AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
