import React from 'react';
import { FormattedMessage } from 'react-intl';
import { componentsTranslations } from '../../translations';

export const acceptMimeTypesLimitMessageValues = acceptMimeTypes => {
    const formats = acceptMimeTypes.map(acceptMime => {
        const format = acceptMime.split('/').pop();

        if (format && format.length > 0) {
            return format.toUpperCase();
        } else {
            return acceptMime.toUpperCase();
        }
    });

    return formats.join(', ');
};

export const fileSizeLimitMessageValues = maxSize => {
    return maxSize / 1024 / 1024;
};

// eslint-disable-next-line react/display-name
export const limitsMessageValues = (acceptMimeTypes, maxSize, maxHeight, maxWidth) => {
    if (!acceptMimeTypes || !maxSize || !maxHeight || !maxWidth) {
        return null;
    }

    return (
        <FormattedMessage
            {...componentsTranslations.ng_file_upload_limits_text}
            values={{
                acceptMimeTypes: acceptMimeTypesLimitMessageValues(acceptMimeTypes),
                maxSize: fileSizeLimitMessageValues(maxSize),
                maxHeight: maxHeight,
                maxWidth: maxWidth
            }}
        />
    );
};
