import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PageHeaderPlaceholder extends Component {
    renderAction() {
        const { className } = this.props;

        const actionsClasses = classNames('onsolve-placeholder__row onsolve-placeholder__page-actions', className);

        return (
            <div className="onsolve-page-header__action">
                <div className={actionsClasses} />
            </div>
        );
    }

    render() {
        const { className, classes, style, componentProps } = this.props;
        const { action } = componentProps;

        const rootClasses = classNames('onsolve-placeholder', classes.root);
        const rowClasses = classNames('onsolve-placeholder__row', classes.row, className);

        const titleClasses = classNames('onsolve-placeholder__page-title', rowClasses, classes.title);

        return (
            <div className={rootClasses}>
                <div className="onsolve-page-header__wrapper">
                    <div className={titleClasses} style={{ ...style }} />
                </div>
                {action && this.renderAction()}
            </div>
        );
    }
}

PageHeaderPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    componentProps: PropTypes.object,
    style: PropTypes.object
};

PageHeaderPlaceholder.defaultProps = {
    classes: {},
    componentProps: {}
};

PageHeaderPlaceholder.displayName = 'PageHeaderPlaceholder';

export default PageHeaderPlaceholder;
