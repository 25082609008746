import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from '../../../../Selections';

export const refUpdater = ({ checked, intermediate }) => input => {
    if (input) {
        input.checked = checked;
        input.intermediate = intermediate;
    }
};

class Checkbox extends Component {
    state = {
        checked: this.props.intermediate || this.props.checked || false,
        intermediate: this.props.intermediate
    };

    componentDidUpdate(prevProps) {
        const { checked, intermediate } = this.props;

        if (prevProps.checked !== checked || prevProps.intermediate !== intermediate) {
            this.setState({
                checked: intermediate || checked,
                intermediate: intermediate
            });
        }
    }

    handleChange = (e, data) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(e, data);
        }

        this.setState({
            checked: data.checked,
            intermediate: false
        });
    };

    render() {
        const { disabled: disabledProps, readOnly } = this.props;
        const { checked, intermediate } = this.state;

        const disabled = disabledProps || readOnly;

        return (
            <CheckBox checked={checked} intermediate={intermediate} onChange={this.handleChange} disabled={disabled} />
        );
    }
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    intermediate: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
