import { DomUtils } from '../../utils';

/*
This function should resolve the issue what happens with key navigation by Shift + Tab on popover
when component is located on Modal dialog.
*/
function getPopupContainer(element) {
    const modalElement = document.querySelector('.modal');

    if (modalElement && element && DomUtils.isInDOMSubtree(element, modalElement)) {
        return modalElement;
    }
    // reactstrap does not process returned value as a string in proper way
    // when container is a function.
    return document.querySelector('body');
}

export default {
    getPopupContainer
};
