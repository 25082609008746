import { useEffect, useRef } from 'react';

function useResizeObserver(elementRef, { onResize }) {
    const previousSize = useRef({
        width: undefined,
        height: undefined
    });
    const resizeObserverRef = useRef(null);

    useEffect(() => {
        const element = elementRef.current;

        function createObserver(ResizeObserver) {
            resizeObserverRef.current = new ResizeObserver(entries => {
                const [{ target }] = entries;

                const { width, height } = target.getBoundingClientRect();

                const fixedWidth = Math.floor(width);
                const fixedHeight = Math.floor(height);

                if (previousSize.current.width !== fixedWidth || previousSize.current.height !== fixedHeight) {
                    const size = { width: fixedWidth, height: fixedHeight };

                    if (previousSize.current.width !== undefined && previousSize.current.height !== undefined) {
                        if (onResize) {
                            Promise.resolve().then(() => {
                                onResize(size);
                            });
                        }
                    }
                    previousSize.current = size;
                }
            });

            resizeObserverRef.current.observe(elementRef.current);
        }

        if ('ResizeObserver' in window) {
            createObserver(ResizeObserver);
        } else {
            import('resize-observer-polyfill').then(createObserver);
        }

        return () => {
            if (resizeObserverRef.current) {
                resizeObserverRef.current.unobserve(element);
            }
        };
    }, [elementRef, onResize]);

    return [elementRef];
}

export default useResizeObserver;
