import { setReason } from './';
import { fp as _ } from '../../utils';

import { REASONS } from '../constants';

const rejectedErrorToReason = {
    HasMaxSizeInPixelsValidator: REASONS.WIDTH_HEIGHT,
    IsImageValidator: REASONS.TYPE,
    StreamHasMaxLengthValidator: REASONS.SIZE
};

export const fileUploadToServer = (acceptedFiles, rejectedFiles, globalReason, onFileUpload) => {
    const _acceptedFiles = [];
    const _rejectedFiles = [...rejectedFiles];

    return Promise.allSettled(
        acceptedFiles.map(file => {
            return new Promise((resolve, reject) => {
                onFileUpload(file)
                    .then(response => resolve({ file, response }))
                    .catch(error => reject({ file, error }));
            });
        })
    ).then(items => {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.status === 'rejected') {
                // INFO: IE11 doesn't support get method for FormData so you should return file from onFileUpload promise
                if (!_.has(item, 'reason.file') || !_.has(item, 'reason.error.response.data.errors')) {
                    continue;
                }

                const { file } = item.reason;
                const errorReason =
                    item.reason.error.response.data.errors.length > 0
                        ? item.reason.error.response.data.errors[0].errorCode
                        : '';

                if (!file || !errorReason) {
                    continue;
                }

                setReason(file, rejectedErrorToReason[errorReason]);
                _rejectedFiles.push(file);
                continue;
            }

            _acceptedFiles.push(item.value.response);
        }

        return { acceptedFiles: _acceptedFiles, rejectedFiles: _rejectedFiles, globalReason };
    });
};
