import React from 'react';

import { SortIcon } from '../../Icons';

const DropdownToggle = () => (
    <>
        <div className="onsolve-dropdown-btn__divider" />
        <div className="onsolve-dropdown-btn__icon">
            <SortIcon />
        </div>
    </>
);

DropdownToggle.displayName = 'DropdownToggle';

export default DropdownToggle;
