import React from 'react';
import { FormattedMessage } from 'react-intl';

import { componentsTranslations } from '../../translations';

export const GLOBAL_REASON = {
    MULTIPLE_FILES: <FormattedMessage {...componentsTranslations.ng_file_upload_multiple_files_support} />
};

export const REASONS = {
    TYPE: <FormattedMessage {...componentsTranslations.ng_file_upload_type_not_support} />,
    SIZE: <FormattedMessage {...componentsTranslations.ng_file_upload_size_to_big} />,
    WIDTH_HEIGHT: <FormattedMessage {...componentsTranslations.ng_file_upload_height_or_width_to_large} />
};

export const VALIDATION_TYPES = {
    any: 'any',
    images: 'images'
};
