import React from 'react';
import SvgIcon from './SvgIcon';

const BulletinBoard = props => (
    <SvgIcon {...props}>
        <g>
            <path
                stroke="none"
                d="M8,-1.54321e-13 C8.74865266,-1.54321e-13 9.35555556,0.606902895 9.35555556,1.35555556 C9.35555556,1.50894351 9.33007898,1.6563811 9.28312647,1.79386768 L12.7,4.088 L16,4.08888889 L16,15.4888889 L0,15.4888889 L0,4.08888889 L3.299,4.088 L6.71687353,1.79386768 C6.6834013,1.69585413 6.66084357,1.59278329 6.6506498,1.4861046 L6.64444444,1.35555556 C6.64444444,0.606902895 7.25134734,-1.54321e-13 8,-1.54321e-13 Z M15.3333333,4.75533333 L0.666666667,4.75533333 L0.666666667,14.822 L15.3333333,14.822 L15.3333333,4.75533333 Z M14.2111111,5.87777778 L14.2111111,13.7 L1.78888889,13.7 L1.78888889,5.87777778 L14.2111111,5.87777778 Z M13.5443333,6.54433333 L2.45533333,6.54433333 L2.45533333,13.0333333 L13.5443333,13.0333333 L13.5443333,6.54433333 Z M8.91842151,2.3525771 C8.67691097,2.5751612 8.35433189,2.71111111 8,2.71111111 C7.64566811,2.71111111 7.32308903,2.5751612 7.08157849,2.3525771 L4.494,4.088 L11.505,4.088 Z M8,0.666666667 C7.61953717,0.666666667 7.31111111,0.975092728 7.31111111,1.35555556 C7.31111111,1.73601838 7.61953717,2.04444444 8,2.04444444 C8.38046283,2.04444444 8.68888889,1.73601838 8.68888889,1.35555556 C8.68888889,0.975092728 8.38046283,0.666666667 8,0.666666667 Z"
            ></path>
        </g>
    </SvgIcon>
);

BulletinBoard.displayName = 'BulletinBoardIcon';

export default BulletinBoard;
