import { ToastrActions } from './ToastrConstants';

const createToastrStore = reducer => {
    let state;
    let listeners = [];
    const getState = () => state;
    const dispatch = action => {
        state = reducer(state, action);
        listeners.forEach(listener => listener());
    };
    const subscribe = listener => {
        listeners.push(listener);

        return () => {
            listeners = listeners.filter(l => l !== listener);
        };
    };

    dispatch({});

    return { getState, dispatch, subscribe };
};

const insert = (state, action) => [...state, action.data];

const remove = (state, action) => {
    let elemToRemoveArray = state.slice().filter(item => item.id === action.data.id);

    if (Array.isArray(elemToRemoveArray)) {
        let elemToRemoveIndex = state.indexOf(elemToRemoveArray[0]);

        return [...state.slice(0, elemToRemoveIndex), ...state.slice(elemToRemoveIndex + 1)];
    }
    return state;
};

const toastrReducer = (state = [], action) => {
    switch (action.type) {
        case ToastrActions.Insert:
            return insert(state, action);
        case ToastrActions.Remove:
            return remove(state, action);
        case ToastrActions.RemoveAll:
            return [];
        default:
            return state;
    }
};

const ToastrStore = createToastrStore(toastrReducer);

export default ToastrStore;
