import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '../../Icons';

class DateInputToggle extends Component {
    constructor(props) {
        super(props);

        this.state = { active: false };

        this.handleHover = this.handleHover.bind(this);
    }

    handleHover(active = false) {
        this.setState({ active });
    }

    render() {
        const { id, onClick } = this.props;
        const { active } = this.state;
        const iconClasses = `onsolve-svg-icon--${active ? 'primary' : 'secondary'}`;

        return (
            <span
                id={id}
                className="onsolve-date-input__toggle"
                onClick={onClick}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover()}
            >
                <CalendarIcon className={iconClasses} />
            </span>
        );
    }
}

DateInputToggle.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func.isRequired
};

DateInputToggle.displayName = 'DateInputToggle';

export default DateInputToggle;
