import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { componentsTranslations } from '../../../translations';
import { BlurLoader } from '../../../BlurLoader';

import { paginatorVariants } from '../../constants';

const {
    ng_components_paginationInfoTotal,
    ng_components_paginationInfoRange,
    ng_components_paginationInfoRangePlus
} = componentsTranslations;

class PaginationInfo extends PureComponent {
    renderInforResult({ start, end, total, messages, maxShow, paginationInfoRender, intl, variant }) {
        if (paginationInfoRender) {
            return React.createElement(paginationInfoRender, { start, end, total });
        }

        if (messages && messages.paginationInfoDescription) {
            return messages.paginationInfoDescription;
        }

        const createMessage = (messageDescriptor, options) => {
            const message = intl.formatMessage(messageDescriptor, options);

            if (variant === paginatorVariants.MOBILE) {
                return message;
            }

            return `(${message})`;
        };

        switch (true) {
            case total <= 1:
                return createMessage(ng_components_paginationInfoTotal, { 0: total });
            case total <= maxShow:
                return createMessage(ng_components_paginationInfoRange, { 0: start, 1: end, 2: total });
            case total > maxShow:
                return createMessage(ng_components_paginationInfoRangePlus, { 0: start, 1: end, 2: maxShow });
            default:
                return null;
        }
    }

    render() {
        const { total, variant, i18nBlur } = this.props;
        const className = classNames('onsolve-table__paginator-info', {
            'font-italic': total !== 0 && variant !== paginatorVariants.MOBILE,
            'onsolve-table__paginator-info-mobile': variant === paginatorVariants.MOBILE
        });

        const renderInfo = i18nBlur ? (
            <BlurLoader width={4}>{this.renderInforResult(this.props)}</BlurLoader>
        ) : (
            this.renderInforResult(this.props)
        );

        return <div className={className}>{renderInfo}</div>;
    }
}

PaginationInfo.displayName = 'PaginationInfo';

PaginationInfo.propTypes = {
    end: PropTypes.number,
    i18nBlur: PropTypes.bool,
    intl: PropTypes.object,
    maxShow: PropTypes.number,
    messages: PropTypes.shape({
        pageSizelabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        pageSizeHelpText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        paginationInfoDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    }),
    paginationInfoRender: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    start: PropTypes.number,
    total: PropTypes.number,
    variant: PropTypes.oneOf(Object.values(paginatorVariants))
};

PaginationInfo.defaultProps = {
    start: 0,
    end: 0,
    maxShow: 500,
    total: 0,
    variant: paginatorVariants.DESKTOP
};

export default injectIntl(PaginationInfo);
