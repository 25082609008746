import React from 'react';

import SvgIcon from './SvgIcon';

const Microphone = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1">
            <path d="M14.182999,6.23781888 L14.2788786,6.25 C14.7210532,6.3375 15.0747928,6.775 14.9863579,7.2125 C14.5441834,10.3625 11.979571,12.8125 8.8843491,13.1625 L8.8843491,13.1625 L8.8843491,15 L7.1156509,15 L7.1156509,13.1625 C4.02042903,12.725 1.45581663,10.3625 1.01364208,7.2125 C0.925207165,6.775 1.27894681,6.3375 1.72112136,6.25 C2.25173082,6.1625 2.69390537,6.5125 2.78234028,7.0375 C3.13607993,9.575 5.34695269,11.5 8,11.5 C10.6530473,11.5 12.8639201,9.575 13.3060946,6.95 C13.3060946,6.5125 13.8367041,6.1625 14.2788786,6.25 Z M8,1 C9.94556803,1 11.5373964,2.575 11.5373964,4.5 L11.5373964,4.5 L11.5373964,6.25 C11.5373964,8.175 9.94556803,9.75 8,9.75 C6.05443197,9.75 4.46260358,8.175 4.46260358,6.25 L4.46260358,6.25 L4.46260358,4.5 C4.46260358,2.575 6.05443197,1 8,1 Z" />
        </g>
    </SvgIcon>
);

Microphone.displayName = 'MicrophoneIcon';

export default Microphone;
