import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextArea } from '../TextArea';

import './TextAreaWithReadMode.scss';

const TextAreaWithReadMode = ({ readMode, value, className, ...others }) =>
    readMode ? (
        <div className="onsolve-alerts-text mt-2 mb-4">{value}</div>
    ) : (
        <TextArea
            {...others}
            value={value}
            className={classNames('onsolve-alert-details-form-message__textarea', className)}
        />
    );

TextAreaWithReadMode.defaultProps = {
    readMode: false
};

TextAreaWithReadMode.propTypes = {
    className: PropTypes.string,
    readMode: PropTypes.bool,
    value: PropTypes.string
};

TextAreaWithReadMode.displayName = 'TextAreaWithReadMode';

export default TextAreaWithReadMode;
