export const FONT_SIZE_UNITS = 'pt';

export const DEFAULT_FONT_SIZE = 'size16';

export const FONT_SIZES = {
    size8: { fontSize: `8${FONT_SIZE_UNITS}` },
    size10: { fontSize: `10${FONT_SIZE_UNITS}` },
    size11: { fontSize: `11${FONT_SIZE_UNITS}` },
    size12: { fontSize: `12${FONT_SIZE_UNITS}` },
    size14: { fontSize: `14${FONT_SIZE_UNITS}` },
    size16: { fontSize: `16${FONT_SIZE_UNITS}` },
    size18: { fontSize: `18${FONT_SIZE_UNITS}` },
    size20: { fontSize: `20${FONT_SIZE_UNITS}` },
    size22: { fontSize: `22${FONT_SIZE_UNITS}` }
};
