import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SortIcon } from '../../../../Icons';
import { DomUtils } from '../../../../utils';

class Toggle extends PureComponent {
    handleToggle = e => {
        const { id, onNodeToggle } = this.props;

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        onNodeToggle(id);
    };

    handleKeyDown = e => {
        const { id, onNodeToggle } = this.props;

        if (e.key === 'Enter' || e.keyCode === 32) {
            onNodeToggle(id);
            e.preventDefault();
        }
    };

    render() {
        const { expanded, isLeaf } = this.props;

        const isRtl = DomUtils.isRtl();
        const rotation = !expanded ? (isRtl ? 90 : 270) : null;

        const className = classnames('onsolve-dropdown-tree__toggle', {
            // Even if the item does not have children and does not need an
            // expanded/collapsed toggle, the component still need to be
            // rendered (with visiblity: hidden), to occupy space. Otherwise,
            // the item will be shifted left appearing as if it were one level
            // up the hierarchy.
            'onsolve-dropdown-tree__toggle--hidden': isLeaf
        });

        return (
            <div className={className}>
                <SortIcon
                    color="primary"
                    rotation={rotation}
                    onClick={this.handleToggle}
                    onKeyDown={this.handleKeyDown}
                />
            </div>
        );
    }
}

Toggle.propTypes = {
    expanded: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isLeaf: PropTypes.bool,
    onNodeToggle: PropTypes.func
};

Toggle.displayName = 'Toggle';

export default Toggle;
