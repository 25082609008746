import { withPlaceholder } from '../../Placeholders';
import { PageHeaderPlaceholder } from '../../Placeholders/Templates';
import { PageHeader } from '..';

export default withPlaceholder({
    classes: {
        root: 'onsolve-page-header'
    },
    customPlaceholder: PageHeaderPlaceholder
})(PageHeader);
