import React from 'react';

import SvgIcon from './SvgIcon';

const Resend = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M5.40754479,11.6095359 L5.40754479,14.4871167 C5.40754479,14.8981997 5.87539209,15.0743781 6.16779665,14.7807474 L7.51285762,13.1951417 L10.2614605,15.2505565 C10.4953841,15.4267349 10.8462696,15.3092826 10.9047505,15.0156519 L12.6591779,6.50036192 C12.7176588,6.14800509 12.4252542,5.91310054 12.0743687,6.03055281 L3.59463653,9.43666882 C3.30223197,9.5541211 3.24375106,9.96520407 3.4776747,10.2001086 L4.41336929,10.9048223 L7.10349124,9.61284724 C7.33741488,9.49539496 7.51285762,9.78902565 7.33741488,9.90647793 L5.40754479,11.6095359 Z" />
            <path
                d="M8.64154115,0.666666667 C7.20766151,0.666666667 5.80297702,1.15266667 4.66038529,2.01133333 L4.66038529,0.666666667 L3.33333333,0.666666667 L3.33333333,4 C3.33333333,4.36866667 3.63059297,4.66666667 3.99685931,4.66666667 L7.3144892,4.66666667 L7.3144892,3.33333333 L5.13945104,3.33333333 C6.10222724,2.48533333 7.35761838,2 8.64154115,2 C10.0382633,2 11.4064539,2.56933333 12.3951076,3.562 L13.3333333,2.61933333 C12.097848,1.378 10.3879415,0.666666667 8.64154115,0.666666667 Z"
                opacity="0.500720796"
            />
        </g>
    </SvgIcon>
);

Resend.displayName = 'ResendIcon';

export default Resend;
