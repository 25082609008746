import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fp as _, OnsolvePropTypes } from '../../utils';

class TableCell extends PureComponent {
    get cellRender() {
        const { dataItem, field, render, ...other } = this.props;

        if (render) {
            return render({ dataItem, field, ...other });
        }
        return <span className="onsolve-table__body-cell-value">{_.get(dataItem, field)}</span>;
    }

    render() {
        const { align, className, sortable, tabIndex = 0 } = this.props;
        const cellClasses = classNames('onsolve-table__body-cell', className, {
            [`text-${align}`]: align !== undefined,
            'onsolve-table__body-cell--sortable': sortable !== undefined
        });

        return (
            <td {...(tabIndex === -1 ? {} : { tabIndex })} className={cellClasses} role="gridcell">
                {this.cellRender}
            </td>
        );
    }
}

TableCell.propTypes = {
    align: OnsolvePropTypes.align,
    className: PropTypes.string,
    dataItem: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    render: PropTypes.func,
    sortable: PropTypes.bool,
    tabIndex: PropTypes.number
};

TableCell.displayName = 'TableCell';

export default TableCell;
