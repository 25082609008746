import React from 'react';

import SvgIcon from './SvgIcon';

const Reorder = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M0 3h14v1H0V3zm0 3.5h14v1H0v-1zM0 10h14v1H0v-1zM7 0l3 2H4l3-2zm0 14l-3-2h6l-3 2z" />
        </SvgIcon>
    );
};

Reorder.displayName = 'ReorderIcon';

export default Reorder;
