import React from 'react';

import SvgIcon from './SvgIcon';

const TimesCircle = ({ ...props }) => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 Z M4.65,4.65 C4.45,4.85 4.45,5.15 4.65,5.35 L7.3,8 L4.65,10.65 C4.45,10.85 4.45,11.15 4.65,11.35 C4.75,11.45 4.85,11.5 5,11.5 C5.15,11.5 5.25,11.45 5.35,11.35 L8,8.7 L10.65,11.35 C10.75,11.45 10.9,11.5 11,11.5 C11.1,11.5 11.25,11.45 11.35,11.35 C11.55,11.15 11.55,10.85 11.35,10.65 L8.7,8 L11.35,5.35 C11.55,5.15 11.55,4.85 11.35,4.65 C11.15,4.45 10.85,4.45 10.65,4.65 L8,7.3 L5.35,4.65 C5.15,4.45 4.85,4.45 4.65,4.65 Z"
        />
    </SvgIcon>
);

TimesCircle.displayName = 'TimesCircleIcon';

export default TimesCircle;
