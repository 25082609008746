import React from 'react';

import SvgIcon from './SvgIcon';

const Emblem = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M12.3,12.3H3.6L4.9,10H2.2l4.4-7.4L8,5l1.4-2.4l4.4,7.4H11L12.3,12.3z M4.4,11.8h7L10.4,10H5.5L4.4,11.8z M10.7,9.5H13 l-3.6-6L8.3,5.5L10.7,9.5z M5.8,9.5h4.3L8,5.9L5.8,9.5z M3,9.5h2.2l2.5-4.1L6.6,3.6L3,9.5z"
        />
    </SvgIcon>
);

Emblem.displayName = 'EmblemIcon';

export default Emblem;
