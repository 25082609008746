function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}

function setMultipleRefs() {
    const refs = arguments;

    return ref => {
        for (let i = 0; i < refs.length; i++) {
            setRef(refs[i], ref);
        }
    };
}

export default {
    setRef,
    setMultipleRefs
};
