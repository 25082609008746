import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Action extends PureComponent {
    handleClick = () => {
        const { onAction, actionData } = this.props;

        if (onAction) {
            onAction(actionData.nodeId, actionData.action);
        }
    };

    render() {
        const { title, className, text, readOnly } = this.props;

        return (
            <i title={title} className={className} onClick={!readOnly ? this.handleClick : undefined}>
                {text}
            </i>
        );
    }
}

Action.defaultProps = {
    onAction: () => {}
};

Action.propTypes = {
    actionData: PropTypes.object,
    className: PropTypes.string,
    onAction: PropTypes.func,
    readOnly: PropTypes.bool,
    text: PropTypes.string,
    title: PropTypes.string
};

Action.displayName = 'Action';

export default Action;
