import React from 'react';

import SvgIcon from './SvgIcon';

const Topic = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M1.68333592,11.0164458 C2.59448028,10.1053015 4.07193596,10.1055003 4.98332555,11.0168899 C5.89471513,11.9282795 5.89491393,13.4057351 4.98376958,14.3168795 C4.07262522,15.2280239 2.59516954,15.2278251 1.68377995,14.3164355 C0.772390366,13.4050459 0.772191566,11.9275902 1.68333592,11.0164458 Z M4.15843915,11.8417763 C3.70274435,11.3860815 2.96401651,11.3859821 2.50844434,11.8415543 C2.05287216,12.2971264 2.05297156,13.0358543 2.50866635,13.4915491 C2.96436114,13.9472439 3.70308898,13.9473433 4.15866116,13.4917711 C4.61423334,13.0361989 4.61413394,12.2974711 4.15843915,11.8417763 Z M3.333046,0.999461504 C9.77530064,1.00130112 14.9982393,6.22423983 15.000079,12.6664945 C15.000171,12.988676 14.7390658,13.24986 14.4168843,13.2498653 C14.0947028,13.2498706 13.8334485,12.9886952 13.8333565,12.6665137 C13.8317008,6.86848448 9.13105599,2.16783964 3.33302681,2.16618399 C3.01084528,2.16609199 2.74966984,1.90483768 2.74967514,1.58265616 C2.74968044,1.26047465 3.01086447,0.999369503 3.333046,0.999461504 Z M3.33389095,5.666472 C7.19783826,5.66856863 10.3302139,8.80094423 10.3323105,12.6648915 C10.3324853,12.9870731 10.0714473,13.2483242 9.74926585,13.2484123 C9.42708437,13.2485005 9.16576295,12.9873922 9.16558813,12.6652106 C9.16384094,9.44525455 6.55352794,6.83494155 3.33357185,6.83319436 C3.01139033,6.83301954 2.75028204,6.57169812 2.75037015,6.24951664 C2.75045827,5.92733516 3.01170943,5.66629718 3.33389095,5.666472 Z"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

Topic.displayName = 'TopicIcon';

export default Topic;
