import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Dot = ({ color, className }) => {
    const rootClasses = classNames('onsolve-dot', className, {
        [`onsolve-dot__${color}`]: color
    });

    return <span className={rootClasses} />;
};

Dot.displayName = 'Dot';

Dot.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Dot.defaultProps = {
    color: 'none'
};

export default Dot;
