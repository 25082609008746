import React from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from '../Selections';

const ListItemCheckbox = ({ checked, disabled, 'aria-label': ariaLabel, onChange }) => {
    const handleChange = (e, { checked }) => onChange(e, checked);

    return (
        <div className="onsolve-list-item__selection">
            <CheckBox checked={checked} disabled={disabled} onChange={handleChange} aria-label={ariaLabel} />
        </div>
    );
};

ListItemCheckbox.displayName = 'ListItemCheckbox';

ListItemCheckbox.defaultProps = {
    onChange: () => {}
};

ListItemCheckbox.propTypes = {
    /**
    Specifies aria-label attribute
    */
    'aria-label': PropTypes.string,
    /**
    Control check state
    */
    checked: PropTypes.bool,
    /**
    Control disable state
    */
    disabled: PropTypes.bool,
    /**
    Callback function for the check state change
    */
    onChange: PropTypes.func
};

export default ListItemCheckbox;
