import React from 'react';

import SvgIcon from './SvgIcon';

const WeatherEvents = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M7.4,14.8909599 C6.9,14.6082202 6.8,14.0427407 7.1,13.5715078 L8.1,12.2520556 L4.1,12.2520556 L7.1,7.91671293 C7.5,7.53972661 8.1,7.44548003 8.6,7.72821977 C9,8.01095951 9.1,8.57643899 8.8,9.0476719 L7.8,10.367124 L11.8,10.367124 L8.8,14.7024667 C8.5,15.0794531 7.9,15.1736996 7.4,14.8909599 Z M14.7,10.3671236 L13.4,8.95342493 C13.8,8.57643862 14,8.10520573 14,7.53972626 C14,6.22027416 13,5.1835618 10.1,6.22027416 C10,5.27780838 9.7,1.88493156 6,1.88493156 C3.8,1.88493156 2,3.58136997 2,5.65479469 C2,6.88000021 2.6,7.91671257 3.6,8.6706852 L2.4,10.1786304 C0.9,9.14191809 0,7.44547968 0,5.65479469 C0,2.54465761 2.7,-5.2903315e-13 6,-5.2903315e-13 C8.6,-5.2903315e-13 10.8,1.50794525 11.6,3.76986313 C14,3.58136997 16,5.37205496 16,7.53972626 C16,8.57643862 15.5,9.61315098 14.7,10.3671236 Z"
            />
        </SvgIcon>
    );
};

WeatherEvents.displayName = 'WeatherEventsIcon';

export default WeatherEvents;
