import { withPlaceholder } from '../../Placeholders';
import { SingleRowPlaceholder } from '../../Placeholders/Templates';
import SearchBox from '../SearchBox';

export default withPlaceholder({
    classes: {
        root: 'onsolve-placeholder__search_box'
    },
    customPlaceholder: SingleRowPlaceholder
})(SearchBox);
