import React from 'react';
import SvgIcon from './SvgIcon';

const TimeElement = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 Z M8,1.93333333 C4.64947252,1.93333333 1.93333333,4.64947252 1.93333333,8 C1.93333333,11.3505275 4.64947252,14.0666667 8,14.0666667 C11.3505275,14.0666667 14.0666667,11.3505275 14.0666667,8 C14.0666667,4.64947252 11.3505275,1.93333333 8,1.93333333 Z M8,11.2666667 C8.2290959,11.2666667 8.41963448,11.4317501 8.45914804,11.6494494 L8.46666667,11.7333333 L8.46666667,12.6666667 C8.46666667,12.9243995 8.25773288,13.1333333 8,13.1333333 C7.7709041,13.1333333 7.58036552,12.9682499 7.54085196,12.7505506 L7.53333333,12.6666667 L7.53333333,11.7333333 C7.53333333,11.4756005 7.74226712,11.2666667 8,11.2666667 Z M5.4653518,4.81601841 L5.52998316,4.87001684 L8.1932,7.53333333 L12.6666667,7.53333333 C12.8957626,7.53333333 13.0863011,7.69841682 13.1258147,7.91611608 L13.1333333,8 C13.1333333,8.2290959 12.9682499,8.41963448 12.7505506,8.45914804 L12.6666667,8.46666667 L8,8.46666667 C7.90098584,8.46666667 7.80521598,8.43520009 7.7260599,8.37780477 L7.67001684,8.32998316 L4.87001684,5.52998316 C4.68777217,5.3477385 4.68777217,5.0522615 4.87001684,4.87001684 C5.0320121,4.70802157 5.28347487,4.6900221 5.4653518,4.81601841 Z M4.26666667,7.53333333 C4.52439955,7.53333333 4.73333333,7.74226712 4.73333333,8 C4.73333333,8.2290959 4.56824985,8.41963448 4.35055059,8.45914804 L4.26666667,8.46666667 L3.33333333,8.46666667 C3.07560045,8.46666667 2.86666667,8.25773288 2.86666667,8 C2.86666667,7.7709041 3.03175015,7.58036552 3.24944941,7.54085196 L3.33333333,7.53333333 L4.26666667,7.53333333 Z M8,2.86666667 C8.2290959,2.86666667 8.41963448,3.03175015 8.45914804,3.24944941 L8.46666667,3.33333333 L8.46666667,4.26666667 C8.46666667,4.52439955 8.25773288,4.73333333 8,4.73333333 C7.7709041,4.73333333 7.58036552,4.56824985 7.54085196,4.35055059 L7.53333333,4.26666667 L7.53333333,3.33333333 C7.53333333,3.07560045 7.74226712,2.86666667 8,2.86666667 Z"
        />
    </SvgIcon>
);

TimeElement.displayName = 'TimeElementIcon';

export default TimeElement;
