import React, { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';
import { keyCodes } from '../../../common/constants';
import { fp as _, DomUtils } from '../../../components/utils';
import { RowSelectionType } from '../constants';
import { HeaderSelectionCell, HeaderCell, TableDraggableHeaderCell } from '../Cells';

class HeaderRow extends Component {
    constructor(props) {
        super(props);

        this.headerRowRefs = {};
    }

    componentWillUnmount() {
        this.clearRefs();
    }

    setRef = (key, ref) => {
        if (this.headerRowRefs[key] === undefined) {
            this.headerRowRefs[key] = ref;
        }
    };

    clearRefs = () => {
        this.headerRowRefs = {};
    };

    setFocusToNextHeaderElement = (index, charCode) => {
        const { LEFT, RIGHT } = keyCodes;
        let nextElement = null;

        if (charCode === LEFT) {
            nextElement = this.headerRowRefs[--index];
        }

        if (charCode === RIGHT) {
            nextElement = this.headerRowRefs[++index];
        }

        if (nextElement && nextElement.current) {
            DomUtils.setFocus(nextElement.current);
        }
    };

    handleKeyUp = (e, currIndex) => {
        const charCode = DomUtils.getCharCode(e);
        const { LEFT, RIGHT } = keyCodes;

        if (!_.includes([LEFT, RIGHT], charCode)) {
            return;
        }

        const { headerRowRefs } = this;
        const [currElementChildren] = headerRowRefs[currIndex].current.children;
        let nextElement = null;

        if (currElementChildren.classList.contains('onsolve-table__header-cell--selection')) {
            const [switcher, dropdown] = currElementChildren.children;
            const [checkbox] = switcher.children;
            const [input] = checkbox.children;
            const [DropDownIcon] = dropdown.children;

            if (charCode === RIGHT) {
                if (document.activeElement === input) {
                    DomUtils.setFocus(DropDownIcon);
                } else {
                    this.setFocusToNextHeaderElement(currIndex, charCode);
                }
            }

            if (charCode === LEFT) {
                if (document.activeElement === DropDownIcon) {
                    DomUtils.setFocus(input);
                } else {
                    this.setFocusToNextHeaderElement(currIndex, charCode);
                }
            }
        } else {
            if (charCode === LEFT) {
                nextElement = headerRowRefs[--currIndex];
            }

            if (charCode === RIGHT) {
                nextElement = headerRowRefs[++currIndex];
            }

            if (nextElement && nextElement.current) {
                const [nextElementChildren] = nextElement.current.children;

                if (nextElementChildren.classList.contains('onsolve-table__header-cell--selection')) {
                    const [switcher, dropdown] = nextElementChildren.children;
                    const [checkbox] = switcher.children;
                    const [input] = checkbox.children;
                    const [DropDownIcon] = dropdown.children;

                    if (charCode === RIGHT) {
                        if (document.activeElement === input) {
                            DomUtils.setFocus(DropDownIcon);
                        } else if (document.activeElement === DropDownIcon) {
                            this.setFocusToNextHeaderElement(currIndex, charCode);
                        } else {
                            DomUtils.setFocus(input);
                        }
                    }

                    if (charCode === LEFT) {
                        if (document.activeElement === input) {
                            this.setFocusToNextHeaderElement(currIndex, charCode);
                        } else if (document.activeElement === DropDownIcon) {
                            DomUtils.setFocus(input);
                        } else {
                            DomUtils.setFocus(DropDownIcon);
                        }
                    }
                } else {
                    this.setFocusToNextHeaderElement(currIndex, charCode);
                }
            }
        }
    };

    handleColumnResize = column => (e, data) => {
        const { onColumnResize } = this.props;

        onColumnResize(e, { column, ...data });
    };

    isCheckboxDisabled = disabled => {
        if (typeof disabled === 'function') {
            return disabled();
        }

        return disabled;
    };

    renderHeaderCells = () => {
        const {
            columns,
            resizable,
            selectedField,
            sort,
            onHeaderSelectionChange,
            onSortChange,
            rowSelection,
            draggableField
        } = this.props;

        return _.map(columns, (column, key) => {
            const { field, headerCell, disabled, ...otherCellProps } = column;

            if (draggableField && draggableField === field) {
                return <TableDraggableHeaderCell key={key} {...column} />;
            }

            if (selectedField && selectedField === field && rowSelection === RowSelectionType.Multiple) {
                return (
                    <HeaderSelectionCell
                        key={key}
                        field={field}
                        render={headerCell}
                        {...otherCellProps}
                        selectionValue={column.headerSelectionValue}
                        onSelectionChange={onHeaderSelectionChange}
                        index={key}
                        onKeyUp={this.handleKeyUp}
                        setRef={this.setRef}
                        disabled={this.isCheckboxDisabled(disabled)}
                    />
                );
            }
            return (
                <HeaderCell
                    key={key}
                    field={field}
                    sort={sort}
                    render={headerCell}
                    resizable={resizable}
                    {...otherCellProps}
                    onSortChange={onSortChange}
                    onColumnResize={this.handleColumnResize(column)}
                    index={key}
                    onKeyUp={this.handleKeyUp}
                    setRef={this.setRef}
                />
            );
        });
    };

    render() {
        const { innerRef } = this.props;

        return (
            <tr ref={innerRef} onKeyDown={this.handleArrowKeys} role="row">
                {this.renderHeaderCells()}
            </tr>
        );
    }
}

HeaderRow.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            headerCell: PropTypes.func
        })
    ).isRequired,
    draggableField: PropTypes.string,
    innerRef: PropTypes.object,
    onColumnResize: PropTypes.func,
    onHeaderSelectionChange: PropTypes.func,
    onSortChange: PropTypes.func,
    resizable: PropTypes.bool,
    rowSelection: PropTypes.oneOf([RowSelectionType.Multiple, RowSelectionType.Single]),
    selectedField: PropTypes.string,
    sort: PropTypes.shape({
        field: PropTypes.string,
        dir: PropTypes.oneOf(['asc', 'desc'])
    })
};

HeaderRow.displayName = 'HeaderRowWithoutForwardedRef';

const ForwarderHeaderRow = forwardRef((props, ref) => <HeaderRow innerRef={ref} {...props} />);

ForwarderHeaderRow.displayName = 'HeaderRow';

export default ForwarderHeaderRow;
