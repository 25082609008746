import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DropdownButton } from '../../Button';
import { Menu, MenuItem } from '../../Menu';
import { EDITOR_TYPES, EDITOR_TYPE_VALUES } from '../constants/editorTypes';
import { EditorTypeWarningModal } from './EditorTypeWarningModal';

const EditorTypeDropdown = ({ currentType, onTypeChange }) => {
    const [type, setType] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    const handleItemChange = useCallback(
        index => {
            onTypeChange(EDITOR_TYPES[index]);
            setIsDropdownOpen(false);
        },
        [onTypeChange]
    );

    const handleClick = useCallback(
        index => () => {
            const type = EDITOR_TYPES[index].value;

            if (type === currentType.value) {
                setIsDropdownOpen(false);
                return;
            }

            if (currentType.value === EDITOR_TYPE_VALUES.HTML && type === EDITOR_TYPE_VALUES.EDITOR) {
                setType(index);
                setIsModalOpen(true);
                setIsDropdownOpen(false);
                return;
            }

            handleItemChange(index);
        },
        [currentType, handleItemChange]
    );

    const handleConfirm = useCallback(() => {
        handleItemChange(type);
        setIsModalOpen(false);
    }, [type, handleItemChange]);

    const handleCancel = useCallback(() => {
        setIsModalOpen(false);
        setType(null);
    }, []);

    return (
        <>
            <DropdownButton
                className="onsolve-wysiwyg-editor__type-dropdown text-uppercase ml-auto"
                title={<FormattedMessage {...currentType.translation} />}
                isOpen={isDropdownOpen}
                onToggle={handleToggle}
            >
                <Menu>
                    {EDITOR_TYPES.map(({ translation, value }, index) => (
                        <MenuItem key={value} onClick={handleClick(index)}>
                            <FormattedMessage {...translation} />
                        </MenuItem>
                    ))}
                </Menu>
            </DropdownButton>
            <EditorTypeWarningModal isOpen={isModalOpen} onCancel={handleCancel} onConfirm={handleConfirm} />
        </>
    );
};

EditorTypeDropdown.propTypes = {
    currentType: PropTypes.shape({
        value: PropTypes.string,
        translation: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    }).isRequired,
    onTypeChange: PropTypes.func.isRequired
};

EditorTypeDropdown.displayName = 'EditorTypeDropdown';

export default EditorTypeDropdown;
