import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { fp as _ } from '../../../../../components/utils';
import { componentsTranslations } from '../../../../../components/translations';

const MAX_DISPLAYED_SELECTED_DIVISION_NAMES = 2;

class Placeholder extends PureComponent {
    getTagLabels = () => {
        const { tags, intl } = this.props;

        return tags.length <= MAX_DISPLAYED_SELECTED_DIVISION_NAMES
            ? _.map(tags, 'label').join(', ')
            : intl.formatMessage(componentsTranslations.ng_components_division_dropdown_options_selected, {
                  0: tags.length
              });
    };

    render() {
        const { tags, text, textSize } = this.props;

        if (_.isEmpty(tags)) {
            return (
                <span
                    className={cx('onsolve-input-field__placeholder', {
                        'onsolve-input-field__placeholder--xs': textSize === 'xs',
                        'onsolve-input-field__placeholder--sm': textSize === 'sm'
                    })}
                >
                    {text}
                </span>
            );
        }
        return <span className={'onsolve-input-field__tags'}>{this.getTagLabels()}</span>;
    }
}

Placeholder.propTypes = {
    intl: PropTypes.object,
    tags: PropTypes.array,
    text: PropTypes.string.isRequired,
    textSize: PropTypes.oneOf(['sm', 'xs'])
};

Placeholder.displayName = 'Placeholder';

export default Placeholder;
