import React from 'react';
import SvgIcon from './SvgIcon';

const MultiLineElement = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8.55555556,12.0140845 C8.80101544,12.0140845 9,12.2347892 9,12.5070423 C9,12.749045 8.84277764,12.9503181 8.63544501,12.9920578 L8.55555556,13 L1.44444444,13 C1.19898456,13 1,12.7792953 1,12.5070423 C1,12.2650395 1.15722236,12.0637664 1.36455499,12.0220267 L1.44444444,12.0140845 L8.55555556,12.0140845 Z M14.5625,7.50704225 C14.8041246,7.50704225 15,7.72774695 15,8 C15,8.24200271 14.8452342,8.44327586 14.6411412,8.48501554 L14.5625,8.49295775 L1.4375,8.49295775 C1.19587542,8.49295775 1,8.27225305 1,8 C1,7.75799729 1.15476577,7.55672414 1.35885882,7.51498446 L1.4375,7.50704225 L14.5625,7.50704225 Z M14.5625,3 C14.8041246,3 15,3.2207047 15,3.49295775 C15,3.73496045 14.8452342,3.9362336 14.6411412,3.97797328 L14.5625,3.98591549 L1.4375,3.98591549 C1.19587542,3.98591549 1,3.76521079 1,3.49295775 C1,3.25095504 1.15476577,3.04968189 1.35885882,3.00794221 L1.4375,3 L14.5625,3 Z"
        />
    </SvgIcon>
);

MultiLineElement.displayName = 'MultiLineElementIcon';

export default MultiLineElement;
