import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

class Tooltip extends PureComponent {
    constructor(props) {
        super(props);

        this.id = `tooltip_${props.id || uuid()}`;
        this.state = {
            isOpen: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { disableHover } = props;
        const { isOpen } = state;

        if (disableHover && isOpen) {
            return {
                isOpen: false
            };
        }
        return null;
    }

    handleToggleToolTip = () => {
        const { disableHover } = this.props;

        this.setState(prevState => ({ isOpen: !disableHover && !prevState.isOpen }));
    };

    render() {
        const { isOpen } = this.state;
        const { children, title, disableHover, tabIndex, ...other } = this.props;

        const tooltipProps = { isOpen: disableHover ? false : isOpen, target: this.id, ...other };

        if (!disableHover) {
            tooltipProps.toggle = this.handleToggleToolTip;
        }

        return (
            <Fragment>
                {React.cloneElement(children, { ...children.props, id: this.id, tabIndex })}
                <ReactstrapTooltip {...tooltipProps}>{title}</ReactstrapTooltip>
            </Fragment>
        );
    }
}

Tooltip.propTypes = {
    /**
    Target element that the tooltip is attached to.
    */
    children: PropTypes.element.isRequired,
    /**
    Disable ability to show tooltip when the mouse cursor is hovered.
    */
    disableHover: PropTypes.bool,
    /**
    Id attribute of the target element.
    */
    id: PropTypes.string,
    /**
    Tab Index attribute of the target element.
    */
    tabIndex: PropTypes.number,
    /**
    The display content of the tooltip.
    */
    title: PropTypes.node.isRequired
};

Tooltip.defaultProps = {
    tabIndex: 0
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
