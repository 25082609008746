import React from 'react';
import SvgIcon from './SvgIcon';

const FormatItalic = props => {
    return (
        <SvgIcon {...props}>
            <path stroke="none" d="M4 0v2h2.58l-3.66 8H0v2h8v-2H5.42l3.66-8H12V0z" fillRule="nonzero" />
        </SvgIcon>
    );
};

FormatItalic.displayName = 'FormatItalicIcon';

export default FormatItalic;
