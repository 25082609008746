import React from 'react';

import SvgIcon from './SvgIcon';

const Location = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M12.2346667,11.026 C14.266,11.3966667 16,12.098 16,13.3333333 C16,15.4386667 10.9713333,16 8,16 C5.02866667,16 0,15.4386667 0,13.3333333 C0,12.098 1.734,11.3966667 3.76466667,11.0253333 C4.07266667,11.4486667 4.384,11.85 4.68866667,12.222 C2.652,12.516 1.45666667,13.0346667 1.32933333,13.3606667 C1.51533333,13.7793333 3.97133333,14.666 8,14.666 C11.95,14.666 14.388,13.814 14.6573333,13.3326667 C14.474,13.006 13.2886667,12.5086667 11.31,12.2226667 C11.6153333,11.8506667 11.9266667,11.4493333 12.2346667,11.026 Z M8,0 C10.622,0 13.3333333,1.99466667 13.3333333,5.33333333 C13.3333333,8.4 8.97066667,12.8846667 8.47333333,13.3866667 C8.348,13.5126667 8.178,13.5833333 8,13.5833333 C7.822,13.5833333 7.652,13.5126667 7.52666667,13.3866667 C7.02933333,12.8846667 2.66666667,8.4 2.66666667,5.33333333 C2.66666667,1.99466667 5.378,0 8,0 Z M8,6.66666667 C8.73666667,6.66666667 9.33333333,6.07 9.33333333,5.33333333 C9.33333333,4.59666667 8.73666667,4 8,4 C7.26333333,4 6.66666667,4.59666667 6.66666667,5.33333333 C6.66666667,6.07 7.26333333,6.66666667 8,6.66666667 Z"
        />
    </SvgIcon>
);

Location.displayName = 'LocationIcon';

export default Location;
