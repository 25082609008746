import React from 'react';
import SvgIcon from './SvgIcon';

const Duplicate = props => (
    <SvgIcon {...props}>
        <g stroke="none">
            <path
                d="M1.875,12.375 L4.9375,12.375 C5.17912458,12.375 5.375,12.5708754 5.375,12.8125 C5.375,13.0541246 5.17912458,13.25 4.9375,13.25 L1.4375,13.25 C1.19587542,13.25 1,13.0541246 1,12.8125 L1,1.4375 C1,1.19587542 1.19587542,1 1.4375,1 L11.0625,1 C11.3041246,1 11.5,1.19587542 11.5,1.4375 L11.5,3.1875 C11.5,3.42912458 11.3041246,3.625 11.0625,3.625 C10.8208754,3.625 10.625,3.42912458 10.625,3.1875 L10.625,1.875 L1.875,1.875 L1.875,12.375 Z M8,6.25 L8,14.125 L14.125,14.125 L14.125,6.25 L8,6.25 Z M7.5625,5.375 L14.5625,5.375 C14.8041246,5.375 15,5.57087542 15,5.8125 L15,14.5625 C15,14.8041246 14.8041246,15 14.5625,15 L7.5625,15 C7.32087542,15 7.125,14.8041246 7.125,14.5625 L7.125,5.8125 C7.125,5.57087542 7.32087542,5.375 7.5625,5.375 Z"
                id="path-1"
            />
        </g>
    </SvgIcon>
);

Duplicate.displayName = 'DuplicateIcon';

export default Duplicate;
