import React from 'react';
import SvgIcon from './SvgIcon';

const Flag = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <path d="M1.438 1.438v13.125" strokeLinecap="round" />
                <path d="M4.063 3.188h7.875l-1.75 3.5 1.75 3.5H4.063z" />
            </g>
        </SvgIcon>
    );
};

Flag.displayName = 'FlagIcon';

export default Flag;
