import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ListItemActions = ({ children, className }) => (
    <div className={classNames('onsolve-list-item__actions', className)}>{children}</div>
);

ListItemActions.displayName = 'ListItemActions';

ListItemActions.propTypes = {
    /**
     The content of wrapped component.
     */
    children: PropTypes.node,
    /**
    Specifies wrapper class name
    */
    className: PropTypes.string
};

export default ListItemActions;
