import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import { DomUtils, ReactUtils, fp as _ } from '../../utils';
import DropdownToggle from './DropdownToggle';

class DropdownButton extends Component {
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();
    }

    handleToggle = () => {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        this.handleMenuClose();
    };

    handleMenuClose = () => {
        const { onToggle } = this.props;

        if (onToggle) {
            onToggle();
        }
    };

    render() {
        const { isOpen, children, title, showToggle, innerRef, className, ...others } = this.props;
        const isRtl = DomUtils.isRtl();
        const menuProps = {
            open: isOpen,
            position: isRtl ? 'left' : 'right',
            onClose: this.handleMenuClose
        };
        const otherProps = _.omit(others, 'onToggle');
        const rootClassNames = classNames('onsolve-dropdown-btn', className);

        return (
            <Fragment>
                <Button
                    className={rootClassNames}
                    innerRef={ReactUtils.setMultipleRefs(this.buttonRef, innerRef)}
                    onClick={this.handleToggle}
                    aria-haspopup="true"
                    aria-expanded={isOpen}
                    {...otherProps}
                >
                    <Fragment>
                        <div className="onsolve-dropdown-btn__title">{title}</div>
                        {showToggle && <DropdownToggle />}
                    </Fragment>
                </Button>
                {React.cloneElement(children, {
                    anchorEl: this.buttonRef.current,
                    ...menuProps
                })}
            </Fragment>
        );
    }
}

DropdownButton.propTypes = {
    /**
    The content of the component.
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to the component. `string`
    */
    className: PropTypes.string,
    /**
    Specifies a button color.
    */
    color: PropTypes.string,
    /**
    Specifies a button enabling.
    */
    disabled: PropTypes.bool,
    /**
     * @ignore
     */
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
    Shows if the dropdown open.
    */
    isOpen: PropTypes.bool,
    /**
    Callback function fired on toggle.
    */
    onToggle: PropTypes.func,
    /**
    Shows if the dropdown active.
    */
    showToggle: PropTypes.bool,
    /**
    Specifies a button title.
    */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

DropdownButton.defaultProps = {
    color: 'primary',
    showToggle: true
};

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
