import React from 'react';

import SvgIcon from './SvgIcon';

const ZoomOut = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path d="M10 8h-2v2h-2v-2h-2v-2h2v-2h2v2h2z" />
                <path d="M15.707 13.293l-2.707-2.707c0.63-1.050 1-2.275 1-3.586 0-3.86-3.141-7-7-7s-7 3.14-7 7c0 3.86 3.141 7 7 7 1.312 0 2.536-0.369 3.586-1l2.707 2.707c0.195 0.195 0.451 0.293 0.707 0.293s0.512-0.098 0.707-0.293l1-1c0.391-0.391 0.391-1.023 0-1.414zM7 12c-2.761 0-5-2.239-5-5s2.239-5 5-5c2.761 0 5 2.239 5 5s-2.239 5-5 5z" />
            </g>
        </SvgIcon>
    );
};

ZoomOut.displayName = 'ZoomOutIcon';

export default ZoomOut;
