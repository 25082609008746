function getElement(element) {
    if (element && element.current) {
        return element.current;
    }
    return element;
}

const bind = (element, event, callback, options) => {
    const el = getElement(element);

    if (el) {
        return el.addEventListener && el.addEventListener(event, callback, options);
    }
};

const unbind = (element, event, callback, options) => {
    const el = getElement(element);

    if (el) {
        return el.removeEventListener && el.removeEventListener(event, callback, options);
    }
};

export default {
    bind,
    unbind
};
