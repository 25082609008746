export const INDENT_BOUNDS = {
    MIN: 0,
    MAX: 120,
    STEP: 16
};

const indentSizes = {};

const initIndentSizes = () => {
    for (let i = INDENT_BOUNDS.MIN; i < INDENT_BOUNDS.MAX; i++) {
        indentSizes[`indent_${i}`] = { 'margin-left': `${i * INDENT_BOUNDS.STEP}px` };
    }
};

initIndentSizes();

export const INDENT_SIZES = indentSizes;

export const INDENT_STATE_KEYS = {
    STYLE_NAME: 'margin-left'
};
