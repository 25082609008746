import { ENTITY_TYPES } from '../../constants';

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPES.LINK;
    }, callback);
};

export default findLinkEntities;
