import React, { lazy, Suspense } from 'react';
import { LoadingSpinner } from '../Loading';

const WysiwygEditor = lazy(() => import('./WysiwygEditor'));

const LazyWysiwygEditor = props => (
    <Suspense fallback={<LoadingSpinner color="primary" className="onsolve-loading__icon--small" />}>
        <WysiwygEditor {...props} />
    </Suspense>
);

LazyWysiwygEditor.displayName = 'LazyWysiwygEditor';

export default LazyWysiwygEditor;
