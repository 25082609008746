import React from 'react';
import PropTypes from 'prop-types';

import BlurComponent from './BlurComponent';
import { BlurLoaderContextConsumer, I18nBlurLoaderConsumer } from './BlurLoaderContext';

const BlurLoader = ({ isLoading, variant, component, children, ...props }) => {
    // eslint-disable-next-line react/display-name
    const blurContextRender = otherLoading => (
        <BlurLoaderContextConsumer>
            {context =>
                otherLoading || context.isLoading ? React.createElement(component, { ...context, ...props }) : children
            }
        </BlurLoaderContextConsumer>
    );

    if (variant === 'i18n') {
        return <I18nBlurLoaderConsumer>{loading => blurContextRender(loading || isLoading)}</I18nBlurLoaderConsumer>;
    }

    return blurContextRender(isLoading);
};

BlurLoader.defaultProps = {
    component: BlurComponent,
    variant: 'i18n'
};

BlurLoader.propTypes = {
    /**
    The child display content to be blurred.
    */
    children: PropTypes.node,
    /**
    An alternative component that is displayed while the BlurLoader is active/loading.
    */
    component: PropTypes.func,
    /**
    Overrides default loading status (of whether or not to display the "loading component").
    */
    isLoading: PropTypes.bool,
    /**
    The variant of BlurLoader, currently there's only 'i18n' and anything other than 'i18n'.
    By default, the 'i18n' variant will load when 'i18n' language is loading, otherwise it relies only on "isLoading".
    */
    variant: PropTypes.string
};

BlurLoader.displayName = 'BlurLoader';

export default BlurLoader;
