import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBoxCircleBlank = props => (
    <SvgIcon {...props}>
        <circle
            cx="8"
            cy="8"
            r="6"
            fill="none"
            strokeLinecap="round"
            stroke="#565858"
            strokeLinejoin="round"
            data-cap="butt"
        />
    </SvgIcon>
);

CheckBoxCircleBlank.displayName = 'CheckBoxCircleBlankIcon';

export default CheckBoxCircleBlank;
