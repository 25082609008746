import React from 'react';
import PropTypes from 'prop-types';

const CardListActions = ({ children }) => {
    const childrens = React.Children.map(children || null, (child, i) => {
        return (
            <div className="onsolve-card-list__cell--icon" key={i}>
                {child}
            </div>
        );
    });

    return childrens;
};

CardListActions.propTypes = {
    /**
    Specifies actions for a card item
    */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

CardListActions.displayName = 'CardListActions';

export default CardListActions;
