const normalizePaginatorSettings = settings => {
    const props = settings === true || settings === undefined ? {} : settings;
    const { buttonCount, info, pageSizes, pageButtons } = props;

    return {
        buttonCount: buttonCount === undefined ? 5 : buttonCount,
        info: info === undefined ? true : info,
        pageSizes: pageSizes === undefined ? [25, 50, 100, 200] : pageSizes,
        pageButtons: pageButtons === undefined ? true : pageButtons
    };
};

export default normalizePaginatorSettings;
