import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stickybits from 'stickybits';

import { ReactUtils } from '../utils';

// TODO: create helpers for browser detection
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const Sticky = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        disabled,
        onInitial,
        onSticky,
        onStuck,
        parentClassName,
        placement,
        scrollTarget,
        stickyClassName,
        stuckClassName,
        ...rest
    } = props;

    const sticky = React.useRef(null);
    const wrapperRef = React.useRef(null);

    const applyStyle = React.useCallback(
        ({ classes, styles }, item) => {
            const currentClassName = item.el.className.split(' ').filter(x => /\s*/.test(x));

            Object.keys(classes).forEach(key => {
                if (classes[key] && key !== item.props.stickyChangeClass) {
                    if (!currentClassName.includes(key)) {
                        currentClassName.push(key);
                    }
                } else {
                    const keyIndex = currentClassName.indexOf(key);

                    if (keyIndex >= 0) {
                        currentClassName.splice(keyIndex, 1);
                    }
                }
            });

            item.el.className = currentClassName.join(' ');

            const { style } = item.el;

            Object.keys(styles).forEach(key => {
                style[key] = styles[key];
            });

            style.position = styles.position || 'absolute';

            switch (style.position) {
                case 'fixed':
                    if (!style.width || style.width === 'auto') {
                        style.width = 'inherit';
                    }
                    break;
                case 'absolute':
                    style.position = 'relative';
                    if (!style.width || style.width === 'inherit') {
                        style.width = 'auto';
                    }
                    break;
                default:
                    break;
            }

            if (classes[item.props.stuckClass]) {
                onStuck && onStuck();
            } else if (Object.values(classes).every(enabled => !enabled)) {
                onInitial && onInitial();
            } else {
                onSticky && onSticky();
            }
        },
        [onInitial, onSticky, onStuck]
    );

    React.useEffect(() => {
        if (disabled) {
            if (sticky.current) {
                sticky.current.cleanup();
                sticky.current = null;
            }
            return;
        }
        const stickybitsOptions = {
            applyStyle,
            parentClass: parentClassName,
            stickyClass: stickyClassName,
            stuckClass: stuckClassName,
            verticalPosition: placement,
            ...(scrollTarget && { scrollEl: scrollTarget })
        };

        if (sticky.current) {
            sticky.current.update({
                ...sticky.current.props,
                ...stickybitsOptions
            });
        } else {
            sticky.current = stickybits(wrapperRef.current, {
                ...stickybitsOptions,
                useStickyClasses: true
            });

            // IE11 doesn't init sticky element without scroll event
            if (isIE11) {
                const {
                    props: { scrollEl }
                } = sticky.current;

                setTimeout(() => {
                    scrollEl.scrollTop += 1;
                }, 50);
            }
        }
    }, [applyStyle, disabled, parentClassName, placement, scrollTarget, stickyClassName, stuckClassName]);

    React.useEffect(() => {
        return () => {
            if (sticky.current) {
                sticky.current.cleanup();
            }
        };
    }, []);

    return (
        <div
            ref={ReactUtils.setMultipleRefs(ref, wrapperRef)}
            className={classNames(`onsolve-sticky--${placement}`, className, {
                'onsolve-sticky--disabled': disabled
            })}
            aria-disabled={disabled}
            {...rest}
        >
            {children}
        </div>
    );
});

Sticky.propTypes = {
    /**
     * The display content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     * Disable sticky behavior.
     */
    disabled: PropTypes.bool,
    /**
     * Callback function fired when the sticky container is in initial placement.
     * @returns {void}
     */
    onInitial: PropTypes.func,
    /**
     * Callback function fired when the sticky container is floating.
     * @returns {void}
     */
    onSticky: PropTypes.func,
    /**
     * Callback function fired when the sticky container is stuck.
     * @returns {void}
     */
    onStuck: PropTypes.func,
    /**
     * Override or extend the styles applied to the parent element.
     */
    parentClassName: PropTypes.string,
    /**
     * Specifies whether sticky container should act like header of footer.
     */
    placement: PropTypes.oneOf(['top', 'bottom']),
    /**
     * Reference to the scroll container.
     */
    scrollTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
    /**
     * Override or extend the styles applied to the sticky container when is is floating.
     */
    stickyClassName: PropTypes.string,
    /**
     * Override or extend the styles applied to the sticky container when is is stuck.
     */
    stuckClassName: PropTypes.string
};

Sticky.defaultProps = {
    parentClassName: 'onsolve-sticky--parent',
    placement: 'top',
    stickyClassName: 'onsolve-sticky--is-sticky',
    stuckClassName: 'onsolve-sticky--is-stuck'
};

Sticky.displayName = 'Sticky';

export default Sticky;
