import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '../Typography';

const ListItemSubtitle = ({ text, children, className, ...other }) => (
    <div className={classNames('onsolve-list-item__subtitle', className)}>
        {text ? <Typography {...other}>{text}</Typography> : children}
    </div>
);

ListItemSubtitle.displayName = 'ListItemSubtitle';

ListItemSubtitle.defaultProps = {
    variant: 'p12'
};

ListItemSubtitle.propTypes = {
    /**
    Content for the component
    */
    children: PropTypes.node,
    /**
    Specifies class name for the wrapper
    */
    className: PropTypes.string,
    /**
    Text to display
    */
    text: PropTypes.string,
    /**
    Specifies text variant
    */
    variant: PropTypes.string
};

export default ListItemSubtitle;
