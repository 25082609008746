import PropTypes from 'prop-types';
import colors from './colors';

export default {
    iconSizeShape: PropTypes.oneOf(['sm', 'md', 'lg']),
    alignShape: PropTypes.oneOf(['left', 'right', 'center']),
    buttonSizeShape: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    iconColorsShape: PropTypes.oneOf(colors.iconColors),
    themeColorsShape: PropTypes.oneOf(colors.themeColors),

    dragShape: PropTypes.shape({
        iconColor: PropTypes.oneOf(colors.iconColors),
        iconSize: PropTypes.oneOf(['sm', 'md', 'lg']),
        keyCodes: PropTypes.shape({
            lift: PropTypes.arrayOf(PropTypes.number),
            drop: PropTypes.arrayOf(PropTypes.number),
            cancel: PropTypes.arrayOf(PropTypes.number),
            up: PropTypes.arrayOf(PropTypes.number),
            down: PropTypes.arrayOf(PropTypes.number)
        }),
        lockAxis: PropTypes.oneOf(['x', 'y']),
        lockToContainerEdges: PropTypes.bool,
        useLocalState: PropTypes.bool
    })
};
