import React from 'react';

import SvgIcon from './SvgIcon';

const ExclamationCircle = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            fillRule="evenodd"
            d="M8 1C4.15 1 1 4.15 1 8s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm.875 10.5h-1.75V7.125h1.75V11.5zM8 6.25c-.525 0-.875-.35-.875-.875S7.475 4.5 8 4.5s.875.35.875.875-.35.875-.875.875z"
        />
    </SvgIcon>
);

ExclamationCircle.displayName = 'ExclamationCircleIcon';

export default ExclamationCircle;
