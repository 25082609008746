import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputFieldControl = React.forwardRef(function InputFieldControl(props, ref) {
    const { children, className, ...other } = props;
    const rootClasses = classNames('onsolve-input-field__control', className);

    return (
        <div ref={ref} className={rootClasses} {...other}>
            {children}
        </div>
    );
});

InputFieldControl.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string
};

InputFieldControl.displayName = 'InputFieldControl';

export default InputFieldControl;
