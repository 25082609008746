import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { componentsTranslations } from '../translations';

import TagEditorInput from './TagEditorInput';
import TagEditorTag from './TagEditorTag';

class TagEditor extends Component {
    state = {
        inputValue: '',
        tags: this.props.tags || [],
        error: null
    };

    areStringsEqual = (str1, str2) => str1.toLowerCase() === str2.toLowerCase();

    handleTagInputChange = newValue => {
        const { onInputChange } = this.props;

        this.setState(
            { inputValue: newValue, error: null },
            () => onInputChange && onInputChange(this.state.inputValue)
        );
    };

    handleTagAdd = () => {
        const { duplicateItemsErrStr, intl, onTagAdd, validateTag } = this.props;
        const { inputValue, tags } = this.state;

        const newTag = inputValue.trim();

        if (newTag.length === 0) {
            return;
        }

        // check tag uniqueness
        if (tags.some(currentValue => this.areStringsEqual(currentValue, newTag))) {
            const error = duplicateItemsErrStr
                ? duplicateItemsErrStr
                : intl.formatMessage(componentsTranslations.ng_components_enter_duplicate_item);

            this.setState({ error });
            return;
        }

        // check external tag validation
        const externalValidationResult = validateTag ? validateTag(newTag) : true;

        if (externalValidationResult !== true) {
            this.setState({ error: externalValidationResult });
            return;
        }

        // all ok, add tag
        this.setState(
            {
                inputValue: '',
                error: null,
                tags: [...tags, newTag]
            },
            () => onTagAdd && onTagAdd(this.state.tags)
        );
    };

    handleTagDelete = tagToDelete => () => {
        const { onTagDelete } = this.props;
        const { tags } = this.state;

        this.setState(
            {
                error: null,
                tags: tags.filter(tag => !this.areStringsEqual(tag, tagToDelete))
            },
            () => onTagDelete && onTagDelete(this.state.tags)
        );
    };

    render() {
        const { intl, placeholder, title, disabled, error: externalError } = this.props;
        const { error, inputValue, tags } = this.state;

        return (
            <div className="onsolve-tag-editor__container">
                <TagEditorInput
                    intl={intl}
                    disabled={disabled}
                    value={inputValue}
                    onAdd={this.handleTagAdd}
                    onChange={this.handleTagInputChange}
                    placeholder={placeholder}
                    title={title}
                    error={externalError || error}
                />
                {tags.length > 0 && (
                    <div className="onsolve-tag-editor__tags-container">
                        {tags.map((tag, index) => (
                            <TagEditorTag key={index} intl={intl} onDelete={this.handleTagDelete(tag)}>
                                {tag}
                            </TagEditorTag>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

TagEditor.propTypes = {
    /**
     * Specifies an input field is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Message in case of duplication error.
     */
    duplicateItemsErrStr: PropTypes.string,
    /**
     * Specifies the error message.
     */
    error: PropTypes.node,
    /**
     * React-intl shape injected by injectIntl HOC.
     */
    intl: PropTypes.object.isRequired,
    /**
     * Callback function on change event of tag editor.
     * @param {string} [newValue] - changed value of tag editor input.
     * @returns {void}
     */
    onInputChange: PropTypes.func,
    /**
     * Callback function on add event of tag editor.
     * @param {string[]} tags - actual list of tags.
     * @returns {void}
     */
    onTagAdd: PropTypes.func,
    /**
     * Callback function on delete event of tag editor.
     * @param {string[]} tags - actual list of tags.
     * @returns {void}
     */
    onTagDelete: PropTypes.func,
    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder: PropTypes.node,
    /**
     * The list of predefined tags.
     */
    tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Add a label for the component.
     */
    title: PropTypes.node,
    /**
     * Function for validating the tag being added. Should return true if there is no error.
     * @param {string} [newTag] - tag to add.
     * @returns {React.ReactNode}
     */
    validateTag: PropTypes.func
};

TagEditor.displayName = 'TagEditor';

export default injectIntl(TagEditor);
