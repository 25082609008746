import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SingleRowPlaceholder extends Component {
    render() {
        const { className, classes, style, placeholderProps } = this.props;
        const { style: skeletonStyle } = placeholderProps;

        const rootClasses = classNames(
            'onsolve-placeholder',
            'onsolve-placeholder__row',
            'onsolve-placeholder__table-action',
            classes.root,
            className
        );

        return <div className={rootClasses} style={{ ...style, ...skeletonStyle }} />;
    }
}

SingleRowPlaceholder.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    placeholderProps: PropTypes.object,
    style: PropTypes.object
};

SingleRowPlaceholder.defaultProps = {
    classes: {},
    placeholderProps: {}
};

SingleRowPlaceholder.displayName = 'SingleRowPlaceholder';

export default SingleRowPlaceholder;
