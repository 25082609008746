import React from 'react';

import SvgIcon from './SvgIcon';

const TimeAlarm = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                <path d="M8 3.625c2.887 0 5.25 2.362 5.25 5.25 0 1.137-.35 2.188-.963 3.063l1.575 1.574c.35.35.35.875 0 1.226a.945.945 0 01-.612.262.945.945 0 01-.613-.262l-1.575-1.575A5.304 5.304 0 018 14.124a5.304 5.304 0 01-3.063-.963l-1.575 1.575A.794.794 0 012.75 15a.794.794 0 01-.612-.262.846.846 0 010-1.226l1.575-1.575a5.304 5.304 0 01-.963-3.062c0-2.888 2.362-5.25 5.25-5.25zm.875 2.625h-1.75v3.5h3.5V8h-1.75V6.25zM11.063 1A3.921 3.921 0 0115 4.938c0 .612-.175 1.137-.35 1.662-.875-2.625-3.238-4.462-6.037-4.725C9.225 1.35 10.1 1 11.063 1zM4.936 1c.963 0 1.838.35 2.45.875-2.8.263-5.074 2.188-6.037 4.725C1.175 6.075 1 5.55 1 4.938A3.921 3.921 0 014.938 1z" />
            </g>
        </SvgIcon>
    );
};

TimeAlarm.displayName = 'TimeAlarmIcon';

export default TimeAlarm;
