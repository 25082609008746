import { AtomicBlockUtils, EditorState } from 'draft-js';

import { ENTITY_TYPES } from '../constants';

const insertImage = (editorState, src, alt) => {
    const contentState = editorState.getCurrentContent();
    const newContentState = contentState.createEntity(ENTITY_TYPES.IMAGE, 'IMMUTABLE', {
        src,
        alt
    });
    const entityKey = newContentState.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
        currentContent: newContentState
    });

    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};

export default insertImage;
