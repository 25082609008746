const displayVerticalShadow = (element, values) => {
    const { scrollLeft } = values;

    if (element) {
        const opacity = (1 / 20) * Math.min(scrollLeft, 20);

        element.style.opacity = opacity;
    }
};

const displayHorizontalShadow = (element, values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;

    if (element) {
        const bottomScrollTop = scrollHeight - clientHeight;
        const opacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

        element.style.opacity = opacity;
    }
};

export default {
    displayVerticalShadow,
    displayHorizontalShadow
};
