import React from 'react';

import SvgIcon from './SvgIcon';

const MultipleUsersFilledIcon = props => (
    <SvgIcon viewBox="0 0 125 125" {...props}>
        <g stroke="none">
            <path d="M117.845109,73.2922949 L96.3777174,65.8655768 C95.4320642,65.5401257 94.7525893,64.744314 94.611413,63.7968531 L93.5108696,56.4632276 C101.194305,52.3358981 105.955363,44.5987763 105.978261,36.2026648 L105.978261,20.8734222 C105.969674,13.8530578 100.457267,7.94265396 93.1331522,7.10089411 L90.7608696,2.58590463 C89.6234368,0.47798071 87.0307099,-0.513776817 84.6766304,0.258590463 L70.0380435,5.23645687 C65.5051137,6.9365089 61.7677643,10.1340629 59.5108696,14.2431627 C66.5855009,19.8441389 70.6696138,28.1536537 70.6521739,36.9112027 L70.6521739,41.3744741 C71.2905331,48.1237429 73.6201445,54.6289797 77.4456522,60.3446704 C78.7437997,62.1972195 79.1428602,64.4912688 78.541289,66.643076 C77.9397179,68.7948832 76.3955198,70.5969668 74.3016304,71.5907696 C73.4288876,71.9855114 72.5270359,72.3191171 71.6032609,72.5889288 L75.3478261,73.6569074 C82.2595793,75.6045723 86.9897893,81.6611355 86.9565217,88.5206872 L86.9565217,100.850281 C86.9475151,101.733211 86.7783635,102.608031 86.4565217,103.436185 L122.282609,103.436185 C123.783382,103.436185 125,102.278436 125,100.850281 L125,83.0075386 C124.989564,78.6624563 122.133494,74.7843518 117.845109,73.2922949 Z" />
            <path d="M78.8043478,103.436185 L2.7173913,103.436185 C1.21661753,103.436185 -0.000108324797,102.278436 -0.000108324797,100.850281 L-0.000108324797,88.5206872 C-0.0214208925,83.9454093 3.1360334,79.9068574 7.74728261,78.6115007 L25.7826087,73.4629646 C26.7938541,73.1713557 27.535768,72.3483441 27.6847826,71.3528664 L28.125,68.4437237 C16.4809783,70.1271476 9.95108696,67.1016392 9.64673913,66.9620004 C8.94880895,66.6304394 8.43427033,66.0294445 8.23408563,65.3119832 C8.03390093,64.5945219 8.16737791,63.8297931 8.60054348,63.2124386 C13.0355818,56.655427 15.6833153,49.1499003 16.3043478,41.3744741 L16.3043478,36.2026648 C16.3043311,29.7607592 19.1101535,23.6069925 24.0538625,19.2063273 C28.9975715,14.8056622 35.6369042,12.5517809 42.3913043,12.9812412 C55.4235308,14.0151246 65.3939167,24.4676806 65.2173913,36.9112027 L65.2173913,41.3744741 C65.8359682,49.1492134 68.4808822,56.65469 72.9130435,63.2124386 C73.3462091,63.8297931 73.479686,64.5945219 73.2795013,65.3119832 C73.0793166,66.0294445 72.564778,66.6304394 71.8668478,66.9620004 C71.5679348,67.1016392 65.0380435,70.1245617 53.388587,68.4437237 L53.8288043,71.3528664 C53.977819,72.3483441 54.7197328,73.1713557 55.7309783,73.4629646 L73.7744565,78.6115007 C78.3857057,79.9068574 81.54316,83.9454093 81.5218475,88.5206872 L81.5218475,100.850281 C81.5218475,102.278436 80.3051216,103.436185 78.8043478,103.436185 Z" />
        </g>
    </SvgIcon>
);

MultipleUsersFilledIcon.displayName = 'MultipleUsersFilledIcon';

export default MultipleUsersFilledIcon;
