import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import { Badge } from '../Badge';
import { DomUtils } from '../utils';
import FiltersWrapper from './FiltersWrapper';
import FiltersWrapperMobile from './FiltersWrapperMobile';
import FiltersButton from './FiltersButton';

class FiltersPopover extends Component {
    constructor(props) {
        super(props);

        const id = props.id || uuid();

        this.popupId = `filter_popup_${id}`;
        this.targetId = `filter_button_${id}`;
        this.containerId = `filter_container_${id}`;
        this.state = {
            isOpen: false
        };

        this.buttonElement = React.createRef();

        this.handleApply = this.handleApply.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleApply(event) {
        const { closeOnApply, onApply } = this.props;

        if (closeOnApply) {
            this.handleToggle(event);
        }
        onApply();
    }

    handleReset(event) {
        this.handleToggle(event);
        this.props.onReset();
    }

    handleToggle(event) {
        if (this.state.isOpen && event && DomUtils.isInDOMSubtree(event.target, this.buttonElement.current)) {
            // don't fire handleToggle twice, due to onClose popover handler
            event.stopPropagation();
        }

        this.setState(
            prevState => ({ isOpen: !prevState.isOpen }),
            () => {
                const { isOpen } = this.state;
                const { onOpened, onClose } = this.props;

                if (!isOpen) {
                    DomUtils.setFocus(this.buttonElement.current);

                    if (onClose) {
                        onClose();
                    }
                    return;
                }

                if (onOpened) {
                    onOpened();
                }
            }
        );
    }

    render() {
        const {
            children,
            filterCount,
            isApplyDisabled,
            showDot,
            modifiers,
            isMobile,
            variant,
            disabled,
            i18nBlur,
            classes
        } = this.props;
        const { isOpen } = this.state;
        const placement = DomUtils.isRtl() ? 'bottom-start' : 'bottom-end';

        const Wrapper = isMobile ? FiltersWrapperMobile : FiltersWrapper;

        return (
            <div className="onsolve-filters" id={this.containerId}>
                <Badge id={this.targetId} variant="dot" color="primary" invisible={!showDot}>
                    <FiltersButton
                        innerRef={this.buttonElement}
                        variant={variant}
                        disabled={disabled}
                        isMobile={isMobile}
                        i18nBlur={i18nBlur}
                        className={classes.button}
                        onClick={this.handleToggle}
                    />
                </Badge>
                <Wrapper
                    id={this.popupId}
                    container={this.containerId}
                    target={this.targetId}
                    modifiers={modifiers}
                    placement={placement}
                    filterCount={filterCount}
                    isApplyDisabled={isApplyDisabled}
                    isOpen={isOpen}
                    onClose={this.handleToggle}
                    onApply={this.handleApply}
                    onReset={this.handleReset}
                >
                    {children}
                </Wrapper>
            </div>
        );
    }
}

FiltersPopover.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    closeOnApply: PropTypes.bool,
    disabled: PropTypes.bool,
    filterCount: PropTypes.number,
    i18nBlur: PropTypes.bool,
    id: PropTypes.string,
    isApplyDisabled: PropTypes.bool,
    isMobile: PropTypes.bool,
    modifiers: PropTypes.object,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onOpened: PropTypes.func,
    onReset: PropTypes.func.isRequired,
    showDot: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'compact'])
};

FiltersPopover.defaultProps = {
    filterCount: null,
    classes: {},
    closeOnApply: true,
    variant: 'standard',
    modifiers: {
        preventOverflow: {
            enabled: false
        },
        hide: {
            enabled: false
        },
        flip: {
            enabled: false
        }
    }
};

FiltersPopover.displayName = 'FiltersPopover';

export default FiltersPopover;
