import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MenuItemIcon = React.forwardRef(({ children, className }, ref) => {
    const rootClassNames = classNames('onsolve-menu__icon', className);

    return (
        <div ref={ref} className={rootClassNames}>
            {children}
        </div>
    );
});

MenuItemIcon.propTypes = {
    /**
    Specifies menu icon content
    */
    children: PropTypes.node,
    /**
    Override or extend the styles applied to component.
    */
    className: PropTypes.string
};

MenuItemIcon.displayName = 'MenuItemIcon';

export default MenuItemIcon;
