import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressBar = ({ className, minValue, maxValue, bars, barShape, showPercents, lineHeight }) => {
    const calculatePercent = useCallback(
        ({ value }) => (maxValue !== minValue ? 100 * ((value - minValue) / (maxValue - minValue)) : 0),
        [minValue, maxValue]
    );
    const wrapperClassName = classNames('onsolve-progress-bar', className, {
        'onsolve-progress-bar--thin': lineHeight === 'thin'
    });

    return (
        <div className={wrapperClassName}>
            <div className="onsolve-progress-bar__container progress">
                {bars.map(({ value, className }, index) => {
                    const percent = calculatePercent({ value });

                    return (
                        <div
                            key={index}
                            className={classNames('progress-bar onsolve-progress-bar__bar', className, {
                                'onsolve-progress-bar__bar--rounded': barShape === 'rounded'
                            })}
                            role="progressbar"
                            aria-valuenow={value}
                            aria-valuemin={minValue}
                            aria-valuemax={maxValue}
                            style={{
                                width: `${percent}%`
                            }}
                        >
                            {showPercents && bars.length === 1 && `${percent.toFixed(2)}%`}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    barShape: PropTypes.oneOf(['squared', 'rounded']),
    bars: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number.isRequired, className: PropTypes.string }))
        .isRequired,
    className: PropTypes.string,
    lineHeight: PropTypes.oneOf(['thin', 'normal']),
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    showPercents: PropTypes.bool
};

ProgressBar.defaultProps = {
    maxValue: 100,
    minValue: 0,
    barShape: 'squared',
    showPercents: true,
    lineHeight: 'normal',
    bars: []
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
