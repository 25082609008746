import { EditorState, Modifier } from 'draft-js';
import { CUSTOM_STYLE_PREFIX_COLOR } from '../constants';

export const setFontColor = (editorState, fontColor) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;

    let nextEditorState = currentContent;

    for (const inlineStyle of editorState.getCurrentInlineStyle()) {
        if (typeof inlineStyle === 'string' && inlineStyle.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
            nextEditorState = Modifier.removeInlineStyle(nextEditorState, selection, inlineStyle);
        }
    }

    if (currentContentLength > 0) {
        const nextContentState = Modifier.applyInlineStyle(
            nextEditorState,
            selection,
            CUSTOM_STYLE_PREFIX_COLOR + fontColor
        );

        return EditorState.push(editorState, nextContentState, 'change-inline-style');
    }
};
