import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';

import { TextField } from '../../../TextField';
import { Typography } from '../../../Typography';
import { Switcher } from '../../../Selections';
import { LINK_TYPES } from '../../constants';
import { componentsTranslations } from '../../../translations';
import { LinkTypeDropdownField } from './Fields';
import { LinkModal, LinkModalFormSchema } from '.';

const LinkModalForm = ({ isOpen, linkInitValues, formRef, onSubmit, onClose, isSelectedDisplayText }) => {
    const intl = useIntl();

    const handleInputChange = useCallback(
        (form, field) => e => {
            field.onChange(e);
            form.setErrors({ ...form.errors, [field.name]: null });
        },
        []
    );

    const handleSwitcherChange = useCallback(
        (setFieldValue, fieldName) => (_, value) => setFieldValue(fieldName, value.checked),
        []
    );

    const handleKeydown = useCallback(e => e.stopPropagation(), []);

    const getErrors = useCallback(
        (errors, fieldName) => {
            const currentError = errors[fieldName];

            return {
                error: !!(errors.hasOwnProperty(fieldName) && currentError),
                errorText: currentError && intl.formatMessage(currentError, currentError.values)
            };
        },
        [intl]
    );

    return (
        <Formik
            enableReinitialize={true}
            onSubmit={onSubmit}
            initialValues={linkInitValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={LinkModalFormSchema}
            resetForm={linkInitValues}
            ref={formRef}
        >
            {({ handleSubmit }) => (
                <LinkModal isOpen={isOpen} onCancel={onClose} onSubmit={handleSubmit}>
                    <div className="onsolve-wysiwyg-editor__linkform" onKeyDown={handleKeydown}>
                        <Form>
                            <Typography variant="p14">
                                <FormattedMessage
                                    {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_description}
                                />
                            </Typography>

                            <div className="onsolve-wysiwyg-editor__linkform--field-item">
                                <Field name="linkType" component={LinkTypeDropdownField} />
                            </div>

                            <div className="onsolve-wysiwyg-editor__linkform--field-item">
                                <Field
                                    name="displayText"
                                    render={({ field, form }) => (
                                        <TextField
                                            label={
                                                <FormattedMessage
                                                    {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text}
                                                />
                                            }
                                            placeholder={
                                                <FormattedMessage
                                                    {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text_placeholder}
                                                />
                                            }
                                            maxLength={120}
                                            readOnly={isSelectedDisplayText}
                                            {...field}
                                            {...getErrors(form.errors, field.name)}
                                            onChange={handleInputChange(form, field)}
                                        />
                                    )}
                                />
                            </div>

                            <div className="onsolve-wysiwyg-editor__linkform--field-item">
                                <Field
                                    name="targetUrl"
                                    render={({ field, form }) => (
                                        <>
                                            <TextField
                                                label={
                                                    form.values.linkType === LINK_TYPES.EMAIL_ADDRESS ? (
                                                        <FormattedMessage
                                                            {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_email_address}
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_target_url}
                                                        />
                                                    )
                                                }
                                                placeholder={
                                                    form.values.linkType === LINK_TYPES.EMAIL_ADDRESS ? (
                                                        <FormattedMessage
                                                            {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_email_address_placeholder}
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_target_url_placeholder}
                                                        />
                                                    )
                                                }
                                                {...field}
                                                {...getErrors(form.errors, field.name)}
                                                onChange={handleInputChange(form, field)}
                                            />
                                        </>
                                    )}
                                />
                            </div>

                            <div className="onsolve-wysiwyg-editor__linkform--field-item">
                                <Field
                                    name="openLinkInSeparateWindow"
                                    render={({ field, form: { setFieldValue } }) => (
                                        <Switcher
                                            label={
                                                <FormattedMessage
                                                    {...componentsTranslations.ng_wysiwyg_editor_link_modal_add_open_in_separate_windows}
                                                />
                                            }
                                            checked={field.value}
                                            onChange={handleSwitcherChange(setFieldValue, field.name)}
                                        />
                                    )}
                                />
                            </div>
                        </Form>
                    </div>
                </LinkModal>
            )}
        </Formik>
    );
};

LinkModalForm.propTypes = {
    formRef: PropTypes.shape({ current: PropTypes.instanceOf(Formik) }),
    isOpen: PropTypes.bool.isRequired,
    isSelectedDisplayText: PropTypes.bool,
    linkInitValues: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

LinkModalForm.displayName = 'LinkModalForm';

export default LinkModalForm;
