import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ExclamationIcon } from '../Icons';

class InputFieldExclamation extends Component {
    render() {
        const { error, className } = this.props;

        if (!error) {
            return null;
        }

        const classes = classNames('onsolve-input-field__error', className);

        return (
            <div className={classes}>
                <ExclamationIcon color="danger" />
            </div>
        );
    }
}

InputFieldExclamation.propTypes = {
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool
};

InputFieldExclamation.defaultProps = {
    error: false
};

InputFieldExclamation.displayName = 'InputFieldExclamation';

export default InputFieldExclamation;
