class ColumnResize {
    static MIN_COLUMN_WIDTH = 160;

    constructor() {
        this.headerColumnGroup = {};
        this.bodyColumnGroup = {};
        this.columns = [];

        this.onResize = f => f;
        this.onRelease = f => f;
    }

    setupColumnWidth(column, value) {
        const index = this.columns.indexOf(column);
        const headerCell = this.headerColumnGroup.children[index];
        const bodyCell = this.bodyColumnGroup.children[index];

        headerCell.width = `${value}px`;
        bodyCell.width = headerCell.width;
    }

    normalizeColumnWidth(width) {
        let count = 0;
        const { children: headerCols } = this.headerColumnGroup;
        const { children: bodyCols } = this.bodyColumnGroup;

        headerCols.forEach(column => {
            if (column.width) {
                width -= parseInt(column.width);
            } else {
                count++;
            }
        });

        if (count) {
            const delta = Math.floor(width / count);

            headerCols.forEach((column, index) => {
                if (!column.width) {
                    column.width = `${delta}px`;
                    bodyCols[index].width = column.width;
                }
            });
        }
    }

    resize(e, data) {
        const { column, element, move } = data;

        if (move) {
            const { left } = element.getBoundingClientRect();

            const thElement = element.parentElement;
            const totalWidth = thElement.parentElement.clientWidth;
            const oldWidth = thElement.clientWidth;

            let newWidth = oldWidth + (e.clientX - left - element.offsetWidth / 2);

            newWidth = Math.max(newWidth, ColumnResize.MIN_COLUMN_WIDTH);

            this.setupColumnWidth(column, newWidth);
            this.normalizeColumnWidth(totalWidth);
        }

        if (this.onResize) {
            this.onResize(e, data);
        }
    }
}

export default ColumnResize;
