import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowRightIcon, ArrowDownIcon } from '../../Icons';
import { Link } from '../../Link';
import { OnsolvePropTypes } from '../../utils';

class TableExpansionCell extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { onExpandChange, expanded } = this.props;

        onExpandChange(e, !expanded);
    }

    render() {
        const { title, align, expanded } = this.props;
        const cellClasses = classNames(
            'onsolve-table__body-cell--expansion',
            { expanded },
            {
                [`text-${align}`]: align !== undefined
            }
        );

        const contentClasses = classNames({
            hidden: expanded === undefined
        });

        return (
            <td className={cellClasses}>
                <Link className={contentClasses} onClick={this.handleClick}>
                    {title && <span className="onsolve-table__body-expansion-cell-title">{title}</span>}
                    {expanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
                </Link>
            </td>
        );
    }
}

TableExpansionCell.propTypes = {
    align: OnsolvePropTypes.align,
    expanded: PropTypes.bool,
    onExpandChange: PropTypes.func,
    title: PropTypes.string
};

TableExpansionCell.defaultProps = {
    title: '',
    onExpandChange: f => f
};

TableExpansionCell.displayName = 'TableExpansionCell';

export default TableExpansionCell;
