import classNames from 'classnames';

import { ALIGN_STATE_KEYS, INDENT_STATE_KEYS, NODE_TYPES } from '../../constants';

const createParagraphsPlugin = () => {
    const blockStyleFn = block => {
        const blockAlignment = block.getData() && block.getData().get(ALIGN_STATE_KEYS.STYLE_NAME);
        const blockIndent = block.getData() && block.getData().get(INDENT_STATE_KEYS.STYLE_NAME);

        switch (block.getType()) {
            case NODE_TYPES.paragraph.style:
                return classNames('onsolve-paragraph12', {
                    [`onsolve-wysiwyg-editor__${blockAlignment}`]: !!blockAlignment,
                    [`onsolve-wysiwyg-editor__${blockIndent}`]: !!blockIndent
                });

            default:
                return classNames({ [`onsolve-wysiwyg-editor__${blockAlignment}`]: !!blockAlignment });
        }
    };

    return {
        blockStyleFn
    };
};

export default createParagraphsPlugin;
