import React from 'react';

import SvgIcon from './SvgIcon';

const RadioBlank = props => {
    return (
        <SvgIcon {...props}>
            <circle cx="8" cy="8" r="7.5" fill="none" strokeLinecap="round" strokeLinejoin="round" data-cap="butt" />
        </SvgIcon>
    );
};

RadioBlank.displayName = 'RadioBlankIcon';

export default RadioBlank;
