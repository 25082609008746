import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import { ScrollbarsContext } from './ScrollbarsContext';

const withScrollbar = WrappedComponent => {
    const C = props => (
        <ScrollbarsContext.Consumer>
            {scrollbar => {
                return <WrappedComponent scrollbar={scrollbar} {...props} />;
            }}
        </ScrollbarsContext.Consumer>
    );

    C.displayName = `withScrollbar(${WrappedComponent.displayName || WrappedComponent.name})`;
    C.WrappedComponent = WrappedComponent;
    return hoistStatics(C, WrappedComponent);
};

withScrollbar.displayName = 'withScrollbar';

export default withScrollbar;
