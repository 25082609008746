import React from 'react';
import SvgIcon from './SvgIcon';

const Magnifier = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M10.7,9.7c0.2,0,0.5,0.1,0.6,0.3l4.3,4.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L10,11.4 c-0.2-0.2-0.3-0.4-0.3-0.6C8.7,11.5,7.4,12,6,12c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6C12,7.4,11.5,8.7,10.7,9.7z M6,10.7 c2.6,0,4.7-2.1,4.7-4.7S8.6,1.3,6,1.3S1.3,3.4,1.3,6S3.4,10.7,6,10.7z"
        />
    </SvgIcon>
);

Magnifier.displayName = 'MagnifierIcon';

export default Magnifier;
