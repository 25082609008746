import React from 'react';

import { NODE_TYPES } from '../../../constants';
import { insertImage } from '../../../utils';

import { ImageUpload } from '../ImageUpload';

const addImageTool = (onContentImageUpload, contentImageConstraints, disabled) => ({
    focusable: true,
    focusableRef: value => value,

    // eslint-disable-next-line react/display-name
    render: (editorState, intl, onChange, ref) => {
        const handleToggle = (blockType, isActive, imageUrl, imageDisplayName) => {
            if (imageUrl && imageDisplayName) {
                onChange(insertImage(editorState, imageUrl, imageDisplayName));
            } else {
                onChange(editorState);
            }
        };

        return (
            <ImageUpload
                key="insertImage"
                editorState={editorState}
                onToggle={handleToggle}
                intl={intl}
                onContentImageUpload={onContentImageUpload}
                contentImageConstraints={contentImageConstraints}
                ref={ref}
                disabled={disabled}
                {...NODE_TYPES.image}
            />
        );
    }
});

export default addImageTool;
