import React from 'react';
import PropTypes from 'prop-types';

import eachDay from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import { fp as _ } from '../../utils';
import Weekday from './Weekday';
import Week from './Week';

const renderWeekDays = props => {
    const { month } = props;
    const startDate = startOfWeek(month);
    const endDate = addDays(startDate, 6);
    const dateRange = eachDay({ start: startDate, end: endDate });

    return _.map(dateRange, (day, index) => {
        return <Weekday key={index} day={day} />;
    });
};

const renderWeeks = props => {
    const { month, ...other } = props;
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateRange = eachDay({ start: startDate, end: endDate }, { step: 7 });

    return _.map(dateRange, (fromDay, index) => {
        const toDay = addDays(fromDay, 6);

        return <Week key={index} month={month} fromDay={fromDay} toDay={toDay} {...other} />;
    });
};

const Month = props => (
    <div className="onsolve-date-picker__month">
        <div className="onsolve-date-picker__weekdays">{renderWeekDays(props)}</div>
        <div className="onsolve-date-picker__weeks">{renderWeeks(props)}</div>
    </div>
);

Month.propTypes = {
    month: PropTypes.instanceOf(Date).isRequired
};

Month.displayName = 'Month';

export default Month;
