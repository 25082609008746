import React from 'react';
import SvgIcon from './SvgIcon';

const FormatUnderlined = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M6 12c2.76 0 5-2.24 5-5V0H8.5v7a2.5 2.5 0 1 1-5 0V0H1v7c0 2.76 2.24 5 5 5zm-6 2v2h12v-2H0z"
                fillRule="nonzero"
                stroke="none"
            />
        </SvgIcon>
    );
};

FormatUnderlined.displayName = 'FormatUnderlinedIcon';

export default FormatUnderlined;
