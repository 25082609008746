import React from 'react';
import PropTypes from 'prop-types';
import InvisibleInput from '../InvisibleInput';

const MonthInput = ({ value, onChange, ...other }) => {
    const handleChange = e => {
        const { value } = e.target;

        if (value.length > 0 && value !== '0') {
            const result = /(^0?[1-9]$)|(^1[0-2]$)/.test(value);

            if (!result) {
                e.preventDefault();
                return;
            }
        }

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <InvisibleInput
            {...other}
            name="month"
            className="onsolve-date-input__month"
            value={value}
            maxLength={2}
            onChange={handleChange}
        />
    );
};

MonthInput.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

MonthInput.defaultProps = {
    placeholder: 'MM'
};

MonthInput.displayName = 'MonthInput';

export default MonthInput;
