import React, { useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtils, DomUtils } from '../../utils';

const WIDTH_OFFSET = 0.5;

const InvisibleInput = ({
    type,
    className,
    placeholderClassName,
    value,
    inputRef,
    placeholder,
    maxLength,
    ...other
}) => {
    const placeholderRef = useRef(null);
    const rootClasses = useMemo(() => classNames('onsolve-invisible-input__text', className), [className]);
    const placeholderClasses = useMemo(
        () =>
            classNames('onsolve-invisible-input__placeholder', placeholderClassName, {
                'onsolve-invisible-input__placeholder--hidden': !!value
            }),
        [placeholderClassName, value]
    );

    const handleInputRef = useCallback(
        ref => {
            if (ref) {
                //The main goal is to calculate the width of input element based on placeholder or max length
                //to fit the entered value.
                const digitalValue = '0'.repeat(maxLength);
                const maxWidth = Math.max(
                    DomUtils.measureText(ref, placeholder).width,
                    DomUtils.measureText(ref, digitalValue).width
                );

                ref.style.width = `${Math.ceil(maxWidth + WIDTH_OFFSET)}px`;
            }
        },
        [placeholder, maxLength]
    );

    return (
        <div className="onsolve-invisible-input">
            <span className={placeholderClasses} ref={placeholderRef} aria-hidden={!!value}>
                {placeholder}
            </span>
            <input
                className={rootClasses}
                type={type}
                autoComplete="off"
                value={value}
                maxLength={maxLength}
                ref={ReactUtils.setMultipleRefs(handleInputRef, inputRef)}
                {...other}
            />
        </div>
    );
};

InvisibleInput.propTypes = {
    className: PropTypes.string,
    inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    placeholderClassName: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number']),
    value: PropTypes.string
};

InvisibleInput.defaultProps = {
    type: 'text'
};

InvisibleInput.displayName = 'InvisibleInput';

export default InvisibleInput;
