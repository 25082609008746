import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class InputFieldMessage extends PureComponent {
    render() {
        const { maxLength, currentLength, error, errorText, helpText, hideMaxLength, className } = this.props;
        const message = error && errorText ? errorText : helpText;
        const rootClasses = classNames('onsolve-input-field__message', className);
        const messageClasses = classNames('onsolve-input-field__message-text', {
            'onsolve-input-field__message--error': error
        });

        if (maxLength === undefined && message === undefined) {
            return null;
        }

        return (
            <div className={rootClasses}>
                <div key="message" className={messageClasses}>
                    {message}
                </div>
                {!hideMaxLength && (
                    <div key="counter" className="onsolve-input-field__message-counter">
                        {maxLength ? `${currentLength} / ${maxLength}` : null}
                    </div>
                )}
            </div>
        );
    }
}

InputFieldMessage.propTypes = {
    /**
     The set of selectors for root element
     */
    className: PropTypes.string,
    /**
     The current length of input message.
     */
    currentLength: PropTypes.number,
    /**
     Specifies if the error should be showed.
     */
    error: PropTypes.bool,
    /**
     Specifies the error message.
     */
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a help text that shows below the input.
     */
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies a hidden status of max length counter.
     */
    hideMaxLength: PropTypes.bool,
    /**
     Specifies a max length value.
     */
    maxLength: PropTypes.number
};

InputFieldMessage.displayName = 'InputFieldMessage';

export default InputFieldMessage;
