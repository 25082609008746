import React from 'react';
import PropTypes from 'prop-types';

import { BlurLoaderContextProvider } from './BlurLoaderContext';

const BlurLoaderProvider = ({ children, ...props }) => {
    return <BlurLoaderContextProvider value={props}>{children}</BlurLoaderContextProvider>;
};

BlurLoaderProvider.propTypes = {
    /**
    Used to provider BlurLoader functionality to all children in the BlurLoaderProvider.
    */
    children: PropTypes.node
};

BlurLoaderProvider.displayName = 'BlurLoaderProvider';

export default BlurLoaderProvider;
