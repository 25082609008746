import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isDateValid from 'date-fns/isValid';
import parse from 'date-fns/parse';

import { keyCodes } from '../../../common/constants';

import { fp as _, DomUtils } from '../../utils';
import { InputFieldDivider } from '../../InputBase';
import { componentsTranslations } from '../../translations';
import { MonthInput, DayInput, YearInput } from './DateInputs';

class DateInputText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { value = '' } = props;

        if (value !== state.value) {
            return { value };
        }

        return null;
    }

    handleInputChange(e) {
        const { name: targetName, value: targetValue } = e.target;
        const { name, onChange } = this.props;
        const { value } = this.state;

        const [month, day, year] = value.split('/');
        const dateObj = { month, day, year, [targetName]: targetValue };
        const updatedValue = _.values(dateObj).join('/');
        let isValid = true;

        if (updatedValue) {
            const date = parse(updatedValue, 'MM/dd/yyyy', new Date());

            isValid = isDateValid(date);
        }

        if (onChange) {
            const result = {
                name,
                value: updatedValue,
                isValid
            };

            onChange(e, result);
        }

        this.setState({ value: updatedValue });
    }

    handleChange(e) {
        e.persist();

        const { value, maxLength } = e.target;

        if (value.length >= maxLength) {
            const nextInput = DomUtils.findNextInput(e.target);

            DomUtils.setFocus(nextInput);
        }
        this.handleInputChange(e);
    }

    handleKeyDown(e) {
        const { onKeyDown } = this.props;

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    handleKeyUp = e => {
        const { target } = e;
        const { DELETE, BACKSPACE } = keyCodes;
        const charCode = DomUtils.getCharCode(e);

        if ([DELETE, BACKSPACE].indexOf(charCode) !== -1) {
            if (target.value.toString().length === 0) {
                const previousInput = DomUtils.findPreviousInput(target);

                DomUtils.setFocus(previousInput);
            }
        }

        const { onKeyUp } = this.props;

        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    render() {
        const {
            divider,
            onFocus,
            onBlur,
            onMouseDown,
            monthRef,
            dayRef,
            yearRef,
            disabled,
            intl,
            tabIndex
        } = this.props;
        const { value } = this.state;
        const [month = '', day = '', year = ''] = value.split('/');

        const controlProps = {
            onKeyUp: this.handleKeyUp,
            onChange: this.handleChange,
            onFocus: onFocus,
            onMouseDown: onMouseDown,
            onKeyDown: this.handleKeyDown,
            onBlur: onBlur,
            tabIndex
        };

        return (
            <Fragment>
                <MonthInput
                    key="month"
                    inputRef={monthRef}
                    value={month}
                    disabled={disabled}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_month)}
                    {...controlProps}
                />
                <InputFieldDivider symbol={divider} />
                <DayInput
                    key="day"
                    inputRef={dayRef}
                    value={day}
                    disabled={disabled}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_day)}
                    {...controlProps}
                />
                <InputFieldDivider symbol={divider} />
                <YearInput
                    key="year"
                    inputRef={yearRef}
                    value={year}
                    disabled={disabled}
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_year)}
                    {...controlProps}
                />
            </Fragment>
        );
    }
}

DateInputText.propTypes = {
    className: PropTypes.string,
    dayRef: PropTypes.object,
    disabled: PropTypes.bool,
    divider: PropTypes.node,
    intl: PropTypes.object,
    monthRef: PropTypes.object,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    yearRef: PropTypes.object
};

DateInputText.defaultProps = {
    divider: '/',
    disabled: false
};

DateInputText.displayName = 'DateInputText';

export default injectIntl(DateInputText);
