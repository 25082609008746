import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortableHandle } from 'react-sortable-hoc';

import { Focusable } from '../../../components/Focusable';
import { EllipsisVerticalIcon } from '../../Icons';
import { OnsolvePropTypes } from '../../utils';

const SortableHandleIcon = sortableHandle(({ color, size }) => (
    <Focusable
        render={({ classes }) => <EllipsisVerticalIcon className={classes} tabIndex="-1" color={color} size={size} />}
    />
));

const TableDraggableCell = props => {
    const {
        align,
        dataItem,
        drag: { iconColor, iconSize }
    } = props;
    const cellClasses = classNames('onsolve-table__body-cell--draggable', {
        [`text-${align}`]: align !== undefined
    });
    const handleClick = e => e.stopPropagation();

    return (
        <td className={cellClasses} onClick={handleClick} tabIndex="-1">
            {!dataItem.hideDraggable && <SortableHandleIcon color={iconColor} size={iconSize} />}
        </td>
    );
};

TableDraggableCell.propTypes = {
    align: OnsolvePropTypes.align,
    dataItem: PropTypes.object.isRequired,
    drag: OnsolvePropTypes.drag,
    tabIndex: PropTypes.number
};

TableDraggableCell.defaultProps = {
    drag: {
        iconSize: 'md'
    },
    tabIndex: 0
};

TableDraggableCell.displayName = 'TableDraggableCell';

export default TableDraggableCell;
