import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IconButton } from '../../Button';
import { ArrowDownIcon } from '../../Icons';
import { Menu } from '../../Menu';
import { DomUtils } from '../../utils';
import { componentsTranslations } from '../../translations';

class HeaderColumnMenu extends Component {
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;

        if (prevProps.isOpen !== isOpen && !isOpen) {
            DomUtils.setFocus(this.buttonRef);
        }
    }

    render() {
        const { anchorEl, children, isOpen, disabled, intl, position, onToggleClick } = this.props;
        const toggleColor = isOpen ? 'primary' : 'secondary';
        const ariaLabel = intl.formatMessage(componentsTranslations.ng_components_select_rows);
        let menuPosition = position;

        if (DomUtils.isRtl()) {
            menuPosition = position === 'left' ? 'right' : 'left';
        }

        return (
            <span className="onsolve-table__column-menu">
                <IconButton
                    ref={this.buttonRef}
                    size="xs"
                    disabled={disabled}
                    onClick={onToggleClick}
                    aria-label={ariaLabel}
                >
                    <ArrowDownIcon color={toggleColor} disabled={disabled} />
                </IconButton>
                <Menu anchorEl={anchorEl} open={isOpen} position={menuPosition} onClose={onToggleClick}>
                    {children}
                </Menu>
            </span>
        );
    }
}

HeaderColumnMenu.propTypes = {
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    'aria-label': PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    intl: PropTypes.object,
    isOpen: PropTypes.bool,
    onToggleClick: PropTypes.func,
    position: PropTypes.oneOf(['left', 'right'])
};

HeaderColumnMenu.defaultProps = {
    position: 'left'
};

HeaderColumnMenu.displayName = 'HeaderColumnMenu';

export default injectIntl(HeaderColumnMenu);
