import React from 'react';

import SvgIcon from './SvgIcon';

const FilterOrganization = props => {
    return (
        <SvgIcon {...props}>
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g transform="translate(-316.000000, -443.000000)" stroke="#565858">
                    <g transform="translate(316.000000, 443.000000)">
                        <rect x="6.5" y="3.5" width="9" height="4" />
                        <rect x="6.5" y="11.5" width="9" height="4" />
                        <polyline points="0.5 0.5 0.5 13.5 3.5 13.5" />
                        <path d="M0.5,5.5 L3.5,5.5" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

FilterOrganization.displayName = 'FilterOrganizationIcon';

export default FilterOrganization;
