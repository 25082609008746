import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtils } from '../utils';

class InputFieldToggle extends Component {
    constructor(props) {
        super(props);

        this.state = { active: false };

        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
    }

    handleClick(e) {
        const { disabled, onClick } = this.props;

        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(e);
        }
    }

    handleHover(active = false) {
        const { disabled } = this.props;

        this.setState({ active: disabled ? false : active });
    }

    render() {
        const { className, disabled, icon, id, innerRef, hoverColor, defaultColor } = this.props;
        const { active } = this.state;

        const rootClasses = classNames('onsolve-input-field__toggle', className, {
            'onsolve-input-field__toggle--disabled': disabled
        });
        const iconClasses = `onsolve-svg-icon--${active ? hoverColor : defaultColor}`;
        const IconComponent = icon;

        return (
            <span
                id={id}
                ref={ref => ReactUtils.setRef(innerRef, ref)}
                className={rootClasses}
                onClick={this.handleClick}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover()}
            >
                <IconComponent className={iconClasses} />
            </span>
        );
    }
}

InputFieldToggle.propTypes = {
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies a default color.
     */
    defaultColor: PropTypes.string,
    /**
     Specifies an input field is disabled.
     */
    disabled: PropTypes.bool,
    /**
     Specifies a hover color.
     */
    hoverColor: PropTypes.string,
    /**
     Specifies an icon.
     */
    icon: PropTypes.func.isRequired,
    /**
     Specifies an id attribute.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     Specifies a ref for a tag.
     */
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     Callback function on toggle click.
     */
    onClick: PropTypes.func
};

InputFieldToggle.defaultProps = {
    hoverColor: 'primary',
    defaultColor: 'secondary'
};

InputFieldToggle.displayName = 'InputFieldToggle';

export default InputFieldToggle;
