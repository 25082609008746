import React from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format';

const Weekday = ({ day, weekDayFormat }) => (
    <span className="onsolve-date-picker__weekday">{format(day, weekDayFormat)}</span>
);

Weekday.propTypes = {
    day: PropTypes.instanceOf(Date).isRequired,
    weekDayFormat: PropTypes.string
};

Weekday.defaultProps = {
    weekDayFormat: 'EEEEE'
};

Weekday.displayName = 'Weekday';

export default Weekday;
