import React from 'react';

import SvgIcon from './SvgIcon';

const Rss = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1">
            <circle cx="2" cy="13" r="2" fillRule="nonzero" />
            <path d="M0 0v2.728c6.777 0 12.272 5.495 12.272 12.272H15C15 6.72 8.28 0 0 0zm0 5.456v2.728A6.821 6.821 0 016.816 15h2.728A9.54 9.54 0 000 5.456z" />
        </g>
    </SvgIcon>
);

Rss.displayName = 'RssIcon';

export default Rss;
