import React from 'react';
import SvgIcon from './SvgIcon';

const Reporting = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M15,1c0.6,0,1,0.4,1,1v13c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1h3v2H2v11h12V3h-2V1H15z M5,0h6 v4H5V0z M4,6h8v2H4V6z M4,10h8v2H4V10z"
        />
    </SvgIcon>
);

Reporting.displayName = 'ReportingIcon';

export default Reporting;
