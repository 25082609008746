import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Page = ({ className, children }) => {
    const rootClasses = classNames('onsolve-page', className);

    return <div className={rootClasses}>{children}</div>;
};

Page.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

Page.displayName = 'Page';

export default Page;
