import React from 'react';
import SvgIcon from './SvgIcon';
const AssignMe = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M9.333 2.667h-8v1.333h8v-1.333zM9.333 0h-8v1.333h8v-1.333zM12 5.333v-2.667h-1.333v2.667h-2.667v1.333h2.667v2.667h1.333v-2.667h2.667v-1.333h-2.667zM1.333 6.667h5.333v-1.333h-5.333v1.333z"
            />
        </SvgIcon>
    );
};

AssignMe.displayName = 'AssignMeIcon';

export default AssignMe;
