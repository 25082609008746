import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { DomUtils } from '../../utils';
import { InputFieldDivider } from '../../InputBase';
import { componentsTranslations } from '../../translations';
import { TimeUnit } from '../core/TimeInputs';

class TimeInputText extends Component {
    handleBlur = e => {
        const { onBlur } = this.props;
        const { value, name } = e.target;

        const addZero = name !== 'minutes' ? value[0] !== '0' : true;

        if (value.length === 1 && addZero) {
            const newValue = '0' + value;

            this.triggerChange(e, newValue);
        }

        if (onBlur) {
            onBlur(e);
        }
    };

    handleChange = e => {
        const { value } = e.target;

        this.triggerChange(e, value);
    };

    handleKeyUp = e => {
        const { key, target } = e;
        const { onKeyUp } = this.props;

        if (key === 'Delete' || key === 'Backspace') {
            if (target.value.toString().length === 0) {
                const previousInput = DomUtils.findPreviousInput(e.target);

                DomUtils.setFocus(previousInput);
            }
        }

        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    triggerChange = (e, value) => {
        const { onChange } = this.props;
        const { maxLength } = e.target;

        if (value.length >= maxLength) {
            const nextInput = DomUtils.findNextInput(e.target);

            DomUtils.setFocus(nextInput);
        }

        if (onChange) {
            onChange(e, value);
        }
    };

    render() {
        const { hours, minutes, divider, placeholder, disabled, intl, ...other } = this.props;

        const { hours: hourPlaceholder, minutes: minutesPlaceholder } = placeholder;

        const inputProps = {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onKeyUp: this.handleKeyUp
        };

        return (
            <>
                <TimeUnit
                    {...other}
                    name="hours"
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_hours)}
                    value={hours}
                    pattern="(0[1-9]|1[0-2])"
                    placeholder={hourPlaceholder}
                    disabled={disabled}
                    {...inputProps}
                />
                <InputFieldDivider symbol={divider} />
                <TimeUnit
                    {...other}
                    name="minutes"
                    aria-label={intl.formatMessage(componentsTranslations.ng_components_minutes)}
                    value={minutes}
                    pattern="([0-5][0-9])"
                    placeholder={minutesPlaceholder}
                    disabled={disabled}
                    {...inputProps}
                />
            </>
        );
    }
}

TimeInputText.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    divider: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hours: PropTypes.string,
    intl: PropTypes.object,
    minutes: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.shape({ hours: PropTypes.string, minutes: PropTypes.string }),
    value: PropTypes.string
};

TimeInputText.defaultProps = {
    divider: ':',
    disabled: false
};

TimeInputText.displayName = 'TimeInputText';

export default injectIntl(TimeInputText);
