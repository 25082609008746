import React from 'react';
import SvgIcon from './SvgIcon';

const Search = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
            d="M6.688 11.438a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5zm0 1a5.75 5.75 0 1 1 0-11.5 5.75 5.75 0 0 1 0 11.5zm7.52 2.478l-2.624-2.625c-.471-.471.236-1.178.707-.707l2.625 2.625c.471.471-.236 1.178-.707.707z"
        />
    </SvgIcon>
);

Search.displayName = 'SearchIcon';

export default Search;
