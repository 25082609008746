import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StepperBody extends Component {
    handleStepChange(e, index) {
        const { steps, onStepChange } = this.props;

        if (index >= 0 && index <= steps.length - 1) {
            if (!steps[index].disabled && onStepChange) {
                onStepChange(e, index);
            }
        }
    }

    render() {
        const { steps, activeStep, ...other } = this.props;

        return (
            <div className="onsolve-stepper__body" role="tabpanel">
                {React.createElement(steps[activeStep].component, {
                    next: e => this.handleStepChange(e, activeStep + 1),
                    prev: e => this.handleStepChange(e, activeStep - 1),
                    activeStep,
                    ...other
                })}
            </div>
        );
    }
}

StepperBody.propTypes = {
    activeStep: PropTypes.number,
    onStepChange: PropTypes.func,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
            disabled: PropTypes.bool
        })
    )
};

StepperBody.displayName = 'StepperBody';

export default StepperBody;
