import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import LoadingSpinner from './LoadingSpinner';
import { Typography } from '../Typography';
import OnsolvePropTypes from '../utils/PropTypes';

import { componentsTranslations } from '../translations';

const LoadingOverlay = ({ className, description, logoType, message, spinner, spinnerSize = '4x', title, overlay }) => {
    const rootClasses = classNames('onsolve-loading__container', className, {
        'onsolve-loading__overlay': overlay
    });

    return (
        <div className={rootClasses}>
            {spinner || <LoadingSpinner color="primary" logoType={logoType} size={spinnerSize} />}
            {message || (
                <Fragment>
                    <Typography variant="p14" className="onsolve-loading__title">
                        {title || (
                            <FormattedMessage {...componentsTranslations.ng_components_loading}>
                                {value => <> {value}...</>}
                            </FormattedMessage>
                        )}
                    </Typography>
                    <Typography variant="p12">
                        {description || <FormattedMessage {...componentsTranslations.ng_components_loadingMsg} />}
                    </Typography>
                </Fragment>
            )}
        </div>
    );
};

LoadingOverlay.displayName = 'LoadingOverlay';

LoadingOverlay.propTypes = {
    /**
    Override or extend the styles applied to the component. `string`
    */
    className: PropTypes.string,
    /**
    Specifies a description.
    */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     Specifies the type of loading indicator.
     */
    logoType: PropTypes.oneOf(['oldLogo', 'newLogo']),
    /**
   Specifies a message.
   */
    message: PropTypes.node,
    /**
   Specifies an overlay.
   */
    overlay: PropTypes.bool,
    /**
    Specifies how to show a spinner component.
   */
    spinner: PropTypes.node,
    /**
   Specifies a spinner size.
   */
    spinnerSize: OnsolvePropTypes.iconSize,
    /**
   Specifies a title.
   */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

LoadingOverlay.displayName = 'LoadingOverlay';

export default LoadingOverlay;
