import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OnsolvePropTypes from '../utils/PropTypes';

const SvgIcon = forwardRef((props, ref) => {
    const { className, color, children, title, flip, rotation, size, disabled, viewBox, ...other } = props;

    const svgClasses = classNames('onsolve-svg-icon', className, {
        [`onsolve-svg-icon--${size}`]: size,
        'onsolve-svg-icon--light': disabled,
        [`onsolve-svg-icon--${color}`]: !disabled && color,
        [`onsolve-svg-icon--flip-${flip}`]: flip !== null,
        [`onsolve-svg-icon--rotate-${rotation}`]: rotation !== null
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={svgClasses}
            focusable="false"
            viewBox={viewBox}
            aria-hidden={title ? 'false' : 'true'}
            role={title ? 'img' : 'presentation'}
            ref={ref}
            {...other}
        >
            {title && <title>{title}</title>}
            {children}
        </svg>
    );
});

SvgIcon.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    flip: OnsolvePropTypes.flip,
    rotation: OnsolvePropTypes.rotation,
    size: OnsolvePropTypes.iconSize,
    title: PropTypes.string,
    viewBox: PropTypes.string
};

SvgIcon.defaultProps = {
    disabled: false,
    flip: null,
    rotation: null,
    size: 'md',
    viewBox: '0 0 16 16'
};

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
