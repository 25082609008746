import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IconButton } from '../Button';
import { TimesIcon } from '../Icons';
import { componentsTranslations } from '../translations';

const CleanButton = ({ onClick, tabIndex, intl }) => {
    return (
        <IconButton
            tabIndex={tabIndex !== undefined ? tabIndex : -1}
            className="onsolve-search-box__button--clean"
            onClick={onClick}
            aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
        >
            <TimesIcon color="secondary" size="sm" />
        </IconButton>
    );
};

CleanButton.propTypes = {
    intl: PropTypes.object,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number
};

CleanButton.displayName = 'CleanButton';

export default injectIntl(CleanButton);
