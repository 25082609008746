import React from 'react';
import SvgIcon from './SvgIcon';
const FileXlxs = props => {
    return (
        <SvgIcon {...props}>
            <g stroke="none">
                <path fill="#0e512b" d="M14.957 8.667h-13.913l-1.043-1.333 1.391-1.333h13.217l1.391 1.333z" />
                <path
                    fill="#e6e6e6"
                    d="M13.965 16h-11.93c-0.388 0-0.702-0.311-0.702-0.696v-14.609c0-0.384 0.314-0.696 0.702-0.696h8.615l4.017 4v11.304c0 0.384-0.314 0.696-0.702 0.696z"
                />
                <path fill="#b3b3b3" d="M10.667 0v3.333c0 0.368 0.298 0.667 0.667 0.667h3.333l-4-4z" />
                <path
                    fill="#1b7343"
                    d="M15.304 13.333h-14.609c-0.384 0-0.696-0.298-0.696-0.667v-5.333h16v5.333c0 0.368-0.311 0.667-0.696 0.667z"
                />
                <path
                    fill="#fff"
                    d="M5.748 11.731h-0.854l-0.533-0.854-0.527 0.854h-0.835l0.906-1.398-0.852-1.334h0.818l0.493 0.845 0.474-0.845h0.842l-0.865 1.392z"
                />
                <path fill="#fff" d="M6.042 11.731v-2.731h0.738v2.136h1.052v0.596z" />
                <path
                    fill="#fff"
                    d="M10.039 10.941c0 0.169-0.043 0.32-0.129 0.451s-0.21 0.234-0.372 0.306c-0.162 0.073-0.352 0.109-0.57 0.109-0.182 0-0.334-0.013-0.458-0.038s-0.251-0.070-0.385-0.134v-0.658c0.141 0.072 0.287 0.129 0.439 0.169s0.291 0.061 0.418 0.061c0.11 0 0.19-0.019 0.241-0.057s0.077-0.087 0.077-0.147c0-0.037-0.010-0.070-0.031-0.098s-0.054-0.056-0.099-0.085c-0.045-0.029-0.167-0.087-0.363-0.176-0.178-0.081-0.312-0.159-0.401-0.235s-0.155-0.163-0.198-0.262c-0.043-0.098-0.065-0.215-0.065-0.349 0-0.252 0.091-0.448 0.275-0.589s0.435-0.211 0.755-0.211c0.283 0 0.571 0.065 0.865 0.196l-0.226 0.57c-0.255-0.117-0.476-0.175-0.661-0.175-0.096 0-0.166 0.017-0.209 0.050s-0.065 0.075-0.065 0.125c0 0.054 0.028 0.102 0.083 0.144s0.206 0.12 0.451 0.232c0.235 0.106 0.399 0.219 0.49 0.341s0.137 0.274 0.137 0.459z"
                />
                <path
                    fill="#fff"
                    d="M13.082 11.731h-0.854l-0.533-0.854-0.527 0.854h-0.835l0.906-1.398-0.852-1.334h0.818l0.493 0.845 0.474-0.845h0.842l-0.865 1.392z"
                />
            </g>
        </SvgIcon>
    );
};

FileXlxs.displayName = 'FileXlxsIcon';

export default FileXlxs;
