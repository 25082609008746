import React from 'react';

import SvgIcon from './SvgIcon';

const PhoneCall = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M2.21367521,0.424816542 L5.79740568,4.00854701 L4.00253389,5.8034188 L8.1965812,9.99746611 L9.99145299,8.20259432 L13.5751835,11.7863248 L11.3615082,14 L11.1880342,14 C5.00912861,14 0,8.99087139 0,2.81196581 L0,2.63849176 L2.21367521,0.424816542 Z M2.21367521,1.60937149 L0.83900834,2.98403837 C0.929960012,8.56373673 5.43626327,13.07004 11.0159616,13.1609917 L12.3906285,11.7863248 L9.99145299,9.38714927 L8.1965812,11.1820211 L2.81797894,5.8034188 L4.61285073,4.00854701 L2.21367521,1.60937149 Z M8.1965812,0 C11.4019825,0 14,2.5980175 14,5.8034188 L14,6.22222222 L13.1623932,6.22222222 L13.1623932,5.8034188 C13.1623932,3.06061498 10.939385,0.837606838 8.1965812,0.837606838 L7.77777778,0.837606838 L7.77777778,0 L8.1965812,0 Z M8.1965812,2.39316239 C10.0797603,2.39316239 11.6068376,3.92023972 11.6068376,5.8034188 L11.6068376,6.22222222 L10.7692308,6.22222222 L10.7692308,5.8034188 C10.7692308,4.3828372 9.6171628,3.23076923 8.1965812,3.23076923 L7.77777778,3.23076923 L7.77777778,2.39316239 L8.1965812,2.39316239 Z"
        />
    </SvgIcon>
);

PhoneCall.displayName = 'PhoneCallIcon';

export default PhoneCall;
