import React, { Fragment, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyleButton } from '../components';
import { ENTITY_TYPES, LINK_TYPES } from '../constants';
import { getEntityAtCursorPosition, getSelectedText } from '../utils';

import { LinkModalForm } from './LinkModal';

const LinkStyleControls = forwardRef(
    ({ editorState, iconComponent, onToggle, style, intl, translate, disabled }, ref) => {
        const entity = getEntityAtCursorPosition(editorState);
        const isActive = entity && entity.type === ENTITY_TYPES.LINK;
        const initLinkValue = {
            displayText: '',
            targetUrl: '',
            linkType: LINK_TYPES.WEB_ADDRESS,
            openLinkInSeparateWindow: true
        };
        const linkFormRef = React.createRef();
        const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
        const [isSelectedText, setIsSelectedText] = useState(false);
        const [linkValue, setLinkValue] = useState({ ...initLinkValue });

        const handleLinkModalOpen = (blockType, isActive) => {
            if (isActive) {
                onToggle({
                    ...initLinkValue,
                    blockType,
                    isActive
                });
            } else {
                const selectedText = getSelectedText(editorState);

                if (selectedText) {
                    setLinkValue(state => ({
                        ...state,
                        displayText: selectedText,
                        linkType: LINK_TYPES.WEB_ADDRESS
                    }));
                    setIsSelectedText(true);
                    setIsLinkModalOpen(true);
                } else {
                    const selectedText = getSelectedText(editorState);

                    if (selectedText) {
                        setLinkValue(state => ({ ...state, displayText: selectedText }));
                        setIsSelectedText(true);
                    } else {
                        setLinkValue({ ...initLinkValue });
                        setIsSelectedText(false);
                    }

                    setIsLinkModalOpen(true);
                }
            }
        };

        const handleLinkModalClose = () => {
            setIsLinkModalOpen(false);
            setLinkValue({ openLinkInSeparateWindow: true });
        };

        const handleLinkModalSubmit = ({ displayText, targetUrl, linkType, openLinkInSeparateWindow }) => {
            if (linkType === LINK_TYPES.WEB_ADDRESS && !targetUrl.startsWith('http')) {
                targetUrl = `http://${targetUrl}`;
            } else if (linkType === LINK_TYPES.EMAIL_ADDRESS && !targetUrl.startsWith('mailto:')) {
                targetUrl = `mailto:${targetUrl}`;
            }

            if (isSelectedText) {
                onToggle({
                    blockType: style,
                    isActive,
                    targetUrl,
                    linkType,
                    openLinkInSeparateWindow
                });
            } else {
                onToggle({
                    blockType: style,
                    isActive,
                    displayText,
                    targetUrl,
                    linkType,
                    openLinkInSeparateWindow
                });
            }

            handleLinkModalClose();
        };

        return (
            <Fragment>
                <StyleButton
                    isActive={!!isActive}
                    onToggle={handleLinkModalOpen}
                    style={style}
                    intl={intl}
                    translate={translate}
                    iconComponent={iconComponent}
                    ref={ref}
                    disabled={disabled}
                />
                <LinkModalForm
                    isOpen={isLinkModalOpen}
                    formRef={linkFormRef}
                    onSubmit={handleLinkModalSubmit}
                    onClose={handleLinkModalClose}
                    linkInitValues={linkValue}
                    isSelectedDisplayText={isSelectedText}
                />
            </Fragment>
        );
    }
);

LinkStyleControls.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    iconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    intl: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    style: PropTypes.string.isRequired,
    translate: PropTypes.string.isRequired
};

LinkStyleControls.defaultProps = {
    disabled: false
};

LinkStyleControls.displayName = 'LinkStyleControls';

export default LinkStyleControls;
