import PropTypes from 'prop-types';
import { SelectionMode } from '../Table/utils';

const ALLIGN = ['left', 'right', 'center'];
const AXIS = ['x', 'y'];
const COLORS = ['initial', 'primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark', 'light'];

export const SIZE = ['xs', 'sm', 'md', 'lg', 'xl', '2x', '3x', '4x', '5x'];
export const BUTTON_SIZE = ['xs', 'sm', 'md', 'lg'];
export const THEME_COLORS = [...COLORS, 'white', 'wave-blue', 'catalina-blue', 'curious-purple', 'blue-aqua'];
export const TEXT_VARIANTS = [
    'caption',
    'body1',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p8',
    'p10',
    'p12',
    'p14',
    'p16',
    'p18',
    'p24',
    'p30',
    'p48'
];

export const ROTATION = [90, 180, 270];
export const FLIP = ['horizontal', 'vertical'];

function deprecatedPropType(validator, reason) {
    return (props, propName, componentName, location, propFullName) => {
        if (typeof props[propName] !== 'undefined') {
            return new Error(
                `The ${location} \`${propFullName || propName}\` of ` + `\`${componentName}\` is deprecated. ${reason}`
            );
        }
        return null;
    };
}

export default {
    iconSize: PropTypes.oneOf(SIZE),
    align: PropTypes.oneOf(ALLIGN),
    buttonSize: PropTypes.oneOf(BUTTON_SIZE),
    colors: PropTypes.oneOf(THEME_COLORS),
    rotation: PropTypes.oneOf(ROTATION),
    flip: PropTypes.oneOf(FLIP),
    textColors: PropTypes.oneOf(THEME_COLORS),
    textVariants: PropTypes.oneOf(TEXT_VARIANTS),

    drag: PropTypes.shape({
        iconColor: PropTypes.oneOf(COLORS),
        iconSize: PropTypes.oneOf(SIZE),
        lockAxis: PropTypes.oneOf(AXIS)
    }),
    deprecatedPropType,
    selectionMode: PropTypes.oneOf([SelectionMode.None, SelectionMode.Partial, SelectionMode.All])
};
