import React from 'react';
import SvgIcon from './SvgIcon';

const Reports = props => {
    return (
        <SvgIcon {...props}>
            <defs>
                <ellipse id="path-1" cx="43.6719567" cy="43.9202476" rx="43.6719567" ry="43.9202476" />
            </defs>
            <g id="Reports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="RP-1-Reports-Common-Page" transform="translate(-715.000000, -252.000000)">
                    <g id="Group" transform="translate(532.000000, 252.000000)">
                        <g id="Group-15" transform="translate(183.000000, 0.000000)">
                            <g id="Group-9">
                                <g id="Group-13" transform="translate(7.273239, 0.411168)">
                                    <g id="Group-11" transform="translate(0.107573, 0.157667)">
                                        <g id="Group-8" transform="translate(0.432074, 0.000000)">
                                            <mask id="mask-2" fill="white">
                                                <use xlinkHref="#path-1" />
                                            </mask>
                                            <use id="Mask" fill="#EEF6FF" fillRule="nonzero" xlinkHref="#path-1" />
                                        </g>
                                        <ellipse
                                            id="Oval-7"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            cx="32.3486081"
                                            cy="9.67123735"
                                            rx="1.25324578"
                                            ry="1.26097717"
                                        />
                                        <ellipse
                                            id="Oval-7-Copy-2"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            opacity="0.279924665"
                                            cx="66.9609568"
                                            cy="62.703162"
                                            rx="2.41473399"
                                            ry="2.42963072"
                                        />
                                        <ellipse
                                            id="Oval-7-Copy-4"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            opacity="0.503720238"
                                            cx="78.3435413"
                                            cy="79.7567388"
                                            rx="1.25324578"
                                            ry="1.26097717"
                                        />
                                        <ellipse
                                            id="Oval-7-Copy-5"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            opacity="0.503720238"
                                            cx="28.2545306"
                                            cy="75.3627248"
                                            rx="1.25324578"
                                            ry="1.26097717"
                                        />
                                        <ellipse
                                            id="Oval-7-Copy"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            cx="66.9609568"
                                            cy="14.463441"
                                            rx="1"
                                            ry="1"
                                        />
                                        <ellipse
                                            id="Oval-7-Copy-3"
                                            fill="#3777BC"
                                            fillRule="nonzero"
                                            cx="16.3200708"
                                            cy="36.0702219"
                                            rx="1.48554342"
                                            ry="1.49470788"
                                        />
                                    </g>
                                </g>
                                <g id="Group-12" transform="translate(0.000000, 7.550545)">
                                    <g id="Group-10" fill="#3777BC" fillRule="nonzero" opacity="0.595238095">
                                        <g id="Group-4" transform="translate(89.161496, 0.005694)">
                                            <path
                                                d="M2.93712037,7.0041294 L6.43977894,7.0041294 L5.94586551,6.14622 L3.43847892,6.14622 L2.93712037,7.0041294 Z M6.21639761,5.67431095 L7.23145046,5.67431095 L5.47949424,2.65369711 L4.97468767,3.51750665 L6.21639761,5.67431095 Z M3.93461765,2.65275674 L2.14627174,5.67431095 L3.16997859,5.67431095 L4.43144522,3.51572789 L3.93461765,2.65275674 Z M5.67417922,5.67431095 L4.70155045,3.98489069 L3.71426053,5.67431095 L5.67417922,5.67431095 Z M4.70458244,3.04834385 L5.48102289,1.71972273 L8.04839158,6.14622 L6.48808389,6.14622 L7.25368361,7.47603844 L2.11705681,7.47603844 L2.89419698,6.14622 L1.32089038,6.14622 L3.94021343,1.7206631 L4.70458244,3.04834385 Z"
                                                id="Stroke-1-Copy"
                                            />
                                            <path
                                                d="M4.68675104,9.33617814 C7.22540942,9.33617814 9.28339866,7.26648849 9.28339866,4.71339692 C9.28339866,2.16030535 7.22540942,0.0906156956 4.68675104,0.0906156956 C2.14809266,0.0906156956 0.090103425,2.16030535 0.090103425,4.71339692 C0.090103425,7.26648849 2.14809266,9.33617814 4.68675104,9.33617814 Z M4.68675104,8.8642691 C2.40724744,8.8642691 0.559344662,7.00586032 0.559344662,4.71339692 C0.559344662,2.42093352 2.40724744,0.562524739 4.68675104,0.562524739 C6.96625464,0.562524739 8.81415742,2.42093352 8.81415742,4.71339692 C8.81415742,7.00586032 6.96625464,8.8642691 4.68675104,8.8642691 Z"
                                                id="Oval"
                                            />
                                        </g>
                                        <g id="Group-4-Copy" transform="translate(0.397822, 4.191071)">
                                            <path
                                                d="M2.85018105,5.09435096 L2.45645192,5.76808819 L5.28927338,5.76808819 L4.90139108,5.09435096 L2.85018105,5.09435096 Z M5.94309681,4.66963282 L4.52611009,2.22655252 L4.12928159,2.9055933 L5.14486997,4.66963282 L5.94309681,4.66963282 Z M3.24986215,2.2257062 L1.8033926,4.66963282 L2.60853075,4.66963282 L3.64036339,2.90399242 L3.24986215,2.2257062 Z M4.65687342,4.66963282 L3.88345809,3.32623894 L3.0983845,4.66963282 L4.65687342,4.66963282 Z M3.88618689,2.48334678 L4.52748588,1.38597559 L6.67834382,5.09435096 L5.38938763,5.09435096 L6.02178758,6.19280633 L1.71839472,6.19280633 L2.3603273,5.09435096 L1.06054938,5.09435096 L3.25489835,1.38682191 L3.88618689,2.48334678 Z"
                                                id="Stroke-1-Copy"
                                            />
                                            <path
                                                d="M3.87134565,7.72931716 C5.9779121,7.72931716 7.68562162,6.0118987 7.68562162,3.89335566 C7.68562162,1.77481262 5.9779121,0.0573941518 3.87134565,0.0573941518 C1.76477921,0.0573941518 0.05706969,1.77481262 0.05706969,3.89335566 C0.05706969,6.0118987 1.76477921,7.72931716 3.87134565,7.72931716 Z M3.87134565,7.30459902 C1.99801851,7.30459902 0.479386803,5.77733334 0.479386803,3.89335566 C0.479386803,2.00937797 1.99801851,0.482112291 3.87134565,0.482112291 C5.7446728,0.482112291 7.2633045,2.00937797 7.2633045,3.89335566 C7.2633045,5.77733334 5.7446728,7.30459902 3.87134565,7.30459902 Z"
                                                id="Oval"
                                            />
                                        </g>
                                        <g id="Group-4-Copy-2" transform="translate(8.537914, 67.974372)">
                                            <path
                                                d="M2.33315803,5.62645612 L5.18290874,5.62645612 L4.77299296,4.91444755 L2.74925277,4.91444755 L2.33315803,5.62645612 Z M5.81756585,4.56051577 L4.39222849,2.10303782 L3.97354506,2.81947608 L4.97589203,4.56051577 L5.81756585,4.56051577 Z M3.1540092,2.10233255 L1.6991017,4.56051577 L2.54787752,4.56051577 L3.56611322,2.81814201 L3.1540092,2.10233255 Z M4.56922825,4.56051577 L3.76869214,3.17001412 L2.95608897,4.56051577 L4.56922825,4.56051577 Z M3.77096614,2.46760398 L4.39337498,1.40255704 L6.43027169,4.91444755 L5.17965675,4.91444755 L5.79333724,5.9803879 L1.71811036,5.9803879 L2.34104131,4.91444755 L1.08006568,4.91444755 L3.15820604,1.40326231 L3.77096614,2.46760398 Z"
                                                id="Stroke-1-Copy"
                                            />
                                            <path
                                                d="M3.75675123,7.47141699 C5.78497731,7.47141699 7.42917941,5.817867 7.42917941,3.77810972 C7.42917941,1.73835244 5.78497731,0.084802454 3.75675123,0.084802454 C1.72852515,0.084802454 0.0843230469,1.73835244 0.0843230469,3.77810972 C0.0843230469,5.817867 1.72852515,7.47141699 3.75675123,7.47141699 Z M3.75675123,7.1174852 C1.92289123,7.1174852 0.436253975,5.62239587 0.436253975,3.77810972 C0.436253975,1.93382357 1.92289123,0.438734237 3.75675123,0.438734237 C5.59061122,0.438734237 7.07724848,1.93382357 7.07724848,3.77810972 C7.07724848,5.62239587 5.59061122,7.1174852 3.75675123,7.1174852 Z"
                                                id="Oval"
                                            />
                                        </g>
                                    </g>
                                    <g id="Path-6" transform="translate(56.308948, 5.662909)" fill="#EEF6FF">
                                        <polygon
                                            id="Path-5"
                                            points="0.199362664 3.42452117 1.67028456 4.03282877 1.68367133 0.0634685341 0.199362664 0.0634685341"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g id="Group-14" transform="translate(28.712871, 18.791209)">
                                <polygon
                                    id="Fill-19"
                                    fill="#FFFFFF"
                                    points="1.86639354 42.1706129 34.061682 42.1706129 34.061682 1.91684604 1.86639354 1.91684604"
                                />
                                <path
                                    d="M1.81326062,40.3301433 L32.6942029,40.3301433 L32.6942029,1.8619537 L1.81326062,1.8619537 L1.81326062,40.3301433 Z M0,42.1706129 L34.5282804,42.1706129 L34.5282804,0 L0,0 L0,42.1706129 Z"
                                    id="Fill-21"
                                    fill="#3777BC"
                                />
                                <polygon
                                    id="Fill-22"
                                    fill="#3777BC"
                                    opacity="0.665480841"
                                    points="38.7951535 6.12276081 36.2223209 6.12276081 36.2223209 4.31290359 40.5940594 4.31290359 40.5940594 46.4835165 6.06577899 46.4835165 6.06577899 42.8153137 7.80930029 42.8153137 7.80930029 44.6202845 38.7951535 44.6202845"
                                />
                                <polygon
                                    id="Fill-23"
                                    fill="#5998D1"
                                    opacity="0.686895461"
                                    points="7.93217253 34.9824402 27.995903 34.9824402 27.995903 33.0655942 7.93217253 33.0655942"
                                />
                                <polygon
                                    id="Fill-24"
                                    fill="#5998D1"
                                    opacity="0.686895461"
                                    points="7.93217253 26.8358446 27.995903 26.8358446 27.995903 24.9189985 7.93217253 24.9189985"
                                />
                                <polygon
                                    id="Fill-25"
                                    fill="#5998D1"
                                    opacity="0.686895461"
                                    points="7.93217253 18.2100374 27.995903 18.2100374 27.995903 16.2931913 7.93217253 16.2931913"
                                />
                                <polygon
                                    id="Fill-26"
                                    fill="#5998D1"
                                    opacity="0.686895461"
                                    points="7.93217253 9.10501869 27.995903 9.10501869 27.995903 7.18817265 7.93217253 7.18817265"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

Reports.displayName = 'ReportsIcon';

export default Reports;
