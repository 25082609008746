import format from 'date-fns/format';

function getDateString(value, dateFormat) {
    return value ? format(value, dateFormat) : '';
}

function getDateRangeInputString(value, dateFormat) {
    const [start, end] = value;

    const startDate = { value: getDateString(start, dateFormat), isValid: true };
    const endDate = { value: getDateString(end, dateFormat), isValid: true };

    return { value, startDate, endDate };
}

function getDateInputString(value, dateFormat) {
    const startDate = { value: getDateString(value, dateFormat), isValid: true };

    return { value, startDate };
}

export default {
    getDateInputString,
    getDateRangeInputString
};
