import * as Yup from 'yup';
import { TranslationUtility } from '../../../../common/utility';

import { componentsTranslations } from '../../../translations';

const translate = TranslationUtility.createTranslationString;

const LinkModalFormSchema = Yup.object().shape({
    displayText: Yup.string()
        .trim()
        .max(
            120,
            translate(
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text_error_max_length.id,
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text_error_max_length.defaultMessage,
                120
            )
        )
        .required(
            translate(
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text_error_required.id,
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_display_text_error_required.defaultMessage
            )
        ),
    targetUrl: Yup.string()
        .trim()
        .required(
            translate(
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_target_url_error_required.id,
                componentsTranslations.ng_wysiwyg_editor_link_modal_add_target_url_error_required.defaultMessage
            )
        ),
    openLinkInSeparateWindow: Yup.boolean()
});

export default LinkModalFormSchema;
