import React from 'react';
import PropTypes from 'prop-types';

import eachDay from 'date-fns/eachDayOfInterval';
import isSameMonth from 'date-fns/isSameMonth';
import isSameDay from 'date-fns/isSameDay';
import isWithinRange from 'date-fns/isWithinInterval';
import isAfter from 'date-fns/isAfter';
import getTime from 'date-fns/getTime';

import { fp as _ } from '../../utils';
import Day from './Day';

const Week = ({
    value,
    month,
    fromDay,
    toDay,
    maxDate,
    minDate,
    initialValues,
    currentDate = new Date().setHours(0, 0, 0, 0),
    ...other
}) => {
    const renderWeekDays = function() {
        let result = Array.isArray(value) ? [...(value || [])] : [value];

        if (Array.isArray(initialValues)) {
            result = result.concat(initialValues);
        }

        const dateRange = eachDay({ start: fromDay, end: toDay });

        return _.map(dateRange, from => {
            const selected = result.some(day => isSameDay(from, day));
            const isCurrent = from.valueOf() === currentDate.valueOf();
            let disabled = !isSameMonth(from, month);

            if (!disabled) {
                if (minDate !== undefined && maxDate !== undefined) {
                    disabled = !isWithinRange(from, { start: minDate, end: maxDate });
                } else if (maxDate !== undefined) {
                    disabled = isAfter(new Date(from), new Date(maxDate));
                } else if (minDate !== undefined) {
                    disabled = isAfter(new Date(minDate), new Date(from));
                }
            }

            const inRange =
                Array.isArray(value) &&
                value[0] !== undefined &&
                value[1] !== undefined &&
                isWithinRange(from, {
                    start: value[0],
                    end: value[value.length - 1]
                });

            return (
                <Day
                    isCurrent={isCurrent}
                    key={getTime(from)}
                    day={from}
                    disabled={disabled}
                    selected={selected}
                    inRange={inRange}
                    {...other}
                />
            );
        });
    };

    return <div className="onsolve-date-picker__week">{renderWeekDays()}</div>;
};

Week.propTypes = {
    currentDate: PropTypes.instanceOf(Date),
    fromDay: PropTypes.instanceOf(Date).isRequired,
    initialValues: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    month: PropTypes.instanceOf(Date).isRequired,
    toDay: PropTypes.instanceOf(Date).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date))])
};

Week.displayName = 'Week';

export default Week;
