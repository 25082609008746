import { TranslationString } from '../models';

const marker = '$$translationString';

class TranslationUtility {
    static get markerPropertyName() {
        return marker;
    }

    //This method is deprecated, please use getTranslationString
    static createTranslationString(id, defaultMessage, ...values) {
        return new TranslationString(id, defaultMessage, values);
    }

    static getTranslationString({ id, defaultMessage }, ...values) {
        return TranslationUtility.createTranslationString(id, defaultMessage, ...values);
    }

    static isTranslationString(obj) {
        // instanceof does not work after yup
        return obj[marker];
    }
}

export default TranslationUtility;
