import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Focusable } from '../Focusable';

const Link = forwardRef(({ className, disabled, href, target, onClick, ...other }, ref) => {
    const handleClick = e => {
        if (!href || href === '#') {
            e.preventDefault();
        }

        if (!disabled && onClick) {
            onClick(e);
        }
    };

    const anchorProps = useMemo(() => {
        if (disabled) {
            return {
                'aria-disabled': true,
                tabIndex: -1
            };
        }
    }, [disabled]);

    return (
        <Focusable
            render={({ classes }) => (
                <a
                    ref={ref}
                    href={href || '#'}
                    className={classNames('onsolve-link', className, classes, { 'onsolve-link--disabled': disabled })}
                    rel={target === '_blank' ? 'noopener norefferer' : null}
                    target={target}
                    {...other}
                    {...anchorProps}
                    onClick={handleClick}
                />
            )}
        />
    );
});

Link.displayName = 'Link';

Link.propTypes = {
    /**
    Override or extend the styles applied to component.
    */
    className: PropTypes.string,
    /**
    Specifies the state of component.
    */
    disabled: PropTypes.bool,
    /**
    The URL that the hyperlink points to.
    */
    href: PropTypes.string,
    /**
    Callback function on click event occurs when the link is clicked.
    */
    onClick: PropTypes.func,
    /**
    Specifies where to display the linked URL, as the name for a browsing context.
    */
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
};

export default Link;
