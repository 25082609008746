import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalSubHeader = ({ children, className }) => (
    <div className={classNames('onsolve-alert-modal__sub-header', className)}>{children}</div>
);

ModalSubHeader.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

ModalSubHeader.displayName = 'ModalSubHeader';

export default ModalSubHeader;
