import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PaperContent = ({ className, children }) => {
    const rootClasses = classNames('onsolve-paper-content', className);

    return <div className={rootClasses}>{children}</div>;
};

PaperContent.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string
};

PaperContent.displayName = 'PaperContent';

export default PaperContent;
