import PropTypes from 'prop-types';
import classNames from 'classnames';

const Focusable = ({ outlineDirection, type, render, ...rest }) => {
    const classes = classNames('onsolve-focusable', `onsolve-focusable__${type}-focus`, {
        'onsolve-focusable--inset': outlineDirection
    });

    return render({ ...rest, classes });
};

Focusable.defaultProps = {
    type: 'square'
};

Focusable.propTypes = {
    outlineDirection: PropTypes.string,
    render: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['square', 'circle'])
};

Focusable.displayName = 'Focusable';

export default Focusable;
