import React from 'react';
import SvgIcon from './SvgIcon';

const Alert = props => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M15.3,1.3C15.7,1.3,16,1.6,16,2v2c0,0.3-0.1,0.5-0.3,0.6l-7.3,4C8.2,8.7,8.1,8.7,8,8.7s-0.2,0-0.3-0.1 l-7.3-4C0.1,4.5,0,4.3,0,4V2c0-0.4,0.3-0.7,0.7-0.7H15.3z M8.9,9.7L16,5.9V14c0,0.4-0.3,0.7-0.7,0.7H0.7C0.3,14.7,0,14.4,0,14V5.9 l7.1,3.8C7.3,9.9,7.7,10,8,10S8.7,9.9,8.9,9.7z"
            />
        </SvgIcon>
    );
};

Alert.displayName = 'AlertIcon';

export default Alert;
