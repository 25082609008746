import accepts from 'attr-accept';

// Firefox versions prior to 53 return a bogus MIME type for every file drag, so dragovers with
// that MIME type will always be accepted
export const fileTypeAccepted = (file, accept) => {
    return file.type === 'application/x-moz-file' || accepts(file, accept);
};

const isDefined = value => {
    return value !== undefined && value !== null;
};

export const fileMatchSize = (file, minSizeInBytes, maxSizeInBytes) => {
    if (isDefined(file.size)) {
        if (isDefined(minSizeInBytes) && isDefined(maxSizeInBytes)) {
            return file.size >= minSizeInBytes && file.size <= maxSizeInBytes;
        } else if (isDefined(minSizeInBytes)) {
            return file.size >= minSizeInBytes;
        } else if (isDefined(maxSizeInBytes)) {
            return file.size <= maxSizeInBytes;
        }
    }
    return true;
};

export const fileMatchMaxDimensions = (fileDimension, maxDimension) => {
    if (isDefined(fileDimension) && isDefined(maxDimension)) {
        return fileDimension <= maxDimension;
    }
    return true;
};
