import React from 'react';
import SvgIcon from './SvgIcon';

const FloppyDiskOutlined = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M10.063 0c.116 0 .227.046.309.128l3.5 3.5a.437.437 0 01.128.31v9.624a.437.437 0 01-.438.438H.438A.437.437 0 010 13.562V.438C0 .196.196 0 .438 0zM9.88.875H.875v12.25h12.25V4.119L9.881.875zm1.056 7c.215 0 .394.155.431.359l.007.079v3.5a.437.437 0 01-.868.078l-.007-.079V8.75h-7v3.063a.438.438 0 01-.359.43l-.079.007a.438.438 0 01-.43-.359l-.007-.079v-3.5c0-.214.155-.393.359-.43l.079-.007h7.874zM8.313 1.75c.214 0 .393.155.43.359l.007.079v2.624a.438.438 0 01-.359.431l-.079.007h-5.25a.438.438 0 01-.43-.359l-.007-.079V2.188a.437.437 0 01.868-.078l.007.079v2.187h4.375V2.187c0-.214.155-.393.359-.43l.079-.007z"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            />
        </SvgIcon>
    );
};

FloppyDiskOutlined.displayName = 'FloppyDiskOutlinedIcon';

export default FloppyDiskOutlined;
