import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TimesIcon } from '../Icons';
import { Link } from '../Link';

const Tab = ({ className, classNames, variant, selected, value, label, tabIndex, onClose, onChange }) => {
    const handleClick = e => {
        e.preventDefault();

        if (onChange) {
            onChange(value.toString());
        }
    };

    const handleTabClose = e => {
        e.preventDefault();
        e.stopPropagation();

        onClose(value);
    };

    const rootClassName = `onsolve-${variant}__item`;
    const linkClassName = `onsolve-${variant}__link`;
    const closeIconClassName = `onsolve-${variant}__close`;

    const rootClassNames = classnames(
        rootClassName,
        { [`${rootClassName}--active`]: selected },
        className,
        classNames.rootClass
    );
    const linkClassNames = classnames(linkClassName, { [`${linkClassName}--active`]: selected }, classNames.linkClass);

    return (
        <li className={rootClassNames} tabIndex="-1">
            <Link className={linkClassNames} onClick={handleClick} tabIndex={tabIndex}>
                {label}
                {onClose && <TimesIcon size="xs" className={closeIconClassName} onClick={handleTabClose} />}
            </Link>
        </li>
    );
};

Tab.propTypes = {
    className: PropTypes.string,
    classNames: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    selected: PropTypes.bool,
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.any,
    variant: PropTypes.oneOf(['tabs', 'pills', 'separated'])
};

Tab.defaultProps = {
    variant: 'tabs',
    classNames: {}
};

Tab.displayName = 'Tab';

export default Tab;
