import React from 'react';

import SvgIcon from './SvgIcon';

const AngleDown = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M8,9.79289322 L12.6464466,5.14644661 C12.8417088,4.95118446 13.1582912,4.95118446 13.3535534,5.14644661 C13.5488155,5.34170876 13.5488155,5.65829124 13.3535534,5.85355339 L8.35355339,10.8535534 C8.15829124,11.0488155 7.84170876,11.0488155 7.64644661,10.8535534 L2.64644661,5.85355339 C2.45118446,5.65829124 2.45118446,5.34170876 2.64644661,5.14644661 C2.84170876,4.95118446 3.15829124,4.95118446 3.35355339,5.14644661 L8,9.79289322 Z"
                stroke="none"
            />
        </SvgIcon>
    );
};

AngleDown.displayName = 'AngleDownIcon';

export default AngleDown;
