import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardBody = React.forwardRef((props, ref) => {
    const { background, className, component: Component = 'div', transparent, ...other } = props;
    const bodyClass = classnames(
        'onsolve-card__body',
        {
            [`bg-${background}`]: !!background,
            'bg-transparent': transparent
        },
        className
    );

    return <Component className={bodyClass} ref={ref} {...other} />;
});

CardBody.propTypes = {
    /**
     Specifies that a color of background.
     */
    background: PropTypes.string,
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    /**
     Specifies a tag of component that wraps the body content and will be returned by CardBody.
     */
    component: PropTypes.elementType,
    /**
     Specifies a transparency of CardBody.
     */
    transparent: PropTypes.bool
};

CardBody.displayName = 'CardBody';

export default CardBody;
