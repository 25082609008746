import React, { Component, Fragment } from 'react';
import { add, differenceInMilliseconds } from 'date-fns';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { keyCodes } from '../../../../common/constants';
import { componentsTranslations } from '../../../translations';

import { DomUtils, fp as _ } from '../../../utils';
import { InputFieldDivider } from '../../../InputBase';
import { TimeUnit } from '../TimeInputs';
import { formatInitialValue, validate } from './helpers';

class TimeCounterInputText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...formatInitialValue({ ...this.props })
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.value && this.props.value !== prevProps.value) {
            this.setState({
                ...formatInitialValue({ ...this.props })
            });
        }
    }

    handleBlur = e => {
        const isFirstInput = !DomUtils.findPreviousInput(e.target);
        const { onBlur } = this.props;
        const { value, maxLength, name } = e.target;
        const { length } = value;

        onBlur && onBlur(e);

        if (length > 0 && length !== maxLength) {
            const diff = maxLength - length;
            const newValue = '0'.repeat(diff) + value;

            !isFirstInput && this.handleNextFocus(e.target);
            this.triggerChange(name, newValue);
        }
    };

    handleChange = e => {
        const { value, name } = e.target;

        this.handleNextFocus(e.target);
        this.triggerChange(name, value);
    };

    handleKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { target } = e;

        if (charCode === keyCodes.DELETE || charCode === keyCodes.BACKSPACE) {
            if (target.value.toString().length === 0) {
                const previousInput = DomUtils.findPreviousInput(target);

                DomUtils.setFocus(previousInput);
            }
        }
    };

    validateValues = () => {
        const { format, pattern } = this.props;

        return _.every(format, field => validate(pattern[field], this.state[field]));
    };

    handleNextFocus = element => {
        const { value, maxLength } = element;

        if (value.length === maxLength) {
            const nextInput = DomUtils.findNextInput(element);

            DomUtils.setFocus(nextInput);
        }
    };

    triggerChange = (fieldName, value) => {
        const { onChange, format } = this.props;

        this.setState(
            {
                [fieldName]: value
            },
            () => {
                const isValidValue = this.validateValues();
                const startDate = new Date(0);

                const newDate = isValidValue
                    ? add(
                          startDate,
                          _.pickBy(this.state, (_, key) => format.includes(key))
                      )
                    : undefined;

                onChange({
                    isValidValue,
                    value: newDate && differenceInMilliseconds(newDate, startDate)
                });
            }
        );
    };

    getInput = () => {
        const { intl, format, placeholder, disabled, divider, pattern, refs, ...others } = this.props;

        const amountFields = format.length;

        const inputProps = {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onKeyDown: this.handleKeyDown
        };

        return format.map((field, index) => (
            <Fragment key={field}>
                <TimeUnit
                    {...others}
                    {...inputProps}
                    name={field}
                    disabled={disabled}
                    inputRef={refs[field]}
                    maxLength={placeholder[field].length || 1}
                    value={this.state[field]}
                    pattern={pattern[field]}
                    placeholder={placeholder[field]}
                    aria-label={intl.formatMessage(componentsTranslations[`ng_components_${field}`])}
                />
                {index !== amountFields - 1 && (
                    <InputFieldDivider className="onsolve-time-counter-input__divider" symbol={divider} />
                )}
            </Fragment>
        ));
    };

    render() {
        return <Fragment>{this.getInput()}</Fragment>;
    }
}

TimeCounterInputText.propTypes = {
    disabled: PropTypes.bool,
    divider: PropTypes.string,
    format: PropTypes.array,
    intl: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    pattern: PropTypes.object.isRequired,
    placeholder: PropTypes.shape({
        hours: PropTypes.string,
        minutes: PropTypes.string,
        seconds: PropTypes.string
    }).isRequired,
    refs: PropTypes.shape({
        hours: PropTypes.object,
        minutes: PropTypes.object,
        seconds: PropTypes.object
    }).isRequired,
    value: PropTypes.number
};

TimeCounterInputText.displayName = 'TimeCounterInputText';

export default injectIntl(TimeCounterInputText);
