import TranslationUtility from '../utility/translationUtility';

class TranslationString {
    constructor(id, defaultMessage, values) {
        // instanceof does not work after yup
        this[TranslationUtility.markerPropertyName] = true;
        this.id = id;
        this.defaultMessage = defaultMessage;
        this.values = values;
    }
}

export default TranslationString;
