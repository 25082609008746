import React from 'react';
import SvgIcon from './SvgIcon';

const PaperPlane = props => {
    return (
        <SvgIcon {...props} width="110" height="109" viewBox="0 0 110 109">
            <path d="M24.565 65.506V98.94c0 4.777 5.459 6.824 8.87 3.412L49.13 83.929l32.07 23.883c2.73 2.047 6.824.682 7.506-2.73l20.47-98.94c.683-4.095-2.729-6.824-6.823-5.46L3.412 40.26C0 41.624-.682 46.4 2.047 49.129l10.918 8.189 31.388-15.012c2.73-1.365 4.776 2.047 2.73 3.412L24.564 65.506z" />
        </SvgIcon>
    );
};

PaperPlane.displayName = 'PaperPlaneIcon';

export default PaperPlane;
