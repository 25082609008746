import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Paper = ({ className, children, compact, variant }) => {
    const rootClasses = classNames('onsolve-paper', className, {
        'onsolve-paper--outlined': variant === 'outlined',
        'onsolve-paper--compact': variant === 'compact' || compact
    });

    return <div className={rootClasses}>{children}</div>;
};

Paper.propTypes = {
    /**
    The display content of the component.
    */
    children: PropTypes.node.isRequired,
    /**
    Override or extend the styles applied to the component.
    */
    className: PropTypes.string,
    /**
    Sets the height equal to the content height.
    */
    compact: PropTypes.bool,
    /**
    The variants to represent component.
    */
    variant: PropTypes.oneOf(['outlined', 'flat', 'compact'])
};

Paper.defaultProps = {
    variant: 'outlined'
};

Paper.displayName = 'Paper';

export default Paper;
