import React from 'react';

import SvgIcon from './SvgIcon';

const ChatArrow = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1">
            <path d="M14.125,1 L1.875,1 C1.392,1 1,1.392 1,1.875 L1,5.375 L6.25,5.375 L6.25,3.625 L9.75,6.25 L6.25,8.875 L6.25,7.125 L1,7.125 L1,10.625 C1,11.108 1.392,11.5 1.875,11.5 L4.5,11.5 L4.5,15 L9.75,11.5 L14.125,11.5 C14.608,11.5 15,11.108 15,10.625 L15,1.875 C15,1.392 14.608,1 14.125,1 Z" />
        </g>
    </SvgIcon>
);

ChatArrow.displayName = 'ChatArrowIcon';

export default ChatArrow;
