import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from './LoadingOverlay';

const withLoader = WrappedComponent => {
    class LoadIndicator extends Component {
        render() {
            const { isLoading, logoType } = this.props;

            return isLoading ? <LoadingOverlay logoType={logoType} /> : <WrappedComponent {...this.props} />;
        }
    }

    LoadIndicator.propTypes = {
        isLoading: PropTypes.bool,
        logoType: PropTypes.oneOf(['oldLogo', 'newLogo'])
    };

    LoadIndicator.displayName = 'LoadIndicator';

    return LoadIndicator;
};

export default withLoader;
