import React from 'react';
import SvgIcon from './SvgIcon';

const Desktop = props => (
    <SvgIcon {...props}>
        <g id="desktop" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M8,12.0925926 C8.15323508,12.0925926 8.28160143,12.1981762 8.31515029,12.3399807 L8.32369942,12.4135802 L8.323,13.858 L12.0057803,13.8580247 C12.1845546,13.8580247 12.3294798,14.0029499 12.3294798,14.1817241 C12.3294798,14.3349592 12.2230041,14.4633255 12.0800017,14.4968744 L12.0057803,14.5054235 L3.99421965,14.5054235 C3.8154454,14.5054235 3.67052023,14.3604984 3.67052023,14.1817241 C3.67052023,14.028489 3.77699586,13.9001227 3.91999835,13.8665738 L3.99421965,13.8580247 L7.676,13.858 L7.67630058,12.4135802 C7.67630058,12.2363037 7.82122575,12.0925926 8,12.0925926 Z M14.6763006,1.5 C14.8550748,1.5 15,1.64371107 15,1.82098765 L15,1.82098765 L15,10.6481481 C15,10.8254247 14.8550748,10.9691358 14.6763006,10.9691358 L14.6763006,10.9691358 L1.32369942,10.9691358 C1.14492517,10.9691358 1,10.8254247 1,10.6481481 L1,10.6481481 L1,1.82098765 C1,1.64371107 1.14492517,1.5 1.32369942,1.5 L1.32369942,1.5 Z M14.3522775,2.14128748 L1.6467052,2.14128748 L1.6467052,10.3268395 L14.3522775,10.3268395 L14.3522775,2.14128748 Z"
                id="Mask"
                fillRule="nonzero"
            />
        </g>
    </SvgIcon>
);

Desktop.displayName = 'DesktopIcon';

export default Desktop;
