import React from 'react';
import SvgIcon from './SvgIcon';

const DoubleArrow = props => {
    return (
        <SvgIcon {...props}>
            <path
                fill="#3777bc"
                d="M1.661 2.2l12 13.125c0.349 0.382 0.349 0.967 0 1.35l-12 13.125c-0.373 0.408-1.005 0.436-1.413 0.063s-0.436-1.005-0.063-1.413l11.383-12.45-11.383-12.45c-0.373-0.408-0.344-1.040 0.063-1.413s1.040-0.344 1.413 0.063zM12.738 2.2l12 13.125c0.349 0.382 0.349 0.967 0 1.35l-12 13.125c-0.373 0.408-1.005 0.436-1.413 0.063s-0.436-1.005-0.063-1.413l11.383-12.45-11.383-12.45c-0.373-0.408-0.344-1.040 0.063-1.413s1.040-0.344 1.413 0.063z"
            />
        </SvgIcon>
    );
};

DoubleArrow.displayName = 'DoubleArrowIcon';

export default DoubleArrow;
