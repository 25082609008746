(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("classnames"), require("react-intl"), require("draft-js"), require("reactstrap"), require("uuid"), require("react-dom"), require("formik"), require("yup"), require("immutable"), require("draft-js-plugins-editor"), require("react-router-dom"), require("react-router"), require("draft-js-export-html"), require("draft-js-import-html"), require("draft-js-focus-plugin"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "classnames", "react-intl", "draft-js", "reactstrap", "uuid", "react-dom", "formik", "yup", "immutable", "draft-js-plugins-editor", "react-router-dom", "react-router", "draft-js-export-html", "draft-js-import-html", "draft-js-focus-plugin"], factory);
	else if(typeof exports === 'object')
		exports["onsolve-ui-components"] = factory(require("react"), require("prop-types"), require("classnames"), require("react-intl"), require("draft-js"), require("reactstrap"), require("uuid"), require("react-dom"), require("formik"), require("yup"), require("immutable"), require("draft-js-plugins-editor"), require("react-router-dom"), require("react-router"), require("draft-js-export-html"), require("draft-js-import-html"), require("draft-js-focus-plugin"));
	else
		root["onsolve-ui-components"] = factory(root["react"], root["prop-types"], root["classnames"], root["react-intl"], root["draft-js"], root["reactstrap"], root["uuid"], root["react-dom"], root["formik"], root["yup"], root["immutable"], root["draft-js-plugins-editor"], root["react-router-dom"], root["react-router"], root["draft-js-export-html"], root["draft-js-import-html"], root["draft-js-focus-plugin"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__37__, __WEBPACK_EXTERNAL_MODULE__46__, __WEBPACK_EXTERNAL_MODULE__70__, __WEBPACK_EXTERNAL_MODULE__72__, __WEBPACK_EXTERNAL_MODULE__101__, __WEBPACK_EXTERNAL_MODULE__135__, __WEBPACK_EXTERNAL_MODULE__136__, __WEBPACK_EXTERNAL_MODULE__423__, __WEBPACK_EXTERNAL_MODULE__427__, __WEBPACK_EXTERNAL_MODULE__430__, __WEBPACK_EXTERNAL_MODULE__431__, __WEBPACK_EXTERNAL_MODULE__433__) {
return 