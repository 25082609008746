import { ContentState, EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import {
    FONT_SIZES,
    ALIGN_VALUES,
    INDENT_SIZES,
    ALIGN_STATE_KEYS,
    INDENT_STATE_KEYS,
    CUSTOM_STYLE_PREFIX_COLOR,
    ENTITY_TYPES,
    SCRIPTS_MAP
} from '../constants';
import { fp as _ } from '../../utils';

export const convertJsonToWysiwygValue = (content, decorator) => {
    if (content) {
        return decorator
            ? EditorState.createWithContent(convertFromRaw(JSON.parse(content)), decorator)
            : EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
    } else {
        return EditorState.createEmpty();
    }
};

export const convertJsonToHtml = json => {
    const inlineStyles = _.reduce(
        { ...FONT_SIZES, ...ALIGN_VALUES, ...INDENT_SIZES },
        (result, style, key) => {
            result[key] = { style };
            return result;
        },
        {}
    );

    return stateToHTML(convertJsonToWysiwygValue(json).getCurrentContent(), {
        defaultBlockTag: 'p',
        blockStyleFn: block => {
            let style = {};
            let key = ALIGN_STATE_KEYS.KEY;
            let styleName = ALIGN_STATE_KEYS.STYLE_NAME;
            let styleValue = block.getData().get(styleName);

            if (styleValue) {
                style[styleName] = styleValue.replace(key, '');
            }

            styleName = INDENT_STATE_KEYS.STYLE_NAME;
            styleValue = block.getData().get(styleName);

            if (styleValue) {
                style = { ...style, ...INDENT_SIZES[styleValue] };
            }
            return _.isEmpty(style) ? null : { style };
        },
        entityStyleFn: entity => {
            const entityType = entity.getType();

            if (entityType === ENTITY_TYPES.SUBSCRIPT || entityType === ENTITY_TYPES.SUPERSCRIPT) {
                return {
                    element: SCRIPTS_MAP[entityType].element
                };
            }
        },
        inlineStyleFn: styles => {
            const color = styles.filter(value => value.startsWith(CUSTOM_STYLE_PREFIX_COLOR)).first();

            if (color) {
                return {
                    style: {
                        color: color.replace(CUSTOM_STYLE_PREFIX_COLOR, '')
                    }
                };
            }
        },
        inlineStyles
    });
};

export const convertHtmlToJson = (html, options) => {
    const defaultOptions = {
        customInlineFn(element, { Style, Entity }) {
            if (element.tagName === 'SUB' || element.tagName === 'SUP') {
                return Entity(
                    element.tagName === 'SUB' ? ENTITY_TYPES.SUBSCRIPT : ENTITY_TYPES.SUPERSCRIPT,
                    {},
                    'MUTABLE'
                );
            }

            let customStyle;

            if (element.style.color) {
                return Style(CUSTOM_STYLE_PREFIX_COLOR + element.style.color);
            }

            for (const [name, styles] of Object.entries(FONT_SIZES)) {
                const hasCustomStyle = Object.entries(styles).every(
                    ([styleName, styleValue]) => element.style[styleName] === styleValue
                );

                if (hasCustomStyle) {
                    customStyle = Style(name);
                    break;
                }
            }

            return customStyle;
        },

        customBlockFn(element) {
            const data = {};

            for (const [name, styles] of Object.entries({ ...ALIGN_VALUES, ...INDENT_SIZES })) {
                Object.entries(styles).forEach(([styleName, styleValue]) => {
                    if (element.style[styleName] === styleValue) {
                        data[styleName] = name;
                    }
                });
            }

            return _.isEmpty(data) ? null : { data };
        }
    };

    const contentState = stateFromHTML(html, { ...defaultOptions, ...options });
    const rawContentState = convertToRaw(contentState);

    return JSON.stringify(rawContentState);
};

export const convertWysiwygValueToJson = editorState => {
    if (!editorState) {
        return null;
    }
    const contentState = editorState.getCurrentContent();
    const convertToRawContentState = convertToRaw(contentState);

    return JSON.stringify(convertToRawContentState);
};

export const createEditorStateFromRawText = text => {
    return EditorState.createWithContent(ContentState.createFromText(text));
};

export const convertStateToStateHTMLText = editorState => {
    const decorator = editorState.getDecorator();
    const rawHTML = convertJsonToHtml(convertWysiwygValueToJson(editorState));

    return EditorState.createWithContent(ContentState.createFromText(rawHTML), decorator);
};

export const convertStateHTMLTextToState = editorState => {
    const decorator = editorState.getDecorator();
    const HTMLText = editorState.getCurrentContent().getPlainText('');

    return convertJsonToWysiwygValue(convertHtmlToJson(HTMLText), decorator);
};
