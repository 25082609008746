import React from 'react';
import SvgIcon from './SvgIcon';

const Integrations = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M8,6.7c2.5,0,5.1-0.5,6.7-1.6V8c0,1.8-3.5,2.7-6.7,2.7S1.3,9.8,1.3,8V5.1C2.9,6.2,5.5,6.7,8,6.7z M8,12 c2.5,0,5.1-0.5,6.7-1.6v2.9c0,1.8-3.5,2.7-6.7,2.7s-6.7-0.9-6.7-2.7v-2.9C2.9,11.5,5.5,12,8,12z M8,0c3.2,0,6.7,0.9,6.7,2.7 S11.2,5.3,8,5.3S1.3,4.5,1.3,2.7S4.8,0,8,0z"
        />
    </SvgIcon>
);

Integrations.displayName = 'IntegrationsIcon';

export default Integrations;
