import React from 'react';

import SvgIcon from './SvgIcon';

const Moon = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            fillRule="evenodd"
            d="M6.5,2 C3.875,2.675 2,5.075 2,7.925 C2,11.3 4.7,14 8.075,14 C10.925,14 13.25,12.125 14,9.5 C9.425,10.775 5.225,6.575 6.5,2 Z"
        />
    </SvgIcon>
);

Moon.displayName = 'MoonIcon';

export default Moon;
