import { FormattedMessage } from 'react-intl';
import React from 'react';

import { componentsTranslations } from '../../translations';

import { fileMatchNameLength, fileMatchSize, fileTypeAccepted as isOneOfMimeTypes, setReason } from './validation';
import { GLOBAL_REASON, REASONS, VALIDATION_TYPES } from '../constants';
import { imagesValidation } from '../validationsTypes';

const isMimeTypeValid = (file, acceptMimeTypes = [], rejectMimeTypes = []) => {
    if (acceptMimeTypes.length && !isOneOfMimeTypes(file, acceptMimeTypes)) {
        return false;
    }

    if (rejectMimeTypes.length && isOneOfMimeTypes(file, rejectMimeTypes)) {
        return false;
    }

    return true;
};

export const filesValidation = (
    files,
    acceptMimeTypes,
    rejectMimeTypes,
    minSizeInBytes,
    maxSizeInBytes,
    maxFileNameLength,
    minFileNameLength,
    isMultipleFiles,
    validationType,
    additionalValidationParams,
    errorMessages
) => {
    const acceptedFiles = [];
    const rejectedFiles = [];
    let globalReason = null;

    return new Promise(resolve => resolve(files))
        .then(items => {
            if (!isMultipleFiles && items.length > 1) {
                rejectedFiles.push(...items);
                globalReason = errorMessages.multipleFiles || GLOBAL_REASON.MULTIPLE_FILES;
                return { acceptedFiles, rejectedFiles, globalReason };
            }

            for (let i = 0; i < items.length; i++) {
                const file = items[i];

                if (!isMimeTypeValid(file, acceptMimeTypes, rejectMimeTypes)) {
                    setReason(file, errorMessages.type || REASONS.TYPE);
                    rejectedFiles.push(file);
                    continue;
                }

                if (!fileMatchSize(file, minSizeInBytes, maxSizeInBytes)) {
                    setReason(file, errorMessages.size || REASONS.SIZE);
                    rejectedFiles.push(file);
                    continue;
                }

                if (!fileMatchNameLength(file, minFileNameLength, maxFileNameLength)) {
                    setReason(
                        file,
                        errorMessages.name || (
                            <FormattedMessage
                                {...componentsTranslations.ng_file_upload_name_error}
                                values={{ 0: minFileNameLength, 1: maxFileNameLength }}
                            />
                        )
                    );
                    rejectedFiles.push(file);
                    continue;
                }

                acceptedFiles.push(file);
            }

            return { acceptedFiles, rejectedFiles, globalReason };
        })
        .then(({ acceptedFiles, rejectedFiles, globalReason }) => {
            if (validationType === VALIDATION_TYPES.images && !globalReason) {
                return imagesValidation(acceptedFiles, rejectedFiles, globalReason, additionalValidationParams);
            }

            return { acceptedFiles, rejectedFiles, globalReason };
        });
};
