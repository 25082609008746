import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { IconButton } from '../Button';
import { TimesIcon } from '../Icons';

import { componentsTranslations } from '../translations';

const PopoverHeader = props => {
    const { className, children, size, onClose, intl } = props;
    const rootClasses = classNames('onsolve-popover__header', className, {
        [`onsolve-popover__header--${size}`]: size
    });

    return (
        <div className={rootClasses}>
            <div className="onsolve-popover__title">{children}</div>
            <IconButton
                size="xs"
                onClick={onClose}
                tabIndex="0"
                aria-label={intl.formatMessage(componentsTranslations.ng_components_close)}
            >
                <TimesIcon color="secondary" />
            </IconButton>
        </div>
    );
};

PopoverHeader.propTypes = {
    /**
     The content of wrapped component/content.
     */
    children: PropTypes.node.isRequired,
    /**
     Override or extend the styles applied to the component.
     */
    className: PropTypes.string,
    intl: PropTypes.object,
    /**
     Callback function on close event occurs when the close even triggered.
     */
    onClose: PropTypes.func,
    /**
     Specifies one of the size of popover.
     */
    size: PropTypes.oneOf(['md', 'lg'])
};

PopoverHeader.displayName = 'PopoverHeader';

export default injectIntl(PopoverHeader);
