import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { shapes } from '../../common/constants';

import CardDraggableIcon from './CardDraggableIcon';
import CardDraggable from './CardDraggable';

const CardListRow = ({
    index,
    children,
    draggable,
    disabled,
    className,
    selected,
    draggableIconProps,
    collection = 0,
    onClick,
    onKeyPress,
    tabIndex
}) => {
    const classes = classNames('onsolve-card-list__row', className, {
        'onsolve-card-list__row--selected': selected,
        'onsolve-card-list__row--disabled': disabled
    });

    const handleKeyPress = e => {
        if (onKeyPress) {
            onKeyPress(e);
        }
    };

    if (draggable) {
        return (
            <CardDraggable index={index} disabled={disabled} collection={collection}>
                <div className={classes} onClick={onClick} onKeyPress={handleKeyPress} tabIndex={tabIndex}>
                    <CardDraggableIcon {...draggableIconProps} disabled={disabled} />
                    {children}
                </div>
            </CardDraggable>
        );
    }

    return (
        <div className={classes} onClick={onClick} onKeyPress={handleKeyPress} tabIndex={tabIndex}>
            {children}
        </div>
    );
};

CardListRow.defaultProps = {
    draggableIconProps: {
        color: 'light',
        size: 'md'
    }
};

CardListRow.propTypes = {
    /**
    Specifies element(s) to be displaied inside card item
    */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    /**
    Extend classes for card row
    */
    className: PropTypes.string,
    /**
    Specifies given collection of the elements to drag
    */
    collection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
    Specifies disable state for an element
    */
    disabled: PropTypes.bool,
    /**
    Specifies draggable state
    */
    draggable: PropTypes.bool,
    /**
    Specifies drag icon props
    */
    draggableIconProps: PropTypes.shape({
        className: PropTypes.string,
        size: shapes.iconSizeShape,
        color: shapes.iconColorsShape
    }),
    /**
    Specifies index of the draggable element.
    */
    index: PropTypes.number.isRequired,
    /**
    Specifies onClick handler
    */
    onClick: PropTypes.func,
    /**
    Specifies onKeyPress handler
    */
    onKeyPress: PropTypes.func,
    /**
    Specifies selected state
    */
    selected: PropTypes.bool,
    /**
    Specifies tabIndex property to a card
    */
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

CardListRow.displayName = 'CardListRow';

export default CardListRow;
