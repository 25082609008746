import { EditorState, RichUtils, Modifier } from 'draft-js';

import { fp as _ } from '../../utils';

export const setInlineStyle = (editorState, newStyle, styleList) => {
    const selection = editorState.getSelection();

    // Turn off all other font sizes.
    const nextContentState = _.reduce(
        styleList,
        (contentState, _, sizeKey) => {
            return Modifier.removeInlineStyle(contentState, selection, sizeKey);
        },
        editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current font size.
    if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce(
            (state, newStyle) => RichUtils.toggleInlineStyle(state, newStyle),
            nextEditorState
        );
    }

    // If the font size is being toggled on, apply it.
    if (!currentStyle.has(newStyle)) {
        nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, newStyle);
    }

    return nextEditorState;
};
