import React from 'react';

import SvgIcon from './SvgIcon';

const CalendarOutlined = props => (
    <SvgIcon {...props}>
        <path
            d="M15 2h-2V1c0-.6-.4-1-1-1h-1c-.6 0-1 .4-1 1v1H6V1c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v1H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-1 12H2V7h12v7z"
            fillRule="nonzero"
            stroke="none"
        />
    </SvgIcon>
);

CalendarOutlined.displayName = 'CalendarOutlinedIcon';

export default CalendarOutlined;
