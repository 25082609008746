import React from 'react';
import SvgIcon from './SvgIcon';

const OpeningTimes = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M11.0625,7.125 C13.2371212,7.125 15,8.8878788 15,11.0625 C15,13.2371212 13.2371212,15 11.0625,15 C8.8878788,15 7.125,13.2371212 7.125,11.0625 C7.125,8.8878788 8.8878788,7.125 11.0625,7.125 Z M11.0625,1 C11.3041246,1 11.5,1.19587542 11.5,1.4375 L11.5,2.75 L13.6875,2.75 C14.4123737,2.75 15,3.33762627 15,4.0625 L15,5.8125 C15,6.0272774 14.8452342,6.20590732 14.6411412,6.24295129 L14.5625,6.25 L1.875,6.25 L1.875,13.6875 C1.875,13.9291246 2.07087542,14.125 2.3125,14.125 L6.6875,14.125 C6.92912458,14.125 7.125,14.3208754 7.125,14.5625 C7.125,14.8041246 6.92912458,15 6.6875,15 L2.3125,15 C1.58762627,15 1,14.4123737 1,13.6875 L1,4.0625 C1,3.33762627 1.58762627,2.75 2.3125,2.75 L4.5,2.75 L4.5,1.4375 C4.5,1.19587542 4.69587542,1 4.9375,1 C5.17912458,1 5.375,1.19587542 5.375,1.4375 L5.375,2.75 L10.625,2.75 L10.625,1.4375 C10.625,1.19587542 10.8208754,1 11.0625,1 Z M11.0625,8 C9.37112795,8 8,9.37112795 8,11.0625 C8,12.753872 9.37112795,14.125 11.0625,14.125 C12.753872,14.125 14.125,12.753872 14.125,11.0625 C14.125,9.37112795 12.753872,8 11.0625,8 Z M11.0625,8.875 C11.3041246,8.875 11.5,9.07087542 11.5,9.3125 L11.5,10.625 L12.8125,10.625 C13.0541246,10.625 13.25,10.8208754 13.25,11.0625 C13.25,11.3041246 13.0541246,11.5 12.8125,11.5 L11.0625,11.5 C10.8208754,11.5 10.625,11.3041246 10.625,11.0625 L10.625,9.3125 C10.625,9.07087542 10.8208754,8.875 11.0625,8.875 Z M13.6875,3.625 L2.3125,3.625 C2.07087542,3.625 1.875,3.82087542 1.875,4.0625 L1.875,5.375 L14.125,5.375 L14.125,4.0625 C14.125,3.82087542 13.9291246,3.625 13.6875,3.625 Z" />
        </g>
    </SvgIcon>
);

OpeningTimes.displayName = 'OpeningTimesIcon';

export default OpeningTimes;
