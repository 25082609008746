import React from 'react';
import SvgIcon from './SvgIcon';

const FlagFilled = props => {
    return (
        <SvgIcon {...props}>
            <path d="M8.787 1.765L8.415 0H.038v15H1.9V8.824h5.212c-1.576.71-1.452 1.297.372 1.765s3.996.467 6.516 0V1.765H8.787z" />
        </SvgIcon>
    );
};

FlagFilled.displayName = 'FlagFilledIcon';

export default FlagFilled;
