import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AlertModal } from '../../../Modals';
import { Typography } from '../../../Typography';
import { CancelYesActions } from '../../../Modals/Footer';
import { componentsTranslations } from '../../../translations';

const EditorTypeWarningModal = ({ isOpen, onCancel, onConfirm }) => {
    const actions = <CancelYesActions onYes={onConfirm} onCancel={onCancel} />;
    const title = <FormattedMessage {...componentsTranslations.ng_wysiwyg_editor_type_warning_title} />;
    const message = (
        <Typography className="onsolve-wysiwyg-editor-warning-modal__description mb-0" variant="p14">
            <FormattedMessage {...componentsTranslations.ng_wysiwyg_editor_type_warning_description} />
        </Typography>
    );

    return (
        <AlertModal
            className="onsolve-wysiwyg-editor-warning-modal"
            isOpen={isOpen}
            title={title}
            message={message}
            actions={actions}
            onClose={onCancel}
        />
    );
};

EditorTypeWarningModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
};

EditorTypeWarningModal.displayName = 'EditorTypeWarningModal';

export default EditorTypeWarningModal;
