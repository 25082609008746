import React from 'react';

import SvgIcon from './SvgIcon';

const CheckBox = props => {
    return (
        <SvgIcon {...props}>
            <g fillRule="evenodd" strokeLinecap="square">
                <rect width="15.484" height="15.484" x=".258" y=".258" rx="1" />
                <path stroke="#FFF" d="M4.387 8.516l2.065 2.065 5.161-5.162" />
            </g>
        </SvgIcon>
    );
};

CheckBox.displayName = 'CheckBoxIcon';

export default CheckBox;
