import React from 'react';
import SvgIcon from './SvgIcon';

const InfoCircle = props => (
    <SvgIcon {...props}>
        <g fill="none">
            <circle fillRule="nonzero" cx={8} cy={4.267} r={0.2} />
            <circle strokeLinecap="round" strokeLinejoin="round" cx={8} cy={8} r={7.467} />
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 12.267V8" />
        </g>
    </SvgIcon>
);

InfoCircle.displayName = 'InfoCircleIcon';

export default InfoCircle;
