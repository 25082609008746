import React, { Suspense, lazy } from 'react';

import { LoadingSpinner } from '../Loading';

const FileUpload = lazy(() => import('./FileUpload'));

const LazyFileUpload = props => (
    <Suspense fallback={<LoadingSpinner color="primary" className="onsolve-loading__icon--small" />}>
        <FileUpload {...props} />
    </Suspense>
);

LazyFileUpload.displayName = 'LazyFileUpload';

export default LazyFileUpload;
