import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { Radio, RadioGroup } from '../../Selections';
import { componentsTranslations } from '../../translations';

class MeridiemSwitcher extends PureComponent {
    render() {
        const { value, name: nameProp, onChange, disabled, intl } = this.props;
        const rootClasses = classNames('onsolve-time-input__check-box', {
            'onsolve-time-input__check-box--disabled': disabled
        });

        const name = `${nameProp}_meridiem`;

        return (
            <RadioGroup
                aria-label={intl.formatMessage(componentsTranslations.ng_components_meridiemAfter, { 0: nameProp })}
                className="onsolve-time-input__radio-group"
                name={name}
                value={value}
                onChange={onChange}
            >
                <Radio className={rootClasses} label="AM" value="AM" disabled={disabled} />
                <Radio className={rootClasses} label="PM" value="PM" disabled={disabled} />
            </RadioGroup>
        );
    }
}

MeridiemSwitcher.propTypes = {
    disabled: PropTypes.bool,
    intl: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOf(['', 'AM', 'PM'])
};

MeridiemSwitcher.defaultProps = {
    disabled: false
};

MeridiemSwitcher.displayName = 'MeridiemSwitcher';

export default injectIntl(MeridiemSwitcher);
