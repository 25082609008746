import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '../../Dropdowns';
import { componentsTranslations } from '../../translations';
import { FONT_SIZES } from '../constants';

const SizesDropdownControl = forwardRef(({ editorState, intl, list, onToggle, disabled }, ref) => {
    const handleDropdownChange = useCallback(
        (event, type) => {
            event.preventDefault();

            onToggle(type);
        },
        [onToggle]
    );

    const activeValue = useMemo(
        () => Object.keys(FONT_SIZES).find(size => editorState.getCurrentInlineStyle().has(size)),
        [editorState]
    );

    const menuProps = useMemo(() => ({ position: 'left', className: 'onsolve-wysiwyg-editor__font-menu' }), []);

    return (
        <div
            className="onsolve-wysiwyg-editor__drop-down onsolve-wysiwyg-editor__drop-down--font-size"
            aria-label={intl.formatMessage(componentsTranslations.ng_wysiwyg_editor_toolbar_dropdown_headers)}
        >
            <Dropdown
                ref={ref}
                variant="standard"
                value={activeValue}
                data={list}
                onChange={handleDropdownChange}
                menuProps={menuProps}
                disabled={disabled}
            />
        </div>
    );
});

SizesDropdownControl.propTypes = {
    disabled: PropTypes.bool,
    editorState: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired
};

SizesDropdownControl.defaultProps = {
    disabled: false
};

SizesDropdownControl.displayName = 'SizesDropdownControl';

export default SizesDropdownControl;
