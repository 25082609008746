import React from 'react';
import SvgIcon from './SvgIcon';

const Todo = props => (
    <SvgIcon {...props}>
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M6.513 11.238l-1.75-1.75a.846.846 0 010-1.226.846.846 0 011.224 0L7.125 9.4l2.887-2.888a.846.846 0 011.226 0c.35.35.35.876 0 1.226l-3.5 3.5c-.35.35-.788.437-1.226 0z" />
            <path d="M14.125 1.875H11.5v1.75h1.75v9.625H2.75V3.625H4.5v-1.75H1.875c-.525 0-.875.35-.875.875v11.375c0 .525.35.875.875.875h12.25c.525 0 .875-.35.875-.875V2.75c0-.525-.35-.875-.875-.875z" />
            <path d="M5.375 1h5.25v3.5h-5.25z" />
        </g>
    </SvgIcon>
);

Todo.displayName = 'TodoIcon';

export default Todo;
