import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { IconButton } from '../../Button';
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icons';
import { DomUtils } from '../../utils';
import { componentsTranslations } from '../../translations';

const translations = {
    left: componentsTranslations.ng_components_previousPeriod,
    right: componentsTranslations.ng_components_nextPeriod
};

const NavButton = React.forwardRef((props, ref) => {
    const { direction, onClick, disabled, size, tabIndex = 0, onKeyDown } = props;
    const intl = useIntl();

    let arrowDirection = direction;

    if (DomUtils.isRtl()) {
        arrowDirection = direction === 'left' ? 'right' : 'left';
    }

    const IconComponent = arrowDirection === 'left' ? ArrowLeftIcon : ArrowRightIcon;

    return (
        <div className="onsolve-date-picker__navigation">
            <IconButton
                size="xs"
                onClick={onClick}
                disabled={disabled}
                aria-label={intl.formatMessage(translations[arrowDirection], { 0: 'month' })}
                ref={ref}
                onKeyDown={onKeyDown}
                tabIndex={tabIndex}
            >
                <IconComponent disabled={disabled} size={size} color="secondary" />
            </IconButton>
        </div>
    );
});

NavButton.propTypes = {
    direction: PropTypes.oneOf(['left', 'right']),
    disabled: PropTypes.bool,
    intl: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    tabIndex: PropTypes.number
};

NavButton.defaultProps = {
    direction: 'left',
    disabled: false,
    size: 'md'
};

NavButton.displayName = 'NavButton';

export default NavButton;
