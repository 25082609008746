import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { addDays, addMonths, isSameMonth, subDays, subMonths } from 'date-fns';

import { keyCodes } from '../../../common/constants';

import { Popover, PopoverBody } from '../../Popover';
import { DomUtils } from '../../utils';
import { DatePicker, NavButton } from '../core';

class DatePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMonth: undefined,
            value: undefined,
            focusedDate: undefined,
            tabbing: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value || !props.isOpen) {
            return {
                currentMonth: props.initialMonth,
                value: props.value,
                focusedDate: props.initialMonth || state.focusedDate
            };
        }
        return null;
    }

    handleDatePickerChange = (e, date) => {
        const { onChange } = this.props;

        this.setState({ value: date, focusedDate: undefined, tabbing: false });

        if (onChange) {
            onChange(date);
        }
    };

    handlePrevMonthClick = () => {
        this.calculateMonth(subMonths);
    };

    handleNextMonthClick = () => {
        this.calculateMonth(addMonths);
    };

    handleNavigationButtonKeyDown = e => {
        const charCode = DomUtils.getCharCode(e);
        const { UP } = keyCodes;

        if (charCode === UP && e.altKey) {
            this.setState({ tabbing: false });
        }
        const { onKeyDown } = this.props;

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    handleDatePickerKeyDown = e => {
        const { onKeyDown } = this.props;

        if (!e.altKey) {
            const { focusedDate } = this.state;
            const charCode = DomUtils.getCharCode(e);
            const { TAB, UP, DOWN, LEFT, RIGHT, SPACE, ENTER_KEY } = keyCodes;

            switch (charCode) {
                case TAB:
                    this.setState({ tabbing: true });
                    break;
                case UP:
                    e.preventDefault();
                    this.setFocusedDate(subDays(focusedDate, 7));
                    break;
                case DOWN:
                    e.preventDefault();
                    this.setFocusedDate(addDays(focusedDate, 7));
                    break;
                case LEFT:
                    e.preventDefault();
                    this.setFocusedDate(subDays(focusedDate, 1));
                    break;
                case RIGHT:
                    e.preventDefault();
                    this.setFocusedDate(addDays(focusedDate, 1));
                    break;
                case SPACE:
                case ENTER_KEY:
                    e.preventDefault();
                    this.handleDatePickerChange(e, focusedDate);
                    break;
            }
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    handleKeyUp = e => {
        const { onKeyUp } = this.props;

        const charCode = DomUtils.getCharCode(e);
        const { ESC } = keyCodes;

        if (charCode === ESC) {
            this.setState({ focusedDate: undefined, tabbing: false });
        }

        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    setFocusedDate = value => {
        this.setState({ focusedDate: value, currentMonth: value, tabbing: false });
    };

    calculateMonth = fn => {
        const { onMonthChange } = this.props;
        const { currentMonth } = this.state;
        const value = fn(currentMonth, 1);

        if (onMonthChange) {
            onMonthChange();
        }

        this.setState({
            currentMonth: value,
            focusedDate: new Date(value.getFullYear(), value.getMonth(), 1)
        });
    };

    render() {
        const { id, container, isOpen, onClose, currentDate, maxDate, minDate, autoFocus } = this.props;
        const { currentMonth, value, focusedDate, tabbing } = this.state;
        const placement = DomUtils.isRtl() ? 'bottom-start' : 'bottom-end';
        const leftButtonDisabled = isSameMonth(currentMonth, minDate);
        const rightButtonDisabled = isSameMonth(currentMonth, maxDate);

        return (
            <Popover
                boundariesElement={id}
                isOpen={isOpen}
                target={id}
                container={container}
                placement={placement}
                onClose={onClose}
                trigger="manual"
            >
                <PopoverBody>
                    <div onKeyUp={this.handleKeyUp}>
                        <DatePicker
                            autoFocus={autoFocus && !tabbing}
                            month={currentMonth}
                            value={value}
                            leftButton={
                                <NavButton
                                    direction="left"
                                    disabled={leftButtonDisabled}
                                    onKeyDown={this.handleNavigationButtonKeyDown}
                                    onClick={this.handlePrevMonthClick}
                                />
                            }
                            rightButton={
                                <NavButton
                                    direction="right"
                                    disabled={rightButtonDisabled}
                                    onKeyDown={this.handleNavigationButtonKeyDown}
                                    onClick={this.handleNextMonthClick}
                                />
                            }
                            currentDate={currentDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            focusedDate={focusedDate}
                            onChange={this.handleDatePickerChange}
                            onKeyDown={this.handleDatePickerKeyDown}
                        />
                    </div>
                </PopoverBody>
            </Popover>
        );
    }
}

DatePopup.propTypes = {
    autoFocus: PropTypes.bool,
    container: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    currentDate: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    initialMonth: PropTypes.instanceOf(Date),
    isOpen: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMonthChange: PropTypes.func,
    value: PropTypes.instanceOf(Date)
};

DatePopup.defaultProps = {
    autoFocus: false,
    initialMonth: new Date(),
    value: null
};

DatePopup.displayName = 'DatePopup';

export default DatePopup;
