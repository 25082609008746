import React from 'react';
import SvgIcon from './SvgIcon';

const ArrowRight = props => (
    <SvgIcon {...props}>
        <path
            stroke="none"
            d="M9.793 8l-4.646-4.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l5 5c0.195 0.195 0.195 0.512 0 0.707l-5 5c-0.195 0.195-0.512 0.195-0.707 0s-0.195-0.512 0-0.707l4.646-4.646z"
        />
    </SvgIcon>
);

ArrowRight.displayName = 'ArrowRightIcon';

export default ArrowRight;
