import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Divider extends Component {
    render() {
        const { className, variant, color, vertical, children, ...props } = this.props;
        const rootClasses = classNames('onsolve-divider', className, {
            'onsolve-divider--fitted': variant === 'fitted',
            [`onsolve-divider--${color}`]: color !== undefined,
            'onsolve-divider--vertical': vertical,
            'onsolve-divider--text': children
        });

        return (
            <div className={rootClasses} {...props}>
                {children}
            </div>
        );
    }
}

Divider.propTypes = {
    /**
     Add react component or node between divider lines.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    /**
     Add classname to wrapper.
     */
    className: PropTypes.string,
    /**
     Change line color.
     */
    color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success', 'dark', 'light']),
    /**
     Change variant.
     */
    variant: PropTypes.oneOf(['fitted']),
    /**
     Change divider position from horizontal to vertical.
     */
    vertical: PropTypes.bool
};

Divider.displayName = 'Divider';

export default Divider;
