import React from 'react';

import SvgIcon from './SvgIcon';

const Play = props => (
    <SvgIcon {...props}>
        <g transform="translate(3.000000, 1.000000)">
            <path d="M1.12,0.14 C0.9079,-0.0189 0.6244,-0.0448 0.3871,0.0735 C0.1498,0.1925 0,0.4347 0,0.7 L0,13.3 C0,13.5653 0.1498,13.8075 0.3871,13.9265 C0.4858,13.9755 0.5936,14 0.7,14 C0.8484,14 0.9968,13.9524 1.12,13.86 L9.52,7.56 C9.6964,7.4284 9.8,7.2205 9.8,7 C9.8,6.7795 9.6964,6.5723 9.52,6.44 L1.12,0.14 Z" />
        </g>
    </SvgIcon>
);

Play.displayName = 'PlayIcon';

export default Play;
