import React from 'react';

import SvgIcon from './SvgIcon';

const Clone = props => {
    return (
        <SvgIcon {...props}>
            <path
                d="M1.875 12.375h3.063c.242 0 .438.196.438.438s-.196.438-.437.438h-3.5c-.242 0-.437-.196-.437-.437V1.438C1 1.196 1.196 1 1.438 1h9.625c.242 0 .438.196.438.438v1.75c0 .242-.196.438-.437.438s-.437-.196-.437-.437V1.875h-8.75v10.5zM8 6.25v7.875h6.125V6.25H8zm-.437-.875h7c.242 0 .438.196.438.438v8.75c0 .242-.196.438-.437.438h-7c-.242 0-.437-.196-.437-.437v-8.75c0-.242.196-.437.438-.437z"
                stroke="none"
            />
        </SvgIcon>
    );
};

Clone.displayName = 'CloneIcon';

export default Clone;
